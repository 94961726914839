import React from "react";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

// MUI
import Dialog from "@mui/material/Dialog";

// ACTIONS
import { setWatchlist } from "../../../actions/watchlistActions";
import { setAlert } from "../../../actions/alertActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const CreateWatchlist = ({
    closeModal,
    user,
    watchlist,
    setWatchlist,
    setAlert,
}) => {
    /*Create Select Watchlist Icon Modal*/
    const [openWatchlistIcon, setOpenWatchlistIcon] = React.useState(false);
    const handleClickOpenWatchlistIcon = () => {
        setOpenWatchlistIcon(true);
    };
    const handleCloseWatchlistIcon = () => {
        setOpenWatchlistIcon(false);
    };

    /*Create new Watchlist functions*/
    const [newWatchlist, setNewWatchlist] = React.useState({
        name: "",
        icon: "collection",
        description: "",
    });
    const handleNewWatchlistName = (event) => {
        if (event.target.value.length <= 20) {
            setNewWatchlist({ ...newWatchlist, name: event.target.value });
        }
    };
    const handleNewWatchlistIcon = (icon) => {
        setNewWatchlist({ ...newWatchlist, icon: icon });
    };
    const handleNewWatchlistDescription = (event) => {
        if (event.target.value.length <= 500) {
            setNewWatchlist({
                ...newWatchlist,
                description: event.target.value,
            });
        }
    };
    const handleSetWatchlist = () => {
        if (newWatchlist.name !== "") {
            setWatchlist(
                {
                    name: newWatchlist.name,
                    icon: newWatchlist.icon,
                    description: newWatchlist.description,
                    user: user.id,
                },
                watchlist,
                user.status
            );
            setAlert(
                `Your watchlist "${newWatchlist.name}" has been created.`,
                "success"
            );
            setNewWatchlist({
                name: "",
                icon: "collection",
                description: "",
            });
            // setCurrentWatchlist(id);
            closeModal();
            // routeRedirect(`/watchlist?id=${id}`);
        } else {
            setAlert(`You need to provide a watchlist name.`, "danger");
        }
    };
    return (
        <div className='text_color_1 background_color_2 max_width_500px'>
            <div className='flex space_between title_4 mt_10px mb_10px ml_15px mr_15px'>
                <div className='mr_10px title_5'>Create Watchlist</div>
                <div
                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                    onClick={closeModal}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            <div className='divider'></div>

            <div className='flex flex_column padding_10px space_between'>
                <div className='width_100pr'>
                    <div className='flex'>
                        <div
                            className='width_20pr mt_5px flex left cursor_pointer hover_opacity_80'
                            onClick={handleClickOpenWatchlistIcon}
                        >
                            <div className='width_20px text_1'>
                                {icon_storage.map((icon) =>
                                    icon.name === newWatchlist.icon
                                        ? icon.icon
                                        : null
                                )}
                            </div>
                            <div className='width_30px text_1'>
                                <RiIcons.RiArrowDownSFill />
                            </div>
                        </div>
                        <div className='input_basic_border border_radius_5px width_80pr padding_5px height_100pr'>
                            <input
                                className='text_2 text_color_1'
                                type='text'
                                placeholder='Name...'
                                maxlength='20'
                                onKeyUp={handleNewWatchlistName}
                            />
                        </div>
                    </div>
                    <div>
                        <textarea
                            className='mt_10px text_2 width_100pr text_color_1 height_120px padding_5px resize_none border_radius_5px input_basic_border'
                            name='text'
                            rows='8'
                            placeholder='Description...'
                            maxlength='500'
                            onKeyUp={handleNewWatchlistDescription}
                        />
                    </div>
                </div>

                <div className='width_100pr flex right mt_10px'>
                    <div
                        className='button_blue color_white ml_15px'
                        onClick={() => {
                            handleSetWatchlist();
                        }}
                    >
                        <div className='ml_10px text_3'>Create</div>
                        <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                    </div>
                </div>
            </div>
            {/* WATCHLIST ICON */}
            <Dialog
                open={openWatchlistIcon}
                onClose={handleCloseWatchlistIcon}
                maxWidth={false}
                className=''
            >
                <div className='max_width_500px background_color_2 text_color_1'>
                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{"Select a fitting Icon"}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleCloseWatchlistIcon}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider mb_15px'></div>
                    <div className='flex space_around wrap border_box padding_10px'>
                        {icon_storage.map((icon, index) => {
                            return (
                                <div
                                    className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                    key={index}
                                    onClick={() => {
                                        handleNewWatchlistIcon(icon.name);
                                        handleCloseWatchlistIcon();
                                    }}
                                >
                                    {icon.icon}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

CreateWatchlist.propTypes = {
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    setWatchlist: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    watchlist: state.watchlist,
    user: state.user,
});

export default connect(mapStateToProps, {
    setWatchlist,
    setAlert,
})(CreateWatchlist);
