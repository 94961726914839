import React from "react";
import { useIntl } from "react-intl";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import PortfolioTableRow from "./PortfolioTableRow";
import PortfolioTableHead from "./PortfolioTableHead";

const PortfolioTable = ({
    companies,
    portfolioType,
    allocation,
    company_allocation_tracker,
    portfolio_value,
    portfolio,
    slider,
    options,
}) => {
    const useintl = useIntl();

    // CURRENT SELECTED PORTFOLIO
    let currentPortfolio = null;
    if (window.location.pathname === "/creator") {
        // TICKER ARRAY BEFÜLLEN
        currentPortfolio = {
            id: null,
            assets: portfolio.current_creator_companies,
            allocation: allocation,
            ticker: null,
        };
    } else {
        currentPortfolio = portfolio.portfolios.filter((obj) => {
            return obj.id === portfolio.current;
        });
        currentPortfolio = currentPortfolio[0];
    }

    let tableBuild = {
        individual: { metrics: ["ticker"], headers: ["Ticker"] },
        filtered_metrics: { metrics: ["ticker"], headers: ["Ticker"] },
        value: {
            metrics: [
                "ticker",
                "price_earnings",
                "price_earnings_growth",
                "price_sales",
                "ev_ebitda",
                "price_book",
                "price_cash_flow",
                "revenue_growth_5y",
                "earnings_growth_5y",
                "debt_ratio",
                "ev",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.price_earnings",
                }),
                useintl.formatMessage({
                    id: "metrics.price_earnings_growth",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
                useintl.formatMessage({
                    id: "metrics.ev_ebitda",
                }),
                useintl.formatMessage({
                    id: "metrics.price_book",
                }),
                useintl.formatMessage({
                    id: "metrics.price_cash_flow",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.debt_ratio",
                }),
                useintl.formatMessage({
                    id: "metrics.ev",
                }),
            ],
        },
        growth: {
            metrics: [
                "ticker",
                "revenue_growth_1y",
                "revenue_growth_3y",
                "revenue_growth_5y",
                "revenue_growth_5y_ranker",
                "earnings_growth_1y",
                "earnings_growth_3y",
                "earnings_growth_5y",
                "earnings_growth_5y_ranker",
                "gross_profit_margin",
                "gross_profit_margin_ranker",
                "price_sales",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.revenue_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
            ],
        },
        dividends: {
            metrics: [
                "ticker",
                "dividend_yield",
                "dividend_consistency",
                "dividend_growth_3y",
                "earnings_growth_3y",
                "dividend_growth_5y",
                "earnings_growth_5y",
                "payout_ratio",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.dividend_yield",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_consistency",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.payout_ratio",
                }),
            ],
        },
    };

    let currency_sign = "$";
    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    // Get Filtered Metrics and Metrics to display in the table
    let screenerCombinedForm = { ...slider, ...options };
    tableBuild.individual.length = 0;
    tableBuild.filtered_metrics.length = 0;
    tableBuild.individual = { metrics: ["ticker"], headers: ["Ticker"] };
    tableBuild.filtered_metrics = { metrics: ["ticker"], headers: ["Ticker"] };

    for (const [key, value] of Object.entries(screenerCombinedForm)) {
        if (
            value.display &&
            value.property !== "searchOption" &&
            value.property !== "sortBy"
        ) {
            tableBuild.filtered_metrics.metrics.push(key);
            tableBuild.filtered_metrics.headers.push(value.displayName);
        }
        if (value.display) {
            tableBuild.individual.metrics.push(key);
            tableBuild.individual.headers.push(value.displayName);
        }
    }

    let tableValues;
    if (screenerCombinedForm.tableStructure.value === "individual") {
        tableValues = tableBuild.individual;
    } else if (
        screenerCombinedForm.tableStructure.value === "filtered_metrics"
    ) {
        tableValues = tableBuild.filtered_metrics;
    } else if (screenerCombinedForm.tableStructure.value === "value") {
        tableValues = tableBuild.value;
    } else if (screenerCombinedForm.tableStructure.value === "growth") {
        tableValues = tableBuild.growth;
    } else if (screenerCombinedForm.tableStructure.value === "dividends") {
        tableValues = tableBuild.dividends;
    }

    return (
        <div>
            {companies === null ? (
                <Preloader />
            ) : (
                <div className='overflow_x_auto sticky_table max_table_height background_color_2'>
                    <table>
                        <thead className='sticky_table_thead background_color_2'>
                            <tr className='sticky_table_thead_tr background_color_2 text_2 text_color_1'>
                                <th className='padding_15_5px min_width_200px background_color_2 vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.company",
                                    })}
                                </th>

                                <th className='padding_15_5px min_width_100px text_right vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.allocation",
                                    })}
                                </th>

                                <th className='padding_15_5px min_width_100px text_right vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.position",
                                    })}
                                </th>

                                {window.location.pathname === "/portfolio" ||
                                window.location.pathname === "/creator" ? (
                                    <th className='padding_15_5px min_width_100px text_right vertical_align_top'>
                                        {useintl.formatMessage({
                                            id: "metrics.stock_price",
                                        })}
                                    </th>
                                ) : null}

                                {window.location.pathname === "/portfolio" ||
                                window.location.pathname === "/creator" ? (
                                    <th className='padding_15_5px min_width_100px text_right vertical_align_top'>
                                        {useintl.formatMessage({
                                            id: "metrics.daily_return",
                                        })}
                                    </th>
                                ) : null}

                                <th className='padding_15_5px width_80px text_right vertical_align_top'>
                                    {" "}
                                    {useintl.formatMessage({
                                        id: "general.shares",
                                    })}
                                </th>

                                {tableValues.metrics.map((metric) =>
                                    industries_display.includes(metric) ? (
                                        <PortfolioTableHead metric={metric} />
                                    ) : null
                                )}
                                {tableValues.metrics.map((metric) =>
                                    !industries_display.includes(metric) ? (
                                        <PortfolioTableHead metric={metric} />
                                    ) : null
                                )}
                                <th className='padding_15_5px text_right vertical_align_top min_width_120px'>
                                    {useintl.formatMessage({
                                        id: "metrics.market_cap",
                                    })}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {window.location.pathname === "/creator" ||
                            portfolioType === "manager"
                                ? allocation !== null
                                    ? companies.map((company) =>
                                          allocation[company.ticker] ? (
                                              <PortfolioTableRow
                                                  portfolio_value={
                                                      portfolio_value
                                                  }
                                                  portfolioType={portfolioType}
                                                  company_allocation={
                                                      allocation
                                                  }
                                                  company={company}
                                                  currentPortfolio={
                                                      currentPortfolio
                                                  }
                                                  metrics={tableValues.metrics}
                                                  key={company.ticker}
                                              />
                                          ) : null
                                      )
                                    : null
                                : company_allocation_tracker !== null
                                ? companies.map((company) =>
                                      company_allocation_tracker[
                                          company.ticker
                                      ] ? (
                                          <PortfolioTableRow
                                              portfolio_value={portfolio_value}
                                              portfolioType={portfolioType}
                                              company_allocation={
                                                  company_allocation_tracker
                                              }
                                              company={company}
                                              currentPortfolio={
                                                  currentPortfolio
                                              }
                                              metrics={tableValues.metrics}
                                              key={company.ticker}
                                          />
                                      ) : null
                                  )
                                : null}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

PortfolioTable.propTypes = {
    companies: PropTypes.array,
    allocation: PropTypes.object.isRequired,
    portfolio_value: PropTypes.number.isRequired,
    screenerCombinedForm: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, null)(PortfolioTable);
