import React, { useEffect } from "react";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Ad0Vertical100vw = ({ user }) => {
    // ADS
    // useEffect(() => {
    //     if (user.status === 0) {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({});
    //     }

    //     // eslint-disable-next-line
    // }, []);

    // return (
    //     <div className='width_70vw height_10vh flex center'>
    //         {user.status === 0 ? (
    //             <ins
    //                 className='adsbygoogle'
    //                 style={{
    //                     display: "inline-block",
    //                     width: "100%",
    //                     height: "100%",
    //                 }}
    //                 data-ad-client='ca-pub-7214201182905694'
    //                 data-ad-slot='7280907182'
    //                 data-ad-format='auto'
    //                 data-adtest='on'
    //                 data-full-width-responsive='true'
    //             ></ins>
    //         ) : null}
    //     </div>
    // );
    return <div></div>;
};

Ad0Vertical100vw.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {})(Ad0Vertical100vw);
