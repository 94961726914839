import React from "react";
import { useIntl } from "react-intl";

import * as dfd from "danfojs";
import * as math from "mathjs";

// Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { IoMdGlasses } from "react-icons/io";

const PortfolioEstimatedDividendsChart = ({
    company_list,
    company_allocation,
}) => {
    const useintl = useIntl();
    const [estimation, setEstimation] = React.useState("linear_regression");

    let currency = "USD";

    const handleEstimation = () => {};
    let chartOptions;

    let dividend_ps_arr = [];
    // let dividend_yield_arr = [];

    let ticker_list = [];

    function getQuarter(d) {
        d = d || new Date();
        var m = Math.floor(d.getMonth() / 3) + 2;
        m -= m > 4 ? 4 : 0;
        var y = d.getFullYear() + (m == 1 ? 1 : 0);
        return `${y}q${m}`;
    }

    let latest_quarter;
    let years_length;

    // Calculate Dividend History
    const calculateDividendHistory = (asset_allocation, companies_array) => {
        let companies = {};
        let sorted_companies_length = companies_array.sort((a, b) =>
            a.dividends_paid_arr.length > b.dividends_paid_arr.length ? -1 : 1
        );
        sorted_companies_length.map((company) => {
            companies[company.ticker] = company;
            ticker_list.push(company.ticker);
        });

        let allocation_object = {};
        let first_ticker = null;

        // Create Assets Object
        Object.keys(asset_allocation).map((asset) => {
            if (asset_allocation[asset].status) {
                if (first_ticker === null) {
                    first_ticker = asset_allocation[asset].ticker;
                }
                allocation_object[asset] = asset_allocation[asset];
            }
        });

        // CALCULATE DIVIDENDS PER SHARE
        let ticker_dividends_per_share = companies[
            first_ticker
        ].dividends_paid_arr.map((n, i) => {
            if (
                n !== null &&
                companies[first_ticker].outstanding_shares_arr[i] !== null
            ) {
                return (
                    Math.abs(n) /
                    companies[first_ticker].outstanding_shares_arr[i]
                );
            } else {
                return 0;
            }
        });

        // CALCULATE DIVIDENDS PER POSITION
        let ticker_dividends_per_position = math.multiply(
            ticker_dividends_per_share,
            allocation_object[first_ticker].count
        );

        let years_arr = [];

        latest_quarter = getQuarter(
            new Date(
                companies[first_ticker].time_arr[
                    companies[first_ticker].time_arr.length - 1
                ]
            )
        );

        companies[first_ticker].time_arr.map((date) =>
            date !== null ? years_arr.push(getQuarter(new Date(date))) : null
        );

        // INITIATE DATAFRAME
        let initiate = new dfd.DataFrame(
            {
                time: years_arr,
                // time: companies[first_ticker].time_arr,
                [first_ticker]: ticker_dividends_per_position,
            },
            { index: companies[first_ticker].time_arr }
        );

        let initiate_df_pre = initiate.groupby(["time"]);
        let initiate_df = initiate_df_pre.col([first_ticker]).sum();

        // Loop the object, merge every company to dataframe
        Object.keys(allocation_object).map((asset, index) => {
            if (index > 0) {
                // CALCULATE DIVIDEND PER POSITION
                let ticker_dividends_per_share = companies[
                    asset
                ].dividends_paid_arr.map((n, i) => {
                    if (
                        n !== null &&
                        companies[asset].outstanding_shares_arr[i] !== null
                    ) {
                        return (
                            Math.abs(n) /
                            companies[asset].outstanding_shares_arr[i]
                        );
                    } else {
                        return 0;
                    }
                });
                let ticker_dividends_per_position = math.multiply(
                    ticker_dividends_per_share,
                    allocation_object[asset].count
                );

                let years_arr = [];

                companies[asset].time_arr.map((date) =>
                    date !== null
                        ? years_arr.push(getQuarter(new Date(date)))
                        : null
                );

                let df_loop = new dfd.DataFrame(
                    {
                        time: years_arr,
                        [asset]: ticker_dividends_per_position,
                    },
                    { index: companies[asset].time_arr }
                );

                let grp = df_loop.groupby(["time"]);
                let loop_sum = grp.col([asset]).sum();

                // An dieser Stelle Inhalte aufsummieren die selbes Jahr haben

                let merge_df = dfd.merge({
                    left: initiate_df,
                    right: loop_sum,
                    on: ["time"],
                    how: "outer",
                });

                initiate_df = merge_df;
            }
        });

        // Remove outliers

        initiate_df.set_index({ column: "time", inplace: true });
        initiate_df.drop({ columns: ["time"], inplace: true });
        let total_dividend_df = initiate_df.sum({ axis: 0 });

        // let df2 = total_dividend_df.sort_index({ ascending: true });

        let df_stats = total_dividend_df.describe();
        let min = df_stats.$data[3];
        let median = df_stats.$data[4];

        let dividends = total_dividend_df.values;
        let iterate_dividends = 0;

        for (let i = 0; i < total_dividend_df.values.length; i++) {
            if (i === 0 && dividends[i] > median + 20 * (median - min)) {
                dividends[i] = Number(median.toFixed(2));
            } else if (dividends[i] > median + 10 * (median - min)) {
                dividends[i] = Number(dividends[iterate_dividends].toFixed(2));
            } else {
                dividends[i] = Number(dividends[i].toFixed(2));
                iterate_dividends = i;
            }
        }

        let history = [total_dividend_df.index, dividends];

        // SORT HISTORY BY QUARTERS

        // let test_index = help_dates.indexOf("2012q2");
        // let sorted_dates = total_dividend_df.index.sort((a, b) =>
        //     a < b ? -1 : 1
        // );

        history = history[0].map((_, colIndex) =>
            history.map((row) => row[colIndex])
        );

        history.sort(sortFunction);

        function sortFunction(a, b) {
            if (a[0] === b[0]) {
                return 0;
            } else {
                return a[0] < b[0] ? -1 : 1;
            }
        }

        history = history[0].map((_, colIndex) =>
            history.map((row) => row[colIndex])
        );

        return history;
    };

    let dividendHistory =
        company_list &&
        company_list.length > 0 &&
        company_allocation !== null &&
        company_allocation !== undefined
            ? calculateDividendHistory(company_allocation, company_list)
            : null;

    let theta;
    let std;
    let y_std_diff_std;

    const linear_regression = (dividendHistory) => {
        let PerformanceMatrix = math.matrix([
            dividendHistory[0].slice(1),
            dividendHistory[1].slice(1),
        ]);

        let m = PerformanceMatrix._data[1].length;

        let y = math.matrix(PerformanceMatrix._data[1]);
        // [y1, y2, y3, y4 ...]

        let X_help = [];
        for (let i = 0; i < PerformanceMatrix._data[0].length; i++) {
            X_help.push(i);
        }

        years_length = PerformanceMatrix._data[0].length;

        // let X_help = PerformanceMatrix._data[0];
        let ones = math.ones(m);
        ones = ones._data;

        let Xtr = math.matrix([X_help, ones]);
        // [[x1, x2, x3, x4 ...], [1, 1, 1, 1 ...]]

        let X = math.transpose(Xtr);
        // [[x1, 1], [x2, 2], [x3, 3] ...]

        let Xtr_X = math.multiply(Xtr, X);

        let Xtr_y = math.multiply(Xtr, y);

        theta = math.multiply(math.inv(Xtr_X), Xtr_y);

        let y_std = PerformanceMatrix._data[1];

        y_std = math.log(math.matrix(y_std));

        let y_std_diff = math.diff(y_std);

        y_std_diff_std = math.std(y_std_diff);

        // let X_help_std = PerformanceMatrix._data[0];
        let X_help_std = X_help;

        let X_std = math.multiply(theta._data[0], X_help_std);

        let theta_1_std = math.multiply(math.ones(m), theta._data[1]);

        let help = math.add(
            y_std,
            math.multiply(math.add(X_std, theta_1_std), -1)
        );
        std = math.sqrt(math.multiply(help, help) / 9);
    };

    linear_regression(dividendHistory);

    let lin_reg = [];
    dividendHistory[0].map((year) => lin_reg.push(null));

    for (let i = years_length; i < years_length + 11; i++) {
        lin_reg.push(Number((theta._data[1] + theta._data[0] * i).toFixed(2)));
        // dividend_ps_arr.push(last_dividend + theta._data[0] * (year - 2020));
    }

    let dividend_annual_average_growth = Number(
        (
            ((lin_reg[lin_reg.length - 1] / lin_reg[lin_reg.length - 5]) **
                (1 / 5) -
                1) *
            100
        ).toPrecision(3)
    );

    let estimated_dividends_years = [];

    dividendHistory[0].map((year) => estimated_dividends_years.push(`${year}`));

    let latest_date = latest_quarter.split("q");
    let latest_year = Number(latest_date[0]);
    let latest_quartile = Number(latest_date[1]);

    for (let i = 1; i <= 12; i++) {
        if (latest_quartile === 4) {
            estimated_dividends_years.push(
                `${latest_year}q${latest_quartile}e`
            );
            latest_quartile = 1;
            latest_year += 1;
        } else {
            estimated_dividends_years.push(
                `${latest_year}q${latest_quartile}e`
            );
            latest_quartile += 1;
        }
    }

    chartOptions = {
        chart: {
            zoomType: "xy",
            events: {
                load() {},
            },
        },
        title: {
            enabled: false,
            // text: `${useintl.formatMessage({
            //     id: `general.dividends`,
            // })} - ${useintl.formatMessage({
            //     id: `general.ttm_short`,
            // })}`,
            text: null,
        },
        subtitle: {
            text: "",
        },
        credits: {
            text: "StocksOnView",
            href: "#",
        },
        xAxis: [
            {
                categories: estimated_dividends_years,
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    format: `{value} ${currency}`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    enabled: false,
                    text: `${useintl.formatMessage({
                        id: `general.amount_in`,
                    })} ${currency}`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
            // {
            //     // Secondary yAxis
            //     title: {
            //         text: "Dividend yield in %",
            //         style: {
            //             color: Highcharts.getOptions().colors[0],
            //         },
            //     },
            //     labels: {
            //         format: "{value} %",
            //         style: {
            //             color: Highcharts.getOptions().colors[0],
            //         },
            //     },
            //     opposite: true,
            // },
        ],
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            layout: "vertical",
            align: "left",
            x: 120,
            verticalAlign: "top",
            y: 100,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                "rgba(255,255,255,0.25)",
        },
        series: [
            {
                name: `${useintl.formatMessage({
                    id: `general.received_dividends`,
                })}`,
                type: "column",
                color: Highcharts.getOptions().colors[3],
                data: dividendHistory[1],
                tooltip: {
                    valueSuffix: `${currency}`,
                },
            },
            // {
            //     name: `${useintl.formatMessage({
            //         id: `general.estimated_dividends`,
            //     })}`,
            //     type: "column",
            //     color: "#f5c38e",
            //     data: lin_reg,
            //     yAxis: 0,
            //     tooltip: {
            //         valueSuffix: `${currency}`,
            //     },
            // },
            // {
            //     name: "Dividend yield",
            //     type: "line",
            //     color: Highcharts.getOptions().colors[5],
            //     data: dividend_yield_arr,
            //     yAxis: 1,
            //     tooltip: {
            //         valueSuffix: ` %`,
            //     },
            // },
        ],
    };

    return (
        <div className=''>
            <div className='flex space_between mt_20px ml_20px mr_20px'>
                <div className='flex wrap'>
                    <div className='text_1 mr_15px'>
                        {useintl.formatMessage({
                            id: `general.dividends`,
                        })}{" "}
                        -{" "}
                        {useintl.formatMessage({
                            id: `general.ttm_short`,
                        })}
                    </div>
                </div>
                {!isNaN(dividend_annual_average_growth) ? (
                    <div className='flex wrap'>
                        {dividend_annual_average_growth >= 0 ? (
                            <div className='text_color_green'>
                                {dividend_annual_average_growth} %{" "}
                            </div>
                        ) : (
                            <div className='text_color_red'>
                                {dividend_annual_average_growth} %{" "}
                            </div>
                        )}
                        <div className='ml_15px'>
                            {useintl.formatMessage({
                                id: `general.averaged_estimated_growth`,
                            })}{" "}
                            (qoq)
                        </div>
                    </div>
                ) : null}
            </div>
            <div className='mt_20px'></div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

PortfolioEstimatedDividendsChart.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(PortfolioEstimatedDividendsChart);
