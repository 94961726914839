import React from "react";
import { useIntl } from "react-intl";

const PortfolioTableHead = ({ metric }) => {
    const useintl = useIntl();

    let not_metrics = [
        "page",
        "limit",
        "search",
        "searchOption",
        "sort",
        "industry",
        "esg",
        "stock_exchange",
        "ticker",
        "description",
        "name",
        "currency",
        "country",
        "market_cap",
        "market_cap_usd",
    ];
    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    let not_manager = ["daily_return"];
    let not_creator = ["daily_return"];

    if (not_metrics.includes(metric)) {
        return null;
    } else if (
        (window.location.pathname === "/portfolio" &&
            not_manager.includes(metric)) ||
        (window.location.pathname === "/portfolio" &&
            metric === "stock_price") ||
        (window.location.pathname === "/creator" && metric === "stock_price") ||
        (window.location.pathname === "/creator" &&
            not_creator.includes(metric))
    ) {
        return null;
    } else if (industries_display.includes(metric)) {
        return (
            <th className='padding_15_5px min_width_120px text_2 text_left vertical_align_top'>
                {useintl.formatMessage({
                    id: `metrics.${metric}`,
                })}
            </th>
        );
    } else {
        return (
            <th className='padding_15_5px min_width_120px text_2 text_right vertical_align_top'>
                {useintl.formatMessage({
                    id: `metrics.${metric}`,
                })}
            </th>
        );
    }
};

export default PortfolioTableHead;
