import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as MdIcons from "react-icons/md";

// COMPONENTS
import CompanyActionsMenu from "../elements/CompanyActionsMenu";
import Overview from "./Overview";
import Scores from "./Scores";
import Charts from "./Charts";
import Figures from "./Figures";
import Competitors from "./Competitors";
import Preloader from "../../layout/Preloader";

// ACTIONS
import {
    getCompany,
    setCompanyLoading,
    setCompanyOpenTab,
} from "../../../actions/companyActions";
import { setBookmark } from "../../../actions/bookmarkActions";

// CONTEXT
import { Context } from "../../../context/LocaleContext";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

const Company = ({
    user,
    sidebar: { sidebar },
    bookmark: { bookmarks },
    company,
    getCompany,
    setCompanyLoading,
    setBookmark,
    setCompanyOpenTab,
}) => {
    // useEffect(() => {
    //     if (window.location.search) {
    //         getCompany(window.location.search);
    //     }
    //     // eslint-disable-next-line
    // }, []);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (window.location.search) {
            if (window.location.search !== `?ticker=${company.ticker}`) {
                getCompany(window.location.search);
            }
            if (company.open_tab) {
                if (company.open_tab === 1) {
                    setCompanyTab(<Overview requiredStatus={0} />);
                    setUnderLine(1);
                    window.scrollTo(0, 0);
                } else if (company.open_tab === 3) {
                    setCompanyTab(<Scores requiredStatus={0} />);
                    setUnderLine(3);
                } else if (company.open_tab === 4) {
                    setCompanyTab(<Charts requiredStatus={0} />);
                    setUnderLine(4);
                } else if (company.open_tab === 5) {
                    setCompanyTab(<Figures requiredStatus={0} />);
                    setUnderLine(5);
                } else if (company.open_tab === 7) {
                    setCompanyTab(<Competitors requiredStatus={0} />);
                    setUnderLine(7);
                }
            } else {
                setUnderLine(1);
                window.scrollTo(0, 0);
            }
        }
        // eslint-disable-next-line
    }, [company.ticker]);

    const handleSetBookmark = (bookmark) => {
        let checkForBookmark = bookmarks.filter(
            (item) => item.ticker === bookmark.ticker
        );
        if (checkForBookmark.length == 0) {
            setBookmark(bookmark);
        }
    };

    const useintl = useIntl();
    const context = useContext(Context);

    let stock_price_change_1d = 0;
    if (company.company !== null && company.company.stock_prices !== null) {
        stock_price_change_1d = Number(
            (
                company.company.stock_prices[
                    company.company.stock_prices.length - 1
                ][4] -
                company.company.stock_prices[
                    company.company.stock_prices.length - 2
                ][4]
            ).toPrecision(4)
        );
    }

    const [underLine, setUnderLine] = React.useState(1);

    const [companyTab, setCompanyTab] = React.useState(<Overview />);

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>
                    {`
                    ${
                        company && company.company && company.company.name
                            ? company.company.name + " - "
                            : " "
                    }${
                        company && company.company && company.company.ticker
                            ? company.company.ticker + ", "
                            : " "
                    }
                    Charts, Scores, Competitors... | StocksOnView`}
                </title>
                <meta
                    name='description'
                    content={`Quantitative Stock Analysis of ${
                        company && company.company && company.company.name
                            ? company.company.name + " - "
                            : " "
                    }${
                        company && company.company && company.company.ticker
                            ? company.company.ticker + ", "
                            : " "
                    } stock price, revenues, earnings, ratings and a discounted cash flow analysis | StocksOnView`}
                />
                <meta
                    name='keywords'
                    content={`${
                        company && company.company && company.company.name
                            ? company.company.name + " - "
                            : " "
                    }${
                        company && company.company && company.company.ticker
                            ? company.company.ticker + ", "
                            : " "
                    } stock price, revenues, earnings, ratings, discounted cash flow, fundamental`}
                />
            </Helmet>
            <TopBarCallToAction />
            {/* Stock Price and Actions Menu */}
            <div className='pl_15px mb_20px width_100pr background_color_1 text_color_1'>
                {company.company === null ? null : (
                    <div className='width_100pr'>
                        {/* NAME AND PRICE */}
                        <div className='flex space_between wrap'>
                            <h1 className='title_4 mt_20px pr_20px'>
                                {company.company.name} -{" "}
                                {company.company.ticker}
                            </h1>

                            <div className='flex wrap'>
                                <Link
                                    to={`/monte_carlo_dcf?ticker=${company.company.ticker}`}
                                    className='cursor_pointer mr_20px ps_10px text_color_white button_blue'
                                    onClick={() => {
                                        setCompanyLoading();
                                        handleSetBookmark({
                                            ticker: company.company.ticker,
                                            name: company.company.name
                                                ? company.company.name
                                                : null,
                                            industry: company.company.industry
                                                ? company.company.industry
                                                : null,
                                            id: company.company.ticker,
                                            compare: true,
                                        });
                                    }}
                                >
                                    <div>Import in DCF</div>
                                    <MdIcons.MdAreaChart className='ml_10px text_2' />
                                </Link>
                                <CompanyActionsMenu
                                    className='pt_3px'
                                    company={company.company}
                                    type={1}
                                />
                            </div>
                        </div>
                        {company.company.stock_prices !== null ? (
                            <div className='flex align_items_start space_between mt_10px'>
                                <div>
                                    {user.simplified_view
                                        ? "Stock Price: "
                                        : null}
                                    {/* Stock Price */}
                                    {Number(
                                        (
                                            company.company.stock_prices[
                                                company.company.stock_prices
                                                    .length - 1
                                            ][4] -
                                            company.company.stock_prices[
                                                company.company.stock_prices
                                                    .length - 2
                                            ][4]
                                        ).toPrecision(4)
                                    ) >= 0 ? (
                                        <div className='mr_15px text_color_green'>
                                            {Number(
                                                company.company.stock_prices[
                                                    company.company.stock_prices
                                                        .length - 1
                                                ][4]
                                            ).toPrecision(4)}{" "}
                                            USD
                                        </div>
                                    ) : (
                                        <div className='mr_15px text_color_red'>
                                            {Number(
                                                company.company.stock_prices[
                                                    company.company.stock_prices
                                                        .length - 1
                                                ][4]
                                            ).toPrecision(4)}{" "}
                                            USD
                                        </div>
                                    )}
                                </div>

                                <div className='mr_20px'>
                                    {/* Stock Price Change */}
                                    {Number(
                                        (
                                            company.company.stock_prices[
                                                company.company.stock_prices
                                                    .length - 1
                                            ][4] -
                                            company.company.stock_prices[
                                                company.company.stock_prices
                                                    .length - 2
                                            ][4]
                                        ).toPrecision(4)
                                    ) >= 0 ? (
                                        <div className='text_color_green flex right'>
                                            +
                                            {Number(
                                                (
                                                    company.company
                                                        .stock_prices[
                                                        company.company
                                                            .stock_prices
                                                            .length - 1
                                                    ][4] -
                                                    company.company
                                                        .stock_prices[
                                                        company.company
                                                            .stock_prices
                                                            .length - 2
                                                    ][4]
                                                ).toPrecision(4)
                                            )}{" "}
                                            USD ( +
                                            {Number(
                                                (
                                                    (company.company
                                                        .stock_prices[
                                                        company.company
                                                            .stock_prices
                                                            .length - 1
                                                    ][4] /
                                                        company.company
                                                            .stock_prices[
                                                            company.company
                                                                .stock_prices
                                                                .length - 2
                                                        ][4] -
                                                        1) *
                                                    100
                                                ).toPrecision(4)
                                            )}{" "}
                                            %)
                                        </div>
                                    ) : (
                                        <div className='text_color_red flex right'>
                                            {stock_price_change_1d} USD (
                                            {Number(
                                                (
                                                    (company.company
                                                        .stock_prices[
                                                        company.company
                                                            .stock_prices
                                                            .length - 1
                                                    ][4] /
                                                        company.company
                                                            .stock_prices[
                                                            company.company
                                                                .stock_prices
                                                                .length - 2
                                                        ][4] -
                                                        1) *
                                                    100
                                                ).toPrecision(4)
                                            )}{" "}
                                            %)
                                        </div>
                                    )}
                                    {/* Date */}
                                    <div className=''>
                                        {new Intl.DateTimeFormat(
                                            context.locale,
                                            {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                            }
                                        ).format(
                                            company.company.stock_prices[
                                                company.company.stock_prices
                                                    .length - 1
                                            ][0] * 1000
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>

            {/* MENU */}
            <div className='flex mb_20px pt_5px overflow_x_auto scrollbar_invisible border_bottom_1px_solid_grey title_7 text_color_1'>
                <div
                    className={
                        underLine === 1
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(<Overview requiredStatus={0} />);
                        setCompanyOpenTab(1);
                        setUnderLine(1);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.overview",
                    })}
                </div>
                <div
                    className={
                        underLine === 3
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(<Scores requiredStatus={0} />);
                        setCompanyOpenTab(3);
                        setUnderLine(3);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.scores",
                    })}
                </div>
                <div
                    className={
                        underLine === 4
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(<Charts requiredStatus={0} />);
                        setCompanyOpenTab(4);
                        setUnderLine(4);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.charts",
                    })}
                </div>
                <div
                    className={
                        underLine === 5
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(<Figures requiredStatus={0} />);
                        setCompanyOpenTab(5);
                        setUnderLine(5);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.figures",
                    })}
                </div>
                {/* <div
                    className={
                        underLine === 6
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(
                            <Estimates
                                performance={company.company.stock_prices}
                                requiredStatus={1}
                            />
                        );
                        setCompanyOpenTab(6);
                        setUnderLine(6);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.estimates",
                    })}
                </div> */}
                <div
                    className={
                        underLine === 7
                            ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                            : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                    }
                    onClick={() => {
                        setCompanyTab(
                            <Competitors
                                requiredStatus={0}
                                industry={company.company.industry}
                                sector={company.company.sector}
                                competitors={company.company.competitors}
                                competitors_recent={
                                    company.company.competitors_recent
                                }
                            />
                        );
                        setCompanyOpenTab(7);
                        setUnderLine(7);
                    }}
                >
                    {useintl.formatMessage({
                        id: "company.competitors",
                    })}
                </div>
            </div>

            {company.company === null ? <Preloader /> : companyTab}
        </div>
    );
};

Company.propTypes = {
    user: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    bookmark: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setBookmark: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    setCompanyLoading: PropTypes.func.isRequired,
    setCompanyOpenTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    sidebar: state.sidebar,
    watchlist: state.watchlist,
    company: state.company,
    bookmark: state.bookmark,
    user: state.user,
});

export default connect(mapStateToProps, {
    getCompany,
    setBookmark,
    setCompanyLoading,
    setCompanyOpenTab,
})(Company);
