import React, { useEffect, useContext } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import CompaniesTableModal from "../elements/CompaniesTableModal";
import CompaniesTable from "../../tables/companiesSimple/CompaniesTable";
import FeedbackIcon from "../elements/FeedbackIcon";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// ACTIONS
import { getCompetitors } from "../../../actions/companyActions";
import { setDisplayFalseAll } from "../../../actions/screenerAction";

// MUI
import Dialog from "@mui/material/Dialog";

/*Form to save Values to App level State*/

let screenerOptionsForm = {};

const Competitors = ({
    user,
    sector,
    company: {
        company: { gic_sector, gic_group, gic_industry, gic_sub_industry },
        industry,
        competitors,
        competitors_recent,
    },
    screener: { slider, options },
    getCompetitors,
    setDisplayFalseAll,
}) => {
    screenerOptionsForm = options;

    const [underLine, setUnderLine] = React.useState(1);

    useEffect(() => {
        let pagination_competitors = 0;
        if (underLine === 0 && gic_sector !== null) {
            getCompetitors(
                `?gic_sector=${encodeURIComponent(gic_sector)}`,
                options.sortBy.value,
                options.sortBy.direction,
                pagination_competitors
            );
        }
        if (underLine === 1 && gic_group !== null) {
            getCompetitors(
                `?gic_group=${encodeURIComponent(gic_group)}`,
                options.sortBy.value,
                options.sortBy.direction,
                pagination_competitors
            );
        }
        if (underLine === 2 && gic_industry !== null) {
            getCompetitors(
                `?gic_industry=${encodeURIComponent(gic_industry)}`,
                options.sortBy.value,
                options.sortBy.direction,
                pagination_competitors
            );
        }
        if (underLine === 3 && gic_sub_industry !== null) {
            getCompetitors(
                `?gic_sub_industry=${encodeURIComponent(gic_sub_industry)}`,
                options.sortBy.value,
                options.sortBy.direction,
                pagination_competitors
            );
        }
        // eslint-disable-next-line
    }, [underLine]);
    const useintl = useIntl();

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = metrics.sorted_metrics_object;

    let sorted_rankers_object = metrics.sorted_rankers_object;

    let [metricType, setMetricType] = React.useState(0);

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // Slider Search Functionality

    const handlesearchSliderText = (event) => {
        if (event.target.value === "") {
            setSearchSliderObject(SliderData);
        } else {
            let helper = {};
            Object.keys(SliderData).map((slider) => {
                if (
                    SliderData[slider].displayName.includes(
                        event.target.value
                    ) ||
                    SliderData[slider].property.includes(event.target.value) ||
                    SliderData[slider].filter_keywords.includes(
                        event.target.value
                    )
                ) {
                    helper[slider] = SliderData[slider];
                }
            });
            setSearchSliderObject(helper);
        }
    };
    /*Tablecolumns Dialog Field*/
    const [openColumns, setOpenColumns] = React.useState(false);
    const handleClickOpenColumns = () => {
        setOpenColumns(true);
    };
    const handleCloseColumns = () => {
        setOpenColumns(false);
    };
    const [tableStructure, setTableStructure] = React.useState(
        screenerOptionsForm.tableStructure.value
    );
    const handletableStructure = (new_structure) => {
        setTableStructure(new_structure);
        screenerOptionsForm.tableStructure.value = new_structure;
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })} - ${useintl.formatMessage({
            id: "company.competitors",
        })}`,
        location_en: "Company - Competitors",
        location_index: 4,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //     id: "general.company",
    // })} - ${useintl.formatMessage({
    //     id: "company.competitors",
    // })}`,
    //     location_index: 4,
    // };

    return (
        <div>
            {competitors === null ? (
                <Preloader />
            ) : (
                <div className='background_color_1 text_color_1'>
                    <FeedbackIcon feedbackInfo={feedbackInfo} />
                    <div className='flex space_between mt_15px mb_15px mr_15px ml_15px'>
                        <div className='flex left'>
                            <div
                                className={
                                    underLine === 0
                                        ? "page_menu_link transition_350ms cursor_pointer mr_10px text_2 text_color_blue"
                                        : "page_menu_link transition_350ms cursor_pointer mr_10px text_2 text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(0);
                                }}
                            >
                                {gic_sector}
                            </div>
                            <IoIcons5.IoChevronForward />
                            <div
                                className={
                                    underLine === 1
                                        ? "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_blue"
                                        : "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(1);
                                }}
                            >
                                {gic_group}
                            </div>
                            <IoIcons5.IoChevronForward />
                            <div
                                className={
                                    underLine === 2
                                        ? "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_blue"
                                        : "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(2);
                                }}
                            >
                                {gic_industry}
                            </div>
                            <IoIcons5.IoChevronForward />
                            <div
                                className={
                                    underLine === 3
                                        ? "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_blue"
                                        : "page_menu_link transition_350ms cursor_pointer mr_10px ml_10px text_2 text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(3);
                                }}
                            >
                                {gic_sub_industry}
                            </div>
                        </div>
                        <div
                            className='button_blue hover_opacity_80 click_translate text_2 width_80px color_white'
                            onClick={() => {
                                handleClickOpenColumns();
                                setSearchSliderObject(SliderData);
                            }}
                        >
                            <div className='text_3 color_white'>
                                {useintl.formatMessage({
                                    id: "general.table",
                                })}
                            </div>
                            <AiIcons.AiOutlineTable />
                        </div>
                    </div>

                    <div className='divider'></div>

                    <div className='screener_table_wrapper ml_10px_0px mr_10px_0px'>
                        <CompaniesTable
                            companies={competitors}
                            slider={slider}
                            options={options}
                            tableStructure={tableStructure}
                            screenerPagination={0}
                            competitorsType={
                                underLine === 0
                                    ? "gic_sector"
                                    : underLine === 1
                                    ? "gic_group"
                                    : underLine === 2
                                    ? "gic_industry"
                                    : "gic_sub_industry"
                            }
                            competitorsUrl={
                                underLine === 0
                                    ? gic_sector
                                    : underLine === 1
                                    ? gic_group
                                    : underLine === 2
                                    ? gic_industry
                                    : gic_sub_industry
                            }
                        />
                    </div>

                    {/* Table Column Creation */}
                    <Dialog
                        open={openColumns}
                        onClose={handleCloseColumns}
                        fullScreen={true}
                    >
                        <CompaniesTableModal
                            closeModal={handleCloseColumns}
                            slider={slider}
                            options={options}
                            tableStructure={tableStructure}
                            handletableStructure={handletableStructure}
                            metricType={metricType}
                            setMetricType={setMetricType}
                            handlesearchSliderText={handlesearchSliderText}
                            sorted_metrics_object={sorted_metrics_object}
                            sorted_rankers_object={sorted_rankers_object}
                            AllowedMetrics={AllowedMetrics}
                            searchSliderObject={searchSliderObject}
                        />
                    </Dialog>
                </div>
            )}
        </div>
    );
};

Competitors.propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    getCompetitors: PropTypes.func.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    screener: state.screener,
});

export default connect(mapStateToProps, { getCompetitors, setDisplayFalseAll })(
    Competitors
);
