import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import logo from "../../favicon.png";

import TextField from "@mui/material/TextField";

import Alerts from "../layout/Alerts";

// Actions
import { setAlert } from "../../actions/alertActions";
import { requestNewPassword } from "../../actions/userActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

const ForgotPassword = ({ alerts, user, setAlert, requestNewPassword }) => {
    const history = useHistory();
    const useintl = useIntl();
    useEffect(() => {
        if (user.isAuthenticated) {
            history.push("/screener");
        }
        // eslint-disable-next-line
    }, []);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    // EMAIL FORM
    const [values, setValues] = React.useState({
        email: "",
    });

    const { email } = values;

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    // EMAIL VERIFICATION TIMEOUT
    const verification_min_timeout = 30000;
    const [verificationTime, setVerificationTime] = React.useState(0);

    const submitEmail = () => {
        let d = new Date();
        let n = d.getTime();
        console.log(
            n,
            verificationTime,
            n - verificationTime,
            verification_min_timeout
        );
        if (n - verificationTime > verification_min_timeout) {
            setVerificationTime(n);
            if (email === "") {
                setAlert("Please enter you email adress", "danger");
            } else {
                requestNewPassword({
                    email: email,
                });
            }
        }
        // else {
        //     setAlert(
        //         "You should have received an email from us recently containing further instructions.",
        //         "warning"
        //     );
        // }
    };

    return (
        <div className='width_100pr background_color_1 text_color_1'>
            <Helmet>
                <title>{`Forgot Password | StocksOnView`}</title>
                <meta
                    name='description'
                    content='In case you forgot your password, you can reset it here.'
                />
            </Helmet>
            <Alerts />
            <div className='height_50px'> </div>
            <div className='ms_auto mt_50px mb_50px width_350px background_color_2 shadow_dark border_radius_15px'>
                <div className='flex center text_color_1'>
                    <div className='text_color_1 mt_25px'>
                        <img
                            src={logo}
                            alt='Stocks On View Logo'
                            className='width_80px height_80px'
                        />
                    </div>
                </div>
                <div className='flex center mt_40px title_5'>
                    Forgot Password
                </div>
                <div className='flex center mt_15px text_3'>
                    <p>
                        Please submit your email adress and check your mails for
                        instructions
                    </p>
                </div>
                <div className='flex center mt_40px ms_auto'>
                    <TextField
                        className='width_250px pl_10px'
                        id='outlined-basic'
                        label={useintl.formatMessage({
                            id: "general.email",
                        })}
                        variant='outlined'
                        onChange={handleChange("email")}
                    />
                </div>
                <div className='flex center mt_25px'>
                    <a
                        className='button_blue width_250px height_40px'
                        onClick={submitEmail}
                    >
                        Submit
                    </a>
                </div>
                <div className='flex center mt_40px mb_25px'>
                    <Link to={`/login`} className='ml_5px'>
                        Back to Login
                    </Link>
                </div>
                <div className='divider_no_margin'></div>

                <div className='flex center mt_25px pb_25px'>
                    Questions?{" "}
                    <Link to={`/contact_us`} className='ml_5px'>
                        Contact Us
                    </Link>
                </div>
            </div>

            <div className='height_50px'> </div>
        </div>
    );
};

ForgotPassword.propTypes = {
    alerts: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    requestNewPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alerts,
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    requestNewPassword,
})(ForgotPassword);
