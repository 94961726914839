import { SUBMIT_FEEDBACK, SET_ALERT, REMOVE_ALERT } from "./Types";

// Formats big numbers into Strings
export const numberFormatter = (number) => {
    let suffice = ["", "K", "M", "B", "T"];
    for (let i = 0; i < suffice.length; i++) {
        if (Math.abs(Math.round(number)).toString().length < 3 * (i + 1) + 1) {
            return `${(number / 10 ** (3 * i)).toPrecision(3)}${suffice[i]}`;
        }
    }
    return `${(number / 10 ** (3 * (suffice.length - 1))).toPrecision(3)}${
        suffice[suffice.length - 1]
    }`;
};

export const numberFormatterfour = (number) => {
    if (number.toString().length > 4) {
        let suffice = ["", "K", "M", "B", "T"];
        for (let i = 0; i < suffice.length; i++) {
            if (
                Math.abs(Math.round(number)).toString().length <
                3 * (i + 1) + 1
            ) {
                return `${(number / 10 ** (3 * i)).toPrecision(3)}${
                    suffice[i]
                }`;
            }
        }
        return `${(number / 10 ** (3 * (suffice.length - 1))).toPrecision(3)}${
            suffice[suffice.length - 1]
        }`;
    } else {
        return number;
    }
};

// Calculates average metric of a portfolio with weights
export const getAverageMetric = (companies_array, asset_allocation, metric) => {
    let metric_avg = null;
    if (companies_array === null || asset_allocation === null) {
        return 0;
    } else {
        companies_array.map((company, index) => {
            if (asset_allocation[company.ticker] && company[metric] !== null) {
                metric_avg +=
                    company[metric] * asset_allocation[company.ticker].weight;
            }
        });
        return metric_avg !== null ? Number(metric_avg.toFixed(4)) : null;
    }
};

// Calculates the average value of a given array
export const calculateAverageArray = (array) => {
    let not_null_counter = 0;
    let total_value = 0;
    array.map((value) => {
        if (value !== null) {
            not_null_counter += 1;
            total_value += value;
        }
    });
    return total_value / not_null_counter;
};

// SORT AN ARRAY OF OBJECTS BY A KEY CONTAINED IN THE OBJECTS
export const sort_array_of_objects_by_value_number = (array, value) => {
    return array.sort(function (a, b) {
        return a[value] - b[value];
    });
};

// Dechiffres Table html string
const workWithTable = (table) => {
    let new_table = table
        .split("<tr>")
        .slice(1)
        .map((row) =>
            row
                .slice(0, -5)
                .split("<td>")
                .slice(1)
                .map((col) => col.slice(0, -5))
        );
    return new_table;
};

// Truncates String to n charackters
export const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

// Submits Feedbackform
export const submitFeedbackForm = (feedbackObject) => async (dispatch) => {
    try {
        console.log(feedbackObject);
        const res = await fetch("/node_submit_feedback", {
            method: "POST",
            body: JSON.stringify(feedbackObject),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            const id = Math.floor(Math.random() * 100);
            let type = "success";
            let msg = "Your feedback has been submitted!";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occurred while trying to submit your feedback. That's pretty ironic, isn't it?";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};
