import React from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import SliderInfoBox from "../screener/sliderinfo/SliderInfoBox";
import MetricHistogramAsset from "../elements/MetricHistogramAsset";
import FeedbackIcon from "../elements/FeedbackIcon";
import ScreenerPresetScore from "./scores/ScreenerPresetScore";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import Dialog from "@mui/material/Dialog";

// ACTIONS
import { numberFormatter } from "../../../actions/generalActions";

/*Problemtofix: Mit Component und Loop geht nur bei relative Scores, Absolute müssen aufgrund von wechselnden Punktekriterien mit Grid geschrieben werden*/

const Scores = ({ company: { company }, screener: { presets } }) => {
    const useintl = useIntl();
    const [score, setOpenScore] = React.useState({
        piotroski: false,
        varanaut_relative: false,
        altman_z: false,
        beneish_m: false,
    });
    const handleOpenScore = (value) => {
        setOpenScore({ ...score, [value]: !score[value] });
    };

    /*Sliders Information Modal*/
    const [openInfo, setOpenInfo] = React.useState(false);
    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };
    const handleCloseInfo = () => {
        setOpenInfo(false);
    };
    const [infoScore, setInfoScore] = React.useState("piotroski_score");
    const handleInfoScore = (score) => {
        setInfoScore(score);
    };

    const NULLIF = (value, fall_back) => {
        return !isNaN(value) ? value : fall_back;
    };

    // PIOTROSKI SCORE
    let PiotroskiFScore = [
        {
            name: useintl.formatMessage({
                id: `scores.profitability`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_1`,
                    }),
                    raw_value:
                        company.return_on_assets !== null
                            ? Number(company.return_on_assets.toPrecision(4))
                            : null,
                    value:
                        company.return_on_assets !== null
                            ? `${Number(
                                  company.return_on_assets.toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.return_on_assets !== null
                            ? company.return_on_assets > 0
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.return_on_assets`,
                    }),
                    histogram_value: "return_on_assets",
                    histogram_unit: "%",
                    histogram_change: false,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_2`,
                    }),
                    raw_value:
                        company.free_cashflow !== null
                            ? Number(company.free_cashflow.toPrecision(4))
                            : null,
                    value:
                        company.free_cashflow !== null
                            ? `${numberFormatter(
                                  Number(company.free_cashflow.toPrecision(4))
                              )} USD`
                            : "- USD",
                    crit:
                        company.free_cashflow !== null
                            ? company.free_cashflow > 0
                            : false,
                    histogram_name: null,
                    histogram_value: null,
                    histogram_unit: null,
                    histogram_change: null,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_3`,
                    }),
                    raw_value:
                        company.return_on_assets_change !== null
                            ? Number(
                                  (
                                      (company.return_on_assets_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.return_on_assets_change !== null
                            ? `${Number(
                                  (
                                      (company.return_on_assets_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.return_on_assets_change !== null
                            ? company.return_on_assets_change >= 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.return_on_assets_change`,
                    }),
                    histogram_value: "return_on_assets_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_4`,
                    }),
                    raw_value: null,
                    value:
                        (company.free_cashflow !== null &&
                        company.total_assets !== null
                            ? `${Number(
                                  (
                                      (company.free_cashflow /
                                          company.total_assets) *
                                      100
                                  ).toPrecision(4)
                              )} % to `
                            : "- % to ") +
                        (company.return_on_assets !== null
                            ? `${Number(
                                  company.return_on_assets.toPrecision(4)
                              )} %`
                            : "- %"),
                    crit:
                        company.free_cashflow !== null &&
                        company.total_assets !== null &&
                        company.return_on_assets !== null
                            ? (100 * company.free_cashflow) /
                                  company.total_assets >
                              company.return_on_assets
                            : false,
                    histogram_name: null,
                    histogram_value: null,
                    histogram_unit: null,
                    histogram_change: null,
                },
            ],
        },
        {
            name: useintl.formatMessage({
                id: `scores.leverage_liquidity_source_of_funds`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_5`,
                    }),
                    raw_value:
                        company.debt_to_equity_change !== null
                            ? Number(
                                  (
                                      (company.debt_to_equity_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.debt_to_equity_change !== null
                            ? `${Number(
                                  (
                                      (company.debt_to_equity_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.debt_to_equity_change !== null
                            ? company.debt_to_equity_change < 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.debt_to_equity_change`,
                    }),
                    histogram_value: "debt_to_equity_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_6`,
                    }),
                    raw_value:
                        company.current_ratio_change !== null
                            ? Number(
                                  (
                                      (company.current_ratio_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.current_ratio_change !== null
                            ? `${Number(
                                  (
                                      (company.current_ratio_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.current_ratio_change !== null
                            ? company.current_ratio_change >= 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.current_ratio_change`,
                    }),
                    histogram_value: "current_ratio_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_7`,
                    }),
                    raw_value:
                        company.outstanding_shares_change === null
                            ? null
                            : Number(
                                  (
                                      (company.outstanding_shares_change - 1) *
                                      100
                                  ).toFixed(2)
                              ),
                    value:
                        company.outstanding_shares_change === null
                            ? "-"
                            : company.outstanding_shares_change < 1
                            ? `${Number(
                                  Math.abs(
                                      (1 - company.outstanding_shares_change) *
                                          100
                                  ).toFixed(2)
                              )}%` +
                              " " +
                              `${useintl.formatMessage({
                                  id: `scores.of_shares_bought_back`,
                              })}`
                            : `${Number(
                                  Math.abs(
                                      (company.outstanding_shares_change - 1) *
                                          100
                                  ).toFixed(2)
                              )}%` +
                              " " +
                              `${useintl.formatMessage({
                                  id: `scores.new_shares_emitted`,
                              })}`,
                    crit:
                        company.outstanding_shares_change !== null
                            ? company.outstanding_shares_change <= 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.shares_outstanding_change`,
                    }),
                    histogram_value: "outstanding_shares_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
            ],
        },
        {
            name: useintl.formatMessage({
                id: `scores.operating_efficiency`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_8`,
                    }),
                    raw_value:
                        company.gross_profit_margin_change !== null
                            ? Number(
                                  (
                                      (company.gross_profit_margin_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.gross_profit_margin_change !== null
                            ? `${Number(
                                  (
                                      (company.gross_profit_margin_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.gross_profit_margin_change !== null
                            ? company.gross_profit_margin_change >= 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.gross_profit_margin_change`,
                    }),
                    histogram_value: "gross_profit_margin_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
                {
                    name: useintl.formatMessage({
                        id: `scores.piotroski_9`,
                    }),
                    raw_value:
                        company.asset_turnover_change !== null
                            ? Number(
                                  (
                                      (company.asset_turnover_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.asset_turnover_change !== null
                            ? `${Number(
                                  (
                                      (company.asset_turnover_change - 1) *
                                      100
                                  ).toPrecision(4)
                              )} %`
                            : "- %",
                    crit:
                        company.asset_turnover_change !== null
                            ? company.asset_turnover_change >= 1
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.asset_turnover_change`,
                    }),
                    histogram_value: "asset_turnover_change",
                    histogram_unit: "%",
                    histogram_change: true,
                },
            ],
        },
    ];
    let piotroski_values = [
        company.return_on_assets > 0 ? 1 : -1,
        company.free_cashflow > 0 ? 1 : -1,
        company.return_on_assets_change - 1,
        company.free_cashflow !== null && company.total_assets !== null
            ? (company.free_cashflow / company.total_assets) * 100 -
              company.return_on_assets
            : -1,
        company.debt_to_equity_change !== null
            ? 1 - company.debt_to_equity_change
            : -1,
        company.current_ratio_change - 1,
        1 - company.outstanding_shares_change,
        company.gross_profit_margin_change - 1,
        company.asset_turnover_change - 1,
    ];
    let piotroski_total = 0;
    piotroski_values.map((value) =>
        value >= 0 ? (piotroski_total += 1) : null
    );

    // RELATIVE SCORE
    let VaranautRelativeScore = [
        {
            name: useintl.formatMessage({
                id: `scores.valuation`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `metrics.price_book_ranker`,
                    }),
                    raw_value:
                        company.price_book_ranker !== null
                            ? Number(
                                  (100 * company.price_book_ranker).toPrecision(
                                      4
                                  )
                              )
                            : null,
                    value:
                        company.price_book_ranker !== null
                            ? `${Number(
                                  (100 * company.price_book_ranker).toPrecision(
                                      4
                                  )
                              )}`
                            : "-",
                    crit:
                        company.price_book_ranker !== null
                            ? company.price_book_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.price_book_ranker`,
                    }),
                    histogram_value: "price_book_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
                {
                    name: useintl.formatMessage({
                        id: `metrics.price_sales_ranker`,
                    }),
                    raw_value:
                        company.price_sales_ranker !== null
                            ? Number(
                                  (
                                      100 * company.price_sales_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.price_sales_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.price_sales_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.price_sales_ranker !== null
                            ? company.price_sales_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.price_sales_ranker`,
                    }),
                    histogram_value: "price_sales_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
            ],
        },
        {
            name: useintl.formatMessage({
                id: `scores.profitability`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `metrics.asset_turnover_ranker`,
                    }),
                    raw_value:
                        company.asset_turnover_ranker !== null
                            ? Number(
                                  (
                                      100 * company.asset_turnover_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.asset_turnover_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.asset_turnover_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.asset_turnover_ranker !== null
                            ? company.asset_turnover_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.asset_turnover_ranker`,
                    }),
                    histogram_value: "asset_turnover_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
                {
                    name: useintl.formatMessage({
                        id: `metrics.gross_profit_margin_ranker`,
                    }),
                    raw_value:
                        company.gross_profit_margin_ranker !== null
                            ? Number(
                                  (
                                      100 * company.gross_profit_margin_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.gross_profit_margin_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.gross_profit_margin_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.gross_profit_margin_ranker !== null
                            ? company.gross_profit_margin_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.gross_profit_margin_ranker`,
                    }),
                    histogram_value: "gross_profit_margin_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
            ],
        },
        {
            name: useintl.formatMessage({
                id: `scores.growth`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `metrics.revenue_growth_1y_ranker`,
                    }),
                    raw_value:
                        company.revenue_growth_1y_ranker !== null
                            ? Number(
                                  (
                                      100 * company.revenue_growth_1y_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.revenue_growth_1y_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.revenue_growth_1y_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.revenue_growth_1y_ranker !== null
                            ? company.revenue_growth_1y_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.revenue_growth_1y_ranker`,
                    }),
                    histogram_value: "revenue_growth_1y_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
                {
                    name: useintl.formatMessage({
                        id: `metrics.revenue_growth_3y_ranker`,
                    }),
                    raw_value:
                        company.revenue_growth_3y_ranker !== null
                            ? Number(
                                  (
                                      100 * company.revenue_growth_3y_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.revenue_growth_3y_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.revenue_growth_3y_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.revenue_growth_3y_ranker !== null
                            ? company.revenue_growth_3y_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.revenue_growth_3y_ranker`,
                    }),
                    histogram_value: "revenue_growth_3y_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
            ],
        },
        {
            name: useintl.formatMessage({
                id: `scores.debt`,
            }),
            values: [
                {
                    name: useintl.formatMessage({
                        id: `metrics.debt_to_equity_ranker`,
                    }),
                    raw_value:
                        company.debt_to_equity_ranker !== null
                            ? Number(
                                  (
                                      100 * company.debt_to_equity_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.debt_to_equity_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.debt_to_equity_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.debt_to_equity_ranker !== null
                            ? company.debt_to_equity_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.debt_to_equity_ranker`,
                    }),
                    histogram_value: "debt_to_equity_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
                {
                    name: useintl.formatMessage({
                        id: `metrics.current_ratio_ranker`,
                    }),
                    raw_value:
                        company.current_ratio_ranker !== null
                            ? Number(
                                  (
                                      100 * company.current_ratio_ranker
                                  ).toPrecision(4)
                              )
                            : null,
                    value:
                        company.current_ratio_ranker !== null
                            ? `${Number(
                                  (
                                      100 * company.current_ratio_ranker
                                  ).toPrecision(4)
                              )}`
                            : "-",
                    crit:
                        company.current_ratio_ranker !== null
                            ? company.current_ratio_ranker > 2 / 3
                            : false,
                    histogram_name: useintl.formatMessage({
                        id: `metrics.current_ratio_ranker`,
                    }),
                    histogram_value: "current_ratio_ranker",
                    histogram_unit: "",
                    histogram_change: false,
                },
            ],
        },
    ];
    let varanaut_relative_values = [
        company.price_book_ranker,
        company.price_sales_ranker,
        company.asset_turnover_ranker,
        company.gross_profit_margin_ranker,
        company.revenue_growth_1y_ranker,
        company.revenue_growth_3y_ranker,
        company.debt_to_equity_ranker,
        company.current_ratio_ranker,
    ];
    let varanaut_relative_total = 0;
    varanaut_relative_values.map((value) =>
        value > 2 / 3 ? (varanaut_relative_total += 1) : null
    );

    const [histogram, setHistogram] = React.useState(
        PiotroskiFScore[0].values[0]
    );
    const handleHistogram = (metric) => {
        setHistogram(metric);
    };

    /*Sliders Histogram Field*/
    const [openHistogram, setOpenHistogram] = React.useState(false);
    const handleClickOpenHistogram = () => {
        setOpenHistogram(true);
    };
    const handleCloseHistogram = () => {
        //setValue(slider[sliderprops.property].value);
        setOpenHistogram(false);
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })} - ${useintl.formatMessage({
            id: "company.scores",
        })}`,
        location_en: "Company - Scores",
        location_index: 4,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //     id: "general.company",
    // })} - ${useintl.formatMessage({
    //     id: "company.scores",
    // })}`,
    //     location_index: 4,
    // };

    let scores_props = {
        piotroski_score: {
            property: "piotroski_score",
            default: 5,
            min: 1,
            max: 9,
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.piotroski_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.piotroski_score",
            }),
            unit: null,
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.piotroski_score.description_text_1",
            }),
        },
        relative_score: {
            property: "relative_score",
            default: 5,
            min: 1,
            max: 9,
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.relative_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.relative_score",
            }),
            unit: null,
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.relative_score.description_text_1",
            }),
        },
        altman_z_score: {
            property: "altman_z_score",
            default: 5,
            min: 1,
            max: 9,
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.altman_z_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.altman_z_score",
            }),
            unit: null,
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.altman_z_score.description_text_1",
            }),
        },
        beneish_m_score: {
            property: "beneish_m_score",
            default: 5,
            min: 1,
            max: 9,
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.beneish_m_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.beneish_m_score",
            }),
            unit: null,
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.beneish_m_score.description_text_1",
            }),
        },
    };

    return (
        <div>
            {company.company === null ? (
                <Preloader />
            ) : (
                <div className='text_color_1'>
                    <FeedbackIcon feedbackInfo={feedbackInfo} />
                    <div className='flex align_items_start wrap left width_100pr pl_20px_0px pr_20px_0px mt_20px mb_20px'>
                        {/* PIOTROSKI F SCORE */}
                        <div className='shadow_dark background_color_3 width_30pr_100pr min_width_320px max_width_500px border_radius_10px bg_color_white ml_20px_0px mr_20px_0px mb_20px'>
                            {/* HEADER */}
                            <div className='company_scores_piotrosky_header_box'>
                                <div className='title_5 flex center'>
                                    <div>Piotroski F-Score</div>
                                    <AiIcons.AiOutlineInfoCircle
                                        className='text_2 mt_5px cursor_pointer ml_10px'
                                        onClick={() => {
                                            handleInfoScore("piotroski_score");
                                            handleClickOpenInfo();
                                        }}
                                    />
                                </div>
                                {piotroski_total > 6 ? (
                                    <div className='text_color_green text_1 center'>{`${piotroski_total}/9`}</div>
                                ) : piotroski_total > 3 ? (
                                    <div className='text_color_yellow text_1 center'>{`${piotroski_total}/9`}</div>
                                ) : (
                                    <div className='text_color_red text_1 center'>{`${piotroski_total}/9`}</div>
                                )}
                                <div className='center'>
                                    {score.piotroski ? (
                                        <IoIcons5.IoChevronUpSharp
                                            className='cursor_pointer'
                                            onClick={() => {
                                                handleOpenScore("piotroski");
                                            }}
                                        />
                                    ) : (
                                        <IoIcons5.IoChevronDownSharp
                                            className='cursor_pointer center'
                                            onClick={() => {
                                                handleOpenScore("piotroski");
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* CATEGORIES AND SUBCATEGORIES */}
                            {score.piotroski ? (
                                <div className='ps_10px background_color_3'>
                                    {PiotroskiFScore.map((subScore) => (
                                        <div className='compay_score_body_section'>
                                            <div className='text_2 center mt_15px'>
                                                {subScore.name}
                                            </div>
                                            <div className='company_score_divider_thicc'></div>
                                            <div className=''>
                                                {subScore.values.map(
                                                    (item, index) => (
                                                        <div>
                                                            {index !== 0 ? (
                                                                <div className='company_score_divider_thin'></div>
                                                            ) : null}
                                                            <div className='compay_score_body_section_body_grid mt_5px mb_10px'>
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                                <div className='ml_10px'>
                                                                    {item.value}
                                                                </div>
                                                                <div>
                                                                    {item.crit ? (
                                                                        <IoIcons5.IoCheckmarkSharp className='text_color_green' />
                                                                    ) : (
                                                                        <IoIcons5.IoCloseSharp className='text_color_red' />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.histogram_name !==
                                                                    null ? (
                                                                        <AiIcons.AiOutlineFullscreen
                                                                            className='screener_icon_widen cursor_pointer'
                                                                            onClick={() => {
                                                                                handleHistogram(
                                                                                    item
                                                                                );
                                                                                handleClickOpenHistogram();
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    {/* <AiIcons.AiOutlineInfoCircle
                                                                        className='screener_icon_info cursor_pointer'
                                                                        // onClick={handleClickOpenInfo}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>

                        {/* RELATIVE SCORE */}
                        <div className='shadow_dark background_color_3 width_30pr_100pr min_width_320px max_width_500px border_radius_10px bg_color_white ml_20px_0px mr_20px_0px mb_20px'>
                            <div className='company_scores_piotrosky_header_box'>
                                <div className='title_5 flex center'>
                                    <div>Relative Score</div>
                                    <AiIcons.AiOutlineInfoCircle
                                        className='text_2 mt_5px cursor_pointer ml_10px'
                                        onClick={() => {
                                            handleInfoScore("relative_score");
                                            handleClickOpenInfo();
                                        }}
                                    />
                                </div>
                                {varanaut_relative_total > 5 ? (
                                    <div className='text_color_green text_1 center'>{`${varanaut_relative_total}/8`}</div>
                                ) : varanaut_relative_total > 3 ? (
                                    <div className='text_color_yellow text_1 center'>{`${varanaut_relative_total}/8`}</div>
                                ) : (
                                    <div className='text_color_red text_1 center'>{`${varanaut_relative_total}/8`}</div>
                                )}
                                <div className='center'>
                                    {score.varanaut_relative ? (
                                        <IoIcons5.IoChevronUpSharp
                                            className='cursor_pointer'
                                            onClick={() => {
                                                handleOpenScore(
                                                    "varanaut_relative"
                                                );
                                            }}
                                        />
                                    ) : (
                                        <IoIcons5.IoChevronDownSharp
                                            className='cursor_pointer center'
                                            onClick={() => {
                                                handleOpenScore(
                                                    "varanaut_relative"
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            {score.varanaut_relative ? (
                                <div className='ps_10px background_color_3'>
                                    {VaranautRelativeScore.map((subScore) => (
                                        <div className='compay_score_body_section'>
                                            <div className='text_2 center mt_15px'>
                                                {subScore.name}
                                            </div>
                                            <div className='company_score_divider_thicc'></div>
                                            <div className=''>
                                                {subScore.values.map(
                                                    (item, index) => (
                                                        <div>
                                                            {index !== 0 ? (
                                                                <div className='company_score_divider_thin'></div>
                                                            ) : null}
                                                            <div className='compay_score_body_section_body_grid mt_5px mb_10px'>
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                                <div className='ml_10px'>
                                                                    {item.value}{" "}
                                                                    %
                                                                </div>
                                                                <div>
                                                                    {item.crit ? (
                                                                        <IoIcons5.IoCheckmarkSharp className='text_color_green' />
                                                                    ) : (
                                                                        <IoIcons5.IoCloseSharp className='text_color_red' />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.histogram_name !==
                                                                    null ? (
                                                                        <AiIcons.AiOutlineFullscreen
                                                                            className='screener_icon_widen cursor_pointer'
                                                                            onClick={() => {
                                                                                handleHistogram(
                                                                                    item
                                                                                );
                                                                                handleClickOpenHistogram();
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    {/* <AiIcons.AiOutlineInfoCircle
                                                                        className='screener_icon_info cursor_pointer'
                                                                        // onClick={handleClickOpenInfo}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>

                        {/* ALTMAN Z SCORE */}
                        {company.altman_z_score ? (
                            <div className='shadow_dark background_color_3 width_30pr_100pr min_width_320px max_width_500px border_radius_10px bg_color_white ml_20px_0px mr_20px_0px mb_20px'>
                                <div className='company_scores_piotrosky_header_box'>
                                    <div className='title_5 flex center'>
                                        <div>Altman Z-Score</div>
                                        <AiIcons.AiOutlineInfoCircle
                                            className='text_2 mt_5px cursor_pointer ml_10px'
                                            onClick={() => {
                                                handleInfoScore(
                                                    "altman_z_score"
                                                );
                                                handleClickOpenInfo();
                                            }}
                                        />
                                    </div>
                                    {company.altman_z_score > 2.99 ? (
                                        <div className='text_color_green text_1 center'>{`${Number(
                                            company.altman_z_score.toFixed(3)
                                        )}`}</div>
                                    ) : company.altman_z_score > 1.81 ? (
                                        <div className='text_color_yellow text_1 center'>{`${Number(
                                            company.altman_z_score.toFixed(3)
                                        )}`}</div>
                                    ) : (
                                        <div className='text_color_red text_1 center'>{`${Number(
                                            company.altman_z_score.toFixed(3)
                                        )}`}</div>
                                    )}
                                    <div className='center height_23px'></div>
                                </div>
                            </div>
                        ) : null}

                        {/* BENEISH M SCORE */}
                        {company.beneish_m_score ? (
                            <div className='shadow_dark background_color_3 width_30pr_100pr min_width_320px max_width_500px border_radius_10px bg_color_white ml_20px_0px mr_20px_0px mb_20px'>
                                <div className='company_scores_piotrosky_header_box'>
                                    <div className='title_5 flex center'>
                                        <div>Beneish M-Score</div>
                                        <AiIcons.AiOutlineInfoCircle
                                            className='text_2 mt_5px cursor_pointer ml_10px'
                                            onClick={() => {
                                                handleInfoScore(
                                                    "beneish_m_score"
                                                );
                                                handleClickOpenInfo();
                                            }}
                                        />
                                    </div>
                                    {company.beneish_m_score < -1.78 ? (
                                        <div className='text_color_green text_1 center'>{`${Number(
                                            company.beneish_m_score.toFixed(3)
                                        )}`}</div>
                                    ) : (
                                        <div className='text_color_red text_1 center'>{`${Number(
                                            company.beneish_m_score.toFixed(3)
                                        )}`}</div>
                                    )}
                                    <div className='center height_23px'></div>
                                </div>
                            </div>
                        ) : null}

                        {presets
                            ? Object.values(presets).map((preset) => {
                                  return (
                                      <ScreenerPresetScore
                                          preset={preset}
                                          company={company}
                                          handleHistogram={handleHistogram}
                                          handleClickOpenHistogram={
                                              handleClickOpenHistogram
                                          }
                                      />
                                  );
                              })
                            : null}

                        {/* EXPLANATION */}
                        <Dialog
                            open={openInfo}
                            onClose={handleCloseInfo}
                            maxWidth={false}
                            className='slider_info_wrapper'
                        >
                            <div className='border_box text_color_1 background_color_4'>
                                <div className='slider_info_header title_5'>
                                    <div className='flex'>
                                        <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px' />
                                        {/* {sliderprops.displayName} */}
                                        {useintl.formatMessage({
                                            id: `scores.${infoScore}`,
                                        })}
                                    </div>
                                    <AiIcons.AiOutlineClose
                                        className='title_5 cursor_pointer'
                                        onClick={handleCloseInfo}
                                    />
                                </div>

                                <SliderInfoBox
                                    sliderprops={scores_props[infoScore]}
                                />

                                {/* <div className='divider_no_margin'></div>
                                <div className='slider_info_disclaimer margin_10px'>
                                    <p>
                                        To get an <b>overview</b> on typical values for this
                                        metric, click{" "}
                                        <b
                                            className='cursor_pointer'
                                            onClick={() => {
                                                handleCloseInfo();
                                                handleClickOpenHistogram();
                                            }}
                                        >
                                            here
                                        </b>
                                    </p>
                                </div> */}
                            </div>
                        </Dialog>
                    </div>

                    {/* HISTOGRAM */}
                    <Dialog
                        open={openHistogram}
                        onClose={handleCloseHistogram}
                        maxWidth={false}
                        className='slider_info_wrapper'
                    >
                        <div className='slider_info_container text_color_1 background_color_4'>
                            <div className='slider_info_header title_5'>
                                <div className='flex'>
                                    <RiIcons.RiBarChartBoxLine className='title_5 mr_10px mt_5px' />
                                    {histogram.histogram_name}
                                </div>
                                <AiIcons.AiOutlineClose
                                    className='title_5 cursor_pointer'
                                    onClick={handleCloseHistogram}
                                />
                            </div>
                            <MetricHistogramAsset histogramprops={histogram} />
                        </div>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

Scores.propTypes = {
    company: PropTypes.object.isRequired,
    presets: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
    screener: state.screener,
});

export default connect(mapStateToProps, null)(Scores);
