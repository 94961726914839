import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// REDUX
import { Provider } from "react-redux";
import store from "./store";

// ERRORS
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./components/errors/Fallback";

// LANDING PAGE / AUTH
import Login from "../src/components/auth/Login";
import LoginDemoRedirect from "./components/auth/LoginDemoRedirect";
import Register from "../src/components/auth/Register";
import ForgotPassword from "../src/components/auth/ForgotPassword";
import EmailVerification from "./components/auth/EmailVerification";

import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./utils/PrivateRoute";

// STRIPE
// import { Elements, PaymentElement } from '@stripe/react-stripe-js';
// import { loadStripe } from "@stripe/stripe-js";

// LEGAL
import TermsOfService from "./components/legal/TermsOfService";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import AboutUs from "./components/legal/AboutUs";
import ContactUs from "./components/legal/ContactUs";

// DASHBOARD
import Alerts from "./components/layout/Alerts";
import Menu from "../src/components/layout/Menu";
import PortfoliosMenu from "../src/components/pages/portfolio/PortfoliosMenu";
import PortfolioCreator from "../src/components/pages/portfolio/PortfolioCreator";
import WatchlistsMenu from "../src/components/pages/watchlist/WatchlistsMenu";
import Account from "./components/pages/account/Account";
import Screener from "../src/components/pages/screener/Screener";
import Market from "../src/components/pages/market/Market";
import Compare from "../src/components/pages/compare/Compare";
import Company from "../src/components/pages/company/Company";
import MonteCarloDCF from "./components/pages/dcf/MonteCarloDCF";

// CSS
import "./stylesheets/Main.css";
import "./stylesheets/PortfolioCreator.css";
import "./stylesheets/Portfolio.css";
import "./stylesheets/Screener.css";
import "./stylesheets/Company.css";
import "./stylesheets/Materialize.css";

// CONTEXT
import LocaleContext from "./context/LocaleContext";
import ThemeContext from "./context/ThemeContext";
import { MetricsProvider } from "./context/MetricsContext";

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const errorHandler = (error, errorInfo) => {
    console.log(error, errorInfo);
};

const helmetContext = {};

const App = () => {
    return (
        <Provider store={store}>
            {/* <Elements stripe = {stripePromise} options = {options}> */}
            <LocaleContext>
                <ThemeContext>
                    <MetricsProvider>
                        <ErrorBoundary
                            FallbackComponent={Fallback}
                            onError={errorHandler}
                        >
                            <HelmetProvider context={helmetContext}>
                                <Router>
                                    <Fragment>
                                        <Switch></Switch>
                                    </Fragment>
                                    <Fragment>
                                        <Menu />
                                        <Alerts />
                                        <Switch>
                                            <Route
                                                path='/login'
                                                component={Login}
                                            />
                                            <Route
                                                path='/demologin'
                                                component={LoginDemoRedirect}
                                            />
                                            <Route
                                                path='/register'
                                                component={Register}
                                            />
                                            <Route
                                                path='/forgot_password'
                                                component={ForgotPassword}
                                            />
                                            <Route
                                                path='/verification'
                                                component={EmailVerification}
                                            />
                                            <Route
                                                path='/terms_of_service'
                                                component={TermsOfService}
                                            />
                                            <Route
                                                path='/privacy_policy'
                                                component={PrivacyPolicy}
                                            />
                                            <Route
                                                path='/about_us'
                                                component={AboutUs}
                                            />
                                            <Route
                                                path='/contact_us'
                                                component={ContactUs}
                                            />
                                            <Route
                                                path='/fucking_shit_error'
                                                component={Fallback}
                                            />

                                            <PrivateRoute
                                                path='/portfolio'
                                                component={PortfoliosMenu}
                                            />
                                            <PrivateRoute
                                                path='/creator'
                                                component={PortfolioCreator}
                                            />
                                            <PrivateRoute
                                                path='/watchlist'
                                                component={WatchlistsMenu}
                                            />
                                            <PrivateRoute
                                                path='/account'
                                                component={Account}
                                            />
                                            <PrivateRoute
                                                path='/screener'
                                                component={Screener}
                                            />
                                            <PrivateRoute
                                                path='/compare'
                                                component={Compare}
                                            />
                                            <PrivateRoute
                                                path='/market'
                                                component={Market}
                                            />
                                            <PrivateRoute
                                                path='/company'
                                                component={Company}
                                            />
                                            <PrivateRoute
                                                path='/monte_carlo_dcf'
                                                component={MonteCarloDCF}
                                            />
                                            <PrivateRoute
                                                path='/'
                                                component={Market}
                                            />
                                            {/* <Route
                                                path='*'
                                                component={PageNotFound}    
                                            /> */}
                                        </Switch>
                                    </Fragment>
                                </Router>
                            </HelmetProvider>
                        </ErrorBoundary>
                    </MetricsProvider>
                </ThemeContext>
            </LocaleContext>
            {/* </Elements> */}
        </Provider>
    );
};

export default App;
