import React from "react";
import * as AiIcons from "react-icons/ai";
import styled from "styled-components";

import { Link } from "react-router-dom";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import {
    clearBookmark,
    updateBookmark,
} from "../../../actions/bookmarkActions";

import { setCompanyTicker } from "../../../actions/companyActions";

const BookmarkTickerFlag = styled.div`
    border-radius: 4px 0px 0px 4px;
    height: 28.5px;
    width: 5px;
    background-color: #376fd0;
`;
// background-color: ${({ item }) => (item.compare ? "#376FD0" : "#233044")};

const BookmarkItem = ({
    item,
    clearBookmark,
    updateBookmark,
    setCompanyTicker,
}) => {
    const onDelete = () => {
        clearBookmark(item.id);
    };
    // const onUpdate = () => {
    //     const updBookmark = {
    //         id: item.id,
    //         ticker: item.ticker,
    //         compare: !item.compare,
    //     };

    //     updateBookmark(updBookmark);
    // };

    const [onmouse, setOnmouse] = React.useState(false);

    const handleMousein = () => {
        setOnmouse(true);
    };
    const handleMouseout = () => {
        setOnmouse(false);
    };

    return (
        <div className='height_100pr'>
            <div
                className='height_80pr border_radius_5px height_30px border_box width_85px flex space_between ml_5px mt_5px mb_5px background_color_3 hover_opacity_80'
                onMouseOver={handleMousein}
                onMouseLeave={handleMouseout}
            >
                <Link
                    to={`/company?ticker=${item.ticker}`}
                    onClick={() => {
                        setCompanyTicker(item.ticker);
                    }}
                    className='flex left'
                >
                    <BookmarkTickerFlag item={item} />
                    <div
                        className='ml_5px cursor_pointer text_color_1'
                        // onClick={onUpdate}
                    >
                        {item.ticker}
                    </div>
                </Link>
                <div
                    className='mt_4px mr_2px text_2 text_color_1 cursor_pointer'
                    onClick={onDelete}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            {onmouse && item.name ? (
                <div className='pos_absolute ml_10px text_color_1 background_color_2 padding_5px'>
                    {item.ticker} | {item.name}
                </div>
            ) : null}
        </div>
    );
};

BookmarkItem.propTypes = {
    bookmark: PropTypes.object.isRequired,
    clearBookmark: PropTypes.func.isRequired,
    updateBookmark: PropTypes.func.isRequired,
    setCompanyTicker: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    bookmark: state.bookmark,
});

export default connect(mapStateToProps, {
    clearBookmark,
    updateBookmark,
    setCompanyTicker,
})(BookmarkItem);
