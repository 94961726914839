import React, { useEffect } from "react";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { numberFormatter } from "../../../actions/generalActions";

// Components
import SectorTableRowColumn from "./SectorTableRowColumn";
import CompanyActionsMenu from "../../pages/elements/CompanyActionsMenu";

/*Dialog*/
import Dialog from "@mui/material/Dialog";

const SectorTableRow = ({ sector, sector_object, metrics }) => {
    let sector_groups = [];
    let gic_sector_code = 10;

    Object.keys(sector_object).map((gic_sector) => {
        if (sector_object[gic_sector].name === sector.sector) {
            // console.log(sector_object[sector_idx].industries)
            sector_groups = sector_object[gic_sector].contains;
            console.log("gic_sector: ", gic_sector);
            gic_sector_code = gic_sector;
        }
    });

    useEffect(() => {
        Object.keys(sector_object).map((gic_sector) => {
            if (sector_object[gic_sector].name === sector.sector) {
                // console.log(sector_object[sector_idx].industries)
                sector_groups = sector_object[gic_sector].contains;
                console.log("gic_sector: ", gic_sector);
                gic_sector_code = gic_sector;
            }
        });
        // eslint-disable-next-line
    }, []);

    /*Sector information Dialog Field*/
    const [openSectorInfo, setOpenSectorInfo] = React.useState(false);
    const handleClickOpenSectorInfo = () => {
        setOpenSectorInfo(true);
    };
    const handleCloseSectorInfo = () => {
        setOpenSectorInfo(false);
    };

    const handleIndustryToFilter = (gic_key) => {
        let industries_to_filter = [];
        let gic_key_string = gic_key.toString();
        let gic_key_string_length = gic_key_string.length;

        let gic_key_sector_string = gic_key.toString().substring(0, 2);
        let gic_key_group_string = gic_key.toString().substring(0, 4);
        let gic_key_industry_string = gic_key.toString().substring(0, 6);

        Object.keys(sector_object).map((gic_sector) => {
            Object.keys(sector_object[gic_sector].contains).map((gic_group) => {
                Object.keys(
                    sector_object[gic_sector].contains[gic_group].contains
                ).map((gic_industry) => {
                    Object.keys(
                        sector_object[gic_sector].contains[gic_group].contains[
                            gic_industry
                        ].contains
                    ).map((gic_sub_industry) => {
                        if (
                            gic_key_string_length === 2 &&
                            gic_key_sector_string ===
                                sector_object[gic_sector].code.toString()
                        ) {
                            console.log(2);
                            industries_to_filter.push(
                                sector_object[gic_sector].contains[gic_group]
                                    .contains[gic_industry].contains[
                                    gic_sub_industry
                                ].name
                            );
                        }

                        if (
                            gic_key_string_length === 4 &&
                            gic_key_group_string ===
                                sector_object[gic_sector].contains[
                                    gic_group
                                ].code.toString()
                        ) {
                            console.log(4);
                            industries_to_filter.push(
                                sector_object[gic_sector].contains[gic_group]
                                    .contains[gic_industry].contains[
                                    gic_sub_industry
                                ].name
                            );
                        }

                        if (
                            gic_key_string_length === 6 &&
                            gic_key_industry_string ===
                                sector_object[gic_sector].contains[
                                    gic_group
                                ].contains[gic_industry].code.toString()
                        ) {
                            console.log(6);
                            industries_to_filter.push(
                                sector_object[gic_sector].contains[gic_group]
                                    .contains[gic_industry].contains[
                                    gic_sub_industry
                                ].name
                            );
                        }

                        if (
                            gic_key_string_length === 8 &&
                            gic_key_string ===
                                sector_object[gic_sector].contains[
                                    gic_group
                                ].contains[gic_industry].contains[
                                    gic_sub_industry
                                ].code.toString()
                        ) {
                            console.log(8);
                            industries_to_filter.push(
                                sector_object[gic_sector].contains[gic_group]
                                    .contains[gic_industry].contains[
                                    gic_sub_industry
                                ].name
                            );
                        }
                    });
                });
            });
        });

        localStorage.setItem(
            "latest_industries",
            JSON.stringify(industries_to_filter)
        );
        localStorage.setItem("latest_screener", "");
    };

    return sector.sector !== "" ? (
        <tr
            key={`${window.location.pathname}${sector}`}
            className={
                "sticky_table_tbody_tr background_color_2_hover_background_color_3"
            }
        >
            {/* Sector Name */}
            <th
                onClick={handleClickOpenSectorInfo}
                style={{
                    zIndex: "9",
                }}
            >
                <div className='text_3 text_weight_4 text_color_1 width_250px cursor_pointer background_color_2_hover_background_color_3 flex padding_5px border_radius_10px'>
                    {sector.sector}
                </div>
            </th>

            {/* Figures */}
            {metrics.map((metric) => (
                <SectorTableRowColumn sector={sector} metric={metric} />
            ))}

            {/* INFORMATION ABOUT INDUSTRIES SECTOR COMPRISES */}
            <Dialog
                open={openSectorInfo}
                onClose={handleCloseSectorInfo}
                maxWidth={false}
            >
                <div className='background_color_2 text_color_1 max_width_1250px'>
                    {/* HEADING */}
                    <div className='flex space_between'>
                        <div className='flex wrap space_between width_100pr'>
                            {/* Header, Country-selection */}
                            <div className='flex wrap'>
                                <div className='pl_20px mt_20px text_1 text_color_1 flex'>
                                    <Link
                                        className='ml_5px cursor_pointer text_color_1'
                                        onClick={() => {
                                            handleIndustryToFilter(
                                                gic_sector_code
                                            );
                                        }}
                                        to={`/screener`}
                                    >
                                        {sector.sector}
                                    </Link>
                                </div>
                            </div>

                            {/* Reset, Submit */}
                            <div className='flex wrap'></div>
                        </div>
                        <div
                            className='cursor_pointer title_4 text_color_1 flex center mr_15px mt_25px'
                            onClick={handleCloseSectorInfo}
                        >
                            <MdIcons.MdClose />
                        </div>
                    </div>

                    <div className='divider'></div>

                    {/* METRICS */}
                    <div className='flex wrap space_around width_100pr border_box background_color_2'>
                        <div className='width_100pr flex flex_column align_items_start wrap padding_20px'>
                            {Object.keys(sector_groups).map((gic_group) => (
                                <div className='width_100pr'>
                                    <div className='width_100pr pl_15px pr_15px min_width_200px ms_auto max_width_1000px flex space_between'>
                                        <div className='flex left'>
                                            <Link
                                                className='ml_5px cursor_pointer text_color_1'
                                                onClick={() => {
                                                    handleIndustryToFilter(
                                                        sector_groups[gic_group]
                                                            .code
                                                    );
                                                }}
                                                to={`/screener`}
                                            >
                                                {sector_groups[gic_group].name}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='mt_10px mb_10px background_color_3 pt_10px pb_10px'>
                                        {Object.keys(
                                            sector_groups[gic_group].contains
                                        ).map((gic_industry) => (
                                            <div>
                                                <div className='width_100pr pl_30px pr_30px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                    <div className='flex left'>
                                                        <Link
                                                            className='ml_5px cursor_pointer text_color_1'
                                                            onClick={() => {
                                                                handleIndustryToFilter(
                                                                    sector_groups[
                                                                        gic_group
                                                                    ].contains[
                                                                        gic_industry
                                                                    ].code
                                                                );
                                                            }}
                                                            to={`/screener`}
                                                        >
                                                            {
                                                                sector_groups[
                                                                    gic_group
                                                                ].contains[
                                                                    gic_industry
                                                                ].name
                                                            }
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='divider_no_margin'></div>

                                                <div className='mb_5px background_color_5'>
                                                    {Object.keys(
                                                        sector_groups[gic_group]
                                                            .contains[
                                                            gic_industry
                                                        ].contains
                                                    ).map(
                                                        (gic_sub_industry) => (
                                                            <div>
                                                                <div className='width_100pr pl_45px pr_30px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                    <div className='flex left'>
                                                                        <Link
                                                                            className='ml_5px cursor_pointer text_color_1'
                                                                            onClick={() => {
                                                                                handleIndustryToFilter(
                                                                                    sector_groups[
                                                                                        gic_group
                                                                                    ]
                                                                                        .contains[
                                                                                        gic_industry
                                                                                    ]
                                                                                        .contains[
                                                                                        gic_sub_industry
                                                                                    ]
                                                                                        .code
                                                                                );
                                                                            }}
                                                                            to={`/screener`}
                                                                        >
                                                                            {
                                                                                sector_groups[
                                                                                    gic_group
                                                                                ]
                                                                                    .contains[
                                                                                    gic_industry
                                                                                ]
                                                                                    .contains[
                                                                                    gic_sub_industry
                                                                                ]
                                                                                    .name
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='flex left width_100pr ps_20px pb_10px border_box background_color_2'>
                        *Click any to see the contained companies
                    </div>
                </div>
            </Dialog>
        </tr>
    ) : null;
};

SectorTableRow.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(SectorTableRow);
