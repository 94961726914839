import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";

// ACTIONS
import { numberFormatter } from "../../../../actions/generalActions";

const AdjustYAxisRange = ({
    component_id,
    handleResetAxis,
    axis_1_description,
    minY0,
    changeMinY0,
    maxY0,
    changeMaxY0,
    axis_2_description,
    minY1,
    changeMinY1,
    maxY1,
    changeMaxY1,
    axis_3_description,
    minY2,
    changeMinY2,
    maxY2,
    changeMaxY2,
    axis_4_description,
    minY3,
    changeMinY3,
    maxY3,
    changeMaxY3,
    required_axis,
}) => {
    const useintl = useIntl();

    const [openEditAxis, setOpenEditAxis] = React.useState(false);
    const handleOpenEditAxis = () => {
        setOpenEditAxis(true);
    };
    const handleCloseEditAxis = () => {
        setOpenEditAxis(false);
    };

    // const component_id = "COMPONENT_NAME";
    // const axis_1_description = "DESCRIPTION_1";
    // const axis_2_description = "DESCRIPTION_2";
    // const axis_3_description = "DESCRIPTION_3";
    // const axis_4_description = "DESCRIPTION_4";

    // const [chartAlignTicks, setChartAlignTicks] = React.useState(true);

    // useEffect(() => {
    //     handleResetAxis();
    //     // eslint-disable-next-line
    // }, [ticker]);

    // const [minY0, setminY0] = React.useState(
    //     Math.min(...company["METRIC_1"].concat(company["METRIC_2"]))
    // );
    // const [maxY0, setmaxY0] = React.useState(
    //     Math.max(...company["METRIC_1"].concat(company["METRIC_2"]))
    // );
    // const changeMinY0 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value.value === "" || isNaN(Number(new_value))) {
    //         setminY0(
    //             Math.min(...company["METRIC_1"].concat(company["METRIC_2"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setminY0(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };
    // const changeMaxY0 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setmaxY0(
    //             Math.max(...company["METRIC_1"].concat(company["METRIC_2"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setmaxY0(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };

    // const [minY1, setminY1] = React.useState(
    //     Math.min(...company["METRIC_3"].concat(company["METRIC_4"]))
    // );
    // const [maxY1, setmaxY1] = React.useState(
    //     Math.max(...company["METRIC_3"].concat(company["METRIC_4"]))
    // );
    // const changeMinY1 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setminY1(
    //             Math.min(...company["METRIC_3"].concat(company["METRIC_4"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setminY1(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };
    // const changeMaxY1 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setmaxY1(
    //             Math.max(...company["METRIC_3"].concat(company["METRIC_4"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setmaxY1(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };

    // const [minY2, setminY2] = React.useState(
    //     Math.min(...company["METRIC_5"].concat(company["METRIC_5"]))
    // );
    // const [maxY2, setmaxY2] = React.useState(
    //     Math.max(...company["METRIC_5"].concat(company["METRIC_6"]))
    // );
    // const changeMinY2 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setminY2(
    //             Math.min(...company["METRIC_5"].concat(company["METRIC_6"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setminY2(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };
    // const changeMaxY2 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setmaxY2(
    //             Math.max(...company["METRIC_5"].concat(company["METRIC_6"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setmaxY2(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };

    // const [minY3, setminY3] = React.useState(
    //     Math.min(...company["METRIC_7"].concat(company["METRIC_8"]))
    // );
    // const [maxY3, setmaxY3] = React.useState(
    //     Math.max(...company["METRIC_7"].concat(company["METRIC_8"]))
    // );
    // const changeMinY3 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setminY3(
    //             Math.min(...company["METRIC_7"].concat(company["METRIC_8"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setminY3(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };
    // const changeMaxY3 = (event) => {
    //     let new_value = event.target.value;
    //     if (!new_value || new_value === "" || isNaN(Number(new_value))) {
    //         setmaxY3(
    //             Math.max(...company["METRIC_7"].concat(company["METRIC_8"]))
    //         );
    //         setChartAlignTicks(false);
    //     } else {
    //         setmaxY3(Number(new_value));
    //         setChartAlignTicks(false);
    //     }
    // };

    // const handleResetAxis = () => {
    //     setminY0(Math.min(...company["METRIC_1"].concat(company["METRIC_2"])));
    //     setmaxY0(Math.max(...company["METRIC_1"].concat(company["METRIC_2"])));
    //     setminY1(Math.min(...company["METRIC_3"].concat(company["METRIC_4"])));
    //     setmaxY1(Math.max(...company["METRIC_3"].concat(company["METRIC_4"])));
    //     setminY2(Math.min(...company["METRIC_5"].concat(company["METRIC_6"])));
    //     setmaxY2(Math.max(...company["METRIC_5"].concat(company["METRIC_6"])));
    //     setminY3(Math.min(...company["METRIC_7"].concat(company["METRIC_8"])));
    //     setmaxY3(Math.max(...company["METRIC_7"].concat(company["METRIC_8"])));
    //     try {
    //         document.getElementById(`${component_id}_minInputY0_field`).reset();
    //         document.getElementById(`${component_id}_maxInputY0_field`).reset();
    //         document.getElementById(`${component_id}_minInputY1_field`).reset();
    //         document.getElementById(`${component_id}_maxInputY1_field`).reset();
    //         document.getElementById(`${component_id}_minInputY2_field`).reset();
    //         document.getElementById(`${component_id}_maxInputY2_field`).reset();
    //         document.getElementById(`${component_id}_minInputY3_field`).reset();
    //         document.getElementById(`${component_id}_maxInputY3_field`).reset();
    //     } catch (err) {}
    //     setChartAlignTicks(true);
    // };

    return (
        <div className='padding_10px width_100pr background_color_2'>
            <div className='center'>
                {openEditAxis ? (
                    <IoIcons5.IoChevronUpSharp
                        className='cursor_pointer'
                        onClick={() => {
                            handleCloseEditAxis();
                        }}
                    />
                ) : (
                    <div
                        className='flex center cursor_pointer'
                        onClick={() => {
                            handleOpenEditAxis();
                        }}
                    >
                        <div className='text_2'>Adjust y-axis values</div>

                        <IoIcons5.IoChevronDownSharp className='ml_10px center' />
                    </div>
                )}
            </div>
            {openEditAxis ? (
                <div className='transition_350ms'>
                    <div className='flex space_around width_100pr padding_10px'>
                        <div className='text_1 width_48pr_95pr text_left'>
                            Adjust y-axis values
                        </div>
                        <div className='width_48pr_95pr flex right'>
                            <div
                                className='button_white_layer_2 hover_opacity_80 text_2 padding_10px mt_5px ml_15px'
                                onClick={() => {
                                    handleResetAxis();
                                }}
                            >
                                <div className='text_2 pr_10px'>
                                    {useintl.formatMessage({
                                        id: "general.reset",
                                    })}
                                </div>
                                <IoIcons.IoIosRefresh />
                            </div>
                        </div>
                    </div>
                    <div className='flex space_around wrap'>
                        {required_axis["yAxis_0"] ? (
                            <div className='border_box margin_5px padding_10px background_color_3 border_radius_10px min_width_320px width_48pr_95pr shadow_dark'>
                                <div className='text_2 text_center'>
                                    Axis 1 - {axis_1_description}
                                </div>
                                Min
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_minInputY0_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_minInputY0`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMinY0}
                                            defaultValue={minY0}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {minY0 ? numberFormatter(minY0) : 0}
                                    </div>
                                </div>
                                Max
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_maxInputY0_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_maxInputY0`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMaxY0}
                                            defaultValue={maxY0}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {maxY0 ? numberFormatter(maxY0) : 0}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {required_axis["yAxis_1"] ? (
                            <div className='border_box margin_5px padding_10px background_color_3 border_radius_10px min_width_320px width_48pr_95pr shadow_dark'>
                                <div className='text_2 text_center'>
                                    Axis 2 - {axis_2_description}
                                </div>
                                Min
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_minInputY1_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_minInputY1`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMinY1}
                                            defaultValue={minY1}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {minY1 ? numberFormatter(minY1) : 0}
                                    </div>
                                </div>
                                Max
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_maxInputY1_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_maxInputY1`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMaxY1}
                                            defaultValue={maxY1}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {maxY1 ? numberFormatter(maxY1) : 0}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {required_axis["yAxis_2"] ? (
                            <div className='border_box margin_5px padding_10px background_color_3 border_radius_10px min_width_320px width_48pr_95pr shadow_dark'>
                                <div className='text_2 text_center'>
                                    Axis 3 - {axis_3_description}
                                </div>
                                Min
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_minInputY2_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_minInputY2`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMinY2}
                                            defaultValue={minY2}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {minY2 ? numberFormatter(minY2) : 0}
                                    </div>
                                </div>
                                Max
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_maxInputY2_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_maxInputY2`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMaxY2}
                                            defaultValue={maxY2}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {maxY2 ? numberFormatter(maxY2) : 0}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {required_axis["yAxis_3"] ? (
                            <div className='border_box margin_5px padding_10px background_color_3 border_radius_10px min_width_320px width_48pr_95pr shadow_dark'>
                                <div className='text_2 text_center'>
                                    Axis 4 - {axis_4_description}
                                </div>
                                Min
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_minInputY3_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_minInputY3`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMinY3}
                                            defaultValue={minY3}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {minY3 ? numberFormatter(minY3) : 0}
                                    </div>
                                </div>
                                Max
                                <div className='flex space_between'>
                                    <form
                                        id={`${component_id}_maxInputY3_field`}
                                        className='width_75pr'
                                    >
                                        <input
                                            id={`${component_id}_maxInputY3`}
                                            className='background_color_4 text_color_1 padding_5px input_text hover_opacity_80'
                                            onChange={changeMaxY3}
                                            defaultValue={maxY3}
                                        />
                                    </form>
                                    <div className='width_25pr text_right padding_5px'>
                                        {maxY3 ? numberFormatter(maxY3) : 0}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className='transition_350ms'></div>
            )}
        </div>
    );
};

export default AdjustYAxisRange;
