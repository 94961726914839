import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import * as math from "mathjs";

// COMPONENTS
import Preloader from "../../../layout/Preloader";

// HIGHCHARTS
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";

// ACTIONS
import {
    getAverageMetric,
    numberFormatter,
} from "../../../../actions/generalActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PortfolioTrackerPerformanceChart = ({
    benchmark_mode,
    benchmarks,
    transactions,
    performance,
    invested_sum,
    relative_performance,
    realized_profit_and_loss_over_time,
    sharpe_ratio,
    company_list,
    company_allocation,
    portfolio_id,
}) => {
    // const useintl = useIntl();

    const [tClose, setTClose] = React.useState(performance ? performance : []);
    const [tInvest, setTInvest] = React.useState(
        invested_sum ? invested_sum : []
    );
    const [tRelative, setTRelative] = React.useState(
        relative_performance ? relative_performance : []
    );
    const [tRealizedPL, setTRealizedPL] = React.useState(
        realized_profit_and_loss_over_time
            ? realized_profit_and_loss_over_time
            : []
    );

    let benchmark_colors = [
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
    ];

    useEffect(() => {
        if (performance) {
            setTClose(performance);
        } else {
            setTClose([]);
        }
        if (invested_sum) {
            setTInvest(invested_sum);
        } else {
            setTInvest([]);
        }
        if (relative_performance) {
            setTRelative(relative_performance);
        } else {
            setTRelative([]);
        }
        if (realized_profit_and_loss_over_time) {
            setTRealizedPL(realized_profit_and_loss_over_time);
        } else {
            setTRealizedPL([]);
        }
        // eslint-disable-next-line
    }, [
        performance,
        invested_sum,
        relative_performance,
        realized_profit_and_loss_over_time,
    ]);

    const handleCalculateIncludedCashflows = (
        cf_performance,
        cf_invested_sum,
        cf_realized_profit_and_loss_over_time
    ) => {
        if (
            cf_performance &&
            cf_realized_profit_and_loss_over_time &&
            cf_invested_sum
        ) {
            let performance_matrix = math.matrix(cf_performance);
            let performanceMatrix = [
                math.transpose(math.column(performance_matrix, 0)),
                math.transpose(math.column(performance_matrix, 1)),
            ];
            let performance_dates = performanceMatrix[0]._data[0];
            let performance_values = performanceMatrix[1]._data[0];

            let invest_matrix = math.matrix(cf_invested_sum);
            let investMatrix = [
                math.transpose(math.column(invest_matrix, 0)),
                math.transpose(math.column(invest_matrix, 1)),
            ];
            let invest_values = investMatrix[1]._data[0];

            let pl_matrix = math.matrix(cf_realized_profit_and_loss_over_time);
            let plMatrix = [
                math.transpose(math.column(pl_matrix, 0)),
                math.transpose(math.column(pl_matrix, 1)),
            ];
            let pl_values = plMatrix[1]._data[0];

            let performanceIncludingCashflowMatrix = math.matrix([
                performance_dates,
                math.add(performance_values, pl_values),
            ]);

            let performanceRelativeIncludingCashflowMatrix = math.matrix([
                performance_dates,
                math.add(
                    math.dotMultiply(
                        math.add(
                            math.dotDivide(
                                math.add(performance_values, pl_values),
                                invest_values
                            ),
                            -1
                        ),
                        100
                    ),
                    100
                ),
            ]);

            let performance_including_cash_flows = math.transpose(
                performanceIncludingCashflowMatrix
            )._data;
            let relative_performance_including_cash_flows = math.transpose(
                performanceRelativeIncludingCashflowMatrix
            )._data;

            setTClose(performance_including_cash_flows);
            setTRelative(relative_performance_including_cash_flows);
        } else {
            setTClose([]);
            setTRelative([]);
        }
    };

    const handleExcludeCashflows = () => {
        if (performance) {
            setTClose(performance);
        } else {
            setTClose([]);
        }
        if (relative_performance) {
            setTRelative(relative_performance);
        } else {
            setTRelative([]);
        }
    };

    let transaction_flags = [];

    // useEffect(() => {
    if (transactions !== null) {
        Object.keys(transactions).map((company) => {
            transactions[company].map((transaction) => {
                transaction_flags.push({
                    x: Number(transaction.date), //Date.UTC(2021, 2, 2),
                    title: transaction.type === "buy" ? "B" : "S", //'A',
                    text: `<span> <div style="margin-top: 25px;">${
                        transaction.type === "buy" ? "Buy" : "Sell"
                    } ${company} <br/> ${transaction.share_count} x ${
                        transaction.price_per_share
                    } USD <br/> Total: ${
                        transaction.transaction_value
                    } USD</div></span>`, //'Some event with a description'
                });
            });
        });
    }
    // eslint-disable-next-line
    // }, []);

    const [relative, setRelative] = React.useState(false);
    const handleSetRelative = () => {
        setRelative(!relative);
    };

    const [showTransactions, setShowTransactions] = React.useState(false);
    const handleSetShowTransactions = () => {
        setShowTransactions(!showTransactions);
    };

    const [showChartValue, setShowChartValue] = React.useState("Performance");

    const [showPerformance, setShowPerformance] = React.useState(true);
    const handleSetShowPerformance = () => {
        setShowPerformance(true);
        setShowInvestments(false);
        setShowProfitAndLoss(false);
        setShowChartValue("Performance");
    };
    const [showInvestments, setShowInvestments] = React.useState(false);
    const handleSetShowInvestments = () => {
        setShowPerformance(false);
        setShowInvestments(true);
        setShowProfitAndLoss(false);
        setShowChartValue("Investments");
    };
    const [showProfitAndLoss, setShowProfitAndLoss] = React.useState(false);
    const handleSetShowProfitAndLoss = () => {
        setShowPerformance(false);
        setShowInvestments(false);
        setShowProfitAndLoss(true);
        setShowChartValue("Profit & Loss");
    };

    const [includeCashflows, setIncludeCashflows] = React.useState(false);
    // INCLUDE CASHFLOWS AT BENCHMARKS ASWELL
    // HANDLE IF BENCHMARK HAS LESS TIME SERIES DATA THAN PORTFOLIO
    const handleSetIncludeCashflows = () => {
        if (includeCashflows) {
            handleExcludeCashflows();
        } else {
            handleCalculateIncludedCashflows(
                performance,
                invested_sum,
                realized_profit_and_loss_over_time
            );
        }

        setIncludeCashflows(!includeCashflows);
    };

    let [timeFrame, setTimeFrame] = React.useState(5);

    let series_absolute = [
        // PERFORMANCE
        {
            type: benchmark_mode ? "line" : "area",
            fillColor: benchmark_mode
                ? null
                : {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1,
                      },
                      stops: [
                          [
                              0,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[0]
                              )
                                  .setOpacity(0.7)
                                  .get("rgba"),
                          ],
                          [
                              1,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[0]
                              )
                                  .setOpacity(0)
                                  .get("rgba"),
                          ],
                      ],
                  },
            name: "Value",
            data: tClose,
            compare: "undefined",
            visible: !benchmark_mode ? true : showPerformance ? true : false,
            showInLegend: !benchmark_mode
                ? true
                : showPerformance
                ? true
                : false,
            //   threshold: null,
            id: "stockprice",
            color: Highcharts.getOptions().colors[0],
            tooltip: {
                pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                changeDecimals: 2,
                valueDecimals: 2,
            },
            dataGrouping: {
                units: [
                    ["day", [1]],
                    ["week", [1]],
                    ["month", [1, 2, 3, 4, 6]],
                ],
            },
        },
        // INVESTMENTS
        {
            type: "line",
            name: "Invest",
            data: tInvest,
            compare: "undefined",
            //   threshold: null,
            id: "investment",
            color: benchmark_mode
                ? Highcharts.getOptions().colors[0]
                : Highcharts.getOptions().colors[1],
            visible: !benchmark_mode ? true : showInvestments ? true : false,
            showInLegend: !benchmark_mode
                ? true
                : showInvestments
                ? true
                : false,
            tooltip: {
                pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                changeDecimals: 2,
                valueDecimals: 2,
            },
            dataGrouping: {
                units: [
                    ["day", [1]],
                    ["week", [1]],
                    ["month", [1, 2, 3, 4, 6]],
                ],
            },
        },
        // PL
        {
            type: "line",
            name: "Realized capital gains",
            data: tRealizedPL,
            compare: "undefined",
            visible: !benchmark_mode ? true : showProfitAndLoss ? true : false,
            showInLegend: !benchmark_mode
                ? true
                : showProfitAndLoss
                ? true
                : false,
            //   threshold: null,
            id: "realized_pl",
            color: benchmark_mode
                ? Highcharts.getOptions().colors[0]
                : Highcharts.getOptions().colors[2],
            tooltip: {
                pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                changeDecimals: 2,
                valueDecimals: 2,
            },
            dataGrouping: {
                units: [
                    ["day", [1]],
                    ["week", [1]],
                    ["month", [1, 2, 3, 4, 6]],
                ],
            },
        },
        // FLAGS
        {
            type: "flags",
            useHTML: true,
            // dataLabels:{
            //     useHTML:true,
            // },
            data: showTransactions ? transaction_flags : [],
            showInLegend: false,
            onSeries: "investment",
            shape: "circlepin",
            width: 16,
        },
    ];
    let series_relative = [
        // PERFORMANCE
        {
            type: benchmark_mode ? "line" : "area",
            fillColor: benchmark_mode
                ? null
                : {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1,
                      },
                      stops: [
                          [
                              0,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[10]
                              )
                                  .setOpacity(0.7)
                                  .get("rgba"),
                          ],
                          [
                              1,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[10]
                              )
                                  .setOpacity(0)
                                  .get("rgba"),
                          ],
                      ],
                  },
            negativeFillColor: benchmark_mode
                ? null
                : {
                      linearGradient: {
                          x1: 0,
                          y1: 1,
                          x2: 0,
                          y2: 0,
                      },
                      stops: [
                          [
                              0,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[11]
                              )
                                  .setOpacity(0.7)
                                  .get("rgba"),
                          ],
                          [
                              1,
                              Highcharts.Color(
                                  Highcharts.getOptions().colors[11]
                              )
                                  .setOpacity(0)
                                  .get("rgba"),
                          ],
                      ],
                  },
            name: "Performance",
            data: tRelative,
            compare: "percent",
            threshold: 0,
            id: "stockprice",
            color: Highcharts.getOptions().colors[10],
            negativeColor: Highcharts.getOptions().colors[11],
            tooltip: {
                pointFormat:
                    '<span style="color:{series.color}">{series.name}</span>: <b>{point.change}%</b><br/>',
                changeDecimals: 2,
                valueDecimals: 2,
            },
            dataGrouping: {
                units: [
                    ["day", [1]],
                    ["week", [1]],
                    ["month", [1, 2, 3, 4, 6]],
                ],
            },
        },
        // FLAGS
        {
            type: "flags",
            useHTML: true,
            color: Highcharts.getOptions().colors[0],
            // dataLabels:{
            //     useHTML:true,
            // },
            data: showTransactions ? transaction_flags : [],
            showInLegend: false,
            onSeries: "stockprice",
            shape: "circlepin",
            width: 16,
        },
    ];

    const provide_benchmarks = () => {
        series_absolute = [
            // PERFORMANCE
            {
                type: benchmark_mode ? "line" : "area",
                fillColor: benchmark_mode
                    ? null
                    : {
                          linearGradient: {
                              x1: 0,
                              y1: 0,
                              x2: 0,
                              y2: 1,
                          },
                          stops: [
                              [
                                  0,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[0]
                                  )
                                      .setOpacity(0.7)
                                      .get("rgba"),
                              ],
                              [
                                  1,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[0]
                                  )
                                      .setOpacity(0)
                                      .get("rgba"),
                              ],
                          ],
                      },
                name: "Value",
                data: tClose,
                compare: "undefined",
                visible: !benchmark_mode
                    ? true
                    : showPerformance
                    ? true
                    : false,
                showInLegend: !benchmark_mode
                    ? true
                    : showPerformance
                    ? true
                    : false,
                //   threshold: null,
                id: "stockprice",
                color: Highcharts.getOptions().colors[0],
                tooltip: {
                    pointFormat:
                        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    changeDecimals: 2,
                    valueDecimals: 2,
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            // INVESTMENTS
            {
                type: "line",
                name: "Invest",
                data: tInvest,
                compare: "undefined",
                //   threshold: null,
                id: "investment",
                color: benchmark_mode
                    ? Highcharts.getOptions().colors[0]
                    : Highcharts.getOptions().colors[1],
                visible: !benchmark_mode
                    ? true
                    : showInvestments
                    ? true
                    : false,
                showInLegend: !benchmark_mode
                    ? true
                    : showInvestments
                    ? true
                    : false,
                tooltip: {
                    pointFormat:
                        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    changeDecimals: 2,
                    valueDecimals: 2,
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            // PL
            {
                type: "line",
                name: "Realized capital gains",
                data: tRealizedPL,
                compare: "undefined",
                visible: !benchmark_mode
                    ? true
                    : showProfitAndLoss
                    ? true
                    : false,
                showInLegend: !benchmark_mode
                    ? true
                    : showProfitAndLoss
                    ? true
                    : false,
                //   threshold: null,
                id: "realized_pl",
                color: benchmark_mode
                    ? Highcharts.getOptions().colors[0]
                    : Highcharts.getOptions().colors[2],
                tooltip: {
                    pointFormat:
                        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                    changeDecimals: 2,
                    valueDecimals: 2,
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            // FLAGS
            {
                type: "flags",
                useHTML: true,
                // dataLabels:{
                //     useHTML:true,
                // },
                data: showTransactions ? transaction_flags : [],
                showInLegend: false,
                onSeries: "investment",
                shape: "circlepin",
                width: 16,
            },
        ];
        series_relative = [
            // PERFORMANCE
            {
                type: benchmark_mode ? "line" : "area",
                fillColor: benchmark_mode
                    ? null
                    : {
                          linearGradient: {
                              x1: 0,
                              y1: 0,
                              x2: 0,
                              y2: 1,
                          },
                          stops: [
                              [
                                  0,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[10]
                                  )
                                      .setOpacity(0.7)
                                      .get("rgba"),
                              ],
                              [
                                  1,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[10]
                                  )
                                      .setOpacity(0)
                                      .get("rgba"),
                              ],
                          ],
                      },
                negativeFillColor: benchmark_mode
                    ? null
                    : {
                          linearGradient: {
                              x1: 0,
                              y1: 1,
                              x2: 0,
                              y2: 0,
                          },
                          stops: [
                              [
                                  0,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[11]
                                  )
                                      .setOpacity(0.7)
                                      .get("rgba"),
                              ],
                              [
                                  1,
                                  Highcharts.Color(
                                      Highcharts.getOptions().colors[11]
                                  )
                                      .setOpacity(0)
                                      .get("rgba"),
                              ],
                          ],
                      },
                name: "Performance",
                data: tRelative,
                compare: "percent",
                threshold: 0,
                id: "stockprice",
                color: Highcharts.getOptions().colors[10],
                negativeColor: Highcharts.getOptions().colors[11],
                tooltip: {
                    pointFormat:
                        '<span style="color:{series.color}">{series.name}</span>: <b>{point.change}%</b><br/>',
                    changeDecimals: 2,
                    valueDecimals: 2,
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            // FLAGS
            {
                type: "flags",
                useHTML: true,
                color: Highcharts.getOptions().colors[0],
                // dataLabels:{
                //     useHTML:true,
                // },
                data: showTransactions ? transaction_flags : [],
                showInLegend: false,
                onSeries: "stockprice",
                shape: "circlepin",
                width: 16,
            },
        ];
        if (benchmarks && benchmarks.length > 0) {
            // performance
            // invested_sum
            // relative_performance
            // realized_profit_and_loss_over_time
            // realized_profit_and_loss
            benchmarks.map((benchmark, idx) => {
                let bm_performance = benchmark.prices.performance;
                let bm_invested_sum = benchmark.prices.invested_sum;
                let bm_realized_profit_and_loss_over_time =
                    benchmark.prices.realized_profit_and_loss_over_time;

                let bm_relative_performance =
                    benchmark.prices.relative_performance;

                if (
                    bm_performance &&
                    bm_realized_profit_and_loss_over_time &&
                    bm_invested_sum &&
                    includeCashflows
                ) {
                    let performance_matrix = math.matrix(bm_performance);
                    let performanceMatrix = [
                        math.transpose(math.column(performance_matrix, 0)),
                        math.transpose(math.column(performance_matrix, 1)),
                    ];
                    let performance_dates = performanceMatrix[0]._data[0];
                    let performance_values = performanceMatrix[1]._data[0];

                    let invest_matrix = math.matrix(bm_invested_sum);
                    let investMatrix = [
                        math.transpose(math.column(invest_matrix, 0)),
                        math.transpose(math.column(invest_matrix, 1)),
                    ];
                    let invest_values = investMatrix[1]._data[0];

                    let pl_matrix = math.matrix(
                        bm_realized_profit_and_loss_over_time
                    );
                    let plMatrix = [
                        math.transpose(math.column(pl_matrix, 0)),
                        math.transpose(math.column(pl_matrix, 1)),
                    ];
                    let pl_values = plMatrix[1]._data[0];

                    let performanceIncludingCashflowMatrix = math.matrix([
                        performance_dates,
                        math.add(performance_values, pl_values),
                    ]);

                    let performanceRelativeIncludingCashflowMatrix =
                        math.matrix([
                            performance_dates,
                            math.add(
                                math.dotMultiply(
                                    math.add(
                                        math.dotDivide(
                                            math.add(
                                                performance_values,
                                                pl_values
                                            ),
                                            invest_values
                                        ),
                                        -1
                                    ),
                                    100
                                ),
                                100
                            ),
                        ]);

                    bm_performance = math.transpose(
                        performanceIncludingCashflowMatrix
                    )._data;
                    bm_relative_performance = math.transpose(
                        performanceRelativeIncludingCashflowMatrix
                    )._data;
                }

                if (showPerformance) {
                    series_absolute.push({
                        type: "line",
                        name: `${benchmark.name}`,
                        data: bm_performance,
                        compare: "undefined",
                        //   threshold: null,
                        id: `${benchmark.ticker}_performance`,

                        color: Highcharts.getOptions().colors[idx + 1],
                        visible:
                            benchmark_mode && showPerformance ? true : false,
                        showInLegend:
                            benchmark_mode && showPerformance ? true : false,
                        tooltip: {
                            pointFormat:
                                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                            changeDecimals: 2,
                            valueDecimals: 2,
                        },
                        dataGrouping: {
                            units: [
                                ["day", [1]],
                                ["week", [1]],
                                ["month", [1, 2, 3, 4, 6]],
                            ],
                        },
                    });
                }
                if (showInvestments) {
                    series_absolute.push({
                        type: "line",
                        name: `${benchmark.name} - Invest`,
                        data: benchmark.prices.invested_sum,
                        compare: "undefined",
                        //   threshold: null,
                        id: `${benchmark.ticker}_investment`,
                        color: Highcharts.getOptions().colors[idx + 1],
                        visible:
                            benchmark_mode && showInvestments ? true : false,
                        showInLegend:
                            benchmark_mode && showInvestments ? true : false,
                        tooltip: {
                            pointFormat:
                                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                            changeDecimals: 2,
                            valueDecimals: 2,
                        },
                        dataGrouping: {
                            units: [
                                ["day", [1]],
                                ["week", [1]],
                                ["month", [1, 2, 3, 4, 6]],
                            ],
                        },
                    });
                }
                if (showProfitAndLoss) {
                    series_absolute.push({
                        type: "line",
                        name: `${benchmark.name} - P&L`,
                        data: benchmark.prices
                            .realized_profit_and_loss_over_time,
                        compare: "undefined",
                        //   threshold: null,
                        id: `${benchmark.ticker}_realized_profit_and_loss_over_time`,
                        color: Highcharts.getOptions().colors[idx + 1],
                        visible:
                            benchmark_mode && showProfitAndLoss ? true : false,
                        showInLegend:
                            benchmark_mode && showProfitAndLoss ? true : false,
                        tooltip: {
                            pointFormat:
                                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                            changeDecimals: 2,
                            valueDecimals: 2,
                        },
                        dataGrouping: {
                            units: [
                                ["day", [1]],
                                ["week", [1]],
                                ["month", [1, 2, 3, 4, 6]],
                            ],
                        },
                    });
                }
                series_relative.push({
                    type: "line",
                    name: `${benchmark.name}`,
                    data: bm_relative_performance,
                    compare: "percent",
                    //   threshold: null,
                    id: `${benchmark.ticker}_relative_performance`,
                    color: Highcharts.getOptions().colors[idx + 1],
                    visible: benchmark_mode ? true : false,
                    showInLegend: benchmark_mode ? true : false,
                    showInLegend: true,
                    tooltip: {
                        pointFormat:
                            '<span style="color:{series.color}">{series.name}</span>: <b>{point.change}%</b><br/>',
                        changeDecimals: 2,
                        valueDecimals: 2,
                    },
                    dataGrouping: {
                        units: [
                            ["day", [1]],
                            ["week", [1]],
                            ["month", [1, 2, 3, 4, 6]],
                        ],
                    },
                });
            });
        }
    };
    provide_benchmarks();

    let chartOptions = {
        chart: {
            height: benchmark_mode ? 700 : 400,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        rangeSelector: {
                            buttons: [
                                {
                                    type: "ytd",
                                    text: "YTD",
                                    events: {
                                        click: function () {
                                            setTimeFrame(3);
                                        },
                                    },
                                },
                                {
                                    type: "all",
                                    text: "All",
                                    events: {
                                        click: function () {
                                            setTimeFrame(5);
                                        },
                                    },
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
                {
                    condition: {
                        maxWidth: 1200,
                        minWidth: 401,
                    },
                    chartOptions: {
                        rangeSelector: {
                            buttons: [
                                {
                                    type: "month",
                                    count: 1,
                                    text: "1m",
                                    events: {
                                        click: function () {
                                            setTimeFrame(0);
                                        },
                                    },
                                },
                                {
                                    type: "month",
                                    count: 3,
                                    text: "3m",
                                    events: {
                                        click: function () {
                                            setTimeFrame(1);
                                        },
                                    },
                                },
                                {
                                    type: "month",
                                    count: 6,
                                    text: "6m",
                                    events: {
                                        click: function () {
                                            setTimeFrame(2);
                                        },
                                    },
                                },
                                {
                                    type: "ytd",
                                    text: "YTD",
                                    events: {
                                        click: function () {
                                            setTimeFrame(3);
                                        },
                                    },
                                },
                                {
                                    type: "year",
                                    count: 1,
                                    text: "1y",
                                    events: {
                                        click: function () {
                                            setTimeFrame(4);
                                        },
                                    },
                                },
                                {
                                    type: "all",
                                    text: "All",
                                    events: {
                                        click: function () {
                                            setTimeFrame(5);
                                        },
                                    },
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
            ],
        },
        title: {
            text: "",
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        yAxis: [
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Value",
                },
                height: "100%",
                lineWidth: 2,
                gridLineColor: "#5f5f5f",
                resize: {
                    enabled: true,
                },
            },
        ],
        navigator: {
            enabled: true,
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            text: " ",
        },
        tooltip: {
            shared: true,
        },
        legend: {
            enabled: true,
            align: "center",
            verticalAlign: "bottom",
            x: 0,
            y: 0,
            backgroundColor: "rgba(255,255,255,0)",
        },
        series: relative ? series_relative : series_absolute,
    };

    let onem_abs;
    let onem_rel;
    let threem_abs;
    let threem_rel;
    let sixm_abs;
    let sixm_rel;
    let ytd_abs;
    let ytd_rel;
    let oney_abs;
    let oney_rel;
    let all_abs;
    let all_rel;

    try {
        onem_abs = Number(
            (
                performance[performance.length - 1][1] -
                performance[performance.length - 21][1]
            ).toPrecision(4)
        );
        onem_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    performance[performance.length - 21][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        onem_abs = "-";
        onem_rel = "-";
    }
    try {
        threem_abs = Number(
            (
                performance[performance.length - 1][1] -
                performance[performance.length - 61][1]
            ).toPrecision(4)
        );
        threem_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    performance[performance.length - 61][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        threem_abs = "-";
        threem_rel = "-";
    }
    try {
        sixm_abs = Number(
            (
                performance[performance.length - 1][1] -
                performance[performance.length - 121][1]
            ).toPrecision(4)
        );
        sixm_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    performance[performance.length - 121][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        sixm_abs = "-";
        sixm_rel = "-";
    }
    try {
        ytd_abs = Number(
            (
                performance[performance.length - 1][1] -
                performance[performance.length - 121][1]
            ).toPrecision(4)
        );
        ytd_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    performance[performance.length - 121][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        ytd_abs = "-";
        ytd_rel = "-";
    }
    try {
        oney_abs = Number(
            (
                performance[performance.length - 1][1] -
                performance[performance.length - 241][1]
            ).toPrecision(4)
        );
        oney_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    performance[performance.length - 241][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        oney_abs = "-";
        oney_rel = "-";
    }
    try {
        all_abs = Number(
            (
                performance[performance.length - 1][1] -
                invested_sum[performance.length - 1][1]
            ).toPrecision(4)
        );
        all_rel = Number(
            (
                (performance[performance.length - 1][1] /
                    invested_sum[performance.length - 1][1] -
                    1) *
                100
            ).toPrecision(4)
        );
    } catch (err) {
        all_abs = "-";
        all_rel = "-";
    }

    let returns_time = {
        0: {
            time: "1 Month",
            absolute: onem_abs,
            relative: onem_rel,
        },
        1: {
            time: "3 Months",
            absolute: threem_abs,
            relative: threem_rel,
        },
        2: {
            time: "6 Months",
            absolute: sixm_abs,
            relative: sixm_rel,
        },
        3: {
            time: "YTD",
            absolute: ytd_abs,
            relative: ytd_rel,
        },
        4: {
            time: "1 Year",
            absolute: oney_abs,
            relative: oney_rel,
        },
        5: {
            time: "All Time",
            absolute: all_abs,
            relative: all_rel,
        },
    };

    // AVG METRICS
    let dividend_yield_avg = getAverageMetric(
        company_list,
        company_allocation,
        "dividend_yield"
    );

    let beta_avg = getAverageMetric(company_list, company_allocation, "beta");
    let cagr =
        tRelative && tRelative.length > 0
            ? Number(
                  (Math.pow(
                      (tRelative[tRelative.length - 1][1] - 100) / 100 + 1,
                      1 / (tRelative.length / 253)
                  ) -
                      1) *
                      100
              ).toPrecision(3)
            : null;
    let current_invested_sum =
        invested_sum && invested_sum.length > 0
            ? Number(invested_sum[performance.length - 1][1])
            : null;
    let realized_profit =
        tRealizedPL && tRealizedPL.length > 0
            ? Number(tRealizedPL[tRealizedPL.length - 1][1])
            : null;
    let unrealized_profit =
        performance && performance.length > 0 && tInvest && tInvest.length > 0
            ? Number(
                  performance[performance.length - 1][1] -
                      tInvest[tInvest.length - 1][1]
              )
            : null;

    return (
        <div className='mt_10px'>
            <div className='flex space_between pt_10px pl_20px pr_20px mt_10px'>
                {/* SELECT WHAT IS SHOWN */}
                <div className='flex wrap'>
                    <div className='text_1 mr_20px'>
                        <FormattedMessage
                            id='general.current_performance'
                            defaultMessage='Performance'
                        />
                    </div>
                    <div
                        className={
                            relative
                                ? "button_white_active pl_10px pr_10px hover_opacity_80 mr_20px"
                                : "button_white_layer_2 pl_10px pr_10px hover_opacity_80 mr_20px"
                        }
                        onClick={handleSetRelative}
                    >
                        Relative
                    </div>
                    <div
                        className={
                            showTransactions
                                ? "button_white_active pl_10px pr_10px hover_opacity_80 mr_20px"
                                : "button_white_layer_2 pl_10px pr_10px hover_opacity_80 mr_20px"
                        }
                        onClick={handleSetShowTransactions}
                    >
                        Transactions
                    </div>
                    <div
                        className={
                            includeCashflows
                                ? "button_white_active pl_10px pr_10px hover_opacity_80 mr_20px"
                                : "button_white_layer_2 pl_10px pr_10px hover_opacity_80 mr_20px"
                        }
                        onClick={handleSetIncludeCashflows}
                    >
                        Include Capital Gains
                    </div>
                    {benchmark_mode ? (
                        <div className='width_200px mr_20px'>
                            <FormControl sx={{ minWidth: 150 }} size='small'>
                                <InputLabel
                                    htmlFor='tableStructure'
                                    className='width_200px_120px'
                                    id='demo-simple-select-helper-label'
                                >
                                    Shown data
                                </InputLabel>
                                <Select
                                    id='demo-simple-select'
                                    className='background_color_3 text_color_1'
                                    value={showChartValue}
                                    label={"Shown data"}
                                    // defaultValue={benchmarkPrices}
                                    // onChange={handleLanguageChange}
                                    labelId='demo-simple-select-helper-label'
                                    // inputProps={{
                                    //     "aria-label": "Without label",
                                    // }}
                                >
                                    <MenuItem
                                        key='performance'
                                        name='Performance'
                                        value='Performance'
                                        onClick={() => {
                                            handleSetShowPerformance();
                                        }}
                                    >
                                        <ListItemText primary='Performance' />
                                    </MenuItem>
                                    <MenuItem
                                        key='investments'
                                        name='Investments'
                                        value='Investments'
                                        onClick={() => {
                                            handleSetShowInvestments();
                                            setRelative(false);
                                            setIncludeCashflows(false);
                                        }}
                                    >
                                        <ListItemText primary='Investments' />
                                    </MenuItem>
                                    <MenuItem
                                        key='profit_and_loss'
                                        name='Profit & Loss'
                                        value='Profit & Loss'
                                        onClick={() => {
                                            handleSetShowProfitAndLoss();
                                            setRelative(false);
                                            setIncludeCashflows(false);
                                        }}
                                    >
                                        <ListItemText primary='Profit & Loss' />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    ) : null}
                </div>
                {/* SHOW PERFORMANCE */}
                <div className='flex right wrap'>
                    {returns_time[timeFrame].absolute >= 0 ? (
                        <div className='flex right no_wrap'>
                            <div className='text_color_green'>
                                +{returns_time[timeFrame].absolute}
                            </div>
                            <div className='ml_5px'> USD</div>
                        </div>
                    ) : (
                        <div className='flex right no_wrap'>
                            <div className='text_color_red'>
                                {returns_time[timeFrame].absolute}
                            </div>
                            <div className='ml_5px'> USD</div>
                        </div>
                    )}
                    {returns_time[timeFrame].absolute >= 0 ? (
                        <div className='text_color_green ml_10px'>
                            (+{returns_time[timeFrame].relative} %)
                        </div>
                    ) : (
                        <div className='text_color_red ml_10px'>
                            ({returns_time[timeFrame].relative} %)
                        </div>
                    )}
                    <div className='ml_10px ml_10px'>
                        {returns_time[timeFrame].time}
                    </div>
                </div>
            </div>
            <div className='divider'></div>
            {performance !== null &&
            performance.length > 0 &&
            tClose !== null ? (
                <div>
                    {/* CHART */}
                    <div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            constructorType={"stockChart"}
                            containerProps={{ className: "chart" }}
                            className='profile_historic_price'
                        />
                    </div>
                    {/* SHORT INFO */}
                    <div className='width_100pr flex wrap background_color_1 text_2 text_color_1'>
                        {sharpe_ratio && sharpe_ratio !== null ? (
                            <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                                Sharpe Ratio:{" "}
                                {sharpe_ratio
                                    ? Number(sharpe_ratio.toPrecision(3))
                                    : null}
                            </div>
                        ) : null}
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            Beta:{" "}
                            {beta_avg ? Number(beta_avg.toPrecision(3)) : null}
                        </div>
                        {/* console.log("tRelative: ", tRelative)
                        console.log("tRealizedPL: ", tRealizedPL) */}
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            CAGR: {cagr ? cagr : null} %
                        </div>
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            Dividend Yield:{" "}
                            {dividend_yield_avg
                                ? Number(dividend_yield_avg.toFixed(2))
                                : 0}{" "}
                            %
                        </div>
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            Invested:{" "}
                            {current_invested_sum
                                ? current_invested_sum.toFixed(2)
                                : null}{" "}
                            USD
                        </div>
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            Realized:{" "}
                            {realized_profit ? realized_profit.toFixed(2) : 0}{" "}
                            USD
                        </div>
                        <div className='background_color_3 shadow_dark border_radius_15px pt_5px pb_5px ps_10px ms_10px mb_5px'>
                            Unrealized:{" "}
                            {unrealized_profit
                                ? unrealized_profit.toFixed(2)
                                : null}{" "}
                            USD
                        </div>
                    </div>
                    {benchmark_mode && benchmarks && benchmarks.length > 0 ? (
                        <div className='ps_20px mt_20px'>
                            <div className='width_100pr overflow_x_auto sticky_table max_table_height direction_rtl'>
                                <table className='direction_ltr'>
                                    <thead className='sticky_table_thead background_color_2'>
                                        <tr className='sticky_table_thead_tr background_color_2 text_color_1'>
                                            <th
                                                className='padding_15_5px min_width_200px background_color_2 vertical_align_top'
                                                style={{
                                                    zIndex: "10",
                                                }}
                                            >
                                                <div></div>
                                            </th>
                                            <th className='text_right padding_15_5px'>
                                                Sharpe
                                            </th>
                                            {/* <th className='text_right padding_15_5px'>
                                                Beta
                                            </th> */}
                                            <th className='text_right padding_15_5px'>
                                                CAGR
                                            </th>
                                            {/* <th className='text_right padding_15_5px'>
                                                Performance YTD
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='sticky_table_tbody_tr background_color_1_hover_background_color_3'>
                                            <th
                                                className='sticky_table_tbody_tr'
                                                style={{
                                                    zIndex: "9",
                                                }}
                                            >
                                                <div className='padding_5px border_radius_5px background_color_1_hover_background_color_3'>
                                                    Portfolio
                                                </div>
                                            </th>
                                            <td className='text_right padding_15_5px'>
                                                {sharpe_ratio
                                                    ? Number(
                                                          sharpe_ratio.toPrecision(
                                                              3
                                                          )
                                                      )
                                                    : null}
                                            </td>
                                            {/* <td className='text_right padding_15_5px'>
                                                {beta_avg
                                                    ? Number(
                                                          beta_avg.toPrecision(
                                                              3
                                                          )
                                                      )
                                                    : null}
                                            </td> */}
                                            <td className='text_right padding_15_5px'>
                                                {cagr ? cagr : "-"} %
                                            </td>
                                            {/* <td className='text_right padding_15_5px'>
                                                -
                                            </td> */}
                                        </tr>
                                        {benchmarks.map((benchmark) => {
                                            return (
                                                <tr className='sticky_table_tbody_tr background_color_1_hover_background_color_3'>
                                                    <th
                                                        className='sticky_table_tbody_tr'
                                                        style={{
                                                            zIndex: "9",
                                                        }}
                                                    >
                                                        <div className='padding_5px border_radius_5px background_color_1_hover_background_color_3'>
                                                            {benchmark.name}
                                                        </div>
                                                    </th>
                                                    <td className='text_right padding_15_5px'>
                                                        {Number(
                                                            benchmark.prices
                                                                .sharpe_ratio
                                                        ).toFixed(2)}
                                                    </td>
                                                    {/* <td className='text_right padding_15_5px'>
                                                        -
                                                    </td> */}
                                                    <td className='text_right padding_15_5px'>
                                                        {benchmark.prices
                                                            .relative_performance &&
                                                        benchmark.prices
                                                            .relative_performance
                                                            .length > 0
                                                            ? Number(
                                                                  (Math.pow(
                                                                      (benchmark
                                                                          .prices
                                                                          .relative_performance[
                                                                          benchmark
                                                                              .prices
                                                                              .relative_performance
                                                                              .length -
                                                                              1
                                                                      ][1] -
                                                                          100) /
                                                                          100 +
                                                                          1,
                                                                      1 /
                                                                          (benchmark
                                                                              .prices
                                                                              .relative_performance
                                                                              .length /
                                                                              253)
                                                                  ) -
                                                                      1) *
                                                                      100
                                                              ).toPrecision(3) +
                                                              " %"
                                                            : "-"}
                                                    </td>
                                                    {/* <td className='text_right padding_15_5px'>
                                                        -
                                                    </td> */}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : performance !== null &&
              performance.length === 0 &&
              tClose !== null ? (
                <Preloader />
            ) : (
                <div className='width_100pr flex center pt_20px text_color_1'>
                    Add companies to the tracker by using transactions to see a
                    performance chart here.
                </div>
            )}
        </div>
    );
};

PortfolioTrackerPerformanceChart.propTypes = {
    performance: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, null)(PortfolioTrackerPerformanceChart);
