import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";

// MUI
import { withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";

// COMPONENTS
import FeedbackIcon from "../../elements/FeedbackIcon";

// ACTIONS
import { setCreatorExperience } from "../../../../actions/portfolioActions";
import { setNPositions } from "../../../../actions/portfolioActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const BlueRadio = withStyles({
    // root: {
    //     "&$checked": {
    //         color: "#3F51B5",
    //     },
    // },
    checked: {},
})((props) => <Checkbox {...props} />);
{
    /* <Radio color='default' {...props} />  */
}

const CreatorAssessment = ({
    portfolio,
    setCreatorExperience,
    setNPositions,
}) => {
    const useintl = useIntl();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [risk_value, setRisk_Value] = React.useState({
        question_1:
            portfolio.in_creation_preferences.risk_assessment_values.question_1,
        question_2:
            portfolio.in_creation_preferences.risk_assessment_values.question_2,
        question_3:
            portfolio.in_creation_preferences.risk_assessment_values.question_3,
        question_4:
            portfolio.in_creation_preferences.risk_assessment_values.question_4,
        question_5:
            portfolio.in_creation_preferences.risk_assessment_values.question_5,
        question_6:
            portfolio.in_creation_preferences.risk_assessment_values.question_6,
        question_7:
            portfolio.in_creation_preferences.risk_assessment_values.question_7,
        question_8:
            portfolio.in_creation_preferences.risk_assessment_values.question_8,
    });

    const [used, setUsed] = React.useState({
        question_1:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_1,
        question_2:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_2,
        question_3:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_3,
        question_4:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_4,
        question_5:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_5,
        question_6:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_6,
        question_7:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_7,
        question_8:
            portfolio.in_creation_preferences.risk_assessment_checked
                .question_8,
    });

    let risk_result = portfolio.in_creation_preferences.risk;

    const handleReduxUpdate = (question, value) => {
        setRisk_Value({
            ...risk_value,
            ["question_" + question]: value,
        });
        setUsed({
            ...used,
            ["question_" + question]: true,
        });

        let values = risk_value;
        values["question_" + question] = value;
        let checked = used;
        checked["question_" + question] = true;
        if (
            checked.question_1 &&
            checked.question_2 &&
            checked.question_3 &&
            checked.question_4 &&
            checked.question_5 &&
            checked.question_6 &&
            checked.question_7 &&
            checked.question_8
        ) {
            risk_result =
                (values.question_1 +
                    values.question_2 +
                    values.question_3 +
                    values.question_4 +
                    values.question_5 +
                    values.question_6 +
                    values.question_7) /
                7;
            if (values.question_8 === 2) {
                risk_result += 0.3333;
            }
            if (values.question_8 === 3) {
                risk_result += 0.6666;
            }
            if (values.question_8 === 4) {
                risk_result += 1;
            }
            risk_result = Number(risk_result.toFixed(0));
        }
        setCreatorExperience(
            risk_result,
            values,
            checked,
            portfolio.in_creation_preferences
        );
        setNPositions(12 + (1 - (risk_result - 1) / 4) * 6);
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.assessment",
        })}`,
        location_index: 1,
    };
    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.assessment",
        })}`,
        location_index: 1,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "portfolio.creator.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/Z2Cy-SHKviU'
                title='The top FEATURES our STOCK MARKET SCREENER offers'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "portfolio.creator.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.header_text_1",
        }),
        description_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.description_text_1",
        }),
    };

    return (
        <div className='height_90pr'>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                informationInfo={informationInfo}
            />
            <div className='text_1 height_70px pl_25px pt_20px pb_15px'>
                <FormattedMessage
                    id='general.assessment_would_you'
                    defaultMessage='Risk Assessment: Would you ...'
                />
            </div>
            <div className='divider_no_margin'></div>
            <div className='mv_auto overflow_y_auto background_color_3'>
                {/* Question 1: */}
                <div className='mt_25px ml_15px mr_15px text_center text_2'>
                    <p>
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_1'
                            defaultMessage='... bet a day’s income at a high-stake poker game?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_1 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(1, 1);
                                    }}
                                    color='primary'
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_1 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(1, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_1 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(1, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='height_50pr flex center'>
                                <BlueRadio
                                    checked={risk_value.question_1 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(1, 4);
                                    }}
                                />
                            </div>
                            <div className='height_50pr text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 2: */}
                <div className='divider width_90pr'></div>
                <div className='mt_25px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_1 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_2'
                            defaultMessage='... bet a day’s income on the outcome of a sporting event?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_2 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(2, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_2 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(2, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_2 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(2, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_2 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(2, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 3: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_2 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_3'
                            defaultMessage='... choose a career that you truly enjoy over a more secure one?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_3 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(3, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_3 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(3, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_3 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(3, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_3 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(3, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 4: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_3 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_4'
                            defaultMessage='... start a new career in your mid-thirties?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_4 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(4, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_4 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(4, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_4 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(4, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_4 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(4, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 5: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_4 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_5'
                            defaultMessage='... start your own business?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_5 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(5, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_5 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(5, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_5 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(5, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_5 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(5, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 6: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_5 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_6'
                            defaultMessage="... borrow a month's salary from a friend?"
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_6 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(6, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_6 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(6, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_6 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(6, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_6 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(6, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 7: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_6 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_7'
                            defaultMessage='... quickly question your point of view, if someone questions it?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_7 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(7, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_7 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(7, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_7 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(7, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_7 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(7, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Question 8: */}
                <div className='divider width_90pr'></div>
                <div className='mt_50px ml_15px mr_15px text_center text_2'>
                    <p
                        className={
                            used.question_7 === false
                                ? "color_grey"
                                : "color_black"
                        }
                    >
                        <FormattedMessage
                            id='portfolio.creator.assessment.question_8'
                            defaultMessage='... press a button, not knowing what it does?'
                        />
                    </p>
                </div>
                <div className='mt_15px ms_auto flex width_80pr_95pr mb_20px'>
                    <div className='ms_auto flex space_around width_100pr'>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_8 === 1}
                                    onClick={() => {
                                        handleReduxUpdate(8, 1);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5'>
                                <FormattedMessage
                                    id='general.extremely_unlikely'
                                    defaultMessage='Extremely Unlikely'
                                />
                            </div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_8 === 2}
                                    onClick={() => {
                                        handleReduxUpdate(8, 2);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5'>
                                <FormattedMessage
                                    id='general.unlikely'
                                    defaultMessage='Unlikely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_8 === 3}
                                    onClick={() => {
                                        handleReduxUpdate(8, 3);
                                    }}
                                />
                            </div>
                            <div className='text_center hide_sub_667px text_5 hide_sub_667px'>
                                <FormattedMessage
                                    id='general.likely'
                                    defaultMessage='Likely'
                                />
                            </div>
                            <div className='text_5 mt_35px show_sub_667px'></div>
                        </div>
                        <div className='width_25pr height_80px flex flex_column start'>
                            <div className='flex center'>
                                <BlueRadio
                                    checked={risk_value.question_8 === 4}
                                    onClick={() => {
                                        handleReduxUpdate(8, 4);
                                    }}
                                />
                            </div>
                            <div className='text_center text_5 overflow_x_auto'>
                                <FormattedMessage
                                    id='general.extremely_likely'
                                    defaultMessage='Extremely Likely'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {used.question_1 &&
                used.question_2 &&
                used.question_3 &&
                used.question_4 &&
                used.question_5 &&
                used.question_6 &&
                used.question_7 &&
                used.question_8 ? (
                    <div className='flex center mt_25px'>
                        <div className='text_2'>
                            <div className='divider width_90pr mb_15px'></div>
                            <FormattedMessage
                                id='portfolio.creator.assessment.result'
                                defaultMessage='Your financial risk tolerance calculated, is '
                            />
                            <div>
                                {" "}
                                <p className='text_weight_6 text_1'>
                                    {risk_result} (
                                    {risk_result === 1 ? (
                                        <FormattedMessage
                                            id='portfolio.creator.assessment.very_low_risk'
                                            defaultMessage='very low risk*'
                                        />
                                    ) : risk_result === 2 ? (
                                        <FormattedMessage
                                            id='portfolio.creator.assessment.low_risk'
                                            defaultMessage='low risk*'
                                        />
                                    ) : risk_result === 3 ? (
                                        <FormattedMessage
                                            id='portfolio.creator.assessment.balanced'
                                            defaultMessage='balanced*'
                                        />
                                    ) : risk_result === 4 ? (
                                        <FormattedMessage
                                            id='portfolio.creator.assessment.high_risk'
                                            defaultMessage='high risk*'
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id='portfolio.creator.assessment.very_high_risk'
                                            defaultMessage='very high risk'
                                        />
                                    )}
                                    )*
                                </p>
                            </div>
                            <div className='text_5 mt_15px'>
                                <FormattedMessage
                                    id='portfolio.creator.assessment.risk_note'
                                    defaultMessage='*can be edited on the next page'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

CreatorAssessment.propTypes = {
    portfolio: PropTypes.object.isRequired,
    setCreatorExperience: PropTypes.func.isRequired,
    setNPositions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, {
    setCreatorExperience,
    setNPositions,
})(CreatorAssessment);
