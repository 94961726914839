import React, { useEffect } from "react";
import HighchartsCustomEvents from "highcharts-custom-events";

// Components
import GUI from "./GUI";

// Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

/*Dialog*/
import Dialog from "@mui/material/Dialog";

HighchartsCustomEvents(Highcharts); // very important, otherwise it won't work on the chart

const StockPrice = ({ company: { company }, ticker }) => {
    /*GUI Modal*/
    const [openGUI, setOpenGUI] = React.useState(false);
    const handleOpenGUI = () => {
        setOpenGUI(true);
    };
    const handleCloseGUI = () => {
        setOpenGUI(false);
    };

    const [relative, setRelative] = React.useState(false);
    const handleSetRelative = () => {
        if (relative) {
            setRelative(false);
        } else {
            setTClose(
                company.stock_prices.map((tohlc) => [tohlc[0] * 1000, tohlc[5]])
            );
            setRelative(true);
            setUnderWater(false);
        }
    };

    const [underWater, setUnderWater] = React.useState(false);
    const handleSetUnderWater = () => {
        setRelative(false);
        if (underWater) {
            setRelative(false);
            setUnderWater(false);
            setTClose(
                company.stock_prices.map((tohlc) => [tohlc[0] * 1000, tohlc[5]])
            );
        } else {
            setRelative(false);
            setUnderWater(true);
            let currentTClose = company.stock_prices.map((tohlc) => [
                tohlc[0] * 1000,
                tohlc[5],
            ]);
            let underWaterTClose = [];

            let lastATH = currentTClose[0][1];

            currentTClose.map((tc) => {
                if (tc[1] >= lastATH) {
                    underWaterTClose.push([tc[0], 0]);
                    lastATH = tc[1];
                } else {
                    underWaterTClose.push([
                        tc[0],
                        Number((100 * (tc[1] / lastATH - 1)).toPrecision(2)),
                    ]);
                }
            });

            setTClose(underWaterTClose);
        }
    };

    useEffect(() => {
        setRelative(false);
        setUnderWater(false);
        setTClose(
            company.stock_prices.map((tohlc) => [tohlc[0] * 1000, tohlc[5]])
        );
        // eslint-disable-next-line
    }, [ticker]);

    const [log, setLog] = React.useState(false);
    const handleLog = () => {
        setLog(!log);
    };

    let [timeFrame, setTimeFrame] = React.useState(4);

    const [tClose, setTClose] = React.useState(
        company.stock_prices.map((tohlc) => [tohlc[0] * 1000, tohlc[5]])
    );

    // let tOHLC = company.stock_prices.map((tohlc) => [
    //     tohlc[0] * 1000,
    //     tohlc[1],
    //     tohlc[2],
    //     tohlc[3],
    //     tohlc[4],
    //     tohlc[5],
    // ]);
    let tVolume = company.stock_prices.map((tohlc) => [
        tohlc[0] * 1000,
        tohlc[6],
    ]);

    var isEvent = false;

    let chartOptions = {
        chart: {
            height: "400px",
        },
        events: {
            load: function () {
                var chart = this;
                chart.labelGroup = chart.renderer.g("labelGroup").add();
                document.addEventListener("mouseup", function () {
                    chart.mouseDown = false;
                    if (chart.label) {
                        chart.label.destroy();
                        chart.label = undefined;
                    }
                    if (chart.selectionRect) {
                        chart.selectionRect.destroy();
                        chart.selectionRect = undefined;
                    }
                });
            },
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        rangeSelector: {
                            selected: 1,
                            buttons: [
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
                {
                    condition: {
                        minWidth: 401,
                    },
                    chartOptions: {
                        rangeSelector: {
                            selected: 3,
                            buttons: [
                                {
                                    type: "month",
                                    count: 3,
                                    text: "3m",
                                },
                                {
                                    type: "month",
                                    count: 6,
                                    text: "6m",
                                },
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "year",
                                    count: 1,
                                    text: "1y",
                                },
                                {
                                    type: "year",
                                    count: 5,
                                    text: "5y",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
            ],
        },
        title: {
            text: "",
        },
        stockTools: {
            // gui: {
            //     buttons: ["fullScreen"],
            // },
            gui: "disabled",
        },
        // xAxis: {
        //     crosshair: false,
        // },
        yAxis: [
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Stockprice",
                },
                height: "85%",
                lineWidth: 2,
                resize: {
                    enabled: true,
                },
            },
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Volume",
                },
                top: "85%",
                height: "15%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        navigator: {
            enabled: true,
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            text: " ",
        },
        plotOptions: {
            series: {
                compare: relative ? "percent" : "undefined",
                marker: {
                    radius: 4,
                },
                point: {
                    events: {
                        click: (e) => {
                            console.log(this);
                            console.log(e.point.category);
                            console.log(e.point.y);
                        },
                    },
                },
                point: {
                    events: {
                        mouseOver() {
                            var point = this,
                                chart = point.series.chart,
                                dif,
                                percentDif,
                                rectWidth,
                                sign,
                                x;

                            if (chart.mouseDown) {
                                if (chart.label) {
                                    chart.label.destroy();
                                    chart.label = undefined;
                                }
                                if (chart.selectionRect) {
                                    chart.selectionRect.destroy();
                                    chart.selectionRect = undefined;
                                }
                                dif = point.y - chart.pointMouseDown.y;
                                percentDif =
                                    Math.round(
                                        (dif / chart.pointMouseDown.y) * 10000
                                    ) /
                                        100 +
                                    "%";

                                rectWidth =
                                    point.plotX - chart.pointMouseDown.plotX;

                                if (rectWidth > 0) {
                                    x =
                                        chart.plotLeft +
                                        chart.pointMouseDown.plotX;
                                    sign = 1;
                                } else {
                                    x = chart.plotLeft + point.plotX;
                                    sign = -1;
                                }

                                chart.selectionRect = chart.renderer
                                    .rect(
                                        x, // x
                                        chart.plotTop, // y
                                        (point.plotX -
                                            chart.pointMouseDown.plotX) *
                                            sign, // width
                                        chart.plotSizeY // height
                                    )
                                    .attr({
                                        fill: "#848b8e",
                                        zIndex: -1,
                                    })
                                    .add();
                                chart.label = chart.renderer
                                    .label(
                                        dif > 0 ? "+" + percentDif : percentDif,
                                        x +
                                            (point.plotX -
                                                chart.pointMouseDown.plotX) *
                                                sign *
                                                0.5,
                                        60
                                    )
                                    .add(chart.labelGroup);
                                // chart.labelGroup
                                //     .translate(-chart.label.width / 2, 0)
                                //     .toFront();
                            }
                        },
                        mousedown: function () {
                            var point = this,
                                chart = point.series.chart;

                            chart.mouseDown = true;
                            chart.pointMouseDown = point;
                        },

                        mouseup: function () {
                            var point = this,
                                chart = point.series.chart;
                            chart.mouseDown = false;
                            if (chart.label) {
                                chart.label.destroy();
                                chart.label = undefined;
                            }
                            if (chart.selectionRect) {
                                chart.selectionRect.destroy();
                                chart.selectionRect = undefined;
                            }
                        },
                    },
                },
            },
        },
        tooltip: {
            shared: true,
            hideDelay: 50,
            pointFormat: relative
                ? '<span style="color:{series.color}">{series.name}</span>: <b>{point.change} %</b><br/>'
                : underWater
                ? '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} from last high</b><br/>'
                : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            changeDecimals: 2,
            valueDecimals: 2,
        },
        series: [
            {
                type: "area",
                name: "Stock Price",
                data: tClose,
                threshold: null,
                id: "stockprice",
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [
                            0,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0.7)
                                .get("rgba"),
                        ],
                        [
                            1,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0)
                                .get("rgba"),
                        ],
                    ],
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: underWater || relative ? " %" : " USD",
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            {
                type: "column",
                name: "Volume",
                data: relative ? null : tVolume,
                visible: relative ? false : true,
                threshold: null,
                yAxis: 1,
                color: Highcharts.getOptions().colors[0],
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
        ],
    };

    return (
        <div>
            <div className='company_overview_stock_price_options flex wrap space_between pl_20px pr_20px mb_10px'>
                <div className='text_2'>Historic Stock Prices</div>
                <div className='flex'>
                    <div
                        className={
                            relative
                                ? "button_white_active pl_10px pr_10px mr_10px hover_opacity_80"
                                : "button_white_layer_2 pl_10px pr_10px mr_10px hover_opacity_80"
                        }
                        onClick={handleSetRelative}
                    >
                        Relative
                    </div>
                    <div
                        className={
                            underWater
                                ? "button_white_active pl_10px pr_10px mr_10px hover_opacity_80"
                                : "button_white_layer_2 pl_10px pr_10px mr_10px hover_opacity_80"
                        }
                        onClick={handleSetUnderWater}
                    >
                        Under water
                    </div>
                    {/* <div
                        className={
                            log
                                ? "button_white_active pl_10px pr_10px mr_10px hover_opacity_80"
                                : "button_white_layer_2 pl_10px pr_10px mr_10px hover_opacity_80"
                        }
                        onClick={handleLog}
                    >
                        Log Timeframe
                    </div> */}
                    {/* <div
                        className='button_blue color_white pl_10px pr_10px display_1100px'
                        onClick={handleOpenGUI}
                        // onClick={() => {
                        //     chart.fullscreen.toggle();
                        // }}
                    >
                        Extended view
                    </div> */}
                </div>
            </div>
            <div className='company_overview_stock_price_max_height'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    constructorType={"stockChart"}
                    containerProps={{ className: "chart" }}
                    className='company_overview_stock_price'
                    style={{ overflow: "visible" }}
                />
            </div>

            {/* GUI modal */}
            {/* <Dialog open={openGUI} onClose={handleCloseGUI} maxWidth={false}>
                <GUI />
            </Dialog> */}
        </div>
    );
};

StockPrice.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(StockPrice);
