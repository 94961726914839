import React from "react";
import { Link } from "react-router-dom";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Convince yourself of the StocksOnView premium features for 2 weeks, completely free!

const TopBarCallToAction = ({ user }) => {
    return user.status === 0 ? (
        <div className='width_100pr flex wrap background_color_3'>
            <span>Use all our features to their full extent and</span>
            <Link to={`/account`} className='ml_3px mr_3px'>
                Upgrade
            </Link>
            <span>your account to Premium status!</span>
        </div>
    ) : user.status === 70 ? (
        <div className='width_100pr padding_10px background_color_3'>
            <div>Premium version test</div>
        </div>
    ) : user.status === 80 ? (
        <div className='width_100pr padding_10px background_color_3'>
            <span>Like what you're seeing?</span>
            <a className=' padding_10px ml_3px mr_3px' href='/register'>
                Create an account
            </a>
            <span>
                to add companies to your own watchlists/ portfolios, follow
                other members and use all our features to their full extent!
            </span>
        </div>
    ) : null;
};

TopBarCallToAction.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, null)(TopBarCallToAction);
