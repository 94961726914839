import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Fallback = () => {
    return (
        <div className='width_100pr flex flex_column center height_100vh'>
            <h1 className='text_center'>Oops, something went wrong</h1>
            <div className='text_center width_60pr min_width_320px'>
                Our development team has been notified and will fix this error
                as soon as possible. If you have additional feedback, feel free
                to fill out the feedback form in the bottom right corner of any
                page in the dashboard and tell us what you miss.
            </div>
            <Link to={`/screener`} className='button_blue padding_10px mt_20px'>
                Back to the Dashboard
            </Link>
        </div>
    );
};

export default Fallback;
