import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import * as IoIcons5 from "react-icons/io5";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";

// COMPONENTS
import StripePage from "../../auth/StripePage";

// ACTIONS
import { setAlert } from "../../../actions/alertActions";
import {
    changePassword,
    openSubscriptionChanges,
    changeEmail,
    changeUserName,
    toggleSimplifiedView,
    deleteAccount,
    sendEmailVerificationMail,
} from "../../../actions/userActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { Context } from "../../../context/LocaleContext";
import { Context_Theme } from "../../../context/ThemeContext";
import { MetricsContext } from "../../../context/MetricsContext";

// MUI
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

// ANALYTICS
import ReactGA from "react-ga4";
const {
    REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID,
    REACT_APP_ENVIRONMENT,
    REACT_APP_EXPRESS_BASE_URL,
} = process.env;

const Account = ({
    sidebar: { sidebar },
    user,
    setAlert,
    changePassword,
    changeEmail,
    changeUserName,
    toggleSimplifiedView,
    openSubscriptionChanges,
    sendEmailVerificationMail,
    deleteAccount,
}) => {
    useEffect(() => {
        // eslint-disable-next-line
    }, [user.subscriptionId]);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    const context = useContext(Context);
    const context_theme = useContext(Context_Theme);
    const context_metrics = useContext(MetricsContext);
    const useintl = useIntl();

    // PAY
    const [openPayModal, setOpenPayModal] = React.useState(false);
    const handleOpenPayModal = () => {
        setOpenPayModal(true);
        openSubscriptionChanges();
    };
    const handleClosePayModal = () => {
        setOpenPayModal(false);
        openSubscriptionChanges();
    };

    // CHANGE PASSWORD DIALOG
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const handleOpenChangePassword = () => {
        openSubscriptionChanges();
        setOpenChangePassword(true);
    };
    const handleCloseChangePassword = () => {
        openSubscriptionChanges();
        setOpenChangePassword(false);
    };
    const handleClickShowPasswordOld = () => {
        setValues({ ...values, showPassword_old: !values.showPassword_old });
    };
    const handleMouseDownPasswordOld = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => {
        setValues({ ...values, showPassword2: !values.showPassword2 });
    };
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const [values, setValues] = React.useState({
        old_password: "",
        password: "",
        repeat_password: "",
    });
    const { old_password, password, repeat_password } = values;
    const handleNewPassword = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const submitNewPassword = () => {
        console.log("1", password, "2", repeat_password, "3", old_password);
        if (password === "" || repeat_password === "" || old_password === "") {
            setAlert("Please fill out all three fields!", "danger");
        } else if (password !== repeat_password) {
            setAlert("Both passwords have to be equal!", "danger");
        } else if (password.length < 8) {
            setAlert(
                "The password should have at least 8 characters",
                "danger"
            );
        } else {
            changePassword(user, password, old_password);
            setValues({
                old_password: "",
                password: "",
                repeat_password: "",
            });
        }
    };

    // CHANGE EMAIL
    const [openEditEmail, setOpenEditEmail] = React.useState(false);
    const handleOpenEditEmail = () => {
        openSubscriptionChanges();
        setOpenEditEmail(true);
    };
    const handleCloseEditEmail = () => {
        openSubscriptionChanges();
        setOpenEditEmail(false);
    };
    const [editEmailValues, setEditEmailValues] = React.useState({
        check_password: "",
        email1: "",
        email2: "",
        showCheckPassword: false,
    });
    const { check_password, email1, email2, showCheckPassword } =
        editEmailValues;
    const handleChangeEditEmail = (prop) => (event) => {
        setEditEmailValues({ ...editEmailValues, [prop]: event.target.value });
    };
    const handleClickShowPasswordEmail = () => {
        setEditEmailValues({
            ...editEmailValues,
            showCheckPassword: !editEmailValues.showCheckPassword,
        });
    };
    const handleMouseDownPasswordEmail = (event) => {
        event.preventDefault();
    };
    const submitNewEmail = () => {
        console.log("1", check_password, "2", email1, "3", email2);
        if (check_password === "" || email1 === "" || email2 === "") {
            setAlert("Please fill out all three fields!", "danger");
        } else if (email1 !== email2) {
            setAlert("Both emails have to be equal!", "danger");
        } else if (email1 !== email2) {
            // CHECK IF IT ACTUALLY IS AN EMAIL
            setAlert("You need to provide a working email adress", "danger");
        } else {
            changeEmail(user, check_password, email1);
            setEditEmailValues({
                check_password: "",
                email1: "",
                email2: "",
                showCheckPassword: false,
            });
        }
    };

    // EMAIL VERIFICATION TIMEOUT
    const verification_min_timeout = 10000;
    const [verificationTime, setVerificationTime] = React.useState(0);

    const handleSendEmailVerification = () => {
        let d = new Date();
        let n = d.getTime();

        if (n - verificationTime > verification_min_timeout) {
            setVerificationTime(n);
            sendEmailVerificationMail(user.token, user.email);
        }
        // else {
        //     setAlert(
        //         "You should have received an email from us recently to verify it.",
        //         "warning"
        //     );
        // }
    };

    // CHANGE USERNAME
    const [openEditName, setOpenEditName] = React.useState(false);
    const handleOpenEditName = () => {
        openSubscriptionChanges();
        setOpenEditName(true);
    };
    const handleCloseEditName = () => {
        openSubscriptionChanges();
        setOpenEditName(false);
    };
    const [editNameValues, setEditNameValues] = React.useState({
        check_password_name: "",
        name1: "",
        name2: "",
        showCheckPasswordName: false,
    });
    const { check_password_name, name1, name2, showCheckPasswordName } =
        editNameValues;
    const handleChangeEditName = (prop) => (event) => {
        setEditNameValues({ ...editNameValues, [prop]: event.target.value });
    };
    const handleClickShowPasswordName = () => {
        setEditNameValues({
            ...editNameValues,
            showCheckPasswordName: !editNameValues.showCheckPasswordName,
        });
    };
    const handleMouseDownPasswordName = (event) => {
        event.preventDefault();
    };
    const submitNewName = () => {
        if (check_password_name === "" || name1 === "" || name2 === "") {
            setAlert("Please fill out all three fields!", "danger");
        } else if (name1 !== name2) {
            setAlert("Both names have to be equal!", "danger");
        } else {
            changeUserName(user, check_password_name, name1);
            setEditNameValues({
                check_password_name: "",
                name1: "",
                name2: "",
                showCheckPasswordName: false,
            });
        }
    };

    // DELETE ACCOUNT
    const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
    const handleOpenDeleteAccount = () => {
        openSubscriptionChanges();
        setOpenDeleteAccount(true);
    };
    const handleCloseDeleteAccount = () => {
        openSubscriptionChanges();
        setOpenDeleteAccount(false);
    };
    const [showPasswordDeleteAccount, setShowPasswordDeleteAccount] =
        React.useState(false);
    const handleClickShowPasswordDeleteAccount = () => {
        setShowPasswordDeleteAccount(!showPasswordDeleteAccount);
    };
    const handleMouseDownPasswordDeleteAccount = (event) => {
        event.preventDefault();
    };
    const [deleteAccountPassword, setDeleteAccountPassword] =
        React.useState("");

    const handleNewPasswordDeleteAccount = (prop) => (event) => {
        setDeleteAccountPassword(event.target.value);
    };
    const submitDeleteAccount = () => {
        if (deleteAccountPassword === "") {
            setAlert("You need to provide a password!", "danger");
        } else {
            deleteAccount(deleteAccountPassword);
            setDeleteAccountPassword("");
        }
    };

    // const cancelSubscription = () => {
    //     if (user.status > 0) {
    //         handleCancelSSE();
    //         return fetch("/cancel-subscription", {
    //             method: "post",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 email: user.email,
    //             }),
    //         })
    //             .then((response) => {
    //                 return response.json();
    //             })
    //             .then((cancelSubscriptionResponse) => {
    //                 // Display to the user that the subscription has been cancelled.
    //                 // NOTIFY USER ABOUT CANCELED SUBSCRIPTION
    //             });
    //     }
    // };
    // const handleCancelSSE = () => {
    //     const sse = new EventSource(
    //         `${
    //             REACT_APP_EXPRESS_BASE_URL +
    //             "/cancel_subscription_stream?user_id=" +
    //             user.stripeCustomerId
    //         }`,
    //         {
    //             withCredentials: true,
    //         }
    //     );

    //     sse.onmessage = (e) => {
    //         let response = JSON.parse(e.data);
    //         if (Object.keys(response).length === 0) {
    //             // KEEP REQUESTING
    //             console.log("NO RESPONSE YET");
    //         } else {
    //             // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
    //             console.log("RESPONSE CAME IN");
    //             sse.close();
    //         }
    //     };
    //     sse.onerror = () => {
    //         // error log here
    //         sse.close();
    //     };
    //     // return () => {
    //     //     sse.close();
    //     // };
    // };

    // THEME
    const [darkMode, setDarkMode] = React.useState(
        localStorage.theme
            ? localStorage.theme === "dark"
                ? true
                : false
            : window.matchMedia("(prefers-color-scheme: dark)" ? true : false)
    );
    const applyDarkMode = () => {
        if (!darkMode) {
            document.body.classList.toggle("dark");
            context_theme.selectTheme("dark");
            setDarkMode(true);
        } else {
            document.body.classList.toggle("dark");
            context_theme.selectTheme("light");
            setDarkMode(false);
        }
    };

    // LANGUAGE
    const [language, setLanguage] = React.useState(context.locale);
    const handleLanguageChange = (event) => {
        if (event.target.value !== language) {
            setLanguage(event.target.value);
        }
    };

    // SIMPLIFIED VIEW
    const [checkedSimplifiedView, setCheckedSimplifiedView] = React.useState(
        localStorage.simplified_view
            ? localStorage.simplified_view === "true"
                ? true
                : false
            : user.simplified_view
            ? true
            : false
    );
    const handleChangeSimplifiedView = () => {
        setCheckedSimplifiedView(!checkedSimplifiedView);
        toggleSimplifiedView(!checkedSimplifiedView);
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Account | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Control your account data aswell as layout preferences here.'
                />
            </Helmet>
            <div className='width_100pr height_100vh background_color_1 border_box'>
                <div className='width_70pr max_width_1000px ms_auto flex space_between'>
                    <div className='mt_25px flex'>
                        {user ? (
                            <div className='text_1 text_color_1'>
                                {user.name}
                            </div>
                        ) : (
                            <div className='text_1 text_color_1'>
                                {useintl.formatMessage({
                                    id: "login.welcome_back",
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {/* ACCOUNT DETAILS SECTION */}
                <div className='width_70pr_95pr shadow_dark height_380px_480px min_height_350px min_width_320px max_width_1000px ms_auto mt_20px border_radius_15px background_color_2 border_box padding_20px'>
                    {/* HEADING */}
                    <div className='width_100pr height_auto flex space_between mb_20px'>
                        <div className='text_1 text_color_1'>
                            {useintl.formatMessage({
                                id: "account.account_details",
                            })}
                        </div>
                        <div className='ml_40px text_3 text_color_1'>
                            {user.status === 0
                                ? "Free"
                                : user.status === 1
                                ? "Premium"
                                : user.status === 80
                                ? "Demo"
                                : user.status === 90 || user.status === 99
                                ? "Beta tester"
                                : user.status === 100
                                ? "Admin"
                                : "-"}
                        </div>

                        {/* {user.status < 2 ? (
                            <div className='ml_15px button_blue width_120px hover_opacity_80'>
                                Upgrade
                            </div>
                        ) : null} */}
                    </div>

                    {/* LOGIN METHOD, REGISTRATION DATE */}
                    <div className='width_100pr height_auto flex wrap left mt_30px'>
                        <div className='width_50pr min_width_200px'>
                            <div className='text_2 text_color_1'>
                                {useintl.formatMessage({
                                    id: "account.login_method",
                                })}
                            </div>
                            <div className='text_3 text_color_2 pl_10px'>
                                {user.preferred_login_method === 0
                                    ? "Email login"
                                    : user.preferred_login_method === 1
                                    ? "Google"
                                    : "-"}
                            </div>
                        </div>
                        <div className='width_50pr min_width_200px'>
                            <div className='text_2 text_color_1'>
                                {useintl.formatMessage({
                                    id: "account.registration_date",
                                })}
                            </div>
                            <div className='text_3 text_color_2 pl_10px'>
                                {user.date && user.date !== null
                                    ? new Intl.DateTimeFormat("en-us", {
                                          weekday: "long",
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                      }).format(new Date(user.date))
                                    : null}
                            </div>
                        </div>
                    </div>

                    {/* EMAIL AND NAME */}
                    <div className='width_100pr height_auto flex left wrap mt_30px'>
                        {/* EDIT EMAIL */}
                        <div className='width_50pr min_width_200px'>
                            <div className='text_2 text_color_1 flex space_between width_220px_100pr max_width_250px'>
                                <div className='flex'>
                                    <div>Email</div>
                                    {user.status !== 80 ? (
                                        <MdIcons.MdModeEditOutline
                                            className='ml_5px cursor_pointer'
                                            onClick={handleOpenEditEmail}
                                        />
                                    ) : null}
                                </div>
                                {user.email_verified ||
                                user.preferred_login_method !== 0 ? null : (
                                    <div
                                        className='text_2 ml_50px text_color_blue cursor_pointer ps_5px pt_2px pb_2px'
                                        onClick={handleSendEmailVerification}
                                    >
                                        Confirm
                                    </div>
                                )}
                            </div>
                            <div className='text_3 text_color_2 pl_10px'>
                                {user.email}
                            </div>
                        </div>
                        {/* Change Email Modal */}
                        <Dialog
                            open={openEditEmail && user.openChanges}
                            onClose={handleCloseEditEmail}
                            maxWidth={false}
                            className=''
                        >
                            <div className='max_width_500px background_color_2 text_color_1'>
                                <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                    <div className='mr_10px'>
                                        {"Change Email"}
                                    </div>
                                    <div
                                        className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                        onClick={handleCloseEditEmail}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                <div className='flex flex_column border_box padding_10px'>
                                    {/* PASSWORD */}
                                    <div className='flex center mt_25px width_250px ms_auto'>
                                        <FormControl
                                            className=''
                                            variant='outlined'
                                        >
                                            <InputLabel htmlFor='outlined-adornment-password'>
                                                {useintl.formatMessage({
                                                    id: "general.password",
                                                })}
                                            </InputLabel>
                                            <OutlinedInput
                                                className='width_250px background_color_3 text_color_1'
                                                id='outlined-adornment-password'
                                                type={
                                                    editEmailValues.showCheckPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                value={
                                                    editEmailValues.check_password
                                                }
                                                onChange={handleChangeEditEmail(
                                                    "check_password"
                                                )}
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={
                                                                handleClickShowPasswordEmail
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPasswordEmail
                                                            }
                                                            edge='end'
                                                        >
                                                            {editEmailValues.showCheckPassword ? (
                                                                <IoIcons5.IoEyeOutline />
                                                            ) : (
                                                                <IoIcons5.IoEyeOffOutline />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={70}
                                                label={useintl.formatMessage({
                                                    id: "general.password",
                                                })}
                                            />
                                        </FormControl>
                                    </div>
                                    {/* EMAIL 1 */}
                                    <div className='flex center mt_40px ms_auto'>
                                        <TextField
                                            className='width_250px pl_10px background_color_3'
                                            id='outlined-basic'
                                            label='Email'
                                            variant='outlined'
                                            onChange={handleChangeEditEmail(
                                                "email1"
                                            )}
                                            required
                                        />
                                    </div>
                                    {/* EMAIL 2 */}
                                    <div className='flex center mt_15px ms_auto'>
                                        <TextField
                                            className='width_250px pl_10px background_color_3'
                                            id='outlined-basic'
                                            label='Confirm Email'
                                            variant='outlined'
                                            onChange={handleChangeEditEmail(
                                                "email2"
                                            )}
                                            required
                                        />
                                    </div>
                                    {/* SUBMIT NEW EMAIL */}
                                    <div
                                        className='min_width_150px mt_20px mb_10px'
                                        onClick={submitNewEmail}
                                    >
                                        <div className='button_blue padding_10px hover_opacity_80'>
                                            <div className='text_3'>
                                                {useintl.formatMessage({
                                                    id: "general.submit",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>

                        {/* EDIT NAME */}
                        <div className='width_50pr min_width_200px'>
                            <div className='text_2 text_color_1 flex'>
                                <div>Name</div>
                                {user.status !== 80 ? (
                                    <MdIcons.MdModeEditOutline
                                        className='ml_5px cursor_pointer'
                                        onClick={handleOpenEditName}
                                    />
                                ) : null}
                            </div>
                            <div className='text_3 text_color_2 pl_10px'>
                                {user.name}
                            </div>
                        </div>
                        {/* Change Name Modal */}
                        <Dialog
                            open={openEditName && user.openChanges}
                            onClose={handleCloseEditName}
                            maxWidth={false}
                            className=''
                        >
                            <div className='max_width_500px background_color_2 text_color_1'>
                                <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                    <div className='mr_10px'>
                                        {"Change Username"}
                                    </div>
                                    <div
                                        className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                        onClick={handleCloseEditName}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                <div className='flex flex_column border_box padding_10px'>
                                    {/* PASSWORD */}
                                    <div className='flex center mt_25px width_250px ms_auto'>
                                        <FormControl
                                            className=''
                                            variant='outlined'
                                        >
                                            <InputLabel htmlFor='outlined-adornment-password'>
                                                {useintl.formatMessage({
                                                    id: "general.password",
                                                })}
                                            </InputLabel>
                                            <OutlinedInput
                                                className='width_250px background_color_3 text_color_1'
                                                id='outlined-adornment-password'
                                                type={
                                                    editNameValues.showCheckPasswordName
                                                        ? "text"
                                                        : "password"
                                                }
                                                value={
                                                    editNameValues.check_password_name
                                                }
                                                onChange={handleChangeEditName(
                                                    "check_password_name"
                                                )}
                                                endAdornment={
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={
                                                                handleClickShowPasswordName
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPasswordName
                                                            }
                                                            edge='end'
                                                        >
                                                            {editNameValues.showCheckPasswordName ? (
                                                                <IoIcons5.IoEyeOutline />
                                                            ) : (
                                                                <IoIcons5.IoEyeOffOutline />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                labelWidth={70}
                                                label={useintl.formatMessage({
                                                    id: "general.password",
                                                })}
                                            />
                                        </FormControl>
                                    </div>
                                    {/* NAME 1 */}
                                    <div className='flex center mt_40px ms_auto'>
                                        <TextField
                                            className='width_250px pl_10px background_color_3'
                                            id='outlined-basic'
                                            label='Name'
                                            variant='outlined'
                                            onChange={handleChangeEditName(
                                                "name1"
                                            )}
                                            required
                                        />
                                    </div>
                                    {/* NAME 2 */}
                                    <div className='flex center mt_15px ms_auto'>
                                        <TextField
                                            className='width_250px pl_10px background_color_3'
                                            id='outlined-basic'
                                            label='Confirm Name'
                                            variant='outlined'
                                            onChange={handleChangeEditName(
                                                "name2"
                                            )}
                                            required
                                        />
                                    </div>
                                    {/* SUBMIT NEW NAME */}
                                    <div
                                        className='min_width_150px mt_20px mb_10px'
                                        onClick={submitNewName}
                                    >
                                        <div className='button_blue padding_10px hover_opacity_80'>
                                            <div className='text_3'>
                                                {useintl.formatMessage({
                                                    id: "general.submit",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                    {/* CHANGE PASSWORD / SUBSCRIPTION STATUS */}
                    {user.status !== 80 ? (
                        <div className='width_100pr height_auto flex wrap left mt_10px'>
                            {/* CHANGE PASSWORD */}
                            <div className='width_50pr width_220px_100pr'>
                                {user.preferred_login_method === 0 ? (
                                    <div
                                        className='width_220px_100pr mt_20px'
                                        onClick={handleOpenChangePassword}
                                    >
                                        <div className='button_blue width_220px_100pr max_width_250px padding_10px hover_opacity_80'>
                                            <div className='text_3'>
                                                {useintl.formatMessage({
                                                    id: "account.change_password",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* Change Password Modal */}
                            <Dialog
                                open={openChangePassword && user.openChanges}
                                onClose={handleCloseChangePassword}
                                maxWidth={false}
                                className=''
                            >
                                <div className='max_width_500px background_color_2 text_color_1'>
                                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                        <div className='mr_10px'>
                                            {"Change password"}
                                        </div>
                                        <div
                                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                            onClick={handleCloseChangePassword}
                                        >
                                            <AiIcons.AiOutlineClose />
                                        </div>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='flex flex_column border_box padding_10px'>
                                        {/* OLD PASSWORD */}
                                        <div className='flex center mt_25px width_250px ms_auto'>
                                            <FormControl
                                                className=''
                                                variant='outlined'
                                            >
                                                <InputLabel htmlFor='outlined-adornment-password'>
                                                    {useintl.formatMessage({
                                                        id: "general.old_password",
                                                    })}
                                                </InputLabel>
                                                <OutlinedInput
                                                    className='width_250px background_color_3 text_color_1'
                                                    id='outlined-adornment-password'
                                                    type={
                                                        values.showPassword_old
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={values.old_password}
                                                    onChange={handleNewPassword(
                                                        "old_password"
                                                    )}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPasswordOld
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPasswordOld
                                                                }
                                                                edge='end'
                                                            >
                                                                {values.showPassword_old ? (
                                                                    <IoIcons5.IoEyeOutline />
                                                                ) : (
                                                                    <IoIcons5.IoEyeOffOutline />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                    label={useintl.formatMessage(
                                                        {
                                                            id: "general.old_password",
                                                        }
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        {/* NEW PASSWORD 1 */}
                                        <div className='flex center mt_25px width_250px ms_auto'>
                                            <FormControl
                                                className=''
                                                variant='outlined'
                                            >
                                                <InputLabel htmlFor='outlined-adornment-password'>
                                                    {useintl.formatMessage({
                                                        id: "general.password",
                                                    })}
                                                </InputLabel>
                                                <OutlinedInput
                                                    className='width_250px background_color_3 text_color_1'
                                                    id='outlined-adornment-password'
                                                    type={
                                                        values.showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={values.password}
                                                    onChange={handleNewPassword(
                                                        "password"
                                                    )}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge='end'
                                                            >
                                                                {values.showPassword ? (
                                                                    <IoIcons5.IoEyeOutline />
                                                                ) : (
                                                                    <IoIcons5.IoEyeOffOutline />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                    label={useintl.formatMessage(
                                                        {
                                                            id: "general.password",
                                                        }
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        {/* NEW PASSWORD 2 */}
                                        <div className='flex center mt_25px width_250px ms_auto'>
                                            <FormControl
                                                className=''
                                                variant='outlined'
                                            >
                                                <InputLabel htmlFor='outlined-adornment-password'>
                                                    {useintl.formatMessage({
                                                        id: "general.repeat_password",
                                                    })}
                                                </InputLabel>
                                                <OutlinedInput
                                                    className='width_250px background_color_3 text_color_1'
                                                    id='outlined-adornment-password'
                                                    type={
                                                        values.showPassword2
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={
                                                        values.repeat_password
                                                    }
                                                    onChange={handleNewPassword(
                                                        "repeat_password"
                                                    )}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPassword2
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword2
                                                                }
                                                                edge='end'
                                                            >
                                                                {values.showPassword2 ? (
                                                                    <IoIcons5.IoEyeOutline />
                                                                ) : (
                                                                    <IoIcons5.IoEyeOffOutline />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                    label={useintl.formatMessage(
                                                        {
                                                            id: "general.repeat_password",
                                                        }
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        {/* SUBMIT NEW PASSWORD */}
                                        <div className='min_width_150px mt_20px mb_10px'>
                                            <div className='button_blue padding_10px hover_opacity_80'>
                                                <div
                                                    className='text_3'
                                                    onClick={submitNewPassword}
                                                >
                                                    {useintl.formatMessage({
                                                        id: "general.submit",
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>

                            {/* EDIT SUBSCRIPTION */}
                            <div className='width_50pr width_220px_100pr'>
                                {user.status < 2 ? (
                                    <div className='mt_20px'>
                                        <div
                                            className='button_blue width_220px_100pr max_width_250px padding_10px hover_opacity_80'
                                            onClick={handleOpenPayModal}
                                        >
                                            <div className='text_3'>
                                                {user.status === 0
                                                    ? "Upgrade to premium"
                                                    : "Edit subscription"}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    {/* DELETE ACCOUNT */}
                    {user.status !== 80 ? (
                        <div className='width_100pr height_auto flex wrap left mt_10px'>
                            <div className='width_50pr width_220px_100pr'>
                                <div
                                    className='width_220px_100pr mt_10px'
                                    onClick={handleOpenDeleteAccount}
                                >
                                    <div className='button_delete width_220px_100pr max_width_250px padding_10px hover_opacity_80'>
                                        <div className='text_3'>
                                            Delete account
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Delete Account Modal */}
                            <Dialog
                                open={openDeleteAccount && user.openChanges}
                                onClose={handleCloseDeleteAccount}
                                maxWidth={false}
                                className=''
                            >
                                <div className='max_width_500px background_color_2 text_color_1'>
                                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                        <div className='mr_10px'>
                                            {"Delete account"}
                                        </div>
                                        <div
                                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                            onClick={handleCloseDeleteAccount}
                                        >
                                            <AiIcons.AiOutlineClose />
                                        </div>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='flex flex_column border_box padding_10px'>
                                        {/* PASSWORD */}
                                        <div className='flex center mt_25px width_250px ms_auto'>
                                            <FormControl
                                                className=''
                                                variant='outlined'
                                            >
                                                <InputLabel htmlFor='outlined-adornment-password'>
                                                    {useintl.formatMessage({
                                                        id: "general.password",
                                                    })}
                                                </InputLabel>
                                                <OutlinedInput
                                                    className='width_250px background_color_3 text_color_1'
                                                    id='outlined-adornment-password'
                                                    type={
                                                        showPasswordDeleteAccount
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    value={
                                                        deleteAccountPassword
                                                    }
                                                    onChange={handleNewPasswordDeleteAccount(
                                                        "password"
                                                    )}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPasswordDeleteAccount
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPasswordDeleteAccount
                                                                }
                                                                edge='end'
                                                            >
                                                                {showPasswordDeleteAccount ? (
                                                                    <IoIcons5.IoEyeOutline />
                                                                ) : (
                                                                    <IoIcons5.IoEyeOffOutline />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                    label={useintl.formatMessage(
                                                        {
                                                            id: "general.password",
                                                        }
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        {/* WARNING */}
                                        <div className='flex center mt_25px width_250px ms_auto'>
                                            We value your privacy. This is why
                                            your account will be deleted
                                            immediately and can not be restored!
                                            All open subscriptions are going to
                                            be cancelled automatically.
                                        </div>
                                        {/* SUBMIT ACCOUNT DELETION */}
                                        <div className='min_width_150px mt_20px mb_10px'>
                                            <div className='button_blue padding_10px hover_opacity_80'>
                                                <div
                                                    className='text_3'
                                                    onClick={
                                                        submitDeleteAccount
                                                    }
                                                >
                                                    {useintl.formatMessage({
                                                        id: "general.submit",
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                            <div className='width_50pr min_width_200px'>
                                <div className='text_2 text_color_1'></div>
                                <div className='text_3 text_color_2 pl_10px'></div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* Stripe Modal */}
                <Dialog
                    open={openPayModal && user.openChanges}
                    onClose={handleClosePayModal}
                    maxWidth={false}
                >
                    <div className='height_auto background_color_2 text_color_1'>
                        {user.email_verified ? (
                            <div>
                                <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                    <div className='mr_10px'>
                                        {user.status === 0
                                            ? "Upgrade to premium"
                                            : "Edit Subscription"}
                                    </div>
                                    <div
                                        className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                        onClick={handleClosePayModal}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                <StripePage />
                            </div>
                        ) : (
                            <div className='padding_10px'>
                                You need to verify your email adress first. Just
                                click the confirm button next to where you can
                                edit your email adress in the "Account details"
                                section and follow the instructions sent to your
                                email adress.
                            </div>
                        )}
                    </div>
                </Dialog>
                {/* PREFERENCES SECTION */}
                <div className='width_70pr_95pr shadow_dark height_auto min_height_300px min_width_320px max_width_1000px ms_auto mt_20px mb_20px border_radius_15px background_color_2 border_box padding_20px'>
                    {/* HEADING */}
                    <div className='width_100pr flex left mb_20px'>
                        <div className='text_1 text_color_1'>
                            {useintl.formatMessage({
                                id: "account.preferences",
                            })}
                        </div>
                    </div>
                    <div className='flex width_100pr space_between wrap'>
                        {/* CHANGE LANGUAGE */}
                        {/* <div className='width_50pr_100pr flex left mt_20px'>
                            <div className='width_220px_100pr'>
                                <div className='text_2 text_color_1 mb_10px'>
                                    {useintl.formatMessage({
                                        id: "account.language",
                                    })}
                                </div>

                                <div className='ml_10px_0px width_220px_100pr'>
                                    <FormControl fullWidth size='small'>
                                        <Select
                                            id='demo-simple-select'
                                            className='background_color_3 text_color_1'
                                            value={language}
                                            defaultValue={context.locale}
                                            onChange={handleLanguageChange}
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    context.selectLang("en-us");
                                                    window.location.reload();
                                                }}
                                                value={"en-us"}
                                            >
                                                <div className='pl_10px'>
                                                    English
                                                </div>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    context.selectLang("de-de");
                                                    window.location.reload();
                                                }}
                                                value={"de-de"}
                                            >
                                                <div className='pl_10px'>
                                                    Deutsch
                                                </div>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div> */}
                        {/* CHANGE THEME */}
                        <div className='width_50pr_100pr flex left mt_20px'>
                            <div className='width_100pr'>
                                <div className='text_2 text_color_1 mb_10px'>
                                    Dark mode
                                </div>
                                {/* <div className='width_220px_100pr height_35px accent_color_2 text_3 text_color_1 ml_10px_0px pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                                    <div
                                        className={
                                            darkMode
                                                ? "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                                : "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                        }
                                        onClick={() => {
                                            applyDarkMode(false);
                                        }}
                                    >
                                        {useintl.formatMessage({
                                            id: "account.light",
                                        })}
                                    </div>
                                    <div
                                        className={
                                            darkMode
                                                ? "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                                : "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                        }
                                        onClick={() => {
                                            applyDarkMode(true);
                                        }}
                                    >
                                        {useintl.formatMessage({
                                            id: "account.dark",
                                        })}
                                    </div>
                                </div> */}
                                <Switch
                                    checked={darkMode}
                                    onClick={applyDarkMode}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </div>
                        </div>

                        {/* SIMPLIFIED VIEW TOGGLE */}
                        <div className='width_50pr_100pr flex left mt_20px'>
                            <div className='width_100pr'>
                                <div className='text_2 text_color_1 mb_10px'>
                                    Simplified view
                                </div>
                                <Switch
                                    checked={checkedSimplifiedView}
                                    onClick={handleChangeSimplifiedView}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Account.propTypes = {
    sidebar: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    openSubscriptionChanges: PropTypes.func.isRequired,
    changeEmail: PropTypes.func.isRequired,
    changeUserName: PropTypes.func.isRequired,
    toggleSimplifiedView: PropTypes.func.isRequired,
    sendEmailVerificationMail: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    changePassword,
    changeEmail,
    changeUserName,
    toggleSimplifiedView,
    openSubscriptionChanges,
    sendEmailVerificationMail,
    deleteAccount,
})(Account);
