import React, { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as IoIcons5 from "react-icons/io5";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { MetricsContext } from "../../../../context/MetricsContext";

// ACTIONS
import { numberFormatterfour } from "../../../../actions/generalActions";

const ScreenerPresetScore = ({
    preset,
    company: { company },
    handleHistogram,
    handleClickOpenHistogram,
}) => {
    const useintl = useIntl();

    // METRICS DISPLAYABLE
    const metrics = useContext(MetricsContext);
    //possible Options Values
    let possible_options = metrics.possible_options;
    //possible Slider Range Keys
    let possible_ranges = metrics.possible_ranges;

    let sorted_metrics_object = metrics.sorted_metrics_object;
    let sorted_rankers_object = metrics.sorted_rankers_object;

    const [maxScore, setMaxScore] = useState(0);
    const [fulfilledCriteria, setFulfilledCriteria] = useState(0);
    let [scoreValues, setScoreValues] = useState({});
    let [scoreParams, setScoreParams] = useState({});

    useEffect(() => {
        let params_arr = preset.url.slice(1).split("&");

        let amount_slider_metrics = 0;
        let fulfilled_criteria = 0;
        let score_details = {};

        let sorted_merged_object = {};

        Object.keys(sorted_metrics_object).map((category) => {
            sorted_merged_object[category] = sorted_metrics_object[category];
            if (sorted_rankers_object[category]) {
                sorted_merged_object[category] = sorted_metrics_object[
                    category
                ].concat(sorted_rankers_object[category]);
            }
        });

        // {
        //     name: useintl.formatMessage({
        //         id: `scores.profitability`,
        //     }),
        //     values: [],
        // }

        let raw_score_params = {};
        params_arr.forEach((element) => {
            let keyvalue = element.split("=");
            let key = keyvalue[0];
            if (key[key.length - 1] === ":") {
                let original_key = key.slice(0, key.length - 1);
                if (possible_ranges.includes(original_key)) {
                    amount_slider_metrics += 1;

                    let value = keyvalue[1].split("_").map(Number);
                    raw_score_params[original_key] = value;

                    if (
                        company[original_key] >= value[0] &&
                        company[original_key] <= value[1]
                    ) {
                        fulfilled_criteria += 1;
                    }
                }
            } else if (possible_ranges.includes(key)) {
                amount_slider_metrics += 1;

                let value = keyvalue[1].split("_").map(Number);
                raw_score_params[key] = value;

                if (company[key] >= value[0] && company[key] <= value[1]) {
                    fulfilled_criteria += 1;
                }
            }

            //All Range-slider Values
        });

        Object.keys(sorted_merged_object).map((category) => {
            sorted_merged_object[category].map((parameter) => {
                if (raw_score_params[parameter] && !score_details[category]) {
                    score_details = {
                        ...score_details,
                        [category]: {
                            name: useintl.formatMessage({
                                id: `metrics.${category}`,
                            }),
                            values: [
                                {
                                    name: useintl.formatMessage({
                                        id: `metrics.${parameter}`,
                                    }),
                                    raw_value:
                                        company[parameter] !== null
                                            ? Number(
                                                  company[
                                                      parameter
                                                  ].toPrecision(4)
                                              )
                                            : null,
                                    value:
                                        company[parameter] !== null
                                            ? `${numberFormatterfour(
                                                  Number(
                                                      company[
                                                          parameter
                                                      ].toPrecision(4)
                                                  )
                                              )}`
                                            : "-",
                                    crit:
                                        company[parameter] !== null
                                            ? company[parameter] >=
                                                  raw_score_params[
                                                      parameter
                                                  ][0] &&
                                              company[parameter] <=
                                                  raw_score_params[parameter][1]
                                            : false,
                                    histogram_name: useintl.formatMessage({
                                        id: `metrics.${parameter}`,
                                    }),
                                    histogram_value: `${parameter}`,
                                    histogram_unit: "%",
                                    histogram_change: false,
                                },
                            ],
                        },
                    };
                } else if (
                    raw_score_params[parameter] &&
                    score_details[category]
                ) {
                    score_details[category].values.push({
                        name: useintl.formatMessage({
                            id: `metrics.${parameter}`,
                        }),
                        raw_value:
                            company[parameter] !== null
                                ? Number(company[parameter].toPrecision(4))
                                : null,
                        value:
                            company[parameter] !== null
                                ? `${numberFormatterfour(
                                      Number(company[parameter].toPrecision(4))
                                  )}`
                                : "-",
                        crit:
                            company[parameter] !== null
                                ? company[parameter] >=
                                      raw_score_params[parameter][0] &&
                                  company[parameter] <=
                                      raw_score_params[parameter][1]
                                : false,
                        histogram_name: useintl.formatMessage({
                            id: `metrics.${parameter}`,
                        }),
                        histogram_value: `${parameter}`,
                        histogram_unit: "%",
                        histogram_change: false,
                    });
                }
            });
        });

        setMaxScore(amount_slider_metrics);
        setFulfilledCriteria(fulfilled_criteria);
        setScoreValues(score_details);
        setScoreParams(raw_score_params);

        // eslint-disable-next-line
    }, []);

    // SHOW METRICS OF THE SCORE
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className='shadow_dark background_color_3 width_30pr_100pr min_width_320px max_width_500px border_radius_10px bg_color_white ml_20px_0px mr_20px_0px mb_20px'>
            <div className='company_scores_piotrosky_header_box ps_10px'>
                <div className='title_5 flex center'>
                    <div>{preset.name}</div>
                    {/* <AiIcons.AiOutlineInfoCircle
                        className='text_2 mt_5px cursor_pointer ml_10px'
                        onClick={() => {
                            // handleInfoScore("altman_z_score");
                            // handleClickOpenInfo();
                        }}
                    /> */}
                </div>
                <div className='text_color_blue text_1 center'>{`${fulfilledCriteria}/${maxScore}`}</div>
                <div className='center'>
                    {open ? (
                        <IoIcons5.IoChevronUpSharp
                            className='cursor_pointer'
                            onClick={() => {
                                handleClose();
                            }}
                        />
                    ) : (
                        <IoIcons5.IoChevronDownSharp
                            className='cursor_pointer center'
                            onClick={() => {
                                handleOpen();
                            }}
                        />
                    )}
                </div>
                {open ? (
                    <div className='ps_10px background_color_3'>
                        {Object.keys(scoreValues).map((subScore) => (
                            <div className='compay_score_body_section'>
                                <div className='text_2 center mt_15px'>
                                    {scoreValues[subScore].name}
                                </div>
                                <div className='company_score_divider_thicc'></div>
                                <div className=''>
                                    {scoreValues[subScore].values.map(
                                        (item, index) => (
                                            <div>
                                                {index !== 0 ? (
                                                    <div className='company_score_divider_thin'></div>
                                                ) : null}
                                                <div className='flex flex_column mb_10px'>
                                                    <div className='compay_score_body_section_body_grid mt_5px width_100pr'>
                                                        <div>{item.name}</div>
                                                        <div className='ml_10px'>
                                                            {item.value}
                                                        </div>
                                                        <div>
                                                            {item.crit ? (
                                                                <IoIcons5.IoCheckmarkSharp className='text_color_green' />
                                                            ) : (
                                                                <IoIcons5.IoCloseSharp className='text_color_red' />
                                                            )}
                                                        </div>
                                                        <div>
                                                            {item.histogram_name !==
                                                            null ? (
                                                                <AiIcons.AiOutlineFullscreen
                                                                    className='screener_icon_widen cursor_pointer'
                                                                    onClick={() => {
                                                                        handleHistogram(
                                                                            item
                                                                        );
                                                                        handleClickOpenHistogram();
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {/* <AiIcons.AiOutlineInfoCircle
                                                        className='screener_icon_info cursor_pointer'
                                                        // onClick={handleClickOpenInfo}
                                                    /> */}
                                                        </div>
                                                    </div>

                                                    <div className='flex left pl_15px width_100pr text_4'>
                                                        (
                                                        <div className='pr_10px'>
                                                            Between
                                                        </div>{" "}
                                                        {scoreParams[
                                                            item.histogram_value
                                                        ][0] <=
                                                        -10000000000000000 ? (
                                                            <div className='flex pr_10px'>
                                                                <p className='pb_3px mr_3px'>
                                                                    -
                                                                </p>
                                                                <IoIcons5.IoInfiniteOutline />
                                                            </div>
                                                        ) : (
                                                            <div className='pr_10px'>
                                                                {numberFormatterfour(
                                                                    Number(
                                                                        scoreParams[
                                                                            item
                                                                                .histogram_value
                                                                        ][0]
                                                                    ).toFixed(3)
                                                                )}
                                                            </div>
                                                        )}{" "}
                                                        <div className='flex pr_10px'>
                                                            and
                                                        </div>{" "}
                                                        {scoreParams[
                                                            item.histogram_value
                                                        ][1] >=
                                                        10000000000000000 ? (
                                                            <div className='flex'>
                                                                <p className='mr_3px'>
                                                                    +
                                                                </p>
                                                                <IoIcons5.IoInfiniteOutline className='mt_3px' />
                                                            </div>
                                                        ) : (
                                                            numberFormatterfour(
                                                                Number(
                                                                    scoreParams[
                                                                        item
                                                                            .histogram_value
                                                                    ][1]
                                                                ).toFixed(3)
                                                            )
                                                        )}
                                                        )
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

ScreenerPresetScore.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(ScreenerPresetScore);
