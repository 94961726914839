import React from "react";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";

// MUI
import Dialog from "@mui/material/Dialog";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { setPortfolio } from "../../../actions/portfolioActions";
import { setAlert } from "../../../actions/alertActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const CreatePortfolio = ({
    closeModal,
    portfolio,
    user,
    setPortfolio,
    setAlert,
}) => {
    /*Create Select Portfolio Icon Modal*/
    const [openPortfolioIcon, setOpenPortfolioIcon] = React.useState(false);
    const handleClickOpenPortfolioIcon = () => {
        setOpenPortfolioIcon(true);
    };
    const handleClosePortfolioIcon = () => {
        setOpenPortfolioIcon(false);
    };

    /*Create new Portfolio functions*/
    const [newPortfolio, setNewPortfolio] = React.useState({
        id: null,
        name: "",
        icon: "collection",
        description: "",
    });
    const handleNewPortfolioName = (event) => {
        if (event.target.value.length <= 20) {
            setNewPortfolio({ ...newPortfolio, name: event.target.value });
        }
    };
    const handlenewPortfolioIcon = (icon, vector) => {
        setNewPortfolio({ ...newPortfolio, icon: icon });
    };
    const handleNewPortfolioDescription = (event) => {
        if (event.target.value.length <= 500) {
            setNewPortfolio({
                ...newPortfolio,
                description: event.target.value,
            });
        }
    };

    const handleSetPortfolio = () => {
        if (newPortfolio.name !== "") {
            setPortfolio(
                {
                    name: newPortfolio.name,
                    icon: newPortfolio.icon,
                    description: newPortfolio.description,
                    user: user.id,
                },
                portfolio,
                user.status
            );
            setNewPortfolio({
                id: null,
                name: "",
                icon: "collection",
                description: "",
            });
            closeModal();
            // setCurrentPortfolio(id);
            // getPortfolioCompanies(null, user.id, portfolio.portfolios);
            // routeRedirect(`/portfolio?id=${id}`);
        } else {
            setAlert(
                `You need to provide a name for your portfolio.`,
                "danger"
            );
        }
    };

    return (
        <div>
            <div className='text_color_1 background_color_2 max_width_500px'>
                <div className='flex space_between title_4 pt_15px  ml_15px mr_15px'>
                    <div className='mr_10px title_5'>Create Portfolio</div>
                    <div
                        className='cursor_pointer title_4 flex center mt_5px'
                        onClick={closeModal}
                    >
                        <AiIcons.AiOutlineClose />
                    </div>
                </div>
                <div className='divider'></div>
                {/* Problemtofix: Dialog mit Icons */}
                <div className='flex flex_column padding_10px space_between width_100pr'>
                    <div className='width_100pr'>
                        <div className='flex'>
                            <div
                                className='width_20pr mt_5px flex left cursor_pointer hover_opacity_80'
                                onClick={handleClickOpenPortfolioIcon}
                            >
                                <div className='width_20px text_1'>
                                    {icon_storage.map((icon) =>
                                        icon.name === newPortfolio.icon
                                            ? icon.icon
                                            : null
                                    )}
                                </div>
                                <div className='width_30px text_1'>
                                    <RiIcons.RiArrowDownSFill />
                                </div>
                            </div>
                            <div className='input_basic_border border_radius_5px width_80pr padding_5px height_100pr'>
                                <input
                                    className='text_2 text_color_1'
                                    type='text'
                                    placeholder='Name...'
                                    maxLength='20'
                                    onKeyUp={handleNewPortfolioName}
                                />
                            </div>
                        </div>
                        <div>
                            <textarea
                                className='mt_10px text_2 width_100pr text_color_1 height_120px padding_5px resize_none border_radius_5px input_basic_border'
                                name='text'
                                rows='8'
                                maxLength='500'
                                // wrap='soft'
                                placeholder='Description...'
                                onKeyUp={handleNewPortfolioDescription}
                            />
                        </div>
                    </div>

                    <div className='width_100pr flex right mt_10px'>
                        <div
                            className='button_blue color_white ml_15px'
                            onClick={() => {
                                handleSetPortfolio();
                            }}
                        >
                            <div className='ml_10px text_3'>Create</div>
                            <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                        </div>
                    </div>
                </div>
            </div>

            {/* Select Portfolio Icon Modal */}
            <Dialog
                open={openPortfolioIcon}
                onClose={handleClosePortfolioIcon}
                maxWidth={false}
                className=''
            >
                <div className='max_width_500px background_color_2 text_color_1'>
                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{"Select a fitting Icon"}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleClosePortfolioIcon}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider mb_15px'></div>
                    <div className='flex space_around wrap border_box padding_10px'>
                        {icon_storage.map((icon, index) => {
                            return (
                                <div
                                    className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                    key={index}
                                    onClick={() => {
                                        handlenewPortfolioIcon(icon.name);
                                        handleClosePortfolioIcon();
                                    }}
                                >
                                    {icon.icon}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

CreatePortfolio.propTypes = {
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setPortfolio: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    setPortfolio,
    setAlert,
})(CreatePortfolio);
