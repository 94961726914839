import React, { useEffect, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

import { loadUser } from "../actions/userActions";

// Context
import { MetricsContext } from "../context/MetricsContext";

import { getCompaniesStatistics } from "../actions/screenerAction";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Function to update screener form with url parameters
const getUrlParam = (params) => {
    let params_arr = params.slice(1).split("&");
    let watchlist_id = params_arr[0].split("=")[1];
    // params_arr.forEach((element) => {
    //     let (key, value) = element.split("=");
    // });
    return watchlist_id;
};

const PrivateRoute = ({
    component: Component,
    screener: { slider, statistics },
    loadUser,
    user: { isAuthenticated, token, loading, force_login },
    watchlist,
    getCompaniesStatistics,
    ...rest
}) => {
    const metrics = useContext(MetricsContext);

    const history = useHistory();
    // useEffect(() => {
    //     if(force_login){
    //         history.push("/login");
    //     }
    //     // eslint-disable-next-line
    // }, [force_login]);

    useEffect(() => {
        loadUser(
            0,
            window.location.pathname,
            getUrlParam(window.location.search)
        );
        if (force_login) {
            history.push("/login");
        }
        let url_params = [];

        if (
            window.location.pathname === "/screener" &&
            window.location.search
        ) {
            let params_arr = window.location.search.slice(1).split("&");
            params_arr.forEach((element) => {
                let keyvalue = element.split("=");
                url_params.push(keyvalue[0]);
            });
        } else if (
            window.location.pathname === "/screener" &&
            localStorage["latest_screener"]
        ) {
            let params_arr = localStorage["latest_screener"]
                .slice(1)
                .split("&");
            params_arr.forEach((element) => {
                let keyvalue = element.split("=");
                url_params.push(keyvalue[0]);
            });
        }
        getCompaniesStatistics(statistics, slider, metrics.metrics, url_params);
        // eslint-disable-next-line
    }, [force_login]);

    // DETECT SWIPES TO OPEN/ CLOSE MENU
    // click ganz links wenn release 150px rechts dann open menu

    return (
        <Route
            {...rest}
            render={(props) =>
                !isAuthenticated && !token && !loading ? (
                    <Redirect to='/login' />
                ) : (
                    <Component {...props} />
                )
            }
        ></Route>
    );
};

PrivateRoute.propTypes = {
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    getCompaniesStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    watchlist: state.watchlist,
    screener: state.screener,
});

export default connect(mapStateToProps, {
    loadUser,
    getCompaniesStatistics,
})(PrivateRoute);
