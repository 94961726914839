// Sidebar
export const SET_SIDEBAR = "SET_SIDEBAR";

// User Profile
export const LOAD_USER = "LOAD_USER";
export const FORCE_LOGIN = "FORCE_LOGIN";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_GOOGLE_SUCCESS = "REGISTER_GOOGLE_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const USER_ERROR = "USER_ERROR";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";
export const CHANGE_USERNAME_SUCCESS = "CHANGE_USERNAME_SUCCESS";
export const CHANGE_USERNAME_FAIL = "CHANGE_USERNAME_FAIL";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const SIMPLIFIED_VIEW = "SIMPLIFIED_VIEW";

export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";

export const OPEN_CHANGES = "OPEN_CHANGES";
export const OPEN_LOADER = "OPEN_LOADER";
export const GET_HISTORIC_METRIC_SECTOR = "GET_HISTORIC_METRIC_SECTOR";

// Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// MARKET
export const GET_INDEX_PERFORMANCE = "GET_INDEX_PERFORMANCE";
export const GET_SECTOR_STATISTICS = "GET_SECTOR_STATISTICS";
export const SET_TABLE_METRIC_DISPLAY = "SET_TABLE_METRIC_DISPLAY";
export const OPEN_SECTOR_METRIC_HISTORY = "OPEN_SECTOR_METRIC_HISTORY";
export const CLOSE_SECTOR_METRIC_HISTORY = "CLOSE_SECTOR_METRIC_HISTORY";

// User Portfolio
export const LOAD_PORTFOLIOS = "LOAD_PORTFOLIOS";
export const CLEAR_PORTFOLIOS = "CLEAR_PORTFOLIOS";
export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const FOLLOW_PORTFOLIO = "FOLLOW_PORTFOLIO";
export const UNFOLLOW_PORTFOLIO = "UNFOLLOW_PORTFOLIO";
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_URL_PORTFOLIO = "GET_URL_PORTFOLIO";
export const SET_PORTFOLIO_PERFORMANCE = "SET_PORTFOLIO_PERFORMANCE";
export const SET_CURRENT_PORTFOLIO = "SET_CURRENT_PORTFOLIO";
export const CHANGE_PORTFOLIO = "CHANGE_PORTFOLIO";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const ADD_PORTFOLIO_COMPANY = "ADD_PORTFOLIO_COMPANY";
export const ADD_TRANSACTION_FROM_PORTFOLIO = "ADD_TRANSACTION_FROM_PORTFOLIO";
export const SET_MANAGER_DIVERSIFICATION = "SET_MANAGER_DIVERSIFICATION";
export const SET_RISK_FREE_RATE = "SET_RISK_FREE_RATE";
//CREATOR
export const SET_CREATOR_DIVERSIFICATION = "SET_CREATOR_DIVERSIFICATION";
export const SET_CREATOR_POSITIONS = "SET_CREATOR_POSITIONS";
export const SET_CREATOR_WINDOW = "SET_CREATOR_WINDOW";
export const SET_CURRENT_CREATOR_COMPANIES = "SET_CURRENT_CREATOR_COMPANIES";
export const SET_CURRENT_CREATOR_PERFORMANCE =
    "SET_CURRENT_CREATOR_PERFORMANCE";
export const CLEAR_CURRENT_CREATOR = "CLEAR_CURRENT_CREATOR";
export const UPDATE_CREATOR_ALLOCATION = "UPDATE_CREATOR_ALLOCATION";
export const UPDATE_PORTFOLIO_PERFORMANCE = "UPDATE_PORTFOLIO_PERFORMANCE";
export const UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE =
    "UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE";
export const UPDATE_PORTFOLIO_TRACKER_PERFORMANCE =
    "UPDATE_PORTFOLIO_TRACKER_PERFORMANCE";
export const UPDATE_PORTFOLIO_SHARE_COUNT = "UPDATE_PORTFOLIO_SHARE_COUNT";
export const SUBMIT_PORTFOLIO_ALLOCATION = "SUBMIT_PORTFOLIO_ALLOCATION";
export const ADD_CREATOR_TO_MANAGER = "ADD_CREATOR_TO_MANAGER";
//TRACKER
export const GET_PORTFOLIO_TRACKER_COMPANIES =
    "GET_PORTFOLIO_TRACKER_COMPANIES";
export const OPEN_CHANGES_PORTFOLIO = "OPEN_CHANGES_PORTFOLIO";
export const RESET_PORTFOLIO_BENCHMARKS = "RESET_PORTFOLIO_BENCHMARKS";
export const UPDATE_PORTFOLIO_BENCHMARKS = "UPDATE_PORTFOLIO_BENCHMARKS";
export const PORTFOLIO_ERROR = "PORTFOLIO_ERROR";

// User Watchlist
export const LOAD_WATCHLISTS = "LOAD_WATCHLISTS";
export const CLEAR_WATCHLISTS = "CLEAR_WATCHLISTS";
export const SET_WATCHLIST = "ADD_WATCHLIST";
export const RENAME_WATCHLIST = "RENAME_WATCHLIST";
export const SET_CURRENT_WATCHLIST = "SET_CURRENT_WATCHLIST";
export const DELETE_WATCHLIST = "DELETE_WATCHLIST";

export const GET_WATCHLIST_COMPANIES = "GET_WATCHLIST_COMPANIES";
export const GET_URL_WATCHLIST_COMPANIES = "GET_URL_WATCHLIST_COMPANIES";
export const ADD_WATCHLIST_COMPANY = "ADD_WATCHLIST_COMPANY";
export const DELETE_WATCHLIST_COMPANY = "DELETE_WATCHLIST_COMPANY";
export const WATCHLIST_ERROR = "WATCHLIST_ERROR";

// Bookmarks
export const GET_BOOKMARKS = "GET_BOOKMARKS";
export const SET_BOOKMARK = "SET_BOOKMARK";
export const CLEAR_BOOKMARK = "CLEAR_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";

// Screener
export const GET_COMPANIES = "GET_COMPANIES";
export const CLEAR_SCREENER = "CLEAR_SCREENER";
export const GET_COMPANIES_PAGINATION = "GET_COMPANIES_PAGINATION";
export const UPDATE_SCREENER_VALUE = "UPDATE_SCREENER_VALUE";
export const UPDATE_SINGLE_SLIDER_VALUE = "UPDATE_SINGLE_SLIDER_VALUE";
export const UPDATE_SINGLE_SLIDER_VALUE_ONLY =
    "UPDATE_SINGLE_SLIDER_VALUE_ONLY";
export const UPDATE_SINGLE_OPTIONS_VALUE = "UPDATE_SINGLE_OPTIONS_VALUE";
export const UPDATE_SINGLE_OPTION_SORT_BY = "UPDATE_SINGLE_OPTION_SORT_BY";
export const UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY =
    "UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY";
export const UPDATE_SEARCH_TEXT_SCREENER = "UPDATE_SEARCH_TEXT_SCREENER";
export const UPDATE_SEARCH_OPTION_SCREENER = "UPDATE_SEARCH_OPTION_SCREENER";
export const UPDATE_SEARCH_COUNTRIES = "UPDATE_SEARCH_COUNTRIES";
export const UPDATE_SEARCH_INDUSTRIES = "UPDATE_SEARCH_INDUSTRIES";
export const UPDATE_CHECKED_INDUSTRIES = "UPDATE_CHECKED_INDUSTRIES";
export const CLEAR_SCREENER_VALUES = "CLEAR_SCREENER_VALUES";
export const SET_DISPLAY_FALSE_ALL = "SET_DISPLAY_FALSE_ALL";
export const SET_THRESHHOLD = "SET_THRESHHOLD";
export const UPDATE_ESG_SCREENER = "UPDATE_ESG_SCREENER";
export const CLEAR_SINGLE_SLIDER_VALUE = "CLEAR_SINGLE_SLIDER_VALUE";
export const SCREENER_ERROR = "SCREENER_ERROR";
export const LOAD_SCREENER_PRESETS = "LOAD_SCREENER_PRESETS";
export const ADD_SCREENER_PRESET = "ADD_SCREENER_PRESET";
export const UPDATE_SCREENER_PRESET = "UPDATE_SCREENER_PRESET";
export const DELETE_SCREENER_PRESET = "DELETE_SCREENER_PRESET";
export const GET_HISTOGRAM_DATA = "GET_HISTOGRAM_DATA";
export const GET_COMPANIES_STATISTICS = "GET_COMPANIES_STATISTICS";
export const GET_SEARCHBAR_COMPANIES = "GET_SEARCHBAR_COMPANIES";

// Company
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_MONTE_CARLO_PARAMS = "GET_COMPANY_MONTE_CARLO_PARAMS";
export const GET_MONTE_CARLO_BY_PARAMS = "GET_MONTE_CARLO_BY_PARAMS";
export const GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS =
    "GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS";
export const UPDATE_MONTE_CARLO_PARAM = "UPDATE_MONTE_CARLO_PARAM";
export const RESET_MONTE_CARLO_PARAMS = "RESET_MONTE_CARLO_PARAMS";
export const GET_COMPETITORS = "GET_COMPETITORS";
export const GET_COMPETITORS_PAGINATION = "GET_COMPETITORS_PAGINATION";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const SET_COMPANY_LOADING = "SET_COMPANY_LOADING";
export const SET_COMPANY_TICKER = "SET_COMPANY_TICKER";
export const SET_COMPANY_OPEN_TAB = "SET_COMPANY_OPEN_TAB";
export const SET_METRIC_DATA = "SET_METRIC_DATA";
export const SET_CHART_STRUCTURE = "SET_CHART_STRUCTURE";
export const SET_CHART_METRICS = "SET_CHART_METRICS";
export const RESET_METRIC_DATA = "RESET_METRIC_DATA";
export const SET_CHART_PER_SHARE = "SET_CHART_PER_SHARE";
export const SET_CHART_CHANGE_RELATIVE = "SET_CHART_CHANGE_RELATIVE";
export const SET_CHART_CHANGE_ABSOLUTE = "SET_CHART_CHANGE_ABSOLUTE";

// Compare
export const GET_COMPANIES_COMPARE = "GET_COMPANIES_COMPARE";
export const COMPARE_ERROR = "COMPARE_ERROR";

// Loading animations
export const SET_LOADING = "SET_LOADING";

// GENERAL
export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
