import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// ICONS
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";

// COMPONENTS
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CreateWatchlist from "../../pages/elements/CreateWatchlist";

// MUI
import Dialog from "@mui/material/Dialog";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    setCurrentWatchlist,
    getWatchlist,
} from "../../../actions/watchlistActions";
import { setSidebar } from "../../../actions/sidebarActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

let currentWatchlist = [];

const SidebarWatchlists = ({
    watchlist,
    user,
    screener: { options },
    setCurrentWatchlist,
    getWatchlist,
    setSidebar,
}) => {
    const location = useLocation();
    useEffect(() => {
        if (watchlist.watchlists.length !== 0 && watchlist.current !== null) {
            currentWatchlist = watchlist.watchlists.filter((obj) => {
                return obj.id === watchlist.current;
            });
        } else {
            currentWatchlist = [];
        }
        // eslint-disable-next-line
    }, [watchlist.current]);

    const showSidebar = () => {
        setSidebar();
    };

    /*Set Current Watchlist*/
    if (watchlist.watchlists.length !== 0 && watchlist.current !== null) {
        currentWatchlist = watchlist.watchlists.filter((obj) => {
            return obj.id === watchlist.current;
        });
    } else {
        currentWatchlist = [];
    }

    /*Control Subnavigation Open/Close*/
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);

    /*Create Watchlist Modal*/
    const [openCreateWatchlist, setOpenCreateWatchlist] = React.useState(false);
    const handleClickOpenCreateWatchlist = () => {
        setOpenCreateWatchlist(true);
    };
    const handleCloseCreateWatchlist = () => {
        setOpenCreateWatchlist(false);
    };

    return (
        <div className='width_100pr'>
            {/* Watchlists */}
            {currentWatchlist.length !== 0 ? (
                <div
                    className={
                        location.pathname === "/watchlist"
                            ? subnav
                                ? "flex space_between text_color_3 border_radius_15px_top_right selected_menu_hover height_80px width_100pr text_decoration_none"
                                : "flex space_between text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                            : subnav
                            ? "flex space_between text_color_3 border_radius_15px_top_right menu_hover height_80px width_100pr text_decoration_none"
                            : "flex space_between text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                    }
                    item={currentWatchlist[0]}
                >
                    <div className='flex width_80pr height_100pr'>
                        <Link
                            to={`/watchlist`}
                            className='pl_20px pr_20px flex center height_100pr'
                            onClick={() => {
                                if (window.innerWidth < 1224) {
                                    showSidebar();
                                }
                            }}
                        >
                            <MdIcons.MdBookmarkBorder className='text_1' />
                        </Link>
                        <div className='flex flex_column align_items_start width_100pr height_100pr'>
                            <Link
                                to={`/watchlist`}
                                className='text_2 text_left height_50pr width_100pr pt_20px'
                                onClick={() => {
                                    if (window.innerWidth < 1224) {
                                        showSidebar();
                                    }
                                }}
                            >
                                <div className='mt_-5px'>Watchlist</div>
                            </Link>
                            <Link
                                to={`/watchlist?id=${currentWatchlist[0].id}`}
                                className='text_3 text_left height_50pr width_100pr pb_20px text_truncated max_width_135px'
                                onClick={() => {
                                    if (window.innerWidth < 1224) {
                                        showSidebar();
                                    }
                                }}
                            >
                                <div className='padding_8px background_color_2 hover_opacity_80 border_radius_5px text_color_1'>
                                    <FiIcons.FiCornerDownRight className='text_3' />
                                    {"  "}
                                    {currentWatchlist[0].name}
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div
                        onClick={watchlist.watchlists && showSubnav}
                        className='width_20pr flex center cursor_pointer'
                    >
                        {currentWatchlist[0].length !== 0 && subnav ? (
                            <RiIcons.RiArrowUpSFill />
                        ) : watchlist.watchlists ? (
                            <RiIcons.RiArrowDownSFill />
                        ) : null}
                    </div>
                </div>
            ) : (
                <div
                    className={
                        location.pathname === "/watchlist"
                            ? subnav
                                ? "flex text_color_3 border_radius_15px_top_right selected_menu_hover height_80px width_100pr text_decoration_none"
                                : "flex text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                            : subnav
                            ? "flex text_color_3 border_radius_15px_top_right menu_hover height_80px width_100pr text_decoration_none"
                            : "flex text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                    }
                >
                    <Link
                        to={`/watchlist`}
                        className='pl_20px flex center pr_20px height_100pr'
                        onClick={() => {
                            if (window.innerWidth < 1224) {
                                showSidebar();
                            }
                        }}
                    >
                        <MdIcons.MdBookmarkBorder className='text_1' />
                    </Link>
                    <div className='flex flex_column align_items_start height_100pr width_100pr'>
                        <div className='text_2 text_left height_50pr width_100pr pt_20px cursor_pointer'>
                            <div className='mt_-5px'>Watchlist</div>
                        </div>

                        <div
                            className='text_2 text_left height_50pr width_100pr pb_20px cursor_pointer'
                            onClick={() => {
                                handleClickOpenCreateWatchlist();
                            }}
                        >
                            <div className='padding_8px background_color_2 hover_opacity_80 border_radius_5px text_color_1'>
                                <FiIcons.FiCornerDownRight className='text_2' />
                                {"  "}
                                Create a Watchlist
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <TransitionGroup component={null}>
                {subnav &&
                    watchlist.watchlists.map((item, index) => {
                        if (index < watchlist.watchlists.length - 1) {
                            return (
                                <CSSTransition
                                    classNames='component_transition_opacity'
                                    timeout={300}
                                >
                                    <Link
                                        className={
                                            location.pathname ===
                                                "/watchlist" &&
                                            location.search === `?id=${item.id}`
                                                ? "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer selected_submenu_hover"
                                                : "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover"
                                        }
                                        to={`/watchlist?id=${item.id}`}
                                        key={index}
                                        onClick={() => {
                                            if (window.innerWidth < 1224) {
                                                showSidebar();
                                            }
                                            setCurrentWatchlist(item.id);
                                            getWatchlist(
                                                item.id,
                                                user.id,
                                                options.sortWatchlistBy.value,
                                                options.sortWatchlistBy
                                                    .direction
                                            );
                                        }}
                                    >
                                        {icon_storage.map((icon) =>
                                            icon.name === item.icon
                                                ? icon.icon
                                                : null
                                        )}
                                        <div className='text_4 ml_16px text_truncated max_width_180px'>
                                            {item.name}
                                        </div>
                                    </Link>
                                </CSSTransition>
                            );
                        } else {
                            return (
                                <CSSTransition
                                    classNames='component_transition_opacity'
                                    timeout={300}
                                >
                                    <div>
                                        <Link
                                            className={
                                                location.pathname ===
                                                    "/watchlist" &&
                                                location.search ===
                                                    `?id=${item.id}`
                                                    ? "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer selected_submenu_hover"
                                                    : "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover"
                                            }
                                            to={`/watchlist?id=${item.id}`}
                                            key={index}
                                            onClick={() => {
                                                if (window.innerWidth < 1224) {
                                                    showSidebar();
                                                }
                                                setCurrentWatchlist(item.id);
                                                getWatchlist(
                                                    item.id,
                                                    user.id,
                                                    options.sortWatchlistBy
                                                        .value,
                                                    options.sortWatchlistBy
                                                        .direction
                                                );
                                            }}
                                        >
                                            {icon_storage.map((icon) =>
                                                icon.name === item.icon
                                                    ? icon.icon
                                                    : null
                                            )}
                                            <div className='text_4 ml_16px text_truncated max_width_180px'>
                                                {item.name}
                                            </div>
                                        </Link>
                                        {user.status === 80 ? null : (
                                            <div
                                                className='background_color_8 height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover'
                                                key='newWatchlistModal'
                                                onClick={() => {
                                                    handleClickOpenCreateWatchlist();
                                                }}
                                            >
                                                <IoIcons.IoIosAddCircleOutline />
                                                <div className='text_4 ml_16px'>
                                                    Create a Watchlist
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CSSTransition>
                            );
                        }
                    })}
            </TransitionGroup>

            {/* CREATE WATCHLIST */}
            <Dialog
                open={openCreateWatchlist}
                onClose={handleCloseCreateWatchlist}
                maxWidth={false}
            >
                <CreateWatchlist closeModal={handleCloseCreateWatchlist} />
            </Dialog>
        </div>
    );
};

SidebarWatchlists.propTypes = {
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    setCurrentWatchlist: PropTypes.func.isRequired,
    screener: PropTypes.object.isRequired,
    getWatchlist: PropTypes.func.isRequired,
    setSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    watchlist: state.watchlist,
    screener: state.screener,
    user: state.user,
});

export default connect(mapStateToProps, {
    setCurrentWatchlist,
    getWatchlist,
    setSidebar,
})(SidebarWatchlists);
