import React, { useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import TableStructureComponent from "../screener/TableStructureComponent";
import TableStructureComponentUpgrade from "../screener/TableStructureComponentUpgrade";
import TableStructureComponentManifest from "../screener/TableStructureComponentManifest";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { setDisplayFalseAll } from "../../../actions/screenerAction";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// MUI
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Badge from "@mui/material/Badge";

const CompaniesTableModal = ({
    user,
    closeModal,
    slider,
    options,
    tableStructure,
    setDisplayFalseAll,
    handletableStructure,
    metricType,
    setMetricType,
}) => {
    const useintl = useIntl();
    const setNewTableStructure = (event) => {
        handletableStructure(event.target.value);
    };

    // METRICS DISPLAYABLE IN THE TABLE
    const metrics = useContext(MetricsContext);

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    useEffect(() => {
        // SEARCH WHICH CATEGORIES CONTAIN CHECKED METRICS
        let new_sorted_metrics = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        let new_sorted_rankers = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        Object.keys(SliderData).map((slide) => {
            Object.keys(sorted_metrics_object).map((category) => {
                if (sorted_metrics_object[category].includes(slide)) {
                    new_sorted_metrics[category].open = false;
                    new_sorted_metrics[category].match =
                        new_sorted_metrics[category].match + 1;
                    new_sorted_metrics[category].checked = slider[slide].display
                        ? new_sorted_metrics[category].checked + 1
                        : new_sorted_metrics[category].checked;
                }
            });
            Object.keys(sorted_rankers_object).map((category) => {
                if (sorted_rankers_object[category].includes(slide)) {
                    new_sorted_rankers[category].open = false;
                    new_sorted_rankers[category].match =
                        new_sorted_rankers[category].match + 1;
                    new_sorted_rankers[category].checked = slider[slide].display
                        ? new_sorted_rankers[category].checked + 1
                        : new_sorted_rankers[category].checked;
                }
            });
        });
        setOpenSortedMetrics(new_sorted_metrics);
        setOpenSortedRankers(new_sorted_rankers);
        // eslint-disable-next-line
    }, [metrics]);

    let sorted_metrics_object = user.simplified_view
        ? metrics.simplified_sorted_metrics_object
        : metrics.sorted_metrics_object;

    let sorted_rankers_object = user.simplified_view
        ? metrics.simplified_sorted_rankers_object
        : metrics.sorted_rankers_object;

    let display_industries = metrics.possible_manifest_values;

    const [openSortedMetrics, setOpenSortedMetrics] = React.useState({
        general: { open: false, match: 0, checked: 0 },
        performance: { open: false, match: 0, checked: 0 },
        ratings: { open: false, match: 0, checked: 0 },
        price_ratios: { open: false, match: 0, checked: 0 },
        fundamental_ratios: { open: false, match: 0, checked: 0 },
        dividends: { open: false, match: 0, checked: 0 },
        // shares: {open: false, match: 0, checked: 0 },
        growth: { open: false, match: 0, checked: 0 },
        debt_assets: { open: false, match: 0, checked: 0 },
        margins: { open: false, match: 0, checked: 0 },
    });
    const handleOpenSortedMetrics = (category) => {
        setOpenSortedMetrics({
            ...openSortedMetrics,
            [category]: {
                open: !openSortedMetrics[category].open,
                match: openSortedMetrics[category].match,
                checked: openSortedMetrics[category].checked,
            },
        });
    };
    const [openSortedRankers, setOpenSortedRankers] = React.useState({
        general: { open: false, match: 0, checked: 0 },
        performance: { open: false, match: 0, checked: 0 },
        ratings: { open: false, match: 0, checked: 0 },
        price_ratios: { open: false, match: 0, checked: 0 },
        fundamental_ratios: { open: false, match: 0, checked: 0 },
        dividends: { open: false, match: 0, checked: 0 },
        // shares: {open: false, match: 0, checked: 0 },
        growth: { open: false, match: 0, checked: 0 },
        debt_assets: { open: false, match: 0, checked: 0 },
        margins: { open: false, match: 0, checked: 0 },
    });
    const handleOpenSortedRankers = (category) => {
        setOpenSortedRankers({
            ...openSortedRankers,
            [category]: {
                open: !openSortedRankers[category].open,
                match: openSortedRankers[category].match,
                checked: openSortedRankers[category].checked,
            },
        });
    };
    const detectCheck = (category, type, bool) => {
        if (type === "ranker") {
            setOpenSortedRankers({
                ...openSortedRankers,
                [category]: {
                    open: openSortedRankers[category].open,
                    match: openSortedRankers[category].match,
                    checked: bool
                        ? openSortedRankers[category].checked + 1
                        : openSortedRankers[category].checked - 1,
                },
            });
        } else {
            setOpenSortedMetrics({
                ...openSortedMetrics,
                [category]: {
                    open: openSortedMetrics[category].open,
                    match: openSortedMetrics[category].match,
                    checked: bool
                        ? openSortedMetrics[category].checked + 1
                        : openSortedMetrics[category].checked - 1,
                },
            });
        }
    };

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // SEARCH METRIC FUNCTIONS
    const [searchSliderText, setSearchSliderText] = React.useState("");
    const handlesearchSliderText = (event) => {
        setSearchSliderText(event.target.value);
        let new_sorted_metrics = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        let new_sorted_rankers = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };

        let helper = {};
        Object.keys(SliderData).map((slide) => {
            if (
                event.target.value === "" ||
                SliderData[slide].displayName.includes(event.target.value) ||
                SliderData[slide].property.includes(event.target.value) ||
                SliderData[slide].filter_keywords.includes(event.target.value)
            ) {
                helper[slide] = SliderData[slide];

                Object.keys(sorted_metrics_object).map((category) => {
                    if (sorted_metrics_object[category].includes(slide)) {
                        new_sorted_metrics[category].open =
                            event.target.value === "" ? false : true;
                        new_sorted_metrics[category].match =
                            new_sorted_metrics[category].match + 1;
                        new_sorted_metrics[category].checked = slider[slide]
                            .display
                            ? new_sorted_metrics[category].checked + 1
                            : new_sorted_metrics[category].checked;
                    }
                });
                Object.keys(sorted_rankers_object).map((category) => {
                    if (sorted_rankers_object[category].includes(slide)) {
                        new_sorted_rankers[category].open =
                            event.target.value === "" ? false : true;
                        new_sorted_rankers[category].match =
                            new_sorted_rankers[category].match + 1;
                        new_sorted_rankers[category].checked = slider[slide]
                            .display
                            ? new_sorted_rankers[category].checked + 1
                            : new_sorted_rankers[category].checked;
                    }
                });
            }
        });
        setSearchSliderObject(helper);

        setOpenSortedMetrics(new_sorted_metrics);
        setOpenSortedRankers(new_sorted_rankers);
    };
    return (
        <div className='background_color_2 text_color_1 width_100pr'>
            {/* OPTIONS SECTION */}
            <div className='flex space_between'>
                <div className='flex wrap space_between width_100pr'>
                    <div className='flex wrap mt_10px'>
                        {/* HEADER */}
                        <div className='mt_10px ml_20px title_3 flex text_color_1'>
                            <div className='text_1 pr_5px'>
                                <FormattedMessage
                                    id='general.table_structure'
                                    defaultMessage='Table Structure'
                                />
                            </div>
                            <AiIcons.AiOutlineTable className='text_1 ml_5px pt_3px' />
                        </div>

                        {/* PRESETS DROPDOWN */}
                        <div className='mt_10px ml_20px'>
                            <FormControl sx={{ minWidth: 150 }} size='small'>
                                <InputLabel
                                    htmlFor='tableStructure'
                                    className='width_200px_120px'
                                    id='demo-simple-select-helper-label'
                                >
                                    <FormattedMessage
                                        id='general.table_structure'
                                        defaultMessage='Table Structure'
                                    />
                                </InputLabel>
                                <Select
                                    value={tableStructure}
                                    onChange={setNewTableStructure}
                                    className='width_200px_120px background_color_4'
                                    labelId='demo-simple-select-helper-label'
                                    label={
                                        <FormattedMessage
                                            id='general.table_structure'
                                            defaultMessage='Table Structure'
                                        />
                                    }
                                    id='demo-simple-select-helper'
                                >
                                    <MenuItem value={"filtered_metrics"}>
                                        {useintl.formatMessage({
                                            id: "general.strategy.individual",
                                        })}
                                    </MenuItem>
                                    <MenuItem value={"dividends"}>
                                        {useintl.formatMessage({
                                            id: "general.strategy.dividends",
                                        })}
                                    </MenuItem>
                                    <MenuItem value={"value"}>
                                        {useintl.formatMessage({
                                            id: "general.strategy.value",
                                        })}
                                    </MenuItem>
                                    <MenuItem value={"growth"}>
                                        {useintl.formatMessage({
                                            id: "general.strategy.growth",
                                        })}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {/* RESET BUTTON */}
                    <div className='flex right mt_5px'>
                        <div
                            className='button_white_layer_2 hover_opacity_80 text_2 padding_10px ml_15px mt_10px mr_15px'
                            onClick={() => {
                                setDisplayFalseAll(slider, options);
                                handletableStructure("filtered_metrics");
                                closeModal();
                            }}
                        >
                            <div className='text_2 text_color_1 pr_10px'>
                                <FormattedMessage
                                    id='general.reset'
                                    defaultMessage='Reset'
                                />
                            </div>
                            <IoIcons.IoIosRefresh />
                        </div>
                    </div>
                </div>
                <div
                    className='cursor_pointer title_4 flex center mr_15px mt_15px'
                    onClick={closeModal}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>

            <div className='divider'></div>

            {/* SEARCH METRIC INPUT */}
            <div className='flex left width_100pr height_40px_30px'>
                <AiIcons.AiOutlineSearch className='ml_15px mr_15px text_1' />
                <div className='width_100pr'>
                    <input
                        type='text'
                        className='text_2 input_text width_100pr pt_15px pb_15px'
                        placeholder={useintl.formatMessage({
                            id: "metrics.search_metric",
                        })}
                        onKeyUp={handlesearchSliderText}
                    />
                </div>
            </div>

            <div className='divider'></div>

            {/* ABSOLUTE/ RELATIVE SWITCH */}
            <div className='flex width_100pr height_40px_30px text_2'>
                <div
                    className={
                        metricType === 0
                            ? "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer accent_color_1 text_decoration_underline"
                            : "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer text_decoration_none"
                    }
                    onClick={
                        metricType !== 0
                            ? () => {
                                  setMetricType(0);
                              }
                            : null
                    }
                >
                    {useintl.formatMessage({
                        id: `general.absolute`,
                    })}
                </div>
                <div
                    className={
                        metricType === 1
                            ? "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer accent_color_1 text_decoration_underline"
                            : "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer text_decoration_none"
                    }
                    onClick={
                        metricType !== 1
                            ? () => {
                                  setMetricType(1);
                              }
                            : null
                    }
                >
                    {useintl.formatMessage({
                        id: `general.relative`,
                    })}
                </div>
            </div>

            <div className='divider_no_margin'></div>

            {/* METRICS */}

            <div className='flex wrap space_around border_box width_100pr background_color_2'>
                {metricType === 0
                    ? Object.keys(sorted_metrics_object).map((category) =>
                          (openSortedMetrics[category].match &&
                              openSortedMetrics[category].match > 0) ||
                          searchSliderText === "" ? (
                              <div className='width_100pr flex wrap'>
                                  <div
                                      className='width_100pr height_50px ps_20px flex space_between text_2 text_color_1 background_color_1 cursor_pointer border_1px_solid'
                                      onClick={() => {
                                          handleOpenSortedMetrics(category);
                                      }}
                                  >
                                      <Badge
                                          badgeContent={
                                              openSortedMetrics[category]
                                                  .checked
                                          }
                                          color='secondary'
                                          max={999}
                                      >
                                          <div className='pr_10px'>
                                              {useintl.formatMessage({
                                                  id: `metrics.${category}`,
                                              })}
                                          </div>
                                      </Badge>
                                      {openSortedMetrics[category].open ? (
                                          <RiIcons.RiArrowUpSFill />
                                      ) : (
                                          <RiIcons.RiArrowDownSFill />
                                      )}
                                  </div>
                                  {openSortedMetrics[category].open
                                      ? sorted_metrics_object[category].map(
                                            (metric) =>
                                                searchSliderObject[metric] &&
                                                metric !== "market_cap_usd" &&
                                                metric !== "market_cap" &&
                                                AllowedMetrics.includes(
                                                    metric
                                                ) ? (
                                                    <TableStructureComponent
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        handletableStructure={
                                                            handletableStructure
                                                        }
                                                        tableStructureValue={
                                                            options
                                                                .tableStructure
                                                                .value
                                                        }
                                                        category={category}
                                                        type='normal'
                                                        detectCheck={
                                                            detectCheck
                                                        }
                                                        key={
                                                            category +
                                                            "_" +
                                                            metric
                                                        }
                                                    />
                                                ) : searchSliderObject[
                                                      metric
                                                  ] &&
                                                  metric !== "market_cap_usd" &&
                                                  metric !== "market_cap" &&
                                                  !AllowedMetrics.includes(
                                                      metric
                                                  ) ? (
                                                    <TableStructureComponentUpgrade
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        handletableStructure={
                                                            handletableStructure
                                                        }
                                                        tableStructureValue={
                                                            options
                                                                .tableStructure
                                                                .value
                                                        }
                                                        category={category}
                                                        type='normal'
                                                        detectCheck={
                                                            detectCheck
                                                        }
                                                        key={
                                                            category +
                                                            "_" +
                                                            metric
                                                        }
                                                    />
                                                ) : null
                                        )
                                      : null}
                                  {openSortedMetrics[category].open &&
                                  category === "general"
                                      ? display_industries.map((industry) => (
                                            <TableStructureComponentManifest
                                                sliderprops={options[industry]}
                                                handletableStructure={
                                                    handletableStructure
                                                }
                                                tableStructureValue={
                                                    options.tableStructure.value
                                                }
                                                category={category}
                                                type='normal'
                                                detectCheck={detectCheck}
                                                key={category + "_" + industry}
                                            />
                                        ))
                                      : null}
                              </div>
                          ) : null
                      )
                    : Object.keys(sorted_rankers_object).map((category) =>
                          (openSortedRankers[category].match &&
                              openSortedRankers[category].match > 0) ||
                          searchSliderText === "" ? (
                              <div className='width_100pr flex wrap'>
                                  <div
                                      className='width_100pr height_50px ps_20px flex space_between text_2 text_color_1 background_color_1 cursor_pointer border_1px_solid'
                                      onClick={() => {
                                          handleOpenSortedRankers(category);
                                      }}
                                  >
                                      <Badge
                                          badgeContent={
                                              openSortedRankers[category]
                                                  .checked
                                          }
                                          color='secondary'
                                          max={999}
                                      >
                                          <div className='pr_10px'>
                                              {useintl.formatMessage({
                                                  id: `metrics.${category}`,
                                              })}
                                          </div>
                                      </Badge>
                                      {openSortedRankers[category].open ? (
                                          <RiIcons.RiArrowUpSFill />
                                      ) : (
                                          <RiIcons.RiArrowDownSFill />
                                      )}
                                  </div>
                                  {openSortedRankers[category].open
                                      ? sorted_rankers_object[category].map(
                                            (metric) =>
                                                searchSliderObject[metric] &&
                                                AllowedMetrics.includes(
                                                    metric
                                                ) ? (
                                                    <TableStructureComponent
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        handletableStructure={
                                                            handletableStructure
                                                        }
                                                        tableStructureValue={
                                                            options
                                                                .tableStructure
                                                                .value
                                                        }
                                                        category={category}
                                                        type='ranker'
                                                        detectCheck={
                                                            detectCheck
                                                        }
                                                        key={
                                                            category +
                                                            "_" +
                                                            metric
                                                        }
                                                    />
                                                ) : searchSliderObject[
                                                      metric
                                                  ] &&
                                                  !AllowedMetrics.includes(
                                                      metric
                                                  ) ? (
                                                    <TableStructureComponentUpgrade
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        handletableStructure={
                                                            handletableStructure
                                                        }
                                                        tableStructureValue={
                                                            options
                                                                .tableStructure
                                                                .value
                                                        }
                                                        category={category}
                                                        type='ranker'
                                                        detectCheck={
                                                            detectCheck
                                                        }
                                                        key={
                                                            category +
                                                            "_" +
                                                            metric
                                                        }
                                                    />
                                                ) : null
                                        )
                                      : null}
                              </div>
                          ) : null
                      )}
            </div>
        </div>
    );
};

CompaniesTableModal.propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    screener: state.screener,
});

export default connect(mapStateToProps, { setDisplayFalseAll })(
    CompaniesTableModal
);
