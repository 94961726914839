import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// COMPONENTS
import CreatePortfolio from "../../pages/elements/CreatePortfolio";

// ICONS
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as IoIcons5 from "react-icons/io5";

// MUI
import Dialog from "@mui/material/Dialog";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    setCurrentPortfolio,
    setPortfolio,
} from "../../../actions/portfolioActions";
import { setSidebar } from "../../../actions/sidebarActions";
import { setAlert } from "../../../actions/alertActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

let currentPortfolio = [];

const SiderbarPortfolios = ({
    user,
    portfolio,
    setCurrentPortfolio,
    setSidebar,
}) => {
    const location = useLocation();
    useEffect(() => {
        if (portfolio.portfolios.length !== 0 && portfolio.current !== null) {
            currentPortfolio = portfolio.portfolios.filter((obj) => {
                return obj.id === portfolio.current;
            });
        } else {
            currentPortfolio = [];
        }
        // eslint-disable-next-line
    }, [portfolio.current]);
    /*Set Current Portfolio*/
    if (portfolio.portfolios.length !== 0 && portfolio.current !== null) {
        currentPortfolio = portfolio.portfolios.filter((obj) => {
            return obj.id === portfolio.current;
        });
    } else {
        currentPortfolio = [];
    }

    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    const showSidebar = () => {
        setSidebar();
    };

    /*Create Portfolio Modal*/
    const [openCreatePortfolio, setOpenCreatePortfolio] = React.useState(false);
    const handleClickOpenCreatePortfolio = () => {
        setOpenCreatePortfolio(true);
    };
    const handleClickCloseCreatePortfolio = () => {
        setOpenCreatePortfolio(false);
    };

    return (
        <div className='width_100pr'>
            {currentPortfolio.length !== 0 ? (
                <div
                    className={
                        location.pathname === "/portfolio"
                            ? subnav
                                ? "flex space_between text_color_3 border_radius_15px_top_right selected_menu_hover height_80px width_100pr text_decoration_none"
                                : "flex space_between text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                            : subnav
                            ? "flex space_between text_color_3 border_radius_15px_top_right menu_hover height_80px width_100pr text_decoration_none"
                            : "flex space_between text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                    }
                    item={currentPortfolio[0]}
                >
                    <div className='flex width_80pr height_100pr'>
                        <Link
                            to={`/portfolio`}
                            className='pl_20px flex center pr_20px height_100pr'
                            onClick={() => {
                                if (window.innerWidth < 1224) {
                                    showSidebar();
                                }
                            }}
                        >
                            <RiIcons.RiDonutChartFill
                                alt='Portfolio Icon'
                                className='text_1'
                            />
                        </Link>
                        <div className='flex flex_column align_items_start width_100pr height_100pr'>
                            <Link
                                to={`/portfolio`}
                                className='text_2 text_left height_50pr width_100pr pt_20px'
                                onClick={() => {
                                    if (window.innerWidth < 1224) {
                                        showSidebar();
                                    }
                                }}
                            >
                                <div className='mt_-5px'>Portfolio</div>
                            </Link>
                            <Link
                                to={`/portfolio?id=${currentPortfolio[0].id}`}
                                className='text_3 text_left text_truncated max_width_135px height_50pr width_100pr pb_20px'
                                onClick={() => {
                                    if (window.innerWidth < 1224) {
                                        showSidebar();
                                    }
                                }}
                            >
                                <div className='padding_8px background_color_2 hover_opacity_80 border_radius_5px text_color_1'>
                                    <FiIcons.FiCornerDownRight className='text_3' />
                                    {"  "}
                                    {currentPortfolio[0].name}
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div
                        onClick={portfolio.portfolios && showSubnav}
                        className='width_20pr flex center cursor_pointer'
                    >
                        {currentPortfolio[0].length !== 0 && subnav ? (
                            <RiIcons.RiArrowUpSFill />
                        ) : portfolio.portfolios ? (
                            <RiIcons.RiArrowDownSFill />
                        ) : null}
                    </div>
                </div>
            ) : (
                <div
                    className={
                        location.pathname === "/portfolio"
                            ? subnav
                                ? "flex text_color_3 border_radius_15px_top_right selected_menu_hover height_80px width_100pr text_decoration_none"
                                : "flex text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                            : subnav
                            ? "flex text_color_3 border_radius_15px_top_right menu_hover height_80px width_100pr text_decoration_none"
                            : "flex text_color_3 border_radius_15px_right menu_hover height_80px width_100pr text_decoration_none"
                    }
                >
                    <Link
                        to={`/portfolio`}
                        className='pl_20px flex center pr_20px height_100pr'
                        aria-label='Go to Portfolio Manager'
                        onClick={() => {
                            if (window.innerWidth < 1224) {
                                showSidebar();
                            }
                        }}
                    >
                        {/* <AiIcons.AiOutlineEye className='text_1' /> */}
                        <RiIcons.RiDonutChartFill
                            alt='Portfolio Icon'
                            className='text_1'
                        />
                    </Link>
                    <div className='flex flex_column align_items_start height_100pr width_100pr'>
                        <div className='text_2 text_left height_50pr width_100pr pt_20px cursor_pointer'>
                            <div className='mt_-5px'>Portfolio</div>
                        </div>

                        <div
                            className='text_2 text_left height_50pr width_100pr pb_20px cursor_pointer'
                            onClick={() => {
                                handleClickOpenCreatePortfolio();
                            }}
                        >
                            <div className='padding_8px background_color_2 hover_opacity_80 border_radius_5px text_color_1'>
                                <FiIcons.FiCornerDownRight className='text_2' />
                                {"  "}
                                Create a Portfolio
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <TransitionGroup component={null}>
                {subnav &&
                    portfolio.portfolios.map((item, index) => {
                        if (index < portfolio.portfolios.length - 1) {
                            return (
                                <CSSTransition
                                    classNames='component_transition_opacity'
                                    timeout={300}
                                >
                                    <Link
                                        className={
                                            location.pathname ===
                                                "/portfolio" &&
                                            location.search === `?id=${item.id}`
                                                ? "height_50px width_100pr pl_15px flex text_decoration_none text_color_3 cursor_pointer selected_submenu_hover"
                                                : "height_50px width_100pr pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover"
                                        }
                                        to={`/portfolio?id=${item.id}`}
                                        key={index}
                                        onClick={() => {
                                            if (window.innerWidth < 1224) {
                                                showSidebar();
                                            }
                                            setCurrentPortfolio(item.id);
                                        }}
                                    >
                                        {icon_storage.map((icon) =>
                                            icon.name === item.icon
                                                ? icon.icon
                                                : null
                                        )}
                                        <div className='text_4 ml_16px text_truncated max_width_180px'>
                                            {item.name}
                                        </div>
                                    </Link>
                                </CSSTransition>
                            );
                        } else {
                            return (
                                <CSSTransition
                                    classNames='component_transition_opacity'
                                    timeout={300}
                                >
                                    <div>
                                        <Link
                                            className={
                                                location.pathname ===
                                                    "/portfolio" &&
                                                location.search ===
                                                    `?id=${item.id}`
                                                    ? "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer selected_submenu_hover"
                                                    : "height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover"
                                            }
                                            to={`/portfolio?id=${item.id}`}
                                            key={index}
                                            onClick={() => {
                                                if (window.innerWidth < 1224) {
                                                    showSidebar();
                                                }
                                                setCurrentPortfolio(item.id);
                                            }}
                                        >
                                            {icon_storage.map((icon) =>
                                                icon.name === item.icon
                                                    ? icon.icon
                                                    : null
                                            )}
                                            <div className='text_4 ml_16px text_truncated max_width_180px'>
                                                {item.name}
                                            </div>
                                        </Link>
                                        {user.status === 80 ? null : (
                                            <div
                                                className='height_50px pl_15px flex text_decoration_none text_color_3 cursor_pointer submenu_hover'
                                                onClick={() => {
                                                    handleClickOpenCreatePortfolio();
                                                }}
                                            >
                                                <IoIcons.IoIosAddCircleOutline />
                                                <div className='text_4 ml_16px'>
                                                    Create a Portfolio
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CSSTransition>
                            );
                        }
                    })}
            </TransitionGroup>

            {/* CREATE NEW PORTFOLIO MODAL */}
            <Dialog
                open={openCreatePortfolio}
                onClose={handleClickCloseCreatePortfolio}
                maxWidth={false}
            >
                <CreatePortfolio closeModal={handleClickCloseCreatePortfolio} />
            </Dialog>
        </div>
    );
};

SiderbarPortfolios.propTypes = {
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setCurrentPortfolio: PropTypes.func.isRequired,
    setSidebar: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    setCurrentPortfolio,
    setPortfolio,
    setSidebar,
    setAlert,
})(SiderbarPortfolios);
