import React, { useEffect, useState } from "react";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import "./Stripe.css";
import Preloader from "../layout/Preloader";

import {
    userCreateSubscription,
    openSubscriptionLoader,
} from "../../actions/userActions";
import { setAlert } from "../../actions/alertActions";

import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

const { REACT_APP_EXPRESS_BASE_URL, REACT_APP_REACT_ACCOUNT_URL } = process.env;

const StripeForm = ({
    user,
    clientSecret,
    changeSubscription,
    userCreateSubscription,
    openSubscriptionLoader,
    setAlert,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    // CREATE SUBSCRIPTION
    const handleSubmit = async (e) => {
        e.preventDefault();
        openSubscriptionLoader();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        console.log("STRIPE EVENT", e);

        const { error } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: REACT_APP_REACT_ACCOUNT_URL,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (e.g., payment
            // details incomplete)
            // const messageContainer = document.querySelector('#error-message');
            // messageContainer.textContent = error.message;
            console.log("ERROR", error);
        } else {
            handleCreateSSE();
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            stripe
                .retrievePaymentIntent(clientSecret)
                .then(function (response) {
                    if (
                        response.paymentIntent &&
                        response.paymentIntent.status === "succeeded"
                    ) {
                        // Handle successful payment here

                        // OPEN ALERT
                        console.log("CREATE SUB PAYMENT INTENT SUCCEEDED");
                    } else {
                        // Handle unsuccessful, processing, or canceled payments and API errors here

                        // OPEN ALERT
                        console.log("CREATE SUB PAYMENT INTENT FAILED");
                    }
                });
        }

        // Check Payment Success
    };
    const handleCreateSSE = () => {
        const sse = new EventSource(
            `${
                REACT_APP_EXPRESS_BASE_URL +
                "/create_subscription_stream?user_id=" +
                user.id
            }`,
            {
                withCredentials: true,
            }
        );

        sse.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (Object.keys(response).length === 0) {
                // KEEP REQUESTING
                console.log("NO RESPONSE YET");
            } else {
                // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
                console.log("RESPONSE CAME IN");
                console.log(response);
                userCreateSubscription(response);
                setAlert("Your are now subscribed to our service", "success");
                sse.close();
            }
        };
        sse.onerror = () => {
            // error log here
            sse.close();
        };
        // return () => {
        //     sse.close();
        // };
    };

    // CHANGE PAYMENT METHOD
    const handleChangePaymentMethod = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        openSubscriptionLoader();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: "if_required",
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: REACT_APP_REACT_ACCOUNT_URL,
            },
        });

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            console.log("SETUPINTENT: ", setupIntent.payment_method);

            fetch("/new-payment-method", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    customer_id: user.stripeCustomerId,
                    payment_method: setupIntent.payment_method,
                    subscription_id: user.subscriptionId,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                });
            // Inspect the SetupIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            switch (setupIntent.status) {
                case "succeeded":
                    handleUpdatePaymentMethodSSE();
                    break;

                case "processing":
                    break;

                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    break;
            }
        });
    };
    const handleUpdatePaymentMethodSSE = () => {
        const sse = new EventSource(
            `${
                REACT_APP_EXPRESS_BASE_URL +
                "/update_method_subscription_stream?user_id=" +
                user.stripeCustomerId +
                "&method=" +
                user.paymentMethod.id
            }`,
            {
                withCredentials: true,
            }
        );

        sse.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (Object.keys(response).length === 0) {
                // KEEP REQUESTING
                console.log("NO RESPONSE YET");
            } else {
                // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
                console.log("RESPONSE CAME IN");
                console.log(response);
                userCreateSubscription(response);
                setAlert("Your payment method has been changed", "success");
                sse.close();
            }
        };
        sse.onerror = () => {
            // error log here
            sse.close();
        };
        // return () => {
        //     sse.close();
        // };
    };

    return (
        <form className='width_100pr'>
            <div className='width_700px_320px'>
                <div className='divider'></div>
                <div className='pos_absolute'>
                    <div className='width_80pr ml_10pr mr_10pr'>
                        <Preloader></Preloader>
                    </div>
                </div>
                <PaymentElement />
                {user.openLoader ? <Preloader></Preloader> : null}
                {changeSubscription ? (
                    <div
                        className='button_blue padding_10px width_100pr mt_10px'
                        onClick={
                            user.openLoader ? null : handleChangePaymentMethod
                        }
                    >
                        Submit
                    </div>
                ) : (
                    <div
                        className='button_blue padding_10px width_100pr mt_10px'
                        onClick={user.openLoader ? null : handleSubmit}
                    >
                        Submit
                    </div>
                )}
            </div>
        </form>
    );
};

StripeForm.propTypes = {
    user: PropTypes.object.isRequired,
    userCreateSubscription: PropTypes.func.isRequired,
    openSubscriptionLoader: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {
    userCreateSubscription,
    openSubscriptionLoader,
    setAlert,
})(StripeForm);
