import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import Dialog from "@mui/material/Dialog";

// ACTIONS
import {
    setCurrentWatchlist,
    renameWatchlist,
    deleteWatchlist,
} from "../../../actions/watchlistActions";
import { setAlert } from "../../../actions/alertActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const ChangeWatchlist = ({
    closeModal,
    currentWatchlist,
    user,
    watchlist,
    setCurrentWatchlist,
    renameWatchlist,
    deleteWatchlist,
    setAlert,
}) => {
    // STATE OF THE FORM
    const [changeWatchlist, setChangeWatchlist] = React.useState({
        id: null,
        name: "",
        icon: "collection",
        description: "",
        new_public: null,
    });
    useEffect(() => {
        if (currentWatchlist) {
            setChangeWatchlist({
                id: currentWatchlist.id,
                name: currentWatchlist.name,
                icon: currentWatchlist.icon,
                description: currentWatchlist.description,
                new_public: currentWatchlist.public,
            });
        } else {
            setChangeWatchlist({
                id: null,
                name: null,
                icon: null,
                description: "",
                new_public: null,
            });
        }
        // eslint-disable-next-line
    }, [watchlist.current]);

    const useintl = useIntl();

    /*Create Select Watchlist Icon Modal*/
    const [openWatchlistIcon, setOpenWatchlistIcon] = React.useState(false);
    const handleClickOpenWatchlistIcon = () => {
        setOpenWatchlistIcon(true);
    };
    const handleCloseWatchlistIcon = () => {
        setOpenWatchlistIcon(false);
    };

    /*Change Watchlist functions*/

    const handleChangeWatchlistName = (event) => {
        setChangeWatchlist({ ...changeWatchlist, name: event.target.value });
    };
    const handleChangeWatchlistIcon = (icon) => {
        setChangeWatchlist({ ...changeWatchlist, icon: icon });
    };
    const handleChangeWatchlistPublic = (new_public) => {
        setChangeWatchlist({
            ...changeWatchlist,
            new_public: new_public,
        });
    };
    const handleChangeWatchlistDescription = (event) => {
        setChangeWatchlist({
            ...changeWatchlist,
            description: event.target.value,
        });
    };
    const handleChangeWatchlist = () => {
        let form_data = {
            id: changeWatchlist.id,
            name: changeWatchlist.name,
            icon: changeWatchlist.icon,
            description: changeWatchlist.description,
            public: changeWatchlist.new_public,
            user: user.id,
        };
        if (changeWatchlist.name !== "") {
            renameWatchlist(form_data, watchlist);
            closeModal();
        } else {
            setAlert("Don't forget to give your Watchlist a name.", "warning");
        }
    };

    /*Delete Watchlist Dialog Field*/
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const history = useHistory();
    const routeRedirect = (path) => {
        history.push(path);
    };
    const handleDeleteWatchlist = () => {
        let delete_data = {
            id: currentWatchlist ? currentWatchlist.id : null,
            user: user.id,
        };
        deleteWatchlist(delete_data, watchlist);
        routeRedirect(`/watchlist`);
        if (watchlist.watchlists.length > 0) {
            setCurrentWatchlist(watchlist.watchlists[0].id);
        } else {
            setCurrentWatchlist(null);
        }
        handleCloseDelete();
        closeModal();
    };
    return (
        <div className='background_color_2 text_color_1'>
            {/* HEADER */}
            <div className='flex space_between title_4 mt_10px mb_10px ml_15px mr_15px'>
                <div className='mr_10px'>
                    {useintl.formatMessage({
                        id: "watchlist.changes_to",
                    })}
                    {" - "}'{currentWatchlist.name}'
                </div>
                <div
                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                    onClick={closeModal}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            <div className='divider_no_margin'></div>
            {/* ICON AND NAME */}
            <div className='pl_15px pr_15px mt_20px mb_20px'>
                <div className='text_2'>
                    {useintl.formatMessage({
                        id: "watchlist.icon_and_name",
                    })}
                </div>
                <div className='flex'>
                    <div
                        className='width_15pr mt_3px flex left cursor_pointer hover_opacity_80'
                        onClick={handleClickOpenWatchlistIcon}
                    >
                        <div className='width_20px text_1'>
                            {icon_storage.map((icon) =>
                                icon.name === changeWatchlist.icon
                                    ? icon.icon
                                    : null
                            )}
                        </div>
                        <div className='width_30px text_1'>
                            <RiIcons.RiArrowDownSFill />
                        </div>
                    </div>
                    <div className='input_basic_border border_radius_5px width_85pr padding_5px height_100pr'>
                        <input
                            type='text'
                            defaultValue={
                                changeWatchlist.name !== null
                                    ? changeWatchlist.name
                                    : ""
                            }
                            onKeyUp={handleChangeWatchlistName}
                            className='text_color_1 text_2'
                            placeholder='Name...'
                            maxLength='20'
                        />
                    </div>
                </div>
            </div>
            {/* DESCRIPTION */}
            <div className='pl_15px pr_15px mt_20px mb_20px'>
                <div className='text_2'>
                    {useintl.formatMessage({
                        id: "general.description",
                    })}
                </div>
                <div>
                    <textarea
                        className='text_2 width_100pr text_color_1 height_120px padding_5px resize_none border_radius_5px input_basic_border'
                        name='text'
                        rows='8'
                        maxLength='500'
                        placeholder='Description...'
                        defaultValue={
                            changeWatchlist.description !== null
                                ? changeWatchlist.description
                                : null
                        }
                        onKeyUp={handleChangeWatchlistDescription}
                    />
                </div>
            </div>
            {/* PUBLIC */}
            <div className='pl_15px pr_15px mt_20px mb_20px width_100pr'>
                <div className='text_2'>
                    {useintl.formatMessage({
                        id: "general.public",
                    })}
                </div>
                <div className='width_220px_100pr height_35px accent_color_2 text_3 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                    <div
                        className={
                            changeWatchlist.new_public
                                ? "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                : "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                        }
                        onClick={() => {
                            handleChangeWatchlistPublic(false);
                        }}
                    >
                        {useintl.formatMessage({
                            id: "general.no",
                        })}
                    </div>
                    <div
                        className={
                            changeWatchlist.new_public
                                ? "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                : "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                        }
                        onClick={() => {
                            handleChangeWatchlistPublic(true);
                        }}
                    >
                        {useintl.formatMessage({
                            id: "general.yes",
                        })}
                    </div>
                </div>
            </div>
            <div className='divider_no_margin'></div>
            {/* BUTTONS */}
            <div className='flex wrap space_between mb_15px mt_10px'>
                <div
                    className='button_delete hover_opacity_80 mt_5px ml_15px mr_15px pr_10px pl_10px click_translate'
                    onClick={() => {
                        handleOpenDelete();
                    }}
                >
                    <div className='ml_10px text_3'>
                        {useintl.formatMessage({
                            id: "watchlist.delete_watchlist",
                        })}
                    </div>
                    <div className='mr_10px ml_10px pt_5px text_2'>
                        <MdIcons.MdDelete />
                    </div>
                </div>
                <div
                    className='button_blue color_white mt_5px ml_15px mr_15px'
                    onClick={() => {
                        handleChangeWatchlist();
                    }}
                >
                    <div className='ml_10px text_3'>
                        {useintl.formatMessage({
                            id: "watchlist.save_changes",
                        })}
                    </div>
                    <MdIcons.MdModeEdit className='mr_10px ml_10px text_2' />
                </div>
            </div>

            {/* Select Watchlist Icon Modal */}
            <Dialog
                open={openWatchlistIcon}
                onClose={handleCloseWatchlistIcon}
                maxWidth={false}
                className=''
            >
                <div className='background_color_2 max_width_500px text_color_1'>
                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{"Select a fitting Icon"}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleCloseWatchlistIcon}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider mb_15px'></div>
                    <div className='flex space_around wrap border_box padding_10px'>
                        {icon_storage.map((icon, index) => {
                            return (
                                <div
                                    className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                    key={index}
                                    onClick={() => {
                                        handleChangeWatchlistIcon(icon.name);
                                        handleCloseWatchlistIcon();
                                    }}
                                >
                                    {icon.icon}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>

            {/* Delete Watchlist Modal */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                maxWidth={false}
            >
                <div className='background_color_2 text_color_1'>
                    <div className=' flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>
                            {useintl.formatMessage({
                                id: "watchlist.delete_watchlist",
                            })}
                            {" - "}'{currentWatchlist.name}'
                        </div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleCloseDelete}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className=' mt_10px mb_10px ml_10px mr_10px flex right'>
                        {/* Problemtofix: Dialog mit Icons */}
                        <div
                            className='button_blue color_white ml_15px'
                            onClick={handleCloseDelete}
                        >
                            <div className='ml_10px text_3'>
                                {useintl.formatMessage({
                                    id: "general.no",
                                })}
                            </div>
                            <AiIcons.AiOutlineClose className='mr_10px ml_10px text_2' />
                        </div>
                        <div
                            className='button_delete ml_15px'
                            onClick={() => {
                                handleDeleteWatchlist();
                            }}
                        >
                            <div className='ml_10px text_3'>
                                {useintl.formatMessage({
                                    id: "general.yes",
                                })}
                            </div>
                            <IoIcons5.IoTrashOutline className='mr_10px ml_10px text_2' />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

ChangeWatchlist.propTypes = {
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    deleteWatchlist: PropTypes.func.isRequired,
    renameWatchlist: PropTypes.func.isRequired,
    setCurrentWatchlist: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    watchlist: state.watchlist,
});

export default connect(mapStateToProps, {
    deleteWatchlist,
    renameWatchlist,
    setCurrentWatchlist,
    setAlert,
})(ChangeWatchlist);
