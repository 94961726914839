import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useIntl } from "react-intl";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import logo from "../../favicon.png";

// MUI
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

// COMPONENTS
import Alerts from "../layout/Alerts";

// ACTIONS
import { setAlert } from "../../actions/alertActions";
import {
    loginUser,
    logoutUser,
    loginUserGoogle,
    loginDemoUser,
} from "../../actions/userActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
const {
    REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_ENVIRONMENT,
} = process.env;

const Login = ({
    user,
    loginUser,
    logoutUser,
    loginUserGoogle,
    loginDemoUser,
    setAlert,
}) => {
    const history = useHistory();
    const useintl = useIntl();

    useEffect(() => {
        // CLEAR LOCALSTORAGE/ REDUX etc.
        logoutUser();
        // eslint-disable-next-line
    }, []);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user.isAuthenticated) {
            if (localStorage.getItem("latest_url")) {
                history.push(JSON.parse(localStorage.getItem("latest_url")));
                localStorage.setItem("latest_url", "");
            } else {
                history.push("/screener");
                localStorage.setItem("latest_url", "");
            }
        }
        // eslint-disable-next-line
    }, [user.isAuthenticated]);

    // TRADITIONAL EMAIL LOGIN
    const [values, setValues] = React.useState({
        email: "",
        password: "",
    });

    const { email, password } = values;

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitLogin = () => {
        if (email === "" || password === "") {
            setAlert("Please enter all fields", "danger");
        } else {
            loginUser({
                email: email,
                password: password,
            });
        }
    };

    const submitDemoLogin = () => {
        loginDemoUser();
    };

    // GOOGLE LOGIN
    const googleClientId = REACT_APP_GOOGLE_CLIENT_ID;

    const onGoogleLoginSuccess = (res) => {
        loginUserGoogle(res.profileObj);
    };

    const onGoogleLogoutSuccess = () => {
        console.log("Google Logout Success");
    };

    const onGoogleLoginFailure = (res) => {
        console.log("Google Login Failure: ", res.profileObj);
    };

    return (
        <div
            className='width_100pr background_color_1 text_color_1'
            onKeyUp={(event) => {
                if (event.key === "Enter") {
                    submitLogin();
                }
            }}
        >
            <Helmet>
                <title>{`Login | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Login to your StocksOnView account to access the dashboard features available to your plan.'
                />
            </Helmet>
            <Alerts />
            <div className='ms_auto mt_50px mb_50px width_95pr max_width_350px background_color_2 shadow_dark border_radius_15px'>
                <div className='flex center'>
                    <div className='color_black mt_25px'>
                        <img
                            src={logo}
                            alt='Stocks On View Logo'
                            className='width_80px height_80px'
                        />
                    </div>
                </div>
                <div className='flex center mt_40px title_3'>
                    {useintl.formatMessage({
                        id: "login.welcome_back",
                    })}
                </div>
                <div className='flex center mt_15px text_3'>
                    {useintl.formatMessage({
                        id: "login.log_in",
                    })}
                    {/* <IoIcons5.IoLogInOutline className='ml_10px mt_3px text_2' /> */}
                </div>
                <div className='flex wrap center text_3'>
                    <div>
                        {useintl.formatMessage({
                            id: "register.no_account_yet",
                        })}
                        ?{" "}
                    </div>
                    <div>
                        <Link to={`/register`} className='ml_5px'>
                            {useintl.formatMessage({
                                id: "register.register_here",
                            })}
                        </Link>
                    </div>
                </div>
                <div className='flex center mt_25px mb_25px'>
                    <GoogleLogin
                        clientId={googleClientId}
                        className='width_95pr max_width_250px text_color_black background_color_white'
                        buttonText={useintl.formatMessage({
                            id: "login.continue_with_google",
                        })}
                        onSuccess={onGoogleLoginSuccess}
                        onFailure={onGoogleLoginFailure}
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
                <div className='flex center mt_40px ms_auto'>
                    <TextField
                        autoFocus
                        type='email'
                        className='width_95pr max_width_250px pl_10px background_color_3 text_color_1'
                        id='outlined-basic'
                        label={useintl.formatMessage({
                            id: "general.email",
                        })}
                        variant='outlined'
                        onChange={handleChange("email")}
                    />
                </div>
                <div className='flex center mt_15px width_95pr max_width_250px ms_auto'>
                    <FormControl variant='outlined'>
                        <InputLabel htmlFor='outlined-adornment-password'>
                            {useintl.formatMessage({
                                id: "general.password",
                            })}
                        </InputLabel>
                        <OutlinedInput
                            className='width_100pr background_color_3 text_color_1'
                            id='outlined-adornment-password'
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                    >
                                        {values.showPassword ? (
                                            <IoIcons5.IoEyeOutline />
                                        ) : (
                                            <IoIcons5.IoEyeOffOutline />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                            label={useintl.formatMessage({
                                id: "general.password",
                            })}
                        />
                    </FormControl>
                </div>
                <div className='flex center mt_15px'>
                    <Link to={`/forgot_password`} className='ml_5px'>
                        {useintl.formatMessage({
                            id: "login.forgot_password",
                        })}
                    </Link>
                </div>
                <div className='flex center mt_20px'>
                    <a
                        className='button_blue width_95pr max_width_250px height_40px hover_opacity_80'
                        onClick={submitLogin}
                    >
                        {useintl.formatMessage({
                            id: "general.continue",
                        })}
                    </a>
                </div>
                <div className='flex center mt_20px'>
                    <a
                        className='button_grey text_color_1 width_95pr max_width_250px height_40px hover_opacity_80'
                        onClick={submitDemoLogin}
                    >
                        Try Demo
                    </a>
                </div>
                {/* <div className='flex center mt_25px'>
                    <GoogleLogout
                        clientId={googleClientId}
                        buttonText='Logout'
                        onLogoutSuccess={onGoogleLogoutSuccess}
                    />
                </div> */}
                {/* <div className='flex center mt_15px'>
                    <div className='button_facebook color_white width_95pr max_width_250px height_40px'>
                        <div className='ml_15px text_2 pt_5px'>
                            <IoIcons5.IoLogoFacebook />
                        </div>
                        <div className='ml_15px text_3'>
                            {useintl.formatMessage({
                                id: "login.continue_with_facebook",
                            })}
                        </div>
                    </div>
                </div>
                <div className='flex center mt_15px mb_25px'>
                    <div className='button_apple color_black width_95pr max_width_250px height_40px'>
                        <div className='ml_15px text_2 pt_5px'>
                            <IoIcons5.IoLogoApple />
                        </div>
                        <div className='ml_15px text_3'>
                            {useintl.formatMessage({
                                id: "login.continue_with_apple",
                            })}
                        </div>
                    </div>
                </div> */}
                <div className='divider_no_margin'></div>

                <div className='flex wrap center pt_25px pb_25px'>
                    <div>
                        {useintl.formatMessage({
                            id: "general.questions",
                        })}
                        ?{" "}
                    </div>
                    <a className='ml_5px' href='mailto: info@stocksonview.com'>
                        info@stocksonview.com
                    </a>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    user: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    loginUserGoogle: PropTypes.func.isRequired,
    loginDemoUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    loginUser,
    logoutUser,
    loginUserGoogle,
    loginDemoUser,
})(Login);
