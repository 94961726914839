import { GET_COMPANIES_COMPARE, COMPARE_ERROR } from "./Types";

// Get all companies contained in the URL (sharing etc.)
export const getCompaniesCompareUrl = (tickers) => async (dispatch) => {
    try {
        console.log(`/node_compare${tickers}`);
        const res = await fetch(`/node_compare${tickers}`);
        const data = await res.json();

        dispatch({
            type: GET_COMPANIES_COMPARE,
            payload: data,
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: COMPARE_ERROR,
            payload: err.response.statusText,
        });
    }
};
