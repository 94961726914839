import React, { useEffect, lazy, Suspense } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

// ICONS
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// COMPONENTS
import Ad0Vertical100vw from "../ads/Ad0Vertical100vw";
import FeedbackIcon from "../elements/FeedbackIcon";
import MCFairValueDistribution from "./MCFairValueDistribution";
import MCProbabilityDensity from "./MCProbabilityDensity";
import Preloader from "../../layout/Preloader";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";
// import MCCashflows from "./MCCashflows";
// import MCDiscountedCashflows from "./MCDiscountedCashflows";
// import MCSlider from "./MCSlider";

// MUI
import Dialog from "@mui/material/Dialog";
// import Slider from "@mui/material/Slider";
// import Tooltip from "@mui/material/Tooltip";
// import Popover from "@mui/material/Popover";

import {
    getMonteCarloParams,
    getMonteCarloByParams,
    setCompanyLoading,
    resetMonteCarloParams,
    setMonteCarloParams,
} from "../../../actions/companyActions";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

// LAZY COMPONENTS
const MCSlider = lazy(() => import("./MCSlider"));
const MCCashflows = lazy(() => import("./MCCashflows"));
const MCDiscountedCashflows = lazy(() => import("./MCDiscountedCashflows"));

// RETURN CLOSEST INDEX OF VALUE
function closest(num, arr) {
    var curr = arr[0],
        diff = Math.abs(num - curr),
        index = 0;

    for (var val = 0; val < arr.length; val++) {
        let newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        }
    }
    return index;
}

// RETURN SMALLER VALUE
// const min = (value1, value2) => {
//     if (value1 < value2) {
//         return value1;
//     } else {
//         return value2;
//     }
// };

const renderLoader = () => {
    <p>loading...</p>;
};

const MonteCarloDCF = ({
    sidebar: { sidebar },
    company: {
        company_monte_carlo_dcf_params,
        company_monte_carlo_dcf,
        company_monte_carlo_dcf_preview,
        monte_carlo_params,
        loading,
    },
    getMonteCarloParams,
    getMonteCarloByParams,
    setCompanyLoading,
    resetMonteCarloParams,
    setMonteCarloParams,
}) => {
    const useintl = useIntl();

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    let current_year = 2022;
    let future_years = [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
    ];

    let stock_price_index =
        company_monte_carlo_dcf &&
        company_monte_carlo_dcf.bin_edges &&
        monte_carlo_params &&
        monte_carlo_params.p_0 &&
        monte_carlo_params.p_0 !== null
            ? closest(monte_carlo_params.p_0, company_monte_carlo_dcf.bin_edges)
            : null;

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (window.location.search) {
            setCompanyLoading();
            getMonteCarloParams(window.location.search);
            if (monte_carlo_params && monte_carlo_params.n_periods !== null) {
                future_years = [];
                for (let i = 1; i <= monte_carlo_params.n_periods; i++) {
                    future_years.push(`${current_year + i}`);
                }
            }
        } else if (monte_carlo_params) {
            setCompanyLoading();
            getMonteCarloByParams(monte_carlo_params);
            if (monte_carlo_params && monte_carlo_params.n_periods !== null) {
                future_years = [];
                for (let i = 1; i <= monte_carlo_params.n_periods; i++) {
                    future_years.push(`${current_year + i}`);
                }
            }
        } else {
            setCompanyLoading();
            setMonteCarloParams({
                n_trials: 100000, // 1-100000
                n_periods: 10, // 1-20
                r_0: 1.3, // 0.1-5
                dr_0: 0.3, // 0.001-5
                r_n: 1.04, // 0.1-5
                dr_n: 0.16, // 0.001-5
                S_0: 10, // >0 - 100000
                p_0: 100,
                m: 0.3,
                dm: 0.1,
                discount_rate: 0.1, // 0-9
                terminal_multiple: 15, // 0-100
                cash: 0, // 0-100k
                debt: 0, // 0-100k
            });
            getMonteCarloByParams({
                n_trials: 100000, // 1-100000
                n_periods: 10, // 1-20
                r_0: 1.3, // 0.1-5
                dr_0: 0.3, // 0.001-5
                r_n: 1.04, // 0.1-5
                dr_n: 0.16, // 0.001-5
                S_0: 10, // >0 - 100000
                p_0: 100,
                m: 0.3,
                dm: 0.1,
                discount_rate: 0.1, // 0-9
                terminal_multiple: 15, // 0-100
                cash: 0, // 0-100k
                debt: 0, // 0-100k
            });
            if (monte_carlo_params && monte_carlo_params.n_periods !== null) {
                future_years = [];
                for (let i = 1; i <= monte_carlo_params.n_periods; i++) {
                    future_years.push(`${current_year + i}`);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (monte_carlo_params && monte_carlo_params.n_periods !== null) {
            future_years = [];
            for (let i = 1; i <= monte_carlo_params.n_periods; i++) {
                future_years.push(`${current_year + i}`);
            }
        }
        // eslint-disable-next-line
    }, [monte_carlo_params]);

    // HANDLE UPDATE OF PARAMETERS
    const handleGetMontecarlo = () => {
        setCompanyLoading();
        getMonteCarloByParams(monte_carlo_params);
        if (monte_carlo_params.n_periods !== null) {
            future_years = [];
            for (let i = 1; i <= monte_carlo_params.n_periods; i++) {
                future_years.push(`${current_year + i}`);
            }
        }
    };

    const handleResetMonteCarloParams = () => {
        if (window.location.search) {
            console.log("PRE RESET PARAMS: ", company_monte_carlo_dcf_params);
            resetMonteCarloParams(company_monte_carlo_dcf_params);
            getMonteCarloByParams(company_monte_carlo_dcf_params);
        } else {
            resetMonteCarloParams({
                n_trials: 100000,
                n_periods: 10,
                r_0: 1.03,
                dr_0: 0.005,
                r_n: 1.04,
                dr_n: 0.16,
                S_0: 10,
                p_0: 100,
                m: 0.3,
                dm: 0.1,
                discount_rate: 0.1,
                terminal_multiple: 15,
                cash: 0, // 0-100k
                debt: 0, // 0-100k
            });
            getMonteCarloByParams({
                n_trials: 100000, // 1-100000
                n_periods: 10, // 1-20
                r_0: 1.3, // 0.1-5
                dr_0: 0.3, // 0.001-5
                r_n: 1.04, // 0.1-5
                dr_n: 0.16, // 0.001-5
                S_0: 10, // >0 - 100000
                p_0: 100,
                m: 0.3,
                dm: 0.1,
                discount_rate: 0.1, // 0-9
                terminal_multiple: 15, // 0-100
                cash: 0, // 0-100k
                debt: 0, // 0-100k
            });
        }
    };

    // PARAMETER MENU COMPONENT
    const [openParameters, setOpenParameters] = React.useState(false);
    const handleOpenParameters = () => {
        setOpenParameters(true);
    };
    const handleCloseParameters = () => {
        setOpenParameters(false);
    };

    // OPEN CHANGES STATE IN SLIDERS
    const [openChanges, setOpenChanges] = React.useState(false);

    let mcdcf_metrics = [
        {
            property: "n_periods",
            default: 10,
            min: 1,
            max: 20,
            slider_extremes: [1, 20],
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.n_periods",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.n_periods",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.n_periods.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 1,
                    max: 20,
                }
            ),
        },
        {
            property: "p_0",
            default: 100,
            min: 0,
            max: 100000,
            slider_extremes: [0, 1000],
            step: 1,
            displayName: useintl.formatMessage({
                id: "metrics.p_0",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.p_0",
            }),
            unit: " USD",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.p_0.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 100000,
                }
            ),
        },
        {
            property: "S_0",
            default: 10,
            min: 0,
            max: 10000,
            slider_extremes: [0, 100],
            step: 0.1,
            displayName: useintl.formatMessage({
                id: "metrics.S_0",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.S_0",
            }),
            unit: " USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.S_0.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 10000,
                }
            ),
        },
        {
            property: "r_0",
            default: 1.3,
            min: 0.1,
            max: 5,
            slider_extremes: [0.95, 2.5],
            step: 0.01,
            displayName: useintl.formatMessage({
                id: "metrics.r_0",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.r_0",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.r_0.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: -90,
                    max: 500,
                }
            ),
        },
        {
            property: "dr_0",
            default: 0.3,
            min: 0.001,
            max: 5,
            slider_extremes: [0.001, 1],
            step: 0.001,
            displayName: useintl.formatMessage({
                id: "metrics.dr_0",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dr_0",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dr_0.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0.1,
                    max: 500,
                }
            ),
        },
        {
            property: "r_n",
            default: 1.04,
            min: 0.1,
            max: 5,
            slider_extremes: [0.95, 2.5],
            step: 0.01,
            displayName: useintl.formatMessage({
                id: "metrics.r_n",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.r_n",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.r_n.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: -90,
                    max: 500,
                }
            ),
        },
        {
            property: "dr_n",
            default: 0.16,
            min: 0.001,
            max: 5,
            slider_extremes: [0.001, 1],
            step: 0.001,
            displayName: useintl.formatMessage({
                id: "metrics.dr_n",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dr_n",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dr_n.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0.1,
                    max: 500,
                }
            ),
        },
        {
            property: "m",
            default: 0.3,
            min: 0,
            max: 1,
            slider_extremes: [0.01, 1],
            step: 0.01,
            displayName: useintl.formatMessage({
                id: "metrics.m",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.m",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.m.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 100,
                }
            ),
        },
        {
            property: "dm",
            default: 0.1,
            min: 0,
            max: 0.5,
            slider_extremes: [0, 0.5],
            step: 0.001,
            displayName: useintl.formatMessage({
                id: "metrics.dm",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dm",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dm.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 50,
                }
            ),
        },
        {
            property: "terminal_multiple",
            default: 15,
            min: 0,
            max: 100,
            slider_extremes: [0, 100],
            step: 0.5,
            displayName: useintl.formatMessage({
                id: "metrics.terminal_multiple",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.terminal_multiple",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.terminal_multiple.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 100,
                }
            ),
        },
        {
            property: "discount_rate",
            default: 0.1,
            min: 0,
            max: 9,
            slider_extremes: [0, 1],
            step: 0.05,
            displayName: useintl.formatMessage({
                id: "metrics.discount_rate",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.discount_rate",
            }),
            unit: " %",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.discount_rate.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 90,
                }
            ),
        },
        {
            property: "cash",
            default: 0,
            min: 0,
            max: 300,
            slider_extremes: [0, 200],
            step: 3,
            displayName: useintl.formatMessage({
                id: "metrics.cash",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash",
            }),
            unit: " USD",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 100000,
                }
            ),
        },
        {
            property: "debt",
            default: 0,
            min: 0,
            max: 300,
            slider_extremes: [0, 200],
            step: 3,
            displayName: useintl.formatMessage({
                id: "metrics.debt",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt",
            }),
            unit: " USD",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider" },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt.description_text_1",
            }),
            disclaimer_text_1: useintl.formatMessage(
                {
                    id: "metrics.mcdcf.disclaimer_text_1",
                },
                {
                    min: 0,
                    max: 100000,
                }
            ),
        },
    ];

    // FEEDBACK
    let feedbackInfo = {
        location: `Monte Carlo - Discounted Cash Flow`,
        location_index: 7,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `Monte Carlo - Discounted Cash Flow`,
        location_index: 7,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "mcdcf.header_text_1",
        }),
        description_text_1: useintl.formatMessage({
            id: "mcdcf.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "mcdcf.header_text_2",
        }),
        description_text_2: useintl.formatMessage({
            id: "mcdcf.description_text_2",
        }),
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>
                    {`
                    ${
                        window.location.search
                            ? window.location.search.split("?ticker=")[1] +
                              " - "
                            : ""
                    }
                    Discounted Cash Flow | StocksOnView`}
                </title>
                <meta
                    name='description'
                    content='Use our Monte Carlo Discounted Cash Flow analysis to calculate a fair value distribution for your investment ideas.'
                />
            </Helmet>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                informationInfo={informationInfo}
            />
            <TopBarCallToAction />
            <div className='flex width_100pr mt_20px mb_20px'>
                <div className='title_4 ml_25px_10px mt_5px'>
                    <MdIcons.MdAreaChart />
                </div>
                <div className='title_4 ml_15px'>
                    Discounted Cash Flow Analysis
                </div>
                <AiIcons.AiOutlineInfoCircle
                    className='text_1 hover_translate_up ml_15px mt_5px'
                    onClick={() => {
                        handleOpenPageInfo();
                    }}
                />
            </div>

            <PageInfoModalSimple
                infoHeader={"What can i do here?"}
                infoBody={
                    "Here you can perform a discounted cash flow analysis along the lines of Aswath Damodaran. In each individual company view, you have the option of importing metrics and adjusting them here under 'Parameters'. A detailed description on how to use this page can be found in the bottom right corner of this page."
                }
                showPageInfo={showPageInfo}
                handleClosePageInfo={handleClosePageInfo}
            />

            <div className='mt_15px'>
                {monte_carlo_params ? (
                    <div className='pl_20px pr_20px width_100pr mb_20px flex'>
                        <div
                            className='button_blue hover_opacity_80 text_2 padding_10px click_translate'
                            onClick={() => {
                                handleOpenParameters();
                                // setSearchSliderObject(SliderData);
                            }}
                        >
                            <div className='text_2 pr_10px'>Parameters</div>
                            <FiIcons.FiSliders />
                        </div>
                        {/* PARAMETER MENU COMPONENT */}
                        <Dialog
                            open={openParameters}
                            onClose={handleCloseParameters}
                            fullScreen={true}
                        >
                            <div className=' min_height_500px height_100vh_real background_color_2'>
                                {/* HEADING */}
                                <div className='flex space_between mb_20px'>
                                    <div className='flex wrap space_between width_100pr'>
                                        {/* Header, Country-selection */}
                                        <div className='flex wrap'>
                                            <div className='ml_20px mt_20px text_1 text_color_1 flex'>
                                                <div className=''>
                                                    DCF Parameters
                                                </div>
                                                <FiIcons.FiSliders className='ml_5px' />
                                            </div>
                                        </div>

                                        {/* Reset, Submit */}
                                        <div className='flex wrap mt_10px'>
                                            <div
                                                className='button_white_layer_2 hover_opacity_80 text_2 padding_10px mt_10px ml_15px'
                                                onClick={() => {
                                                    handleResetMonteCarloParams();
                                                    handleCloseParameters();
                                                }}
                                            >
                                                <div className='text_2 pr_10px'>
                                                    {useintl.formatMessage({
                                                        id: "general.reset",
                                                    })}
                                                </div>
                                                <IoIcons.IoIosRefresh />
                                            </div>
                                            <div
                                                className='button_blue hover_opacity_80 text_2 padding_10px mt_10px ml_15px mr_15px'
                                                onClick={() => {
                                                    handleGetMontecarlo();
                                                    handleCloseParameters();
                                                }}
                                            >
                                                <div className='text_2 pr_10px'>
                                                    {useintl.formatMessage({
                                                        id: "general.apply",
                                                    })}
                                                </div>
                                                <FiIcons.FiSliders />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='cursor_pointer title_4 text_color_1 flex center mr_15px mt_20px'
                                        onClick={handleCloseParameters}
                                    >
                                        <MdIcons.MdClose />
                                    </div>
                                </div>
                                {loading ? <Preloader /> : null}
                                <div className='margin_10px pl_15px'>
                                    To import preset metrics of the company you
                                    want to analyze, you can click the 'import
                                    to dcf' button in the company's analysis
                                    view.
                                </div>

                                <div className='divider_no_margin'></div>

                                {/* METRICS */}
                                <div className='flex wrap space_around width_100pr border_box mt_20px min_width_320px background_color_2'>
                                    <div
                                        className={
                                            window.innerWidth > 1100
                                                ? "width_60pr_100pr flex wrap"
                                                : "width_100pr flex wrap"
                                        }
                                        // className="width_100pr flex wrap"
                                    >
                                        {mcdcf_metrics.map((parameter) => (
                                            <div className='width_100pr'>
                                                <Suspense
                                                    fallback={renderLoader}
                                                >
                                                    <MCSlider
                                                        sliderprops={parameter}
                                                        parameters_value={
                                                            monte_carlo_params[
                                                                parameter
                                                                    .property
                                                            ]
                                                        }
                                                        company_monte_carlo_dcf_params={
                                                            monte_carlo_params
                                                        }
                                                        setCompanyLoading={
                                                            setCompanyLoading
                                                        }
                                                        setOpenChanges={
                                                            setOpenChanges
                                                        }
                                                    />
                                                </Suspense>
                                            </div>
                                        ))}
                                    </div>
                                    {window.innerWidth > 1100 &&
                                    company_monte_carlo_dcf_preview ? (
                                        <div className='width_40pr_100pr flex flex_column height_100pr space_around align_items_start'>
                                            <div className='text_1 pl_20px'>
                                                Preview
                                            </div>
                                            <div className='height_350px width_100pr'>
                                                <MCProbabilityDensity
                                                    height={350}
                                                    p_undervalued_arr={
                                                        company_monte_carlo_dcf_preview.p_undervalued_arr
                                                    }
                                                    bin_edges={
                                                        company_monte_carlo_dcf_preview.bin_edges
                                                    }
                                                    fair_value={
                                                        company_monte_carlo_dcf_preview.fair_value
                                                    }
                                                    stock_price={
                                                        monte_carlo_params.p_0
                                                    }
                                                />
                                            </div>
                                            <div className='height_350px width_100pr'>
                                                <Suspense
                                                    fallback={renderLoader}
                                                >
                                                    <MCDiscountedCashflows
                                                        height={350}
                                                        discounted_cash_flow_matrix={
                                                            company_monte_carlo_dcf_preview.discounted_cash_flow_matrix
                                                        }
                                                        mean_discounted_cash_flow={
                                                            company_monte_carlo_dcf_preview.mean_discounted_cash_flow
                                                        }
                                                        future_years={
                                                            future_years
                                                        }
                                                    />
                                                </Suspense>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Dialog>
                    </div>
                ) : null}
                {loading ? <Preloader /> : null}

                {monte_carlo_params ? (
                    <div>
                        <div className='divider'></div>
                        <div className='pl_20px_0px pr_20px_0px width_100pr height_auto overflow_x_auto scrollbar_invisible'>
                            <div className='flex width_100pr min_width_450px height_80px text_color_1'>
                                <div className='width_30pr height_90pr shadow_dark background_color_2 min_width_150px max_width_250px flex flex_column align_items_start space_between padding_10px'>
                                    <div>Fair value</div>
                                    <div>
                                        {company_monte_carlo_dcf &&
                                        company_monte_carlo_dcf.fair_value ? (
                                            <div>
                                                {Number(
                                                    company_monte_carlo_dcf.fair_value.toFixed(
                                                        3
                                                    )
                                                )}{" "}
                                                USD
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {monte_carlo_params &&
                                monte_carlo_params.p_0 ? (
                                    <div className='width_30pr ml_10px height_90pr shadow_dark background_color_2 min_width_150px max_width_250px flex flex_column align_items_start space_between padding_10px'>
                                        <div>
                                            Current price{" "}
                                            {window.location.search
                                                ? "(" +
                                                  window.location.search.split(
                                                      "?ticker="
                                                  )[1] +
                                                  ")"
                                                : window.location.search.split(
                                                      "?ticker="
                                                  )[1]}
                                        </div>
                                        <div>
                                            {monte_carlo_params &&
                                            monte_carlo_params.p_0 ? (
                                                <div>
                                                    {Number(
                                                        monte_carlo_params.p_0.toFixed(
                                                            3
                                                        )
                                                    )}{" "}
                                                    USD
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                                {stock_price_index !== null &&
                                company_monte_carlo_dcf &&
                                company_monte_carlo_dcf.p_undervalued_arr ? (
                                    <div className='width_30pr ml_10px height_90pr shadow_dark background_color_2 min_width_150px max_width_250px flex flex_column align_items_start space_between padding_10px'>
                                        <div>
                                            Probabilty of being undervalued
                                        </div>
                                        <div>
                                            {company_monte_carlo_dcf &&
                                            company_monte_carlo_dcf.fair_value ? (
                                                <div className='flex no_wrap'>
                                                    <div className='mr_5px'>
                                                        {Number(
                                                            (
                                                                Number(
                                                                    company_monte_carlo_dcf
                                                                        .p_undervalued_arr[
                                                                        stock_price_index
                                                                    ]
                                                                ) * 100
                                                            ).toFixed(3)
                                                        )}
                                                    </div>

                                                    <div>%</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className='divider_no_margin'></div>
                {company_monte_carlo_dcf ? (
                    <div className='mt_20px'>
                        <Ad0Vertical100vw />
                        <MCFairValueDistribution
                            hist={company_monte_carlo_dcf.hist}
                            p_undervalued_arr={
                                company_monte_carlo_dcf.p_undervalued_arr
                            }
                            bin_edges={company_monte_carlo_dcf.bin_edges}
                            fair_value={company_monte_carlo_dcf.fair_value}
                            stock_price={monte_carlo_params.p_0}
                        />
                        <MCProbabilityDensity
                            p_undervalued_arr={
                                company_monte_carlo_dcf.p_undervalued_arr
                            }
                            bin_edges={company_monte_carlo_dcf.bin_edges}
                            fair_value={company_monte_carlo_dcf.fair_value}
                            stock_price={monte_carlo_params.p_0}
                        />
                        <Ad0Vertical100vw />
                        <Suspense fallback={renderLoader}>
                            <MCCashflows
                                cash_flow_matrix={
                                    company_monte_carlo_dcf.cash_flow_matrix
                                }
                                mean_cash_flow={
                                    company_monte_carlo_dcf.mean_cash_flow
                                }
                                future_years={future_years}
                            />
                        </Suspense>
                        <Suspense fallback={renderLoader}>
                            <MCDiscountedCashflows
                                discounted_cash_flow_matrix={
                                    company_monte_carlo_dcf.discounted_cash_flow_matrix
                                }
                                mean_discounted_cash_flow={
                                    company_monte_carlo_dcf.mean_discounted_cash_flow
                                }
                                future_years={future_years}
                            />
                        </Suspense>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

MonteCarloDCF.propTypes = {
    sidebar: PropTypes.object.isRequired,
    // company_monte_carlo_dcf_params
    getMonteCarloParams: PropTypes.func.isRequired,
    getMonteCarloByParams: PropTypes.func.isRequired,
    setCompanyLoading: PropTypes.func.isRequired,
    resetMonteCarloParams: PropTypes.func.isRequired,
    setMonteCarloParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    company: state.company,
});

export default connect(mapStateToProps, {
    getMonteCarloParams,
    getMonteCarloByParams,
    setCompanyLoading,
    resetMonteCarloParams,
    setMonteCarloParams,
})(MonteCarloDCF);
