import React from "react";
import { useIntl } from "react-intl";
import * as IoIcons5 from "react-icons/io5";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    getFilteredCompanies,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
} from "../../../actions/screenerAction";

import { getCompetitors } from "../../../actions/companyActions";

import { getWatchlist } from "../../../actions/watchlistActions";

const CompaniesTableHead = ({
    metric,
    options,
    slider,
    watchlist,
    user,
    threshold,
    checked,
    competitorsUrl,
    competitorsType,
    industry,
    getFilteredCompanies,
    getCompetitors,
    getWatchlist,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
}) => {
    const useintl = useIntl();

    let not_metrics = [
        "page",
        "limit",
        "search",
        "searchOption",
        "sort",
        "industry",
        "esg",
        "stock_exchange",
        "ticker",
        "description",
        "name",
        "currency",
        "country",
    ];
    let not_sortable = [
        "industry",
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];
    let not_metrics_screener = ["market_cap", "market_cap_usd", "ticker"];
    let not_metrics_watchlist = [
        "market_cap",
        "market_cap_usd",
        "ticker",
        "daily_return",
    ];

    const sortTableBy = (metric) => {
        if (window.location.pathname === "/watchlist") {
            let oldOptions = options;
            let oldSortMetric = oldOptions.sortWatchlistBy.value;
            if (oldSortMetric !== metric) {
                oldOptions.sortWatchlistBy.value = metric;
                oldOptions.sortWatchlistBy.direction = "DESC";
            } else {
                if (oldOptions.sortWatchlistBy.direction === "DESC") {
                    oldOptions.sortWatchlistBy.direction = "ASC";
                } else {
                    oldOptions.sortWatchlistBy.direction = "DESC";
                }
            }
            updateSingleOptionSortWatchlistBy(oldOptions.sortWatchlistBy);
            getWatchlist(
                watchlist,
                user,
                oldOptions.sortWatchlistBy.value,
                oldOptions.sortWatchlistBy.direction
            );
        } else {
            let oldOptions = options;
            let oldSortMetric = oldOptions.sortBy.value;
            if (oldSortMetric !== metric) {
                oldOptions.sortBy.value = metric;
                oldOptions.sortBy.direction = "DESC";
            } else {
                if (oldOptions.sortBy.direction === "DESC") {
                    oldOptions.sortBy.direction = "ASC";
                } else {
                    oldOptions.sortBy.direction = "DESC";
                }
            }
            updateSingleOptionSortBy(oldOptions.sortBy);
            if (window.location.pathname === "/screener" || industry === null) {
                getFilteredCompanies(slider, options, 0, threshold, checked);
            } else if (window.location.pathname === "/company") {
                getCompetitors(
                    `?${competitorsType}=${encodeURIComponent(competitorsUrl)}`,
                    oldOptions.sortBy.value,
                    oldOptions.sortBy.direction,
                    0
                );
            }
        }
    };
    if (window.location.pathname === "/watchlist") {
        if (
            !not_metrics.includes(metric) &&
            !not_metrics_watchlist.includes(metric)
        ) {
            return options.sortWatchlistBy.value === metric ? (
                <th
                    className='padding_15_5px flex right text_2 text_right text_color_blue min_width_120px cursor_pointer vertical_align_top'
                    onClick={() => {
                        sortTableBy(metric);
                    }}
                >
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                    {options.sortBy.direction === "ASC" ? (
                        <IoIcons5.IoArrowUpOutline className='ml_5px width_15px text_color_blue' />
                    ) : (
                        <IoIcons5.IoArrowDownOutline className='ml_5px width_15px text_color_blue' />
                    )}
                </th>
            ) : not_sortable.includes(metric) ? (
                <th className='min_width_120px text_2 text_color_1'>
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                </th>
            ) : (
                <th
                    className='padding_15_5px min_width_120px text_2 text_right text_color_1 cursor_pointer vertical_align_top'
                    onClick={() => {
                        sortTableBy(metric);
                    }}
                >
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                </th>
            );
        } else {
            return null;
        }
    } else {
        if (
            !not_metrics.includes(metric) &&
            !not_metrics_screener.includes(metric)
        ) {
            return options.sortBy.value === metric ? (
                <th
                    className='padding_15_5px flex right text_2 text_right text_color_blue min_width_120px cursor_pointer vertical_align_top'
                    onClick={() => {
                        sortTableBy(metric);
                    }}
                >
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                    {options.sortBy.direction === "ASC" ? (
                        <IoIcons5.IoArrowUpOutline className='ml_5px text_color_blue' />
                    ) : (
                        <IoIcons5.IoArrowDownOutline className='ml_5px text_color_blue' />
                    )}
                </th>
            ) : not_sortable.includes(metric) ? (
                <th className='min_width_120px text_2 text_color_1'>
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                </th>
            ) : (
                <th
                    className='padding_15_5px min_width_120px text_2 text_right text_color_1 cursor_pointer vertical_align_top'
                    onClick={() => {
                        sortTableBy(metric);
                    }}
                >
                    {useintl.formatMessage({
                        id: `metrics.${metric}`,
                    })}
                </th>
            );
        } else {
            return null;
        }
    }
};

CompaniesTableHead.propTypes = {
    slider: PropTypes.object.isRequired,
    watchlist: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
    options: PropTypes.object.isRequired,
    getCompetitors: PropTypes.func.isRequired,
    getFilteredCompanies: PropTypes.func.isRequired,
    getWatchlist: PropTypes.func.isRequired,
    updateSingleOptionSortBy: PropTypes.func.isRequired,
    updateSingleOptionSortWatchlistBy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    getFilteredCompanies,
    getCompetitors,
    getWatchlist,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
})(CompaniesTableHead);
