import React from "react";
import { Link } from "react-router-dom";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AiIcons from "react-icons/ai";

const MetricAnalysisBarUpgrade = ({ sliderprops, dummy_value }) => {
    return (
        <div className='background_color_3 width_100pr min_width_300px max_width_1500px mb_15px'>
            <div className='flex wrap pl_10px'>
                {/* NAME, FIGURE, INFO (width_40pr_100pr) */}
                <div className='flex space_between width_100pr min_width_300px'>
                    {/* NAME */}
                    <div className='text_3 width_30pr_50pr min_width_150px'>
                        {sliderprops.displayName}
                    </div>
                    {/* VALUE */}
                    <div className='width_50pr_30pr min_width_50px'>
                        <Link to={`/account`}>Upgrade</Link>
                    </div>{" "}
                    {/* INFO ICONS */}
                    <div className='text_2 text_color_2 width_20pr min_width_50px flex right pr_10px'>
                        <AiIcons.AiOutlineFullscreen />
                        <AiIcons.AiOutlineInfoCircle />
                    </div>
                </div>
                {/* RANKER (width_60pr_100pr) */}
                <div className='width_100pr min_width_300px pr_10px'>
                    <div className='width_100pr cursor_pointer'>
                        {/* BARCHART */}

                        <div className='border_box flex width_100pr border_1px_solid_light_grey left mb_5px'>
                            <div
                                className='background_color_blue'
                                style={{
                                    width: `${dummy_value}%`,
                                    height: "10px",
                                }}
                            >
                                <div className='text_color_white text_right text_5 mt_-3px pr_5px'>
                                    {/* {metric_ranker} % */}
                                </div>
                                {/*
                                    {ranker_hovered &&
                                    !isNaN(average_metric_ranker) &&
                                    average_metric_ranker > 20 ? (
                                        <div className='width_100pr height_100pr flex right pos_relative min_width_150px'>
                                            <div className='pos_absolute ml_10px text_5 pr_5px mt_5px text_color_white'>
                                                Ranker: {average_metric_ranker}{" "}
                                                %
                                            </div>
                                        </div>
                                    ) : ranker_hovered &&
                                      !isNaN(average_metric_ranker) &&
                                      average_metric_ranker <= 20 ? (
                                        <div className='width_100pr height_100pr flex left pos_relative min_width_150px'>
                                            <div className='pos_absolute pl_5px text_5 pr_5px mt_5px text_color_white'>
                                                Ranker: {average_metric_ranker}{" "}
                                                %
                                            </div>
                                        </div>
                                    ) : null} */}
                            </div>
                        </div>

                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

MetricAnalysisBarUpgrade.propTypes = {
    sliderprops: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MetricAnalysisBarUpgrade);
