import React, { useContext } from "react";
// import * as MdIcons from "react-icons/md";

import Preloader from "../../layout/Preloader";

// Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import { Context_Theme } from "../../../context/ThemeContext";

// Bring in Redux and App level state
import { connect } from "react-redux";
// import PropTypes from "prop-types";

const IndexPerformance = ({ index_performance_data }) => {
    const context_theme = useContext(Context_Theme);
    Highcharts.setOptions(context_theme.currentThemeObject);

    const [relative, setRelative] = React.useState(false);
    const handleSetRelative = () => {
        setLog(false);
        setRelative(!relative);
    };

    const [log, setLog] = React.useState(false);
    const handleSetLog = () => {
        setLog(!log);
        setRelative(false);
    };

    let chartOptions = {
        chart: {
            height: "400px",
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        rangeSelector: {
                            selected: 1,
                            buttons: [
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
                {
                    condition: {
                        minWidth: 401,
                    },
                    chartOptions: {
                        rangeSelector: {
                            selected: 2,
                            buttons: [
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "year",
                                    count: 1,
                                    text: "1y",
                                },
                                {
                                    type: "year",
                                    count: 5,
                                    text: "5y",
                                },
                                {
                                    type: "year",
                                    count: 15,
                                    text: "15y",
                                },
                                {
                                    type: "year",
                                    count: 30,
                                    text: "30y",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
            ],
        },
        title: {
            text: "",
        },
        stockTools: {
            gui: "disabled",
        },
        yAxis: [
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Index Price",
                },
                type: log ? "logarithmic" : "linear",
                height: "85%",
                lineWidth: 2,
                resize: {
                    enabled: true,
                },
            },
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Volume",
                },
                top: "85%",
                height: "15%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        navigator: {
            enabled: true,
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            text: " ",
        },
        plotOptions: {
            series: {
                compare: relative && !log ? "percent" : "undefined",
            },
        },
        tooltip: {
            shared: true,
            hideDelay: 50,
            pointFormat:
                relative && !log
                    ? '<span style="color:{series.color}">{series.name}</span>: {point.y} <b>({point.change}%)</b><br/>'
                    : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            changeDecimals: 2,
            valueDecimals: 2,
        },
        series: [
            {
                type: "area",
                name: "Index Price",
                data: index_performance_data.performance,
                threshold: null,
                id: "stockprice",
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [
                            0,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0.7)
                                .get("rgba"),
                        ],
                        [
                            1,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0)
                                .get("rgba"),
                        ],
                    ],
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: " USD",
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            {
                type: "column",
                name: "Volume",
                data: relative ? null : index_performance_data.volume,
                threshold: null,
                yAxis: 1,
                color: Highcharts.getOptions().colors[0],
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
        ],
    };

    return (
        <div>
            {index_performance_data && index_performance_data !== null ? (
                <div>
                    <div>
                        <div className='flex space_between height_70px padding_20px'>
                            <div className='text_1'>
                                General Index Performance - S&P500 Index
                            </div>
                            <div className='flex'>
                                <div
                                    className={
                                        relative
                                            ? "button_white_active pl_10px pr_10px hover_opacity_80 mr_10px"
                                            : "button_white_layer_2 pl_10px pr_10px hover_opacity_80 mr_10px"
                                    }
                                    onClick={handleSetRelative}
                                >
                                    Relative
                                </div>
                                <div
                                    className={
                                        log
                                            ? "button_white_active pl_10px pr_10px hover_opacity_80"
                                            : "button_white_layer_2 pl_10px pr_10px hover_opacity_80"
                                    }
                                    onClick={handleSetLog}
                                >
                                    Logarithmic
                                </div>
                            </div>
                        </div>
                        <div className='company_overview_stock_price_max_height'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                                constructorType={"stockChart"}
                                containerProps={{ className: "chart" }}
                                className='company_overview_stock_price'
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
        </div>
    );
};

IndexPerformance.propTypes = {
    // portfolio: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    // portfolio: state.portfolio,
});

export default connect(mapStateToProps, null)(IndexPerformance);
