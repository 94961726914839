import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as IoIcons5 from "react-icons/io5";
import * as MdIcons from "react-icons/md";
// import ReactCountryFlag from "react-country-flag";

// COMPONENTS
import AddToWatchlist from "../elements/AddToWatchlist";
import CompaniesTable from "../../tables/companiesSimple/CompaniesTable";
import CompaniesTableModal from "../elements/CompaniesTableModal";
import RangeSliderScreener from "./RangeSliderScreener";
import RangeSliderScreenerUpgrade from "./RangeSliderScreenerUpgrade";
import FeedbackIcon from "../elements/FeedbackIcon";
import Presets from "./Presets";
import Preloader from "../../layout/Preloader";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// ACTIONS
import {
    updateScreenerValues,
    getFilteredCompanies,
    updateSingleOptionsValue,
    updateSingleOptionSortBy,
    updateSearchTextScreener,
    updateSearchOption,
    // updateSearchCountries,
    updateSearchIndustries,
    updateCheckedIndustries,
    clearScreenerValues,
    setDisplayFalseAll,
    setThreshold,
    updateESG,
} from "../../../actions/screenerAction";

// MUI
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import { makeStyles, useTheme } from "@mui/styles";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
import Ad0Vertical100vw from "../ads/Ad0Vertical100vw";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

// Sliders Tooltip
function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={open}
            enterTouchDelay={0}
            placement='top'
            title={value}
        >
            {children}
        </Tooltip>
    );
}
ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

/*Form to save Values to App level State*/
let screenerSliderForm = {};

let screenerOptionsForm = {
    filterPreset: {
        property: "filterPreset",
        value: "",
        default_value: "",
        filter: false,
        display: false,
    },
    tableStructure: {
        property: "tableStructure",
        value: "filtered_metrics",
        default_value: "filtered_metrics",
        filter: false,
        display: false,
    },
    search: {
        property: "search",
        value: "",
        default_value: "",
        filter: false,
        display: false,
    },
    searchOption: {
        property: "searchOption",
        value: "ticker",
        default_value: "ticker",
        filter: true,
        display: false,
    },
    sortBy: {
        property: "sortBy",
        value: "market_cap_usd",
        default_value: "market_cap_usd",
        direction: "DESC",
        checked: false,
        direction_default: "DESC",
        filter: true,
        display: false,
    },
    industry: {
        property: "industry",
        value: "",
        default_value: "",
        filter: false,
        display: false,
        displayName: "Industry",
    },
    country: {
        property: "country",
        value: "",
        default_value: "",
        filter: false,
        display: false,
        displayName: "Country",
    },
    esg: {
        property: "esg",
        value: false,
        default_value: false,
        filter: false,
        display: false,
        displayName: "ESG",
    },
};

/*Multiple Select Dropdown*/
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "8px",
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: "24px",
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Screener = ({
    sidebar: { sidebar },
    user,
    screener: {
        slider,
        options,
        companies,
        pagination,
        checked,
        threshold,
        checkedIndustriesGlobal,
        checkedIndustriesSelectedGlobal,
        // statistics,
    },
    getFilteredCompanies,
    updateSingleOptionSortBy,
    updateSearchTextScreener,
    updateSearchOption,
    // updateSearchCountries,
    updateSearchIndustries,
    updateCheckedIndustries,
    clearScreenerValues,
    setThreshold,
    updateESG,
}) => {
    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    // INDIVIDUAL SCORE FILTER
    const handleThreshold = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value) >= 0 &&
            Number(event.target.value) <= checked
        ) {
            setThreshold(Number(event.target.value));
        }
    };
    const [openThresholdInfo, setOpenThresholdInfo] = React.useState(false);
    const handleClickOpenThresholdInfo = () => {
        setOpenThresholdInfo(true);
    };
    const handleCloseThresholdInfo = () => {
        setOpenThresholdInfo(false);
    };

    const [oldChecked, setOldChecked] = React.useState(checked);
    // if (oldChecked < checked) {
    //     setOldChecked(checked);
    //     let old_threshold = threshold;
    //     setThresholdState(old_threshold + 1);
    //     setThreshold(old_threshold + 1);
    // }
    // if (oldChecked > checked) {
    //     setOldChecked(checked);
    //     let old_threshold = threshold;
    //     if (old_threshold > 0) {
    //         setThresholdState(old_threshold - 1);
    //         setThreshold(old_threshold - 1);
    //     }
    // }

    // CLEAR SCREENER
    const handleClearScreenerValues = () => {
        // CLEAR INDUSTRIES
        let old_industries_global = checkedIndustriesGlobal;
        let old_industries_selected_global = 0;

        Object.keys(checkedIndustriesGlobal).map((gic) => {
            old_industries_global[gic] = true;
        });

        console.log("handleClearScreenerValues: ", old_industries_global);

        updateCheckedIndustries(
            old_industries_global,
            old_industries_selected_global
        );
        setCheckedIndustries(old_industries_global);
        setTotalCheckedIndustries(0);
        setCheckedIndustryNumber(0);

        // CLEAR REST
        clearScreenerValues(slider, options);
        setThreshold(0);
        let new_options = options;
        new_options["sortBy"].value = new_options["sortBy"].default_value;
        new_options["sortBy"].direction =
            new_options["sortBy"].direction_default;
        getFilteredCompanies(
            slider,
            new_options,
            0, //paginaiton
            0, //threshold
            0 //checked
        );
        // SEARCH WHICH CATEGORIES CONTAIN CHECKED METRICS
        let new_sorted_metrics = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        let new_sorted_rankers = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };

        setOpenSortedMetrics(new_sorted_metrics);
        setOpenSortedRankers(new_sorted_rankers);
    };

    // METRICS DISPLAYABLE IN THE TABLE
    const metrics = useContext(MetricsContext);

    //possible Options Values
    let possible_options = metrics.possible_options;
    //possible Slider Range Keys
    let possible_ranges = metrics.possible_ranges;

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                importance: slider[metric].importance,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    const [checkedIndustries, setCheckedIndustries] = React.useState(
        checkedIndustriesGlobal
    );
    const [totalCheckedIndustries, setTotalCheckedIndustries] = React.useState(
        checkedIndustriesSelectedGlobal
    );

    const [checkedIndustryNumber, setCheckedIndustryNumber] = React.useState(
        checkedIndustriesSelectedGlobal
    );

    // GIC CLASSIFICATION INDUSTRIES
    let industries = metrics.industries;

    // State to handle visibility

    const [visibleIndustries, setVisibleIndustries] = React.useState({
        10: false,
        15: false,
        20: false,
        25: false,
        30: false,
        35: false,
        40: false,
        45: false,
        50: false,
        55: false,
        60: false,
        1010: false,
        1510: false,
        2010: false,
        2020: false,
        2030: false,
        2510: false,
        2520: false,
        2530: false,
        2550: false,
        3010: false,
        3020: false,
        3030: false,
        3510: false,
        3520: false,
        4010: false,
        4020: false,
        4030: false,
        4510: false,
        4520: false,
        4530: false,
        5010: false,
        5020: false,
        5510: false,
        6010: false,
        101010: false,
        101020: false,
        151010: false,
        151020: false,
        151030: false,
        151040: false,
        151050: false,
        201010: false,
        201020: false,
        201030: false,
        201040: false,
        201050: false,
        201060: false,
        201070: false,
        202010: false,
        202020: false,
        203010: false,
        203020: false,
        203030: false,
        203040: false,
        203050: false,
        251010: false,
        251020: false,
        252010: false,
        252020: false,
        252030: false,
        253010: false,
        253020: false,
        255010: false,
        255020: false,
        255030: false,
        255040: false,
        301010: false,
        302010: false,
        302020: false,
        302030: false,
        303010: false,
        303020: false,
        351010: false,
        351020: false,
        351030: false,
        352010: false,
        352020: false,
        352030: false,
        401010: false,
        401020: false,
        402010: false,
        402020: false,
        402030: false,
        402040: false,
        403010: false,
        451020: false,
        451030: false,
        452010: false,
        452020: false,
        452030: false,
        453010: false,
        501010: false,
        501020: false,
        502010: false,
        502020: false,
        502030: false,
        551010: false,
        551020: false,
        551030: false,
        551040: false,
        551050: false,
        601010: false,
        601020: false,
        10101010: false,
        10101020: false,
        10102010: false,
        10102020: false,
        10102030: false,
        10102040: false,
        10102050: false,
        15101010: false,
        15101020: false,
        15101030: false,
        15101040: false,
        15101050: false,
        15102010: false,
        15103010: false,
        15103020: false,
        15104010: false,
        15104020: false,
        15104025: false,
        15104030: false,
        15104040: false,
        15104045: false,
        15104050: false,
        15105010: false,
        15105020: false,
        20101010: false,
        20102010: false,
        20103010: false,
        20104010: false,
        20104020: false,
        20105010: false,
        20106010: false,
        20106015: false,
        20106020: false,
        20107010: false,
        20201010: false,
        20201050: false,
        20201060: false,
        20201070: false,
        20201080: false,
        20202010: false,
        20202020: false,
        20301010: false,
        20302010: false,
        20303010: false,
        20304010: false,
        20304020: false,
        20305010: false,
        20305020: false,
        20305030: false,
        25101010: false,
        25101020: false,
        25102010: false,
        25102020: false,
        25201010: false,
        25201020: false,
        25201030: false,
        25201040: false,
        25201050: false,
        25202010: false,
        25203010: false,
        25203020: false,
        25203030: false,
        25301010: false,
        25301020: false,
        25301030: false,
        25301040: false,
        25302010: false,
        25302020: false,
        25501010: false,
        25502020: false,
        25503010: false,
        25503020: false,
        25504010: false,
        25504020: false,
        25504030: false,
        25504040: false,
        25504050: false,
        25504060: false,
        30101010: false,
        30101020: false,
        30101030: false,
        30101040: false,
        30201010: false,
        30201020: false,
        30201030: false,
        30202010: false,
        30202030: false,
        30203010: false,
        30301010: false,
        30302010: false,
        35101010: false,
        35101020: false,
        35102010: false,
        35102015: false,
        35102020: false,
        35102030: false,
        35103010: false,
        35201010: false,
        35202010: false,
        35203010: false,
        40101010: false,
        40101015: false,
        40102010: false,
        40201020: false,
        40201030: false,
        40201040: false,
        40202010: false,
        40203010: false,
        40203020: false,
        40203030: false,
        40203040: false,
        40204010: false,
        40301010: false,
        40301020: false,
        40301030: false,
        40301040: false,
        40301050: false,
        45102010: false,
        45102020: false,
        45102030: false,
        45103010: false,
        45103020: false,
        45201020: false,
        45202030: false,
        45203010: false,
        45203015: false,
        45203020: false,
        45203030: false,
        45301010: false,
        45301020: false,
        50101010: false,
        50101020: false,
        50102010: false,
        50201010: false,
        50201020: false,
        50201030: false,
        50201040: false,
        50202010: false,
        50202020: false,
        50203010: false,
        55101010: false,
        55102010: false,
        55103010: false,
        55104010: false,
        55105010: false,
        55105020: false,
        60101010: false,
        60101020: false,
        60101030: false,
        60101040: false,
        60101050: false,
        60101060: false,
        60101070: false,
        60101080: false,
        60102010: false,
        60102020: false,
        60102030: false,
        60102040: false,
    });

    useEffect(() => {
        screenerSliderForm = slider;
        screenerOptionsForm = options;
        setSearchText(options.search.value);

        let latest_table_arr =
            localStorage["latest_table"] &&
            localStorage["latest_table"] !== null &&
            localStorage["latest_table"] !== ""
                ? JSON.parse(localStorage["latest_table"])
                : [];

        if (window.location.search) {
            //eventually calls updateScreenerValues
            SliderData = sliderDataProvider(AllMetrics);

            let params_arr = window.location.search.slice(1).split("&");

            let amount_slider_metrics = 0;

            params_arr.forEach((element) => {
                let keyvalue = element.split("=");
                if (!latest_table_arr.includes(keyvalue[0])) {
                    latest_table_arr.push(keyvalue[0]);
                }

                //All Options Values
                if (possible_options.includes(keyvalue[0])) {
                    let key = keyvalue[0];
                    let value = keyvalue[1];

                    if (key === "sortBy") {
                        if (value.slice(-3) === "ASC") {
                            let newSortValue = value.split("ASC")[0];
                            screenerOptionsForm[key].value = newSortValue;
                            screenerOptionsForm[key].direction = "ASC";
                        } else {
                            let newSortValue = value.split("DESC")[0];
                            screenerOptionsForm[key].value = newSortValue;
                            screenerOptionsForm[key].direction = "DESC";
                        }
                    } else {
                        if (key === "search") {
                            setSearchText(value);
                        }
                        if (key === "searchOption") {
                            setSearchOption(value);
                        }
                        screenerOptionsForm[key].value = value;
                        screenerOptionsForm[key].filter = true;
                        screenerOptionsForm[key].display = true;
                    }
                }

                //All Range-slider Values
                if (keyvalue[0][keyvalue[0].length - 1] === ":") {
                    let original_key = keyvalue[0].slice(
                        0,
                        keyvalue[0].length - 1
                    );
                    if (possible_ranges.includes(original_key)) {
                        amount_slider_metrics += 1;
                        let key = original_key;
                        let value = keyvalue[1].split("_").map(Number);
                        screenerSliderForm[key].value = value;
                        screenerSliderForm[key].filter = true;
                        screenerSliderForm[key].display = true;
                        screenerSliderForm[key].importance = 2;
                        SliderData[key].value = value;
                        SliderData[key].filter = true;
                        SliderData[key].display = true;
                        SliderData[key].importance = 2;
                    }
                } else {
                    if (possible_ranges.includes(keyvalue[0])) {
                        amount_slider_metrics += 1;
                        let key = keyvalue[0];
                        let value = keyvalue[1].split("_").map(Number);
                        screenerSliderForm[key].value = value;
                        screenerSliderForm[key].filter = true;
                        screenerSliderForm[key].display = true;
                        screenerSliderForm[key].importance = 1;
                        SliderData[key].value = value;
                        SliderData[key].filter = true;
                        SliderData[key].display = true;
                        SliderData[key].importance = 1;
                    }
                }
            });

            // UPDATE TABLE VALUES
            latest_table_arr.map((key) => {
                if (
                    possible_options.includes(key) &&
                    screenerOptionsForm[key] &&
                    SliderData[key]
                ) {
                    screenerOptionsForm[key].display = true;
                    SliderData[key].display = true;
                } else if (
                    possible_ranges.includes(key) &&
                    screenerSliderForm[key] &&
                    SliderData[key]
                ) {
                    screenerSliderForm[key].display = true;
                    SliderData[key].display = true;
                }
            });
            localStorage.setItem(
                "latest_table",
                JSON.stringify(latest_table_arr)
            );
            setOldChecked(amount_slider_metrics);
            updateScreenerValues(screenerSliderForm, screenerOptionsForm);
            getFilteredCompanies(
                screenerSliderForm,
                screenerOptionsForm,
                0,
                amount_slider_metrics,
                amount_slider_metrics
            );
        } else if (
            localStorage["latest_screener"] &&
            localStorage["latest_screener"] !== null &&
            localStorage["latest_screener"] !== "" &&
            localStorage["latest_screener"] !== "?sortBy=market_cap_usdDESC&"
        ) {
            //eventually calls updateScreenerValues
            SliderData = sliderDataProvider(AllMetrics);
            let params_arr = localStorage["latest_screener"]
                .slice(1)
                .split("&");
            let amount_slider_metrics = 0;

            let latest_threshold = Number(localStorage["latest_threshold"]);
            if (latest_threshold && latest_threshold !== null) {
                setThreshold(latest_threshold);
            }

            // REFILL INDUSTRIES
            let industries_arr = localStorage["latest_industries"];
            if (
                industries_arr &&
                industries_arr !== null &&
                industries_arr.length !== 0
            ) {
                let industries_parsed = JSON.parse(industries_arr);
                screenerOptionsForm["industry"].value = industries_parsed;
                screenerOptionsForm["industry"].filter = true;
                screenerOptionsForm["industry"].display = true;

                let old_industries_global = checkedIndustriesGlobal;
                let old_industries_selected_global = 0;

                // SET ALL INDUSTRIES TO FALSE
                Object.keys(old_industries_global).map((industry) => {
                    old_industries_global[industry] = false;
                });
                setCheckedIndustryNumber(0);
                setTotalCheckedIndustries(null);

                // REBUILD INDUSTRIES OBJECT WITH LOCAL STORAGE

                Object.keys(industries).map((gic_sector) => {
                    Object.keys(industries[gic_sector].contains).map(
                        (gic_group) => {
                            Object.keys(
                                industries[gic_sector].contains[gic_group]
                                    .contains
                            ).map((gic_industry) => {
                                Object.keys(
                                    industries[gic_sector].contains[gic_group]
                                        .contains[gic_industry].contains
                                ).map((gic_sub_industry) => {
                                    if (
                                        industries_parsed.includes(
                                            industries[gic_sector].contains[
                                                gic_group
                                            ].contains[gic_industry].contains[
                                                gic_sub_industry
                                            ].name
                                        )
                                    ) {
                                        old_industries_global[
                                            `${gic_sector}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_group}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_industry}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_sub_industry}`
                                        ] = true;
                                        old_industries_selected_global += 1;
                                    }
                                });
                            });
                        }
                    );
                });

                // UPDATE REDUX
                updateCheckedIndustries(
                    old_industries_global,
                    old_industries_selected_global
                );

                setCheckedIndustries(old_industries_global);
                setTotalCheckedIndustries(old_industries_selected_global);
                setCheckedIndustryNumber(old_industries_selected_global);
            } else {
                screenerOptionsForm["industry"].value = [];
                screenerOptionsForm["industry"].filter = false;
                screenerOptionsForm["industry"].display = false;
            }

            // REFILL REST OF SLIDER AND OPTIONS
            params_arr.forEach((element) => {
                let keyvalue = element.split("=");

                //All Options Values
                if (possible_options.includes(keyvalue[0])) {
                    let key = keyvalue[0];
                    let value = keyvalue[1];

                    if (key === "sortBy") {
                        if (value.slice(-3) === "ASC") {
                            let newSortValue = value.split("ASC")[0];
                            screenerOptionsForm[key].value = newSortValue;
                            screenerOptionsForm[key].direction = "ASC";
                        } else {
                            let newSortValue = value.split("DESC")[0];
                            screenerOptionsForm[key].value = newSortValue;
                            screenerOptionsForm[key].direction = "DESC";
                        }
                    } else {
                        if (key === "search") {
                            setSearchText(value);
                        }
                        if (key === "searchOption") {
                            setSearchOption(value);
                        }
                        screenerOptionsForm[key].value = value;
                        screenerOptionsForm[key].filter = true;
                        screenerOptionsForm[key].display = true;
                    }
                }

                //All Range-slider Values
                if (keyvalue[0][keyvalue[0].length - 1] === ":") {
                    let original_key = keyvalue[0].slice(
                        0,
                        keyvalue[0].length - 1
                    );
                    if (possible_ranges.includes(original_key)) {
                        amount_slider_metrics += 1;
                        let key = original_key;
                        let value = keyvalue[1].split("_").map(Number);
                        screenerSliderForm[key].value = value;
                        screenerSliderForm[key].filter = true;
                        screenerSliderForm[key].display = true;
                        screenerSliderForm[key].importance = 2;
                        SliderData[key].value = value;
                        SliderData[key].filter = true;
                        SliderData[key].display = true;
                        SliderData[key].importance = 2;
                    }
                } else {
                    if (possible_ranges.includes(keyvalue[0])) {
                        amount_slider_metrics += 1;
                        let key = keyvalue[0];
                        let value = keyvalue[1].split("_").map(Number);
                        screenerSliderForm[key].value = value;
                        screenerSliderForm[key].filter = true;
                        screenerSliderForm[key].display = true;
                        screenerSliderForm[key].importance = 1;
                        SliderData[key].value = value;
                        SliderData[key].filter = true;
                        SliderData[key].display = true;
                        SliderData[key].importance = 1;
                    }
                }
            });

            // UPDATE TABLE VALUES
            latest_table_arr.map((key) => {
                if (
                    possible_options.includes(key) &&
                    screenerOptionsForm[key] &&
                    SliderData[key]
                ) {
                    screenerOptionsForm[key].display = true;
                    SliderData[key].display = true;
                } else if (
                    possible_ranges.includes(key) &&
                    screenerSliderForm[key] &&
                    SliderData[key]
                ) {
                    screenerSliderForm[key].display = true;
                    SliderData[key].display = true;
                }
            });

            setOldChecked(amount_slider_metrics);
            updateScreenerValues(screenerSliderForm, screenerOptionsForm);
            getFilteredCompanies(
                screenerSliderForm,
                screenerOptionsForm,
                0,
                user.simplified_view ? amount_slider_metrics : latest_threshold,
                amount_slider_metrics
            );
        } else if (
            localStorage["latest_industries"] &&
            localStorage["latest_industries"] !== null &&
            localStorage["latest_industries"] !== ""
        ) {
            //eventually calls updateScreenerValues
            SliderData = sliderDataProvider(AllMetrics);

            // REFILL INDUSTRIES
            let industries_arr = localStorage["latest_industries"];
            if (
                industries_arr &&
                industries_arr !== null &&
                industries_arr.length !== 0
            ) {
                let industries_parsed = JSON.parse(industries_arr);
                screenerOptionsForm["industry"].value = industries_parsed;
                screenerOptionsForm["industry"].filter = true;
                screenerOptionsForm["industry"].display = true;

                let old_industries_global = checkedIndustriesGlobal;
                let old_industries_selected_global = 0;

                // SET ALL INDUSTRIES TO FALSE
                Object.keys(old_industries_global).map((industry) => {
                    old_industries_global[industry] = false;
                });
                setCheckedIndustryNumber(0);
                setTotalCheckedIndustries(null);

                // REBUILD INDUSTRIES OBJECT WITH LOCAL STORAGE
                Object.keys(industries).map((gic_sector) => {
                    Object.keys(industries[gic_sector].contains).map(
                        (gic_group) => {
                            Object.keys(
                                industries[gic_sector].contains[gic_group]
                                    .contains
                            ).map((gic_industry) => {
                                Object.keys(
                                    industries[gic_sector].contains[gic_group]
                                        .contains[gic_industry].contains
                                ).map((gic_sub_industry) => {
                                    if (
                                        industries_parsed.includes(
                                            industries[gic_sector].contains[
                                                gic_group
                                            ].contains[gic_industry].contains[
                                                gic_sub_industry
                                            ].name
                                        )
                                    ) {
                                        old_industries_global[
                                            `${gic_sector}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_group}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_industry}`
                                        ] = true;
                                        old_industries_global[
                                            `${gic_sub_industry}`
                                        ] = true;
                                        old_industries_selected_global += 1;
                                    }
                                });
                            });
                        }
                    );
                });

                // UPDATE REDUX
                updateCheckedIndustries(
                    old_industries_global,
                    old_industries_selected_global
                );

                setCheckedIndustries(old_industries_global);
                setTotalCheckedIndustries(old_industries_selected_global);
                setCheckedIndustryNumber(old_industries_selected_global);
            } else {
                screenerOptionsForm["industry"].value = [];
                screenerOptionsForm["industry"].filter = false;
                screenerOptionsForm["industry"].display = false;
            }

            // UPDATE TABLE VALUES
            latest_table_arr.map((key) => {
                if (
                    possible_options.includes(key) &&
                    screenerOptionsForm[key] &&
                    SliderData[key]
                ) {
                    screenerOptionsForm[key].display = true;
                    SliderData[key].display = true;
                } else if (
                    possible_ranges.includes(key) &&
                    screenerSliderForm[key] &&
                    SliderData[key]
                ) {
                    screenerSliderForm[key].display = true;
                    SliderData[key].display = true;
                }
            });

            updateScreenerValues(screenerSliderForm, screenerOptionsForm);
            getFilteredCompanies(
                screenerSliderForm,
                screenerOptionsForm,
                0,
                user.simplified_view ? checked : threshold,
                checked
            );
        } else {
            SliderData = sliderDataProvider(AllMetrics);

            latest_table_arr.map((key) => {
                if (
                    possible_options.includes(key) &&
                    screenerOptionsForm[key] &&
                    SliderData[key]
                ) {
                    screenerOptionsForm[key].display = true;
                    SliderData[key].display = true;
                } else if (
                    possible_ranges.includes(key) &&
                    screenerSliderForm[key] &&
                    SliderData[key]
                ) {
                    screenerSliderForm[key].display = true;
                    SliderData[key].display = true;
                }
            });

            updateScreenerValues(screenerSliderForm, screenerOptionsForm);
            getFilteredCompanies(
                slider,
                options,
                pagination,
                user.simplified_view ? checked : threshold,
                checked
            );
        }

        // SEARCH WHICH CATEGORIES CONTAIN CHECKED METRICS
        let new_sorted_metrics = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        let new_sorted_rankers = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        Object.keys(SliderData).map((slide) => {
            Object.keys(sorted_metrics_object).map((category) => {
                if (sorted_metrics_object[category].includes(slide)) {
                    new_sorted_metrics[category].open = false;
                    new_sorted_metrics[category].match =
                        new_sorted_metrics[category].match + 1;
                    new_sorted_metrics[category].checked = slider[slide].filter
                        ? new_sorted_metrics[category].checked + 1
                        : new_sorted_metrics[category].checked;
                }
            });
            Object.keys(sorted_rankers_object).map((category) => {
                if (sorted_rankers_object[category].includes(slide)) {
                    new_sorted_rankers[category].open = false;
                    new_sorted_rankers[category].match =
                        new_sorted_rankers[category].match + 1;
                    new_sorted_rankers[category].checked = slider[slide].filter
                        ? new_sorted_rankers[category].checked + 1
                        : new_sorted_rankers[category].checked;
                }
            });
        });
        setOpenSortedMetrics(new_sorted_metrics);
        setOpenSortedRankers(new_sorted_rankers);
        // eslint-disable-next-line
    }, [metrics]);

    const useintl = useIntl();

    let sorted_metrics_object = user.simplified_view
        ? metrics.simplified_sorted_metrics_object
        : metrics.sorted_metrics_object;

    let sorted_rankers_object = user.simplified_view
        ? metrics.simplified_sorted_rankers_object
        : metrics.sorted_rankers_object;

    const [openSortedMetrics, setOpenSortedMetrics] = React.useState({
        general: { open: false, match: 0, checked: 0 },
        performance: { open: false, match: 0, checked: 0 },
        ratings: { open: false, match: 0, checked: 0 },
        price_ratios: { open: false, match: 0, checked: 0 },
        fundamental_ratios: { open: false, match: 0, checked: 0 },
        dividends: { open: false, match: 0, checked: 0 },
        // shares: {open: false, match: 0, checked: 0 },
        growth: { open: false, match: 0, checked: 0 },
        debt_assets: { open: false, match: 0, checked: 0 },
        margins: { open: false, match: 0, checked: 0 },
    });
    const handleOpenSortedMetrics = (category) => {
        setOpenSortedMetrics({
            ...openSortedMetrics,
            [category]: {
                open: !openSortedMetrics[category].open,
                match: openSortedMetrics[category].match,
                checked: openSortedMetrics[category].checked,
            },
        });
    };
    const [openSortedRankers, setOpenSortedRankers] = React.useState({
        general: { open: false, match: 0, checked: 0 },
        performance: { open: false, match: 0, checked: 0 },
        ratings: { open: false, match: 0, checked: 0 },
        price_ratios: { open: false, match: 0, checked: 0 },
        fundamental_ratios: { open: false, match: 0, checked: 0 },
        dividends: { open: false, match: 0, checked: 0 },
        // shares: {open: false, match: 0, checked: 0 },
        growth: { open: false, match: 0, checked: 0 },
        debt_assets: { open: false, match: 0, checked: 0 },
        margins: { open: false, match: 0, checked: 0 },
    });
    const handleOpenSortedRankers = (category) => {
        setOpenSortedRankers({
            ...openSortedRankers,
            [category]: {
                open: !openSortedRankers[category].open,
                match: openSortedRankers[category].match,
                checked: openSortedRankers[category].checked,
            },
        });
    };
    const detectCheck = (category, type, bool) => {
        if (type === "ranker") {
            setOpenSortedRankers({
                ...openSortedRankers,
                [category]: {
                    open: openSortedRankers[category].open,
                    match: openSortedRankers[category].match,
                    checked: bool
                        ? openSortedRankers[category].checked + 1
                        : openSortedRankers[category].checked - 1,
                },
            });
        } else {
            setOpenSortedMetrics({
                ...openSortedMetrics,
                [category]: {
                    open: openSortedMetrics[category].open,
                    match: openSortedMetrics[category].match,
                    checked: bool
                        ? openSortedMetrics[category].checked + 1
                        : openSortedMetrics[category].checked - 1,
                },
            });
        }
    };

    let [metricType, setMetricType] = React.useState(0);

    // Slider Array that's getting mapped
    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // console.log("5: ", searchSliderObject["dividend_consistency"].value);

    // Slider Search Functionality
    const [searchSliderText, setSearchSliderText] = React.useState("");
    const handlesearchSliderText = (event) => {
        setSearchSliderText(event.target.value);
        let new_sorted_metrics = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };
        let new_sorted_rankers = {
            general: { open: false, match: 0, checked: 0 },
            performance: { open: false, match: 0, checked: 0 },
            ratings: { open: false, match: 0, checked: 0 },
            price_ratios: { open: false, match: 0, checked: 0 },
            fundamental_ratios: { open: false, match: 0, checked: 0 },
            dividends: { open: false, match: 0, checked: 0 },
            // shares: {open: false, match: 0, checked: 0 },
            growth: { open: false, match: 0, checked: 0 },
            debt_assets: { open: false, match: 0, checked: 0 },
            margins: { open: false, match: 0, checked: 0 },
        };

        let helper = {};
        Object.keys(SliderData).map((slide) => {
            if (
                event.target.value === "" ||
                SliderData[slide].displayName.includes(event.target.value) ||
                SliderData[slide].property.includes(event.target.value) ||
                SliderData[slide].filter_keywords.includes(event.target.value)
            ) {
                helper[slide] = SliderData[slide];

                Object.keys(sorted_metrics_object).map((category) => {
                    if (sorted_metrics_object[category].includes(slide)) {
                        new_sorted_metrics[category].open =
                            event.target.value === "" ? false : true;
                        new_sorted_metrics[category].match =
                            new_sorted_metrics[category].match + 1;
                        new_sorted_metrics[category].checked = slider[slide]
                            .filter
                            ? new_sorted_metrics[category].checked + 1
                            : new_sorted_metrics[category].checked;
                    }
                });
                Object.keys(sorted_rankers_object).map((category) => {
                    if (sorted_rankers_object[category].includes(slide)) {
                        new_sorted_rankers[category].open =
                            event.target.value === "" ? false : true;
                        new_sorted_rankers[category].match =
                            new_sorted_rankers[category].match + 1;
                        new_sorted_rankers[category].checked = slider[slide]
                            .filter
                            ? new_sorted_rankers[category].checked + 1
                            : new_sorted_rankers[category].checked;
                    }
                });
            }
        });
        setSearchSliderObject(helper);

        setOpenSortedMetrics(new_sorted_metrics);
        setOpenSortedRankers(new_sorted_rankers);
    };

    // SAVE CURRENT SCREENER AS PRESET
    const [openSaveScreener, setOpenSaveScreener] = React.useState(false);
    const handleOpenSaveScreener = () => {
        setOpenSaveScreener(true);
    };
    const handleCloseSaveScreener = () => {
        setOpenSaveScreener(false);
    };

    // FILTER PRESETS
    const [filterPreset, setFilterPreset] = React.useState(
        screenerOptionsForm.filterPreset.value
    );
    const handlefilterPreset = (event) => {
        setFilterPreset(event.target.value);
        setTableStructure(event.target.value);
        screenerOptionsForm.filterPreset.value = event.target.value;
        screenerOptionsForm.tableStructure.value = event.target.value;
        updateSingleOptionsValue(screenerOptionsForm.filterPreset);
        updateSingleOptionsValue(screenerOptionsForm.tableStructure);
        getFilteredCompanies(
            slider,
            options,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };

    // SEARCH TEXT AND OPTIONS
    const [searchText, setSearchText] = React.useState(options.search.value);
    const handlesearchText = (event) => {
        let str = event.target.value.replace(/[^a-zA-Z0-9. ]/g, "");
        setSearchText(str);
        screenerOptionsForm.search.value = str;
        if (str !== "") {
            screenerOptionsForm.search.filter = true;
            screenerOptionsForm.searchOption.filter = true;
        } else {
            screenerOptionsForm.search.filter = false;
            screenerOptionsForm.searchOption.filter = true;
        }
        // updateSearchTextScreener(str)
    };
    const clearSearchText = () => {
        setSearchText("");
        screenerOptionsForm.search.value = "";
        updateSearchTextScreener("");
        let options_helper = options;
        options_helper.search.value = "";
        getFilteredCompanies(
            slider,
            options_helper,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };
    const submitsearchText = () => {
        updateSearchTextScreener(searchText);
        let options_helper = options;
        if (searchText !== "") {
            options_helper.search.filter = true;
            options_helper.searchOption.filter = true;
        } else {
            options_helper.search.filter = false;
            options_helper.searchOption.filter = false;
        }
        options_helper.search.value = searchText;
        options_helper.searchOption.value = searchOption;
        getFilteredCompanies(
            slider,
            options_helper,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };
    const [searchOption, setSearchOption] = React.useState(
        screenerOptionsForm.searchOption.value
    );
    const handlesearchOption = (event) => {
        setSearchOption(event.target.value);
        screenerOptionsForm.searchOption.value = event.target.value;
        updateSearchOption(event.target.value);
        let options_helper = options;
        if (searchText !== "") {
            options_helper.search.filter = true;
            options_helper.searchOption.filter = true;
        } else {
            options_helper.search.filter = false;
            options_helper.searchOption.filter = false;
        }
        getFilteredCompanies(
            slider,
            options_helper,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };
    const [sortBy, setSortBy] = React.useState(
        screenerOptionsForm.sortBy.value
    );
    const handlesortBy = (event) => {
        setSortBy(event.target.value);
        screenerOptionsForm.sortBy.value = event.target.value;
        updateSingleOptionSortBy(screenerOptionsForm.sortBy);
        getFilteredCompanies(
            slider,
            options,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };
    const [sortDirection, setSortDirection] = React.useState(
        screenerOptionsForm.sortBy.checked
    );
    const handlesortDirection = (event) => {
        if (sortDirection) {
            setSortDirection(false);
            screenerOptionsForm.sortBy.checked = false;
            screenerOptionsForm.sortBy.direction = "DESC";
            updateSingleOptionSortBy(screenerOptionsForm.sortBy);
            getFilteredCompanies(
                slider,
                options,
                0,
                user.simplified_view ? checked : threshold,
                checked
            );
        } else {
            setSortDirection(true);
            screenerOptionsForm.sortBy.checked = true;
            screenerOptionsForm.sortBy.direction = "ASC";
            updateSingleOptionSortBy(screenerOptionsForm.sortBy);
            getFilteredCompanies(
                slider,
                options,
                0,
                user.simplified_view ? checked : threshold,
                checked
            );
        }
    };

    // TABLE STRUCTURING

    const [tableStructure, setTableStructure] = React.useState(
        options.tableStructure.value
    );
    // const handletableStructure = (event) => {
    //     setTableStructure(event.target.value);
    //     screenerOptionsForm.tableStructure.value = event.target.value;
    //     updateScreenerValues(screenerSliderForm, screenerOptionsForm);
    // };
    const handletableStructure = (new_structure) => {
        setTableStructure(new_structure);
        screenerOptionsForm.tableStructure.value = new_structure;
        updateScreenerValues(screenerSliderForm, screenerOptionsForm);
    };

    /*Tablecolumns Dialog Field*/
    const [openColumns, setOpenColumns] = React.useState(false);
    const handleClickOpenColumns = () => {
        setOpenColumns(true);
    };
    const handleCloseColumns = () => {
        setOpenColumns(false);
    };

    /*Sliders Dialog Field*/
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const hideIndustry = (code) => {
        setVisibleIndustries({ ...visibleIndustries, [code]: false });
    };
    const showIndustry = (code) => {
        setVisibleIndustries({ ...visibleIndustries, [code]: true });
    };

    // States to handle if continents/ countries are checked

    const handleUpdateCheckedIndustries = (
        new_industries_object,
        new_selected_number
    ) => {
        updateCheckedIndustries(new_industries_object, new_selected_number);
    };

    const checkIndustry = (code) => {
        const newcheckedIndustries = { ...checkedIndustries };
        let industry_bool = !newcheckedIndustries[code];
        let code_string = code.toString();
        let code_string_length = code_string.length;

        Object.keys(newcheckedIndustries).map((industry) => {
            if (
                code.toString().substring(0, code_string_length) ===
                industry.toString().substring(0, code_string_length)
            ) {
                newcheckedIndustries[industry] = industry_bool;
            }
            // MARK SECTOR AS PARTLY SELECTED
            if (industry_bool === true && code_string_length === 8) {
                let gic_sector_string = code.toString().substring(0, 2);
                let gic_group_string = code.toString().substring(0, 4);
                let gic_industry_string = code.toString().substring(0, 6);
                newcheckedIndustries[gic_sector_string] = industry_bool;
                newcheckedIndustries[gic_group_string] = industry_bool;
                newcheckedIndustries[gic_industry_string] = industry_bool;
            }
            if (industry_bool === true && code_string_length === 6) {
                let gic_sector_string = code.toString().substring(0, 2);
                let gic_group_string = code.toString().substring(0, 4);
                newcheckedIndustries[gic_sector_string] = industry_bool;
                newcheckedIndustries[gic_group_string] = industry_bool;
            }
            if (industry_bool === true && code_string_length === 4) {
                let gic_sector_string = code.toString().substring(0, 2);
                newcheckedIndustries[gic_sector_string] = industry_bool;
            }
        });

        let i = 0;
        Object.keys(newcheckedIndustries).map((industry) =>
            industry.toString().length === 8 &&
            newcheckedIndustries[industry] === true
                ? (i += 1)
                : null
        );

        if (i < 158) {
            setCheckedIndustryNumber(i);
            setTotalCheckedIndustries(i);
            handleUpdateCheckedIndustries(newcheckedIndustries, i);
        } else {
            setCheckedIndustryNumber(null);
            setTotalCheckedIndustries(null);
            handleUpdateCheckedIndustries(newcheckedIndustries, null);
        }

        setCheckedIndustries(newcheckedIndustries);
        // updateSearchCountries(newcheckedIndustries);
    };

    const de_select_industries = () => {
        const newcheckedIndustries = { ...checkedIndustries };
        let i = 0;
        Object.keys(newcheckedIndustries).map((industry) => {
            if (newcheckedIndustries[industry]) {
                i += 1;
            }
        });

        if (i === 262) {
            Object.keys(newcheckedIndustries).map((industry) => {
                newcheckedIndustries[industry] = false;
            });
            setCheckedIndustryNumber(0);
            setTotalCheckedIndustries(null);
        } else if (i === 0) {
            Object.keys(newcheckedIndustries).map((industry) => {
                newcheckedIndustries[industry] = true;
            });
            setCheckedIndustryNumber(null);
            setTotalCheckedIndustries(null);
        } else if (i > 131) {
            Object.keys(newcheckedIndustries).map((industry) => {
                newcheckedIndustries[industry] = true;
            });
            setCheckedIndustryNumber(null);
            setTotalCheckedIndustries(null);
        } else {
            Object.keys(newcheckedIndustries).map((industry) => {
                newcheckedIndustries[industry] = false;
            });
            setCheckedIndustryNumber(0);
            setTotalCheckedIndustries(null);
        }

        setCheckedIndustries(newcheckedIndustries);
        handleUpdateCheckedIndustries(newcheckedIndustries, 0);
    };

    const deselect_all_industries = () => {
        const newcheckedIndustries = { ...checkedIndustries };

        Object.keys(newcheckedIndustries).map((industry) => {
            newcheckedIndustries[industry] = false;
        });
        setCheckedIndustryNumber(0);
        setTotalCheckedIndustries(null);
    };

    /*Industries Dialog Field*/
    const [openIndustries, setOpenIndustries] = React.useState(false);
    const handleOpenIndustries = () => {
        setOpenIndustries(true);
    };
    const handleCloseIndustries = () => {
        setOpenIndustries(false);
    };

    const handleGetFilteredCompanies = () => {
        let industriesFilter = [];

        // totalCheckedIndustries
        if (totalCheckedIndustries !== null && totalCheckedIndustries > 0) {
            Object.keys(industries).map((gic_sector) => {
                Object.keys(industries[gic_sector].contains).map(
                    (gic_group) => {
                        Object.keys(
                            industries[gic_sector].contains[gic_group].contains
                        ).map((gic_industry) => {
                            Object.keys(
                                industries[gic_sector].contains[gic_group]
                                    .contains[gic_industry].contains
                            ).map((gic_sub_industry) => {
                                if (checkedIndustries[gic_sub_industry]) {
                                    industriesFilter.push(
                                        industries[gic_sector].contains[
                                            gic_group
                                        ].contains[gic_industry].contains[
                                            gic_sub_industry
                                        ].name
                                    );
                                }
                            });
                        });
                    }
                );
            });
        }

        screenerOptionsForm.industry.value = industriesFilter;
        let options_helper = options;

        if (totalCheckedIndustries !== null) {
            options_helper.industry.filter = true;
            options_helper.industry.value = industriesFilter;
            updateSearchIndustries(industriesFilter, true);
        } else {
            options_helper.industry.filter = false;
            options_helper.industry.value = [];
            updateSearchIndustries([], false);
        }

        getFilteredCompanies(
            slider,
            options_helper,
            0,
            user.simplified_view ? checked : threshold,
            checked
        );
    };

    /*Multiple Select Dropdown*/
    const classes = useStyles();
    const theme = useTheme();

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: "Screener",
        location_index: 3,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.screener",
        })}`,
        location_index: 3,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
        ],
        header_text_1: useintl.formatMessage({
            id: "screener.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/FvczyJvrYPY'
                title='The top FEATURES our STOCK MARKET SCREENER offers'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "screener.description_text_1",
        }),
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Screener | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Filter through thousands of companies by more than 100 metrics, build your own scoring systems and find the stocks tailored to your investment strategy.'
                />
            </Helmet>
            <TopBarCallToAction />
            <div className='page_menu_flex'>
                <FeedbackIcon
                    feedbackInfo={feedbackInfo}
                    informationInfo={informationInfo}
                />
                <div className='flex width_100pr mt_20px mb_20px'>
                    <div className='title_4 ml_25px_10px mt_5px'>
                        <MdIcons.MdFilterList />
                    </div>
                    <div className='title_4 ml_15px'>
                        {useintl.formatMessage({
                            id: "sidemenu.screener",
                        })}
                    </div>
                    <AiIcons.AiOutlineInfoCircle
                        className='text_1 hover_translate_up ml_15px mt_5px'
                        onClick={() => {
                            handleOpenPageInfo();
                        }}
                    />
                </div>

                <PageInfoModalSimple
                    infoHeader={"What can i do here?"}
                    infoBody={
                        "The screener allows you to search our database for companies that match your investment strategy from a fundamental point of view. Under 'Filter' you can set the desired filter criteria and under 'Table' the metrics that are displayed in the table. A detailed description on how to use this page can be found in the bottom right corner of this page."
                    }
                    showPageInfo={showPageInfo}
                    handleClosePageInfo={handleClosePageInfo}
                />

                <Ad0Vertical100vw />

                <div className='ml_25px_10px flex wrap'>
                    {/* Searchbar Text Input */}
                    <div className='background_color_3 input_basic border_box pl_10px pr_15px mr_10px width__100pr flex space_between pt_3px height_38px border_radius_5px'>
                        <div className='flex left'>
                            <div className='flex center width_30px'>
                                {searchText !== "" ? (
                                    <AiIcons.AiOutlineClose
                                        className='title_7 text_color_1 hover_opacity_80 cursor_pointer width_100pr'
                                        onClick={clearSearchText}
                                    />
                                ) : (
                                    <IoIcons5.IoSearchOutline className='title_7 text_color_1 width_100pr' />
                                )}
                            </div>
                            <div>
                                <input
                                    type='text'
                                    className='title_7 mt_2px input_text'
                                    value={searchText}
                                    placeholder={
                                        searchText !== ""
                                            ? searchText
                                            : useintl.formatMessage({
                                                  id: "general.search",
                                              }) + "..."
                                    }
                                    onChange={(event) =>
                                        handlesearchText(event)
                                    }
                                    onKeyUp={(event) => {
                                        if (event.key === "Enter") {
                                            submitsearchText();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {searchText !== "" ? (
                            <div className='flex border_radius_15px hover_background_color_blue text_color_3 width_30px height_30px'>
                                <IoIcons5.IoEnterOutline
                                    className='width_40px text_2 cursor_pointer'
                                    onClick={submitsearchText}
                                />
                            </div>
                        ) : null}
                    </div>

                    {/* Search by Dropdown Option */}
                    <div className='min_width_150px width__100pr pt_10px pb_10px'>
                        <FormControl
                            variant='outlined'
                            size='small'
                            sx={{ minWidth: 150 }}
                        >
                            <InputLabel
                                // htmlFor='searchOption'
                                id='demo-simple-select-label'
                                className='width_150px text_color_1'
                            >
                                {useintl.formatMessage({
                                    id: "general.search_by",
                                })}
                            </InputLabel>
                            <Select
                                value={searchOption}
                                onChange={handlesearchOption}
                                className='width_150px text_color_1 background_color_3'
                                labelId='demo-simple-select-label'
                                label={useintl.formatMessage({
                                    id: "general.search_by",
                                })}
                                id='demo-simple-select'
                            >
                                <MenuItem
                                    value={"name"}
                                    className='text_color_1'
                                >
                                    {useintl.formatMessage({
                                        id: "general.name",
                                    })}
                                </MenuItem>
                                <MenuItem
                                    value={"ticker"}
                                    className='text_color_1'
                                >
                                    {useintl.formatMessage({
                                        id: "general.ticker",
                                    })}
                                </MenuItem>
                                <MenuItem
                                    value={"keywords"}
                                    className='text_color_1'
                                >
                                    {useintl.formatMessage({
                                        id: "general.keyword",
                                    })}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>

            {/* Buttons to open Filter/ Table Organizer */}
            <div className='width_100pr flex space_between'>
                <div className='flex'>
                    <Badge
                        badgeContent={
                            checkedIndustryNumber !== null &&
                            checkedIndustryNumber !== 0 &&
                            (options.esg.value === true ||
                                options.esg.value === "true")
                                ? checked + 2
                                : checkedIndustryNumber !== null &&
                                  checkedIndustryNumber !== 0 &&
                                  (options.esg.value === false ||
                                      options.esg.value === "false")
                                ? checked + 1
                                : (checkedIndustryNumber === null ||
                                      checkedIndustryNumber === 0) &&
                                  (options.esg.value === true ||
                                      options.esg.value === "true")
                                ? checked + 1
                                : checked
                        }
                        color='secondary'
                    >
                        <div
                            className='button_blue hover_opacity_80 text_2 padding_10px ml_25px_10px click_translate'
                            onClick={() => {
                                handleClickOpen();
                                setSearchSliderObject(SliderData);
                            }}
                        >
                            <div className='text_2 pr_10px'>
                                {useintl.formatMessage({
                                    id: "general.filter",
                                })}
                            </div>
                            <FiIcons.FiSliders />
                        </div>
                    </Badge>
                    <Badge badgeContent={0} color='secondary'>
                        <div
                            className='button_blue hover_opacity_80 text_2 padding_10px ml_20px click_translate'
                            onClick={() => {
                                handleClickOpenColumns();
                                setSearchSliderObject(SliderData);
                            }}
                        >
                            <div className='text_2 pr_10px'>
                                {useintl.formatMessage({
                                    id: "general.table",
                                })}
                            </div>
                            <AiIcons.AiOutlineTable />
                        </div>
                    </Badge>
                </div>

                {/* AMOUNT OF RESULTS */}
                <div className='text_color_1 mr_25px_10px'>
                    {localStorage["latest_screener"] &&
                    localStorage["latest_screener"] !== null &&
                    localStorage["latest_screener"] !==
                        "?sortBy=market_cap_usdDESC&" ? (
                        <div>
                            {companies && Array.isArray(companies) ? (
                                companies.length === 1 ? (
                                    <div className='padding_5px button_white_small cursor_default'>
                                        1 result
                                    </div>
                                ) : companies.length === 0 ? (
                                    <div className='padding_5px button_white_small cursor_default'>
                                        0 results
                                    </div>
                                ) : companies.length % 50 === 0 ? (
                                    <div className='padding_5px button_white_small cursor_default'>
                                        {companies.length}+ results
                                    </div>
                                ) : (
                                    <div className='padding_5px button_white_small cursor_default'>
                                        {companies.length} results
                                    </div>
                                )
                            ) : null}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>

            {/* Filter Details */}
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={true}
                className='background_color_2'
            >
                <div className='height_100vh_real width_100pr background_color_2'>
                    <div className='height_auto width_100pr background_color_2'>
                        {/* HEADING */}
                        <div className='flex space_between pos_sticky pos_top background_color_2 z_index_100'>
                            <div className='flex wrap space_between width_100pr mb_15px'>
                                {/* Header */}
                                <div className='flex wrap'>
                                    <div className='ml_20px mt_20px text_1 text_color_1 flex'>
                                        <div className=''>
                                            {useintl.formatMessage({
                                                id: "general.filter",
                                            })}
                                        </div>
                                        <FiIcons.FiSliders className='ml_5px' />
                                    </div>
                                </div>

                                {/* Save, Reset, Submit */}
                                <div className='flex wrap mt_10px'>
                                    <div
                                        className='button_white_layer_2 width_auto__100pr hover_opacity_80 text_2 padding_10px mt_5px ml_8px mr_8px'
                                        onClick={() => {
                                            handleClearScreenerValues();
                                            handleClose();
                                        }}
                                    >
                                        <div className='text_2 pr_10px'>
                                            {useintl.formatMessage({
                                                id: "general.reset",
                                            })}
                                        </div>
                                        <IoIcons.IoIosRefresh />
                                    </div>
                                    <div
                                        className='button_blue width_auto__100pr text_2 padding_10px mt_5px ml_8px mr_8px'
                                        onClick={() => {
                                            handleGetFilteredCompanies();
                                            handleClose();
                                        }}
                                    >
                                        <div className='text_2 pr_10px'>
                                            {useintl.formatMessage({
                                                id: "general.apply",
                                            })}
                                        </div>
                                        <FiIcons.FiSliders />
                                    </div>
                                    <div
                                        className='button_blue width_auto__100pr text_2 padding_10px mt_5px ml_8px mr_8px'
                                        onClick={handleOpenSaveScreener}
                                    >
                                        <div className='text_2 pr_10px'>
                                            Presets
                                        </div>
                                        <MdIcons.MdOutlineStorage className='text_2' />
                                    </div>
                                </div>
                            </div>
                            {/* Close */}
                            <div
                                className='cursor_pointer title_4 text_color_1 height_screener_options_close ml_15px mr_15px '
                                onClick={handleClose}
                            >
                                <AiIcons.AiOutlineClose />
                            </div>
                        </div>
                        <Dialog
                            open={openSaveScreener}
                            onClose={handleCloseSaveScreener}
                            maxWidth='xl'
                            // sx={{ width: 1500 }}
                            className=''
                        >
                            <Presets
                                closeModal={handleCloseSaveScreener}
                                className='width_100pr'
                            />
                        </Dialog>
                        {/* OPTIONS SECTION */}
                        <div className='flex width_100pr wrap mt_10px mb_10px'>
                            {/* <div className='mb_10px ml_13px'>
                            <FormControl className={classes.formControl}
                            size='small'>
                                <InputLabel
                                    htmlFor='age-native-simple'
                                    className='width_200px'
                                >
                                    Filter Preset
                                </InputLabel>
                                <Select
                                    native
                                    value={filterPreset}
                                    onChange={handlefilterPreset}
                                    inputProps={{
                                        name: "age",
                                        id: "age-native-simple",
                                    }}
                                    className='width_200px'
                                >
                                    <option
                                        aria-label='None'
                                        value='filtered_metrics'
                                    />
                                    <option value={"dividends"}>
                                        Dividends
                                    </option>
                                    <option value={"value"}>Value</option>
                                    <option value={"growth"}>Growth</option>
                                </Select>
                            </FormControl>
                        </div> */}

                            {/* COUNTRIES */}
                            {/* <Badge
                            badgeContent={checkedCountryNumber}
                            color='secondary'
                            max={999}
                        >
                            <div
                                className='cursor_pointer button_white_2_layer_2 hover_opacity_80 text_2 ml_20px width_120px'
                                onClick={() => {
                                    handleOpenCountries();
                                }}
                            >
                                Regions
                                <IoIcons5.IoFlagOutline />
                            </div>
                        </Badge>
                        <Dialog
                            open={openCountries}
                            onClose={handleCloseCountries}
                            maxWidth={false}
                        >
                            <div className='max_width_1000px width_50vw min_width_320px background_color_3 text_color_1'>
                                <div className='flex space_between min_width_350px height_60px title_5 ml_20px mr_20px'>
                                    <div>Select Regions</div>
                                    <div
                                        className='cursor_pointer title_4 flex center'
                                        onClick={handleCloseCountries}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider_no_margin'></div>
                                <div className='width_100pr max_width_1000px'>
                                    {Object.keys(continents).map(
                                        (continent, idx) =>
                                            visibleContinent[idx] ? (
                                                <div>
                                                    <div className='width_100pr max_width_1000px ms_auto flex space_between'>
                                                        <div className='flex left'>
                                                            <Checkbox
                                                                checked={
                                                                    checkedContinents[
                                                                        idx
                                                                    ]
                                                                }
                                                                color='primary'
                                                                size='medium'
                                                                onClick={() => {
                                                                    checkContinent(
                                                                        continents[
                                                                            continent
                                                                        ].code
                                                                    );
                                                                }}
                                                            />
                                                            <div>
                                                                {
                                                                    continents[
                                                                        continent
                                                                    ].name
                                                                }
                                                            </div>
                                                        </div>

                                                        <RiIcons.RiArrowUpSFill
                                                            className='mr_20px cursor_pointer'
                                                            onClick={() => {
                                                                hideContinent(
                                                                    continents[
                                                                        continent
                                                                    ].code
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='divider_no_margin'></div>
                                                    {Object.keys(countries).map(
                                                        (country, idx) =>
                                                            countries[country]
                                                                .continent_code ===
                                                            continents[
                                                                continent
                                                            ].code ? (
                                                                <div className='width_30pr min_width_200px ms_auto flex wrap left'>
                                                                    <Checkbox
                                                                        checked={
                                                                            checkedCountries[
                                                                                country
                                                                            ]
                                                                        }
                                                                        color='primary'
                                                                        size='small'
                                                                        className='ml_20px'
                                                                        onClick={() => {
                                                                            checkCountry(
                                                                                country
                                                                            );
                                                                        }}
                                                                    />
                                                                    <ReactCountryFlag
                                                                        className='ml_25px'
                                                                        countryCode={
                                                                            country
                                                                        }
                                                                        svg
                                                                    />
                                                                    <div className='ml_25px'>
                                                                        {truncate(
                                                                            countries[
                                                                                country
                                                                            ]
                                                                                .name,
                                                                            13
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className='width_100pr max_width_1000px ms_auto flex space_between'>
                                                        <div className='flex left'>
                                                            <Checkbox
                                                                checked={
                                                                    checkedContinents[
                                                                        idx
                                                                    ]
                                                                }
                                                                color='primary'
                                                                size='medium'
                                                                onClick={() => {
                                                                    checkContinent(
                                                                        continents[
                                                                            continent
                                                                        ].code
                                                                    );
                                                                }}
                                                            />
                                                            <div>
                                                                {
                                                                    continents[
                                                                        continent
                                                                    ].name
                                                                }
                                                            </div>
                                                        </div>

                                                        <RiIcons.RiArrowDownSFill
                                                            className='mr_20px cursor_pointer'
                                                            onClick={() => {
                                                                showContinent(
                                                                    continents[
                                                                        continent
                                                                    ].code
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    )}
                                </div>
                            </div>
                        </Dialog> */}

                            {/* SECTORS AND INDUSTIES */}
                            <Badge
                                badgeContent={checkedIndustryNumber}
                                color='secondary'
                                max={999}
                            >
                                <div
                                    className='cursor_pointer button_white_layer_2 button_border_color_1 text_2 ml_20px padding_10px'
                                    onClick={() => {
                                        handleOpenIndustries();
                                    }}
                                >
                                    <div className='mr_5px'>
                                        {useintl.formatMessage({
                                            id: "sidemenu.industries",
                                        })}
                                    </div>
                                    <MdIcons.MdDomain />
                                </div>
                            </Badge>
                            <Dialog
                                open={openIndustries}
                                onClose={handleCloseIndustries}
                                maxWidth={false}
                            >
                                <div className='max_width_1000px width_100pr min_width_320px background_color_2'>
                                    <div className='flex space_between min_width_280px title_5 ml_20px mr_20px mt_10px mb_10px'>
                                        <div className='flex space_between wrap width_95pr'>
                                            <div>
                                                {useintl.formatMessage({
                                                    id: "sidemenu.industries",
                                                })}
                                            </div>
                                            <div
                                                className='cursor_pointer button_white_2_layer_2 hover_opacity_80 text_2 padding_10px mr_15px'
                                                onClick={de_select_industries}
                                            >
                                                <div className='text_2 pr_10px'>
                                                    {useintl.formatMessage({
                                                        id: "general.de_select_all",
                                                    })}
                                                </div>
                                                <IoIcons.IoIosRefresh />
                                            </div>
                                        </div>
                                        <div
                                            className='cursor_pointer title_4 flex center'
                                            onClick={handleCloseIndustries}
                                        >
                                            <AiIcons.AiOutlineClose />
                                        </div>
                                    </div>
                                    <div className='divider_no_margin'></div>
                                    <div className='width_100pr max_width_1000px'>
                                        {/* gic_sector, gic_group, gic_industry, gic_sub_industry */}
                                        {Object.keys(industries).map(
                                            (gic_sector) =>
                                                visibleIndustries[
                                                    `${gic_sector}`
                                                ] ? (
                                                    <div>
                                                        <div className='width_100pr max_width_1000px ms_auto flex space_between'>
                                                            <div className='flex left'>
                                                                <Checkbox
                                                                    checked={
                                                                        checkedIndustries[
                                                                            `${gic_sector}`
                                                                        ]
                                                                    }
                                                                    color='primary'
                                                                    size='medium'
                                                                    onClick={() => {
                                                                        checkIndustry(
                                                                            `${gic_sector}`
                                                                        );
                                                                    }}
                                                                />
                                                                <div>
                                                                    {
                                                                        industries[
                                                                            gic_sector
                                                                        ].name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <RiIcons.RiArrowUpSFill
                                                                className='mr_20px cursor_pointer'
                                                                onClick={() => {
                                                                    hideIndustry(
                                                                        `${gic_sector}`
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='divider_no_margin'></div>
                                                        {Object.keys(
                                                            industries[
                                                                gic_sector
                                                            ].contains
                                                        ).map((gic_group) =>
                                                            visibleIndustries[
                                                                `${gic_group}`
                                                            ] ? (
                                                                <div>
                                                                    <div className='width_100pr background_color_3 pl_15px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                        <div className='flex left'>
                                                                            <Checkbox
                                                                                checked={
                                                                                    checkedIndustries[
                                                                                        `${gic_group}`
                                                                                    ]
                                                                                }
                                                                                color='primary'
                                                                                size='medium'
                                                                                onClick={() => {
                                                                                    checkIndustry(
                                                                                        `${gic_group}`
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <div>
                                                                                {
                                                                                    industries[
                                                                                        gic_sector
                                                                                    ]
                                                                                        .contains[
                                                                                        gic_group
                                                                                    ]
                                                                                        .name
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <RiIcons.RiArrowUpSFill
                                                                            className='mr_20px cursor_pointer'
                                                                            onClick={() => {
                                                                                hideIndustry(
                                                                                    `${gic_group}`
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='divider_no_margin'></div>
                                                                    {Object.keys(
                                                                        industries[
                                                                            gic_sector
                                                                        ]
                                                                            .contains[
                                                                            gic_group
                                                                        ]
                                                                            .contains
                                                                    ).map(
                                                                        (
                                                                            gic_industry
                                                                        ) =>
                                                                            visibleIndustries[
                                                                                `${gic_industry}`
                                                                            ] ? (
                                                                                <div>
                                                                                    <div className='width_100pr background_color_4 pl_30px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                                        <div className='flex left'>
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    checkedIndustries[
                                                                                                        `${gic_industry}`
                                                                                                    ]
                                                                                                }
                                                                                                color='primary'
                                                                                                size='medium'
                                                                                                onClick={() => {
                                                                                                    checkIndustry(
                                                                                                        `${gic_industry}`
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <div>
                                                                                                {
                                                                                                    industries[
                                                                                                        gic_sector
                                                                                                    ]
                                                                                                        .contains[
                                                                                                        gic_group
                                                                                                    ]
                                                                                                        .contains[
                                                                                                        gic_industry
                                                                                                    ]
                                                                                                        .name
                                                                                                }
                                                                                            </div>
                                                                                        </div>

                                                                                        <RiIcons.RiArrowUpSFill
                                                                                            className='mr_20px cursor_pointer'
                                                                                            onClick={() => {
                                                                                                hideIndustry(
                                                                                                    `${gic_industry}`
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='divider_no_margin'></div>
                                                                                    {Object.keys(
                                                                                        industries[
                                                                                            gic_sector
                                                                                        ]
                                                                                            .contains[
                                                                                            gic_group
                                                                                        ]
                                                                                            .contains[
                                                                                            gic_industry
                                                                                        ]
                                                                                            .contains
                                                                                    ).map(
                                                                                        (
                                                                                            gic_sub_industry
                                                                                        ) => (
                                                                                            <div>
                                                                                                <div className='width_100pr background_color_5 pl_45px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                                                    <div className='flex left'>
                                                                                                        <Checkbox
                                                                                                            checked={
                                                                                                                checkedIndustries[
                                                                                                                    `${gic_sub_industry}`
                                                                                                                ]
                                                                                                            }
                                                                                                            color='primary'
                                                                                                            size='small'
                                                                                                            className=''
                                                                                                            onClick={() => {
                                                                                                                checkIndustry(
                                                                                                                    `${gic_sub_industry}`
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <div className='ml_5px'>
                                                                                                            {
                                                                                                                industries[
                                                                                                                    gic_sector
                                                                                                                ]
                                                                                                                    .contains[
                                                                                                                    gic_group
                                                                                                                ]
                                                                                                                    .contains[
                                                                                                                    gic_industry
                                                                                                                ]
                                                                                                                    .contains[
                                                                                                                    gic_sub_industry
                                                                                                                ]
                                                                                                                    .name
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='divider_no_margin'></div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <div className='width_100pr background_color_4 pl_30px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                                        <div className='flex left'>
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    checkedIndustries[
                                                                                                        `${gic_industry}`
                                                                                                    ]
                                                                                                }
                                                                                                color='primary'
                                                                                                size='medium'
                                                                                                onClick={() => {
                                                                                                    checkIndustry(
                                                                                                        `${gic_industry}`
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <div>
                                                                                                {
                                                                                                    industries[
                                                                                                        gic_sector
                                                                                                    ]
                                                                                                        .contains[
                                                                                                        gic_group
                                                                                                    ]
                                                                                                        .contains[
                                                                                                        gic_industry
                                                                                                    ]
                                                                                                        .name
                                                                                                }
                                                                                            </div>
                                                                                        </div>

                                                                                        <RiIcons.RiArrowDownSFill
                                                                                            className='mr_20px cursor_pointer'
                                                                                            onClick={() => {
                                                                                                showIndustry(
                                                                                                    `${gic_industry}`
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div className='width_100pr background_color_3 pl_15px min_width_200px ms_auto max_width_1000px flex space_between'>
                                                                        <div className='flex left'>
                                                                            <Checkbox
                                                                                checked={
                                                                                    checkedIndustries[
                                                                                        `${gic_group}`
                                                                                    ]
                                                                                }
                                                                                color='primary'
                                                                                size='medium'
                                                                                onClick={() => {
                                                                                    checkIndustry(
                                                                                        `${gic_group}`
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <div>
                                                                                {
                                                                                    industries[
                                                                                        gic_sector
                                                                                    ]
                                                                                        .contains[
                                                                                        gic_group
                                                                                    ]
                                                                                        .name
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <RiIcons.RiArrowDownSFill
                                                                            className='mr_20px cursor_pointer'
                                                                            onClick={() => {
                                                                                showIndustry(
                                                                                    `${gic_group}`
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className='width_100pr max_width_1000px ms_auto flex space_between'>
                                                            <div className='flex left'>
                                                                <Checkbox
                                                                    checked={
                                                                        checkedIndustries[
                                                                            `${gic_sector}`
                                                                        ]
                                                                    }
                                                                    color='primary'
                                                                    size='medium'
                                                                    onClick={() => {
                                                                        checkIndustry(
                                                                            `${gic_sector}`
                                                                        );
                                                                    }}
                                                                />
                                                                <div>
                                                                    {
                                                                        industries[
                                                                            gic_sector
                                                                        ].name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <RiIcons.RiArrowDownSFill
                                                                className='mr_20px cursor_pointer'
                                                                onClick={() => {
                                                                    showIndustry(
                                                                        `${gic_sector}`
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </div>
                            </Dialog>

                            {/* ESG */}
                            <div className='button_white_layer_2 ml_20px pr_15px text_2 button_border_color_1'>
                                <div className=''>
                                    <form action='#'>
                                        <label>
                                            <Checkbox
                                                id='checkbox_esg'
                                                name='checkbox_esg'
                                                checked={options.esg.value}
                                                color='primary'
                                                onChange={() => {
                                                    updateESG(
                                                        !options.esg.value
                                                    );
                                                }}
                                                inputProps={{
                                                    "aria-label":
                                                        "primary checkbox",
                                                }}
                                            />
                                        </label>
                                    </form>
                                </div>
                                <div>ESG</div>
                            </div>
                        </div>
                        {/* SEARCH METRIC */}
                        <div className=' width_50pr pl_20px pr_20px'>
                            <div className='flex left width_100pr min_width_300px background_color_3 border_box input_basic border_radius_5px padding_5px height_35px'>
                                <AiIcons.AiOutlineSearch className='mr_10px text_1 text_color_2' />
                                <div className='width_100pr text_color_2'>
                                    <input
                                        type='text'
                                        className='text_2 text_color_1 width_100pr pt_15px pb_15px'
                                        placeholder={useintl.formatMessage({
                                            id: "metrics.search_metric",
                                        })}
                                        onKeyUp={handlesearchSliderText}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* THRESHOLD SECTION */}
                        {user.simplified_view ? (
                            <div className='mt_20px height_40px'></div>
                        ) : (
                            <div className='flex left height_40px width_100pr pl_20px pr_20px mb_20px border_box text_color_1'>
                                <AiIcons.AiOutlineInfoCircle
                                    className='cursor_pointer mr_10px'
                                    onClick={handleClickOpenThresholdInfo}
                                />
                                <div className='text_2'>
                                    {useintl.formatMessage({
                                        id: "general.threshold",
                                    })}
                                    :
                                </div>
                                {user.status === 0 ? (
                                    <Link className='ml_15px' to={`/account`}>
                                        Upgrade
                                    </Link>
                                ) : (
                                    <div className='flex left'>
                                        <div className='ml_10px mt_3px'>
                                            <TextField
                                                className='width_40px'
                                                // id='standard-number'
                                                // label='Number'
                                                type='text'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={
                                                    threshold >= 0
                                                        ? threshold
                                                        : ""
                                                }
                                                defaultValue={null}
                                                onChange={handleThreshold}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                        max: checked,
                                                    },
                                                    style: { fontSize: 16 },
                                                }}
                                                hiddenLabel
                                                id='filled-hidden-label-small'
                                                // defaultValue='small'
                                                variant='filled'
                                                size='small'
                                            />
                                        </div>
                                        <div className='display_lt320px'>
                                            / {checked}
                                        </div>
                                        <div className='display_320px text_2 ml_5px'>
                                            {useintl.formatMessage({
                                                id: "general.out_of",
                                            })}
                                            {"  "}
                                            {checked}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <Dialog
                            open={openThresholdInfo}
                            onClose={handleCloseThresholdInfo}
                            maxWidth={false}
                            className=''
                        >
                            <div className='width_70vw min_width_250 max_width_900 text_color_1 background_color_4'>
                                <div className='margin_10px flex space_between title_5'>
                                    <div className='flex'>
                                        <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px' />
                                        <div className='mr_10px'>
                                            {useintl.formatMessage({
                                                id: "general.threshold",
                                            })}
                                        </div>
                                    </div>
                                    <AiIcons.AiOutlineClose
                                        className='cursor_pointer'
                                        onClick={handleCloseThresholdInfo}
                                    />
                                </div>

                                <div className='divider_no_margin'></div>
                                <div className='slider_info_disclaimer margin_10px'>
                                    <p>
                                        {useintl.formatMessage({
                                            id: "general.threshold.explanation",
                                        })}
                                    </p>
                                </div>
                            </div>
                        </Dialog>
                        <div className='divider'></div>
                        {/* ABSOLUTE/ RELATIVE SWITCH */}
                        <div className='flex width_100pr height_40px_30px text_2'>
                            <div
                                className={
                                    metricType === 0
                                        ? "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer accent_color_1 text_decoration_underline"
                                        : "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer text_decoration_none"
                                }
                                onClick={
                                    metricType !== 0
                                        ? () => {
                                              setMetricType(0);
                                          }
                                        : null
                                }
                            >
                                {useintl.formatMessage({
                                    id: `general.absolute`,
                                })}
                            </div>
                            <div
                                className={
                                    metricType === 1
                                        ? "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer accent_color_1 text_decoration_underline"
                                        : "width_50pr pl_20px height_40px_30px text_color_1 border_box flex left cursor_pointer text_decoration_none"
                                }
                                onClick={
                                    metricType !== 1
                                        ? () => {
                                              setMetricType(1);
                                          }
                                        : null
                                }
                            >
                                {useintl.formatMessage({
                                    id: `general.relative`,
                                })}
                            </div>
                        </div>
                        <div className='divider_no_margin'></div>
                        {/* METRICS */}
                        <div className='flex wrap space_around border_box width_100pr background_color_2'>
                            {metricType === 0
                                ? Object.keys(sorted_metrics_object).map(
                                      (category) =>
                                          (openSortedMetrics[category].match &&
                                              openSortedMetrics[category]
                                                  .match > 0) ||
                                          searchSliderText === "" ? (
                                              <div className='width_100pr flex wrap'>
                                                  <div
                                                      className='width_100pr height_50px ps_20px flex space_between text_2 text_color_1 background_color_1 cursor_pointer border_1px_solid'
                                                      onClick={() => {
                                                          handleOpenSortedMetrics(
                                                              category
                                                          );
                                                      }}
                                                  >
                                                      <Badge
                                                          badgeContent={
                                                              openSortedMetrics[
                                                                  category
                                                              ].checked
                                                          }
                                                          color='secondary'
                                                          max={999}
                                                      >
                                                          <div className='pr_10px'>
                                                              {useintl.formatMessage(
                                                                  {
                                                                      id: `metrics.${category}`,
                                                                  }
                                                              )}
                                                          </div>
                                                      </Badge>
                                                      {openSortedMetrics[
                                                          category
                                                      ].open ? (
                                                          <RiIcons.RiArrowUpSFill />
                                                      ) : (
                                                          <RiIcons.RiArrowDownSFill />
                                                      )}
                                                  </div>
                                                  {openSortedMetrics[category]
                                                      .open
                                                      ? sorted_metrics_object[
                                                            category
                                                        ].map((metric) =>
                                                            searchSliderObject[
                                                                metric
                                                            ] &&
                                                            AllowedMetrics.includes(
                                                                metric
                                                            ) ? (
                                                                <RangeSliderScreener
                                                                    sliderprops={
                                                                        searchSliderObject[
                                                                            metric
                                                                        ]
                                                                    }
                                                                    category={
                                                                        category
                                                                    }
                                                                    type='normal'
                                                                    detectCheck={
                                                                        detectCheck
                                                                    }
                                                                    key={
                                                                        category +
                                                                        "_" +
                                                                        metric
                                                                    }
                                                                />
                                                            ) : searchSliderObject[
                                                                  metric
                                                              ] &&
                                                              !AllowedMetrics.includes(
                                                                  metric
                                                              ) ? (
                                                                <RangeSliderScreenerUpgrade
                                                                    sliderprops={
                                                                        searchSliderObject[
                                                                            metric
                                                                        ]
                                                                    }
                                                                    category={
                                                                        category
                                                                    }
                                                                    type='normal'
                                                                    detectCheck={
                                                                        detectCheck
                                                                    }
                                                                    key={
                                                                        category +
                                                                        "_" +
                                                                        metric
                                                                    }
                                                                />
                                                            ) : null
                                                        )
                                                      : null}
                                              </div>
                                          ) : null
                                  )
                                : Object.keys(sorted_rankers_object).map(
                                      (category) =>
                                          ((openSortedRankers[category].match &&
                                              openSortedRankers[category]
                                                  .match > 0) ||
                                              searchSliderText === "") &&
                                          !(
                                              user.simplified_view &&
                                              category === "fundamental_ratios"
                                          ) ? (
                                              <div className='width_100pr flex wrap'>
                                                  <div
                                                      className='width_100pr height_50px ps_20px flex space_between text_2 text_color_1 background_color_1 cursor_pointer border_1px_solid'
                                                      onClick={() => {
                                                          handleOpenSortedRankers(
                                                              category
                                                          );
                                                      }}
                                                  >
                                                      <Badge
                                                          badgeContent={
                                                              openSortedRankers[
                                                                  category
                                                              ].checked
                                                          }
                                                          color='secondary'
                                                          max={999}
                                                      >
                                                          <div>
                                                              {useintl.formatMessage(
                                                                  {
                                                                      id: `metrics.${category}`,
                                                                  }
                                                              )}
                                                          </div>
                                                      </Badge>
                                                      {openSortedRankers[
                                                          category
                                                      ].open ? (
                                                          <RiIcons.RiArrowUpSFill />
                                                      ) : (
                                                          <RiIcons.RiArrowDownSFill />
                                                      )}
                                                  </div>
                                                  {openSortedRankers[category]
                                                      .open
                                                      ? sorted_rankers_object[
                                                            category
                                                        ].map((metric) =>
                                                            searchSliderObject[
                                                                metric
                                                            ] &&
                                                            AllowedMetrics.includes(
                                                                metric
                                                            ) ? (
                                                                <RangeSliderScreener
                                                                    sliderprops={
                                                                        searchSliderObject[
                                                                            metric
                                                                        ]
                                                                    }
                                                                    category={
                                                                        category
                                                                    }
                                                                    type='ranker'
                                                                    detectCheck={
                                                                        detectCheck
                                                                    }
                                                                    key={
                                                                        category +
                                                                        "_" +
                                                                        metric
                                                                    }
                                                                />
                                                            ) : searchSliderObject[
                                                                  metric
                                                              ] &&
                                                              !AllowedMetrics.includes(
                                                                  metric
                                                              ) ? (
                                                                <RangeSliderScreenerUpgrade
                                                                    sliderprops={
                                                                        searchSliderObject[
                                                                            metric
                                                                        ]
                                                                    }
                                                                    category={
                                                                        category
                                                                    }
                                                                    type='normal'
                                                                    detectCheck={
                                                                        detectCheck
                                                                    }
                                                                    key={
                                                                        category +
                                                                        "_" +
                                                                        metric
                                                                    }
                                                                />
                                                            ) : null
                                                        )
                                                      : null}
                                              </div>
                                          ) : null
                                  )}
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* Table Column Creation */}
            <Dialog
                open={openColumns}
                onClose={handleCloseColumns}
                fullScreen={true}
            >
                <CompaniesTableModal
                    closeModal={handleCloseColumns}
                    slider={slider}
                    options={options}
                    tableStructure={tableStructure}
                    handletableStructure={handletableStructure}
                    metricType={metricType}
                    setMetricType={setMetricType}
                    // handlesearchSliderText={handlesearchSliderText}
                    // sorted_metrics_object={sorted_metrics_object}
                    // sorted_rankers_object={sorted_rankers_object}
                    // AllowedMetrics={AllowedMetrics}
                    // openSortedMetrics={openSortedMetrics}
                    // openSortedRankers={openSortedRankers}
                    // searchSliderText={searchSliderText}
                    // handleOpenSortedMetrics={handleOpenSortedMetrics}
                    // searchSliderObject={searchSliderObject}
                />
            </Dialog>

            {/* TABLE */}
            {companies === null ? (
                <Preloader className='mt_50px' />
            ) : companies && companies.length > 0 ? (
                <div className='mt_25px ml_10px_0px mr_10px_0px'>
                    <CompaniesTable
                        companies={companies}
                        slider={slider}
                        options={options}
                        tableStructure={tableStructure}
                        screenerPagination={pagination}
                    />
                </div>
            ) : (
                <div className='mt_25px ml_10px_0px mr_10px_0px'>
                    No company matches your criteria. Either adjust them or
                    lower your threshhold to see results.
                </div>
            )}
        </div>
    );
};

Screener.propTypes = {
    sidebar: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateScreenerValues: PropTypes.func.isRequired,
    getFilteredCompanies: PropTypes.func.isRequired,
    clearScreenerValues: PropTypes.func.isRequired,
    updateSearchTextScreener: PropTypes.func.isRequired,
    updateSearchOption: PropTypes.func.isRequired,
    // updateSearchCountries: PropTypes.func.isRequired,
    updateSearchIndustries: PropTypes.func.isRequired,
    updateCheckedIndustries: PropTypes.func.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
    setThreshold: PropTypes.func.isRequired,
    updateESG: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    screener: state.screener,
    user: state.user,
});

export default connect(mapStateToProps, {
    updateScreenerValues,
    getFilteredCompanies,
    updateSingleOptionsValue,
    updateSingleOptionSortBy,
    clearScreenerValues,
    updateSearchTextScreener,
    updateSearchOption,
    // updateSearchCountries,
    updateSearchIndustries,
    updateCheckedIndustries,
    setDisplayFalseAll,
    setThreshold,
    updateESG,
})(Screener);
