import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

// HIGHCHARTS
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import StockPrice from "./charts/StockPrice";
import FeedbackIcon from "../elements/FeedbackIcon";
import MetricAnalysisBarSmall from "./figures/MetricAnalysisBarSmall";
import MetricAnalysisBarUpgrade from "./figures/MetricAnalysisBarUpgrade";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { Context_Theme } from "../../../context/ThemeContext";
import { MetricsContext } from "../../../context/MetricsContext";

// MUI
import Dialog from "@mui/material/Dialog";

const Overview = ({
    company,
    screener: { slider },
    sidebar: { sidebar },
    user,
}) => {
    const useintl = useIntl();
    const context_theme = useContext(Context_Theme);
    const metrics = useContext(MetricsContext);

    let SliderData = {};
    let AllMetrics = metrics.AllMetrics;
    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };
    SliderData = sliderDataProvider(AllMetrics);
    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // POLARCHART
    let polarChartOptions = {
        chart: {
            polar: true,
            backgroundColor: "rgb(0,0,0,0)",
        },
        title: {
            text: "",
            // x: -80,
        },
        xAxis: {
            categories: [
                useintl.formatMessage({
                    id: "metrics.price_book",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
                useintl.formatMessage({
                    id: "metrics.asset_turnover",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.debt_to_equity",
                }),
                useintl.formatMessage({
                    id: "metrics.current_ratio",
                }),
            ],
            tickmarkPlacement: "on",
            lineWidth: 0,
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            lineWidth: 0,
            min: 0,
            max: 100,
        },
        exporting: {
            enabled: false,
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        legend: {
            disabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                // type: "area",
                showInLegend: false,
                tooltip: {
                    enabled: true,
                },
                data: [
                    {
                        name: useintl.formatMessage({
                            id: "metrics.price_book",
                        }),
                        y: company.company.price_book_ranker
                            ? Number(
                                  (
                                      company.company.price_book_ranker * 100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.price_book
                            ? company.company.price_book.toFixed(2)
                            : null,
                        a: "",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.price_sales",
                        }),
                        y: company.company.price_sales_ranker
                            ? Number(
                                  (
                                      company.company.price_sales_ranker * 100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.price_sales
                            ? Number(company.company.price_sales.toFixed(2))
                            : null,
                        a: "",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.asset_turnover",
                        }),
                        y: company.company.asset_turnover_ranker
                            ? Number(
                                  (
                                      company.company.asset_turnover_ranker *
                                      100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.asset_turnover
                            ? Number(company.company.asset_turnover.toFixed(2))
                            : null,
                        a: "%",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.gross_profit_margin",
                        }),
                        y: company.company.gross_profit_margin_ranker
                            ? Number(
                                  (
                                      company.company
                                          .gross_profit_margin_ranker * 100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.gross_profit_margin
                            ? Number(
                                  company.company.gross_profit_margin.toFixed(2)
                              )
                            : null,
                        a: "%",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.revenue_growth_1y",
                        }),
                        y: company.company.revenue_growth_1y_ranker
                            ? Number(
                                  (
                                      company.company.revenue_growth_1y_ranker *
                                      100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.revenue_growth_1y
                            ? Number(
                                  company.company.revenue_growth_1y.toFixed(2)
                              )
                            : null,
                        a: "%",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.revenue_growth_3y",
                        }),
                        y: company.company.revenue_growth_3y_ranker
                            ? Number(
                                  (
                                      company.company.revenue_growth_3y_ranker *
                                      100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.revenue_growth_3y
                            ? Number(
                                  company.company.revenue_growth_3y.toFixed(2)
                              )
                            : null,
                        a: "%",
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.debt_to_equity",
                        }),
                        y: company.company.debt_to_equity_ranker
                            ? Number(
                                  (
                                      company.company.debt_to_equity_ranker *
                                      100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.debt_to_equity
                            ? Number(company.company.debt_to_equity.toFixed(2))
                            : null,
                    },
                    {
                        name: useintl.formatMessage({
                            id: "metrics.current_ratio",
                        }),
                        y: company.company.current_ratio_ranker
                            ? Number(
                                  (
                                      company.company.current_ratio_ranker * 100
                                  ).toFixed(2)
                              )
                            : null,
                        z: company.company.current_ratio
                            ? Number(company.company.current_ratio.toFixed(2))
                            : null,
                    },
                ],
                pointPlacement: "on",
            },
        ],
        tooltip: {
            shared: true,
            pointFormat: `Sector ranking: <b>{point.y} %</b> <br/> Value: <b>{point.z}{point.a}`,
        },
    };

    // RISK INFO
    const [openRiskInfo, setOpenRiskInfo] = React.useState(false);
    const handleClickOpenRiskInfo = () => {
        setOpenRiskInfo(true);
    };
    const handleCloseRiskInfo = () => {
        setOpenRiskInfo(false);
    };

    // RELATIVE SCORE INFO
    const [openRelativeScoreInfo, setOpenRelativeScoreInfo] =
        React.useState(false);
    const handleClickOpenRelativeScoreInfo = () => {
        setOpenRelativeScoreInfo(true);
    };
    const handleCloseRelativeScoreInfo = () => {
        setOpenRelativeScoreInfo(false);
    };

    // SIDESCROLL
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);

    //Slide click
    const slide = (shift) => {
        let new_scroll = scrollX + shift;
        if (new_scroll < 0) {
            scrl.current.scrollLeft = 0;
            new_scroll = 0;
        } else {
            scrl.current.scrollLeft += shift;
        }
        setscrollX(new_scroll);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    Highcharts.setOptions(context_theme.currentThemeObject);

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })} - ${useintl.formatMessage({
            id: "company.overview",
        })}`,
        location_en: "Company - Overview",
        location_index: 4,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })}`,
        location_index: 3,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
        ],
        header_text_1: useintl.formatMessage({
            id: "company.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/8kvIzYbd8AY'
                title='How you can ANALYZE STOCKS using STOCKS ON VIEW'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "company.description_text_1",
        }),
    };

    let donut_colors = [
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
    ];

    let donut_colors_hover = [
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
    ];

    let extrinsic_risk_total = 1 - company.company.intrinsic_risk;

    let extrinsic_equity = company.company.equity_risk / extrinsic_risk_total;
    let extrinsic_interest_rate =
        company.company.interest_rate_risk / extrinsic_risk_total;
    let extrinsic_credit = company.company.credit_risk / extrinsic_risk_total;
    let extrinsic_commodities =
        company.company.commodities_risk / extrinsic_risk_total;
    let extrinsic_inflation =
        company.company.inflation_risk / extrinsic_risk_total;

    let risk_allocation = [
        {
            name: "Equity risk",
            y:
                user.status === 0
                    ? 20
                    : Number((extrinsic_equity * 100).toFixed(2)),
            color: donut_colors[1],
            f: donut_colors_hover[1],
        },
        {
            name: "Interest rate risk",
            y:
                user.status === 0
                    ? 20
                    : Number((extrinsic_interest_rate * 100).toFixed(2)),
            color: donut_colors[2],
            f: donut_colors_hover[2],
        },
        {
            name: "Credit risk",
            y:
                user.status === 0
                    ? 20
                    : Number((extrinsic_credit * 100).toFixed(2)),
            color: donut_colors[3],
            f: donut_colors_hover[3],
        },
        {
            name: "Commodities risk",
            y:
                user.status === 0
                    ? 20
                    : Number((extrinsic_commodities * 100).toFixed(2)),
            color: donut_colors[4],
            f: donut_colors_hover[4],
        },
        {
            name: "Inflation risk",
            y:
                user.status === 0
                    ? 20
                    : Number((extrinsic_inflation * 100).toFixed(2)),
            color: donut_colors[5],
            f: donut_colors_hover[5],
        },
    ];

    let chartOptions = {
        chart: {
            type: "pie",
            backgroundColor: "rgb(0,0,0,0)",
            margin: [0, 0, 0, 0],
            spacingTop: 10,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            width: window.innerWidth < 768 ? 300 : 400,
        },
        title: {
            text: `Extrinsic Risk`,
            align: "center",
            verticalAlign: "middle",
            y: 15,
            style: {
                fontSize: "14px",
            },
        },
        legend: {
            layout: "vertical",
            align: "right",
            x: 5,
            verticalAlign: "top",
            y: -5,
            floating: true,
            backgroundColor:
                Highcharts.getOptions().legend.backgroundColor || // theme
                "rgba(255,255,255,0.25)",
        },
        exporting: {
            enabled: false,
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -125,
                    y: -5,
                    format: "{y}%",
                    style: {
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                    },
                },
                size: "100%",
                borderWidth: 3,
                showInLegend: true,
            },
            series: {
                animation: false,
            },
        },
        tooltip: {
            pointFormat: `{series.name}<br/>Proportion: <b>{point.y} %</b><br/>`,
            shared: true,
        },
        series: [
            {
                type: "pie",
                name: "",
                tooltip: {
                    valueDecimals: 2,
                },
                innerSize: "65%",
                data: risk_allocation,
            },
        ],
    };

    return (
        <div>
            {company.company === null ? (
                <Preloader />
            ) : (
                <div className='text_color_1 pos_relative'>
                    <FeedbackIcon
                        feedbackInfo={feedbackInfo}
                        informationInfo={informationInfo}
                    />

                    {/* SCROLL NAVIGATION */}
                    {(window.innerWidth < 2410 && sidebar) ||
                    (window.innerWidth < 2160 && !sidebar) ? (
                        <div className='pos_absolute width_100pr height_480px'>
                            <div className='flex space_between height_100pr z_index_max mt_20px mb_20px'>
                                {scrollX !== 0 ? (
                                    <div
                                        className='prev cursor_pointer border_radius_15px_top_right border_radius_15px_bottom_right height_100pr flex flex_column center background_color_4 width_30px_20px hover_opacity_60'
                                        onClick={() =>
                                            slide(
                                                window.innerWidth < 768
                                                    ? -360
                                                    : -540
                                            )
                                        }
                                    >
                                        <MdIcons.MdChevronLeft className='width_30px height_40px text_color_1' />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {!scrolEnd ? (
                                    <div
                                        className='prev cursor_pointer border_radius_15px_top_left border_radius_15px_bottom_left height_100pr flex flex_column center background_color_4 width_30px_20px hover_opacity_60'
                                        onClick={() =>
                                            slide(
                                                window.innerWidth < 768
                                                    ? +360
                                                    : +540
                                            )
                                        }
                                    >
                                        <MdIcons.MdChevronRight className='width_30px height_40px text_color_1' />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* CAROUSEL */}
                    <div className='pos_relative ms_30px'>
                        <div
                            className='flex overflow_x_auto scroll_behavior_smooth ml_5px mr_5px border_box'
                            ref={scrl}
                            onScroll={scrollCheck}
                        >
                            {/* OVERVIEW TABLE */}
                            <div className='min_width_500px_320px max_width_700px width_95pr height_480px background_color_3 mt_20px mb_20px mr_20px ps_5px shadow_dark border_radius_15px border_box'>
                                <div className='padding_10px text_1'>
                                    General
                                </div>
                                <div className='flex flex_column ps_10px width_100pr'>
                                    <div className='width_100pr flex pv_12px_8px'>
                                        <div className='text_left width_40pr text_3'>
                                            {useintl.formatMessage({
                                                id: "metrics.sector",
                                            })}
                                        </div>
                                        <div className='text_left width_40pr min_width_50px text_3'>
                                            {company.company.sector !== null
                                                ? company.company.sector
                                                : "-"}
                                        </div>
                                        <div className='width_20pr flex right'></div>
                                    </div>
                                    <div className='width_100pr flex pv_12px_8px'>
                                        <div className='text_left width_40pr text_3'>
                                            {useintl.formatMessage({
                                                id: "metrics.industry",
                                            })}
                                        </div>
                                        <div className='text_left width_40pr text_3'>
                                            {company.company.industry !== null
                                                ? company.company.industry
                                                : "-"}
                                        </div>
                                        <div className='width_20pr flex right'></div>
                                    </div>
                                    {/* <div className='width_100pr flex pv_12px_8px'>
                            <div className='text_left width_40pr text_3'>
                                {useintl.formatMessage({
                                    id: "metrics.shares_held_by_institutions",
                                })}
                            </div>
                            <div className='text_left width_40pr text_3'>
                                {`${
                                    company.company
                                        .shares_held_by_institutions !==
                                    null
                                        ? Number(
                                              company.company.shares_held_by_institutions.toPrecision(
                                                  4
                                              )
                                          )
                                        : "-"
                                } %`}
                            </div>
                            <div className='width_20pr flex right'></div>
                        </div>
                        <div className='width_100pr flex pv_12px_8px'>
                            <div className='text_left width_40pr text_3'>
                                {useintl.formatMessage({
                                    id: "metrics.shares_held_by_insiders",
                                })}
                            </div>
                            <div className='text_left width_40pr text_3'>
                                {`${
                                    company.company
                                        .shares_held_by_insiders !==
                                    null
                                        ? Number(
                                              company.company.shares_held_by_insiders.toPrecision(
                                                  4
                                              )
                                          )
                                        : "-"
                                } %`}
                            </div>
                            <div className='width_20pr flex right'></div>
                        </div> */}
                                    <div className='text_color_1 width_100pr pv_12px_8px'>
                                        <MetricAnalysisBarSmall
                                            sliderprops={
                                                searchSliderObject[
                                                    "market_cap_usd"
                                                ]
                                            }
                                            company={company.company}
                                            metric={
                                                company.company[
                                                    "market_cap_usd"
                                                ]
                                            }
                                            metric_ranker={
                                                company.company[
                                                    `market_cap_ranker`
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className='text_color_1 width_100pr pv_12px_8px'>
                                        <MetricAnalysisBarSmall
                                            sliderprops={
                                                searchSliderObject[
                                                    "dividend_yield"
                                                ]
                                            }
                                            company={company.company}
                                            metric={
                                                company.company[
                                                    "dividend_yield"
                                                ]
                                            }
                                            metric_ranker={
                                                company.company[
                                                    `dividend_yield_ranker`
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className='text_color_1 width_100pr pv_12px_8px'>
                                        <MetricAnalysisBarSmall
                                            sliderprops={
                                                searchSliderObject[
                                                    "price_earnings"
                                                ]
                                            }
                                            company={company.company}
                                            metric={
                                                company.company[
                                                    "price_earnings"
                                                ]
                                            }
                                            metric_ranker={
                                                company.company[
                                                    `price_earnings_ranker`
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className='text_color_1 width_100pr pv_12px_8px'>
                                        <MetricAnalysisBarSmall
                                            sliderprops={
                                                searchSliderObject[
                                                    "price_sales"
                                                ]
                                            }
                                            company={company.company}
                                            metric={
                                                company.company["price_sales"]
                                            }
                                            metric_ranker={
                                                company.company[
                                                    `price_sales_ranker`
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className='text_color_1 width_100pr pv_12px_8px'>
                                        <MetricAnalysisBarSmall
                                            sliderprops={
                                                searchSliderObject[
                                                    "revenue_growth_1y"
                                                ]
                                            }
                                            company={company.company}
                                            metric={
                                                company.company[
                                                    "revenue_growth_1y"
                                                ]
                                            }
                                            metric_ranker={
                                                company.company[
                                                    `revenue_growth_1y_ranker`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* SPIDER CHART SCORE */}
                            <div className='min_width_500px_320px max_width_700px width_95pr height_480px background_color_3 margin_20px ps_5px shadow_dark border_radius_15px border_box'>
                                {/* <div className='divider_no_margin_dark'></div>{' '} */}
                                <div className='padding_10px flex text_1'>
                                    <div className='text_1'>
                                        {" "}
                                        {useintl.formatMessage({
                                            id: `metrics.relative_score`,
                                        })}
                                    </div>
                                    <div className='text_2 flex right ml_10px mt_5px'>
                                        <AiIcons.AiOutlineInfoCircle
                                            className='cursor_pointer'
                                            onClick={
                                                handleClickOpenRelativeScoreInfo
                                            }
                                        />
                                    </div>
                                </div>

                                {/* RELATIVE SCORE INFO */}
                                <Dialog
                                    open={openRelativeScoreInfo}
                                    onClose={handleCloseRelativeScoreInfo}
                                    maxWidth={false}
                                    className='slider_info_wrapper'
                                >
                                    <div className='slider_info_container background_color_3 text_color_1'>
                                        <div className='slider_info_header title_5'>
                                            <div className='flex'>
                                                <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px ' />
                                                {/* {sliderprops.displayName} */}
                                                <div className='mr_10px'>
                                                    {useintl.formatMessage({
                                                        id: `metrics.relative_score`,
                                                    })}
                                                </div>
                                            </div>
                                            <AiIcons.AiOutlineClose
                                                className='title_5 cursor_pointer mt_5px'
                                                onClick={
                                                    handleCloseRelativeScoreInfo
                                                }
                                            />
                                        </div>

                                        {/* <SliderInfoBox sliderprops={sliderprops} /> */}
                                        <div className='divider_no_margin'></div>
                                        <div className='flex flex_column align_items_start margin_10px'>
                                            <div className='title_6 flex'>
                                                <IoIcons5.IoBookOutline className='mr_10px' />
                                                {useintl.formatMessage({
                                                    id: `metrics.infobox.section_1.header`,
                                                })}
                                            </div>
                                            <div className='margin_10px'>
                                                {useintl.formatMessage({
                                                    id: `metrics.relative_score.description_text_1`,
                                                })}
                                            </div>
                                        </div>
                                        {/* <div className='divider_no_margin'></div> */}
                                    </div>
                                </Dialog>

                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={polarChartOptions}
                                />
                            </div>
                            {/* RISK DONUT */}
                            {user.status === 0 ? (
                                <div className='pos_relative min_width_500px_320px width_500px_320px max_width_700px width_95pr height_480px margin_20px shadow_dark border_radius_15px border_box'>
                                    {/* <div className='divider_no_margin_dark'></div>{' '} */}
                                    <div className='pos_absolute width_100pr height_100pr blur background_color_3 ps_5px'>
                                        <div className='padding_10px text_1'>
                                            Risk Allocation
                                        </div>
                                        <div className='flex flex_column mt_-10px width_100pr space_around width_500px_320px'>
                                            {/* <div className='flex center pos_relative width_100pr_i ps_5px mt_-15px'> */}
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={chartOptions}
                                            />
                                            {/* </div> */}
                                            {/* IV BARCHART */}
                                            <div className='text_color_1 width_100pr'>
                                                <MetricAnalysisBarUpgrade
                                                    sliderprops={
                                                        searchSliderObject[
                                                            "implied_volatility"
                                                        ]
                                                    }
                                                    dummy_value={20}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pos_absolute width_100pr height_100pr flex center'>
                                        <div className='flex flex_column center width_220px height_100px background_color_2 border_radius_15px shadow_dark'>
                                            <div className='text_2'>
                                                Company risk allocation
                                            </div>
                                            <Link
                                                to={`/account`}
                                                className='hover_opacity_80'
                                            >
                                                Upgrade
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ) : user.simplified_view ? null : (
                                <div className='min_width_500px_320px width_500px_320px max_width_700px width_95pr height_480px background_color_3 margin_20px  shadow_dark border_radius_15px border_box'>
                                    {/* HEADER  */}
                                    <div className='padding_10px flex text_1'>
                                        <div className='text_1'>
                                            {" "}
                                            Risk Allocation
                                        </div>
                                        <div className='text_2 flex right ml_10px mt_5px'>
                                            <AiIcons.AiOutlineInfoCircle
                                                className='cursor_pointer'
                                                onClick={
                                                    handleClickOpenRiskInfo
                                                }
                                            />
                                        </div>
                                    </div>

                                    {/* RISK INFO */}
                                    <Dialog
                                        open={openRiskInfo}
                                        onClose={handleCloseRiskInfo}
                                        maxWidth={false}
                                        className='slider_info_wrapper'
                                    >
                                        <div className='slider_info_container background_color_3 text_color_1'>
                                            <div className='slider_info_header title_5'>
                                                <div className='flex'>
                                                    <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px ' />
                                                    {/* {sliderprops.displayName} */}
                                                    <div className='mr_10px'>
                                                        Risk Allocation
                                                    </div>
                                                </div>
                                                <AiIcons.AiOutlineClose
                                                    className='title_5 cursor_pointer mt_5px'
                                                    onClick={
                                                        handleCloseRiskInfo
                                                    }
                                                />
                                            </div>

                                            {/* <SliderInfoBox sliderprops={sliderprops} /> */}
                                            <div className='divider_no_margin'></div>
                                            <div className='flex flex_column align_items_start margin_10px'>
                                                <div className='title_6 flex'>
                                                    <IoIcons5.IoBookOutline className='mr_10px' />
                                                    {useintl.formatMessage({
                                                        id: `metrics.infobox.section_1.header`,
                                                    })}
                                                </div>
                                                <div className='margin_10px'>
                                                    {useintl.formatMessage({
                                                        id: `explanation.extrinsic_risk`,
                                                    })}
                                                </div>
                                                <div className='title_6'>
                                                    The indices are:
                                                </div>
                                                {/* SPY, SHY, VCSH, GSG, CPI_US */}
                                                <div className='margin_10px width_100pr'>
                                                    <div className='flex mb_10px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Type
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            Ticker
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            Name
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Equity risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            SPY
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            S&P 500 Index
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Interest rate risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            SHY
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            1-3 Year Treasury
                                                            Bond ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Credit risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            VCSH
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            Short-Term Corporate
                                                            Bond ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Commodities risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            GSG
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            GSCI
                                                            Commodity-Indexed
                                                            Trust ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Inflation risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            -
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            US consumer price
                                                            index
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='divider_no_margin'></div> */}
                                        </div>
                                    </Dialog>

                                    <div className='flex flex_column mt_-15px width_100pr space_around width_500px_320px'>
                                        {/* <div className='flex center pos_relative width_100pr_i ps_5px mt_-15px'> */}
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                        {/* </div> */}
                                        {/* IV BARCHART */}
                                        <div className='text_color_1 width_95pr '>
                                            <MetricAnalysisBarSmall
                                                sliderprops={
                                                    searchSliderObject[
                                                        "implied_volatility"
                                                    ]
                                                }
                                                company={company.company}
                                                metric={
                                                    company.company[
                                                        "implied_volatility"
                                                    ]
                                                }
                                                metric_ranker={
                                                    company.company[
                                                        `implied_volatility_ranker`
                                                    ]
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* EXECUTIVES TABLE */}
                            {company.company.officers &&
                            company.company.officers.length > 0 ? (
                                <div className=' min_width_500px_320px max_width_700px width_95pr overflow_y_auto height_480px background_color_3 mt_20px mb_20px ml_20px ps_5px shadow_dark border_radius_15px border_box'>
                                    <div className='padding_10px text_1'>
                                        Executives
                                    </div>
                                    <table className='width_100pr'>
                                        <tbody className='ps_10px'>
                                            {company.company.officers.map(
                                                (officer) => (
                                                    <tr className=''>
                                                        <td className='text_left text_3 padding_5px vertical_align_top'>
                                                            {officer.name}
                                                        </td>
                                                        <td className='text_left text_3 padding_5px vertical_align_top'>
                                                            {officer.title}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* STOCK NAME AND DESCRIPTION */}
                    <div className='margin_20px min_width_320px padding_10px shadow_dark border_radius_15px'>
                        <div className='flex space_between padding_10px'>
                            <div className='text_1'>
                                {company.company.name !== null
                                    ? `${company.company.name} - ${company.company.ticker}`
                                    : company.company.ticker}
                            </div>
                            {company.company.esg ? (
                                <div className='text_color_green flex'>
                                    <div className='mr_5px'>ESG</div>
                                    <IoIcons5.IoCheckmarkSharp className='text_color_green' />
                                </div>
                            ) : null}
                        </div>
                        <div className='padding_10px text_block'>
                            {company.company.description !== null
                                ? company.company.description
                                : null}
                        </div>
                    </div>

                    {/* STOCK PRICE CHART */}
                    {company.company !== null &&
                    company.company.stock_prices !== null ? (
                        <div className='company_overview_stock_price min_width_320px'>
                            <StockPrice ticker={company.ticker} />
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

Overview.propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    screener: state.screener,
    sidebar: state.sidebar,
});

export default connect(mapStateToProps, null)(Overview);
