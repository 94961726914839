import { GET_COMPANIES_COMPARE, COMPARE_ERROR } from '../actions/Types';

const initialState = {
    companies: null,
    chart_structure: 'individual',
    chart_metrics: null,
    chart_metric_data: null,
    chart_per_share: false,
    ticker: null,
    industry: null,
    competitors: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES_COMPARE:
            return {
                ...state,
                companies: action.payload,
            };

        case COMPARE_ERROR:
            return {
                ...state,
            };

        default:
            return state;
    }
};
