import React, { useEffect } from "react";
import * as AiIcons from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

// ACTIONS
import { setCreatorDiversification } from "../../../../actions/portfolioActions";

// COMPONENTS
import FeedbackIcon from "../../elements/FeedbackIcon";

// MUI
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// let currency = {
//     iso: "USD",
//     sign: "$",
// };

// Tooltip Slider
function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            className='text_1'
            placement='top'
            title={
                value < 500000 ? (
                    Number(value.toFixed(0))
                ) : (
                    <FormattedMessage id='general.more' defaultMessage='More' />
                )
            }
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CreatorDiversification = ({ portfolio, setCreatorDiversification }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const useintl = useIntl();
    const marks = [
        {
            value: 0,
            label: "",
        },
        {
            value: 22.8652526,
            label: "2500$",
        },
        // {
        //     value: 39.81071706,
        //     label: "10000$",
        // },
        {
            value: 75.78582833,
            label: "50000$",
        },
        {
            value: 144.2699906,
            label: "250000$",
        },
        {
            value: 190.3653939,
            label: <FormattedMessage id='general.more' defaultMessage='More' />,
        },
    ];

    const [value, setValue] = React.useState(
        Math.pow(portfolio.in_creation_preferences.diversification, 1 / 2.5)
    );

    const handleChange = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0)
        ) {
            console.log(event.target.value);
            console.log(Math.pow(event.target.value, 1 / 2.5));
            if (Number(event.target.value > 1e11)) {
                setValue(Math.pow(1e11, 1 / 2.5));
            } else {
                setValue(Math.pow(event.target.value, 1 / 2.5));
                setCreatorDiversification(
                    Number(event.target.value),
                    checkBoxes,
                    portfolio.in_creation_preferences
                );
            }
        }
    };

    let [checkBoxes, setCheckBoxes] = React.useState(
        portfolio.in_creation_preferences.fractional_shares
    );

    const handleCheckBoxes = (event) => {
        setCheckBoxes(!checkBoxes);
        setCreatorDiversification(
            Math.pow(value, 2.5),
            !checkBoxes,
            portfolio.in_creation_preferences
        );
    };

    const updateReducer = (event, newValue) => {
        setValue(newValue);
        setCreatorDiversification(
            Math.pow(newValue, 2.5),
            checkBoxes,
            portfolio.in_creation_preferences
        );
    };

    /*Fractional Shares Modal*/
    const [openFractionals, setOpenFractionals] = React.useState(false);
    const handleShowFractionals = () => {
        setOpenFractionals(true);
    };
    const handleCloseFractionals = () => {
        setOpenFractionals(false);
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.diversification",
        })}`,
        location_index: 1,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.diversification",
        })}`,
        location_index: 1,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "portfolio.creator.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/Z2Cy-SHKviU'
                title='The top FEATURES our STOCK MARKET SCREENER offers'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "portfolio.creator.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "portfolio.creator.diversification.header_text_1",
        }),
        description_text_2: useintl.formatMessage({
            id: "portfolio.creator.diversification.description_text_1",
        }),
    };

    return (
        <div className='text_color_1'>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                informationInfo={informationInfo}
            />
            <div className='text_1 pl_25px pt_20px height_70px'>
                {useintl.formatMessage({
                    id: "general.diversification",
                })}
            </div>
            <div className='divider_no_margin'></div>
            <div className='background_color_3 pb_50px height_auto'>
                <div className='pt_50px ml_15px mr_15px text_center text_2'>
                    <p>
                        <FormattedMessage
                            id='portfolio.creator.diversification.question_1'
                            defaultMessage='<b>How much</b> money do you want to invest?'
                            values={{ b: (words) => <b>{words}</b> }}
                        />
                    </p>
                </div>
                <div className='mt_50px ms_auto width_70pr_90pr'>
                    <Slider
                        defaultValue={
                            portfolio.in_creation_preferences.diversification
                        }
                        value={value < 190.3653939 ? value : 190.3653939}
                        onChange={updateReducer}
                        aria-labelledby='discrete-slider-custom'
                        step={0.1}
                        min={12.0112442}
                        max={190.3653939}
                        marks={marks}
                        scale={(x) => x ** 2.5}
                        size='small'
                        ValueLabelComponent={ValueLabelComponent}
                    />
                    <div className='mt_10px text_4'>
                        {Math.pow(value, 2.5) < 5000 ? (
                            <div>
                                *at amounts below 5000$ we recommend to enable
                                fractional shares for diversification purposes{" "}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className='flex center mt_25px'>
                    <div className='height_32px_28px width_150px pl_10px pt_3px input_basic border_box border_1px_solid border_radius_5px background_color_4 text_2'>
                        <input
                            type='text'
                            value={
                                value > 0
                                    ? Number((value ** 2.5).toFixed(0))
                                    : ""
                            }
                            defaultValue={
                                value > 0
                                    ? Number((value ** 2.5).toFixed(0))
                                    : ""
                            }
                            onChange={handleChange}
                            className='text_color_1 text_3'
                        />
                    </div>
                    <div className='ml_5px'>USD</div>
                </div>
                <div className='mt_50px center text_2 flex'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                aria-label='Checkbox demo'
                                checked={checkBoxes}
                                onChange={handleCheckBoxes}
                                name='savings_plans'
                                color='primary'
                            />
                        }
                        label=''
                    />

                    <p>
                        <FormattedMessage
                            id='portfolio.creator.diversification.question_2'
                            defaultMessage='Enable <b>fractional shares</b>'
                            values={{ b: (words) => <b>{words}</b> }}
                        />
                    </p>
                    <AiIcons.AiOutlineInfoCircle
                        className='ml_20px width_20px cursor_pointer'
                        onClick={handleShowFractionals}
                    />
                    {/* Fractional shares description modal */}
                    <Dialog
                        open={openFractionals}
                        onClose={handleCloseFractionals}
                        maxWidth={false}
                        className='width_70pr_95pr min_width_320px max_width_700px ms_auto'
                    >
                        <div>
                            <div>
                                {/* headerline */}
                                <div className='flex space_between title_5 mt_20px ml_15px mr_15px'>
                                    <div className='flex'>
                                        <FormattedMessage
                                            id='general.fractional_shares'
                                            defaultMessage='Fractional Shares'
                                        />
                                    </div>
                                    <div
                                        className='cursor_pointer title_5 width_20px flex center ml_25px mt_5px'
                                        onClick={handleCloseFractionals}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                {/* description */}
                                <div className='mt_15px mb_15px ms_auto text_block width_90pr'>
                                    <FormattedMessage
                                        id='explanation.fractional_shares'
                                        defaultMessage='A fractional share is when you own less than
                                    one whole share of a company. Fractional
                                    shares allow you to purchase stocks based on
                                    the dollar amount you want to invest, so you
                                    may end up with a fraction of a share, a
                                    whole share, or more than one share.'
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

CreatorDiversification.propTypes = {
    portfolio: PropTypes.object.isRequired,
    setCreatorDiversification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, { setCreatorDiversification })(
    CreatorDiversification
);
