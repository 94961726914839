import React, { useEffect } from "react";
// import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

// MUI THEME

import { createTheme, ThemeProvider } from "@mui/material/styles";

export const Context_Theme = React.createContext();

const ThemeContext = (props) => {
    let prefersDarkMode;

    const [currentTheme, setCurrentTheme] = React.useState(
        localStorage.theme === "dark"
            ? "dark"
            : localStorage.theme === "light"
            ? "light"
            : window.matchMedia("(prefers-color-scheme: dark)")
            ? "dark"
            : "light"
    );

    useEffect(() => {
        prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
        if (localStorage.theme) {
            if (localStorage.theme === "dark") {
                document.body.classList.toggle("dark");
                setCurrentTheme("dark");
            } else {
                setCurrentTheme("light");
            }
        } else {
            setCurrentTheme(prefersDarkMode.matches ? "dark" : "light");
            if (prefersDarkMode.matches) {
                document.body.classList.toggle("dark");
            }
        }
        // eslint-disable-next-line
    }, []);

    // CHANGE THEME
    const selectTheme = (newTheme) => {
        if (newTheme === "dark") {
            setCurrentTheme("dark");
            localStorage.setItem("theme", "dark");
        } else {
            setCurrentTheme("light");
            localStorage.setItem("theme", "light");
        }
    };

    // MATERIAL UI
    const darkTheme = createTheme({
        palette: {
            common: {
                black: "#000",
                white: "#fff",
            },
            type: "dark",
            primary: {
                light: "#e3f2fd",
                main: "#90caf9",
                dark: "#42a5f5",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            secondary: {
                light: "#f3e5f5",
                main: "#ce93d8",
                dark: "#ab47bc",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            error: {
                light: "#e57373",
                main: "#f44336",
                dark: "#d32f2f",
                contrastText: "#fff",
            },
            warning: {
                light: "#ffb74d",
                main: "#ffa726",
                dark: "#f57c00",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            info: {
                light: "#4fc3f7",
                main: "#29b6f6",
                dark: "#0288d1",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            success: {
                light: "#81c784",
                main: "#66bb6a",
                dark: "#388e3c",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            grey: {
                50: "#fafafa",
                100: "#f5f5f5",
                200: "#eeeeee",
                300: "#e0e0e0",
                400: "#bdbdbd",
                500: "#9e9e9e",
                600: "#757575",
                700: "#616161",
                800: "#424242",
                900: "#212121",
                A100: "#d5d5d5",
                A200: "#aaaaaa",
                A400: "#303030",
                A700: "#616161",
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            text: {
                primary: "#fff",
                secondary: "rgba(255,255,255,0.7)",
                disabled: "rgba(255,255,255,0.5)",
                icon: "rgba(255,255,255,0.5)",
                hint: "rgba(255, 255, 255, 0.38)",
            },
            divider: "rgba(255, 255, 255, 0.12)",
            background: {
                paper: "#121212",
                default: "#121212",
            },
            action: {
                active: "#fff",
                hover: "rgba(255, 255, 255, 0.08)",
                hoverOpacity: 0.08,
                selected: "rgba(255, 255, 255, 0.16)",
                selectedOpacity: 0.16,
                disabled: "rgba(255, 255, 255, 0.3)",
                disabledBackground: "rgba(255, 255, 255, 0.12)",
                disabledOpacity: 0.38,
                focus: "rgba(255, 255, 255, 0.12)",
                focusOpacity: 0.12,
                activatedOpacity: 0.24,
            },
        },
    });
    const lightTheme = createTheme({
        palette: {
            common: {
                black: "#000",
                white: "#fff",
            },
            type: "light",
            primary: {
                light: "#7986cb",
                main: "#3f51b5",
                dark: "#303f9f",
                contrastText: "#fff",
            },
            secondary: {
                light: "#ff4081",
                main: "#f50057",
                dark: "#c51162",
                contrastText: "#fff",
            },
            error: {
                light: "#e57373",
                main: "#f44336",
                dark: "#d32f2f",
                contrastText: "#fff",
            },
            warning: {
                light: "#ffb74d",
                main: "#ff9800",
                dark: "#f57c00",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            info: {
                light: "#64b5f6",
                main: "#2196f3",
                dark: "#1976d2",
                contrastText: "#fff",
            },
            success: {
                light: "#81c784",
                main: "#4caf50",
                dark: "#388e3c",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            grey: {
                50: "#fafafa",
                100: "#f5f5f5",
                200: "#eeeeee",
                300: "#e0e0e0",
                400: "#bdbdbd",
                500: "#9e9e9e",
                600: "#757575",
                700: "#616161",
                800: "#424242",
                900: "#212121",
                A100: "#d5d5d5",
                A200: "#aaaaaa",
                A400: "#303030",
                A700: "#616161",
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            text: {
                primary: "rgba(0,0,0,0.87)",
                secondary: "rgba(0,0,0,0.6)",
                disabled: "rgba(0,0,0,0.38)",
                hint: "rgba(0, 0, 0, 0.38)",
                icon: "rgba(0,0,0,0.12)",
            },
            divider: "rgba(0, 0, 0, 0.12)",
            background: {
                paper: "#fff",
                default: "#fafafa",
            },
            action: {
                active: "rgba(0, 0, 0, 0.54)",
                hover: "rgba(0, 0, 0, 0.04)",
                hoverOpacity: 0.04,
                selected: "rgba(0, 0, 0, 0.08)",
                selectedOpacity: 0.08,
                disabled: "rgba(0, 0, 0, 0.26)",
                disabledBackground: "rgba(0, 0, 0, 0.12)",
                disabledOpacity: 0.38,
                focus: "rgba(0, 0, 0, 0.12)",
                focusOpacity: 0.12,
                activatedOpacity: 0.12,
            },
        },
    });

    // HIGHCHARTS
    let darkCharts = {
        colors: [
            "#7cb5ec",
            "#909090",
            "#90ed7d",
            "#f7a35c",
            "#8085e9",
            "#f15c80",
            "#e4d354",
            "#2b908f",
            "#f45b5b",
            "#91e8e1",
            "#007414",
            "#E02424",
        ],
        chart: {
            // backgroundColor: {
            //     linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
            //     stops: [
            //         [0, "#2a2a2b"],
            //         [1, "#3e3e40"],
            //     ],
            // },
            backgroundColor: "rgba(0,0,0,0)",
            // style: {
            //     fontFamily: "'Unica One', sans-serif",
            // },
            plotBorderColor: "#606063",
        },
        title: {
            style: {
                color: "#f7f7f7",
                // textTransform: "uppercase",
                // fontSize: "20px",
            },
        },
        subtitle: {
            style: {
                color: "#f7f7f7",
                // textTransform: "uppercase",
            },
        },
        xAxis: {
            gridLineColor: "#707073",
            labels: {
                style: {
                    color: "#f7f7f7",
                },
            },
            // lineColor: "#707073",
            // minorGridLineColor: "#505053",
            // tickColor: "#707073",
            title: {
                style: {
                    color: "#f7f7f7",
                },
            },
        },
        yAxis: {
            gridLineColor: "#707073",
            labels: {
                style: {
                    color: "#f7f7f7",
                },
            },
            // lineColor: "#707073",
            // minorGridLineColor: "#505053",
            // tickColor: "#707073",
            // tickWidth: 1,
            title: {
                style: {
                    color: "#f7f7f7",
                },
            },
        },
        tooltip: {
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            style: {
                color: "#f7f7f7",
            },
            hideDelay: 50,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: "#f7f7f7",
                    // style: {
                    //     fontSize: "13px",
                    // },
                },
                marker: {
                    lineColor: "#333",
                },
            },
            boxplot: {
                fillColor: "#505053",
            },
            candlestick: {
                lineColor: "white",
            },
            errorbar: {
                color: "white",
            },
        }, // as SeriesTypePlotOptions,
        legend: {
            backgroundColor: "#464646",
            itemStyle: {
                color: "#E0E0E3",
            },
            itemHoverStyle: {
                color: "#FFF",
            },
            itemHiddenStyle: {
                color: "#606063",
            },
            title: {
                style: {
                    color: "#C0C0C0",
                },
            },
        },
        credits: {
            style: {
                color: "#666",
            },
        },
        labels: {
            style: {
                color: "#707073",
            },
        },

        drilldown: {
            activeAxisLabelStyle: {
                color: "#F0F0F3",
            },
            activeDataLabelStyle: {
                color: "#F0F0F3",
            },
        },

        navigation: {
            buttonOptions: {
                symbolStroke: "#DDDDDD",
                theme: {
                    fill: "#505053",
                },
                y: -5,
            },
            menuItemStyle: {
                padding: "5px",
                // color: '#303030'
            },
            // menuItemHoverStyle: {
            //     background: '#33bb00',
            //     color: '#FFFFFF'
            // }
        },

        // scroll charts
        rangeSelector: {
            buttonTheme: {
                fill: "#505053",
                stroke: "#000000",
                style: {
                    color: "#CCC",
                },
                states: {
                    hover: {
                        fill: "#707073",
                        stroke: "#000000",
                        style: {
                            color: "white",
                        },
                    },
                    select: {
                        fill: "#000003",
                        stroke: "#000000",
                        style: {
                            color: "white",
                        },
                    },
                },
            },
            inputBoxBorderColor: "#505053",
            inputStyle: {
                backgroundColor: "#333",
                color: "silver",
            },
            labelStyle: {
                color: "silver",
            },
        },

        navigator: {
            handles: {
                backgroundColor: "#666",
                borderColor: "#AAA",
            },
            outlineColor: "#CCC",
            maskFill: "rgba(255,255,255,0.1)",
            series: {
                color: "#7798BF",
                lineColor: "#A6C7ED",
            },
            xAxis: {
                gridLineColor: "#505053",
            },
        },

        scrollbar: {
            barBackgroundColor: "#808083",
            barBorderColor: "#808083",
            buttonArrowColor: "#CCC",
            buttonBackgroundColor: "#606063",
            buttonBorderColor: "#606063",
            rifleColor: "#FFF",
            trackBackgroundColor: "#404043",
            trackBorderColor: "#404043",
        },
        exporting: {
            menuItemDefinitions: {
                // Custom definition
            },
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "downloadPNG"],
                },
            },
        },
    };

    let lightCharts = {
        colors: [
            "#7cb5ec",
            "#434348",
            "#90ed7d",
            "#f7a35c",
            "#8085e9",
            "#f15c80",
            "#e4d354",
            "#2b908f",
            "#f45b5b",
            "#91e8e1",
            "#007414",
            "#E02424",
        ],
        chart: {
            backgroundColor: "#fff",
            // style: {
            //     fontFamily: "'Unica One', sans-serif",
            // },
            plotBorderColor: "#fff",
        },
        title: {
            style: {
                color: "#0d0d0d",
                // textTransform: "uppercase",
                // fontSize: "20px",
            },
        },
        subtitle: {
            style: {
                color: "#0d0d0d",
                // textTransform: "uppercase",
            },
        },
        xAxis: {
            gridLineColor: "#b3b3b3",
            labels: {
                style: {
                    color: "#0d0d0d",
                },
            },
            // lineColor: "#707073",
            // minorGridLineColor: "#505053",
            // tickColor: "#707073",
            title: {
                style: {
                    color: "#0d0d0d",
                },
            },
        },
        yAxis: {
            gridLineColor: "#b3b3b3",
            labels: {
                style: {
                    color: "#0d0d0d",
                },
            },
            // lineColor: "#707073",
            // minorGridLineColor: "#505053",
            // tickColor: "#707073",
            // tickWidth: 1,
            title: {
                style: {
                    color: "#0d0d0d",
                },
            },
        },
        tooltip: {
            backgroundColor: "#f5f5f7",
            style: {
                color: "#0d0d0d",
            },
            hideDelay: 50,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: "#F0F0F3",
                    // style: {
                    //     fontSize: "13px",
                    // },
                },
                marker: {
                    lineColor: "#333",
                },
            },
            boxplot: {
                fillColor: "#505053",
            },
            candlestick: {
                lineColor: "white",
            },
            errorbar: {
                color: "white",
            },
        }, // as SeriesTypePlotOptions,
        legend: {
            backgroundColor: "rgba(247, 247, 247, 0.4)",
            itemStyle: {
                color: "#0d0d0d",
            },
            itemHoverStyle: {
                color: "#FFF",
            },
            itemHiddenStyle: {
                color: "#606063",
            },
            title: {
                style: {
                    color: "#0d0d0d",
                },
            },
        },
        credits: {
            style: {
                color: "#666",
            },
        },
        labels: {
            style: {
                color: "#707073",
            },
        },

        drilldown: {
            activeAxisLabelStyle: {
                color: "#F0F0F3",
            },
            activeDataLabelStyle: {
                color: "#F0F0F3",
            },
        },

        navigation: {
            buttonOptions: {
                symbolStroke: "#DDDDDD",
                theme: {
                    fill: "#505053",
                },
                y: -5,
            },
            menuItemStyle: {
                padding: "5px",
                // color: '#303030'
            },
            // menuItemHoverStyle: {
            //     background: '#33bb00',
            //     color: '#FFFFFF'
            // }
        },

        // scroll charts
        rangeSelector: {
            buttonTheme: {
                fill: "#505053",
                stroke: "#000000",
                style: {
                    color: "#CCC",
                },
                states: {
                    hover: {
                        fill: "#707073",
                        stroke: "#000000",
                        style: {
                            color: "white",
                        },
                    },
                    select: {
                        fill: "#000003",
                        stroke: "#000000",
                        style: {
                            color: "white",
                        },
                    },
                },
            },
            inputBoxBorderColor: "#505053",
            inputStyle: {
                backgroundColor: "#333",
                color: "silver",
            },
            labelStyle: {
                color: "silver",
            },
        },

        navigator: {
            handles: {
                backgroundColor: "#666",
                borderColor: "#AAA",
            },
            outlineColor: "#CCC",
            maskFill: "rgba(255,255,255,0.1)",
            series: {
                color: "#7798BF",
                lineColor: "#A6C7ED",
            },
            xAxis: {
                gridLineColor: "#505053",
            },
        },

        scrollbar: {
            barBackgroundColor: "#808083",
            barBorderColor: "#808083",
            buttonArrowColor: "#CCC",
            buttonBackgroundColor: "#606063",
            buttonBorderColor: "#606063",
            rifleColor: "#FFF",
            trackBackgroundColor: "#404043",
            trackBorderColor: "#404043",
        },
        exporting: {
            menuItemDefinitions: {
                // Custom definition
            },
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "downloadPNG"],
                },
            },
        },
    };

    let currentThemeObject = lightCharts;

    if (currentTheme === "light") {
        currentThemeObject = lightCharts;
        Highcharts.setOptions(lightCharts);
    } else if (currentTheme === "dark") {
        currentThemeObject = darkCharts;
        Highcharts.setOptions(darkCharts);
    }

    return (
        <Context_Theme.Provider
            value={{ currentTheme, currentThemeObject, selectTheme }}
        >
            <ThemeProvider
                theme={currentTheme === "dark" ? darkTheme : lightTheme}
            >
                {props.children}
            </ThemeProvider>
        </Context_Theme.Provider>
    );
};

export default ThemeContext;
