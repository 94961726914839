import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// HIGHCHARTS
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import fullscreen from "highcharts/modules/full-screen";

// COMPONENTS
import AdjustYAxisRange from "./AdjustYAxisRange";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { numberFormatter } from "../../../../actions/generalActions";
exporting(Highcharts);
exportData(Highcharts);
fullscreen(Highcharts);

const RevenueAnnual = ({
    company: { company },
    selected_date_range,
    ticker,
    allDates,
    whole_statement,
    whole_statement_object,
    period,
}) => {
    const useintl = useIntl();
    const component_id = "revenue_annual";
    const axis_1_description = "Amount in USD";
    const axis_2_description = "Margin in %";

    let required_axis = {
        yAxis_0: true,
        yAxis_1: true,
        yAxis_2: false,
        yAxis_3: false,
    };

    const specific_styling = {
        revenue: {
            id: 0,
            name: useintl.formatMessage({
                id: `metrics.revenue`,
            }),
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[0])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[0])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "revenue, sales",
            per_share_possible: true,
            relative_change_possible: true,
        },
        gross_profit: {
            id: 1,
            name: useintl.formatMessage({
                id: `metrics.gross_profit`,
            }),
            type: "area",
            color: Highcharts.getOptions().colors[1],
            yAxis: 0,
            selected: false,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            filter_keywords: "profit, gross",
            per_share_possible: true,
        },
        net_income: {
            id: 2,
            name: useintl.formatMessage({
                id: `metrics.net_income`,
            }),
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[2],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            per_share_possible: true,
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "net income, net, income, earnings",
        },
        ebitda: {
            id: 3,
            name: useintl.formatMessage({
                id: `metrics.ebitda`,
            }),
            type: "area",
            color: Highcharts.getOptions().colors[7],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[7])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[7])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            selected: false,
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords:
                "EBIT, ebit, earnings before tax and interest, earnings before interest and tax",
            per_share_possible: true,
        },
    };

    // FUNCTION TO PROVIDE THE CHARTS' SERIES, PER SHARE, CHANGE, STH ELSE
    const yAxis0Metrics = ["Revenue", "Gross Profit", "EBITDA", "Net Income"];
    const yAxis1Metrics = ["Gross Profit Margin", "Net Income Margin"];
    const plottedMetrics = ["revenue", "gross_profit", "ebitda", "net_income"];

    const changeChartOption = () => {
        // GET REQUESTED PROPERTIES AS ARRAYS
        let properties_object = { xAxis: [] };
        plottedMetrics.map((property) => {
            properties_object[property] = [];
        });

        if (period === "yearly") {
            Object.entries(whole_statement["yearly"]).map((filing) => {
                plottedMetrics.map((property) => {
                    properties_object[property] = [
                        ...properties_object[property],
                        Number(
                            filing[1][
                                whole_statement_object[property].eodproperty
                            ]
                        ),
                    ];
                });
            });
        } else {
            Object.entries(whole_statement["quarterly"]).map((filing) => {
                plottedMetrics.map((property) => {
                    properties_object[property] = [
                        ...properties_object[property],
                        Number(
                            filing[1][
                                whole_statement_object[property].eodproperty
                            ]
                        ),
                    ];
                });
            });
        }

        // IF TTM WITH QUARTERLY AND THEN CALCULATE
        if (period === "ttm") {
            plottedMetrics.map((property) => {
                let ttm_property = [];
                properties_object[property].map((quarter, idx) => {
                    if (idx > 2) {
                        ttm_property.push(
                            quarter +
                                properties_object[property][idx - 1] +
                                properties_object[property][idx - 2] +
                                properties_object[property][idx - 3]
                        );
                    }
                });

                properties_object[property] = ttm_property;
            });
        }

        // CREATE SERIES ARRAY FOR CHART
        let new_chart_metrics = [];
        plottedMetrics.map((property, idx) => {
            new_chart_metrics = [
                ...new_chart_metrics,
                {
                    ...specific_styling[property],
                    ...whole_statement_object[property],
                    data: properties_object[property].slice(
                        selected_date_range[0],
                        selected_date_range[1] + 1
                    ),
                },
            ];
        });

        return new_chart_metrics;
    };
    const [chartAlignTicks, setChartAlignTicks] = React.useState(true);

    useEffect(() => {
        handleResetAxis();
        // eslint-disable-next-line
    }, [ticker]);

    const [minY0, setminY0] = React.useState(null);
    const [maxY0, setmaxY0] = React.useState(null);
    const changeMinY0 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value.value === "" || isNaN(Number(new_value))) {
            setminY0(null);
            setChartAlignTicks(false);
        } else {
            setminY0(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY0 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY0(null);
            setChartAlignTicks(false);
        } else {
            setmaxY0(Number(new_value));
            setChartAlignTicks(false);
        }
    };

    const [minY1, setminY1] = React.useState(null);
    const [maxY1, setmaxY1] = React.useState(null);
    const changeMinY1 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setminY1(null);
            setChartAlignTicks(false);
        } else {
            setminY1(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY1 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY1(null);
            setChartAlignTicks(false);
        } else {
            setmaxY1(Number(new_value));
            setChartAlignTicks(false);
        }
    };

    const handleResetAxis = () => {
        setminY0(null);
        setmaxY0(null);
        setminY1(null);
        setmaxY1(null);
        try {
            document.getElementById(`${component_id}_minInputY0_field`).reset();
            document.getElementById(`${component_id}_maxInputY0_field`).reset();
            document.getElementById(`${component_id}_minInputY1_field`).reset();
            document.getElementById(`${component_id}_maxInputY1_field`).reset();
        } catch (err) {}
        setChartAlignTicks(true);
    };

    let chart_title_period =
        period === "yearly"
            ? useintl.formatMessage({
                  id: `general.yearly`,
              })
            : period === "quarterly"
            ? useintl.formatMessage({
                  id: `general.quarterly`,
              })
            : useintl.formatMessage({
                  id: `general.ttm_short`,
              });

    let chartOptions = {
        chart: {
            //renderTo: 'umsatzchart',
            zoomType: "xy",
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 800,
                    },
                    chartOptions: {
                        yAxis: [
                            {
                                // Primary yAxis
                                labels: {
                                    formatter: function () {
                                        return numberFormatter(this.value);
                                    },
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[1],
                                    },
                                },
                                title: {
                                    enabled: false,
                                    text: `Amount in ${company.currency}`,
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[1],
                                    },
                                },
                                min: minY0,
                                max: maxY0,
                                // tickInterval: (maxY0 - minY0) / 10,
                                alignTicks: chartAlignTicks,
                            },
                            {
                                // Secondary yAxis
                                title: {
                                    enabled: false,
                                    text: "Margin in %",
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[0],
                                    },
                                },
                                labels: {
                                    formatter: function () {
                                        return (
                                            Number(this.value).toFixed(2) + "%"
                                        );
                                    },
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[0],
                                    },
                                },
                                opposite: true,
                                min: minY1,
                                max: maxY1,
                                // tickInterval: (maxY1 - minY1) / 10,
                                alignTicks: chartAlignTicks,
                            },
                        ],
                    },
                },
                {
                    condition: {
                        minWidth: 801,
                    },
                    chartOptions: {
                        yAxis: [
                            {
                                // Primary yAxis
                                labels: {
                                    formatter: function () {
                                        return numberFormatter(this.value);
                                    },
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[1],
                                    },
                                },
                                title: {
                                    enabled: true,
                                    text: `Amount in ${company.currency}`,
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[1],
                                    },
                                },
                                min: minY0,
                                max: maxY0,
                                // tickInterval: (maxY0 - minY0) / 10,
                                alignTicks: chartAlignTicks,
                            },
                            {
                                // Secondary yAxis
                                title: {
                                    enabled: true,
                                    text: "Margin in %",
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[0],
                                    },
                                },
                                labels: {
                                    formatter: function () {
                                        return (
                                            Number(this.value).toFixed(2) + "%"
                                        );
                                    },
                                    style: {
                                        color: Highcharts.getOptions()
                                            .colors[0],
                                    },
                                },
                                opposite: true,
                                min: minY1,
                                max: maxY1,
                                // tickInterval: (maxY1 - minY1) / 10,
                                alignTicks: chartAlignTicks,
                            },
                        ],
                    },
                },
            ],
        },
        title: {
            text: `Revenue and Income - ${chart_title_period}`,
        },
        subtitle: {
            text: "",
        },
        credits: {
            text: "StocksOnView",
            href: "#",
        },
        // exporting: {
        //     enabled: true,
        //     buttons: {
        //       menuItems: [
        //         "viewFullscreen",

        //       ]
        //     }
        //   },
        xAxis: [
            {
                // showResetZoom: true,
                categories: allDates.slice(
                    selected_date_range[0],
                    selected_date_range[1] + 1
                ),
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    formatter: function () {
                        return numberFormatter(this.value);
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: `Amount in ${company.currency}`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                min: minY0,
                max: maxY0,
                // tickInterval: (maxY0 - minY0) / 10,
                alignTicks: chartAlignTicks,
            },
            {
                // Secondary yAxis
                title: {
                    text: "Margin in %",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                labels: {
                    formatter: function () {
                        return Number(this.value).toFixed(2) + "%";
                    },
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                opposite: true,
                min: minY1,
                max: maxY1,
                // tickInterval: (maxY1 - minY1) / 10,
                alignTicks: chartAlignTicks,
            },
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                var points = this.points;
                var pointsLength = points.length;
                var tooltipMarkup = pointsLength
                    ? '<span style="font-size: 10px">' +
                      points[0].key +
                      "</span><br/>"
                    : "";
                var index;
                var value;

                for (index = 0; index < pointsLength; index += 1) {
                    if (yAxis0Metrics.includes(points[index].series.name)) {
                        value = numberFormatter(points[index].y);

                        tooltipMarkup +=
                            '<span style="color:' +
                            points[index].series.color +
                            '">\u25CF</span> ' +
                            points[index].series.name +
                            ": <b>" +
                            value +
                            ` ${company.currency}</b><br/>`;
                    } else {
                        value = points[index].y;

                        tooltipMarkup +=
                            '<span style="color:' +
                            points[index].series.color +
                            '">\u25CF</span> ' +
                            points[index].series.name +
                            ": <b>" +
                            value +
                            " %</b><br/>";
                    }
                }
                return tooltipMarkup;
            },
        },
        legend: {
            align: "center",
            verticalAlign: "bottom",
            x: 0,
            y: 0,
            backgroundColor: "rgba(255,255,255,0)",
        },
        series: changeChartOption(),
    };

    return (
        <div className='mb_10px width_100pr'>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <AdjustYAxisRange
                component_id={component_id}
                handleResetAxis={handleResetAxis}
                minY0={minY0 ? minY0 : null}
                changeMinY0={changeMinY0}
                maxY0={maxY0 ? maxY0 : null}
                changeMaxY0={changeMaxY0}
                minY1={minY1 ? minY1 : null}
                changeMinY1={changeMinY1}
                maxY1={maxY1 ? maxY1 : null}
                changeMaxY1={changeMaxY1}
                axis_1_description={axis_1_description}
                axis_2_description={axis_2_description}
                required_axis={required_axis}
            />
        </div>
    );
};

RevenueAnnual.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(RevenueAnnual);
