import React from "react";
import { useIntl } from "react-intl";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    m -= m > 4 ? 4 : 0;
    var y = d.getFullYear() + (m == 1 ? 1 : 0);
    return `${y}q${m}`;
}

const FundamentalsTable = ({
    period,
    whole_statement,
    income_statement_object,
    balance_sheet_object,
    cash_flow_statement_object,
    selected_date_range,
    plottedMetrics,
    handleAddPlottedMetric,
    handleRemovePlottedMetric,
}) => {
    let table_period = period === "yearly" ? "yearly" : "quarterly";
    let not_in_millions = [
        "gross_profit_ratio",
        "ebitda_ratio",
        "operating_income_ratio",
        "income_before_tax_ratio",
        "net_income_ratio",
        "debt_ratio",
        "free_cash_flow_ratio",
    ];

    return (
        <div className='ps_20px_0px'>
            <div className='width_100pr overflow_x_auto sticky_table max_table_height direction_rtl'>
                <table className='direction_ltr'>
                    <thead className='sticky_table_thead background_color_2'>
                        <tr className='sticky_table_thead_tr background_color_2 text_color_1'>
                            <th
                                className='padding_15_5px min_width_200px background_color_2 vertical_align_top'
                                style={{
                                    zIndex: "10",
                                }}
                            >
                                in mio. {whole_statement.currency_symbol},{" "}
                                {period === "yearly" ? "Yearly" : "Quarterly"}
                            </th>
                            {Object.keys(whole_statement[table_period]).map(
                                (time, idx) => {
                                    return (period === "ttm" &&
                                        idx >= selected_date_range[0] + 3 &&
                                        idx <= selected_date_range[1] + 3) ||
                                        (idx >= selected_date_range[0] &&
                                            idx <= selected_date_range[1]) ? (
                                        <th className='text_right padding_15_5px'>
                                            {period === "yearly"
                                                ? time.substring(0, 4)
                                                : getQuarter(new Date(time))}
                                        </th>
                                    ) : null;
                                }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='sticky_table_tbody_tr background_color_3_hover_background_color_4 width_100pr'>
                            <th
                                className='padding_15_5px'
                                style={{
                                    zIndex: "9",
                                }}
                            >
                                Income Statement
                            </th>
                            <td colSpan={1000}></td>
                        </tr>
                        {Object.entries(income_statement_object).map(
                            (eodproperty) => {
                                return (
                                    <tr
                                        className={
                                            plottedMetrics.includes(
                                                eodproperty[0]
                                            )
                                                ? "sticky_table_tbody_tr background_color_3_hover_background_color_4"
                                                : "sticky_table_tbody_tr background_color_1_hover_background_color_3"
                                        }
                                        onClick={() => {
                                            if (
                                                plottedMetrics.includes(
                                                    eodproperty[0]
                                                )
                                            ) {
                                                handleRemovePlottedMetric(
                                                    eodproperty[0]
                                                );
                                            } else {
                                                handleAddPlottedMetric(
                                                    eodproperty[0]
                                                );
                                            }
                                        }}
                                    >
                                        <th
                                            className='sticky_table_tbody_tr'
                                            style={{
                                                zIndex: "9",
                                            }}
                                        >
                                            {eodproperty[1].style === 1 ? (
                                                <div className='width_100pr'>
                                                    <div className='padding_5px background_color_3_hover_background_color_4 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='width_100pr'>
                                                    <div className='padding_5px background_color_1_hover_background_color_3 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                        {Object.values(
                                            whole_statement[table_period]
                                        ).map((yearly_object, idx) => {
                                            return (period === "ttm" &&
                                                idx >=
                                                    selected_date_range[0] +
                                                        3 &&
                                                idx <=
                                                    selected_date_range[1] +
                                                        3) ||
                                                (idx >=
                                                    selected_date_range[0] &&
                                                    idx <=
                                                        selected_date_range[1]) ? (
                                                <td className='text_right padding_15_5px'>
                                                    {yearly_object[
                                                        eodproperty[1]
                                                            .eodproperty
                                                    ] &&
                                                    !isNaN(
                                                        Number(
                                                            yearly_object[
                                                                eodproperty[1]
                                                                    .eodproperty
                                                            ]
                                                        )
                                                    )
                                                        ? Number(
                                                              yearly_object[
                                                                  eodproperty[1]
                                                                      .eodproperty
                                                              ]
                                                          ) < 0
                                                            ? "(" +
                                                              (-1 *
                                                                  Number(
                                                                      yearly_object[
                                                                          eodproperty[1]
                                                                              .eodproperty
                                                                      ]
                                                                  )) /
                                                                  (not_in_millions.includes(
                                                                      eodproperty[0]
                                                                  )
                                                                      ? 1
                                                                      : 1000000) +
                                                              ")"
                                                            : Number(
                                                                  yearly_object[
                                                                      eodproperty[1]
                                                                          .eodproperty
                                                                  ]
                                                              ) /
                                                              (not_in_millions.includes(
                                                                  eodproperty[0]
                                                              )
                                                                  ? 1
                                                                  : 1000000)
                                                        : "-"}
                                                </td>
                                            ) : null;
                                        })}
                                    </tr>
                                );
                            }
                        )}
                        <tr className='sticky_table_tbody_tr background_color_3_hover_background_color_4 width_100pr '>
                            <th
                                className='padding_15_5px'
                                style={{
                                    zIndex: "9",
                                }}
                            >
                                Balance Sheet
                            </th>
                            <td colSpan={1000}></td>
                        </tr>
                        {Object.entries(balance_sheet_object).map(
                            (eodproperty) => {
                                return (
                                    <tr
                                        className={
                                            plottedMetrics.includes(
                                                eodproperty[0]
                                            )
                                                ? "sticky_table_tbody_tr background_color_3_hover_background_color_4"
                                                : "sticky_table_tbody_tr background_color_1_hover_background_color_3"
                                        }
                                        onClick={() => {
                                            if (
                                                plottedMetrics.includes(
                                                    eodproperty[0]
                                                )
                                            ) {
                                                handleRemovePlottedMetric(
                                                    eodproperty[0]
                                                );
                                            } else {
                                                handleAddPlottedMetric(
                                                    eodproperty[0]
                                                );
                                            }
                                        }}
                                    >
                                        <th
                                            className='sticky_table_tbody_tr'
                                            style={{
                                                zIndex: "9",
                                            }}
                                        >
                                            {eodproperty[1].style === 1 ? (
                                                <div className='width_100pr'>
                                                    <div className='width_100pr padding_5px background_color_3_hover_background_color_4 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='width_100pr'>
                                                    <div className='width_100pr padding_5px background_color_1_hover_background_color_3 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                        {Object.values(
                                            whole_statement[table_period]
                                        ).map((yearly_object, idx) => {
                                            return (period === "ttm" &&
                                                idx >=
                                                    selected_date_range[0] +
                                                        3 &&
                                                idx <=
                                                    selected_date_range[1] +
                                                        3) ||
                                                (idx >=
                                                    selected_date_range[0] &&
                                                    idx <=
                                                        selected_date_range[1]) ? (
                                                <td className='text_right padding_15_5px'>
                                                    {yearly_object[
                                                        eodproperty[1]
                                                            .eodproperty
                                                    ] &&
                                                    !isNaN(
                                                        Number(
                                                            yearly_object[
                                                                eodproperty[1]
                                                                    .eodproperty
                                                            ]
                                                        )
                                                    )
                                                        ? Number(
                                                              yearly_object[
                                                                  eodproperty[1]
                                                                      .eodproperty
                                                              ]
                                                          ) < 0
                                                            ? "(" +
                                                              (-1 *
                                                                  Number(
                                                                      yearly_object[
                                                                          eodproperty[1]
                                                                              .eodproperty
                                                                      ]
                                                                  )) /
                                                                  (not_in_millions.includes(
                                                                      eodproperty[0]
                                                                  )
                                                                      ? 1
                                                                      : 1000000) +
                                                              ")"
                                                            : Number(
                                                                  yearly_object[
                                                                      eodproperty[1]
                                                                          .eodproperty
                                                                  ]
                                                              ) /
                                                              (not_in_millions.includes(
                                                                  eodproperty[0]
                                                              )
                                                                  ? 1
                                                                  : 1000000)
                                                        : "-"}
                                                </td>
                                            ) : null;
                                        })}
                                    </tr>
                                );
                            }
                        )}
                        <tr className='sticky_table_tbody_tr background_color_3_hover_background_color_4 width_100pr'>
                            <th
                                className='padding_15_5px'
                                style={{
                                    zIndex: "9",
                                }}
                            >
                                Cash Flow Statement
                            </th>
                            <td colSpan={1000}></td>
                        </tr>
                        {Object.entries(cash_flow_statement_object).map(
                            (eodproperty) => {
                                return (
                                    <tr
                                        className={
                                            plottedMetrics.includes(
                                                eodproperty[0]
                                            )
                                                ? "sticky_table_tbody_tr background_color_3_hover_background_color_4"
                                                : "sticky_table_tbody_tr background_color_1_hover_background_color_3"
                                        }
                                        onClick={() => {
                                            if (
                                                plottedMetrics.includes(
                                                    eodproperty[0]
                                                )
                                            ) {
                                                handleRemovePlottedMetric(
                                                    eodproperty[0]
                                                );
                                            } else {
                                                handleAddPlottedMetric(
                                                    eodproperty[0]
                                                );
                                            }
                                        }}
                                    >
                                        <th
                                            className='sticky_table_tbody_tr'
                                            style={{
                                                zIndex: "9",
                                            }}
                                        >
                                            {eodproperty[1].style === 1 ? (
                                                <div className='width_100pr'>
                                                    <div className='padding_5px background_color_3_hover_background_color_4 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='width_100pr'>
                                                    <div className='padding_5px background_color_1_hover_background_color_3 border_radius_10px'>
                                                        {eodproperty[1].name}
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                        {Object.values(
                                            whole_statement[table_period]
                                        ).map((yearly_object, idx) => {
                                            return (period === "ttm" &&
                                                idx >=
                                                    selected_date_range[0] +
                                                        3 &&
                                                idx <=
                                                    selected_date_range[1] +
                                                        3) ||
                                                (idx >=
                                                    selected_date_range[0] &&
                                                    idx <=
                                                        selected_date_range[1]) ? (
                                                <td className='text_right padding_15_5px'>
                                                    {yearly_object[
                                                        eodproperty[1]
                                                            .eodproperty
                                                    ] &&
                                                    !isNaN(
                                                        Number(
                                                            yearly_object[
                                                                eodproperty[1]
                                                                    .eodproperty
                                                            ]
                                                        )
                                                    )
                                                        ? Number(
                                                              yearly_object[
                                                                  eodproperty[1]
                                                                      .eodproperty
                                                              ]
                                                          ) < 0
                                                            ? "(" +
                                                              (-1 *
                                                                  Number(
                                                                      yearly_object[
                                                                          eodproperty[1]
                                                                              .eodproperty
                                                                      ]
                                                                  )) /
                                                                  (not_in_millions.includes(
                                                                      eodproperty[0]
                                                                  )
                                                                      ? 1
                                                                      : 1000000) +
                                                              ")"
                                                            : Number(
                                                                  yearly_object[
                                                                      eodproperty[1]
                                                                          .eodproperty
                                                                  ]
                                                              ) /
                                                              (not_in_millions.includes(
                                                                  eodproperty[0]
                                                              )
                                                                  ? 1
                                                                  : 1000000)
                                                        : "-"}
                                                </td>
                                            ) : null;
                                        })}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

FundamentalsTable.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(FundamentalsTable);
