import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

// ICONS
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

// COMPONENTS
import Overview from "./Overview";
import PortfolioEstimatedPerformanceChart from "./charts/PortfolioEstimatedPerformanceChart";
import PortfolioAssetsTable from "./PortfolioAssetsTable";
import Preloader from "../../layout/Preloader";
import FeedbackIcon from "../elements/FeedbackIcon";
import CreatePortfolio from "../elements/CreatePortfolio";

// MUI
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";

// HIGHCHARTS
import Highcharts from "highcharts/highstock";

// ACTIONS
import {
    addCreatorPositionValues,
    setPortfolio,
    setCurrentPortfolio,
    addCreatorToManager,
} from "../../../actions/portfolioActions";
import { setAlert } from "../../../actions/alertActions";

// CONTEXT
import { Context_Theme } from "../../../context/ThemeContext";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PortfolioCreatorPortfolio = ({
    portfolio,
    user,
    setPortfolio,
    addCreatorToManager,
    setAlert,
}) => {
    const useintl = useIntl();
    let new_creator_allocation = null;
    let total_creator_portfolio_value;

    if (portfolio.current_creator_companies !== null) {
        new_creator_allocation = portfolio.current_creator_allocation;
        portfolio.current_creator_companies.map((company) => {
            new_creator_allocation[company.ticker].value =
                new_creator_allocation[company.ticker].count *
                company.stock_prices[company.stock_prices.length - 1][4];
        });

        total_creator_portfolio_value = 0;
        Object.keys(new_creator_allocation).map(function (key, index) {
            if (new_creator_allocation[key].status === true) {
                total_creator_portfolio_value +=
                    new_creator_allocation[key].value;
            }
        });
    }

    let portfolios = [];
    let portfolios_count = 0;
    const fill_portfolios = () => {
        portfolio.portfolios.map((portfolio) => {
            if (portfolio.user_id === user.id) {
                portfolios.push({
                    value: portfolio.id,
                    name: portfolio.name,
                    icon: portfolio.icon,
                });
            }
        });
        portfolios_count += 1;
    };
    if (portfolios_count < 1) {
        fill_portfolios();
    }

    // THEME
    const context_theme = useContext(Context_Theme);
    Highcharts.setOptions(context_theme.currentThemeObject);

    /*Multiple Select Dropdown*/
    const [portfolioState, setPortfolioState] = React.useState(null);

    const handlePortfolioState = (event) => {
        setPortfolioState(event.target.value);
    };

    /*Create Portfolio Dialog Field*/
    const [openCreatePortfolio, setOpenCreatePortfolio] = React.useState(false);
    const handleOpenCreatePortfolio = () => {
        setOpenCreatePortfolio(true);
    };
    const handleClickCloseCreatePortfolio = () => {
        setOpenCreatePortfolio(false);
    };

    /*Add to PortfolioManager Dialog Field*/
    const [openManager, setOpenManager] = React.useState(false);
    const handleClickOpenManager = () => {
        setOpenManager(true);
    };
    const handleCloseManager = () => {
        setOpenManager(false);
    };
    const handleAddToPortfolioManager = (selected) => {
        console.log(selected);
        if (selected) {
            portfolio.portfolios.map((current) =>
                current.id === selected
                    ? addCreatorToManager(portfolio, current)
                    : null
            );
            handleCloseManager();
        } else {
            setAlert(
                "You need to select a portfolio to transfer this creator.",
                "warning"
            );
        }
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "portfolio.creator.created_portfolio.header",
        })}`,
        location_index: 1,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //         id: "sidemenu.portfolio_creator",
    //     })} - ${useintl.formatMessage({
    //         id: "portfolio.creator.created_portfolio.header",
    //     })}`,
    //     location_index: 1,
    // };

    return (
        <div>
            {portfolio.current_creator_companies === null &&
            new_creator_allocation === null ? (
                <Preloader />
            ) : (
                <div>
                    <FeedbackIcon feedbackInfo={feedbackInfo} />

                    <div className='flex center background_color_1 text_2 text_color_1'>
                        <div className='mb_15px mt_15px ml_10px mr_10px'>
                            <MdIcons.MdWarningAmber className='mr_10px mt_5px' />
                            The portfolio created in the portfolio creator is
                            not an investment advice and serves only as an
                            additional approach to search the stock market for
                            companies.
                        </div>
                    </div>
                    {/* <div className='divider_no_margin'></div> */}
                    <div className='flex center background_color_1'>
                        <div
                            className='button_blue color_white width_280px mb_15px mt_15px'
                            onClick={handleClickOpenManager}
                        >
                            <AiIcons.AiOutlineAppstoreAdd className='screener_icon_reset text_2 ml_5px' />{" "}
                            <FormattedMessage
                                id='portfolio.creator.created_portfolio.add_to_manager'
                                defaultMessage='Add to Portfoliomanager'
                            />
                        </div>
                    </div>

                    {/* Add to Manager Modal */}
                    <Dialog
                        open={openManager}
                        onClose={handleCloseManager}
                        maxWidth={600}
                    >
                        <div className='background_color_1 text_color_1'>
                            <div className='flex space_between title_5 margin_15px'>
                                <AiIcons.AiOutlineAppstoreAdd className='text_1 width_40px ml_5px mr_10px' />
                                <div className='mr_10px'>{`Transfer the companies to a Portfolio`}</div>
                                <div
                                    className='cursor_pointer title_5 flex center ml_25px mt_5px'
                                    onClick={handleCloseManager}
                                >
                                    <AiIcons.AiOutlineClose />
                                </div>
                            </div>

                            {user.status === 80 ? (
                                <div className='padding_20px'>
                                    You need to create an account to save this
                                    portfolio.
                                </div>
                            ) : (
                                <div>
                                    <div className='divider'></div>

                                    <div className='ml_10px mr_10px flex space_between wrap'>
                                        <div className='width_200px mt_15px'>
                                            <FormControl
                                                size='small'
                                                sx={{ minWidth: 150 }}
                                            >
                                                <InputLabel
                                                    id='demo-mutiple-checkbox-label'
                                                    className='width_200px'
                                                >
                                                    Select Existing Portfolio
                                                </InputLabel>
                                                <Select
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    label='Select Existing Portfolio'
                                                    value={portfolioState}
                                                    onChange={
                                                        handlePortfolioState
                                                    }
                                                    className='width_200px background_color_3'
                                                >
                                                    <MenuItem value=''>
                                                        {" "}
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {portfolios.map(
                                                        (portfolio) => (
                                                            <MenuItem
                                                                value={
                                                                    portfolio.value
                                                                }
                                                            >
                                                                {portfolio.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div
                                            className='button_blue text_color_1 width_200px mt_15px'
                                            onClick={() => {
                                                handleOpenCreatePortfolio();
                                            }}
                                        >
                                            <div className='ml_10px text_3'>
                                                Create new Portfolio
                                            </div>
                                            <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                                        </div>
                                    </div>

                                    <div className='divider'></div>

                                    <div className='flex right'>
                                        <div
                                            className='button_blue color_white width_80px margin_15px'
                                            onClick={() => {
                                                handleAddToPortfolioManager(
                                                    portfolioState
                                                );
                                            }}
                                        >
                                            Add
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Dialog>

                    {/* CREATE NEW PORTFOLIO MODAL */}
                    <Dialog
                        open={openCreatePortfolio}
                        onClose={handleClickCloseCreatePortfolio}
                        maxWidth={false}
                    >
                        <CreatePortfolio
                            closeModal={handleClickCloseCreatePortfolio}
                        />
                    </Dialog>

                    {/* <div className='divider_no_margin'></div> */}
                    <Overview
                        portfolio_value={total_creator_portfolio_value}
                        company_list={portfolio.current_creator_companies}
                        company_allocation={
                            portfolio.current_creator_allocation
                        }
                        preferences={portfolio.in_creation_preferences}
                        performance={portfolio.current_creator_performance}
                        requiredStatus={1}
                    />
                    {/* <PortfolioPerformanceChart
                        company_creator_performance={
                            portfolio.current_creator_performance
                        }
                        new_creator_allocation={new_creator_allocation}
                        requiredStatus={1}
                    /> */}
                    <PortfolioEstimatedPerformanceChart
                        portfolio_performance={
                            portfolio.current_creator_performance
                        }
                        portfolio_allocation={new_creator_allocation}
                        assets_array={portfolio.current_creator_companies}
                        requiredStatus={1}
                    />
                    <PortfolioAssetsTable
                        portfolio_value={total_creator_portfolio_value}
                        company_list={portfolio.current_creator_companies}
                        company_allocation={
                            portfolio.current_creator_allocation
                        }
                        requiredStatus={1}
                    />
                </div>
            )}
        </div>
    );
};

PortfolioCreatorPortfolio.propTypes = {
    portfolio: PropTypes.object.isRequired,
    addCreatorPositionValues: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setPortfolio: PropTypes.func.isRequired,
    setCurrentPortfolio: PropTypes.func.isRequired,
    addCreatorToManager: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    addCreatorPositionValues,
    setPortfolio,
    setCurrentPortfolio,
    addCreatorToManager,
    setAlert,
})(PortfolioCreatorPortfolio);
