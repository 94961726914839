import {
    GET_COMPANIES,
    GET_COMPANIES_PAGINATION,
    UPDATE_SCREENER_VALUE,
    UPDATE_SINGLE_SLIDER_VALUE,
    UPDATE_SINGLE_SLIDER_VALUE_ONLY,
    UPDATE_SINGLE_OPTIONS_VALUE,
    UPDATE_SINGLE_OPTION_SORT_BY,
    UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY,
    UPDATE_SEARCH_TEXT_SCREENER,
    UPDATE_SEARCH_OPTION_SCREENER,
    UPDATE_SEARCH_COUNTRIES,
    UPDATE_SEARCH_INDUSTRIES,
    UPDATE_CHECKED_INDUSTRIES,
    CLEAR_SCREENER_VALUES,
    SET_DISPLAY_FALSE_ALL,
    SET_THRESHHOLD,
    UPDATE_ESG_SCREENER,
    CLEAR_SINGLE_SLIDER_VALUE,
    LOAD_SCREENER_PRESETS,
    ADD_SCREENER_PRESET,
    UPDATE_SCREENER_PRESET,
    DELETE_SCREENER_PRESET,
    GET_HISTOGRAM_DATA,
    GET_COMPANIES_STATISTICS,
    GET_SEARCHBAR_COMPANIES,
    CLEAR_SCREENER,
    SCREENER_ERROR,
} from "../actions/Types";

const initialState = {
    companies: null,
    searchbar_companies: null,
    options: {
        filterPreset: {
            property: "filterPreset",
            value: "filtered_metrics",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
        },
        tableStructure: {
            property: "tableStructure",
            value: "filtered_metrics",
            default_value: "filtered_metrics",
            filter: false,
            importance: 0,
            display: false,
        },
        search: {
            property: "search",
            value: "",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
        },
        searchOption: {
            property: "searchOption",
            value: "ticker",
            default_value: "ticker",
            filter: false,
            importance: 0,
            display: false,
        },
        sortBy: {
            property: "sortBy",
            value: "market_cap_usd",
            default_value: "market_cap_usd",
            direction: "DESC",
            checked: false,
            direction_default: "DESC",
            filter: true,
            display: false,
        },
        sortWatchlistBy: {
            property: "sortWatchlistBy",
            value: "daily_return",
            default_value: "daily_return",
            direction: "DESC",
            checked: false,
            direction_default: "DESC",
            filter: false,
            importance: 0,
            display: false,
        },
        industry: {
            property: "industry",
            value: [],
            default_value: [],
            filter: false,
            importance: 0,
            display: false,
            displayName: "Industry",
        },
        gic_group: {
            property: "gic_group",
            value: "",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
            displayName: "Group",
        },
        gic_industry: {
            property: "gic_industry",
            value: "",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
            displayName: "Industry",
        },
        gic_sector: {
            property: "gic_sector",
            value: "",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
            displayName: "Sector",
        },
        gic_sub_industry: {
            property: "gic_sub_industry",
            value: "",
            default_value: "",
            filter: false,
            importance: 0,
            display: false,
            displayName: "Subindustry",
        },
        country: {
            property: "country",
            value: [],
            default_value: [],
            filter: false,
            importance: 0,
            display: false,
            displayName: "Country",
        },
        esg: {
            property: "esg",
            value: false,
            default_value: false,
            filter: false,
            importance: 0,
            display: false,
            displayName: "ESG",
        },
    },
    slider: {
        beta: {
            property: "beta",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        implied_volatility: {
            property: "implied_volatility",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        avg_rating_analyst: {
            property: "avg_rating_analyst",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        target_price_analyst: {
            property: "target_price_analyst",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        short_interest_ratio: {
            property: "short_interest_ratio",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        short_ratio_float: {
            property: "short_ratio_float",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        short_ratio_outstanding: {
            property: "short_ratio_outstanding",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        shares_short: {
            property: "shares_short",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        shares_short_prior_month: {
            property: "shares_short_prior_month",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        shares_held_by_insiders: {
            property: "shares_held_by_insiders",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        shares_held_by_institutions: {
            property: "shares_held_by_institutions",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        outstanding_shares: {
            property: "outstanding_shares",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        outstanding_shares_change: {
            property: "outstanding_shares_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        shares_float: {
            property: "shares_float",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev: {
            property: "ev",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        market_cap_usd: {
            property: "market_cap_usd",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        total_revenue: {
            property: "total_revenue",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        free_cashflow: {
            property: "free_cashflow",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        net_income: {
            property: "net_income",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ebit: {
            property: "ebit",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        gross_profit: {
            property: "gross_profit",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividends_paid: {
            property: "dividends_paid",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        total_liabilities: {
            property: "total_liabilities",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        total_current_liabilities: {
            property: "total_current_liabilities",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        total_assets: {
            property: "total_assets",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        total_current_assets: {
            property: "total_current_assets",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        research_development: {
            property: "research_development",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        market_cap: {
            property: "market_cap",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        market_cap_change: {
            property: "market_cap_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        market_cap_ranker: {
            property: "market_cap_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        market_cap_ranker_change: {
            property: "market_cap_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings: {
            property: "price_earnings",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_change: {
            property: "price_earnings_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_ranker: {
            property: "price_earnings_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_ranker_change: {
            property: "price_earnings_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_growth: {
            property: "price_earnings_growth",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_growth_change: {
            property: "price_earnings_growth_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_growth_ranker: {
            property: "price_earnings_growth_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_growth_ranker_change: {
            property: "price_earnings_growth_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales: {
            property: "price_sales",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_change: {
            property: "price_sales_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_ranker: {
            property: "price_sales_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_ranker_change: {
            property: "price_sales_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_growth: {
            property: "price_sales_growth",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_growth_change: {
            property: "price_sales_growth_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_growth_ranker: {
            property: "price_sales_growth_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_growth_ranker_change: {
            property: "price_sales_growth_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book: {
            property: "price_book",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book_change: {
            property: "price_book_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book_ranker: {
            property: "price_book_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book_ranker_change: {
            property: "price_book_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_ebit: {
            property: "price_ebit",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_ebit_average_10y: {
            property: "price_ebit_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_ebit_deviation_10y: {
            property: "price_ebit_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebit: {
            property: "ev_ebit",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebit_average_10y: {
            property: "ev_ebit_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebit_deviation_10y: {
            property: "ev_ebit_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebitda: {
            property: "ev_ebitda",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebitda_average_10y: {
            property: "ev_ebitda_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebitda_deviation_10y: {
            property: "ev_ebitda_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_consistency: {
            property: "dividend_consistency",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_ebit_ranker: {
            property: "price_ebit_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebit_ranker: {
            property: "ev_ebit_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        ev_ebitda_ranker: {
            property: "ev_ebitda_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_yield: {
            property: "dividend_yield",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_yield_change: {
            property: "dividend_yield_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_yield_ranker: {
            property: "dividend_yield_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_yield_ranker_change: {
            property: "dividend_yield_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        payout_ratio: {
            property: "payout_ratio",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        payout_ratio_change: {
            property: "payout_ratio_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        payout_ratio_ranker: {
            property: "payout_ratio_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        payout_ratio_ranker_change: {
            property: "payout_ratio_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_1y: {
            property: "revenue_growth_1y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_1y_change: {
            property: "revenue_growth_1y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_1y_ranker: {
            property: "revenue_growth_1y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_1y_ranker_change: {
            property: "revenue_growth_1y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_1y: {
            property: "earnings_growth_1y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_1y_change: {
            property: "earnings_growth_1y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_1y_ranker: {
            property: "earnings_growth_1y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_1y_ranker_change: {
            property: "earnings_growth_1y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_1y: {
            property: "dividend_growth_1y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_1y_change: {
            property: "dividend_growth_1y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_1y_ranker: {
            property: "dividend_growth_1y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_1y_ranker_change: {
            property: "dividend_growth_1y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_3y: {
            property: "revenue_growth_3y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_3y_change: {
            property: "revenue_growth_3y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_3y_ranker: {
            property: "revenue_growth_3y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_3y_ranker_change: {
            property: "revenue_growth_3y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_3y: {
            property: "earnings_growth_3y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_3y_change: {
            property: "earnings_growth_3y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_3y_ranker: {
            property: "earnings_growth_3y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_3y_ranker_change: {
            property: "earnings_growth_3y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_3y: {
            property: "dividend_growth_3y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_3y_change: {
            property: "dividend_growth_3y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_3y_ranker: {
            property: "dividend_growth_3y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_3y_ranker_change: {
            property: "dividend_growth_3y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_5y: {
            property: "revenue_growth_5y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_5y_change: {
            property: "revenue_growth_5y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_5y_ranker: {
            property: "revenue_growth_5y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_5y_ranker_change: {
            property: "revenue_growth_5y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_5y: {
            property: "earnings_growth_5y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_5y_change: {
            property: "earnings_growth_5y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_5y_ranker: {
            property: "earnings_growth_5y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_5y_ranker_change: {
            property: "earnings_growth_5y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_5y: {
            property: "dividend_growth_5y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_5y_change: {
            property: "dividend_growth_5y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_5y_ranker: {
            property: "dividend_growth_5y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_5y_ranker_change: {
            property: "dividend_growth_5y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_9y: {
            property: "revenue_growth_9y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_9y_change: {
            property: "revenue_growth_9y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_9y_ranker: {
            property: "revenue_growth_9y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_growth_9y_ranker_change: {
            property: "revenue_growth_9y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_9y: {
            property: "earnings_growth_9y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_9y_change: {
            property: "earnings_growth_9y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_9y_ranker: {
            property: "earnings_growth_9y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        earnings_growth_9y_ranker_change: {
            property: "earnings_growth_9y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_9y: {
            property: "dividend_growth_9y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_9y_change: {
            property: "dividend_growth_9y_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_9y_ranker: {
            property: "dividend_growth_9y_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        dividend_growth_9y_ranker_change: {
            property: "dividend_growth_9y_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        gross_profit_margin: {
            property: "gross_profit_margin",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        gross_profit_margin_change: {
            property: "gross_profit_margin_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        gross_profit_margin_ranker: {
            property: "gross_profit_margin_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        gross_profit_margin_ranker_change: {
            property: "gross_profit_margin_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        net_profit_margin: {
            property: "net_profit_margin",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        net_profit_margin_change: {
            property: "net_profit_margin_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        net_profit_margin_ranker: {
            property: "net_profit_margin_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        net_profit_margin_ranker_change: {
            property: "net_profit_margin_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_cash_flow_margin: {
            property: "operating_cash_flow_margin",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_cash_flow_margin_change: {
            property: "operating_cash_flow_margin_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_cash_flow_margin_ranker: {
            property: "operating_cash_flow_margin_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_cash_flow_margin_ranker_change: {
            property: "operating_cash_flow_margin_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_margin: {
            property: "operating_margin",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_margin_change: {
            property: "operating_margin_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_margin_ranker: {
            property: "operating_margin_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        operating_margin_ranker_change: {
            property: "operating_margin_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_assets: {
            property: "return_on_assets",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_assets_change: {
            property: "return_on_assets_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_assets_ranker: {
            property: "return_on_assets_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_assets_ranker_change: {
            property: "return_on_assets_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        asset_turnover: {
            property: "asset_turnover",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        asset_turnover_change: {
            property: "asset_turnover_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        asset_turnover_ranker: {
            property: "asset_turnover_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        asset_turnover_ranker_change: {
            property: "asset_turnover_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        cash_flow_on_assets: {
            property: "cash_flow_on_assets",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        cash_flow_on_assets_change: {
            property: "cash_flow_on_assets_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        cash_flow_on_assets_ranker: {
            property: "cash_flow_on_assets_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        cash_flow_on_assets_ranker_change: {
            property: "cash_flow_on_assets_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_equity: {
            property: "return_on_equity",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_equity_change: {
            property: "return_on_equity_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_equity_ranker: {
            property: "return_on_equity_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_equity_ranker_change: {
            property: "return_on_equity_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_on_equity: {
            property: "revenue_on_equity",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_on_equity_change: {
            property: "revenue_on_equity_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_on_equity_ranker: {
            property: "revenue_on_equity_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        revenue_on_equity_ranker_change: {
            property: "revenue_on_equity_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital_employed: {
            property: "return_on_capital_employed",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital_employed_change: {
            property: "return_on_capital_employed_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital_employed_ranker: {
            property: "return_on_capital_employed_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital_employed_ranker_change: {
            property: "return_on_capital_employed_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_ratio: {
            property: "debt_ratio",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_ratio_change: {
            property: "debt_ratio_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_ratio_ranker: {
            property: "debt_ratio_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_ratio_ranker_change: {
            property: "debt_ratio_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_ebit: {
            property: "debt_to_ebit",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_ebit_change: {
            property: "debt_to_ebit_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_ebit_ranker: {
            property: "debt_to_ebit_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_ebit_ranker_change: {
            property: "debt_to_ebit_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_equity: {
            property: "debt_to_equity",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_equity_change: {
            property: "debt_to_equity_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_equity_ranker: {
            property: "debt_to_equity_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_equity_ranker_change: {
            property: "debt_to_equity_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_revenue: {
            property: "debt_to_revenue",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_revenue_change: {
            property: "debt_to_revenue_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_revenue_ranker: {
            property: "debt_to_revenue_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        debt_to_revenue_ranker_change: {
            property: "debt_to_revenue_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        current_ratio: {
            property: "current_ratio",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        current_ratio_change: {
            property: "current_ratio_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        current_ratio_ranker: {
            property: "current_ratio_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        current_ratio_ranker_change: {
            property: "current_ratio_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow: {
            property: "price_cash_flow",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow_change: {
            property: "price_cash_flow_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow_ranker: {
            property: "price_cash_flow_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow_ranker_change: {
            property: "price_cash_flow_ranker_change",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        relative_score: {
            property: "relative_score",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        piotroski_score: {
            property: "piotroski_score",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        stock_price: {
            property: "stock_price",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        daily_return: {
            property: "daily_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        weekly_return: {
            property: "weekly_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        monthly_return: {
            property: "monthly_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        quarterly_return: {
            property: "quarterly_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        semi_annual_return: {
            property: "semi_annual_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        annual_return: {
            property: "annual_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        two_year_return: {
            property: "two_year_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        three_year_return: {
            property: "three_year_return",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        volume_deviation: {
            property: "volume_deviation",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_average_10y: {
            property: "price_earnings_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_earnings_deviation_10y: {
            property: "price_earnings_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book_average_10y: {
            property: "price_book_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_book_deviation_10y: {
            property: "price_book_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_average_10y: {
            property: "price_sales_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_sales_deviation_10y: {
            property: "price_sales_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow_average_10y: {
            property: "price_cash_flow_average_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        price_cash_flow_deviation_10y: {
            property: "price_cash_flow_deviation_10y",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital_ranker: {
            property: "return_on_capital_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_net_tangible_assets_ranker: {
            property: "return_on_net_tangible_assets_ranker",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_capital: {
            property: "return_on_capital",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
        return_on_net_tangible_assets: {
            property: "return_on_net_tangible_assets",
            value: [10, 20],
            default_value: [10, 20],
            statistics: [4, 8, 12],
            min_max: [10, 20],
            filter: false,
            importance: 0,
            display: false,
        },
    },
    presets: null,
    checkedIndustriesGlobal: {
        10: false,
        15: false,
        20: false,
        25: false,
        30: false,
        35: false,
        40: false,
        45: false,
        50: false,
        55: false,
        60: false,
        1010: false,
        1510: false,
        2010: false,
        2020: false,
        2030: false,
        2510: false,
        2520: false,
        2530: false,
        2550: false,
        3010: false,
        3020: false,
        3030: false,
        3510: false,
        3520: false,
        4010: false,
        4020: false,
        4030: false,
        4510: false,
        4520: false,
        4530: false,
        5010: false,
        5020: false,
        5510: false,
        6010: false,
        101010: false,
        101020: false,
        151010: false,
        151020: false,
        151030: false,
        151040: false,
        151050: false,
        201010: false,
        201020: false,
        201030: false,
        201040: false,
        201050: false,
        201060: false,
        201070: false,
        202010: false,
        202020: false,
        203010: false,
        203020: false,
        203030: false,
        203040: false,
        203050: false,
        251010: false,
        251020: false,
        252010: false,
        252020: false,
        252030: false,
        253010: false,
        253020: false,
        255010: false,
        255020: false,
        255030: false,
        255040: false,
        301010: false,
        302010: false,
        302020: false,
        302030: false,
        303010: false,
        303020: false,
        351010: false,
        351020: false,
        351030: false,
        352010: false,
        352020: false,
        352030: false,
        401010: false,
        401020: false,
        402010: false,
        402020: false,
        402030: false,
        402040: false,
        403010: false,
        451020: false,
        451030: false,
        452010: false,
        452020: false,
        452030: false,
        453010: false,
        501010: false,
        501020: false,
        502010: false,
        502020: false,
        502030: false,
        551010: false,
        551020: false,
        551030: false,
        551040: false,
        551050: false,
        601010: false,
        601020: false,
        10101010: false,
        10101020: false,
        10102010: false,
        10102020: false,
        10102030: false,
        10102040: false,
        10102050: false,
        15101010: false,
        15101020: false,
        15101030: false,
        15101040: false,
        15101050: false,
        15102010: false,
        15103010: false,
        15103020: false,
        15104010: false,
        15104020: false,
        15104025: false,
        15104030: false,
        15104040: false,
        15104045: false,
        15104050: false,
        15105010: false,
        15105020: false,
        20101010: false,
        20102010: false,
        20103010: false,
        20104010: false,
        20104020: false,
        20105010: false,
        20106010: false,
        20106015: false,
        20106020: false,
        20107010: false,
        20201010: false,
        20201050: false,
        20201060: false,
        20201070: false,
        20201080: false,
        20202010: false,
        20202020: false,
        20301010: false,
        20302010: false,
        20303010: false,
        20304010: false,
        20304020: false,
        20305010: false,
        20305020: false,
        20305030: false,
        25101010: false,
        25101020: false,
        25102010: false,
        25102020: false,
        25201010: false,
        25201020: false,
        25201030: false,
        25201040: false,
        25201050: false,
        25202010: false,
        25203010: false,
        25203020: false,
        25203030: false,
        25301010: false,
        25301020: false,
        25301030: false,
        25301040: false,
        25302010: false,
        25302020: false,
        25501010: false,
        25502020: false,
        25503010: false,
        25503020: false,
        25504010: false,
        25504020: false,
        25504030: false,
        25504040: false,
        25504050: false,
        25504060: false,
        30101010: false,
        30101020: false,
        30101030: false,
        30101040: false,
        30201010: false,
        30201020: false,
        30201030: false,
        30202010: false,
        30202030: false,
        30203010: false,
        30301010: false,
        30302010: false,
        35101010: false,
        35101020: false,
        35102010: false,
        35102015: false,
        35102020: false,
        35102030: false,
        35103010: false,
        35201010: false,
        35202010: false,
        35203010: false,
        40101010: false,
        40101015: false,
        40102010: false,
        40201020: false,
        40201030: false,
        40201040: false,
        40202010: false,
        40203010: false,
        40203020: false,
        40203030: false,
        40203040: false,
        40204010: false,
        40301010: false,
        40301020: false,
        40301030: false,
        40301040: false,
        40301050: false,
        45102010: false,
        45102020: false,
        45102030: false,
        45103010: false,
        45103020: false,
        45201020: false,
        45202030: false,
        45203010: false,
        45203015: false,
        45203020: false,
        45203030: false,
        45301010: false,
        45301020: false,
        50101010: false,
        50101020: false,
        50102010: false,
        50201010: false,
        50201020: false,
        50201030: false,
        50201040: false,
        50202010: false,
        50202020: false,
        50203010: false,
        55101010: false,
        55102010: false,
        55103010: false,
        55104010: false,
        55105010: false,
        55105020: false,
        60101010: false,
        60101020: false,
        60101030: false,
        60101040: false,
        60101050: false,
        60101060: false,
        60101070: false,
        60101080: false,
        60102010: false,
        60102020: false,
        60102030: false,
        60102040: false,
    },
    checkedIndustriesSelectedGlobal: 0,
    histogram: {},
    statistics: null,
    checked: 0,
    threshold: 0,
    error: null,
    pagination: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                options: action.payload.options,
                slider: action.payload.slider,
                threshold: action.payload.threshold,
                companies: action.payload.data,
                checked: action.payload.checked
                    ? action.payload.checked
                    : state.checked,
            };

        case GET_SEARCHBAR_COMPANIES:
            return {
                ...state,
                searchbar_companies: action.payload,
            };

        case GET_COMPANIES_PAGINATION:
            return {
                ...state,
                companies: state.companies.concat(action.payload),
                pagination: state.pagination + 1,
            };

        case GET_HISTOGRAM_DATA:
            return {
                ...state,
                histogram: action.payload,
            };

        case GET_COMPANIES_STATISTICS:
            return {
                ...state,
                statistics: action.payload.new_statistics,
                slider: action.payload.slider,
            };

        case UPDATE_SCREENER_VALUE:
            return {
                ...state,
                slider: action.payload.slider,
                options: action.payload.options,
            };

        case UPDATE_SINGLE_SLIDER_VALUE:
            let checked = 0;
            let new_threshold = state.threshold;
            Object.keys(state.slider).map((prop) =>
                state.slider[prop].filter === true ? (checked += 1) : null
            );
            if (
                action.payload.type === "filter" &&
                action.payload.data.filter === true &&
                action.payload.data.importance !== 2
            ) {
                checked += 1;
                new_threshold += 1;
            } else if (
                action.payload.type === "filter" &&
                action.payload.data.filter === false &&
                action.payload.data.importance !== 2
            ) {
                checked -= 1;
                new_threshold > 0 ? (new_threshold -= 1) : (new_threshold += 0);
            }

            localStorage.setItem(
                "latest_threshold",
                JSON.stringify(new_threshold)
            );

            return {
                ...state,
                slider: {
                    ...state.slider,
                    [action.payload.data.property]: {
                        ...state.slider[action.payload.data.property],
                        value: action.payload.data.value,
                        filter: action.payload.data.filter,
                        display: action.payload.data.display,
                        importance: action.payload.data.importance,
                    },
                },
                checked:
                    action.payload.type === "filter" ? checked : state.checked,
                threshold: new_threshold,
            };

        case UPDATE_SINGLE_SLIDER_VALUE_ONLY:
            return {
                ...state,
                slider: {
                    ...state.slider,
                    [action.payload.property]: {
                        ...state.slider[action.payload.property],
                        value: action.payload.value,
                    },
                },
            };

        case UPDATE_SINGLE_OPTIONS_VALUE:
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.payload.property]: {
                        ...state.options[action.payload.property],
                        value: action.payload.value,
                        filter: action.payload.filter,
                        display: action.payload.display,
                    },
                },
            };

        case UPDATE_SINGLE_OPTION_SORT_BY:
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.payload.property]: {
                        ...state.options[action.payload.property],
                        value: action.payload.value,
                        checked: action.payload.checked,
                        direction: action.payload.direction,
                    },
                },
            };

        case UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY:
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.payload.property]: {
                        ...state.options[action.payload.property],
                        value: action.payload.value,
                        checked: action.payload.checked,
                        direction: action.payload.direction,
                    },
                },
            };

        case UPDATE_SEARCH_TEXT_SCREENER:
            return {
                ...state,
                options: {
                    ...state.options,
                    searchOption: {
                        ...state.options.searchOption,
                        filter: action.payload.filter,
                    },
                    search: {
                        ...state.options.search,
                        value: action.payload.value,
                        filter: action.payload.filter,
                    },
                },
            };

        case UPDATE_SEARCH_OPTION_SCREENER:
            return {
                ...state,
                options: {
                    ...state.options,
                    searchOption: {
                        ...state.options.searchOption,
                        value: action.payload.value,
                        filter: action.payload.filter,
                    },
                    search: {
                        ...state.options.search,
                        filter: action.payload.filter,
                    },
                },
            };

        case UPDATE_SEARCH_COUNTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    country: {
                        property: "country",
                        value: action.payload,
                        default_value: [],
                        filter: true,
                        display: false,
                        displayName: "Country",
                    },
                },
            };

        case UPDATE_SEARCH_INDUSTRIES:
            return {
                ...state,
                options: {
                    ...state.options,
                    industry: {
                        property: "industry",
                        value: action.payload.value,
                        default_value: [],
                        filter: action.payload.filter,
                        display: false,
                        displayName: "Industry",
                    },
                },
            };

        case UPDATE_CHECKED_INDUSTRIES:
            return {
                ...state,
                checkedIndustriesGlobal:
                    action.payload.new_checked_industries_object,
                checkedIndustriesSelectedGlobal:
                    action.payload.new_selected_number,
            };

        case CLEAR_SCREENER_VALUES:
            return {
                ...state,
                slider: action.payload.slider,
                options: action.payload.options,
                checked: 0,
                threshold: 0,
                pagination: 0,
            };

        case SET_DISPLAY_FALSE_ALL:
            return {
                ...state,
                slider: action.payload.slider,
                options: action.payload.options,
            };

        case UPDATE_ESG_SCREENER:
            return {
                ...state,
                options: {
                    ...state.options,
                    esg: action.payload,
                },
            };

        case SET_THRESHHOLD:
            return {
                ...state,
                threshold: action.payload,
            };

        case CLEAR_SINGLE_SLIDER_VALUE:
            return {
                ...state,
                slider: {
                    ...state.slider,
                    [action.payload.property]: action.payload,
                },
            };

        case CLEAR_SCREENER:
            return {
                ...state,
                companies: null,
                histogram: {},
                statistics: null,
                checked: 0,
                threshold: 0,
                error: null,
                pagination: 0,
            };

        case SCREENER_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case LOAD_SCREENER_PRESETS:
            return {
                ...state,
                presets: action.payload,
            };
        case ADD_SCREENER_PRESET:
            return {
                ...state,
                presets: action.payload,
            };
        case UPDATE_SCREENER_PRESET:
            return {
                ...state,
                presets: action.payload,
            };
        case DELETE_SCREENER_PRESET:
            return {
                ...state,
                presets: action.payload,
            };

        default:
            return state;
    }
};
