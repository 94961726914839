import React from "react";
import { MathComponent } from "mathjax-react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as IoIcons5 from "react-icons/io5";

// (93.58B USD) and 2020 (143.015B USD). This equals a revenue growth of 52.8%

const SliderInfoBox = ({ sliderprops }) => {
    const useintl = useIntl();

    console.log(sliderprops.property.slice(-7));

    return (
        <div>
            <div className='divider_no_margin'></div>
            <div className='flex flex_column align_items_start margin_10px'>
                {sliderprops.info
                    ? sliderprops.info.map((prop) =>
                          prop.type === "header_1" ? (
                              <div className='title_4 flex'>
                                  {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                  {sliderprops[prop.content]}
                              </div>
                          ) : prop.type === "header_2" ? (
                              prop.content === 1 ? (
                                  <div className='title_6 flex'>
                                      <IoIcons5.IoBookOutline className='mr_10px' />
                                      {useintl.formatMessage({
                                          id: `metrics.infobox.section_1.header`,
                                      })}
                                  </div>
                              ) : prop.content === 2 ? (
                                  <div className='title_6 flex'>
                                      <AiIcons.AiOutlineCalculator className='mr_10px' />
                                      {useintl.formatMessage({
                                          id: `metrics.infobox.section_2.header`,
                                      })}
                                  </div>
                              ) : prop.content === 3 ? (
                                  <div className='title_6 flex'>
                                      <IoIcons5.IoTrailSignOutline className='mr_10px' />
                                      {useintl.formatMessage({
                                          id: `metrics.infobox.section_3.header`,
                                      })}
                                  </div>
                              ) : (
                                  <div className='text_2 flex ml_5px'>
                                      {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                      {sliderprops[prop.content]}
                                  </div>
                              )
                          ) : prop.type === "header_3" ? (
                              <div className='title_7 flex'>
                                  {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                  {sliderprops[prop.content]}
                              </div>
                          ) : prop.type === "text_1" ? (
                              <div className='margin_10px'>
                                  {sliderprops[prop.content]}
                              </div>
                          ) : prop.type === "math" ? (
                              <div className='width_100pr flex center'>
                                  <MathComponent
                                      tex={sliderprops[prop.content]}
                                  />
                              </div>
                          ) : prop.type === "img" ? (
                              <div className='width_100pr flex center'>
                                  <img
                                      src={sliderprops[prop.content]}
                                      alt={sliderprops.displayName}
                                  />
                              </div>
                          ) : prop.type === "divider" ? (
                              <div className='divider'></div>
                          ) : null
                      )
                    : null}
            </div>
            <div className='divider_no_margin'></div>
            {sliderprops.property.slice(-7) === "_ranker" ? (
                <div>
                    <div className='margin_20px'>
                        <MdIcons.MdOutlineInfo className='width_20px mr_5px' />
                        {/* {`The 'Sector Ranker' of a metric equals to the relative change in the last trailing twelve months. In this particular case: ${useintl.formatMessage(
                                {
                                    id: `metrics.${base_metric}`,
                                }
                            )} ${
                                histogramprops.raw_value > 0
                                    ? "increased"
                                    : "decreased"
                            } by ${histogramprops.raw_value} ${
                                histogramprops.histogram_unit
                            }.`} */}
                        The "Sector Ranker" of a key figure indicates the
                        percentile in which it is located in relation to the
                        company's sector. Key figures that are better in terms
                        of size achieve a high rank if they are large, and vice
                        versa. They are used to see at a glance how the key
                        figure is to be classified, but for a more in-depth
                        company analysis you should not leave it at that.
                    </div>
                    <div className='divider_no_margin'></div>
                </div>
            ) : null}
        </div>
    );
};

export default SliderInfoBox;
