import React from "react";
import { Link } from "react-router-dom";

// ICONS
import * as MdIcons from "react-icons/md";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    setCompanyTicker,
    setCompanyOpenTab,
} from "../../../../actions/companyActions";
import { openPortfolioChanges } from "../../../../actions/portfolioActions";

// COMPONENTS
import EditTransaction from "./EditTransaction";

// MUI
import Dialog from "@mui/material/Dialog";

const getDateString = (unix) => {
    let new_date = new Date(unix);
    let date_string = new_date.toLocaleString("en-us", {
        // weekday: "long",
        // year: "numeric",
        month: "long",
        day: "numeric",
    });

    return date_string;
};

const TransactionRow = ({
    transaction,
    portfolio_id,
    user_id,
    portfolio_user_id,
    risk_free_rate,
    setCompanyTicker,
    setCompanyOpenTab,
    portfolio,
    openPortfolioChanges,
}) => {
    // OPEN TRANSACTION EDIT MODAL
    const [openEditTransaction, setOpenEditTransaction] = React.useState(false);
    const handleOpenEditTransaction = () => {
        setOpenEditTransaction(true);
        openPortfolioChanges(true, "EditTransaction");
    };
    const handleCloseEditTransaction = () => {
        setOpenEditTransaction(false);
        openPortfolioChanges(false, "EditTransaction");
    };

    return (
        <div className='width_100pr padding_10px mt_5px border_radius_15px background_color_1 border_radius_15px flex space_between'>
            <div className='flex'>
                <div className='flex wrap'>
                    <div className='ms_10px flex width_110px'>
                        {getDateString(transaction.date)}
                    </div>
                    <div className='ms_10px flex width_60px'>
                        <div className='mr_5px'>
                            {transaction.type === "buy" ? "Buy" : "Sell"}
                        </div>
                        <Link
                            to={`/company?ticker=${transaction.ticker}`}
                            onClick={() => {
                                setCompanyTicker(transaction.ticker);
                                // if(window.location.pathname === "/company"){
                                setCompanyOpenTab(1);
                                // }
                            }}
                        >
                            <div className='text_color_blue'>
                                {transaction.ticker}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='flex wrap left width_320px pl_20px'>
                    <div className='ms_5px flex'>
                        <div>
                            {transaction.share_count} @{" "}
                            {transaction.price_per_share} USD
                        </div>
                    </div>
                    <div className='ms_5px flex'>
                        <div> = {transaction.transaction_value} USD</div>
                    </div>
                </div>
            </div>
            <div>
                <MdIcons.MdModeEditOutline
                    className='ml_5px cursor_pointer width_20px'
                    onClick={handleOpenEditTransaction}
                />
            </div>
            <Dialog
                open={
                    portfolio.changes &&
                    portfolio.changes_component === "EditTransaction" &&
                    openEditTransaction
                }
                onClose={handleCloseEditTransaction}
                maxWidth={false}
            >
                <EditTransaction
                    transaction={transaction}
                    portfolio_id={portfolio_id}
                    user_id={user_id}
                    portfolio_user_id={portfolio_user_id}
                    risk_free_rate={risk_free_rate}
                    closeModal={handleCloseEditTransaction}
                />
            </Dialog>
        </div>
    );
};

TransactionRow.propTypes = {
    setCompanyTicker: PropTypes.func.isRequired,
    setCompanyOpenTab: PropTypes.func.isRequired,
    portfolio: PropTypes.object.isRequired,
    openPortfolioChanges: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, {
    setCompanyTicker,
    setCompanyOpenTab,
    openPortfolioChanges,
})(TransactionRow);
