import {
    LOAD_PORTFOLIOS,
    SET_PORTFOLIO,
    FOLLOW_PORTFOLIO,
    UNFOLLOW_PORTFOLIO,
    GET_PORTFOLIO,
    SET_PORTFOLIO_PERFORMANCE,
    SET_CURRENT_PORTFOLIO,
    CHANGE_PORTFOLIO,
    DELETE_PORTFOLIO,
    SET_CREATOR_DIVERSIFICATION,
    SET_CREATOR_POSITIONS,
    SET_CREATOR_WINDOW,
    SET_CURRENT_CREATOR_COMPANIES,
    CLEAR_CURRENT_CREATOR,
    ADD_PORTFOLIO_COMPANY,
    ADD_TRANSACTION_FROM_PORTFOLIO,
    SET_MANAGER_DIVERSIFICATION,
    UPDATE_CREATOR_ALLOCATION,
    UPDATE_PORTFOLIO_PERFORMANCE,
    UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE,
    UPDATE_PORTFOLIO_TRACKER_PERFORMANCE,
    UPDATE_PORTFOLIO_SHARE_COUNT,
    SUBMIT_PORTFOLIO_ALLOCATION,
    ADD_CREATOR_TO_MANAGER,
    GET_PORTFOLIO_TRACKER_COMPANIES,
    RESET_PORTFOLIO_BENCHMARKS,
    UPDATE_PORTFOLIO_BENCHMARKS,
    SET_RISK_FREE_RATE,
    SET_ALERT,
    REMOVE_ALERT,
    OPEN_CHANGES_PORTFOLIO,
    PORTFOLIO_ERROR,
} from "./Types";

import * as dfd from "danfojs";
import * as math from "mathjs";
// import { useHistory } from "react-router-dom";

// FIND CLOSEST INDEX IN AN ARRAY OF NUMBERS, FOR A NUMBER
function closest(num, arr) {
    var curr = arr[0],
        diff = Math.abs(num - curr),
        index = 0;

    for (var val = 0; val < arr.length; val++) {
        let newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        }
    }
    return index;
}

const merge_on_columns_n_m = (arr1, arr2, n, m) =>
    arr1.map((x1) =>
        x1.concat(arr2.find((x2) => x1[n] == x2[m]).filter((_, i) => i != m))
    );

const sharpe = (close, risk_free_rate) => {
    try {
        let current_date = new Date().getTime();
        close = close.filter((x) => x[0] >= current_date - 3.156e10);
        risk_free_rate = risk_free_rate.filter(
            (x) => x[0] >= current_date - 3.156e10
        );
        risk_free_rate = risk_free_rate.map((x) => [
            x[0],
            ((x[1] / 100 + 1) ** (1 / 250) - 1) * 100,
        ]);

        let returns = close
            .slice(1, close.length)
            .map((x, i) => [x[0], (x[1] / close[i][1] - 1) * 100]);
        let excess_returns = merge_on_columns_n_m(
            returns,
            risk_free_rate,
            0,
            0
        ).map((x) => x[1] - x[2]);

        let mu =
            excess_returns.reduce((a, b) => a + b, 0) / excess_returns.length;
        let std = Math.sqrt(
            excess_returns.reduce((a, b) => a + (b - mu) ** 2, 0) /
                excess_returns.length
        );

        return (
            (((mu / 100 + 1) ** excess_returns.length - 1) * 100) /
            (std * Math.sqrt(excess_returns.length))
        );
    } catch (err) {
        console.log("SHARPE ERROR: ", err);
    }
};

// SORT AN ARRAY OF OBJECTS BY A KEY CONTAINED IN THE OBJECTS
const sort_array_of_objects_by_value_number = (array, value) => {
    return array.sort(function (a, b) {
        return a[value] - b[value];
    });
};

// OPEN A PORTFOLIO MODAL
export const openPortfolioChanges =
    (boolean, component) => async (dispatch) => {
        dispatch({
            type: OPEN_CHANGES_PORTFOLIO,
            payload: {
                changes: boolean,
                changes_component: boolean ? component : null,
            },
        });
    };

// ADD A NEW PORTFOLIO TO THE USER
export const setPortfolio =
    (portfolio_data, portfolio, user_status) => async (dispatch) => {
        try {
            let new_data = portfolio.portfolios;
            let number_of_portfolios = 0;

            new_data.map((one_portfolio) => {
                if (one_portfolio.user_id === portfolio_data.user) {
                    number_of_portfolios += 1;
                }
            });

            if (
                (user_status > 0 && number_of_portfolios < 5) ||
                (user_status === 0 && number_of_portfolios === 0) ||
                user_status > 90
            ) {
                const res = await fetch("/node_create_portfolio", {
                    method: "POST",
                    body: JSON.stringify({
                        name: portfolio_data.name,
                        user: portfolio_data.user,
                        description: portfolio_data.description,
                        icon: portfolio_data.icon,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // SUCCESS ALERT
                    const id = Math.floor(Math.random() * 100);
                    let msg = `Your portfolio "${portfolio_data.name}" has been created! You can now select it as a destination for the Portfolio Creator result`;
                    let type = "success";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                    // DO WHAT IS INTENDED
                    dispatch({
                        type: SET_PORTFOLIO,
                        payload: data,
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
                // history.push(`/portfolio?id=${data[data.length - 1].id}`);
            } else {
                // USER ALREADY HAS TOO MANY PORTFOLIOS
                const id = Math.floor(Math.random() * 100);
                let type = "warning";
                let msg =
                    user_status === 0
                        ? "Creating this portfolio would exceed the maximum amount of portfolios you are allowed to maintain. Upgrade your plan to be able to continue."
                        : "Creating this portfolio would exceed the maximum amount of portfolios you are allowed to maintain.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    7000
                );
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred creating your new portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// FOLLOW A PORTFOLIO THAT HAS BEEN SHARED WITH YOU
export const followPortfolio =
    (portfolio_data, user_data, portfolio) => async (dispatch) => {
        try {
            let number_of_portfolios = portfolio.portfolios
                ? portfolio.portfolios.length + 1
                : 0;

            if (
                (user_data.user_status > 0 && number_of_portfolios < 6) ||
                (user_data.user_status === 0 && number_of_portfolios === 0) ||
                user_data.user_status > 90
            ) {
                console.log(user_data.id);
                const res = await fetch("/node_follow_portfolio", {
                    method: "POST",
                    body: JSON.stringify({
                        user: user_data.id,
                        portfolio_id: portfolio_data.id,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // SUCCESS ALERT
                    const id = Math.floor(Math.random() * 100);
                    let msg = `You are now following the portfolio "${portfolio_data.name}"`;
                    let type = "success";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                    // DO WHAT IS INTENDED
                    dispatch({
                        type: FOLLOW_PORTFOLIO,
                        payload: portfolio_data.id,
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
                // history.push(`/portfolio?id=${data[data.length - 1].id}`);
            } else {
                // USER ALREADY HAS TOO MANY PORTFOLIOS
                const id = Math.floor(Math.random() * 100);
                let type = "warning";
                let msg =
                    user_data.user_status === 0
                        ? "Following this portfolio would exceed the maximum amount of portfolios you are allowed to maintain. Upgrade your plan to be able to continue."
                        : "Following this portfolio would exceed the maximum amount of portfolios you are allowed to maintain.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    7000
                );
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred following the selected portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// FOLLOW A PORTFOLIO THAT HAS BEEN SHARED WITH YOU
export const unfollowPortfolio =
    (portfolio_data, user_data) => async (dispatch) => {
        try {
            const res = await fetch("/node_unfollow_portfolio", {
                method: "POST",
                body: JSON.stringify({
                    user: user_data.id,
                    portfolio_id: portfolio_data.id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();
            if (!data.error) {
                // SUCCESS ALERT
                const id = Math.floor(Math.random() * 100);
                let msg = `You are no longer following the portfolio "${portfolio_data.name}"`;
                let type = "success";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
                // DO WHAT IS INTENDED
                dispatch({
                    type: UNFOLLOW_PORTFOLIO,
                    payload: portfolio_data.id,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg ? data.error_msg : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
            // history.push(`/portfolio?id=${data[data.length - 1].id}`);
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred unfollowing the selected portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// Set Current Portfolio
export const setCurrentPortfolio = (id) => {
    return {
        type: SET_CURRENT_PORTFOLIO,
        payload: id,
    };
};

// Change portfolio
export const renamePortfolio = (data, portfolio) => async (dispatch) => {
    try {
        let new_data = portfolio.portfolios;
        let portfolio_user_id;

        new_data.map((one_portfolio) => {
            if (one_portfolio.id === data.id) {
                portfolio_user_id = one_portfolio.user_id;
                if (one_portfolio.user_id === data.user) {
                    one_portfolio.name = data.name;
                    one_portfolio.icon = data.icon;
                    one_portfolio.description = data.description;
                    one_portfolio.public = data.public;
                }
            }
        });

        if (portfolio_user_id === data.user) {
            const res = await fetch("/node_change_portfolio", {
                method: "POST",
                body: JSON.stringify({
                    portfolio: data.id,
                    name: data.name,
                    icon: data.icon,
                    description: data.description,
                    public: data.public,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });

            const response = await res.json();
            if (!response.error) {
                // DO WHAT IS INTENDED
                const id = Math.floor(Math.random() * 100);
                let type = "success";
                let msg = "Saved changes to your portfolio.";

                dispatch({
                    type: CHANGE_PORTFOLIO,
                    payload: new_data,
                });
                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = response.error_type
                    ? response.error_type
                    : "warning";
                let msg = response.error_msg
                    ? response.error_msg
                    : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred while adding changes to your portfolio.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Delete portfolio
export const deletePortfolio = (data, portfolio) => async (dispatch) => {
    try {
        let new_data = portfolio;
        let portfolio_user_id;

        new_data.portfolios.map((one_portfolio, index) => {
            if (one_portfolio.id === data.id) {
                portfolio_user_id = one_portfolio.user_id;
                if (data.user === one_portfolio.user_id) {
                    new_data.portfolios.splice(index, 1);
                }
            }
        });

        if (portfolio_user_id === data.user) {
            const res = await fetch("/node_delete_portfolio", {
                method: "POST",
                body: JSON.stringify({
                    portfolio: data.id,
                    user: data.user,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const delete_from_portfolio_data = await res.json();
            if (!delete_from_portfolio_data.error) {
                const id = Math.floor(Math.random() * 100);
                let type = "success";
                let msg = "Successfully deleted your portfolio.";
                dispatch({
                    type: DELETE_PORTFOLIO,
                    payload: new_data,
                });
                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg ? data.error_msg : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred while deleting your portfolio.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Get Portfolio Companies
export const getPortfolio = (id, uid, portfolios) => async (dispatch) => {
    try {
        if (id !== null && uid !== null) {
            const res = await fetch("/node_portfolio", {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    uid: uid,
                    risk_free_rate_required: true,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            if (!data.error) {
                let response = {
                    risk_free_rate: data.risk_free_rate,
                    allocation: data.portfolio.allocation,
                    performance:
                        data.companies !== null &&
                        data.companies.length > 0 &&
                        data.portfolio.ticker
                            ? calculatePortfolioPerformance(
                                  data.portfolio.allocation,
                                  data.companies
                              )
                            : null,
                    companies: data.companies,
                    id: id,
                };
                if (
                    data.companies !== null &&
                    data.companies.length !== 0 &&
                    data.portfolio.ticker
                ) {
                    let regression_length = null;
                    data.companies.map((company) => {
                        if (
                            regression_length === null ||
                            (regression_length !== null &&
                                company.stock_prices.length < regression_length)
                        ) {
                            regression_length = company.stock_prices.length - 1;
                        }
                    });

                    calulateLinearRegressionConfidence(
                        response.performance.performance,
                        regression_length
                    );

                    let cot = calculateRegressionConfidenceOverTime(
                        response.performance.performance
                    );

                    response.performance["lin_reg"] = cot.lin_reg;
                    response.performance["conf"] = cot.conf;
                    response.performance["conf_2"] = cot.conf_2;
                    response.performance["conf_3"] = cot.conf_3;
                    response.performance["linear_regression_1y"] =
                        cot.linear_regression_1y;
                    response.performance["confidence_lower"] =
                        cot.confidence_lower;
                    response.performance["confidence_upper"] =
                        cot.confidence_upper;
                }
                dispatch({
                    type: GET_PORTFOLIO,
                    payload: response,
                });
            } else {
                // ERROR HANDLING
                console.log("PORTFOLIO ACTION 1: ", data.error);
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while querying your portfolio data";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } else {
            console.log("PORTFOLIO ACTION 2");
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred while querying your portfolio data";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log("PORTFOLIO ACTION 3: ", err);
        // ERROR HANDLING
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred while querying your portfolio data";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Get Portfolio Companies
export const getPortfolioCompanies =
    (id, ticker, type, allocation) => async (dispatch) => {
        try {
            const res = await fetch("/node_portfolio_companies", {
                method: "POST",
                body: JSON.stringify({
                    ticker: ticker,
                    risk_free_rate_required: true,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();
            if (!data.error) {
                // DO WHAT IS INTENDED
                let companies = data.companies;
                let risk_free_rate = data.risk_free_rate;
                let response = {
                    risk_free_rate: risk_free_rate,
                    allocation: allocation,
                    performance: calculatePortfolioPerformance(
                        allocation,
                        companies
                    ),
                    companies: companies,
                    id: id,
                };

                let regression_length = null;
                companies.map((company) => {
                    if (
                        regression_length === null ||
                        (regression_length !== null &&
                            company.stock_prices.length < regression_length)
                    ) {
                        regression_length = company.stock_prices.length - 1;
                    }
                });

                calulateLinearRegressionConfidence(
                    response.performance.performance,
                    regression_length
                );

                let cot = calculateRegressionConfidenceOverTime(
                    response.performance.performance
                );

                response.performance["lin_reg"] = cot.lin_reg;
                response.performance["conf"] = cot.conf;
                response.performance["conf_2"] = cot.conf_2;
                response.performance["conf_3"] = cot.conf_3;
                response.performance["linear_regression_1y"] =
                    cot.linear_regression_1y;
                response.performance["confidence_lower"] = cot.confidence_lower;
                response.performance["confidence_upper"] = cot.confidence_upper;

                dispatch({
                    type: GET_PORTFOLIO,
                    payload: response,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg ? data.error_msg : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occurred while retrieving the data for your portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// PORTFOLIO CREATOR
export const setCreatorDiversification = (
    div,
    fractional_shares,
    in_creation
) => {
    in_creation.diversification = div;
    in_creation.fractional_shares = fractional_shares;

    return {
        type: SET_CREATOR_DIVERSIFICATION,
        payload: in_creation,
    };
};
export const setCreatorWindow = (page_number) => {
    return {
        type: SET_CREATOR_WINDOW,
        payload: page_number,
    };
};
export const setCreatorExperience = (
    risk,
    risk_value,
    risk_checked,
    in_creation
) => {
    in_creation.risk = risk;
    if (risk_value !== null) {
        in_creation.risk_assessment_values = risk_value;
    }
    if (risk_checked !== null) {
        in_creation.risk_assessment_checked = risk_checked;
    }

    return {
        type: SET_CREATOR_DIVERSIFICATION,
        payload: in_creation,
    };
};
export const setNPositions = (positions) => {
    return {
        type: SET_CREATOR_POSITIONS,
        payload: positions,
    };
};
export const setCreatorStrategy = (
    esg,
    dividends,
    value,
    growth,
    in_creation
) => {
    in_creation.esg = esg;
    in_creation.dividends = dividends;
    in_creation.value = value;
    in_creation.growth = growth;

    return {
        type: SET_CREATOR_DIVERSIFICATION,
        payload: in_creation,
    };
};
export const setCreatorInterests = (technologies, in_creation) => {
    in_creation.technologies = technologies;

    return {
        type: SET_CREATOR_DIVERSIFICATION,
        payload: in_creation,
    };
};

// Get Creator Result
export const getCurrentCreatorPortfolio =
    (creatorOptions) => async (dispatch) => {
        try {
            const res = await fetch("/node_portfolio_creator", {
                method: "POST",
                body: JSON.stringify({
                    creatorData: {
                        risk_coefficient:
                            0.4 + 0.6 * ((creatorOptions.risk - 1) / 4),
                        n_positions: creatorOptions.positions,
                        esg: creatorOptions.esg,
                        dividend:
                            creatorOptions.dividends &&
                            creatorOptions.dividends !== null &&
                            creatorOptions.dividends !== undefined
                                ? creatorOptions.dividends
                                : false,
                        value:
                            creatorOptions.value &&
                            creatorOptions.value !== null &&
                            creatorOptions.value !== undefined
                                ? creatorOptions.value
                                : false,
                        growth:
                            creatorOptions.growth &&
                            creatorOptions.growth !== null &&
                            creatorOptions.growth !== undefined
                                ? creatorOptions.growth
                                : false,
                    },
                    creatorOptions: creatorOptions,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();
            // data = {allocation:{}, companies:[]}

            if (!data.error) {
                let response = {
                    allocation: data.allocation,
                    performance:
                        data.companies.length > 0
                            ? calculatePortfolioPerformance(
                                  data.allocation,
                                  data.companies
                              )
                            : {
                                  performance: [],
                                  buy_in_total_price: null,
                              },
                    companies: data.companies,
                };

                let regression_length = null;
                data.companies.map((company) => {
                    if (
                        regression_length === null ||
                        (regression_length !== null &&
                            company.stock_prices.length < regression_length)
                    ) {
                        regression_length = company.stock_prices.length - 1;
                    }
                });

                calulateLinearRegressionConfidence(
                    response.performance.performance,
                    regression_length
                );

                let cot = calculateRegressionConfidenceOverTime(
                    response.performance.performance
                );

                response.performance["lin_reg"] = cot.lin_reg;
                response.performance["conf"] = cot.conf;
                response.performance["conf_2"] = cot.conf_2;
                response.performance["conf_3"] = cot.conf_3;
                response.performance["linear_regression_1y"] =
                    cot.linear_regression_1y;
                response.performance["confidence_lower"] = cot.confidence_lower;
                response.performance["confidence_upper"] = cot.confidence_upper;

                dispatch({
                    type: SET_CURRENT_CREATOR_COMPANIES,
                    payload: response,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg ? data.error_msg : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: PORTFOLIO_ERROR,
                payload: err.response.statusText,
            });
        }
    };

// Clear Current Creator
export const clearOldCreatorPortfolio = () => {
    return {
        type: CLEAR_CURRENT_CREATOR,
        payload: null,
    };
};

// Add Creator Position Values
export const addCreatorPositionValues = (new_allocation, companies_array) => {
    let response = {
        allocation: new_allocation,
        performance: calculatePortfolioPerformance(
            new_allocation,
            companies_array
        ),
    };

    return {
        type: UPDATE_CREATOR_ALLOCATION,
        payload: response,
    };
};

// CALCUATE MANAGER/ CREATOR PERFORMANCE OLD
export const getPortfolioPerformance = (
    new_allocation,
    companies_array,
    portfolio_type,
    portfolio_id
) => {
    console.log("GET PORTFOLI PERFOR");

    let response = {
        allocation: new_allocation,
        performance: calculatePortfolioPerformance(
            new_allocation,
            companies_array
        ),
        id: portfolio_id,
    };

    let regression_length = null;
    companies_array.map((company) => {
        if (
            regression_length === null ||
            (regression_length !== null &&
                company.stock_prices.length < regression_length)
        ) {
            regression_length = company.stock_prices.length - 1;
        }
    });

    calulateLinearRegressionConfidence(
        response.performance.performance,
        regression_length
    );

    let cot = calculateRegressionConfidenceOverTime(
        response.performance.performance
    );

    response.performance["lin_reg"] = cot.lin_reg;
    response.performance["conf"] = cot.conf;
    response.performance["conf_2"] = cot.conf_2;
    response.performance["conf_3"] = cot.conf_3;
    response.performance["linear_regression_1y"] = cot.linear_regression_1y;
    response.performance["confidence_lower"] = cot.confidence_lower;
    response.performance["confidence_upper"] = cot.confidence_upper;

    console.log(response);

    if (portfolio_type === "creator") {
        return {
            type: UPDATE_CREATOR_ALLOCATION,
            payload: response,
        };
    } else if (portfolio_type === "tracker") {
        return {
            type: UPDATE_PORTFOLIO_PERFORMANCE,
            payload: response,
        };
    } else {
        return {
            type: UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE,
            payload: response,
        };
    }
};

// Reset Creator Position Counts
export const resetCreatorCompanyShareCount = (
    new_allocation,
    companies_array
) => {
    Object.keys(new_allocation).map(function (key) {
        new_allocation[key].count = new_allocation[key].recommend;
    });

    let response = {
        allocation: new_allocation,
        performance: calculatePortfolioPerformance(
            new_allocation,
            companies_array
        ),
    };

    let regression_length = null;
    companies_array.map((company) => {
        if (
            regression_length === null ||
            (regression_length !== null &&
                company.stock_prices.length < regression_length)
        ) {
            regression_length = company.stock_prices.length - 1;
        }
    });

    calulateLinearRegressionConfidence(
        response.performance.performance,
        regression_length
    );

    let cot = calculateRegressionConfidenceOverTime(
        response.performance.performance
    );

    response.performance["lin_reg"] = cot.lin_reg;
    response.performance["conf"] = cot.conf;
    response.performance["conf_2"] = cot.conf_2;
    response.performance["conf_3"] = cot.conf_3;
    response.performance["linear_regression_1y"] = cot.linear_regression_1y;
    response.performance["confidence_lower"] = cot.confidence_lower;
    response.performance["confidence_upper"] = cot.confidence_upper;

    return {
        type: UPDATE_CREATOR_ALLOCATION,
        payload: response,
    };
};

// Add Creator Portfolio to Manager
export const addCreatorToManager = (portfolio, current) => async (dispatch) => {
    let new_current = current;
    try {
        new_current.preferences = portfolio.in_creation_preferences;
        new_current.assets = portfolio.current_creator_companies;
        new_current.allocation = portfolio.current_creator_allocation;
        new_current.performance = portfolio.current_creator_performance;

        let ticker_list = [];
        for (const [key, value] of Object.entries(current.allocation)) {
            ticker_list.push(key);
        }
        new_current.ticker = ticker_list;

        const res = await fetch("/node_portfolio_creator_to_manager", {
            method: "POST",
            body: JSON.stringify({
                portfolio_id: new_current.id,
                preferences: new_current.preferences,
                allocation: new_current.allocation,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });
        const data = await res.json();
        if (!data.error) {
            dispatch({
                type: ADD_CREATOR_TO_MANAGER,
                payload: new_current,
            });
            const id = Math.floor(Math.random() * 100);
            let type = "success";
            let msg =
                "The portfolio creator data was successfully transferred to a manager.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg
                ? data.error_msg
                : "An error occurred when transferring the portfolio creator data to a manager.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        // ERROR HANDLING
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occurred when transferring the portfolio creator data to a manager.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

const getIndexPerformance = async (ticker) => {
    try {
        const res = await fetch(`/node_index?ticker=${ticker}`);
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            // {time: [], close: []}

            let unix_time = [];

            data.time.map((ts) => {
                let date = new Date(ts);
                unix_time.push(date.getTime());
            });

            let transformed_performance_matrix = math.matrix([
                // math.dotMultiply(data.time, 1000),
                unix_time,
                data.close,
            ]);
            let performance = math.transpose(transformed_performance_matrix);

            return performance._data;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};

// PORTFOLIO TRACKER
// LOAD PERFORMANCE INITIALLY
export const loadPortfolioTrackerPerformance =
    (
        transactions_tracker,
        ticker_tracker,
        companies,
        portfolio_id,
        risk_free_rate
    ) =>
    async (dispatch) => {
        if (ticker_tracker !== null && ticker_tracker.length > 0) {
            let response = {
                performance: await calculateTrackerPerformanceDF(
                    companies,
                    transactions_tracker,
                    risk_free_rate
                ),
                portfolio_id: portfolio_id,
            };

            dispatch({
                type: UPDATE_PORTFOLIO_TRACKER_PERFORMANCE,
                payload: response,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "Could not load portfolio tracker performance.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// SUBMIT TRANSACTION FROM PORTFOLIO
export const submitPortfolioTransaction =
    (
        ticker_symbol,
        type,
        date,
        price_per_share,
        share_count,
        portfolio_id,
        user_id,
        portfolio_user_id,
        risk_free_rate
    ) =>
    async (dispatch) => {
        try {
            // SAVE TRANSACTION TO DATABASE
            if (portfolio_user_id === user_id) {
                const transaction_res = await fetch(
                    "/node_portfolio_transaction",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            ticker_symbol: ticker_symbol,
                            type: type,
                            date: date,
                            price_per_share: price_per_share,
                            share_count: share_count,
                            portfolio_id: portfolio_id,
                            user_id: user_id,
                            portfolio_user_id: portfolio_user_id,
                            risk_free_rate_required: risk_free_rate
                                ? false
                                : true,
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.token,
                        },
                    }
                );
                const transaction_response = await transaction_res.json();
                if (!transaction_response.error) {
                    let response = {
                        portfolio_id: portfolio_id,
                        ticker: transaction_response.new_ticker_tracker,
                        transactions: transaction_response.new_transactions,
                        allocation: transaction_response.new_allocation_tracker,
                        performance:
                            transaction_response.companies_data.length > 0
                                ? await calculateTrackerPerformanceDF(
                                      transaction_response.companies_data,
                                      transaction_response.new_transactions,
                                      risk_free_rate
                                          ? risk_free_rate
                                          : transaction_response.risk_free_rate
                                  )
                                : {
                                      performance: [],
                                      invested_sum: [],
                                  },
                        companies: transaction_response.companies_data,
                    };

                    // SET RISK FREE RATE
                    if (
                        transaction_response.risk_free_rate &&
                        transaction_response.risk_free_rate !== null
                    ) {
                        dispatch({
                            type: SET_RISK_FREE_RATE,
                            payload: transaction_response.risk_free_rate,
                        });
                    }

                    // CALCULATE PERFORMANCE FOR LINEAR REGRESSION
                    getPortfolioPerformance(
                        transaction_response.new_allocation_tracker,
                        transaction_response.companies_data,
                        "tracker",
                        portfolio_id
                    );

                    // CALCULATE PORTFOLIO RECOMMENDATIONS
                    getDiversificationIdeas(
                        transaction_response.new_allocation_tracker,
                        portfolio_id
                    );

                    const id = Math.floor(Math.random() * 100);
                    let type = transaction_response.error_type
                        ? transaction_response.error_type
                        : "success";
                    let msg = transaction_response.error_msg
                        ? transaction_response.error_msg
                        : "Portfolio transaction successfully added.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        2000
                    );

                    // CLOSE THE MODAL
                    dispatch({
                        type: ADD_TRANSACTION_FROM_PORTFOLIO,
                        payload: response,
                    });

                    dispatch({
                        type: OPEN_CHANGES_PORTFOLIO,
                        payload: false,
                    });
                } else {
                    console.log(transaction_response);
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = transaction_response.error_type
                        ? transaction_response.error_type
                        : "warning";
                    let msg = transaction_response.error_msg
                        ? transaction_response.error_msg
                        : "An error occured.";

                    // DO NOT CLOSE THE MODAL
                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = "warning";
                let msg =
                    "You have no permission to add a transaction to this portfolio.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            console.log(err);
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occured trying to add a transaction to this portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// EDIT TRANSACTION FROM PORTFOLIO
export const editPortfolioTransaction =
    (transaction, portfolio_id, user_id, portfolio_user_id, risk_free_rate) =>
    async (dispatch) => {
        try {
            // SAVE TRANSACTION TO DATABASE
            if (portfolio_user_id === user_id) {
                const transaction_res = await fetch(
                    "/node_portfolio_edit_transaction",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            transaction_id: transaction.id,
                            ticker_symbol: transaction.ticker,
                            type: transaction.type,
                            date: transaction.date,
                            price_per_share: transaction.price_per_share,
                            share_count: transaction.share_count,
                            portfolio_id: portfolio_id,
                            user_id: user_id,
                            portfolio_user_id: portfolio_user_id,
                            risk_free_rate_required: risk_free_rate
                                ? false
                                : true,
                        }),
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.token,
                        },
                    }
                );
                const transaction_response = await transaction_res.json();
                if (!transaction_response.error) {
                    let response = {
                        portfolio_id: portfolio_id,
                        ticker: transaction_response.new_ticker_tracker,
                        transactions: transaction_response.new_transactions,
                        allocation: transaction_response.new_allocation_tracker,
                        performance:
                            transaction_response.companies_data.length > 0
                                ? await calculateTrackerPerformanceDF(
                                      transaction_response.companies_data,
                                      transaction_response.new_transactions,
                                      risk_free_rate
                                          ? risk_free_rate
                                          : transaction_response.risk_free_rate
                                  )
                                : {
                                      performance: [],
                                      invested_sum: [],
                                  },
                        companies: transaction_response.companies_data,
                    };

                    // SET RISK FREE RATE
                    if (
                        transaction_response.risk_free_rate &&
                        transaction_response.risk_free_rate !== null
                    ) {
                        dispatch({
                            type: SET_RISK_FREE_RATE,
                            payload: transaction_response.risk_free_rate,
                        });
                    }

                    // CALCULATE PERFORMANCE FOR LINEAR REGRESSION
                    getPortfolioPerformance(
                        transaction_response.new_allocation_tracker,
                        transaction_response.companies_data,
                        "tracker",
                        portfolio_id
                    );

                    // CALCULATE PORTFOLIO RECOMMENDATIONS
                    getDiversificationIdeas(
                        transaction_response.new_allocation_tracker,
                        portfolio_id
                    );

                    const id = Math.floor(Math.random() * 100);
                    let type = transaction_response.error_type
                        ? transaction_response.error_type
                        : "success";
                    let msg = transaction_response.error_msg
                        ? transaction_response.error_msg
                        : "Portfolio transaction successfully edited.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        2000
                    );

                    // CLOSE THE MODAL
                    dispatch({
                        type: ADD_TRANSACTION_FROM_PORTFOLIO,
                        payload: response,
                    });

                    dispatch({
                        type: OPEN_CHANGES_PORTFOLIO,
                        payload: false,
                    });
                } else {
                    console.log(transaction_response);
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = transaction_response.error_type
                        ? transaction_response.error_type
                        : "warning";
                    let msg = transaction_response.error_msg
                        ? transaction_response.error_msg
                        : "An error occured.";

                    // DO NOT CLOSE THE MODAL
                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = "warning";
                let msg =
                    "You have no permission to add a transaction to this portfolio.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            console.log(err);
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occured trying to add a transaction to this portfolio.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// PORTFOLIO MANAGER
// Add/ Delete company to Portfolio(s)
export const addPortfolioCompany =
    (data, tickerSymbols, allocation, assets, action) => async (dispatch) => {
        try {
            let new_allocation;
            let new_ticker_symbols;
            if (allocation === null) {
                new_allocation = {};
            } else {
                new_allocation = allocation;
            }
            if (tickerSymbols === null) {
                new_ticker_symbols = [];
            } else {
                new_ticker_symbols = tickerSymbols;
            }
            let portfolio_value = 0;

            delete new_allocation[data.ticker];
            console.log(new_ticker_symbols, new_allocation);

            if (tickerSymbols.length === 0) {
                // PORTFOLIO MANAGER EMPTY NOW
                console.log("ticker symbols were empty");
                const res_portfolio = await fetch("/node_add_to_portfolio", {
                    method: "POST",
                    body: JSON.stringify({
                        new_ticker_list: [],
                        new_allocation: null,
                        user: data.user_id,
                        portfolio_id: data.portfolio,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const add_to_portfolio_data = await res_portfolio.json();
                if (!add_to_portfolio_data.error) {
                    const id = Math.floor(Math.random() * 100);
                    let type = "success";
                    let msg =
                        action === "delete"
                            ? `Deleted ${data.ticker} from your portfolio `
                            : `Added ${data.ticker} to your portfolio`;

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );

                    dispatch({
                        type: ADD_PORTFOLIO_COMPANY,
                        payload: add_to_portfolio_data,
                    });
                } else {
                    // ERROR HANDLING
                    console.log(add_to_portfolio_data);
                    const id = Math.floor(Math.random() * 100);
                    let type = add_to_portfolio_data.error_type
                        ? add_to_portfolio_data.error_type
                        : "warning";
                    let msg = add_to_portfolio_data.error_msg
                        ? add_to_portfolio_data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            } else {
                console.log("tickersymbols were not empty");
                // NEW PORTFOLIO MANAGER DATA CALCULATION
                const res = await fetch("/node_portfolio_companies", {
                    method: "POST",
                    body: JSON.stringify({
                        ticker: tickerSymbols,
                        risk_free_rate_required: false,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const companies_data = await res.json();

                if (!companies_data.error) {
                    companies_data.companies.map((company) => {
                        if (new_allocation[company.ticker]) {
                            new_allocation[company.ticker].value =
                                new_allocation[company.ticker].count *
                                company.stock_price;
                            portfolio_value +=
                                new_allocation[company.ticker].count *
                                company.stock_price;
                        } else {
                            new_allocation[company.ticker] = {
                                count: data.amount,
                                value: data.amount * company.stock_price,
                                status: true,
                                ticker: company.ticker,
                                weight: null,
                                recommend: null,
                            };
                            portfolio_value +=
                                data.amount * company.stock_price;
                        }
                    });

                    Object.keys(new_allocation).map(function (ticker) {
                        new_allocation[ticker].weight =
                            new_allocation[ticker].value / portfolio_value;
                    });

                    const res_portfolio = await fetch(
                        "/node_add_to_portfolio",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                new_ticker_list: tickerSymbols,
                                new_allocation: new_allocation,
                                user: data.user_id,
                                portfolio_id: data.portfolio,
                            }),
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.token,
                            },
                        }
                    );
                    const add_to_portfolio_data = await res_portfolio.json();
                    if (!add_to_portfolio_data.error) {
                        if (action === "delete") {
                            getPortfolioPerformance(
                                new_allocation,
                                companies_data.companies,
                                "manager",
                                data.portfolio
                            );
                        }

                        const id = Math.floor(Math.random() * 100);
                        let type = "success";
                        let msg =
                            action === "delete"
                                ? `Deleted ${data.ticker} from your portfolio `
                                : `Added ${data.ticker} to your portfolio`;

                        dispatch({
                            type: SET_ALERT,
                            payload: { msg, type, id },
                        });

                        setTimeout(
                            () => dispatch({ type: REMOVE_ALERT, payload: id }),
                            3000
                        );

                        dispatch({
                            type: ADD_PORTFOLIO_COMPANY,
                            payload: add_to_portfolio_data,
                        });
                    } else {
                        console.log(add_to_portfolio_data);
                        // ERROR HANDLING
                        const id = Math.floor(Math.random() * 100);
                        let type = add_to_portfolio_data.error_type
                            ? add_to_portfolio_data.error_type
                            : "warning";
                        let msg = add_to_portfolio_data.error_msg
                            ? add_to_portfolio_data.error_msg
                            : "An error occured.";

                        dispatch({
                            type: SET_ALERT,
                            payload: { msg, type, id },
                        });

                        setTimeout(
                            () => dispatch({ type: REMOVE_ALERT, payload: id }),
                            3000
                        );
                    }
                } else {
                    console.log(companies_data);
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = companies_data.error_type
                        ? companies_data.error_type
                        : "warning";
                    let msg = companies_data.error_msg
                        ? companies_data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            }
        } catch (err) {
            console.log("ADD TO PORTFOLIO ERROR: ", err);
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occurred while adding a company to your portfolio manager.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

export const updateCompanyShareCount = (
    ticker,
    count,
    new_allocation,
    companies_array,
    portfolio_type
) => {
    new_allocation[ticker].count = Number(count);
    if (count === 0) {
        new_allocation[ticker].status = false;
    } else {
        new_allocation[ticker].status = true;
    }

    let response = {
        id: portfolio_type,
        allocation: new_allocation,
        performance: calculatePortfolioPerformance(
            new_allocation,
            companies_array
        ),
    };

    let regression_length = null;
    companies_array.map((company) => {
        if (
            regression_length === null ||
            (regression_length !== null &&
                company.stock_prices.length < regression_length)
        ) {
            regression_length = company.stock_prices.length - 1;
        }
    });

    calulateLinearRegressionConfidence(
        response.performance.performance,
        regression_length
    );

    let cot = calculateRegressionConfidenceOverTime(
        response.performance.performance
    );

    response.performance["lin_reg"] = cot.lin_reg;
    response.performance["conf"] = cot.conf;
    response.performance["conf_2"] = cot.conf_2;
    response.performance["conf_3"] = cot.conf_3;
    response.performance["linear_regression_1y"] = cot.linear_regression_1y;
    response.performance["confidence_lower"] = cot.confidence_lower;
    response.performance["confidence_upper"] = cot.confidence_upper;

    if (portfolio_type === "creator") {
        return {
            type: UPDATE_CREATOR_ALLOCATION,
            payload: response,
        };
    } else {
        return {
            type: UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE,
            payload: response,
        };
    }
};

export const submitCompanyShareCount =
    (portfolio_id, allocation, ticker) => async (dispatch) => {
        try {
            const res = await fetch("/node_update_share_count_portfolio", {
                method: "POST",
                body: JSON.stringify({
                    portfolio_id: portfolio_id,
                    allocation: allocation,
                    ticker: ticker,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            console.log(data);

            if (!data.error) {
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "success";
                let msg = data.error_msg
                    ? data.error_msg
                    : "Portfolio manager successfully updated.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });
                dispatch({
                    type: OPEN_CHANGES_PORTFOLIO,
                    payload: false,
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    2000
                );
            } else {
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occured editing your position.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    2000
                );
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occured editing your position.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

export const getDiversificationIdeas = (allocation, id) => async (dispatch) => {
    let portfolio = [];

    Object.keys(allocation).map((asset) => {
        portfolio.push({ ticker: asset, weight: allocation[asset].weight });
    });

    try {
        const res = await fetch(
            "/node_portfolio_recommendation_diversification",
            {
                method: "POST",
                body: JSON.stringify({
                    portfolio: portfolio,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            }
        );
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            let strategy = [];
            let diversification = [];

            data.companies.map((company) => {
                if (data.diversification_data.includes(company.ticker)) {
                    diversification.push(company);
                }

                if (data.strategy_data.includes(company.ticker)) {
                    strategy.push(company);
                }
            });

            dispatch({
                type: SET_MANAGER_DIVERSIFICATION,
                payload: {
                    id: id,
                    diversification_data: diversification,
                    strategy_data: strategy,
                },
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occurred while calculating ideas for your portfolio.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// RESET PORTFOLIO BENCHMARKS
export const resetPortfolioBenchmarks = () => async (dispatch) => {
    try {
        dispatch({
            type: RESET_PORTFOLIO_BENCHMARKS,
            payload: [],
        });
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred while resetting the portfolio benchmarks.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};
// UPDATE PORTFOLIO BENCHMARKS
export const updatePortfolioBenchmarks = (benchmarks) => async (dispatch) => {
    let { portfolios, companies, indices, user_portfolio } = benchmarks;

    try {
        const res = await fetch("/node_portfolio_benchmark_prices", {
            method: "POST",
            body: JSON.stringify({
                portfolios: portfolios,
                companies: companies,
                indices: indices,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            console.log("BENCHMARKS RESULT: ", data);

            // INDICES
            let index_benchmarks = [];
            if (data.indices && data.indices.length > 0) {
                for (let idx = 0; idx < data.indices.length; idx++) {
                    if (data.indices[idx].prices) {
                        let loop_index_performance =
                            await calculateTrackerReferencePerformanceDF(
                                user_portfolio.companies,
                                user_portfolio.transactions_tracker,
                                user_portfolio.risk_free_rate,
                                data.indices[idx].prices,
                                {
                                    performance: user_portfolio.performance,
                                    invested_sum: user_portfolio.invested_sum,
                                },
                                "index"
                            );
                        let index_name;
                        indices.map((index) => {
                            if (index.ticker === data.indices[idx].ticker[0]) {
                                index_name = index.name;
                            }
                        });
                        index_benchmarks.push({
                            ticker: data.indices[idx].ticker[0],
                            prices: loop_index_performance,
                            name: index_name,
                        });
                    }
                }
            }

            // COMPANIES
            let company_benchmarks = [];
            if (data.companies && data.companies.length > 0) {
                for (let idx = 0; idx < data.companies.length; idx++) {
                    let calculate_benchmark = companies.some((company) => {
                        if (company.ticker === data.companies[idx].ticker) {
                            return true;
                        }

                        return false;
                    });

                    console.log(data.companies[idx].stock_prices);

                    if (calculate_benchmark) {
                        let loop_company_performance =
                            await calculateTrackerReferencePerformanceDF(
                                user_portfolio.companies,
                                user_portfolio.transactions_tracker,
                                user_portfolio.risk_free_rate,
                                data.companies[idx].stock_prices,
                                {
                                    performance: user_portfolio.performance,
                                    invested_sum: user_portfolio.invested_sum,
                                },
                                "company"
                            );

                        company_benchmarks.push({
                            ticker: data.companies[idx].ticker,
                            prices: loop_company_performance,
                            name: data.companies[idx].name,
                        });
                    }
                }
            }
            // PORTFOLIOS
            let portfolio_benchmarks = [];
            if (data.portfolios && data.portfolios.length > 0) {
                for (let idx = 0; idx < data.portfolios.length; idx++) {
                    // CREATE ARRAY ONLY OF THOSE COMPANIES INCLUDED IN THE BENCHMARK PORTFOLIO
                    let benchmark_portfolio_companies = [];
                    data.companies.map((company) => {
                        if (
                            Object.keys(
                                data.portfolios[idx].transactions
                            ).includes(company.ticker)
                        ) {
                            benchmark_portfolio_companies.push(company);
                        }
                    });

                    let loop_portfolio_performance =
                        await calculateTrackerPerformanceDF(
                            benchmark_portfolio_companies,
                            data.portfolios[idx].transactions,
                            user_portfolio.risk_free_rate
                        );

                    portfolio_benchmarks.push({
                        ticker: data.portfolios[idx].id,
                        prices: loop_portfolio_performance,
                        name: data.portfolios[idx].name,
                    });
                }
            }

            dispatch({
                type: UPDATE_PORTFOLIO_BENCHMARKS,
                payload: [
                    ...portfolio_benchmarks,
                    ...company_benchmarks,
                    ...index_benchmarks,
                ],
            });
        } else {
            // ERROR HANDLING

            console.log("Server returns error");

            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg
                ? data.error_msg
                : "An error occurred while quering the benchmarks for your portfolio.";
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });
            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log("Client returns error", err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occurred while quering the benchmarks for your portfolio.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// CALC PORTFOLIO MANAGER PERFORMANCE
const calculatePortfolioPerformance = (asset_allocation, companies_array) => {
    // NEW
    let pre_sorted_companies = companies_array.sort((a, b) =>
        a.stock_prices.length > b.stock_prices.length ? -1 : 1
    );
    // console.log("SORTED ARRAY", sorted_companies);

    let sorted_companies_object = {};
    let sorted_companies = [];
    let total_matrix_length;
    let total_paid = 0;
    let total_current_value = 0;

    // Create sorted companies object
    pre_sorted_companies.map((asset) => {
        if (
            asset_allocation &&
            asset_allocation[asset.ticker] &&
            asset_allocation[asset.ticker].status
        ) {
            sorted_companies_object[asset.ticker] = asset;
            sorted_companies.push(asset);
        }
    });

    // Initiate matrix with first company
    // Transpose the matrix and multiply it by the number of shares
    let total_matrix = math.matrix(sorted_companies[0].stock_prices);
    total_matrix_length = sorted_companies[0].stock_prices.length;

    // console.log("TOTAL MATRIX LENGTH", total_matrix_length);

    let total_PerformanceMatrix = [
        math.transpose(math.column(total_matrix, 0)),
        math.transpose(math.column(total_matrix, 5)),
    ];

    // Multiply time by 1000 for charts
    total_PerformanceMatrix[0]._data[0] = math.multiply(
        total_PerformanceMatrix[0]._data[0],
        1000
    );

    // Multiply value by share count
    total_PerformanceMatrix[1]._data[0] = math.multiply(
        total_PerformanceMatrix[1]._data[0],
        asset_allocation[sorted_companies[0].ticker].count
    );

    // console.log("NEW START MATRIX", total_PerformanceMatrix);

    // Loop through the remaining companies
    // Add asset values to total_PerformanceMatrix
    sorted_companies.map((asset) => {
        if (asset.ticker !== sorted_companies[0].ticker) {
            // Initiate matrix for the current company
            // Transpose the matrix and multiply it by the number of shares
            let current_stock_prices = asset.stock_prices;
            let current_matrix_length = asset.stock_prices.length;
            // console.log("CURRENT MATRIX LENGTH", current_matrix_length);

            let current_matrix = math.matrix(current_stock_prices);

            let current_PerformanceMatrix = [
                math.transpose(math.column(current_matrix, 0)),
                math.transpose(math.column(current_matrix, 5)),
            ];

            current_PerformanceMatrix[1]._data[0] = math.multiply(
                current_PerformanceMatrix[1]._data[0],
                asset_allocation[asset.ticker].count
            );

            let current_prices_to_add;

            // Check if current stock prices are shorter than total
            if (current_matrix_length < total_matrix_length) {
                // Create placeholder with missing length
                let zerosPrices = math.zeros(
                    total_matrix_length - current_matrix_length
                )._data;
                // Create array for later addition

                // console.log("current_PerformanceMatrix[1]._data[0]", current_PerformanceMatrix[1]._data[0])
                current_prices_to_add = zerosPrices.concat(
                    current_PerformanceMatrix[1]._data[0]
                );
            } else {
                //
                current_prices_to_add = current_PerformanceMatrix[1]._data[0];
            }

            // Create time array placaholder for addition
            let zerosTime = math.zeros(total_matrix_length)._data;

            // console.log("CURRENT TOTAL MATRIX", total_PerformanceMatrix);

            let total_time = total_PerformanceMatrix[0]._data[0];
            let total_performance = total_PerformanceMatrix[1]._data[0];

            total_PerformanceMatrix = math.add(
                [[total_time], [total_performance]],
                [[zerosTime], [current_prices_to_add]]
            );
            total_PerformanceMatrix = [
                math.matrix(total_PerformanceMatrix[0]),
                math.matrix(total_PerformanceMatrix[1]),
            ];

            // console.log("CURRENT PRICES", current_prices_to_add);
        }
    });

    // Transform the matrix back to the right format
    let transform_matrix = math.matrix([
        total_PerformanceMatrix[0]._data[0],
        total_PerformanceMatrix[1]._data[0],
    ]);
    let performance_data = math.transpose(transform_matrix);

    let performance = performance_data._data.slice(
        performance_data._data.length - 800,
        performance_data._data.length
    );

    // console.log("FINAL RESULT", performance_data);

    let performance_return = {
        performance: performance,
        buy_in_total_price: total_paid,
    };

    return performance_return;
    // return performance = transformbitte._data;
};

// CALC PORTFOLIO TRACKER PERFORMANCE
const calculateTrackerPerformanceDF = async (
    companies_array,
    transactions,
    risk_free_rate
) => {
    // SORT ARRAY BY STOCK PRICES LENGTH
    let sorted_companies;

    if (
        companies_array &&
        companies_array !== null &&
        companies_array.length > 1
    ) {
        sorted_companies = companies_array.sort((a, b) =>
            a.stock_prices.length > b.stock_prices.length ? -1 : 1
        );
    } else {
        sorted_companies = companies_array;
    }

    // MAKE THE ARRAY OF COMPANIES AN OBJECT OF COMPANIES
    let companies = {};
    sorted_companies.map((company) =>
        Object.keys(transactions).includes(company.ticker)
            ? (companies[company.ticker] = company)
            : null
    );

    let first_ticker = Object.keys(transactions)[0];
    let performance = [];
    let invested_capital = [];

    // SAVE EARLIEST TRANSACTION TO CLEAN DATA LATER
    let earliest_transaction_date = null;

    // CREATE 2XN MATRIX WITH FIRST COMPANIES STOCK PRICES
    let initiate_matrix = math.matrix(companies[first_ticker].stock_prices);
    let initiate_PerformanceMatrix = [
        math.transpose(math.column(initiate_matrix, 0)),
        math.transpose(math.column(initiate_matrix, 5)),
    ];

    // INITIATE A VARIABLE FOR THE PROFIT AND LOSS MADE
    let profit_and_loss = 0;

    // GET THE DATES OF THE INITIAL MATRIX AS AN ARRAY
    let initial_dates = initiate_PerformanceMatrix[0]._data[0];
    // CREATE ZEROS ARRAYS FOR BOTH THE NUMBER OF SHARES AND THE INVESTMENT IN THE COMPANY
    let first_company_share_counts = math.zeros(initial_dates.length)._data;
    let first_company_investment = math.zeros(initial_dates.length)._data;
    let first_company_profit_and_loss = math.zeros(initial_dates.length)._data;

    // LOOP TRANSACTIONS AND MODIFY SHARE COUNTS/ INVESTMENTS
    // JOUT: {
    //     1643637452000: {
    //         id: 1,
    //         type: "buy",
    //         share_count: 13,
    //         current_count: 13,
    //         price_per_share: 87.47,
    //         transaction_value: 1137.11,
    //     },
    // },

    const first_company_transactions = sort_array_of_objects_by_value_number(
        transactions[first_ticker],
        "date"
    );

    // STORE SOLD AMOUNT OF SHARES UP TO LOOP POINT HERE, TO CALCULATE INVEST AND PL
    let current_already_sold_shares_first_ticker = 0;

    // MAP THE FIRST COMPANY's TRANSACTIONS
    first_company_transactions.map((transaction, idx) => {
        let parsed_transaction_date = Number(transaction.date) / 1000;

        // IN CASE THE DATE IS LOWER THAN THE CURRENT LOWEST TRANSACTION DATE, SET IT THIS ONE
        if (
            earliest_transaction_date === null ||
            earliest_transaction_date > parsed_transaction_date
        ) {
            earliest_transaction_date = parsed_transaction_date;
        }

        // FIND THIS DATES INDEX IN THE INITIAL DATES ARRAY
        let index_transaction_date = closest(
            parsed_transaction_date,
            initial_dates
        );

        if (first_company_transactions[idx].type === "buy") {
            // GET ARRAY WITH AMOUNT OF OWNED SHARES BEFORE THE TRANSACTION DATE
            let pre_buy_share_count = first_company_share_counts.slice(
                0,
                index_transaction_date
            );

            // GET ARRAY WITH AMOUNT OF OWNED SHARES AT AND AFTER TRANSACTION
            let post_buy_share_count = first_company_share_counts.slice(
                index_transaction_date
            );

            // GET ARRAY WITH AMOUNT OF BOUGHT SHARES AT AND AFTER TRANSACTION
            let post_buy_share_count_to_add = math.multiply(
                math.ones(post_buy_share_count.length),
                first_company_transactions[idx].share_count
            );

            // ADD OWNED AND BOUGHT ARRAYS
            let new_post_buy_share_count = math.add(
                post_buy_share_count,
                post_buy_share_count_to_add
            )._data;

            // OVERWRITE OLD SHARE COUNT
            first_company_share_counts = pre_buy_share_count.concat(
                new_post_buy_share_count
            );

            // INVESTMENT
            let pre_buy_investment = first_company_investment.slice(
                0,
                index_transaction_date
            );
            // ADD VALUE TO POST BUY INVESTMENT SUM
            // OLD SUM
            let post_buy_investment = first_company_investment.slice(
                index_transaction_date
            );
            // SUM TO ADD
            let post_buy_investment_to_add = math.multiply(
                math.multiply(
                    math.ones(post_buy_share_count.length),
                    first_company_transactions[idx].price_per_share
                ),
                first_company_transactions[idx].share_count
            );
            // NEW SUM
            let new_post_buy_investment = math.add(
                post_buy_investment,
                post_buy_investment_to_add
            )._data;
            // OVERWRITE OLD INVESTMENT
            first_company_investment = pre_buy_investment.concat(
                new_post_buy_investment
            );
        } else {
            // GET ARRAY WITH AMOUNT OF OWNED SHARES BEFORE THE TRANSACTION DATE
            let pre_sell_share_count = first_company_share_counts.slice(
                0,
                index_transaction_date
            );
            /// GET ARRAY WITH AMOUNT OF OWNED SHARES AT AND AFTER TRANSACTION
            let post_sell_share_count = first_company_share_counts.slice(
                index_transaction_date
            );
            // GET ARRAY WITH AMOUNT OF SOLD SHARES AT AND AFTER TRANSACTION
            let post_sell_share_count_to_add = math.multiply(
                math.multiply(
                    math.ones(post_sell_share_count.length),
                    first_company_transactions[idx].share_count
                ),
                -1
            );
            // ADD THE NEW SHARE COUNTS TO THE OLD ONES
            let new_post_sell_share_count = math.add(
                post_sell_share_count,
                post_sell_share_count_to_add
            )._data;
            // OVERWRITE OLD SHARE COUNT
            first_company_share_counts = pre_sell_share_count.concat(
                new_post_sell_share_count
            );

            // INVESTMENT, PROFIT AND LOSS
            // SPLIT THE INVESTMENT ARRAY
            // SUM OF INVESTMENTS BEFORE NEW TRANSACTION
            let pre_sell_investment = first_company_investment.slice(
                0,
                index_transaction_date
            );

            // SUM OF INVESTMENTS AFTER NEW TRANSACTION
            let post_sell_investment = first_company_investment.slice(
                index_transaction_date
            );

            // DO THE SAME FOR THE PROFIT AND LOSS
            let pre_sell_profit_and_loss = first_company_profit_and_loss.slice(
                0,
                index_transaction_date
            );

            // SUM OF PROFIT AND LOSS AFTER NEW TRANSACTION
            let post_sell_profit_and_loss = first_company_profit_and_loss.slice(
                index_transaction_date
            );

            //
            //
            // TRANSACTIONS LOOPEN UND DIE ANZAHL DER AKTIEN ZU DEM DAMALIGEN KAUFPREIS ABZIEHEN
            // BEREITS VERKAUFTE ANZAHL AKTIEN AUßERHALB VOm UNTERNEHMENS LOOP SPEICHERN UND DIE IM FALLE VORHERIGER SELL ORDERS ÜBERSPRINGEN
            //
            // AMOUNT OF SHARES SOLD THIS TRANSACTION: first_company_transactions[idx].share_count
            // SAVE ALREADY SOLD AMOUNT OF SHARES FROM THIS LOOP
            let loop_already_sold_shares_first_ticker = 0;
            let loop_this_transactions_eq_share_count = 0;
            let loop_this_transactions_eq_buy_value = 0;

            for (let i_sell = 0; i_sell <= idx; i_sell++) {
                if (first_company_transactions[i_sell].type === "buy") {
                    if (
                        current_already_sold_shares_first_ticker >
                        loop_already_sold_shares_first_ticker +
                            first_company_transactions[i_sell].share_count
                    ) {
                        // ALREADY SOLD THIS SHARE COUNT
                        loop_already_sold_shares_first_ticker +=
                            first_company_transactions[i_sell].share_count;
                    } else {
                        // DID NOT YET SELL THIS LOOPS SHARE COUNT
                        if (
                            first_company_transactions[i_sell].share_count +
                                loop_this_transactions_eq_share_count <=
                            first_company_transactions[idx].share_count
                        ) {
                            // THIS COUNT IS LESS THEN REQUIRED
                            loop_this_transactions_eq_share_count +=
                                first_company_transactions[i_sell].share_count;
                            loop_this_transactions_eq_buy_value +=
                                first_company_transactions[i_sell].share_count *
                                first_company_transactions[i_sell]
                                    .price_per_share;
                        } else {
                            // THIS COUNT IS MORE THAN WHAT IS SOLD
                            // CALC HOW MANY ARE STILL MISSING
                            let missing_shares_to_sell =
                                first_company_transactions[idx].share_count -
                                loop_this_transactions_eq_share_count;

                            loop_this_transactions_eq_share_count +=
                                missing_shares_to_sell;
                            loop_this_transactions_eq_buy_value +=
                                missing_shares_to_sell *
                                first_company_transactions[i_sell]
                                    .price_per_share;
                            break;
                        }
                    }
                } else {
                    // SELL TRANSACTION
                }

                // first_company_transactions[i_sell].share_count
            }

            // ADD THE NOW SOLD SHARE COUNT TO THE TOTAL REGARDING THIS COMPANY
            current_already_sold_shares_first_ticker +=
                loop_this_transactions_eq_share_count;
            // NEED:
            // VALUE OF BOUGHT SHARES THAT IS NOW BEING SOLD
            // DIFFERENCE OF SELL VALUE AND THE ABOVE

            // INVEST: CALCULATE THE EQUIVALENT VALUE THE SHARES WERE BOUGHT FOR, MAKE IT AN ARRAY TO DEAL WITH IT BELOW
            let post_sell_investment_correction = math.multiply(
                math.multiply(
                    math.ones(post_sell_share_count.length),
                    loop_this_transactions_eq_buy_value
                ),
                -1
            );

            // P/L: ADJUST THE PROFIT AND LOSS
            profit_and_loss +=
                first_company_transactions[idx].price_per_share *
                    first_company_transactions[idx].share_count -
                loop_this_transactions_eq_buy_value;

            let post_sell_profit_and_loss_correction = math.multiply(
                math.ones(post_sell_share_count.length),
                profit_and_loss
            );

            // INVEST: SUBSTRACT THE ORIGINAL BUY VALUE FROM OLD INVESTED SUM POST TRANSACTION DATE
            let new_post_sell_investment = math.add(
                post_sell_investment,
                post_sell_investment_correction
            )._data;
            // INVEST: OVERWRITE OLD INVESTMENT
            first_company_investment = pre_sell_investment.concat(
                new_post_sell_investment
            );

            // P/L:
            let new_post_sell_profit_and_loss = math.add(
                post_sell_profit_and_loss,
                post_sell_profit_and_loss_correction
            )._data;
            // P/L: OVERWRITE OLD PROFIT AND LOSS
            first_company_profit_and_loss = pre_sell_profit_and_loss.concat(
                new_post_sell_profit_and_loss
            );
        }
    });

    // MULTIPLY HISTORIC SHARE COUNT TO SHARE PRICE
    initiate_PerformanceMatrix[1]._data[0] = math.dotMultiply(
        initiate_PerformanceMatrix[1]._data[0],
        first_company_share_counts
    );

    // INITIATE PERFORMANCE DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_performance_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            [first_ticker]: initiate_PerformanceMatrix[1]._data[0],
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // INITIATE INVESTED SUM DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_invested_sum_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            [first_ticker]: first_company_investment,
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // INITIATE PROFIT AND LOSS DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_profit_and_loss_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            [first_ticker]: first_company_profit_and_loss,
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // LOOP TRANSACTIONS, PERFORM PREVIOUS CALCULATIONS AND MERGE TO THE DATAFRAME
    Object.keys(transactions).map((asset) => {
        if (asset !== first_ticker) {
            let loop_matrix = math.matrix(companies[asset].stock_prices);
            let loop_PerformanceMatrix = [
                math.transpose(math.column(loop_matrix, 0)),
                math.transpose(math.column(loop_matrix, 5)),
            ];

            // START CALCULATION

            // LOOP DATES ARRAY
            let loop_dates = loop_PerformanceMatrix[0]._data[0];
            // CREATE ZEROS ARRAY, BUY IN PRICE ARRAY
            let loop_company_share_counts = math.zeros(loop_dates.length)._data;
            let loop_company_investment = math.zeros(loop_dates.length)._data;
            let loop_company_profit_and_loss = math.zeros(
                loop_dates.length
            )._data;

            // LOOP TRANSACTIONS AND MODIFY SHARE COUNTS/ INVESTMENTS
            // JOUT: {
            //     1643637452000: {
            //         id: 1,
            //         type: "buy",
            //         share_count: 13,
            //         current_count: 13,
            //         price_per_share: 87.47,
            //         transaction_value: 1137.11,
            //     },
            // },

            const loop_company_transactions =
                sort_array_of_objects_by_value_number(
                    transactions[asset],
                    "date"
                );

            // STORE SOLD AMOUNT OF SHARES UP TO LOOP POINT HERE, TO CALCULATE INVEST AND PL
            let current_already_sold_shares_loop_ticker = 0;

            loop_company_transactions.map((transaction, idx) => {
                let loop_parsed_transaction_date =
                    Number(transaction.date) / 1000;
                if (
                    earliest_transaction_date === null ||
                    earliest_transaction_date > loop_parsed_transaction_date
                ) {
                    earliest_transaction_date = loop_parsed_transaction_date;
                }
                let loop_index_transaction_date = closest(
                    loop_parsed_transaction_date,
                    loop_dates
                );
                if (loop_company_transactions[idx].type === "buy") {
                    // AMOUNT OF SHARES
                    let pre_buy_share_count = loop_company_share_counts.slice(
                        0,
                        loop_index_transaction_date
                    );
                    // ADD NEW SHARES TO POST BUY SHARE COUNT
                    // OLD COUNT
                    let post_buy_share_count = loop_company_share_counts.slice(
                        loop_index_transaction_date
                    );
                    // COUNT TO ADD
                    let post_buy_share_count_to_add = math.multiply(
                        math.ones(post_buy_share_count.length),
                        loop_company_transactions[idx].share_count
                    );
                    // NEW  COUNT
                    let new_post_buy_share_count = math.add(
                        post_buy_share_count,
                        post_buy_share_count_to_add
                    )._data;
                    // OVERWRITE OLD SHARE COUNT
                    loop_company_share_counts = pre_buy_share_count.concat(
                        new_post_buy_share_count
                    );

                    // INVESTMENT
                    let pre_buy_investment = loop_company_investment.slice(
                        0,
                        loop_index_transaction_date
                    );
                    // ADD VALUE TO POST BUY INVESTMENT SUM
                    // OLD SUM
                    let post_buy_investment = loop_company_investment.slice(
                        loop_index_transaction_date
                    );
                    // SUM TO ADD
                    let post_buy_investment_to_add = math.multiply(
                        math.multiply(
                            math.ones(post_buy_share_count.length),
                            loop_company_transactions[idx].price_per_share
                        ),
                        loop_company_transactions[idx].share_count
                    );
                    // NEW SUM
                    let new_post_buy_investment = math.add(
                        post_buy_investment,
                        post_buy_investment_to_add
                    )._data;
                    // OVERWRITE OLD INVESTMENT
                    loop_company_investment = pre_buy_investment.concat(
                        new_post_buy_investment
                    );
                } else {
                    // AMOUNT OF SHARES
                    let pre_sell_share_count = loop_company_share_counts.slice(
                        0,
                        loop_index_transaction_date
                    );
                    // ADD NEW SHARES TO POST BUY SHARE COUNT
                    // OLD COUNT
                    let post_sell_share_count = loop_company_share_counts.slice(
                        loop_index_transaction_date
                    );
                    // COUNT TO ADD
                    let post_sell_share_count_to_add = math.multiply(
                        math.multiply(
                            math.ones(post_sell_share_count.length),
                            loop_company_transactions[idx].share_count
                        ),
                        -1
                    );
                    // NEW  COUNT
                    let new_post_sell_share_count = math.add(
                        post_sell_share_count,
                        post_sell_share_count_to_add
                    )._data;
                    // OVERWRITE OLD SHARE COUNT
                    loop_company_share_counts = pre_sell_share_count.concat(
                        new_post_sell_share_count
                    );

                    // INVESTMENT/ PROFIT AND LOSS
                    let pre_sell_investment = loop_company_investment.slice(
                        0,
                        loop_index_transaction_date
                    );
                    // ADD VALUE TO POST BUY INVESTMENT SUM
                    // OLD SUM
                    let post_sell_investment = loop_company_investment.slice(
                        loop_index_transaction_date
                    );

                    // DO THE SAME FOR THE PROFIT AND LOSS
                    let pre_sell_profit_and_loss =
                        loop_company_profit_and_loss.slice(
                            0,
                            loop_index_transaction_date
                        );

                    // SUM OF PROFIT AND LOSS AFTER NEW TRANSACTIONloop_index_transaction_date
                    let post_sell_profit_and_loss =
                        loop_company_profit_and_loss.slice(
                            loop_index_transaction_date
                        );

                    let loop_already_sold_shares_loop_ticker = 0;
                    let loop_this_transactions_eq_share_count = 0;
                    let loop_this_transactions_eq_buy_value = 0;

                    for (let i_sell = 0; i_sell <= idx; i_sell++) {
                        if (loop_company_transactions[i_sell].type === "buy") {
                            if (
                                current_already_sold_shares_loop_ticker >
                                loop_already_sold_shares_loop_ticker +
                                    loop_company_transactions[i_sell]
                                        .share_count
                            ) {
                                // ALREADY SOLD THIS SHARE COUNT
                                loop_already_sold_shares_loop_ticker +=
                                    loop_company_transactions[i_sell]
                                        .share_count;
                            } else {
                                // DID NOT YET SELL THIS LOOPS SHARE COUNT
                                if (
                                    loop_company_transactions[i_sell]
                                        .share_count +
                                        loop_this_transactions_eq_share_count <=
                                    loop_company_transactions[idx].share_count
                                ) {
                                    // THIS COUNT IS LESS THEN REQUIRED
                                    loop_this_transactions_eq_share_count +=
                                        loop_company_transactions[i_sell]
                                            .share_count;
                                    loop_this_transactions_eq_buy_value +=
                                        loop_company_transactions[i_sell]
                                            .share_count *
                                        loop_company_transactions[i_sell]
                                            .price_per_share;
                                } else {
                                    // THIS COUNT IS MORE THAN WHAT IS SOLD
                                    // CALC HOW MANY ARE STILL MISSING
                                    let missing_shares_to_sell =
                                        loop_company_transactions[idx]
                                            .share_count -
                                        loop_this_transactions_eq_share_count;

                                    loop_this_transactions_eq_share_count +=
                                        missing_shares_to_sell;
                                    loop_this_transactions_eq_buy_value +=
                                        missing_shares_to_sell *
                                        loop_company_transactions[i_sell]
                                            .price_per_share;
                                    break;
                                }
                            }
                        } else {
                            // SELL TRANSACTION
                        }

                        // loop_company_transactions[i_sell].share_count
                    }

                    // ADD THE NOW SOLD SHARE COUNT TO THE TOTAL REGARDING THIS COMPANY
                    current_already_sold_shares_loop_ticker +=
                        loop_this_transactions_eq_share_count;
                    // NEED:
                    // VALUE OF BOUGHT SHARES THAT IS NOW BEING SOLD
                    // DIFFERENCE OF SELL VALUE AND THE ABOVE

                    // INVEST: CALCULATE THE EQUIVALENT VALUE THE SHARES WERE BOUGHT FOR, MAKE IT AN ARRAY TO DEAL WITH IT BELOW
                    let post_sell_investment_correction = math.multiply(
                        math.multiply(
                            math.ones(post_sell_share_count.length),
                            loop_this_transactions_eq_buy_value
                        ),
                        -1
                    );

                    // P/L: ADJUST THE PROFIT AND LOSS
                    profit_and_loss +=
                        loop_company_transactions[idx].price_per_share *
                            loop_company_transactions[idx].share_count -
                        loop_this_transactions_eq_buy_value;

                    let post_sell_profit_and_loss_correction = math.multiply(
                        math.ones(post_sell_share_count.length),
                        profit_and_loss
                    );

                    // INVEST: SUBSTRACT THE ORIGINAL BUY VALUE FROM OLD INVESTED SUM POST TRANSACTION DATE
                    let new_post_sell_investment = math.add(
                        post_sell_investment,
                        post_sell_investment_correction
                    )._data;
                    // INVEST: OVERWRITE OLD INVESTMENT
                    loop_company_investment = pre_sell_investment.concat(
                        new_post_sell_investment
                    );

                    // P/L:
                    let new_post_sell_profit_and_loss = math.add(
                        post_sell_profit_and_loss,
                        post_sell_profit_and_loss_correction
                    )._data;
                    // P/L: OVERWRITE OLD PROFIT AND LOSS
                    loop_company_profit_and_loss =
                        pre_sell_profit_and_loss.concat(
                            new_post_sell_profit_and_loss
                        );
                }
            });

            // MULTIPLY HISTORIC SHARE COUNT TO SHARE PRICE
            loop_PerformanceMatrix[1]._data[0] = math.dotMultiply(
                loop_PerformanceMatrix[1]._data[0],
                loop_company_share_counts
            );

            // INITIATE PERFORMANCE DATA FRAME WITH TIME STAMPS AS INDEX
            let loop_performance_df = new dfd.DataFrame(
                {
                    time: loop_PerformanceMatrix[0]._data[0],
                    [asset]: loop_PerformanceMatrix[1]._data[0],
                },
                { index: loop_PerformanceMatrix[0]._data[0] }
            );

            // INITIATE INVESTED SUM DATA FRAME WITH TIME STAMPS AS INDEX
            let loop_invested_sum_df = new dfd.DataFrame(
                {
                    time: loop_PerformanceMatrix[0]._data[0],
                    [asset]: loop_company_investment,
                },
                { index: loop_PerformanceMatrix[0]._data[0] }
            );

            // INITIATE PROFIT AND LOSS DATA FRAME WITH TIME STAMPS AS INDEX
            let loop_profit_and_loss_df = new dfd.DataFrame(
                {
                    time: loop_PerformanceMatrix[0]._data[0],
                    [asset]: loop_company_profit_and_loss,
                },
                { index: loop_PerformanceMatrix[0]._data[0] }
            );

            // END CALCULATION

            let merge_performance_df = dfd.merge({
                left: initiate_performance_df,
                right: loop_performance_df,
                on: ["time"],
                how: "inner",
            });
            let merge_invested_sum_df = dfd.merge({
                left: initiate_invested_sum_df,
                right: loop_invested_sum_df,
                on: ["time"],
                how: "inner",
            });
            let merge_profit_and_loss_df = dfd.merge({
                left: initiate_profit_and_loss_df,
                right: loop_profit_and_loss_df,
                on: ["time"],
                how: "inner",
            });

            initiate_performance_df = merge_performance_df;
            initiate_invested_sum_df = merge_invested_sum_df;
            initiate_profit_and_loss_df = merge_profit_and_loss_df;
        }
    });

    // SUM UP BOTH PERFORMANCE AND INVESTED SUM

    // PERFORMANCE DATA
    initiate_performance_df.set_index({ column: "time", inplace: true });
    initiate_performance_df.drop({ columns: ["time"], inplace: true });

    // console.log("initiate_performance_df: ", initiate_performance_df);

    let total_performance_df = initiate_performance_df.sum({ axis: 0 });

    // console.log("total_performance_df: ", total_performance_df);

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let index_earliest_transaction_date = closest(
        earliest_transaction_date,
        total_performance_df.index
    );

    let final_performance_dates = total_performance_df.index.slice(
        index_earliest_transaction_date
    );
    let final_performance_values = total_performance_df.values.slice(
        index_earliest_transaction_date
    );

    // console.log("final_performance_dates", final_performance_dates);

    let total_PerformanceMatrix = math.matrix([
        math.multiply(final_performance_dates, 1000),
        final_performance_values,
    ]);

    // TRANSPOSE MATRIX TO DISPLAY
    let final_performance_data = math.transpose(total_PerformanceMatrix)._data;

    // INVESTMENT DATA
    initiate_invested_sum_df.set_index({ column: "time", inplace: true });
    initiate_invested_sum_df.drop({ columns: ["time"], inplace: true });
    let total_invested_sum_df = initiate_invested_sum_df.sum({ axis: 0 });

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let final_investment_dates = total_invested_sum_df.index.slice(
        index_earliest_transaction_date
    );
    let final_investment_values = total_invested_sum_df.values.slice(
        index_earliest_transaction_date
    );

    let total_InvestedSumMatrix = math.matrix([
        math.multiply(final_investment_dates, 1000),
        final_investment_values,
    ]);

    // CALCULATE RELATIVE PERFORMANCE
    let total_relativePerformanceMatrix = math.matrix([
        math.multiply(final_performance_dates, 1000),
        math.add(
            math.dotMultiply(
                math.add(
                    math.dotDivide(
                        final_performance_values,
                        final_investment_values
                    ),
                    -1
                ),
                100
            ),
            100
        ),
    ]);

    // PROFIT AND LOSS DATA
    initiate_profit_and_loss_df.set_index({ column: "time", inplace: true });
    initiate_profit_and_loss_df.drop({ columns: ["time"], inplace: true });
    let total_profit_and_loss_df = initiate_profit_and_loss_df.sum({ axis: 0 });

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let final_profit_and_loss_dates = total_profit_and_loss_df.index.slice(
        index_earliest_transaction_date
    );
    let final_profit_and_loss_values = total_profit_and_loss_df.values.slice(
        index_earliest_transaction_date
    );

    let total_ProfitAndLossMatrix = math.matrix([
        math.multiply(final_profit_and_loss_dates, 1000),
        final_profit_and_loss_values,
    ]);

    // TRANSPOSE MATRICES TO DISPLAY
    let final_invested_sum_data = math.transpose(total_InvestedSumMatrix)._data;
    let final_profit_and_loss_data = math.transpose(
        total_ProfitAndLossMatrix
    )._data;
    let final_relative_performance_data = math.transpose(
        total_relativePerformanceMatrix
    )._data;

    // console.log("SHARPE: ", final_performance_data, risk_free_rate);
    let sharpe_ratio = sharpe(final_performance_data, risk_free_rate);

    let performance_return = {
        performance: final_performance_data,
        invested_sum: final_invested_sum_data,
        relative_performance: final_relative_performance_data,
        realized_profit_and_loss_over_time: final_profit_and_loss_data,
        realized_profit_and_loss: profit_and_loss,
        sharpe_ratio: sharpe_ratio,
    };

    return performance_return;
};

// CALC PORTFOLIO TRACKER PERFORMANCE
const calculateTrackerReferencePerformanceDF = async (
    companies_array,
    transactions,
    risk_free_rate,
    market,
    tracker_performance,
    type
) => {
    console.log(type);
    console.log(market);

    // CREATE A MATRIX WITH THE USERS PORTFOLIO PERFORMANCE
    let user_matrix = math.matrix(tracker_performance.performance);
    let user_PerformanceMatrix = [
        math.transpose(math.column(user_matrix, 0)),
        math.transpose(math.column(user_matrix, 1)),
    ];
    let user_dates = user_PerformanceMatrix[0]._data[0];
    let user_values = user_PerformanceMatrix[1]._data[0];

    // CREATE A MATRIX WITH THE USERS INVESTMENTS OVER TIME
    let user_invest_matrix = math.matrix(tracker_performance.invested_sum);
    let user_InvestMatrix = [
        math.transpose(math.column(user_invest_matrix, 0)),
        math.transpose(math.column(user_invest_matrix, 1)),
    ];
    let user_invest_dates = user_InvestMatrix[0]._data[0];
    let user_invest_values = user_InvestMatrix[1]._data[0];

    // SORT ARRAY BY STOCK PRICES LENGTH
    let sorted_companies;
    if (
        companies_array &&
        companies_array !== null &&
        companies_array.length > 1
    ) {
        sorted_companies = companies_array.sort((a, b) =>
            a.stock_prices.length > b.stock_prices.length ? -1 : 1
        );
    } else {
        sorted_companies = companies_array;
    }
    // MAKE THE ARRAY OF COMPANIES AN OBJECT OF COMPANIES
    let companies = {};
    sorted_companies.map((company) =>
        Object.keys(transactions).includes(company.ticker)
            ? (companies[company.ticker] = company)
            : null
    );

    // CREATE ARRAY OF TRANSACTIONS SORTED BY DATE
    let all_transactions = [];
    Object.keys(transactions).map((asset) => {
        transactions[asset].map((transaction) => {
            let updated_transaction = transaction;
            updated_transaction["ticker"] = asset;
            all_transactions.push(transaction);
        });
    });
    let sorted_transactions = all_transactions.sort((a, b) =>
        a.date > b.date ? 1 : -1
    );
    let earliest_transaction_date = sorted_transactions[0].date;

    let total_profit_and_loss = 0;

    // CREATE OBJECT OF SORTED TRANSACTIONS BY DATE TO ADEQUATELY CALCULATE INVEST/ PL
    let sorted_transactions_object = {};
    sorted_transactions.map((t, idx) => {
        sorted_transactions_object[`${idx}`] = t;
    });

    // CREATE MATRIX WITH FIRST MARKET PRICES
    let sliced_market = market.slice(
        -companies[sorted_transactions[0].ticker].stock_prices.length
    );
    let initiate_matrix = math.matrix(sliced_market);
    let initiate_PerformanceMatrix = [
        math.multiply(
            math.transpose(math.column(initiate_matrix, 0)),
            type === "index" ? 1 : 1000
        ),
        math.transpose(math.column(initiate_matrix, type === "index" ? 1 : 5)),
    ];
    let initial_dates = initiate_PerformanceMatrix[0]._data[0];
    let placeholder_matrix = math.zeros(initial_dates.length)._data;

    // INITIATE PERFORMANCE DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_performance_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            ["Placeholer"]: placeholder_matrix,
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // INITIATE INVESTED SUM DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_invested_sum_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            ["Placeholer"]: placeholder_matrix,
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // INITIATE PROFIT AND LOSS DATA FRAME WITH TIME STAMPS AS INDEX
    let initiate_profit_and_loss_df = new dfd.DataFrame(
        {
            time: initiate_PerformanceMatrix[0]._data[0],
            ["Placeholer"]: placeholder_matrix,
        },
        { index: initiate_PerformanceMatrix[0]._data[0] }
    );

    // STORE SOLD AMOUNT OF SHARES UP TO LOOP POINT HERE, TO CALCULATE INVEST AND PL
    let current_already_sold_shares_loop_ticker = 0;

    // LOOP TRANSACTIONS, PERFORM CALCULATIONS AND MERGE TO THE DATAFRAME
    sorted_transactions.map((transaction, idx) => {
        // MARKET DATA
        let sliced_market = market.slice(
            -companies[transaction.ticker].stock_prices.length
        );
        let loop_matrix = math.matrix(sliced_market);
        let loop_PerformanceMatrix = [
            math.multiply(
                math.transpose(math.column(loop_matrix, 0)),
                type === "index" ? 1 : 1000
            ),
            math.transpose(math.column(loop_matrix, type === "index" ? 1 : 5)),
        ];
        // ARRAY OF THE MARKETS DATES
        let loop_dates = loop_PerformanceMatrix[0]._data[0];
        let loop_values = loop_PerformanceMatrix[1]._data[0];

        // CREATE ARRAYS TO HOLD THE DATA
        let loop_company_share_counts = math.zeros(loop_dates.length)._data;
        let loop_company_investment = math.zeros(loop_dates.length)._data;
        let loop_company_profit_and_loss = math.zeros(loop_dates.length)._data;

        let loop_parsed_transaction_date = Number(transaction.date);

        // INDEX IN THIS LOOPS DATES
        let loop_index_transaction_date = closest(
            loop_parsed_transaction_date,
            loop_dates
        );

        // INDEX IN PERFORMANCE DATES
        let loop_index_user_date = closest(
            loop_parsed_transaction_date,
            user_dates
        );

        // INDEX IN INVEST DATES
        let loop_index_invest_date = closest(
            loop_parsed_transaction_date,
            user_invest_dates
        );

        // CALCULTE THE ADJUSTED SHARE COUNT FOR THE INDEX
        let loop_transaction_value = transaction.transaction_value;
        let loop_transaction_benchmark_value = 0;
        let adjusted_loop_share_count = 0;

        let benchmark_price_at_transaction_date =
            loop_values[loop_index_transaction_date];
        let portfolio_value_at_transaction_date =
            user_values[loop_index_user_date] - loop_transaction_value;
        let portfolio_invest_at_transaction_date =
            user_invest_values[loop_index_user_date] - loop_transaction_value;

        if (idx === 0 || transaction.type === "buy") {
            adjusted_loop_share_count =
                loop_transaction_value / benchmark_price_at_transaction_date;

            // console.log("START: ");
            // console.log("loop_transaction_value: ", loop_transaction_value);
            // console.log(
            //     "benchmark_price_at_transaction_date: ",
            //     benchmark_price_at_transaction_date
            // );
            // console.log(
            //     "adjusted_loop_share_count: ",
            //     adjusted_loop_share_count
            // );
        } else {
            // FIND OUT CURRENT BENCHMARK PORTFOLIO VALUE
            let loop_performance_df_placeholder =
                initiate_performance_df.copy();
            loop_performance_df_placeholder.set_index({
                column: "time",
                inplace: true,
            });
            loop_performance_df_placeholder.drop({
                columns: ["time"],
                inplace: true,
            });

            let total_performance_df = loop_performance_df_placeholder.sum({
                axis: 0,
            });

            // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
            let index_earliest_transaction_date = closest(
                earliest_transaction_date,
                total_performance_df.index
            );

            let loop_performance_dates = total_performance_df.index.slice(
                index_earliest_transaction_date
            );
            let loop_performance_values = total_performance_df.values.slice(
                index_earliest_transaction_date
            );

            // INDEX IN INVEST DATES
            let loop_index_benchmark_portffolio_date = closest(
                loop_parsed_transaction_date,
                loop_performance_dates
            );

            let benchmark_portfolio_price_at_transaction_date =
                loop_performance_values[loop_index_benchmark_portffolio_date];

            // HOW MANY % OF THE PORTFOLIO BEFORE TRANSACTION DOES THE TRANSACTION ACCOUNT FOR?
            let relative_value_of_transaction =
                Math.abs(loop_transaction_value) /
                portfolio_value_at_transaction_date; // 0.15
            // THIS ACCOUNTS FOR X% OF THE BENCHMARK PERFORMANCE
            let relative_value_regarding_market_portfolio =
                relative_value_of_transaction *
                benchmark_portfolio_price_at_transaction_date; // 500USD
            // let relative_value_regarding_market_portfolio = 500;
            adjusted_loop_share_count =
                relative_value_regarding_market_portfolio /
                benchmark_price_at_transaction_date;

            // console.log("START: ");
            // console.log("loop_transaction_value: ", loop_transaction_value);
            // console.log(
            //     "portfolio_value_at_transaction_date: ",
            //     portfolio_value_at_transaction_date
            // );
            // console.log(
            //     "benchmark_price_at_transaction_date: ",
            //     benchmark_price_at_transaction_date
            // );
            // console.log(
            //     "adjusted_loop_share_count: ",
            //     adjusted_loop_share_count
            // );
        }

        sorted_transactions_object[idx]["adjusted_loop_share_count"] =
            adjusted_loop_share_count;
        sorted_transactions_object[idx]["benchmark_price_at_transaction_date"] =
            benchmark_price_at_transaction_date;

        if (transaction.type === "buy") {
            // AMOUNT OF SHARES
            let pre_buy_share_count = loop_company_share_counts.slice(
                0,
                loop_index_transaction_date
            );
            // ADD NEW SHARES TO POST BUY SHARE COUNT
            // OLD COUNT
            let post_buy_share_count = loop_company_share_counts.slice(
                loop_index_transaction_date
            );

            // COUNT TO ADD
            let post_buy_share_count_to_add = math.multiply(
                math.ones(post_buy_share_count.length),
                adjusted_loop_share_count
            );
            // NEW  COUNT
            let new_post_buy_share_count = math.add(
                post_buy_share_count,
                post_buy_share_count_to_add
            )._data;
            // OVERWRITE OLD SHARE COUNT
            loop_company_share_counts = pre_buy_share_count.concat(
                new_post_buy_share_count
            );

            // INVESTMENT
            let pre_buy_investment = loop_company_investment.slice(
                0,
                loop_index_transaction_date
            );
            // ADD VALUE TO POST BUY INVESTMENT SUM
            // OLD SUM
            let post_buy_investment = loop_company_investment.slice(
                loop_index_transaction_date
            );
            // SUM TO ADD
            let post_buy_investment_to_add = math.multiply(
                math.multiply(
                    math.ones(post_buy_share_count.length),
                    benchmark_price_at_transaction_date
                ),
                adjusted_loop_share_count
            );
            // NEW SUM
            let new_post_buy_investment = math.add(
                post_buy_investment,
                post_buy_investment_to_add
            )._data;
            // OVERWRITE OLD INVESTMENT
            loop_company_investment = pre_buy_investment.concat(
                new_post_buy_investment
            );
        } else {
            // AMOUNT OF SHARES
            let pre_sell_share_count = loop_company_share_counts.slice(
                0,
                loop_index_transaction_date
            );
            // ADD NEW SHARES TO POST BUY SHARE COUNT
            // OLD COUNT
            let post_sell_share_count = loop_company_share_counts.slice(
                loop_index_transaction_date
            );
            // COUNT TO ADD
            let post_sell_share_count_to_add = math.multiply(
                math.multiply(
                    math.ones(post_sell_share_count.length),
                    adjusted_loop_share_count
                ),
                -1
            );
            // NEW  COUNT
            let new_post_sell_share_count = math.add(
                post_sell_share_count,
                post_sell_share_count_to_add
            )._data;
            // OVERWRITE OLD SHARE COUNT
            loop_company_share_counts = pre_sell_share_count.concat(
                new_post_sell_share_count
            );

            // INVESTMENT/ PROFIT AND LOSS
            let pre_sell_investment = loop_company_investment.slice(
                0,
                loop_index_transaction_date
            );
            // ADD VALUE TO POST BUY INVESTMENT SUM
            // OLD SUM
            let post_sell_investment = loop_company_investment.slice(
                loop_index_transaction_date
            );

            // DO THE SAME FOR THE PROFIT AND LOSS
            let pre_sell_profit_and_loss = loop_company_profit_and_loss.slice(
                0,
                loop_index_transaction_date
            );

            // SUM OF PROFIT AND LOSS AFTER NEW TRANSACTIONloop_index_transaction_date
            let post_sell_profit_and_loss = loop_company_profit_and_loss.slice(
                loop_index_transaction_date
            );

            let loop_already_sold_shares_loop_ticker = 0;
            let loop_this_transactions_eq_share_count = 0;
            let loop_this_transactions_eq_buy_value = 0;

            for (let i_sell = 0; i_sell <= idx; i_sell++) {
                if (sorted_transactions[i_sell].type === "buy") {
                    if (
                        current_already_sold_shares_loop_ticker >
                        loop_already_sold_shares_loop_ticker +
                            sorted_transactions_object[i_sell]
                                .adjusted_loop_share_count
                    ) {
                        // ALREADY SOLD THIS SHARE COUNT
                        loop_already_sold_shares_loop_ticker +=
                            sorted_transactions_object[i_sell]
                                .adjusted_loop_share_count;
                    } else {
                        // DID NOT YET SELL THIS LOOPS SHARE COUNT
                        if (
                            sorted_transactions_object[i_sell]
                                .adjusted_loop_share_count +
                                loop_this_transactions_eq_share_count <=
                            adjusted_loop_share_count
                        ) {
                            // THIS COUNT IS LESS THEN REQUIRED
                            loop_this_transactions_eq_share_count +=
                                sorted_transactions_object[i_sell]
                                    .adjusted_loop_share_count;
                            loop_this_transactions_eq_buy_value +=
                                sorted_transactions_object[i_sell]
                                    .adjusted_loop_share_count *
                                sorted_transactions_object[i_sell]
                                    .benchmark_price_at_transaction_date;
                        } else {
                            // THIS COUNT IS MORE THAN WHAT IS SOLD
                            // CALC HOW MANY ARE STILL MISSING
                            let missing_shares_to_sell =
                                adjusted_loop_share_count -
                                loop_this_transactions_eq_share_count;

                            loop_this_transactions_eq_share_count +=
                                missing_shares_to_sell;
                            loop_this_transactions_eq_buy_value +=
                                missing_shares_to_sell *
                                sorted_transactions_object[i_sell]
                                    .benchmark_price_at_transaction_date;
                            break;
                        }
                    }
                } else {
                    // SELL TRANSACTION
                    // does not happen this loop
                }

                // loop_company_transactions[i_sell].share_count
            }

            // NEED:
            // VALUE OF BOUGHT SHARES THAT IS NOW BEING SOLD
            // DIFFERENCE OF SELL VALUE AND THE ABOVE

            // INVEST: CALCULATE THE EQUIVALENT VALUE THE SHARES WERE BOUGHT FOR, MAKE IT AN ARRAY TO DEAL WITH IT BELOW
            let post_sell_investment_correction = math.multiply(
                math.multiply(
                    math.ones(post_sell_share_count.length),
                    loop_this_transactions_eq_buy_value
                ),
                -1
            );

            // INVEST: SUBSTRACT THE ORIGINAL BUY VALUE FROM OLD INVESTED SUM POST TRANSACTION DATE
            let new_post_sell_investment = math.add(
                post_sell_investment,
                post_sell_investment_correction
            )._data;

            // INVEST: OVERWRITE OLD INVESTMENT
            loop_company_investment = pre_sell_investment.concat(
                new_post_sell_investment
            );

            // P/L: ADJUST THE PROFIT AND LOSS
            let profit_and_loss =
                benchmark_price_at_transaction_date *
                    adjusted_loop_share_count -
                loop_this_transactions_eq_buy_value;

            total_profit_and_loss += profit_and_loss;

            let post_sell_profit_and_loss_correction = math.multiply(
                math.ones(post_sell_share_count.length),
                profit_and_loss
            );

            // P/L:
            let new_post_sell_profit_and_loss = math.add(
                post_sell_profit_and_loss,
                post_sell_profit_and_loss_correction
            )._data;
            // P/L: OVERWRITE OLD PROFIT AND LOSS
            loop_company_profit_and_loss = pre_sell_profit_and_loss.concat(
                new_post_sell_profit_and_loss
            );
        }

        // MULTIPLY HISTORIC SHARE COUNT TO SHARE PRICE
        loop_PerformanceMatrix[1]._data[0] = math.dotMultiply(
            loop_PerformanceMatrix[1]._data[0],
            loop_company_share_counts
        );

        // INITIATE PERFORMANCE DATA FRAME WITH TIME STAMPS AS INDEX
        let loop_performance_df = new dfd.DataFrame(
            {
                time: loop_PerformanceMatrix[0]._data[0],
                [idx]: loop_PerformanceMatrix[1]._data[0],
            },
            { index: loop_PerformanceMatrix[0]._data[0] }
        );

        // INITIATE INVESTED SUM DATA FRAME WITH TIME STAMPS AS INDEX
        let loop_invested_sum_df = new dfd.DataFrame(
            {
                time: loop_PerformanceMatrix[0]._data[0],
                [idx]: loop_company_investment,
            },
            { index: loop_PerformanceMatrix[0]._data[0] }
        );

        // INITIATE PROFIT AND LOSS DATA FRAME WITH TIME STAMPS AS INDEX
        let loop_profit_and_loss_df = new dfd.DataFrame(
            {
                time: loop_PerformanceMatrix[0]._data[0],
                [idx]: loop_company_profit_and_loss,
            },
            { index: loop_PerformanceMatrix[0]._data[0] }
        );

        // END CALCULATION

        let merge_performance_df = dfd.merge({
            left: initiate_performance_df,
            right: loop_performance_df,
            on: ["time"],
            how: "inner",
        });

        let merge_invested_sum_df = dfd.merge({
            left: initiate_invested_sum_df,
            right: loop_invested_sum_df,
            on: ["time"],
            how: "inner",
        });
        let merge_profit_and_loss_df = dfd.merge({
            left: initiate_profit_and_loss_df,
            right: loop_profit_and_loss_df,
            on: ["time"],
            how: "inner",
        });

        initiate_performance_df = merge_performance_df;
        initiate_invested_sum_df = merge_invested_sum_df;
        initiate_profit_and_loss_df = merge_profit_and_loss_df;
    });

    // SUM UP BOTH PERFORMANCE AND INVESTED SUM

    // PERFORMANCE DATA
    initiate_performance_df.set_index({ column: "time", inplace: true });
    initiate_performance_df.drop({ columns: ["time"], inplace: true });

    // console.log("initiate_performance_df: ", initiate_performance_df);

    let total_performance_df = initiate_performance_df.sum({ axis: 0 });

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let index_earliest_transaction_date = closest(
        earliest_transaction_date,
        total_performance_df.index
    );

    let final_performance_dates = total_performance_df.index.slice(
        index_earliest_transaction_date
    );
    let final_performance_values = total_performance_df.values.slice(
        index_earliest_transaction_date
    );

    // console.log("final_performance_dates", final_performance_dates);

    let total_PerformanceMatrix = math.matrix([
        final_performance_dates,
        final_performance_values,
    ]);

    // TRANSPOSE MATRIX TO DISPLAY
    let final_performance_data = math.transpose(total_PerformanceMatrix)._data;

    // INVESTMENT DATA
    initiate_invested_sum_df.set_index({ column: "time", inplace: true });
    initiate_invested_sum_df.drop({ columns: ["time"], inplace: true });
    let total_invested_sum_df = initiate_invested_sum_df.sum({ axis: 0 });

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let final_investment_dates = total_invested_sum_df.index.slice(
        index_earliest_transaction_date
    );
    let final_investment_values = total_invested_sum_df.values.slice(
        index_earliest_transaction_date
    );

    let total_InvestedSumMatrix = math.matrix([
        final_investment_dates,
        final_investment_values,
    ]);

    // CALCULATE RELATIVE PERFORMANCE
    let total_relativePerformanceMatrix = math.matrix([
        final_performance_dates,
        math.add(
            math.dotMultiply(
                math.add(
                    math.dotDivide(
                        final_performance_values,
                        final_investment_values
                    ),
                    -1
                ),
                100
            ),
            100
        ),
    ]);

    // PROFIT AND LOSS DATA
    initiate_profit_and_loss_df.set_index({ column: "time", inplace: true });
    initiate_profit_and_loss_df.drop({ columns: ["time"], inplace: true });
    let total_profit_and_loss_df = initiate_profit_and_loss_df.sum({ axis: 0 });

    // DELETE EVERY VALUE BEFORE FIRST TRANSACTION
    let final_profit_and_loss_dates = total_profit_and_loss_df.index.slice(
        index_earliest_transaction_date
    );
    let final_profit_and_loss_values = total_profit_and_loss_df.values.slice(
        index_earliest_transaction_date
    );

    let total_ProfitAndLossMatrix = math.matrix([
        final_profit_and_loss_dates,
        final_profit_and_loss_values,
    ]);

    // TRANSPOSE MATRICES TO DISPLAY
    let final_invested_sum_data = math.transpose(total_InvestedSumMatrix)._data;
    let final_profit_and_loss_data = math.transpose(
        total_ProfitAndLossMatrix
    )._data;
    let final_relative_performance_data = math.transpose(
        total_relativePerformanceMatrix
    )._data;

    // let sharpe_ratio =
    //     type === "index"
    //         ? null
    //         : sharpe(final_performance_data, risk_free_rate);
    let sharpe_ratio = sharpe(final_performance_data, risk_free_rate);

    let performance_return = {
        performance: final_performance_data,
        invested_sum: final_invested_sum_data,
        relative_performance: final_relative_performance_data,
        realized_profit_and_loss_over_time: final_profit_and_loss_data,
        realized_profit_and_loss: total_profit_and_loss,
        sharpe_ratio: sharpe_ratio,
    };

    return performance_return;
};

// Calculate any linear Regression with confidence interval
let theta;
let std;
let y_std_diff_std;
const calulateLinearRegressionConfidence = (performance, regression_length) => {
    console.log("PERFORMANCE PLS: ", performance);

    // Transform performance array to Matrix

    let performancematrix = math.matrix(performance);

    let PerformanceMatrix = [
        math.transpose(math.column(performancematrix, 0)),
        math.transpose(math.column(performancematrix, 1)),
    ];

    let regressionInterval;
    if (regression_length > 750) {
        regressionInterval = 750;
    } else {
        // regressionInterval = 0.5 * PerformanceMatrix[1]._data[0].length;
        regressionInterval = regression_length;
    }

    let y = PerformanceMatrix[1]._data[0].slice(
        PerformanceMatrix[1]._data[0].length - regressionInterval
    );
    let m = y.length;

    y = math.log(math.matrix(y));
    // [y1, y2, y3, y4 ...]

    let X_help = PerformanceMatrix[0]._data[0].slice(
        PerformanceMatrix[0]._data[0].length - regressionInterval
    );
    let ones = math.ones(m);
    ones = ones._data;

    let Xtr = math.matrix([X_help, ones]);
    // [[x1, x2, x3, x4 ...], [1, 1, 1, 1 ...]]

    let X = math.transpose(Xtr);
    // [[x1, 1], [x2, 2], [x3, 3] ...]

    let Xtr_X = math.multiply(Xtr, X);

    let Xtr_y = math.multiply(Xtr, y);

    theta = math.multiply(math.inv(Xtr_X), Xtr_y);

    let y_std = PerformanceMatrix[1]._data[0].slice(
        PerformanceMatrix[1]._data[0].length - regressionInterval
    );
    y_std = math.log(math.matrix(y_std));
    let y_std_diff = math.diff(y_std);

    y_std_diff_std = math.std(y_std_diff);

    let X_help_std = PerformanceMatrix[0]._data[0].slice(
        PerformanceMatrix[0]._data[0].length - regressionInterval
    );

    let X_std = math.multiply(theta._data[0], X_help_std);

    let theta_1_std = math.multiply(
        math.ones(regressionInterval),
        theta._data[1]
    );

    let help = math.add(
        y_std,
        math.multiply(math.exp(math.add(X_std, theta_1_std)), -1)
    );
    std = math.sqrt(math.multiply(help, help) / regressionInterval);
};

// Calculates the confidence intervall over time for display,
// returns object: {lin_reg, conf, conf_2, conf_3, linear_regression_1y, confidence_lower, confidence_upper}
const calculateRegressionConfidenceOverTime = (performance) => {
    let day = 365;
    let lin_reg = [];
    let conf = [];
    let conf_2 = [];
    let conf_3 = [];

    let linear_regression_1y = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000
    );

    let confidence_lower = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000 -
            math.sqrt(day) * y_std_diff_std
    );

    let confidence_upper = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000 +
            math.sqrt(day) * y_std_diff_std
    );

    for (day = 1; day < 366; day++) {
        lin_reg.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000
                    )
                    .toFixed(2)
            ),
        ]);
        conf.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 -
                            math.sqrt(day) * y_std_diff_std
                    )
                    .toFixed(2)
            ),
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 +
                            math.sqrt(day) * y_std_diff_std
                    )
                    .toFixed(2)
            ),
        ]);
        conf_2.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 -
                            math.sqrt(day) * 2 * y_std_diff_std
                    )
                    .toFixed(2)
            ),
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 +
                            math.sqrt(day) * 2 * y_std_diff_std
                    )
                    .toFixed(2)
            ),
        ]);
        conf_3.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 -
                            math.sqrt(day) * 3 * y_std_diff_std
                    )
                    .toFixed(2)
            ),
            Number(
                math
                    .exp(
                        math.log(performance[performance.length - 1][1]) +
                            theta._data[0] * day * 24 * 3600 * 1000 +
                            math.sqrt(day) * 3 * y_std_diff_std
                    )
                    .toFixed(2)
            ),
        ]);
    }

    return {
        lin_reg: lin_reg,
        conf: conf,
        conf_2: conf_2,
        conf_3: conf_3,
        linear_regression_1y: linear_regression_1y,
        confidence_lower: confidence_lower,
        confidence_upper: confidence_upper,
    };
};
