import React, { useState, createContext } from "react";
import { IntlProvider } from "react-intl";

// Import language files here
import en_us from "../languages/en-us/en-us.json";
import de_de from "../languages/de-de/de-de.json";

export const Context = createContext();

const local = navigator.language.toLowerCase();

let lang;
let loc;

// if (localStorage.locale) {
//     if (localStorage.locale === "en-us") {
//         loc = "en-us";
//         lang = en_us;
//     } else {
//         loc = "de-de";
//         lang = de_de;
//     }
// } else {
//     if (local === "en-us") {
//         loc = "en-us";
//         lang = en_us;
//     } else {
//         loc = "de-de";
//         lang = de_de;
//     }
// }

loc = "en-us";
lang = en_us;

const LocaleContext = (props) => {
    const [locale, setLocale] = useState(loc);
    const [messages, setMessages] = useState(lang);

    const selectLang = (newLocale) => {
        // const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === "de-de") {
            setMessages(de_de);
            localStorage.setItem("locale", "de-de");
        } else {
            setMessages(en_us);
            localStorage.setItem("locale", "en-us");
        }
    };

    const intlErrorHandler = () => {
        // console.log("error");
    };

    return (
        <Context.Provider value={{ locale, selectLang }}>
            <IntlProvider
                messages={messages}
                locale={locale}
                onError={intlErrorHandler}
            >
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
};

export default LocaleContext;
