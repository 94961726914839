import React, { useEffect } from "react";

// ICONS
import * as AiIcons from "react-icons/ai";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { editPortfolioTransaction } from "../../../../actions/portfolioActions";

// COMPONENTS
import Preloader from "../../../layout/Preloader";

// MUI
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const EditTransaction = ({
    transaction,
    closeModal,
    portfolio_id,
    user_id,
    portfolio_user_id,
    risk_free_rate,
    editPortfolioTransaction,
}) => {
    useEffect(() => {
        setOpenLoader(false);
        // eslint-disable-next-line
    }, []);

    // LOADING ANIMATION
    const [openLoader, setOpenLoader] = React.useState(false);

    // EDITED TRANSACTION STATE
    const [editedTransaction, setEditedTransaction] = React.useState({
        id: transaction.id,
        ticker: transaction.ticker,
        date: new Date(transaction.date),
        type: transaction.type,
        share_count: transaction.share_count,
        price_per_share: transaction.price_per_share,
        transaction_value: transaction.transaction_value,
    });

    const handleTransactionType = (new_type) => {
        if (new_type === "sell") {
            setEditedTransaction({ ...editedTransaction, type: "sell" });
        } else {
            setEditedTransaction({ ...editedTransaction, type: "buy" });
        }
    };

    const handleTransactionDateValue = (newValue) => {
        setEditedTransaction({ ...editedTransaction, date: newValue });
    };

    const handleTransactionPricePerShare = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0) &&
            Number(event.target.value <= 1000000000)
        ) {
            setEditedTransaction({
                ...editedTransaction,
                price_per_share: Number(event.target.value),
                transaction_value:
                    Number(event.target.value) * editedTransaction.share_count,
            });
        } else {
            setEditedTransaction({
                ...editedTransaction,
                price_per_share: 0,
                transaction_value: 0,
            });
        }
    };

    const handleTransactionShareCount = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0) &&
            Number(event.target.value <= 1000000000)
        ) {
            setEditedTransaction({
                ...editedTransaction,
                share_count: Number(event.target.value),
                transaction_value:
                    editedTransaction.price_per_share *
                    Number(event.target.value),
            });
        } else {
            setEditedTransaction({
                ...editedTransaction,
                share_count: 0,
                transaction_value: 0,
            });
        }
    };

    const handleSubmitEditedTransaction = () => {
        console.log("EDIT: ", editedTransaction);
        editPortfolioTransaction(
            editedTransaction,
            portfolio_id,
            user_id,
            portfolio_user_id,
            risk_free_rate
        );
        setOpenLoader(true);
    };

    const handleSubmitDeleteTransaction = () => {
        let deleteTransaction = {
            id: transaction.id,
            ticker: transaction.ticker,
            date: new Date(transaction.date),
            type: transaction.type,
            share_count: 0,
            price_per_share: transaction.price_per_share,
            transaction_value: transaction.transaction_value,
        };
        console.log("EDIT: ", deleteTransaction);
        editPortfolioTransaction(
            deleteTransaction,
            portfolio_id,
            user_id,
            portfolio_user_id,
            risk_free_rate
        );
        setOpenLoader(true);
    };

    return (
        <div className='max_width_500px background_color_2 text_color_1'>
            <div className='flex space_between title_4 margin_10px'>
                <div className='mr_10px flex'>
                    Edit |
                    <div className='mr_5px'>
                        {transaction.type === "buy" ? "Buy" : "Sell"}
                    </div>
                    <div>{transaction.ticker}</div>
                </div>
                <div
                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                    onClick={closeModal}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            <div className='divider_no_margin'></div>

            {/* TYPE AND DATE */}
            <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                {/* BUY/ SELL */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    <div className='width_100pr'>
                        <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                            Type
                        </div>
                        <div className='flex center'>
                            <div className='width_90pr height_35px accent_color_2 text_3 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                                <div
                                    className={
                                        editedTransaction.type === "sell"
                                            ? "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            : "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                    }
                                    onClick={() => {
                                        handleTransactionType("buy");
                                    }}
                                >
                                    Buy
                                </div>
                                <div
                                    className={
                                        editedTransaction.type === "sell"
                                            ? "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            : "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                    }
                                    onClick={() => {
                                        handleTransactionType("sell");
                                    }}
                                >
                                    Sell
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CALENDAR */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    <div className='width_100pr'>
                        <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                            Date
                        </div>
                        <div className='flex center'>
                            <div className='flex center width_90pr height_35px accent_color_2 text_3 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DesktopDatePicker
                                        // label='Date desktop'
                                        sx={{
                                            width: 1,
                                            // '& .MuiFormControl-root-MuiTextField-root': {
                                            //     width: 1,
                                            // },
                                            "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                    borderWidth: 0,
                                                },
                                        }}
                                        inputFormat='MM/dd/yyyy'
                                        value={editedTransaction.date}
                                        onChange={handleTransactionDateValue}
                                        maxDate={new Date()}
                                        minDate={new Date("2011,12,01")}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SHARE PRICE AND COUNT */}
            <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                {/* PRICE PER SHARE */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    <div className='width_100pr'>
                        <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                            Price per share
                        </div>
                        <div className='flex center'>
                            <div className='height_32px width_90pr pl_10px pt_3px border_box input_basic border_1px_solid border_radius_5px background_color_4 text_2'>
                                <input
                                    type='number'
                                    defaultValue={
                                        editedTransaction.price_per_share
                                    }
                                    onKeyUp={handleTransactionPricePerShare}
                                    className='text_color_1 text_3'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* SHARE COUNT */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    <div className='width_100pr'>
                        <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                            Shares
                        </div>
                        <div className='flex center'>
                            <div className='height_32px width_90pr pl_10px pt_3px border_box input_basic border_1px_solid border_radius_5px background_color_4 text_2'>
                                <input
                                    type='number'
                                    defaultValue={editedTransaction.share_count}
                                    onKeyUp={handleTransactionShareCount}
                                    className='text_color_1 text_3'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* TOTAL AND SUBMIT */}
            <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                {/* TOTAL SUM */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    <div className='width_100pr'>
                        <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                            Total
                        </div>
                        <div className='flex center'>
                            <div className='height_32px width_90pr pl_10px pt_3px hover_opacity_80 border_box border_1px_solid border_radius_5px background_color_4 text_2'>
                                <input
                                    type='text'
                                    defaultValue=''
                                    readOnly
                                    // onKeyUp={handleChangePortfolioName}
                                    className='text_color_1 text_3 cursor_default'
                                    value={editedTransaction.transaction_value}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* SUBMIT */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    {!openLoader ? (
                        <div className='width_100pr'>
                            <div className='text_2 height_25px text_color_1 mb_10px'></div>
                            <div className='flex center'>
                                <div
                                    className='height_32px button_blue width_90pr hover_opacity_80 text_2 cursor_pointer'
                                    onClick={handleSubmitEditedTransaction}
                                >
                                    Submit changes
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* TOTAL AND SUBMIT */}
            <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                {/* TOTAL SUM */}
                <div className='width_50pr_100pr flex left mt_10px'></div>
                {/* SUBMIT */}
                <div className='width_50pr_100pr flex left mt_10px'>
                    {!openLoader ? (
                        <div className='width_100pr'>
                            <div className='flex center'>
                                <div
                                    className='height_32px button_delete width_90pr hover_opacity_80 text_2 cursor_pointer'
                                    onClick={handleSubmitDeleteTransaction}
                                >
                                    Delete Transaction
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* LOADING ANIMATION AFTER SUBMIT */}
            {openLoader ? (
                <div className='width_100pr padding_10px flex space_around wrap'>
                    <Preloader />
                </div>
            ) : null}
        </div>
    );
};

EditTransaction.propTypes = {
    editPortfolioTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { editPortfolioTransaction })(
    EditTransaction
);
