import React from "react";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as IoIcons5 from "react-icons/io5";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { addPortfolioCompany } from "../../../actions/portfolioActions";

const RemoveFromPortfolio = ({
    closeModal,
    currentPortfolio,
    ticker,
    user,
    addPortfolioCompany,
}) => {
    const handleDeletePortfolioCompany = (ticker) => {
        // handlePortfolioState(currentPortfolio.id);

        console.log("HANDLE DELETE: ", currentPortfolio.ticker);

        if (currentPortfolio.id !== null && currentPortfolio.ticker !== null) {
            let data = {
                user_id: user.id,
                ticker: ticker,
                portfolio: currentPortfolio.id,
            };
            let newTicker = currentPortfolio.ticker.filter(
                (item) => item !== ticker
            );

            console.log(data, newTicker, currentPortfolio.allocation);

            addPortfolioCompany(
                data,
                newTicker,
                currentPortfolio.allocation,
                null,
                "delete"
            );
        }

        closeModal();
    };
    return (
        <div className='background_color_2'>
            {window.location.pathname === "/portfolio" ? (
                <div className='background_color_2'>
                    <div className='flex space_between title_4 pt_10px pb_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{`Remove ${ticker} from ${currentPortfolio.name}?`}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={closeModal}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider_no_margin'></div>
                    <div className='padding_15px flex right background_color_2'>
                        {/* Problemtofix: Dialog mit Icons */}

                        <div
                            className='button_delete text_color_1 hover_opacity_80 ml_15px'
                            onClick={() => {
                                handleDeletePortfolioCompany(ticker);
                            }}
                        >
                            <div className='pl_10px text_3'>Remove</div>
                            <IoIcons5.IoTrashOutline className='mr_10px ml_10px text_2' />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

RemoveFromPortfolio.propTypes = {
    user: PropTypes.object.isRequired,
    addPortfolioCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {
    addPortfolioCompany,
})(RemoveFromPortfolio);
