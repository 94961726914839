import React from "react";
import styled from "styled-components";
import logo from "../../../favicon.png";
import { useLocation, Link } from "react-router-dom";
import { useIntl } from "react-intl";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import { setSidebar } from "../../../actions/sidebarActions";
import { logoutUser } from "../../../actions/userActions";

// Components
import SideSubMenu from "./SideSubMenu";
import Bookmarks from "../bookmarks/Bookmarks";
import SearchBar from "./SearchBar";

import Popover from "@mui/material/Popover";

const Sidebar = ({
    bookmark: { bookmarks },
    sidebar: { sidebar },
    user,
    setSidebar,
    logoutUser,
}) => {
    const location = useLocation();
    const useintl = useIntl();
    // console.log(location.pathname);

    const submitLogout = () => {
        logoutUser();
    };

    const showSidebar = () => {
        setSidebar();
    };

    // Popover
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    if (
        // location.pathname === "/" ||
        location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname === "/forgot_password" ||
        location.pathname === "/terms_of_service" ||
        location.pathname === "/privacy_policy" ||
        location.pathname === "/about_us" ||
        location.pathname === "/contact_us" ||
        location.pathname === "/verification" ||
        location.pathname === "/fucking_shit_error"
    ) {
        return null;
    } else {
        return (
            <div>
                {/* Topbar Component */}
                <div className='pos_fixed z_index_20 width_100vw min_width_320px topmenu_height flex space_between background_color_6'>
                    <div className='flex width_100pr height_100pr'>
                        {/* SIDEBAR */}
                        <div
                            className={
                                sidebar
                                    ? "sidemenu_width_sidebar_open flex transition_350ms height_100pr padding_10px title_4 text_color_3"
                                    : "sidemenu_width_sidebar_closed flex transition_350ms height_100pr padding_10px title_4 text_color_3"
                            }
                        >
                            <FaIcons.FaBars
                                onClick={showSidebar}
                                className=' ml_15px text_1 cursor_pointer'
                            />
                        </div>
                        {/* TOPBAR */}
                        <div
                            className={
                                sidebar
                                    ? "pl_10px topmenu_width_sidebar_open transition_350ms mobile_display_none height_100pr"
                                    : "pl_10px topmenu_width_sidebar_closed transition_350ms mobile_display_block height_100pr"
                            }
                        >
                            <div className='height_100pr width_70pr_60pr max_width_680px width_ flex flex_column align_items_start space_around'>
                                {bookmarks && bookmarks.length > 0 ? (
                                    <div className='flex left height_45pr width_100pr'>
                                        <Bookmarks />
                                    </div>
                                ) : null}

                                <div className='flex left height_45pr width_100pr max_width_680px text_color_1 pl_5px'>
                                    <SearchBar />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* USER ICON */}
                    {user.isAuthenticated ? (
                        <div
                            aria-describedby={id}
                            className='width_auto pos_absolute pos_right mr_20px color_white flex hover_opacity_60 cursor_pointer'
                            onClick={handleClick}
                        >
                            <div className='mr_5px text_color_3 mt_10px title_4'>
                                <FaIcons.FaUserCircle />
                            </div>
                            <div className='width_auto ml_5px mr_5px text_color_3 text_4 display_768px'>
                                <div>{user.name}</div>
                                <div className='button_white_small height_25px padding_5px'>
                                    {user.status === 0
                                        ? "Free"
                                        : user.status === 1
                                        ? "Premium"
                                        : user.status === 80
                                        ? "Demo"
                                        : user.status === 90 ||
                                          user.status === 99
                                        ? "Beta tester"
                                        : user.status === 100
                                        ? "Admin"
                                        : "-"}
                                </div>
                            </div>
                            <div className='width_10px text_color_3 mt_5px'>
                                <RiIcons.RiArrowDownSFill />
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>

                {/* ACCOUNT SETTINGS POPOVER */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onClick={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <div
                        className='width_150px background_color_4 border_box text_2 padding_10px'
                        onClick={handleClose}
                    >
                        <Link to={`/account`}>
                            <div className='width_100pr hover_opacity_80 text_color_1 text_center mb_10px'>
                                Account Settings
                            </div>
                        </Link>
                        <div
                            className='width_100pr hover_opacity_80 cursor_pointer text_color_1 text_center mt_10px'
                            onClick={submitLogout}
                            href='/login'
                        >
                            {useintl.formatMessage({
                                id: "logout.logout",
                            })}
                        </div>
                    </div>
                </Popover>

                {/* SIDESUBMENU */}
                <nav
                    className='line_height_none sidesubmenu_width height_100vh_test flex center pos_fixed pos_top transition_350ms z_index_20 background_color_7'
                    style={{ left: sidebar ? "0" : "-100%", top: "0" }}
                >
                    <div className='width_100pr background_color_7 text_color_1'>
                        <div className='padding_10px title_4 flex topmenu_height_sidebar text_color_white mt_-3px_-7px_-10px'>
                            <FaIcons.FaBars
                                onClick={showSidebar}
                                className='ml_15px text_1 cursor_pointer'
                            />
                            <div className='flex pl_30px'>
                                <Link
                                    to={``}
                                    onClick={() => {
                                        if (window.innerWidth < 1224) {
                                            showSidebar();
                                        }
                                    }}
                                    className='hover_opacity_80'
                                >
                                    <img
                                        src={logo}
                                        alt='Stocks On View Logo'
                                        className='width_30px height_30px mt_10px'
                                    />
                                </Link>
                                <Link to={``} className='pl_5px'>
                                    <div className='title_7'>StocksOnView</div>
                                </Link>
                            </div>

                            {/* <AiIcons.AiOutlineClose
                                className='cursor_pointer mt_5px hover_opacity_80'
                                onClick={showSidebar}
                            /> */}
                        </div>
                        <div className='divider_no_margin'></div>
                        {/* Sidebar inner Components */}
                        <SideSubMenu />
                        {/* {SidebarData.map((item, index) => {
                            return <SideSubMenu item={item} key={index} />;
                        })} */}
                    </div>
                </nav>
            </div>
        );
    }
};

Sidebar.propTypes = {
    bookmark: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setSidebar: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    bookmark: state.bookmark,
    user: state.user,
});

export default connect(mapStateToProps, { setSidebar, logoutUser })(Sidebar);
