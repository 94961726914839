import React from "react";
import { MathComponent } from "mathjax-react";
import { useIntl } from "react-intl";

// ICONS
import * as MdIcons from "react-icons/md";
import * as IoIcons5 from "react-icons/io5";
import * as AiIcons from "react-icons/ai";

// MUI
import { withStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

// COMPONENTS
import Alerts from "../../layout/Alerts";

// ACTIONS
import { submitFeedbackForm } from "../../../actions/generalActions";
import { setAlert } from "../../../actions/alertActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const BlueRadio = withStyles({
    // root: {
    //     "&$checked": {
    //         color: "#3F51B5",
    //     },
    // },
    checked: {},
})((props) => <Checkbox {...props} />);

const FeedbackIcon = ({
    user,
    informationInfo,
    feedbackInfo,
    submitFeedbackForm,
    setAlert,
}) => {
    const useintl = useIntl();
    // SHOW FURTHER INFO ON HOVER
    const [icon_hovered, setIcon_hovered] = React.useState(false);
    const handleHoverIn = () => {
        setIcon_hovered(true);
    };
    const handleHoverOut = () => {
        setIcon_hovered(false);
    };

    // FEEDBACK MODAL
    const [openFeedback, setOpenFeedback] = React.useState(false);
    const handleOpenFeedback = () => {
        setOpenFeedback(true);
    };
    const handleCloseFeedback = () => {
        setOpenFeedback(false);
        setIcon_hovered(false);
    };

    // FEEDBACK FORM
    const [feedbackOption, setFeedbackOption] = React.useState(null);
    const handleFeedbackOption = (value) => {
        value !== feedbackOption
            ? setFeedbackOption(value)
            : setFeedbackOption(null);
    };

    let feedbackOptionsObject = {
        0: `${useintl.formatMessage({
            id: "feedback.i_found_a_mistake",
        })}`,
        1: `${useintl.formatMessage({
            id: "feedback.i_dont_understand_this",
        })}`,
        2: `${useintl.formatMessage({
            id: "feedback.i_am_missing_information_here",
        })}`,
        3: `${useintl.formatMessage({
            id: "feedback.i_want_additional_functionality",
        })}`,
        4: `${useintl.formatMessage({
            id: "feedback.i_have_general_feedback",
        })}`,
        5: `${useintl.formatMessage({
            id: "feedback.further_explanation",
        })}`,
    };

    // FEEDBACK TIMEOUT
    let min_timeout = 10000;
    const [time, setTime] = React.useState(0);

    const [feedbackText, setFeedbackText] = React.useState("");
    const handleFeedbackText = (event) => {
        setFeedbackText(event.target.value);
    };

    const handleSubmitFeedback = () => {
        let feedbackObject = {
            user_id: user ? user.id : null,
            date: null,
            index: feedbackOption,
            option: feedbackOptionsObject[feedbackOption],
            location_index: feedbackInfo.location_index,
            location: feedbackInfo.location,
            explanation: feedbackText,
        };
        let d = new Date();
        let n = d.getTime();

        if (n - time > min_timeout) {
            setTime(n);
            if (feedbackOption !== null && feedbackText !== "") {
                handleCloseFeedback();
                submitFeedbackForm(feedbackObject);
            } else {
                setAlert(
                    "Please enter both Subject and a further Explanation",
                    "danger"
                );
            }
        }
    };

    // INFORMATION MODAL
    const [openInformation, setOpenInformation] = React.useState(false);
    const handleOpenInformation = () => {
        setOpenInformation(true);
    };
    const handleCloseInformation = () => {
        setOpenInformation(false);
        setIcon_hovered(false);
    };

    return (
        <div className='pos_relative z_index_19'>
            {openFeedback ? (
                <div className='pos_fixed pos_below_topbar pos_right max_table_height text_1 text_color_1'>
                    <div className='pos_relative mr_0px'>
                        <div className='overflow_y_auto pl_15px border_box transition_350ms width_100vw background_color_transparent mr_0px'>
                            <div className='border_box flex width_100pr no_wrap mr_0px'>
                                <div
                                    className='min_height_100vh_real width_50pr'
                                    onClick={handleCloseFeedback}
                                ></div>
                                <div className='height_100vh_real width_50pr_100pr min_width_320px background_color_2 mr_0px'>
                                    <div className='mt_0px mr_0px'>
                                        <div className='width_100pr flex left pt_15px pl_15px text_1'>
                                            <MdIcons.MdClose
                                                className='cursor_pointer text_1 width_25px'
                                                onClick={handleCloseFeedback}
                                            />
                                            <div className='pl_20px'>
                                                {useintl.formatMessage({
                                                    id: "feedback",
                                                })}{" "}
                                                {feedbackInfo
                                                    ? ` | ${feedbackInfo.location}`
                                                    : null}
                                            </div>
                                        </div>
                                        <div className='divider_no_margin'></div>

                                        <div className='pl_20px pr_20px pt_100px mb_50pr text_color_1 border_box'>
                                            <div className='flex'>
                                                <BlueRadio
                                                    checked={
                                                        feedbackOption === 0
                                                    }
                                                    onClick={() => {
                                                        handleFeedbackOption(0);
                                                    }}
                                                />
                                                <div className='pl_10px text_2'>
                                                    {feedbackOptionsObject[0]}
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <BlueRadio
                                                    checked={
                                                        feedbackOption === 1
                                                    }
                                                    onClick={() => {
                                                        handleFeedbackOption(1);
                                                    }}
                                                />
                                                <div className='pl_10px text_2'>
                                                    {feedbackOptionsObject[1]}
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <BlueRadio
                                                    checked={
                                                        feedbackOption === 2
                                                    }
                                                    onClick={() => {
                                                        handleFeedbackOption(2);
                                                    }}
                                                />
                                                <div className='pl_10px text_2'>
                                                    {feedbackOptionsObject[2]}
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <BlueRadio
                                                    checked={
                                                        feedbackOption === 3
                                                    }
                                                    onClick={() => {
                                                        handleFeedbackOption(3);
                                                    }}
                                                />
                                                <div className='pl_10px text_2'>
                                                    {feedbackOptionsObject[3]}
                                                </div>
                                            </div>
                                            <div className='flex'>
                                                <BlueRadio
                                                    checked={
                                                        feedbackOption === 4
                                                    }
                                                    onClick={() => {
                                                        handleFeedbackOption(4);
                                                    }}
                                                />
                                                <div className='pl_10px text_2'>
                                                    {feedbackOptionsObject[4]}
                                                </div>
                                            </div>

                                            <div className='border_box pt_20px width_100pr'>
                                                <TextField
                                                    id='outlined-multiline-static'
                                                    label={
                                                        feedbackOptionsObject[5]
                                                    }
                                                    className='background_color_4 border_radius_5px'
                                                    multiline
                                                    fullWidth
                                                    rows={10}
                                                    defaultValue=''
                                                    onKeyUp={(event) => {
                                                        if (
                                                            event.key ===
                                                            "Enter"
                                                        ) {
                                                            // submitsearchText();
                                                        } else {
                                                            handleFeedbackText(
                                                                event
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className='pt_40px'>
                                                <div
                                                    className='button_blue padding_10px text_3 click_translate hover_opacity_80'
                                                    onClick={
                                                        handleSubmitFeedback
                                                    }
                                                >
                                                    {useintl.formatMessage({
                                                        id: "feedback.submit_feedback",
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : openInformation ? (
                <div className='pos_fixed pos_below_topbar pos_right max_table_height text_1 text_color_1'>
                    <div className='pos_relative mr_0px'>
                        <div className='overflow_y_auto pl_15px border_box transition_350ms width_100vw background_color_transparent mr_0px'>
                            <Alerts />
                            <div className='border_box flex right width_100pr no_wrap mr_0px'>
                                {/* <div className='min_height_100vh_real width_50pr mr_0px'></div> */}
                                <div className='height_100vh_real width_50pr_100pr min_width_320px max_width_1000px background_color_2 mr_0px'>
                                    <div className='mt_0px mr_0px background_color_2'>
                                        <div className='width_100pr flex left pt_15px pl_15px text_1'>
                                            <MdIcons.MdClose
                                                className='cursor_pointer text_1 width_25px'
                                                onClick={handleCloseInformation}
                                            />
                                            <div className='pl_20px'>
                                                {useintl.formatMessage({
                                                    id: "information",
                                                })}{" "}
                                                {informationInfo
                                                    ? ` | ${informationInfo.location}`
                                                    : null}
                                            </div>
                                        </div>
                                        <div className='divider_no_margin'></div>

                                        <div className='pl_20px pr_20px pt_100px'>
                                            {informationInfo.info
                                                ? informationInfo.info.map(
                                                      (prop) =>
                                                          prop.type ===
                                                          "header_1" ? (
                                                              <div className='title_4 flex'>
                                                                  {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                                                  {
                                                                      informationInfo[
                                                                          prop
                                                                              .content
                                                                      ]
                                                                  }
                                                              </div>
                                                          ) : prop.type ===
                                                            "header_2" ? (
                                                              prop.content ===
                                                              1 ? (
                                                                  <div className='title_6 flex'>
                                                                      <IoIcons5.IoBookOutline className='mr_10px' />
                                                                      {useintl.formatMessage(
                                                                          {
                                                                              id: `metrics.infobox.section_1.header`,
                                                                          }
                                                                      )}
                                                                  </div>
                                                              ) : prop.content ===
                                                                2 ? (
                                                                  <div className='title_6 flex'>
                                                                      <AiIcons.AiOutlineCalculator className='mr_10px' />
                                                                      {useintl.formatMessage(
                                                                          {
                                                                              id: `metrics.infobox.section_2.header`,
                                                                          }
                                                                      )}
                                                                  </div>
                                                              ) : prop.content ===
                                                                3 ? (
                                                                  <div className='title_6 flex'>
                                                                      <IoIcons5.IoTrailSignOutline className='mr_10px' />
                                                                      {useintl.formatMessage(
                                                                          {
                                                                              id: `metrics.infobox.section_3.header`,
                                                                          }
                                                                      )}
                                                                  </div>
                                                              ) : (
                                                                  <div className='title_6 flex ml_5px'>
                                                                      {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                                                      {
                                                                          informationInfo[
                                                                              prop
                                                                                  .content
                                                                          ]
                                                                      }
                                                                  </div>
                                                              )
                                                          ) : prop.type ===
                                                            "header_3" ? (
                                                              <div className='title_7 flex'>
                                                                  {/* <IoIcons5.IoTrailSignOutline className='mr_10px' /> */}
                                                                  {
                                                                      informationInfo[
                                                                          prop
                                                                              .content
                                                                      ]
                                                                  }
                                                              </div>
                                                          ) : prop.type ===
                                                            "text_1" ? (
                                                              <div className='margin_10px text_1'>
                                                                  {
                                                                      informationInfo[
                                                                          prop
                                                                              .content
                                                                      ]
                                                                  }
                                                              </div>
                                                          ) : prop.type ===
                                                            "text_2" ? (
                                                              <div className='margin_10px text_2'>
                                                                  {
                                                                      informationInfo[
                                                                          prop
                                                                              .content
                                                                      ]
                                                                  }
                                                              </div>
                                                          ) : prop.type ===
                                                            "math" ? (
                                                              <div className='width_100pr flex center'>
                                                                  <MathComponent
                                                                      tex={
                                                                          informationInfo[
                                                                              prop
                                                                                  .content
                                                                          ]
                                                                      }
                                                                  />
                                                              </div>
                                                          ) : prop.type ===
                                                            "img" ? (
                                                              <div className='width_100pr flex center'>
                                                                  <img
                                                                      src={
                                                                          informationInfo[
                                                                              prop
                                                                                  .content
                                                                          ]
                                                                      }
                                                                      alt={
                                                                          informationInfo.displayName
                                                                      }
                                                                  />
                                                              </div>
                                                          ) : prop.type ===
                                                            "video" ? (
                                                              <div className='width_100pr flex mt_20px mb_20px'>
                                                                  {
                                                                      informationInfo[
                                                                          prop
                                                                              .content
                                                                      ]
                                                                  }
                                                              </div>
                                                          ) : prop.type ===
                                                            "divider" ? (
                                                              <div className='divider'></div>
                                                          ) : null
                                                  )
                                                : null}
                                        </div>

                                        <div className='pl_20px pr_20px pt_100px mb_50pr text_color_1 border_box'>
                                            <div
                                                className='flex text_2 pl_10px cursor_pointer text_color_blue'
                                                onClick={() => {
                                                    handleCloseInformation();
                                                    setFeedbackOption(1);
                                                    handleOpenFeedback();
                                                }}
                                            >
                                                {useintl.formatMessage({
                                                    id: "information.i_dont_understand_this_description",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='pos_fixed pos_bottom pos_right text_1 text_color_1'>
                    <div className='pos_relative cursor_pointer z_index_5'>
                        <div className='flex flex_column_reverse align_items_start right pb_10px pr_5px'>
                            {icon_hovered ? (
                                <div>
                                    {/* <div
                                        className='hover_opacity_80 flex center border_radius_25px width_50px height_50px mb_10px accent_color_2 transition_350ms'
                                        onClick={handleOpenFeedback}
                                    >
                                        <MdIcons.MdOutlineTour />
                                    </div> */}
                                    {informationInfo ? (
                                        <div
                                            className='hover_opacity_80 flex center border_radius_25px width_50px height_50px mb_10px accent_color_2 transition_350ms'
                                            onClick={handleOpenInformation}
                                        >
                                            <MdIcons.MdHelpOutline />
                                        </div>
                                    ) : null}
                                    <div
                                        className='hover_opacity_80 flex center border_radius_25px width_50px height_50px mb_10px accent_color_2 transition_350ms'
                                        onClick={handleOpenFeedback}
                                    >
                                        <MdIcons.MdOutlineFeedback />
                                    </div>
                                    <div
                                        className='flex right border_radius_15px width_30px height_30px accent_color_2 transition_350ms'
                                        onClick={handleHoverOut}
                                    >
                                        <div className='width_100pr flex center'>
                                            <MdIcons.MdKeyboardArrowDown />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='hover_translate_up'>
                                    <div
                                        className='hover_opacity_80 flex center border_radius_15px width_30px height_30px background_color_blue transition_350ms'
                                        onClick={handleHoverIn}
                                    >
                                        {/* <MdIcons.MdKeyboardArrowUp /> */}
                                        <MdIcons.MdHelpOutline />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

FeedbackIcon.propTypes = {
    setAlert: PropTypes.func.isRequired,
    submitFeedbackForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, { setAlert, submitFeedbackForm })(
    FeedbackIcon
);
