import {
    PORTFOLIO_ERROR,
    LOAD_PORTFOLIOS,
    CLEAR_PORTFOLIOS,
    SET_PORTFOLIO,
    GET_PORTFOLIO,
    GET_URL_PORTFOLIO,
    SET_PORTFOLIO_PERFORMANCE,
    SET_CURRENT_PORTFOLIO,
    CHANGE_PORTFOLIO,
    DELETE_PORTFOLIO,
    SET_CURRENT_CREATOR_COMPANIES,
    CLEAR_CURRENT_CREATOR,
    SET_CURRENT_CREATOR_PERFORMANCE,
    ADD_PORTFOLIO_COMPANY,
    ADD_TRANSACTION_FROM_PORTFOLIO,
    SET_MANAGER_DIVERSIFICATION,
    UPDATE_CREATOR_ALLOCATION,
    UPDATE_PORTFOLIO_PERFORMANCE,
    UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE,
    UPDATE_PORTFOLIO_TRACKER_PERFORMANCE,
    SUBMIT_PORTFOLIO_ALLOCATION,
    UPDATE_PORTFOLIO_SHARE_COUNT,
    RESET_PORTFOLIO_BENCHMARKS,
    UPDATE_PORTFOLIO_BENCHMARKS,
    SET_CREATOR_DIVERSIFICATION,
    SET_RISK_FREE_RATE,
    SET_CREATOR_POSITIONS,
    ADD_CREATOR_TO_MANAGER,
    GET_PORTFOLIO_TRACKER_COMPANIES,
    SET_CREATOR_WINDOW,
    OPEN_CHANGES_PORTFOLIO,
} from "../actions/Types";

const initialState = {
    portfolios: [],
    benchmarks: [],
    current: 1,
    changes: false,
    changes_component: null,
    rerender: false,
    in_creation_preferences: {
        diversification: 10000,
        fractional_shares: false,
        risk_assessment_values: {
            question_1: null,
            question_2: null,
            question_3: null,
            question_4: null,
            question_5: null,
            question_6: null,
            question_7: null,
            question_8: null,
        },
        risk_assessment_checked: {
            question_1: false,
            question_2: false,
            question_3: false,
            question_4: false,
            question_5: false,
            question_6: false,
            question_7: false,
            question_8: false,
        },
        risk: 1,
        positions: 15,
        esg: false,
        dividends: false,
        value: false,
        growth: false,
        technologies: [],
    },
    creator_window: 1,
    creator_survey_windows: {
        one: true,
        two: false,
        three: false,
        four: false,
    },
    current_creator_companies: null,
    current_creator_allocation: null,
    current_creator_performance: {
        performance: [],
        buy_in_total_price: null,
    },

    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PORTFOLIOS:
            return {
                ...state,
                portfolios: action.payload,
                changes: false,
                current:
                    action.payload.length > 0 ? action.payload[0].id : null,
            };

        case CLEAR_PORTFOLIOS:
            return {
                portfolios: [],
                current: 1,
                changes: false,
                rerender: false,
                in_creation_preferences: {
                    diversification: 10000,
                    fractional_shares: false,
                    risk_assessment_values: {
                        question_1: null,
                        question_2: null,
                        question_3: null,
                        question_4: null,
                        question_5: null,
                        question_6: null,
                        question_7: null,
                        question_8: null,
                    },
                    risk_assessment_checked: {
                        question_1: false,
                        question_2: false,
                        question_3: false,
                        question_4: false,
                        question_5: false,
                        question_6: false,
                        question_7: false,
                        question_8: false,
                    },
                    risk: 1,
                    esg: false,
                    dividends: false,
                    value: false,
                    growth: false,
                },
                creator_window: 1,
                creator_survey_windows: {
                    one: true,
                    two: false,
                    three: false,
                    four: false,
                },
                current_creator_companies: null,
                current_creator_allocation: null,
                current_creator_performance: {
                    performance: [],
                    buy_in_total_price: null,
                },

                error: null,
            };

        case ADD_PORTFOLIO_COMPANY:
            return {
                ...state,
                portfolios: action.payload,
                rerender: !state.rerender,
            };

        case ADD_TRANSACTION_FROM_PORTFOLIO:
            // portfolio_id
            // ticker
            // transactions
            // allocation
            // performance
            // companies
            return {
                ...state,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.portfolio_id
                        ? {
                              ...portfolio,
                              ticker_tracker: action.payload.ticker,
                              transactions: action.payload.transactions,
                              // portfolio_value_tracker: action.payload.portfolio_value,
                              allocation_tracker: action.payload.allocation,
                              performance_tracker: action.payload.performance,
                              assets: action.payload.companies,
                          }
                        : portfolio
                ),
            };

        case SET_PORTFOLIO:
            return {
                ...state,
                current: action.payload.id,
                portfolios: [
                    ...state.portfolios,
                    {
                        id: action.payload.id,
                        type: action.payload.type,
                        user_id: action.payload.user_id,
                        public: action.payload.public,
                        name: action.payload.name,
                        description: action.payload.description,
                        icon: action.payload.icon,
                        date: action.payload.date,
                        preferences: {},
                        ticker: [],
                        assets: [],
                        allocation: {},
                        performance: [],
                        diversification_ideas: [],
                        strategy_ideas: [],
                    },
                ],
            };

        case GET_PORTFOLIO:
            return {
                ...state,
                changes: false,
                current: action.payload.id,
                risk_free_rate:
                    action.payload.risk_free_rate &&
                    action.payload.risk_free_rate !== null
                        ? action.payload.risk_free_rate
                        : null,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.id
                        ? {
                              ...portfolio,
                              assets: action.payload.companies,
                              performance: action.payload.performance,
                          }
                        : portfolio
                ),
            };

        case GET_URL_PORTFOLIO:
            return {
                ...state,
                changes: false,
                current: action.payload.id,
                risk_free_rate:
                    action.payload.risk_free_rate &&
                    action.payload.risk_free_rate !== null
                        ? action.payload.risk_free_rate
                        : null,
                portfolios: [...state.portfolios, action.payload.url_portfolio],
            };

        case SET_MANAGER_DIVERSIFICATION:
            return {
                ...state,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.id
                        ? {
                              ...portfolio,
                              diversification_ideas:
                                  action.payload.diversification_data,
                              strategy_ideas: action.payload.strategy_data,
                          }
                        : portfolio
                ),
            };

        case SET_RISK_FREE_RATE:
            return {
                ...state,
                risk_free_rate: action.payload,
            };

        case SET_CURRENT_PORTFOLIO:
            return {
                ...state,
                changes: false,
                current: action.payload,
                error: false,
            };

        case SET_CREATOR_DIVERSIFICATION:
            return {
                ...state,
                in_creation_preferences: action.payload,
            };

        case SET_CREATOR_POSITIONS:
            return {
                ...state,
                in_creation_preferences: {
                    ...state.in_creation_preferences,
                    positions: action.payload,
                },
            };

        case CLEAR_CURRENT_CREATOR:
            return {
                ...state,
                current_creator_companies: null,
                current_creator_allocation: null,
                current_creator_performance: {
                    performance: [],
                    buy_in_total_price: null,
                },
            };

        case SET_CREATOR_WINDOW:
            return {
                ...state,
                creator_window: action.payload,
            };

        case SET_CURRENT_CREATOR_COMPANIES:
            return {
                ...state,
                current_creator_companies: action.payload.companies,
                current_creator_allocation: action.payload.allocation,
                current_creator_performance: action.payload.performance,
            };

        case UPDATE_CREATOR_ALLOCATION:
            return {
                ...state,
                current_creator_allocation: action.payload.allocation,
                current_creator_performance: action.payload.performance,
            };

        case UPDATE_PORTFOLIO_PERFORMANCE:
            return {
                ...state,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.id
                        ? {
                              ...portfolio,
                              performance: action.payload.performance,
                          }
                        : portfolio
                ),
            };

        case UPDATE_PORTFOLIO_ALLOCATION_PERFORMANCE:
            return {
                ...state,
                // changes: true,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.id
                        ? {
                              ...portfolio,
                              allocation: action.payload.allocation,
                              performance: action.payload.performance,
                          }
                        : portfolio
                ),
            };

        case UPDATE_PORTFOLIO_TRACKER_PERFORMANCE:
            return {
                ...state,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.portfolio_id
                        ? {
                              ...portfolio,
                              performance_tracker: action.payload.performance,
                          }
                        : portfolio
                ),
            };

        case SUBMIT_PORTFOLIO_ALLOCATION:
            return {
                ...state,
                changes: false,
            };

        case ADD_CREATOR_TO_MANAGER:
            return {
                ...state,
                changes: false,
                portfolios: state.portfolios.map((portfolio) =>
                    portfolio.id === action.payload.id
                        ? action.payload
                        : portfolio
                ),
            };

        case GET_PORTFOLIO_TRACKER_COMPANIES:
            return {
                ...state,
                current_companies: action.payload.companies,
                current_performance: action.payload.performance.performance,
                total_buy_value: action.payload.performance.buy_in_total_price,
            };

        case RESET_PORTFOLIO_BENCHMARKS:
            return {
                ...state,
                benchmarks: [],
            };

        case UPDATE_PORTFOLIO_BENCHMARKS:
            return {
                ...state,
                benchmarks: action.payload,
                changes: false,
                changes_component: null,
            };

        case OPEN_CHANGES_PORTFOLIO:
            return {
                ...state,
                changes: action.payload.changes,
                changes_component: action.payload.changes_component,
            };

        case PORTFOLIO_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
