import React from "react";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

// COMPONENTS
import CreateWatchlist from "./CreateWatchlist";

// ACTIONS
import { setAlert } from "../../../actions/alertActions";
import { addWatchlistCompany } from "../../../actions/watchlistActions";

// MUI
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import { makeStyles, useTheme } from "@mui/styles";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

// MULTI SELECT DROPDOWN STYLING
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "8px",
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: "24px",
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddToWatchlist = ({
    closeModal,
    company,
    user,
    watchlist,
    addWatchlistCompany,
}) => {
    // TARGET WATCHLISTS
    const [watchlistState, setWatchlistState] = React.useState([]);
    const handleWatchlistState = (event) => {
        // value = watchlist id
        setWatchlistState(event.target.value);
    };

    const handleAddToWatchlist = (ticker) => {
        if (watchlistState.length > 0) {
            let data = {
                user_id: user.id,
                ticker: ticker,
                watchlists: watchlistState,
            };
            addWatchlistCompany(data, watchlist.watchlists);
        }
        // handleClickWatchlistSuccess();
    };

    // CREATE WATCHLIST MODAL
    const [openCreateWatchlist, setOpenCreateWatchlist] = React.useState(false);
    const handleClickOpenCreateWatchlist = () => {
        setOpenCreateWatchlist(true);
    };
    const handleCloseCreateWatchlist = () => {
        setOpenCreateWatchlist(false);
    };

    return (
        <div>
            {window.location.pathname !== "/watchlist" ? (
                <div className='background_color_2 text_color_1'>
                    {/* HEADER */}
                    <div className='flex space_between title_5 padding_15px'>
                        <div className='mr_10px'>{`Add ${company.ticker} to watchlist(s)`}</div>
                        <div
                            className='cursor_pointer title_5 flex center ml_25px mt_5px'
                            onClick={closeModal}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider_no_margin'></div>
                    {/* input part */}
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        <div className='margin_10px flex center width_auto_100pr'>
                            {/* DROPDOWN */}
                            <div className='min_width_220px width_90pr'>
                                <FormControl
                                    fullWidth
                                    // size='small'
                                    sx={{ minWidth: 220 }}
                                >
                                    <InputLabel
                                        id='demo-mutiple-checkbox-label'
                                        className='mt_5px'
                                    >
                                        Select Watchlists
                                    </InputLabel>
                                    <Select
                                        className='min_width_220px height_32px background_color_3'
                                        labelId='demo-mutiple-checkbox-label'
                                        label='Select Watchlists'
                                        id='demo-mutiple-checkbox'
                                        multiple
                                        value={watchlistState}
                                        onChange={handleWatchlistState}
                                        input={<Input />}
                                        renderValue={() => {
                                            let render = "";
                                            watchlist.watchlists.map(
                                                (watchlist, idx) =>
                                                    watchlistState.includes(
                                                        watchlist.id
                                                    )
                                                        ? render === ""
                                                            ? (render +=
                                                                  watchlist.name)
                                                            : (render +=
                                                                  ", " +
                                                                  watchlist.name)
                                                        : null
                                            );
                                            return render;
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem disabled value=''>
                                            <em>Select Watchlists</em>
                                        </MenuItem>
                                        {watchlist.watchlists.map((watch) =>
                                            watch.user_id === user.id ? (
                                                <MenuItem
                                                    key={watch.id}
                                                    name={watch.name}
                                                    value={watch.id}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            watchlistState.indexOf(
                                                                watch.id
                                                            ) > -1
                                                        }
                                                    />
                                                    <div className='mr_10px mt_5px'>
                                                        {icon_storage.map(
                                                            (icon) =>
                                                                icon.name ===
                                                                watch.icon
                                                                    ? icon.icon
                                                                    : null
                                                        )}
                                                    </div>
                                                    <ListItemText
                                                        className='text_truncated max_width_135px'
                                                        primary={watch.name}
                                                    />
                                                </MenuItem>
                                            ) : null
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='margin_10px flex center min_width_180px width_auto_100pr'>
                            {/* CREATE BUTTON */}
                            <div
                                className='button_blue text_2 height_32px width_90pr mt_15px padding_10px'
                                key='newWatchlistModal'
                                onClick={() => {
                                    handleClickOpenCreateWatchlist();
                                }}
                            >
                                Create Watchlist
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='width_100pr flex right background_color_2'>
                        <div
                            className='button_blue height_32px margin_15px'
                            onClick={() => {
                                handleAddToWatchlist(company.ticker);
                                closeModal();
                            }}
                        >
                            <div className='pl_10px text_2'>Add</div>
                            <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                        </div>
                    </div>

                    {/* CREATE WATCHLIST */}
                    <Dialog
                        open={openCreateWatchlist}
                        onClose={handleCloseCreateWatchlist}
                        maxWidth={false}
                    >
                        <CreateWatchlist
                            closeModal={handleCloseCreateWatchlist}
                        />
                    </Dialog>
                </div>
            ) : null}
        </div>
    );
};

AddToWatchlist.propTypes = {
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    addWatchlistCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    watchlist: state.watchlist,
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    addWatchlistCompany,
})(AddToWatchlist);
