import {
    SET_WATCHLIST,
    RENAME_WATCHLIST,
    SET_CURRENT_WATCHLIST,
    DELETE_WATCHLIST,
    GET_WATCHLIST_COMPANIES,
    GET_URL_WATCHLIST_COMPANIES,
    ADD_WATCHLIST_COMPANY,
    DELETE_WATCHLIST_COMPANY,
    WATCHLIST_ERROR,
    SET_ALERT,
    REMOVE_ALERT,
} from "./Types";

// Create a new Watchlist
export const setWatchlist =
    (watchlist_data, watchlist, user_status) => async (dispatch) => {
        try {
            let new_data = watchlist.watchlists;
            let number_of_watchlists = 0;

            new_data.map((one_watchlist) => {
                if (one_watchlist.user_id === watchlist_data.user) {
                    number_of_watchlists += 1;
                }
            });

            if (
                (user_status > 0 && number_of_watchlists < 10) ||
                (user_status === 0 && number_of_watchlists === 0) ||
                user_status > 90
            ) {
                const res = await fetch("/node_create_watchlist", {
                    method: "POST",
                    body: JSON.stringify({
                        name: watchlist_data.name,
                        user: watchlist_data.user,
                        icon: watchlist_data.icon,
                        description: watchlist_data.description,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // DO WHAT IS INTENDED
                    dispatch({
                        type: SET_WATCHLIST,
                        payload: data,
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            } else {
                // USER ALREADY HAS TOO MANY PORTFOLIOS
                const id = Math.floor(Math.random() * 100);
                let type = "warning";
                let msg =
                    user_status === 0
                        ? "Creating this watchlist would exceed the maximum amount of watchlists you are allowed to maintain. Upgrade your plan to be able to continue."
                        : "Creating this watchlist would exceed the maximum amount of watchlists you are allowed to maintain.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    7000
                );
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred creating your new watchlist.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// Set Watchlist to 'current' watchlist
export const setCurrentWatchlist = (id) => {
    return {
        type: SET_CURRENT_WATCHLIST,
        payload: id,
    };
};

// RENAME WATCHLIST
export const renameWatchlist = (form_data, watchlist) => async (dispatch) => {
    try {
        let new_data = watchlist.watchlists;
        let watchlist_user_id;

        new_data.map((loop_watchlist) => {
            if (loop_watchlist.id === form_data.id) {
                watchlist_user_id = loop_watchlist.user_id;
                if (loop_watchlist.user_id === form_data.user) {
                    loop_watchlist.name = form_data.name;
                    loop_watchlist.icon = form_data.icon;
                    loop_watchlist.description = form_data.description;
                    loop_watchlist.public = form_data.public;
                }
            }
        });

        if (watchlist_user_id === form_data.user) {
            const res = await fetch("/node_rename_watchlist", {
                method: "POST",
                body: JSON.stringify({
                    watchlist: form_data.id,
                    name: form_data.name,
                    icon: form_data.icon,
                    description: form_data.description,
                    public: form_data.public,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const res_data = await res.json();
            if (!res_data.error) {
                // DO WHAT IS INTENDED

                dispatch({
                    type: RENAME_WATCHLIST,
                    payload: new_data,
                });

                const id = Math.floor(Math.random() * 100);
                let type = "success";
                let msg = "Your watchlist has been updated successfully.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = res_data.error_type
                    ? res_data.error_type
                    : "warning";
                let msg = res_data.error_msg
                    ? res_data.error_msg
                    : "An error occurred when trying add changes to your watchlist's settings.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occurred when trying add changes to your watchlist's settings.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Delete watchlist
export const deleteWatchlist = (data, watchlist) => async (dispatch) => {
    console.log("DELETE");
    try {
        let new_data = watchlist;
        let watchlist_user_id;

        new_data.watchlists.map((one_watchlist, index) => {
            if (one_watchlist.id === data.id) {
                watchlist_user_id = one_watchlist.user_id;
                if (data.user === one_watchlist.user_id) {
                    new_data.watchlists.splice(index, 1);
                }
            }
        });

        if (watchlist_user_id === data.user) {
            const res = await fetch("/node_delete_watchlist", {
                method: "POST",
                body: JSON.stringify({
                    watchlist: data.id,
                    user: data.user,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const res_data = await res.json();
            if (!res_data.error) {
                // DO WHAT IS INTENDED
                dispatch({
                    type: DELETE_WATCHLIST,
                    payload: new_data,
                });
                const id = Math.floor(Math.random() * 100);
                let type = "success";
                let msg = "Your watchlist has been deleted successfully.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = res_data.error_type
                    ? res_data.error_type
                    : "warning";
                let msg = res_data.error_msg
                    ? res_data.error_msg
                    : "An error occurred when trying to delete a watchlist.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } else {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred when trying to delete a watchlist.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred when trying to delete a watchlist.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Get Company Data of a specific watchlist
export const getWatchlist = (id, uid, sortBy, sortDir) => async (dispatch) => {
    try {
        if (id !== null && uid !== null) {
            const res = await fetch("/node_watchlist", {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    uid: uid,
                    sortBy: sortBy,
                    sortDir: sortDir,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            if (!data.error) {
                dispatch({
                    type: GET_WATCHLIST_COMPANIES,
                    payload: {
                        companies: data.companies,
                        watchlist: data.watchlist,
                    },
                });
            } else {
                const id = Math.floor(Math.random() * 100);
                let msg =
                    "This watchlist either doesn't exist or is not publicly available";
                let type = "warning";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    5000
                );
                dispatch({
                    type: WATCHLIST_ERROR,
                });
            }
        } else {
            // handle case, that watchlist is just initiated, or does not have any values inserted
            dispatch({
                type: GET_WATCHLIST_COMPANIES,
                payload: [],
            });
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let msg =
            "This watchlist either doesn't exist or is not publicly available";
        let type = "warning";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
        dispatch({
            type: WATCHLIST_ERROR,
            payload: err.response.statusText,
        });
    }
};

// Add company to watchlist(s)
export const addWatchlistCompany = (data, watchlists) => async (dispatch) => {
    try {
        let watchlists_to_add = [];
        let ticker_already_contained_in = "";
        let count_contained_in = 0;

        watchlists.map((watchlist) => {
            if (watchlist.ticker !== null) {
                if (
                    data.watchlists.includes(watchlist.id) &&
                    data.user_id === watchlist.user_id
                ) {
                    if (!watchlist.ticker.includes(data.ticker)) {
                        watchlists_to_add.push(watchlist.id);
                        if (watchlist.ticker !== null) {
                            watchlist.ticker.push(data.ticker);
                        } else {
                            watchlist.ticker = [data.ticker];
                        }
                    } else {
                        count_contained_in += 1;
                        ticker_already_contained_in +=
                            count_contained_in === 1
                                ? watchlist.name
                                : "," + watchlist.name;
                    }
                }
            } else {
                if (
                    data.watchlists.includes(watchlist.id) &&
                    data.user_id === watchlist.user_id
                ) {
                    watchlists_to_add.push(watchlist.id);
                    watchlist.ticker = [data.ticker];
                }
            }
        });

        if (ticker_already_contained_in !== "") {
            const id = Math.floor(Math.random() * 100);
            let msg = `The company you selected is already contained in: ${ticker_already_contained_in}.`;
            let type = "warning";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }

        if (data.watchlists.length > count_contained_in) {
            const res = await fetch("/node_add_to_watchlists", {
                method: "POST",
                body: JSON.stringify({
                    ticker: data.ticker,
                    watchlists: watchlists_to_add,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const res_data = await res.json();

            if (!res_data.error) {
                let new_data = watchlists;

                dispatch({
                    type: ADD_WATCHLIST_COMPANY,
                    payload: new_data,
                });
                const id = Math.floor(Math.random() * 100);
                let msg = `${data.ticker} has been added to your selected watchlists.`;
                let type = "success";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    5000
                );
            } else {
                const id = Math.floor(Math.random() * 100);
                let msg =
                    "An error occurred while adding a company to your watchlists.";
                let type = "warning";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    5000
                );
            }
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let msg =
            "An error occurred while adding a company to your watchlists.";
        let type = "warning";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    }
};

// Delete company from watchlist
export const deleteWatchlistCompany =
    (ticker, watchlist, user_id) => async (dispatch) => {
        try {
            let new_data = watchlist.watchlists;
            let bool = false;

            new_data.map((one_watchlist) => {
                if (one_watchlist.id === watchlist.current) {
                    if (user_id === one_watchlist.user_id) {
                        bool = true;
                        one_watchlist.ticker.map((asset, index) =>
                            asset === ticker
                                ? one_watchlist.ticker.splice(index, 1)
                                : null
                        );
                    }
                }
            });

            if (bool) {
                const res = await fetch("/node_delete_from_watchlist", {
                    method: "POST",
                    body: JSON.stringify({
                        ticker: ticker,
                        watchlist: watchlist.current,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // DO WHAT IS INTENDED

                    dispatch({
                        type: DELETE_WATCHLIST_COMPANY,
                        payload: new_data,
                    });
                    const id = Math.floor(Math.random() * 100);
                    let msg = `${ticker} has been removed from your watchlist.`;
                    let type = "success";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        5000
                    );
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occured.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occurred when trying to remove a company from a watchlist.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };
