import { SET_ALERT, REMOVE_ALERT } from "../actions/Types";

const initialState = [
    // {
    //     msg: "This watchlist either doesn't exist or is not publicly available",
    //     type: "warning",
    //     id: 39,
    // },
];

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALERT:
            return [...state, action.payload];

        case REMOVE_ALERT:
            return state.filter((alert) => alert.id !== action.payload);

        default:
            return state;
    }
};
