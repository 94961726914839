import React from "react";
import { Link } from "react-router-dom";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { numberFormatter } from "../../../actions/generalActions";
import {
    setCompanyTicker,
    setCompanyOpenTab,
} from "../../../actions/companyActions";

// COMPONENTS
import CompaniesTableRowColumn from "./CompaniesTableRowColumn";
import CompanyActionsMenu from "../../pages/elements/CompanyActionsMenu";

const CompaniesTableRow = ({
    company,
    metrics,
    checked,
    setCompanyTicker,
    setCompanyOpenTab,
}) => {
    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    return (
        <tr
            key={`${window.location.pathname}${company.ticker}`}
            className={
                window.location.pathname === "/company" &&
                window.location.search.split("=")[1] === company.ticker
                    ? "sticky_table_tbody_tr background_color_3_hover_background_color_4"
                    : "sticky_table_tbody_tr background_color_2_hover_background_color_3"
            }
        >
            {/* Company Name */}
            <th
                style={{
                    zIndex: "9",
                }}
            >
                {" "}
                <div
                    className={
                        window.location.pathname === "/company" &&
                        window.location.search.split("=")[1] === company.ticker
                            ? "background_color_3_hover_background_color_4 width_250px flex padding_5px border_radius_10px_right"
                            : "background_color_2_hover_background_color_3 width_250px flex padding_5px border_radius_10px_right"
                    }
                >
                    <CompanyActionsMenu
                        company={company}
                        portfolioType={null}
                    />
                    <Link
                        to={`/company?ticker=${company.ticker}`}
                        onClick={() => {
                            setCompanyTicker(company.ticker);
                            // if(window.location.pathname === "/company"){
                            setCompanyOpenTab(1);
                            // }
                        }}
                        className='ml_15px'
                    >
                        <div className='text_3 text_weight_4 text_color_blue'>
                            {company.name ? company.name : company.ticker}
                        </div>
                        <div className='text_4 text_weight_4 text_color_blue'>
                            {company.ticker}
                        </div>
                    </Link>
                </div>
            </th>

            {metrics.map((metric) =>
                industries_display.includes(metric) ? (
                    <CompaniesTableRowColumn
                        company={company}
                        metric={metric}
                    />
                ) : null
            )}

            {/* Figures */}
            {metrics.map((metric) =>
                !industries_display.includes(metric) ? (
                    <CompaniesTableRowColumn
                        company={company}
                        metric={metric}
                    />
                ) : null
            )}

            {window.location.pathname === "/screener" &&
            checked > 0 &&
            localStorage.latest_screener.includes("threshold") ? (
                <td className='padding_15_5px text_3 text_right text_color_1'>
                    {company.score}
                </td>
            ) : null}

            {/* 1 day return */}
            {window.location.pathname === "/watchlist" ? (
                company.daily_return ? (
                    company.daily_return >= 0 ? (
                        <td className='padding_15_5px padding_15_5px text_3 text_right text_color_green'>
                            {numberFormatter(company.daily_return)} %
                        </td>
                    ) : (
                        <td className='padding_15_5px padding_15_5px text_3 text_right text_color_red'>
                            {numberFormatter(company.daily_return)} %
                        </td>
                    )
                ) : (
                    <td className='padding_15_5px padding_15_5px text_3 text_right text_color_1'>
                        -
                    </td>
                )
            ) : null}

            {/* Market Cap. */}
            {company.market_cap ? (
                <td className='padding_15_5px padding_15_5px text_3 text_right text_color_1'>
                    {numberFormatter(company.market_cap)} USD
                </td>
            ) : (
                <td className='padding_15_5px padding_15_5px text_3 text_right text_color_1'>
                    -
                </td>
            )}
        </tr>
    );
};

CompaniesTableRow.propTypes = {
    setCompanyTicker: PropTypes.func.isRequired,
    setCompanyOpenTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    setCompanyTicker,
    setCompanyOpenTab,
})(CompaniesTableRow);
