import {
    GET_BOOKMARKS,
    SET_BOOKMARK,
    UPDATE_BOOKMARK,
    CLEAR_BOOKMARK,
} from "./Types";

// Get Bookmarks
export const getBookmarks = () => {
    let bookmarks = [];
    if (localStorage.bookmarks) {
        bookmarks = JSON.parse(localStorage.getItem("bookmarks"));
    }
    return {
        type: GET_BOOKMARKS,
        payload: bookmarks,
    };
};

// Set Bookmark
export const setBookmark = (bookmark) => {
    return {
        type: SET_BOOKMARK,
        payload: bookmark,
    };
};

// Update Bookmark
export const updateBookmark = (updBookmark) => async (dispatch) => {
    dispatch({
        type: UPDATE_BOOKMARK,
        payload: updBookmark,
    });
};

// Clear Bookmark
export const clearBookmark = (id) => {
    return {
        type: CLEAR_BOOKMARK,
        payload: id,
    };
};
