import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import SliderInfoBox from "./sliderinfo/SliderInfoBox";
import MetricHistogramSlider from "../elements/MetricHistogramSlider";

// MUI
import Dialog from "@mui/material/Dialog";
import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
} from "../../../actions/screenerAction";
import { numberFormatter } from "../../../actions/generalActions";

const RangeSliderScreenerUpgrade = ({
    sliderprops,
    category,
    type,
    detectCheck,
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
}) => {
    const [check, setCheck] = React.useState(
        // slider[sliderprops.property].filter
        sliderprops.filter
    );

    useEffect(() => {
        // setCheck(slider[sliderprops.property].filter);
        setCheck(sliderprops.filter);
        // eslint-disable-next-line
    }, []);

    const useintl = useIntl();
    const handleCheck = (event, newValue) => {
        if (check) {
            setCheck(false);
            sliderprops.filter = false;
            sliderprops.display = false;
            updateSingleSliderValue(sliderprops, "filter");
            detectCheck(category, type, false);
        } else {
            setCheck(true);
            sliderprops.filter = true;
            sliderprops.display = true;
            updateSingleSliderValue(sliderprops, "filter");
            detectCheck(category, type, true);
        }
    };
    const [value, setValue] = React.useState(sliderprops.value);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        sliderprops.value = newValue;
    };

    const handleUpdateSingleSliderValueOnly = (event, newValue) => {
        updateSingleSliderValueOnly(sliderprops);
    };

    /*SLIDERS EDIT MIN VALUE POPOVER*/
    const [openMinModal, setOpenMinModal] = React.useState(null);
    const handleOpenMinModal = (event) => {
        setOpenMinModal(event.currentTarget);
    };
    const handleCloseMinModal = () => {
        setOpenMinModal(null);
    };
    const openMin = Boolean(openMinModal);
    const idMin = openMin ? "simple-popover" : undefined;
    const [minValue, setMinValue] = React.useState(
        sliderprops.property.includes("ranker") &&
            !sliderprops.property.includes("change")
            ? Number(value[0]).toPrecision(3)
            : value[0] === sliderprops.min_max[0]
            ? null
            : sliderprops.property === "market_cap" ||
              sliderprops.property === "market_cap_usd" ||
              sliderprops.property === "ev"
            ? Number(value[0]).toPrecision(3)
            : Number(value[0]).toPrecision(3)
    );

    const handleMinValue = (event) => {
        if (event.target.value < value[1]) {
            setMinValue(event.target.value);
            let new_value = value;
            new_value[0] = event.target.value;
            setValue(new_value);
            sliderprops.value = new_value;
        } else {
            setMinValue(value[1]);
            let new_value = value;
            new_value[0] = new_value[1];
            new_value[1] = event.target.value;
            setValue(new_value);
            sliderprops.value = new_value;
        }
    };

    const handleMinStatistics = (value_in) => {
        if (value_in < value[1]) {
            setMinValue(value_in);
            let new_value = value;
            new_value[0] = value_in;
            setValue(new_value);
            sliderprops.value = new_value;
        } else {
            setMinValue(value[1]);
            let new_value = value;
            new_value[0] = new_value[1];
            new_value[1] = value_in;
            setValue(new_value);
            sliderprops.value = new_value;
        }
    };

    /*SLIDERS EDIT MAX VALUE POPOVER*/
    const [openMaxModal, setOpenMaxModal] = React.useState(null);
    const handleOpenMaxModal = (event) => {
        setOpenMaxModal(event.currentTarget);
    };
    const handleCloseMaxModal = () => {
        setOpenMaxModal(null);
    };
    const openMax = Boolean(openMaxModal);
    const idMax = openMax ? "simple-popover" : undefined;
    const [maxValue, setMaxValue] = React.useState(
        sliderprops.property.includes("ranker") &&
            !sliderprops.property.includes("change")
            ? Number(value[1]).toPrecision(3)
            : value[1] === sliderprops.min_max[1]
            ? null
            : sliderprops.property === "market_cap" ||
              sliderprops.property === "market_cap_usd"
            ? Number(value[1]).toPrecision(3)
            : Number(value[1]).toPrecision(3)
    );

    const handleMaxValue = (event) => {
        if (event.target.value > value[0]) {
            setMaxValue(event.target.value);
            let new_value = value;
            new_value[1] = event.target.value;
            setValue(new_value);
            sliderprops.value = new_value;
        } else {
            setMaxValue(value[0]);
            let new_value = value;
            new_value[1] = new_value[0];
            new_value[0] = event.target.value;
            setValue(new_value);
            sliderprops.value = new_value;
        }
    };

    const handleMaxStatistics = (value_in) => {
        if (value_in > value[0]) {
            setMaxValue(value_in);
            let new_value = value;
            new_value[1] = value_in;
            setValue(new_value);
            sliderprops.value = new_value;
        } else {
            setMaxValue(value[0]);
            let new_value = value;
            new_value[1] = new_value[0];
            new_value[0] = value_in;
            setValue(new_value);
            sliderprops.value = new_value;
        }
    };

    // property: 'revenue_growth_5y',
    // value: [20, 30],
    // default: [20, 30],
    // statistics: [15, 35, 55],
    // min_max: [0, 1]
    // filter: false,
    // display: true,
    // displayName: 'Revenue Growth (5years) [%]',
    // axis: 'log',

    /* Sliders Tooltip Styling */
    function ValueLabelComponent(props) {
        const { children, open, value } = props;

        const popperRef = React.useRef(null);
        React.useEffect(() => {
            if (popperRef.current) {
                popperRef.current.update();
            }
        });

        return (
            <Tooltip
                PopperProps={{
                    popperRef,
                }}
                open={open}
                enterTouchDelay={0}
                placement='top'
                title={
                    sliderprops.property.includes("ranker") &&
                    !sliderprops.property.includes("change") ? (
                        Number(value).toPrecision(3)
                    ) : value === sliderprops.min_max[0] ||
                      value === sliderprops.min_max[1] ? (
                        <div className='flex text_color_1'>
                            <p className='pb_3px mr_3px'></p>
                            <IoIcons5.IoInfiniteOutline />
                            <div className='pb_2px ml_3px'>
                                {sliderprops.unit}
                            </div>
                        </div>
                    ) : sliderprops.property === "market_cap" ||
                      sliderprops.property === "market_cap_usd" ||
                      sliderprops.property === "ev" ? (
                        numberFormatter(Number(value).toPrecision(3)) +
                        `${sliderprops.unit}`
                    ) : (
                        Number(value).toPrecision(3) + `${sliderprops.unit}`
                    )
                }
            >
                {children}
            </Tooltip>
        );
    }
    ValueLabelComponent.propTypes = {
        children: PropTypes.element.isRequired,
        open: PropTypes.bool.isRequired,
        value: PropTypes.number.isRequired,
    };

    return (
        <div
            className={
                check
                    ? "screener_slider_grid accent_color_3 text_color_2 border_3px_solid opacity_80"
                    : "screener_slider_grid background_color_3 text_color_2 border_3px_solid opacity_80"
            }
            key={sliderprops.property}
        >
            <div className='screener_slider_checkbox'>
                <form action='#'>
                    <label>
                        <Checkbox
                            id={sliderprops.property}
                            name={sliderprops.property}
                            checked={check}
                            color='primary'
                            // onChange={handleCheck}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                        />
                    </label>
                </form>
            </div>
            <div id='range-slider' className='screener_slider_label text_3'>
                <p className='text_4'>{sliderprops.displayName}</p>
            </div>
            <div className='screener_slider_range_grid'>
                <div className='screener_min_value' aria-describedby={idMin}>
                    {sliderprops.property.includes("ranker") &&
                    !sliderprops.property.includes("change") ? (
                        Number(value[0]).toPrecision(3)
                    ) : value[0] <= sliderprops.min_max[0] ? (
                        <div className='flex'>
                            <p className='pb_3px mr_3px'>-</p>
                            <IoIcons5.IoInfiniteOutline />
                            <div className='pb_2px ml_3px'>
                                {sliderprops.unit}
                            </div>
                        </div>
                    ) : sliderprops.property === "market_cap" ||
                      sliderprops.property === "market_cap_usd" ||
                      sliderprops.property === "ev" ? (
                        numberFormatter(Number(value[0]).toPrecision(3)) +
                        `${sliderprops.unit}`
                    ) : (
                        Number(value[0]).toPrecision(3) + `${sliderprops.unit}`
                    )}
                </div>
                <div className='flex center'>
                    <Link to={`/account`}>Upgrade</Link>
                </div>
                <div className='screener_max_value' aria-describedby={idMax}>
                    {sliderprops.property.includes("ranker") &&
                    !sliderprops.property.includes("change") ? (
                        Number(value[1]).toPrecision(3)
                    ) : value[1] >= sliderprops.min_max[1] ? (
                        <div className='flex'>
                            <IoIcons5.IoInfiniteOutline />
                            <div className='pb_2px ml_3px'>
                                {sliderprops.unit}
                            </div>
                        </div>
                    ) : sliderprops.property === "market_cap" ||
                      sliderprops.property === "market_cap_usd" ||
                      sliderprops.property === "ev" ? (
                        numberFormatter(Number(value[1]).toPrecision(3)) +
                        `${sliderprops.unit}`
                    ) : (
                        Number(value[1]).toPrecision(3) + `${sliderprops.unit}`
                    )}
                </div>
            </div>
            <div className='screener_icon_large text_2'>
                <AiIcons.AiOutlineFullscreen className='screener_icon_widen' />
                <IoIcons.IoIosRefresh className='screener_icon_reset' />
                {sliderprops.info ? (
                    <AiIcons.AiOutlineInfoCircle className='screener_icon_info' />
                ) : null}
            </div>
        </div>
    );
};

RangeSliderScreenerUpgrade.propTypes = {
    updateSingleSliderValue: PropTypes.func.isRequired,
    updateSingleSliderValueOnly: PropTypes.func.isRequired,
    clearSingleSliderValue: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//     screener: state.screener,
// });

export default connect(null, {
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
})(RangeSliderScreenerUpgrade);
