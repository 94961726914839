import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    createNewScreenerPreset,
    overwriteScreenerPreset,
    deleteScreenerPreset,
    renameScreenerPreset,
} from "../../../actions/screenerAction";

// MUI
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";

// ICONS
import * as AiIcons from "react-icons/ai";

const Presets = ({
    closeModal,
    screener,
    user,
    createNewScreenerPreset,
    overwriteScreenerPreset,
    deleteScreenerPreset,
    renameScreenerPreset,
}) => {
    const useintl = useIntl();

    // STATE IF CREATE OR EDIT
    const [createOverwrite, setCreateOverwrite] = useState(0);

    // STATE FOR THE NEW NAME
    const [screenerName, setScreenerName] = useState("");
    const handleScreenerName = (event) => {
        if (event.target.value.length <= 20) {
            setScreenerName(event.target.value);
        }
    };

    // OPEN EDIT PRESET MODAL
    const [openEditPreset, setOpenEditPreset] = React.useState(false);
    const handleOpenEditPreset = () => {
        setOpenEditPreset(true);
    };

    const handleCloseEditPreset = () => {
        setOpenEditPreset(false);
    };
    // SAVE CURRENT SELECTED PRESET
    const [currentPresetEdited, setCurrentPresetEdited] = useState(null);
    // EDIT NAME OF SELECTED PRESET
    const [editName, setEditName] = useState("");
    const handleEditName = (event) => {
        if (event.target.value.length <= 20) {
            setEditName(event.target.value);
        }
    };
    // SUBMIT EDITED NAME
    const handleSubmitEditName = () => {
        renameScreenerPreset(user.id, currentPresetEdited, editName);
        handleCloseEditPreset();
    };

    // SUBMIT THE PRESET
    const handleSubmitNewPreset = () => {
        createNewScreenerPreset(
            screener.slider,
            screener.options,
            screener.threshold,
            user.id,
            screenerName
        );
        setCreateOverwrite(0);
    };

    // OVERWRITE THE PRESET
    const handleOverwritePreset = (preset_name, preset_id) => {
        overwriteScreenerPreset(
            screener.slider,
            screener.options,
            screener.threshold,
            user.id,
            preset_name,
            preset_id
        );
    };

    // DELETE THE PRESET
    const handleDeletePreset = (preset_id) => {
        deleteScreenerPreset(user.id, preset_id);
    };

    return (
        <div className='width_100pr max_width_500px background_color_2 text_color_1 min_width_320px'>
            <div className='flex space_between title_4 mt_10px mb_10px pl_15px pr_15px'>
                <div className='mr_10px'>Screener presets</div>
                <div
                    className='cursor_pointer title_4 flex center mt_5px'
                    onClick={closeModal}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            <div className='divider_no_margin'></div>
            <div className='width_100pr flex'>
                <div
                    className={
                        createOverwrite === 0
                            ? "width_50pr padding_10px flex center cursor_pointer background_color_1 text_decoration_underline"
                            : "width_50pr padding_10px flex center cursor_pointer"
                    }
                    onClick={() => {
                        setCreateOverwrite(0);
                    }}
                >
                    Your presets
                </div>
                <div
                    className={
                        createOverwrite === 1
                            ? "width_50pr padding_10px flex center cursor_pointer background_color_1 text_decoration_underline"
                            : "width_50pr padding_10px flex center cursor_pointer"
                    }
                    onClick={() => {
                        setCreateOverwrite(1);
                    }}
                >
                    Create new
                </div>
            </div>
            <div className='divider_no_margin'></div>
            {createOverwrite === 0 ? (
                <div className='width_100pr padding_10px mt_10px mb_10px'>
                    {screener.presets &&
                    Object.keys(screener.presets).length !== 0 ? (
                        Object.values(screener.presets).map((preset) => {
                            return (
                                <div className='flex space_between mt_10px border_radius_10px shadow_dark padding_10px background_color_3'>
                                    <div className='flex'>
                                        <MdIcons.MdModeEditOutline
                                            className='mr_10px cursor_pointer'
                                            onClick={() => {
                                                handleOpenEditPreset();
                                                setCurrentPresetEdited(
                                                    preset.id
                                                );
                                            }}
                                        />
                                        <a
                                            className='flex'
                                            href={`/screener${preset.url}`}
                                        >
                                            {preset.name}
                                        </a>
                                    </div>
                                    {user.status === 80 ? (
                                        <div className='flex'></div>
                                    ) : (
                                        <div className='flex'>
                                            <a
                                                href={`/screener${preset.url}`}
                                                className='button_blue padding_10px mr_10px'
                                            >
                                                <div className='text_2 pr_10px'>
                                                    {useintl.formatMessage({
                                                        id: "general.apply",
                                                    })}
                                                </div>
                                                <FiIcons.FiSliders />
                                            </a>
                                            <div
                                                className='button_grey padding_10px mr_10px'
                                                onClick={() => {
                                                    handleOverwritePreset(
                                                        preset.name,
                                                        preset.id
                                                    );
                                                }}
                                            >
                                                <div className='pr_10px'>
                                                    Overwrite
                                                </div>
                                                <MdIcons.MdRestorePage />
                                            </div>
                                            <div
                                                className='button_delete padding_10px'
                                                onClick={() => {
                                                    handleDeletePreset(
                                                        preset.id
                                                    );
                                                }}
                                            >
                                                <div className='pr_10px'>
                                                    Delete
                                                </div>
                                                <MdIcons.MdDeleteOutline />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className='flex'>
                            <div className='mr_5px'>
                                You have not created any preset yet. Create a
                                preset
                            </div>
                            <div
                                className='text_color_blue cursor_pointer'
                                onClick={() => {
                                    setCreateOverwrite(1);
                                }}
                            >
                                here
                            </div>
                        </div>
                    )}
                    {screener.presets &&
                    Object.keys(screener.presets).length !== 0 ? (
                        <div className='mt_20px'>
                            Click on a saved preset to apply the filter. By
                            clicking "Overwrite", the current selected filter
                            options are getting saved under the respective
                            preset name.
                        </div>
                    ) : null}
                </div>
            ) : user.status === 80 ? (
                <div className='padding_20px'>
                    You need to create an account to create and edit your own
                    presets
                </div>
            ) : (
                <div className='width_100pr padding_10px mt_10px mb_10px'>
                    <div>Preset name</div>
                    <div className='input_basic_border border_radius_5px width_80pr ml_10px_0px padding_5px mt_15px'>
                        <input
                            className='text_2 text_color_1'
                            type='text'
                            placeholder='Name...'
                            maxlength='25'
                            onKeyUp={handleScreenerName}
                        />
                    </div>
                    <div className='mt_15px'>
                        By submitting you save the current selected screener
                        options under the chosen name above.
                    </div>
                </div>
            )}
            {createOverwrite === 0 || user.status === 80 ? null : (
                <div className='width_100pr mt_10px mb_10px ml_15px mr_15px flex right'>
                    <div
                        className='button_blue padding_10px'
                        onClick={handleSubmitNewPreset}
                    >
                        Submit
                    </div>
                </div>
            )}

            {/* CHANGE PRESET NAME */}
            <Dialog
                open={openEditPreset}
                onClose={handleCloseEditPreset}
                maxWidth='xl'
                className=''
            >
                <div className='width_100pr max_width_500px background_color_2 text_color_1 min_width_320px'>
                    <div className='flex space_between title_4 mt_10px mb_10px pl_15px pr_15px'>
                        <div className='mr_10px flex'>
                            <div className='mr_10px'>Rename preset</div>
                            <div>
                                (
                                {currentPresetEdited &&
                                screener.presets &&
                                Object.keys(screener.presets).length !== 0
                                    ? screener.presets[`${currentPresetEdited}`]
                                          .name
                                    : null}
                                )
                            </div>
                        </div>
                        <div
                            className='cursor_pointer title_4 flex center mt_5px'
                            onClick={handleCloseEditPreset}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    {user.status === 80 ? (
                        <div className='padding_20px'>
                            You need to create an account to create and edit
                            your own presets
                        </div>
                    ) : (
                        <div>
                            <div className='divider_no_margin'></div>
                            <div className='width_100pr padding_10px mt_10px mb_10px'>
                                <div>Preset name</div>
                                <div className='input_basic_border border_radius_5px width_80pr ml_10px_0px padding_5px mt_15px'>
                                    <input
                                        className='text_2 text_color_1'
                                        type='text'
                                        placeholder='Name...'
                                        maxlength='25'
                                        onKeyUp={handleEditName}
                                    />
                                </div>
                                <div className='mt_15px'>
                                    By submitting you change the name of the
                                    selected preset.
                                </div>
                            </div>
                            <div className='divider_no_margin'></div>
                            <div className='width_100pr mt_10px mb_10px ml_15px mr_15px flex right'>
                                <div
                                    className='button_blue padding_10px'
                                    onClick={handleSubmitEditName}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

Presets.propTypes = {
    createNewScreenerPreset: PropTypes.func.isRequired,
    overwriteScreenerPreset: PropTypes.func.isRequired,
    deleteScreenerPreset: PropTypes.func.isRequired,
    renameScreenerPreset: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    user: state.user,
});

export default connect(mapStateToProps, {
    createNewScreenerPreset,
    overwriteScreenerPreset,
    deleteScreenerPreset,
    renameScreenerPreset,
})(Presets);
