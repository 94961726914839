import React, { useEffect } from "react";
import Preloader from "../../layout/Preloader";
import { useIntl } from "react-intl";
import * as IoIcons5 from "react-icons/io5";

import CompaniesTableRow from "./CompaniesTableRow";
import CompaniesTableHead from "./CompaniesTableHead";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    getFilteredCompanies,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
} from "../../../actions/screenerAction";

import { getWatchlist } from "../../../actions/watchlistActions";

import { getCompetitors } from "../../../actions/companyActions";

const CompaniesTable = ({
    companies,
    company,
    screener,
    watchlist,
    user,
    slider,
    options,
    tableStructure,
    screenerPagination,
    competitorsUrl,
    competitorsType,
    getFilteredCompanies,
    getWatchlist,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
    getCompetitors,
}) => {
    const useintl = useIntl();

    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    let tableBuild = {
        individual: { metrics: ["ticker"], headers: ["Ticker"] },
        filtered_metrics: { metrics: ["ticker"], headers: ["Ticker"] },
        value: {
            metrics: [
                "ticker",
                "price_earnings",
                "price_earnings_growth",
                "price_sales",
                "ev_ebitda",
                "price_book",
                "price_cash_flow",
                "revenue_growth_5y",
                "earnings_growth_5y",
                "debt_ratio",
                "ev",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.price_earnings",
                }),
                useintl.formatMessage({
                    id: "metrics.price_earnings_growth",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
                useintl.formatMessage({
                    id: "metrics.ev_ebitda",
                }),
                useintl.formatMessage({
                    id: "metrics.price_book",
                }),
                useintl.formatMessage({
                    id: "metrics.price_cash_flow",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.debt_ratio",
                }),
                useintl.formatMessage({
                    id: "metrics.ev",
                }),
            ],
        },
        growth: {
            metrics: [
                "ticker",
                "revenue_growth_1y",
                "revenue_growth_3y",
                "revenue_growth_5y",
                "revenue_growth_5y_ranker",
                "earnings_growth_1y",
                "earnings_growth_3y",
                "earnings_growth_5y",
                "earnings_growth_5y_ranker",
                "gross_profit_margin",
                "gross_profit_margin_ranker",
                "price_sales",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.revenue_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
            ],
        },
        dividends: {
            metrics: [
                "ticker",
                "dividend_yield",
                "dividend_consistency",
                "dividend_growth_3y",
                "earnings_growth_3y",
                "dividend_growth_5y",
                "earnings_growth_5y",
                "payout_ratio",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.dividend_yield",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_consistency",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.payout_ratio",
                }),
            ],
        },
    };
    // Get Filtered Metrics and Metrics to display in the table
    let screenerCombinedForm = { ...slider, ...options };
    tableBuild.individual.length = 0;
    tableBuild.filtered_metrics.length = 0;
    tableBuild.individual = { metrics: ["ticker"], headers: ["Ticker"] };
    tableBuild.filtered_metrics = { metrics: ["ticker"], headers: ["Ticker"] };

    for (const [key, value] of Object.entries(screenerCombinedForm)) {
        if (
            value.display &&
            value.property !== "searchOption" &&
            value.property !== "sortBy"
        ) {
            tableBuild.filtered_metrics.metrics.push(key);
            tableBuild.filtered_metrics.headers.push(value.displayName);
        }
        if (value.display) {
            tableBuild.individual.metrics.push(key);
            tableBuild.individual.headers.push(value.displayName);
        }
    }

    let tableValues;
    if (tableStructure === "filtered_metrics") {
        tableValues = tableBuild.filtered_metrics;
    } else if (tableStructure === "value") {
        tableValues = tableBuild.value;
    } else if (tableStructure === "growth") {
        tableValues = tableBuild.growth;
    } else if (tableStructure === "dividends") {
        tableValues = tableBuild.dividends;
    }

    // PAGINATION TIMEOUT
    const [time, setTime] = React.useState(0);
    const handlePaginationRequest = (event) => {
        let d = new Date();
        let n = d.getTime();

        if (n - time > 2000) {
            getFilteredCompanies(
                slider,
                options,
                Number(screenerPagination) + 1,
                screener.threshold,
                screener.checked
            );
        }

        setTime(n);
    };

    const [paginationCompetitors, setPaginationCompetitors] = React.useState(0);

    const handleCompetitorsPaginationRequest = (event) => {
        let d = new Date();
        let n = d.getTime();

        if (n - time > 2000) {
            getCompetitors(
                `?${competitorsType}=${encodeURIComponent(competitorsUrl)}`,
                screenerCombinedForm.sortBy.value,
                screenerCombinedForm.sortBy.direction,
                paginationCompetitors + 1
            );
            setPaginationCompetitors(paginationCompetitors + 1);
        }

        setTime(n);
    };

    // console.log("companies: ", companies);
    // useEffect(() => {
    //     // eslint-disable-next-line
    // }, [companies]);

    const sortTableBy = (metric) => {
        if (window.location.pathname === "/watchlist") {
            let oldSortMetric = screenerCombinedForm.sortWatchlistBy.value;
            if (oldSortMetric !== metric) {
                screenerCombinedForm.sortWatchlistBy.value = metric;
                screenerCombinedForm.sortWatchlistBy.direction = "DESC";
            } else {
                if (screenerCombinedForm.sortWatchlistBy.direction === "DESC") {
                    screenerCombinedForm.sortWatchlistBy.direction = "ASC";
                } else {
                    screenerCombinedForm.sortWatchlistBy.direction = "DESC";
                }
            }
            updateSingleOptionSortWatchlistBy(
                screenerCombinedForm.sortWatchlistBy
            );
            getWatchlist(
                watchlist.current,
                user.id,
                screenerCombinedForm.sortWatchlistBy.value,
                screenerCombinedForm.sortWatchlistBy.direction
            );
        } else {
            let oldSortMetric = screenerCombinedForm.sortBy.value;
            if (oldSortMetric !== metric) {
                screenerCombinedForm.sortBy.value = metric;
                screenerCombinedForm.sortBy.direction = "DESC";
            } else {
                if (screenerCombinedForm.sortBy.direction === "DESC") {
                    screenerCombinedForm.sortBy.direction = "ASC";
                } else {
                    screenerCombinedForm.sortBy.direction = "DESC";
                }
            }
            updateSingleOptionSortBy(screenerCombinedForm.sortBy);
            if (
                window.location.pathname === "/screener" ||
                company.industry === null
            ) {
                getFilteredCompanies(
                    slider,
                    options,
                    0,
                    screener.threshold,
                    screener.checked
                );
            } else if (window.location.pathname === "/company") {
                let pagination_competitors = 0;
                setPaginationCompetitors(0);
                getCompetitors(
                    `?${competitorsType}=${encodeURIComponent(competitorsUrl)}`,
                    screenerCombinedForm.sortBy.value,
                    screenerCombinedForm.sortBy.direction,
                    pagination_competitors
                );
            }
        }
    };

    return (
        <div>
            {companies === null ? (
                <Preloader />
            ) : (
                <div
                    className='overflow_x_auto sticky_table max_table_height background_color_2'
                    onScroll={(event) =>
                        window.location.pathname === "/screener"
                            ? event.target.scrollTop /
                                  event.target.scrollHeight >
                                  0.6 && companies.length % 50 == 0
                                ? handlePaginationRequest(event)
                                : null
                            : window.location.pathname === "/company"
                            ? event.target.scrollTop /
                                  event.target.scrollHeight >
                                  0.6 &&
                              company.competitors &&
                              company.competitors.length % 50 == 0
                                ? handleCompetitorsPaginationRequest(event)
                                : null
                            : null
                    }
                >
                    <table>
                        {/* Problemtofix: Mobile - Ticker Symbol, Desktop - Company Name */}
                        <thead className='sticky_table_thead background_color_2'>
                            <tr className='sticky_table_thead_tr background_color_2 text_2 text_color_1'>
                                {/* <th className='text_right min_width_80px vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.actions",
                                    })}
                                </th> */}
                                <th className='padding_15_5px min_width_200px background_color_2 vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.company",
                                    })}
                                </th>
                                {tableValues.metrics.map((metric) =>
                                    industries_display.includes(metric) ? (
                                        <CompaniesTableHead
                                            metric={metric}
                                            options={options}
                                            slider={slider}
                                            checked={screener.checked}
                                            threshold={screener.threshold}
                                            industry={company.industry}
                                            competitorsUrl={competitorsUrl}
                                            competitorsType={competitorsType}
                                            watchlist={watchlist.current}
                                            user={user.id}
                                        />
                                    ) : null
                                )}
                                {tableValues.metrics.map((metric) =>
                                    !industries_display.includes(metric) ? (
                                        <CompaniesTableHead
                                            metric={metric}
                                            options={options}
                                            slider={slider}
                                            checked={screener.checked}
                                            threshold={screener.threshold}
                                            industry={company.industry}
                                            competitorsUrl={competitorsUrl}
                                            competitorsType={competitorsType}
                                            watchlist={watchlist.current}
                                            user={user.id}
                                            industries_display
                                        />
                                    ) : null
                                )}
                                {window.location.pathname === "/screener" &&
                                screener.checked > 0 &&
                                localStorage.latest_screener.includes(
                                    "threshold"
                                ) ? (
                                    options.sortBy.value === "score" ? (
                                        <th
                                            className='padding_15_5px flex right text_color_blue text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("score");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "general.threshold",
                                            })}
                                            {options.sortBy.direction ===
                                            "ASC" ? (
                                                <IoIcons5.IoArrowUpOutline className='text_1 ml_5px text_color_blue' />
                                            ) : (
                                                <IoIcons5.IoArrowDownOutline className='text_1 ml_5px text_color_blue' />
                                            )}
                                        </th>
                                    ) : (
                                        <th
                                            className='padding_15_5px text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("score");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "general.threshold",
                                            })}
                                        </th>
                                    )
                                ) : null}
                                {window.location.pathname === "/watchlist" ? (
                                    options.sortWatchlistBy.value ===
                                    "daily_return" ? (
                                        <th
                                            className='padding_15_5px flex right text_color_blue text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("daily_return");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "metrics.daily_return",
                                            })}
                                            {options.sortWatchlistBy
                                                .direction === "ASC" ? (
                                                <IoIcons5.IoArrowUpOutline className='text_1 ml_5px text_color_blue' />
                                            ) : (
                                                <IoIcons5.IoArrowDownOutline className='text_1 ml_5px text_color_blue' />
                                            )}
                                        </th>
                                    ) : (
                                        <th
                                            className='padding_15_5px text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("daily_return");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "metrics.daily_return",
                                            })}
                                        </th>
                                    )
                                ) : null}
                                {window.location.pathname === "/watchlist" ? (
                                    options.sortWatchlistBy.value ===
                                    "market_cap_usd" ? (
                                        <th
                                            className='padding_15_5px flex right text_color_blue text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("market_cap_usd");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "metrics.market_cap",
                                            })}
                                            {options.sortWatchlistBy
                                                .direction === "ASC" ? (
                                                <IoIcons5.IoArrowUpOutline className='text_1 ml_5px text_color_blue' />
                                            ) : (
                                                <IoIcons5.IoArrowDownOutline className='text_1 ml_5px text_color_blue' />
                                            )}
                                        </th>
                                    ) : (
                                        <th
                                            className='padding_15_5px text_right min_width_120px cursor_pointer vertical_align_top'
                                            onClick={() => {
                                                sortTableBy("market_cap_usd");
                                            }}
                                        >
                                            {useintl.formatMessage({
                                                id: "metrics.market_cap",
                                            })}
                                        </th>
                                    )
                                ) : options.sortBy.value ===
                                  "market_cap_usd" ? (
                                    <th
                                        className='padding_15_5px flex right text_color_blue text_right min_width_120px cursor_pointer vertical_align_top'
                                        onClick={() => {
                                            sortTableBy("market_cap_usd");
                                        }}
                                    >
                                        {useintl.formatMessage({
                                            id: "metrics.market_cap",
                                        })}
                                        {options.sortBy.direction === "ASC" ? (
                                            <IoIcons5.IoArrowUpOutline className='text_1 ml_5px text_color_blue' />
                                        ) : (
                                            <IoIcons5.IoArrowDownOutline className='text_1 ml_5px text_color_blue' />
                                        )}
                                    </th>
                                ) : (
                                    <th
                                        className='padding_15_5px text_right min_width_120px cursor_pointer vertical_align_top'
                                        onClick={() => {
                                            sortTableBy("market_cap_usd");
                                        }}
                                    >
                                        {useintl.formatMessage({
                                            id: "metrics.market_cap",
                                        })}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {companies.map((company) => (
                                <CompaniesTableRow
                                    company={company}
                                    metrics={tableValues.metrics}
                                    key={company.ticker}
                                    checked={screener.checked}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

CompaniesTable.propTypes = {
    companies: PropTypes.array,
    company: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    tableStructure: PropTypes.string.isRequired,
    screenerPagination: PropTypes.number.isRequired,
    getFilteredCompanies: PropTypes.func.isRequired,
    updateSingleOptionSortBy: PropTypes.func.isRequired,
    updateSingleOptionSortWatchlistBy: PropTypes.func.isRequired,
    getCompetitors: PropTypes.func.isRequired,
    getWatchlist: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    company: state.company,
    watchlist: state.watchlist,
    user: state.user,
});

export default connect(mapStateToProps, {
    getFilteredCompanies,
    updateSingleOptionSortBy,
    updateSingleOptionSortWatchlistBy,
    getCompetitors,
    getWatchlist,
})(CompaniesTable);
