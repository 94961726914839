import React, { useEffect } from "react";
import styled from "styled-components";

import Bookmarks from "../../layout/bookmarks/Bookmarks";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getCompaniesCompareUrl } from "../../../actions/compareActions";

const Compare = ({ sidebar: { sidebar }, getCompaniesCompareUrl }) => {
    useEffect(() => {
        // eslint-disable-next-line
        window.location.search
            ? getCompaniesCompareUrl(window.location.search)
            : null;
    }, []);

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Bookmarks />
        </div>
    );
};

Compare.propTypes = {
    sidebar: PropTypes.object.isRequired,
    getCompaniesCompareUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
});

export default connect(mapStateToProps, {
    getCompaniesCompareUrl,
})(Compare);
