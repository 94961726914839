import React from "react";
import { useIntl } from "react-intl";
// import styled from "styled-components";
import * as IoIcons5 from "react-icons/io5";
import { FormattedMessage } from "react-intl";

// Components
import CreatorStrategy from "./creator/CreatorStrategy";
import CreatorDiversification from "./creator/CreatorDiversification";
import CreatorAssessment from "./creator/CreatorAssessment";
import CreatorExperience from "./creator/CreatorExperience";

import FeedbackIcon from "../elements/FeedbackIcon";

// Actions
import {
    setCreatorWindow,
    getCurrentCreatorPortfolio,
    clearOldCreatorPortfolio,
} from "../../../actions/portfolioActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PortfolioCreatorSurvey = ({
    portfolio,
    setCreatorWindow,
    getCurrentCreatorPortfolio,
    clearOldCreatorPortfolio,
}) => {
    const useintl = useIntl();

    const [underLine, setUnderLine] = React.useState(1);

    const [shown, setShown] = React.useState({
        one: true,
        two: portfolio.current_creator_companies !== null ? true : false,
        three: portfolio.current_creator_companies !== null ? true : false,
        four: portfolio.current_creator_companies !== null ? true : false,
    });

    const [creatorTab, setCreatorTab] = React.useState(
        <CreatorDiversification />
    );

    const handleCreatePortfolio = () => {
        clearOldCreatorPortfolio();
        getCurrentCreatorPortfolio(portfolio.in_creation_preferences);
    };

    let survey_navigation = {
        1: {
            id: 1,
            tab: <CreatorDiversification />,
        },
        2: {
            id: 2,
            tab: <CreatorAssessment />,
        },
        3: {
            id: 3,
            tab: <CreatorExperience />,
        },
        4: {
            id: 4,
            tab: <CreatorStrategy />,
        },
    };

    return (
        <div className='mb_25px background_color_1 height_100pr'>
            <div className='ms_auto'>
                <div className='flex pt_5px overflow_x_auto scrollbar_invisible border_bottom_1px_solid_grey title_7 text_color_1'>
                    <div className='flex text_color_1 width_auto pl_30px'>
                        <div
                            className={
                                underLine === 1
                                    ? "pb_5px page_menu_link transition_350ms cursor_default text_color_blue border_bottom_1px_solid_blue"
                                    : "pb_5px page_menu_link transition_350ms cursor_pointer text_color_1"
                            }
                            onClick={() => {
                                setUnderLine(1);
                                setCreatorTab(<CreatorDiversification />);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "general.diversification",
                            })}
                        </div>
                        <IoIcons5.IoChevronForwardSharp className='title_4 pb_5px ml_30px mr_30px' />
                    </div>
                    {shown.two ? (
                        <div className='flex text_color_1 width_auto'>
                            <div
                                className={
                                    underLine === 2
                                        ? "pb_5px page_menu_link transition_350ms cursor_default text_color_blue border_bottom_1px_solid_blue"
                                        : "pb_5px page_menu_link transition_350ms cursor_pointer text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(2);
                                    setCreatorTab(<CreatorAssessment />);
                                }}
                            >
                                {useintl.formatMessage({
                                    id: "general.assessment",
                                })}
                            </div>
                            <IoIcons5.IoChevronForwardSharp className='title_4 pb_5px ml_30px mr_30px' />
                        </div>
                    ) : null}
                    {shown.three ? (
                        <div className='flex text_color_1'>
                            <div
                                className={
                                    underLine === 3
                                        ? "pb_5px page_menu_link transition_350ms cursor_default text_color_blue border_bottom_1px_solid_blue"
                                        : "pb_5px page_menu_link transition_350ms cursor_pointer text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(3);
                                    setCreatorTab(<CreatorExperience />);
                                }}
                            >
                                {useintl.formatMessage({
                                    id: "general.risk",
                                })}
                            </div>
                            <IoIcons5.IoChevronForwardSharp className='title_4 pb_5px ml_30px mr_30px' />
                        </div>
                    ) : null}
                    {shown.four ? (
                        <div className='flex text_color_1 pr_10px'>
                            <div
                                className={
                                    underLine === 4
                                        ? "pb_5px page_menu_link transition_350ms cursor_default text_color_blue border_bottom_1px_solid_blue"
                                        : "pb_5px page_menu_link transition_350ms cursor_pointer text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(4);
                                    setCreatorTab(<CreatorStrategy />);
                                }}
                            >
                                {useintl.formatMessage({
                                    id: "general.strategy",
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='width_100pr height_40px'>{""}</div>
            </div>

            <div className='ms_auto mt_20px width_90pr min_width_320px max_width_800px background_color_2 text_color_1 shadow_dark border_radius_15px'>
                {creatorTab}

                <div className='height_70px'>
                    <div className='divider_no_margin'></div>
                    <div className='flex space_between mt_20px'>
                        {underLine !== 1 ? (
                            <div
                                className='button_blue pl_15px pr_15px ml_25px'
                                onClick={() => {
                                    setUnderLine(underLine - 1);
                                    setCreatorTab(
                                        survey_navigation[underLine - 1].tab
                                    );
                                }}
                            >
                                <FormattedMessage
                                    id='general.back'
                                    defaultMessage='Back'
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {underLine !== 4 ? (
                            <div
                                className='button_blue pl_15px pr_15px mr_25px'
                                onClick={() => {
                                    if (underLine === 1) {
                                        setShown({ ...shown, two: true });
                                    }
                                    if (underLine === 2) {
                                        setShown({ ...shown, three: true });
                                    }
                                    if (underLine === 3) {
                                        setShown({ ...shown, four: true });
                                    }
                                    setUnderLine(underLine + 1);
                                    setCreatorTab(
                                        survey_navigation[underLine + 1].tab
                                    );
                                }}
                            >
                                <FormattedMessage
                                    id='general.next'
                                    defaultMessage='Next'
                                />
                            </div>
                        ) : (
                            <div
                                className='button_blue pl_15px pr_15px mr_25px'
                                onClick={() => {
                                    handleCreatePortfolio();
                                    setCreatorWindow(2);
                                }}
                            >
                                <FormattedMessage
                                    id='general.create'
                                    defaultMessage='Create'
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioCreatorSurvey.propTypes = {
    portfolio: PropTypes.object.isRequired,
    setCreatorWindow: PropTypes.func.isRequired,
    getCurrentCreatorPortfolio: PropTypes.func.isRequired,
    clearOldCreatorPortfolio: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, {
    setCreatorWindow,
    getCurrentCreatorPortfolio,
    clearOldCreatorPortfolio,
})(PortfolioCreatorSurvey);
