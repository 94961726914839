import React from "react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import * as AiIcons from "react-icons/ai";

import SearchBarResult from "./SearchBarResult";

import { getSearchBarCompanies } from "../../../actions/screenerAction";
import { setCompanyTicker } from "../../../actions/companyActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SearchBar = ({
    screener: { searchbar_companies },
    getSearchBarCompanies,
    setCompanyTicker,
}) => {
    const useintl = useIntl();
    const ref = useRef();

    const [showCompanies, setShowCompanies] = React.useState(false);
    const handleShowCompanies = () => {
        setShowCompanies(true);
    };
    const handleHideCompanies = () => {
        setShowCompanies(false);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                showCompanies &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                setShowCompanies(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showCompanies]);

    const [searchBarValue, setSearchBarValue] = React.useState("");
    const handleSearchBarValue = (event) => {
        let str = event.target.value.replace(/[^a-zA-Z0-9. ]/g, "");
        setSearchBarValue(str);
        if (str.length >= 1) {
            getSearchBarCompanies(str);
        } else {
            getSearchBarCompanies(null);
        }
    };
    const clearSearchBarValue = () => {
        setSearchBarValue("");
        document.getElementById("topbar_searchbar_input_field").reset();
        getSearchBarCompanies(null);
    };

    return (
        <div
            className={
                searchbar_companies !== null &&
                searchbar_companies.length > 0 &&
                showCompanies
                    ? "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark mt_29px"
                    : "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark"
            }
            ref={ref}
        >
            <div
                className={
                    searchbar_companies !== null &&
                    searchbar_companies.length > 0 &&
                    showCompanies
                        ? "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px_top height_30px"
                        : "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px height_30px"
                }
            >
                <div className='mr_5px text_2 color_white height_100pr width_5pr'>
                    <AiIcons.AiOutlineSearch />
                </div>
                <form id='topbar_searchbar_input_field' className='width_95pr'>
                    <input
                        type='text'
                        className='input_text text_2 ml_5px width_100pr'
                        aria-label='search_company'
                        placeholder={
                            searchBarValue !== ""
                                ? searchBarValue
                                : useintl.formatMessage({
                                      id: "general.search",
                                  }) + "..."
                        }
                        onKeyDown={(event) => {
                            if (event.keyCode == 13) {
                                event.preventDefault();
                            }
                        }}
                        onKeyUp={(event) => {
                            handleSearchBarValue(event);
                        }}
                        onClick={() =>
                            setShowCompanies((oldState) => !oldState)
                        }
                    />
                </form>
            </div>
            {searchbar_companies !== null &&
            searchbar_companies.length > 0 &&
            showCompanies ? (
                <div className='flex flex_column align_items_start width_100pr min_width_250px height_100pr'>
                    {searchbar_companies.map((company) => (
                        <Link
                            to={`/company?ticker=${company.ticker}`}
                            onClick={() => {
                                handleHideCompanies();
                                setCompanyTicker(company.ticker);
                                clearSearchBarValue();
                            }}
                            className='width_100pr min_width_250px'
                        >
                            <SearchBarResult search_bar_company={company} />
                        </Link>
                    ))}
                </div>
            ) : // <div className='flex flex_column align_items_start width_100pr min_width_250px'></div>
            null}
        </div>
    );
};

SearchBar.propTypes = {
    // searchbar_companies: PropTypes.object.isRequired,
    setCompanyTicker: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    screener: state.screener,
});

export default connect(mapStateToProps, {
    getSearchBarCompanies,
    setCompanyTicker,
})(SearchBar);
