import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";

// COMPONENTS
import SliderInfoBox from "./sliderinfo/SliderInfoBox";
import MetricHistogramSlider from "../elements/MetricHistogramSlider";
import RangeSliderScreenerMinPopover from "./RangeSliderScreenerMinPopover";
import RangeSliderScreenerMaxPopover from "./RangeSliderScreenerMaxPopover";

// MUI
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
} from "../../../actions/screenerAction";
import { numberFormatterfour } from "../../../actions/generalActions";

const RangeSliderScreener = ({
    sliderprops,
    category,
    type,
    detectCheck,
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
}) => {
    const useintl = useIntl();
    const [check, setCheck] = React.useState(
        // slider[sliderprops.property].filter
        sliderprops.filter
    );

    const [importance, setImportance] = useState(sliderprops.importance);

    useEffect(() => {
        // setCheck(slider[sliderprops.property].filter);
        setCheck(sliderprops.filter);
        setImportance(sliderprops.importance);
        // eslint-disable-next-line
    }, [sliderprops]);

    const handleCheck = (event, newValue) => {
        if (check && importance === 1) {
            setImportance(2);
            sliderprops.importance = 2;
            updateSingleSliderValue(sliderprops, "filter");
        } else if (check && importance === 2) {
            setImportance(0);
            setCheck(false);
            sliderprops.filter = false;
            sliderprops.display = false;
            sliderprops.importance = 0;
            updateSingleSliderValue(sliderprops, "filter");
            detectCheck(category, type, false);
        } else {
            setCheck(true);
            setImportance(1);
            sliderprops.filter = true;
            sliderprops.display = true;
            sliderprops.importance = 1;
            updateSingleSliderValue(sliderprops, "filter");
            detectCheck(category, type, true);
        }
    };
    const [value, setValue] = React.useState(sliderprops.value);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        sliderprops.value = newValue;
    };

    const [minValue, setMinValue] = React.useState(
        sliderprops.property.includes("ranker") &&
            !sliderprops.property.includes("change")
            ? Number(value[0]).toPrecision(3)
            : value[0] === sliderprops.min_max[0]
            ? null
            : sliderprops.property === "market_cap" ||
              sliderprops.property === "market_cap_usd" ||
              sliderprops.property === "ev"
            ? Number(value[0]).toPrecision(3)
            : Number(value[0]).toPrecision(3)
    );
    const [maxValue, setMaxValue] = React.useState(
        sliderprops.property.includes("ranker") &&
            !sliderprops.property.includes("change")
            ? Number(value[1]).toPrecision(3)
            : value[1] === sliderprops.min_max[1]
            ? null
            : sliderprops.property === "market_cap" ||
              sliderprops.property === "market_cap_usd" ||
              sliderprops.property === "ev"
            ? Number(value[1]).toPrecision(3)
            : Number(value[1]).toPrecision(3)
    );

    useEffect(() => {
        // setMinValue(Number(value[0]).toPrecision(3));
        // setMaxValue(Number(value[1]).toPrecision(3));
        // eslint-disable-next-line
    }, [minValue, maxValue, value]);

    /*Sliders Information Modal*/
    const [openInfo, setOpenInfo] = React.useState(false);
    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };
    const handleCloseInfo = () => {
        setOpenInfo(false);
    };
    /*Sliders Histogram Modal*/
    const [openHistogram, setOpenHistogram] = React.useState(false);
    const handleClickOpenHistogram = () => {
        setOpenHistogram(true);
    };
    const handleCloseHistogram = () => {
        // setValue(slider[sliderprops.property].value);
        setValue(sliderprops.value);
        setOpenHistogram(false);
    };

    // property: 'revenue_growth_5y',
    // value: [20, 30],
    // default: [20, 30],
    // statistics: [15, 35, 55],
    // min_max: [0, 1]
    // filter: false,
    // display: true,
    // displayName: 'Revenue Growth (5years) [%]',
    // axis: 'log',
    // : value === sliderprops.min_max[0] ||
    //     value === sliderprops.min_max[1] ? (
    //     <div className='flex text_color_1'>
    //         <p className='pb_3px mr_3px'></p>
    //         <IoIcons5.IoInfiniteOutline />
    //         <div className='pb_2px ml_3px'>
    //             {sliderprops.unit}
    //         </div>
    //     </div>
    // )

    /* Sliders Tooltip Styling */
    // function ValueLabelComponent(props) {
    //     const { children, open, value } = props;

    //     const popperRef = React.useRef(null);
    //     React.useEffect(() => {
    //         if (popperRef.current) {
    //             popperRef.current.update();
    //         }
    //     });

    //     return (
    //         <Tooltip
    //             PopperProps={{
    //                 popperRef,
    //             }}
    //             open={open}
    //             enterTouchDelay={0}
    //             placement='top'
    //             title={
    //                 sliderprops.property.includes("ranker") &&
    //                 !sliderprops.property.includes("change")
    //                     ? Number(value).toPrecision(3) * 100
    //                     : sliderprops.property === "market_cap" ||
    //                       sliderprops.property === "market_cap_usd" ||
    //                       sliderprops.property === "ev"
    //                     ? numberFormatterfour(Number(value).toPrecision(3)) +
    //                       `${sliderprops.unit}`
    //                     : Number(value).toPrecision(3) + `${sliderprops.unit}`
    //             }
    //         >
    //             {children}
    //         </Tooltip>
    //     );
    // }
    // ValueLabelComponent.propTypes = {
    //     children: PropTypes.element.isRequired,
    //     open: PropTypes.bool.isRequired,
    //     value: PropTypes.number.isRequired,
    // };

    const handleExtremeRange = () => {
        let number = Number(value);
        if (number > 100) {
            return 100;
        }
        if (number < 0) {
            return 0;
        }
        return number;
    };

    const handleExtremeRanker = (value) => {
        let number = Number(value);
        if (number > 100) {
            return 100;
        }
        if (number < 0) {
            return 0;
        }
        return number;
    };

    return (
        <div
            className={
                check
                    ? "screener_slider_grid accent_color_3 text_color_1 border_3px_solid"
                    : "screener_slider_grid background_color_3 text_color_1 border_3px_solid"
            }
            key={sliderprops.property}
        >
            <div className='screener_slider_checkbox'>
                <div className='pos_relative'>
                    <form action='#'>
                        <label>
                            <Checkbox
                                id={sliderprops.property}
                                name={sliderprops.property}
                                checked={check}
                                color={
                                    importance === 2 ? "secondary" : "primary"
                                }
                                onChange={handleCheck}
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                        </label>
                    </form>
                    {importance === 2 ? (
                        <MdIcons.MdLock className='pos_absolute mt_-20px ml_20px' />
                    ) : null}
                </div>
            </div>
            <div id='range-slider' className='screener_slider_label text_3'>
                <p className='text_4'>{sliderprops.displayName}</p>
            </div>
            <div className='screener_slider_range_grid'>
                <RangeSliderScreenerMinPopover
                    className='screener_min_value'
                    sliderprops={sliderprops}
                    value={value}
                    setValue={setValue}
                    minValue={minValue}
                    setMinValue={setMinValue}
                    setMaxValue={setMaxValue}
                />
                <Slider
                    step={
                        (sliderprops.min_max[1] - sliderprops.min_max[0]) / 50
                    }
                    // components={{
                    //     ValueLabel: ValueLabelComponent,
                    // }}
                    sx={{
                        "& .MuiSlider-thumb": {
                            borderRadius: "1px",
                            width: "3px",
                            height: "10px",
                        },
                    }}
                    id={sliderprops.property}
                    name={sliderprops.property}
                    className='screener_selector'
                    min={sliderprops.min_max[0]}
                    max={sliderprops.min_max[1]}
                    value={[minValue, maxValue]}
                    disabled={true}
                    disabledColor='primary'
                    onChange={handleChange}
                    size='small'
                    color='primary'
                    valueLabelDisplay='auto'
                    aria-labelledby='range-slider'
                />
                <div className='screener_placeholer'></div>
                <RangeSliderScreenerMaxPopover
                    className='screener_max_value'
                    sliderprops={sliderprops}
                    value={value}
                    setValue={setValue}
                    maxValue={maxValue}
                    setMinValue={setMinValue}
                    setMaxValue={setMaxValue}
                />
            </div>
            <div className='screener_icon_large text_2'>
                <AiIcons.AiOutlineFullscreen
                    className='screener_icon_widen cursor_pointer'
                    onClick={handleClickOpenHistogram}
                />
                <IoIcons.IoIosRefresh
                    className='screener_icon_reset cursor_pointer'
                    onClick={() => {
                        setValue(sliderprops.default_value);
                        setCheck(false);
                        // clearSingleSliderValue(slider[sliderprops.property]);
                        clearSingleSliderValue(sliderprops);
                    }}
                />
                {sliderprops.info ? (
                    <AiIcons.AiOutlineInfoCircle
                        className='screener_icon_info cursor_pointer'
                        onClick={handleClickOpenInfo}
                    />
                ) : null}
            </div>

            {/* EXPLANATION */}
            <Dialog
                open={openInfo}
                onClose={handleCloseInfo}
                maxWidth={false}
                className='slider_info_wrapper'
            >
                <div className='border_box text_color_1 background_color_4'>
                    <div className='slider_info_header title_5'>
                        <div className='flex'>
                            <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px mt_5px' />
                            {sliderprops.displayName}
                        </div>
                        <AiIcons.AiOutlineClose
                            className='title_5 cursor_pointer'
                            onClick={handleCloseInfo}
                        />
                    </div>

                    <SliderInfoBox sliderprops={sliderprops} />

                    <div className='divider_no_margin'></div>
                    <div className='slider_info_disclaimer margin_10px'>
                        <p>
                            To get an <b>overview</b> on typical values for this
                            metric, click{" "}
                            <b
                                className='cursor_pointer'
                                onClick={() => {
                                    handleCloseInfo();
                                    handleClickOpenHistogram();
                                }}
                            >
                                here
                            </b>
                        </p>
                    </div>
                </div>
            </Dialog>

            {/* HISTOGRAM */}
            <Dialog
                open={openHistogram}
                onClose={handleCloseHistogram}
                maxWidth={false}
                className='slider_info_wrapper'
            >
                <div className='border_box text_color_1 background_color_4'>
                    <div className='slider_info_header title_5'>
                        <div className='flex'>
                            <RiIcons.RiBarChartBoxLine className='title_5 mr_10px mt_5px' />
                            {sliderprops.displayName}
                        </div>
                        <AiIcons.AiOutlineClose
                            className='title_5 cursor_pointer'
                            onClick={handleCloseHistogram}
                        />
                    </div>
                    <MetricHistogramSlider
                        sliderprops={sliderprops}
                        minValue={minValue}
                        maxValue={maxValue}
                        setMinValue={setMinValue}
                        setMaxValue={setMaxValue}
                    />
                </div>
            </Dialog>
        </div>
    );
};

RangeSliderScreener.propTypes = {
    updateSingleSliderValue: PropTypes.func.isRequired,
    updateSingleSliderValueOnly: PropTypes.func.isRequired,
    clearSingleSliderValue: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//     screener: state.screener,
// });

export default connect(null, {
    updateSingleSliderValue,
    updateSingleSliderValueOnly,
    clearSingleSliderValue,
})(RangeSliderScreener);
