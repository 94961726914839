import React, { useContext } from "react";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import MetricAnalysisBar from "../figures/MetricAnalysisBar";
import MetricAnalysisBarUpgrade from "../figures/MetricAnalysisBarUpgrade";
import FeedbackIcon from "../../elements/FeedbackIcon";

// Intl
import { useIntl } from "react-intl";

// Context
import { MetricsContext } from "../../../../context/MetricsContext";

const PortfolioAnalysisBars = ({
    company_list,
    company_allocation,
    screener: { slider },
    user,
}) => {
    const useintl = useIntl();

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = user.simplified_view
        ? metrics.simplified_sorted_metrics_object
        : metrics.sorted_metrics_object;

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.portfolio",
        })} - ${useintl.formatMessage({
            id: "portfolio.manager.analysis",
        })}`,
        location_index: 0,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //         id: "general.portfolio",
    //     })} - ${useintl.formatMessage({
    //         id: "portfolio.manager.analysis",
    //     })}`,
    //     location_index: 0,
    // };

    return (
        <div className='background_color_1 text_color_1'>
            <FeedbackIcon feedbackInfo={feedbackInfo} />
            <div className='mt_30px mb_30px'>
                <div className=' flex wrap mt_25px pl_20px width_100pr min_width_300px max_width_1500px'>
                    <div className='flex space_between width_100pr min_width_300px text_2'>
                        {/* NAME */}
                        <div className='width_30pr_50pr min_width_150px'>
                            {useintl.formatMessage({
                                id: `general.name`,
                            })}
                        </div>
                        {/* VALUE */}
                        <div className='width_50pr_30pr min_width_50px'>
                            {useintl.formatMessage({
                                id: `general.weighted_avg_value`,
                            })}
                        </div>{" "}
                        {/* INFO ICONS */}
                        <div className='width_20pr min_width_50px flex'></div>
                        {/* RANKER */}
                        {/* <div className='width_10pr min_width_50px'>
                            {useintl.formatMessage({
                                id: `general.ranker`,
                            })}
                        </div> */}
                    </div>
                    {/* <div className='width_100pr min_width_300px text_2'>
                        Ranker
                    </div> */}
                </div>
                <div className='mt_15px'>
                    {Object.keys(sorted_metrics_object).map((category) => (
                        <div className='width_100pr pl_20px pr_20px'>
                            <div className='width_100pr height_15px mb_15px text_2'>
                                {useintl.formatMessage({
                                    id: `metrics.${category}`,
                                })}
                            </div>

                            {sorted_metrics_object[category].map((metric) => {
                                return AllowedMetrics.includes(metric) ? (
                                    <MetricAnalysisBar
                                        sliderprops={searchSliderObject[metric]}
                                        // company={company}
                                        // metric={company[metric]}
                                        // metric_ranker={company[`${metric}_ranker`]}
                                        company_list={company_list}
                                        company_allocation={company_allocation}
                                    />
                                ) : (
                                    <MetricAnalysisBarUpgrade
                                        sliderprops={searchSliderObject[metric]}
                                        dummy_value={0}
                                    />
                                );
                            })}

                            <div className='mb_20px'> </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

PortfolioAnalysisBars.propTypes = {
    sliderprops: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    company_list: PropTypes.array.isRequired,
    company_allocation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    user: state.user,
});

export default connect(mapStateToProps, {})(PortfolioAnalysisBars);
