import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";

// MUI
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    setCurrentPortfolio,
    renamePortfolio,
    deletePortfolio,
} from "../../../actions/portfolioActions";
import { setAlert } from "../../../actions/alertActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const ChangePortfolio = ({
    closeModal,
    currentPortfolio,
    portfolio,
    user,
    setCurrentPortfolio,
    renamePortfolio,
    deletePortfolio,
}) => {
    const useintl = useIntl();
    useEffect(() => {
        setChangePortfolio({
            id:
                currentPortfolio !== null && currentPortfolio.id
                    ? currentPortfolio.id
                    : null,
            name:
                currentPortfolio !== null && currentPortfolio.name
                    ? currentPortfolio.name
                    : null,
            icon:
                currentPortfolio !== null && currentPortfolio.icon
                    ? currentPortfolio.icon
                    : null,
            description:
                currentPortfolio !== null && currentPortfolio.description
                    ? currentPortfolio.description
                    : "",
            new_public:
                currentPortfolio !== null && currentPortfolio.public
                    ? currentPortfolio.public
                    : null,
        });
        // eslint-disable-next-line
    }, [portfolio.current, portfolio.rerender]);

    // STATE WITH INPUTS
    const [changePortfolio, setChangePortfolio] = React.useState({
        id: null,
        name: "",
        icon: "collection",
        description: "",
        new_public: null,
    });

    // ALERT SNACKBAR
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleClickAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    // ICONS
    const [openPortfolioIcon, setOpenPortfolioIcon] = React.useState(false);
    const handleClickOpenPortfolioIcon = () => {
        setOpenPortfolioIcon(true);
    };
    const handleClosePortfolioIcon = () => {
        setOpenPortfolioIcon(false);
    };

    // INPUT FIELDS
    const handleChangePortfolioName = (event) => {
        setChangePortfolio({ ...changePortfolio, name: event.target.value });
    };
    const handleChangePortfolioIcon = (icon) => {
        setChangePortfolio({ ...changePortfolio, icon: icon });
    };
    const handleChangePortfolioPublic = (new_public) => {
        setChangePortfolio({
            ...changePortfolio,
            new_public: new_public,
        });
    };
    const handleChangePortfolioDescription = (event) => {
        setChangePortfolio({
            ...changePortfolio,
            description: event.target.value,
        });
    };

    // SUBMIT CHANGES
    const handleChangePortfolio = () => {
        let data = {
            id: changePortfolio.id,
            name: changePortfolio.name,
            icon: changePortfolio.icon,
            description: changePortfolio.description,
            public: changePortfolio.new_public,
            user: user.id,
        };
        if (changePortfolio.name !== "") {
            renamePortfolio(data, portfolio);
            closeModal();
        } else {
            handleClickAlert();
        }
    };

    // DELETE PORTFOLIO
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const history = useHistory();
    const routeRedirect = (path) => {
        history.push(path);
    };
    const handleDeletePortfolio = () => {
        let data = {
            id:
                currentPortfolio !== null && currentPortfolio.id
                    ? currentPortfolio.id
                    : null,
            user: user.id,
        };
        deletePortfolio(data, portfolio);
        routeRedirect(`/portfolio`);
        if (portfolio.portfolios.length > 0) {
            setCurrentPortfolio(portfolio.portfolios[0].id);
        } else {
            setCurrentPortfolio(null);
        }
        handleCloseDelete();
    };

    return (
        <div>
            <div className='background_color_2 text_color_1'>
                {/* HEADER */}
                <div className='flex space_between title_4 padding_15px'>
                    <div className='mr_10px'>
                        {useintl.formatMessage({
                            id: "portfolio.changes_to",
                        })}
                        {" - "}'{currentPortfolio.name}'
                    </div>
                    <div
                        className='cursor_pointer title_4 flex center ml_25px mt_5px'
                        onClick={closeModal}
                    >
                        <AiIcons.AiOutlineClose />
                    </div>
                </div>
                <div className='divider_no_margin'></div>
                {/* ICON AND NAME */}
                <div className='pl_15px pr_15px mt_20px mb_20px'>
                    <div className='text_2'>
                        {useintl.formatMessage({
                            id: "portfolio.icon_and_name",
                        })}
                    </div>
                    <div className='flex'>
                        <div
                            className='width_15pr mt_3px flex left cursor_pointer hover_opacity_80'
                            onClick={handleClickOpenPortfolioIcon}
                        >
                            <div className='width_20px text_1'>
                                {icon_storage.map((icon) =>
                                    icon.name === changePortfolio.icon
                                        ? icon.icon
                                        : null
                                )}
                            </div>
                            <div className='width_30px text_1'>
                                <RiIcons.RiArrowDownSFill />
                            </div>
                        </div>
                        <div className='input_basic_border border_radius_5px width_85pr padding_5px height_100pr'>
                            <input
                                type='text'
                                defaultValue={
                                    changePortfolio.name !== null
                                        ? changePortfolio.name
                                        : ""
                                }
                                onKeyUp={handleChangePortfolioName}
                                className='text_color_1 text_2'
                                placeholder='Name...'
                                maxLength='20'
                            />
                        </div>
                    </div>
                </div>
                {/* DESCRIPTION */}
                <div className='pl_15px pr_15px mt_20px mb_20px'>
                    <div className='text_2'>
                        {useintl.formatMessage({
                            id: "general.description",
                        })}
                    </div>
                    <div>
                        <textarea
                            className='text_2 width_100pr text_color_1 height_120px padding_5px resize_none border_radius_5px input_basic_border'
                            name='text'
                            rows='8'
                            maxLength='500'
                            placeholder='Description...'
                            defaultValue={
                                changePortfolio.description !== null
                                    ? changePortfolio.description
                                    : null
                            }
                            onKeyUp={handleChangePortfolioDescription}
                        />
                    </div>
                </div>
                {/* PUBLIC */}
                <div className='pl_15px pr_15px mt_20px mb_20px'>
                    <div className='text_2'>
                        {useintl.formatMessage({
                            id: "general.public",
                        })}
                    </div>
                    <div className='width_220px_100pr height_35px accent_color_2 text_3 text_color_1 pl_10px pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                        <div
                            className={
                                changePortfolio.new_public
                                    ? "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                    : "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                            }
                            onClick={() => {
                                handleChangePortfolioPublic(false);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "general.no",
                            })}
                        </div>
                        <div
                            className={
                                changePortfolio.new_public
                                    ? "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                                    : "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px"
                            }
                            onClick={() => {
                                handleChangePortfolioPublic(true);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "general.yes",
                            })}
                        </div>
                    </div>
                </div>
                <div className='divider_no_margin'></div>
                {/* BUTTONS */}
                <div className='flex wrap space_between pt_15px pb_15px'>
                    <div
                        className='button_delete mt_5px ml_15px mr_15px pr_10px pl_10px click_translate'
                        onClick={() => {
                            handleOpenDelete();
                        }}
                    >
                        <div className='ml_10px text_3'>
                            {useintl.formatMessage({
                                id: "portfolio.delete_portfolio",
                            })}
                        </div>
                        <div className='mr_10px ml_10px pt_5px text_2'>
                            <MdIcons.MdDelete />
                        </div>
                    </div>
                    <div
                        className='button_blue color_white mt_5px ml_15px mr_15px'
                        onClick={() => {
                            handleChangePortfolio();
                        }}
                    >
                        <div className='ml_10px text_3'>
                            {useintl.formatMessage({
                                id: "portfolio.save_changes",
                            })}
                        </div>
                        <MdIcons.MdModeEdit className='mr_10px ml_10px text_2' />
                    </div>
                </div>
            </div>

            {/* ALERT SNACKBAR */}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                message="Don't forget to give your Portfolio a recognizable name"
                action={
                    <React.Fragment>
                        <IconButton
                            size='small'
                            aria-label='close'
                            color='inherit'
                            onClick={handleCloseAlert}
                        >
                            <AiIcons.AiOutlineClose />
                        </IconButton>
                    </React.Fragment>
                }
            />

            {/* ICON SELECTION */}
            <Dialog
                open={openPortfolioIcon}
                onClose={handleClosePortfolioIcon}
                maxWidth={false}
                className=''
            >
                <div className='background_color_2 max_width_500px text_color_1'>
                    <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{"Select a fitting Icon"}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleClosePortfolioIcon}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider mb_15px'></div>
                    <div className='flex space_around wrap border_box padding_10px'>
                        {icon_storage.map((icon, index) => {
                            return (
                                <div
                                    className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                    key={index}
                                    onClick={() => {
                                        handleChangePortfolioIcon(icon.name);
                                        handleClosePortfolioIcon();
                                    }}
                                >
                                    {icon.icon}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Dialog>

            {/* DELETE PORTFOLIO */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                maxWidth={false}
            >
                <div className='portfolio_create_dialog_container'>
                    <div className='portfolio_create_dialog_header flex space_between title_4 mt_10px ml_15px mr_15px'>
                        <div className='mr_10px'>
                            {useintl.formatMessage({
                                id: "portfolio.delete_portfolio",
                            })}
                            {" - "}'{currentPortfolio.name}'
                        </div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleCloseDelete}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='portfolio_create_dialog_input mt_10px mb_10px ml_10px mr_10px flex right'>
                        {/* Problemtofix: Dialog mit Icons */}
                        <div
                            className='button_blue color_white ml_15px'
                            onClick={handleCloseDelete}
                        >
                            <div className='ml_10px text_3'>
                                {useintl.formatMessage({
                                    id: "general.no",
                                })}
                            </div>
                            <AiIcons.AiOutlineClose className='mr_10px ml_10px text_2' />
                        </div>
                        <div
                            className='button_delete ml_15px'
                            onClick={() => {
                                handleDeletePortfolio();
                            }}
                        >
                            <div className='ml_10px text_3'>
                                {useintl.formatMessage({
                                    id: "general.yes",
                                })}
                            </div>
                            <IoIcons5.IoTrashOutline className='mr_10px ml_10px text_2' />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

ChangePortfolio.propTypes = {
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    renamePortfolio: PropTypes.func.isRequired,
    deletePortfolio: PropTypes.func.isRequired,
    setCurrentPortfolio: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    renamePortfolio,
    deletePortfolio,
    setCurrentPortfolio,
    setAlert,
})(ChangePortfolio);
