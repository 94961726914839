import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
// import { Link } from 'react-router-dom';
// import { FormattedMessage } from "react-intl";
// import { useHistory } from "react-router-dom";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Watchlist from "./Watchlist";
import WatchlistMenuContainer from "./WatchlistMenuContainer";
import FeedbackIcon from "../elements/FeedbackIcon";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { setWatchlist, getWatchlist } from "../../../actions/watchlistActions";
import { setAlert } from "../../../actions/alertActions";

// MUI
import Dialog from "@mui/material/Dialog";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const WatchlistsMenu = ({
    sidebar: { sidebar },
    user,
    watchlist,
    setWatchlist,
    setAlert,
}) => {
    useEffect(() => {
        // eslint-disable-next-line
    }, [watchlist]);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            console.log("PAGE VIEW: ", window.location.pathname);
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    const useintl = useIntl();

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    /*Create Select Watchlist Icon Modal*/
    const [openWatchlistIcon, setOpenWatchlistIcon] = React.useState(false);
    const handleClickOpenWatchlistIcon = () => {
        setOpenWatchlistIcon(true);
    };
    const handleCloseWatchlistIcon = () => {
        setOpenWatchlistIcon(false);
    };

    /*Create Watchlist functions*/
    /*newWatchlist ist local State um zwischen zu speichern*/
    const [newWatchlist, setNewWatchlist] = React.useState({
        id: null,
        name: "",
        icon: "collection",
        description: "",
    });
    const handleNewWatchlistName = (event) => {
        if (event.target.value.length <= 20) {
            setNewWatchlist({ ...newWatchlist, name: event.target.value });
        }
    };
    const handleNewWatchlistIcon = (icon) => {
        setNewWatchlist({ ...newWatchlist, icon: icon });
    };
    // const handleNewWatchlistPublic = (new_public) => {
    //     setNewWatchlist({ ...newWatchlist, public: new_public });
    // };
    const handleNewWatchlistDescription = (event) => {
        if (event.target.value.length <= 500) {
            setNewWatchlist({
                ...newWatchlist,
                description: event.target.value,
            });
        }
    };

    const handleSetWatchlist = () => {
        if (newWatchlist.name !== "") {
            setWatchlist(
                {
                    name: newWatchlist.name,
                    icon: newWatchlist.icon,
                    description: newWatchlist.description,
                    user: user.id,
                },
                watchlist,
                user.status
            );
            setAlert(
                `Your watchlist "${newWatchlist.name}" has been created`,
                "success"
            );
            setNewWatchlist({
                id: null,
                name: "",
                icon: "collection",
                description: "",
            });
            // setCurrentWatchlist(id);
            getWatchlist(null, user.id, watchlist.watchlists);
            // handleCloseCreateWatchlist();
            // routeRedirect(`/watchlist?id=${id}`);
        } else {
            setAlert(`You need to provide a watchlist name`, "danger");
        }
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.watchlist",
        })}`,
        location_index: 2,
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Watchlist | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Create Watchlists to obtain a structured overview on companies or sectors you are interested in.'
                />
            </Helmet>
            <FeedbackIcon feedbackInfo={feedbackInfo} />
            <TopBarCallToAction />
            {!window.location.search || watchlist.error ? (
                <div className='background_color_1 text_color_1'>
                    <div className='flex width_100pr mt_20px mb_20px'>
                        <div className='title_4 ml_25px_10px mt_5px'>
                            <MdIcons.MdBookmarkBorder />
                        </div>
                        <div className='title_4 ml_15px'>Your Watchlists</div>
                        <AiIcons.AiOutlineInfoCircle
                            className='text_1 hover_translate_up ml_15px mt_5px'
                            onClick={() => {
                                handleOpenPageInfo();
                            }}
                        />
                    </div>
                    <PageInfoModalSimple
                        infoHeader={"What can i do here?"}
                        infoBody={
                            "Keep a structured overview of companies that interest you and that you would like to analyse at a later date by organizing your watchlists."
                        }
                        showPageInfo={showPageInfo}
                        handleClosePageInfo={handleClosePageInfo}
                    />

                    <div className='border_box width_100pr mt_35px '>
                        <div className='ms_auto width_100pr grid grid_column_repeat_5_4_3_2_1'>
                            {watchlist.watchlists.length !== 0
                                ? watchlist.watchlists.map((wl) =>
                                      wl ? (
                                          <WatchlistMenuContainer
                                              containerprops={wl}
                                          />
                                      ) : null
                                  )
                                : null}

                            {user.status === 80 ? null : (
                                <div className='border_box shadow_dark width_30pr_45pr_80pr ms_auto mt_10px mb_10px min_width_320px height_300px background_color_2 border_radius_15px'>
                                    <div className='width_100pr background_color_3 border_radius_15px_top height_20pr flex center padding_10px text_2'>
                                        <div className='width_20px title_4 mt_6px'>
                                            <MdIcons.MdAdd />
                                        </div>
                                        <div className='ml_20px text_2'>
                                            Create new Watchlist
                                        </div>
                                    </div>
                                    <div className='divider_no_margin'></div>
                                    <div className='flex flex_column height_80pr padding_20px space_between'>
                                        <div className='width_100pr'>
                                            <div className='flex'>
                                                <div
                                                    className='width_15pr mt_3px flex left cursor_pointer hover_opacity_80'
                                                    onClick={
                                                        handleClickOpenWatchlistIcon
                                                    }
                                                >
                                                    <div className='width_20px text_1'>
                                                        {icon_storage.map(
                                                            (icon) =>
                                                                icon.name ===
                                                                newWatchlist.icon
                                                                    ? icon.icon
                                                                    : null
                                                        )}
                                                    </div>
                                                    <div className='width_30px text_1'>
                                                        <RiIcons.RiArrowDownSFill />
                                                    </div>
                                                </div>
                                                <div className='input_basic_border border_radius_5px width_85pr padding_5px height_100pr'>
                                                    <input
                                                        type='text'
                                                        placeholder='Name...'
                                                        maxlength='20'
                                                        defaultValue={
                                                            newWatchlist.name !==
                                                            null
                                                                ? newWatchlist.name
                                                                : ""
                                                        }
                                                        onKeyUp={
                                                            handleNewWatchlistName
                                                        }
                                                        className='text_color_1 text_2'
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <textarea
                                                    className='text_3 mt_15px width_100pr text_color_1 height_100px padding_5px resize_none border_radius_5px input_basic_border'
                                                    name='text'
                                                    rows='8'
                                                    maxLength='500'
                                                    placeholder='Description...'
                                                    onKeyUp={
                                                        handleNewWatchlistDescription
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className='width_100pr flex right'>
                                            <div
                                                className='button_blue color_white ml_15px'
                                                onClick={() => {
                                                    handleSetWatchlist();
                                                }}
                                            >
                                                <div className='ml_10px text_3'>
                                                    Create
                                                </div>
                                                <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Select Watchlist Icon Modal */}
                    <Dialog
                        open={openWatchlistIcon}
                        onClose={handleCloseWatchlistIcon}
                        maxWidth={false}
                        className=''
                    >
                        <div className='background_color_2 max_width_500px text_color_1'>
                            <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                <div className='mr_10px'>
                                    {"Select a fitting Icon"}
                                </div>
                                <div
                                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                    onClick={handleCloseWatchlistIcon}
                                >
                                    <AiIcons.AiOutlineClose />
                                </div>
                            </div>
                            <div className='divider mb_15px'></div>
                            <div className='flex space_around wrap border_box padding_10px'>
                                {icon_storage.map((icon, index) => {
                                    return (
                                        <div
                                            className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                            key={index}
                                            onClick={() => {
                                                handleNewWatchlistIcon(
                                                    icon.name,
                                                    icon.icon
                                                );
                                                handleCloseWatchlistIcon();
                                            }}
                                        >
                                            {icon.icon}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Dialog>
                </div>
            ) : (
                <Watchlist />
            )}
        </div>
    );
};

WatchlistsMenu.propTypes = {
    sidebar: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    deleteWatchlist: PropTypes.func.isRequired,
    setWatchlist: PropTypes.func.isRequired,
    getWatchlist: PropTypes.func.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
    setCurrentWatchlist: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    user: state.user,
    watchlist: state.watchlist,
    screener: state.screener,
});

export default connect(mapStateToProps, {
    setWatchlist,
    getWatchlist,
    setAlert,
})(WatchlistsMenu);
