import {
    GET_INDEX_PERFORMANCE,
    GET_SECTOR_STATISTICS,
    GET_HISTORIC_METRIC_SECTOR,
    SET_TABLE_METRIC_DISPLAY,
    OPEN_SECTOR_METRIC_HISTORY,
    CLOSE_SECTOR_METRIC_HISTORY,
} from "../actions/Types";

const initialState = {
    index_performance: null,
    all_sectors_data: null,
    open_metric_history: false,
    metric_history: null,
    metric_history_data: { x_axis: [], y_axis: [] },
    sector_history: null,
    market_table: {
        ev_ebit: {
            property: "ev_ebit",
            display: false,
        },
        ev_ebitda: {
            property: "ev_ebitda",
            display: false,
        },
        price_earnings: {
            property: "price_earnings",
            display: false,
        },
        price_ebit: {
            property: "price_ebit",
            display: false,
        },
        price_sales: {
            property: "price_sales",
            display: true,
        },
        price_cash_flow: {
            property: "price_cash_flow",
            display: false,
        },
        price_book: {
            property: "price_book",
            display: false,
        },
        dividend_yield: {
            property: "dividend_yield",
            display: true,
        },
        payout_ratio: {
            property: "payout_ratio",
            display: false,
        },
        gross_profit_margin: {
            property: "gross_profit_margin",
            display: true,
        },
        net_profit_margin: {
            property: "net_profit_margin",
            display: false,
        },
        operating_cash_flow_margin: {
            property: "operating_cash_flow_margin",
            display: false,
        },
        operating_margin: {
            property: "operating_margin",
            display: false,
        },
        return_on_assets: {
            property: "return_on_assets",
            display: false,
        },
        return_on_net_tangible_assets: {
            property: "return_on_net_tangible_assets",
            display: false,
        },
        asset_turnover: {
            property: "asset_turnover",
            display: false,
        },
        cash_flow_on_assets: {
            property: "cash_flow_on_assets",
            display: false,
        },
        return_on_equity: {
            property: "return_on_equity",
            display: false,
        },
        revenue_on_equity: {
            property: "revenue_on_equity",
            display: false,
        },
        return_on_capital_employed: {
            property: "return_on_capital_employed",
            display: false,
        },
        return_on_capital: {
            property: "return_on_capital",
            display: false,
        },
        debt_ratio: {
            property: "debt_ratio",
            display: true,
        },
        debt_to_ebit: {
            property: "debt_to_ebit",
            display: false,
        },
        debt_to_equity: {
            property: "debt_to_equity",
            display: false,
        },
        debt_to_revenue: {
            property: "debt_to_revenue",
            display: false,
        },
        current_ratio: {
            property: "current_ratio",
            display: false,
        },
        price_earnings_growth: {
            property: "price_earnings_growth",
            display: false,
        },
        price_sales_growth: {
            property: "price_sales_growth",
            display: false,
        },
        revenue_growth_1y: {
            property: "revenue_growth_1y",
            display: true,
        },
        revenue_growth_3y: {
            property: "revenue_growth_3y",
            display: false,
        },
        revenue_growth_5y: {
            property: "revenue_growth_5y",
            display: true,
        },
        revenue_growth_9y: {
            property: "revenue_growth_9y",
            display: false,
        },
        earnings_growth_1y: {
            property: "earnings_growth_1y",
            display: false,
        },
        earnings_growth_3y: {
            property: "earnings_growth_3y",
            display: false,
        },
        earnings_growth_5y: {
            property: "earnings_growth_5y",
            display: false,
        },
        earnings_growth_9y: {
            property: "earnings_growth_9y",
            display: false,
        },
        dividend_growth_1y: {
            property: "dividend_growth_1y",
            display: false,
        },
        dividend_growth_3y: {
            property: "dividend_growth_3y",
            display: false,
        },
        dividend_growth_5y: {
            property: "dividend_growth_5y",
            display: false,
        },
        dividend_growth_9y: {
            property: "dividend_growth_9y",
            display: false,
        },
        daily_return: {
            property: "daily_return",
            display: false,
        },
        weekly_return: {
            property: "weekly_return",
            display: true,
        },
        monthly_return: {
            property: "monthly_return",
            display: false,
        },
        quarterly_return: {
            property: "quarterly_return",
            display: false,
        },
        semi_annual_return: {
            property: "semi_annual_return",
            display: false,
        },
        annual_return: {
            property: "annual_return",
            display: true,
        },
        two_year_return: {
            property: "two_year_return",
            display: false,
        },
        three_year_return: {
            property: "three_year_return",
            display: false,
        },
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_INDEX_PERFORMANCE:
            return {
                ...state,
                index_performance: action.payload,
            };

        case GET_SECTOR_STATISTICS:
            return {
                ...state,
                all_sectors_data: action.payload,
            };

        case GET_HISTORIC_METRIC_SECTOR:
            return {
                ...state,
                metric_history_data: action.payload,
            };

        case SET_TABLE_METRIC_DISPLAY:
            return {
                ...state,
                market_table: {
                    ...state.market_table,
                    [action.payload]: {
                        ...state.market_table[action.payload],
                        display: !state.market_table[action.payload].display,
                    },
                },
            };

        case OPEN_SECTOR_METRIC_HISTORY:
            return {
                ...state,
                open_metric_history: true,
                metric_history: action.payload.metric,
                sector_history: action.payload.sector,
            };

        case CLOSE_SECTOR_METRIC_HISTORY:
            return {
                ...state,
                open_metric_history: false,
            };

        default:
            return state;
    }
};
