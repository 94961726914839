import setAuthToken from "../utils/setAuthToken";
import axios from "axios";

import {
    LOAD_USER,
    FORCE_LOGIN,
    REGISTER_SUCCESS,
    REGISTER_GOOGLE_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_GOOGLE_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    SET_ALERT,
    REMOVE_ALERT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_FAIL,
    CHANGE_USERNAME_SUCCESS,
    CHANGE_USERNAME_FAIL,
    USER_ERROR,
    LOAD_WATCHLISTS,
    GET_WATCHLIST_COMPANIES,
    GET_URL_WATCHLIST_COMPANIES,
    WATCHLIST_ERROR,
    LOAD_PORTFOLIOS,
    GET_PORTFOLIO,
    PORTFOLIO_ERROR,
    GET_URL_PORTFOLIO,
    GET_BOOKMARKS,
    GET_COMPANY_MONTE_CARLO_PARAMS,
    COMPANY_ERROR,
    CLEAR_PORTFOLIOS,
    CLEAR_WATCHLISTS,
    CLEAR_SCREENER,
    SET_THRESHHOLD,
    LOAD_SCREENER_PRESETS,
    CREATE_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
    SIMPLIFIED_VIEW,
    EMAIL_VERIFICATION_SUCCESS,
    OPEN_CHANGES,
    OPEN_LOADER,
    DELETE_ACCOUNT_SUCCESS,
} from "./Types";

import * as math from "mathjs";

// Load User, Reauthentification
export const loadUser = (token, path, params) => async (dispatch) => {
    try {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        } else if (token && token !== 0) {
            setAuthToken(token);
        } else {
            const exitFunction = () => {
                localStorage.setItem(
                    "latest_url",
                    JSON.stringify(
                        window.location.pathname + window.location.search
                    )
                );
                dispatch({
                    type: FORCE_LOGIN,
                });
                setTimeout(() => dispatch({ type: FORCE_LOGIN }), 1000);
            };
            return exitFunction();
        }

        // LOAD USER
        // const res = await fetch(
        //     "/api/auth",
        //     {
        //         method: "POST",
        //         body: JSON.stringify({
        //             id: params,
        //             uid: res.data.id,
        //             sortBy: "market_cap_usd",
        //             sortDir: "DESC",
        //         }),
        //         headers: {
        //             "Content-Type": "application/json",
        //             token: localStorage.token,
        //         },
        //     }
        // );
        const res = await axios.get("/api/auth");
        if (res.data.error) {
            const id = Math.floor(Math.random() * 100);
            let msg = res.data.error_msg;
            let type = res.data.error_type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );

            if (res.data.error_auth) {
                // LOGOUT USER
                try {
                    dispatch({
                        type: LOGOUT_SUCCESS,
                    });
                    dispatch({
                        type: CLEAR_PORTFOLIOS,
                    });
                    dispatch({
                        type: CLEAR_WATCHLISTS,
                    });
                    dispatch({
                        type: CLEAR_SCREENER,
                    });
                } catch (err) {
                    dispatch({
                        type: LOGOUT_FAIL,
                    });
                }
            }
        } else {
            dispatch({
                type: LOAD_USER,
                payload: res.data,
            });
            dispatch({
                type: LOAD_SCREENER_PRESETS,
                payload: res.data.screener_presets,
            });
        }

        // LOAD USERS WATCHLISTS
        const res_watchlists = await fetch("/node_get_watchlists", {
            method: "POST",
            body: JSON.stringify({
                user: res.data.id,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });
        const watchlists_data = await res_watchlists.json();
        if (!watchlists_data.error) {
            dispatch({
                type: LOAD_WATCHLISTS,
                payload: watchlists_data,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = watchlists_data.error_type
                ? watchlists_data.error_type
                : "warning";
            let msg = watchlists_data.error_msg
                ? watchlists_data.error_msg
                : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }

        // LOAD PORTFOLIOS
        const res_portfolios = await fetch("/node_get_portfolios", {
            method: "POST",
            body: JSON.stringify({
                user: res.data.id,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });
        const portfolios_data = await res_portfolios.json();
        if (!portfolios_data.error) {
            // DO WHAT IS INTENDED
            dispatch({
                type: LOAD_PORTFOLIOS,
                payload: portfolios_data,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = portfolios_data.error_type
                ? portfolios_data.error_type
                : "warning";
            let msg = portfolios_data.error_msg
                ? portfolios_data.error_msg
                : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }

        // IF PATH WATCHLIST, LOAD WATCHLIST
        if (path && path === "/watchlist" && params) {
            let watchlist_id_url = params;
            watchlist_id_url = Number(watchlist_id_url);

            if (watchlists_data && res.data.id) {
                // GET WATCHLIST FUNCTION
                try {
                    if (params !== null && res.data.id !== null) {
                        const res_initial_watchlist = await fetch(
                            "/node_watchlist",
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    id: params,
                                    uid: res.data.id,
                                    sortBy: "daily_return",
                                    sortDir: "DESC",
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                    token: localStorage.token,
                                },
                            }
                        );

                        const res_initial_watchlist_data =
                            await res_initial_watchlist.json();

                        // Check if it is made by user
                        // let is_in_list = watchlists_data.includes(id);
                        let is_in_list = false;

                        if (res_initial_watchlist_data.error) {
                            const id = Math.floor(Math.random() * 100);
                            let msg =
                                "This watchlist either doesn't exist or is not publicly available";
                            let type = "warning";

                            dispatch({
                                type: SET_ALERT,
                                payload: { msg, type, id },
                            });

                            setTimeout(
                                () =>
                                    dispatch({
                                        type: REMOVE_ALERT,
                                        payload: id,
                                    }),
                                5000
                            );
                            dispatch({
                                type: WATCHLIST_ERROR,
                            });
                        } else {
                            watchlists_data.map((watchlist) => {
                                if (Number(watchlist.id) === Number(params)) {
                                    is_in_list = true;
                                }
                            });

                            if (is_in_list) {
                                dispatch({
                                    type: GET_WATCHLIST_COMPANIES,
                                    payload: {
                                        companies:
                                            res_initial_watchlist_data.companies,
                                        watchlist:
                                            res_initial_watchlist_data.watchlist,
                                    },
                                });
                            } else {
                                dispatch({
                                    type: GET_URL_WATCHLIST_COMPANIES,
                                    payload: {
                                        companies:
                                            res_initial_watchlist_data.companies,
                                        watchlist:
                                            res_initial_watchlist_data.watchlist,
                                    },
                                });
                            }
                        }
                    } else {
                        // handle case, that watchlist is just initiated, or does not have any values inserted
                        dispatch({
                            type: GET_WATCHLIST_COMPANIES,
                            payload: [],
                        });
                    }
                } catch (err) {
                    const id = Math.floor(Math.random() * 100);
                    let msg = "An error occurred while loading the watchlist.";
                    let type = "warning";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () =>
                            dispatch({
                                type: REMOVE_ALERT,
                                payload: id,
                            }),
                        5000
                    );
                    dispatch({
                        type: WATCHLIST_ERROR,
                    });
                }
            }
        }

        // IF PATH PORTFOLIO, LOAD PORTFOLIO
        if (path && path === "/portfolio" && params) {
            let portfolio_id_url = params;
            portfolio_id_url = Number(portfolio_id_url);

            if (portfolios_data && res.data.id) {
                // GET PORTFOLIO FUNCTION
                try {
                    if (params !== null && res.data.id !== null) {
                        const res_initial_portfolio = await fetch(
                            "/node_portfolio",
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    id: params,
                                    uid: res.data.id,
                                    risk_free_rate_required: true,
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                    token: localStorage.token,
                                },
                            }
                        );

                        const res_initial_portfolio_data =
                            await res_initial_portfolio.json();

                        // res_initial_portfolio_data = {
                        //    risk_free_rate: [],
                        //    companies: [],
                        //    portfolio: {},
                        //    error: boolean
                        // }

                        // Check if it is made by user
                        // let is_in_list = portfolios_data.includes(id);
                        let is_in_list = false;

                        if (res_initial_portfolio_data.error) {
                            const id = Math.floor(Math.random() * 100);
                            let msg = res_initial_portfolio_data.error_msg;
                            let type = res_initial_portfolio_data.error_type;
                            dispatch({
                                type: SET_ALERT,
                                payload: { msg, type, id },
                            });

                            setTimeout(
                                () =>
                                    dispatch({
                                        type: REMOVE_ALERT,
                                        payload: id,
                                    }),
                                5000
                            );
                        } else {
                            let response = {
                                risk_free_rate:
                                    res_initial_portfolio_data.risk_free_rate,
                                allocation:
                                    res_initial_portfolio_data.portfolio
                                        .allocation,
                                performance:
                                    res_initial_portfolio_data.companies &&
                                    res_initial_portfolio_data.companies
                                        .length > 0 &&
                                    res_initial_portfolio_data.portfolio.ticker
                                        ? calculatePortfolioPerformance(
                                              res_initial_portfolio_data
                                                  .portfolio.allocation,
                                              res_initial_portfolio_data.companies
                                          )
                                        : null,
                                companies: res_initial_portfolio_data.companies,
                                id: portfolio_id_url,
                            };

                            if (response.performance !== null) {
                                // CALCULATE MANAGER PERFORMANCE
                                calulateLinearRegressionConfidence(
                                    response.performance.performance
                                );
                                let cot = calculateRegressionConfidenceOverTime(
                                    response.performance.performance
                                );

                                response.performance["lin_reg"] = cot.lin_reg;
                                response.performance["conf_low"] = cot.conf_low;
                                response.performance["conf_up"] = cot.conf_up;
                                response.performance["linear_regression_1y"] =
                                    cot.linear_regression_1y;
                                response.performance["confidence_lower"] =
                                    cot.confidence_lower;
                                response.performance["confidence_upper"] =
                                    cot.confidence_upper;
                            }

                            portfolios_data.map((portfolio) => {
                                if (Number(portfolio.id) === Number(params)) {
                                    is_in_list = true;
                                }
                            });

                            if (is_in_list) {
                                dispatch({
                                    type: GET_PORTFOLIO,
                                    payload: response,
                                });
                            } else {
                                let url_portfolio =
                                    res_initial_portfolio_data.portfolio;
                                url_portfolio["performance"] =
                                    response.performance;
                                url_portfolio["companies"] = response.companies;
                                dispatch({
                                    type: GET_URL_PORTFOLIO,
                                    payload: {
                                        url_portfolio: url_portfolio,
                                        risk_free_rate: response.risk_free_rate,
                                    },
                                });
                            }
                        }
                    } else {
                        // handle case, that portfolio is just initiated, or does not have any values inserted
                        dispatch({
                            type: GET_PORTFOLIO,
                            payload: [],
                        });
                    }
                } catch (err) {
                    const id = Math.floor(Math.random() * 100);
                    let msg = "An error occurred while loading the portfolio.";
                    let type = "warning";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () =>
                            dispatch({
                                type: REMOVE_ALERT,
                                payload: id,
                            }),
                        5000
                    );
                    dispatch({
                        type: PORTFOLIO_ERROR,
                    });
                }
            }
        }

        // SET THE BOOKMARKS FROM LOCALSTORAGE
        let bookmarks = [];
        if (localStorage.bookmarks) {
            bookmarks = JSON.parse(localStorage.getItem("bookmarks"));
        }
        dispatch({
            type: GET_BOOKMARKS,
            payload: bookmarks,
        });

        if (localStorage.simplified_view) {
            dispatch({
                type: SIMPLIFIED_VIEW,
                payload: JSON.parse(localStorage.getItem("simplified_view")),
            });
        }

        // if(path && path === "/monte_carlo_dcf" && params) {
        //     try {
        //         const res = await fetch(`/node_company_mc_dcf${params}`);
        //         const data = await res.json();
        //         // "n_trials": 100000,
        //         // "n_periods": 10,
        //         // "r_0": annual_free_cash_flow_change_average,
        //         // "dr_0": annual_free_cash_flow_change_std,
        //         // "r_n": 1.04,
        //         // "dr_n": 0.16,
        //         // "C_0": free_cash_flow_per_share,
        //         // "discount_rate": 0.1,
        //         // "terminal_multiple": min(price_cash_flow_average_10y, 15)

        //         console.log("DATADATA: ", data)

        //         const varanaut_res_dcf = await fetch(
        //             "https://varanaut-server.herokuapp.com/dcf",
        //             {
        //                 method: "POST",
        //                 body: JSON.stringify({
        //                     "n_trials": 100000,
        //                     "n_periods": 10,
        //                     "r_0": data.annual_free_cash_flow_change_average,
        //                     "dr_0": data.annual_free_cash_flow_change_std,
        //                     "r_n": 1.04,
        //                     "dr_n": 0.16,
        //                     "C_0": data.free_cash_flow_per_share,
        //                     "discount_rate": 0.1,
        //                     "terminal_multiple": 1.2
        //                 }),
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                     token: localStorage.token,
        //                 },
        //             }
        //         );

        //         const response_data = await varanaut_res_dcf.json();

        //         dispatch({
        //             type: GET_COMPANY_MONTE_CARLO_PARAMS,
        //             payload: {data, response_data},
        //         });
        //     } catch (err) {
        //         console.log(err);
        //         dispatch({
        //             type: COMPANY_ERROR,
        //             payload: err.response.statusText,
        //         });
        //     }
        // }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "Your session has expired. Please log in again.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
        const exitFunction = () => {
            localStorage.setItem(
                "latest_url",
                JSON.stringify(
                    window.location.pathname + window.location.search
                )
            );
            dispatch({
                type: FORCE_LOGIN,
            });
            setTimeout(() => dispatch({ type: FORCE_LOGIN }), 1000);
        };
        return exitFunction();
    }
};

// Register User Traditional
export const registerUser = (formData) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/register", {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();
        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }

        dispatch({
            type: REGISTER_SUCCESS,
            payload: data,
        });

        loadUser(data.token, null, null);
    } catch (err) {
        dispatch({
            type: REGISTER_FAIL,
            payload: err.response.msg,
        });
    }
};

// Register User Google
export const registerUserGoogle = (formData) => async (dispatch) => {
    try {
        // {
        //     "googleId": "110868660911604046752",
        //     "imageUrl": "https://lh3.googleusercontent.com/a/AATXAJw29I8VL9PBvIG_rlvJqf1tBEoJUFTfebxF_KnI=s96-c",
        //     "email": "paul.zwecker@gmail.com",
        //     "name": "Paul Zwecker",
        //     "givenName": "Paul",
        //     "familyName": "Zwecker"
        // }
        const res = await fetch("/api/users/register_google", {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }

        dispatch({
            type: REGISTER_GOOGLE_SUCCESS,
            payload: data,
        });

        loadUser(data.token, null, null);
    } catch (err) {
        dispatch({
            type: REGISTER_FAIL,
            payload: err.response.msg,
        });
    }
};

// Login User Traditional
export const loginUser = (formData) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/login", {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }
        if (data.type === "danger") {
            dispatch({
                type: LOGIN_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data,
            });
            localStorage.setItem("latest_threshold", JSON.stringify(0));
            dispatch({
                type: SET_THRESHHOLD,
                payload: 0,
            });
            loadUser(data.token, null, null);
        }
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response.msg,
        });
    }
};

// Login User Google
export const loginUserGoogle = (formData) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/login_google", {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }

        dispatch({
            type: LOGIN_GOOGLE_SUCCESS,
            payload: data,
        });
        loadUser(data.token, null, null);
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response.msg,
        });
    }
};

// Login User Traditional
export const loginDemoUser = () => async (dispatch) => {
    try {
        // const res = await axios.get("/api/auth");
        const res = await fetch("/api/users/login_demo", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }
        if (data.type === "danger") {
            dispatch({
                type: LOGIN_FAIL,
                payload: data.msg,
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data,
            });
            localStorage.setItem("latest_threshold", JSON.stringify(0));
            dispatch({
                type: SET_THRESHHOLD,
                payload: 0,
            });
            loadUser(data.token, null, null);
        }
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response.msg,
        });
    }
};

// Logout User
export const logoutUser = () => (dispatch) => {
    try {
        dispatch({
            type: LOGOUT_SUCCESS,
        });
        dispatch({
            type: CLEAR_PORTFOLIOS,
        });
        dispatch({
            type: CLEAR_WATCHLISTS,
        });
        dispatch({
            type: CLEAR_SCREENER,
        });
    } catch (err) {
        dispatch({
            type: LOGOUT_FAIL,
        });
    }
};

// Forgot Password
export const requestNewPassword = (email) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/forgot_password", {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        if (data.msg) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
            if (data.type === "success") {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: data,
                });
            }
        }
    } catch (err) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: err.response.msg,
        });
    }
};

// VERIFY USER EMAIL
export const verifyUserEmail = (token) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/verify_account", {
            method: "POST",
            body: JSON.stringify({
                token: token,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        console.log(data);

        if (data.error) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.error_msg;
            let type = data.error_type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        } else {
            const id = Math.floor(Math.random() * 100);
            let msg = "Your account has been successfully verified.";
            let type = "success";
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
            dispatch({
                type: EMAIL_VERIFICATION_SUCCESS,
            });
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let msg =
            "An error occurred while trying to verify your account. Please try again later.";
        let type = "warning";
        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    }
};

// SEND EMAIL VERIFICATION MAIL
export const sendEmailVerificationMail = (token, email) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/send_verification_mail", {
            method: "POST",
            body: JSON.stringify({
                token: token,
                email: email,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await res.json();

        console.log(data);

        if (data.error) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.error_msg;
            let type = data.error_type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        } else {
            const id = Math.floor(Math.random() * 100);
            let msg =
                "We have sent you an email with which you can verify your account.";
            let type = "success";
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let msg =
            "An error occurred while trying to send you the email verification.";
        let type = "warning";
        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    }
};

// Change Password
export const changePassword =
    (user, new_password, old_password) => async (dispatch) => {
        try {
            const res = await fetch("/api/users/change_password", {
                method: "POST",
                body: JSON.stringify({
                    user: user,
                    new_password: new_password,
                    old_password: old_password,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });

            const data = await res.json();

            if (data.msg && data.type) {
                const id = Math.floor(Math.random() * 100);
                let msg = data.msg;
                let type = data.type;
                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    5000
                );
                if (data.type === "success") {
                    dispatch({
                        type: RESET_PASSWORD_SUCCESS,
                        payload: data,
                    });
                }
            }
        } catch (err) {
            dispatch({
                type: RESET_PASSWORD_FAIL,
                payload: err.response.msg,
            });
        }
    };

// Change Email
export const changeEmail = (user, password, new_email) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/change_email", {
            method: "POST",
            body: JSON.stringify({
                user: user,
                password: password,
                new_email: new_email,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });

        const data = await res.json();

        if (data.msg && data.type) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
            if (data.type === "success") {
                setAuthToken(data.response.token);
                sendEmailVerificationMail(data.response.token, new_email);
                dispatch({
                    type: CHANGE_EMAIL_SUCCESS,
                    payload: data.response,
                });
            }
        }
    } catch (err) {
        dispatch({
            type: CHANGE_EMAIL_FAIL,
            payload: err.response.msg,
        });
    }
};

// CHANGE USERNAME
export const changeUserName =
    (user, password, new_name) => async (dispatch) => {
        try {
            const res = await fetch("/api/users/change_username", {
                method: "POST",
                body: JSON.stringify({
                    user: user,
                    password: password,
                    new_name: new_name,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });

            const data = await res.json();

            if (data.msg && data.type) {
                const id = Math.floor(Math.random() * 100);
                let msg = data.msg;
                let type = data.type;
                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    5000
                );
                if (data.type === "success") {
                    setAuthToken(data.response.token);
                    dispatch({
                        type: CHANGE_USERNAME_SUCCESS,
                        payload: data.response,
                    });
                }
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let msg =
                "An error occurred while trying to change your username. Please try again later.";
            let type = "warning";
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
        }
    };

// Set state.openChanges = false/true
export const openSubscriptionChanges = () => (dispatch) => {
    dispatch({
        type: OPEN_CHANGES,
    });
};

// Set state.openLoader = false/true
export const openSubscriptionLoader = () => (dispatch) => {
    dispatch({
        type: OPEN_LOADER,
    });
};

// Create Subscription
export const userCreateSubscription = (user) => (dispatch) => {
    console.log(user);
    try {
        dispatch({
            type: CREATE_SUBSCRIPTION,
            payload: user,
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
        });
    }
};

// Cancel Subscription
export const userCancelSubscription = (user) => (dispatch) => {
    console.log(user);
    try {
        dispatch({
            type: CANCEL_SUBSCRIPTION,
            payload: user,
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
        });
    }
};

// Change Billing Period Subscription
export const userChangeBillingSubscription = (user) => (dispatch) => {
    try {
        dispatch({
            type: CREATE_SUBSCRIPTION,
            payload: user,
        });
    } catch (err) {
        dispatch({
            type: USER_ERROR,
        });
    }
};

// DELETE ACCOUNT
export const deleteAccount = (password) => async (dispatch) => {
    try {
        const res = await fetch("/api/users/delete_account", {
            method: "POST",
            body: JSON.stringify({
                password: password,
            }),
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
            },
        });

        const data = await res.json();

        if (data.msg && data.type) {
            const id = Math.floor(Math.random() * 100);
            let msg = data.msg;
            let type = data.type;
            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                5000
            );
            if (data.type === "success") {
                // CLEAR REDUX

                dispatch({
                    type: DELETE_ACCOUNT_SUCCESS,
                    // payload: data.response,
                    // data.subscriptionEnd
                });
            }
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let msg =
            "An error occurred while trying to delete your account. Please try again later.";
        let type = "warning";
        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    }
};

export const toggleSimplifiedView = (selected_option) => async (dispatch) => {
    try {
        localStorage.setItem(
            "simplified_view",
            JSON.stringify(selected_option)
        );
        localStorage.setItem("latest_screener", "?sortBy=market_cap_usdDESC&");
        dispatch({
            type: SIMPLIFIED_VIEW,
            payload: selected_option,
        });

        const id = Math.floor(Math.random() * 100);
        let msg = selected_option
            ? "You are now using StocksOnView as the simplified version."
            : "You are now using StocksOnView as the advanced version.";
        let type = "success";
        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let msg = "An error occurred while changeing the view options.";
        let type = "warning";
        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 5000);
    }
};
// Calculate any linear Regression with confidence interval
let theta;
let std;
let y_std_diff_std;
const calulateLinearRegressionConfidence = (performance) => {
    // Transform performance array to Matrix

    let performancematrix = math.matrix(performance);

    let PerformanceMatrix = [
        math.transpose(math.column(performancematrix, 0)),
        math.transpose(math.column(performancematrix, 1)),
    ];

    let y = PerformanceMatrix[1]._data[0].slice(
        PerformanceMatrix[1]._data[0].length - 750
    );
    let m = y.length;

    y = math.log(math.matrix(y));
    // [y1, y2, y3, y4 ...]

    let X_help = PerformanceMatrix[0]._data[0].slice(
        PerformanceMatrix[0]._data[0].length - 750
    );
    let ones = math.ones(m);
    ones = ones._data;

    let Xtr = math.matrix([X_help, ones]);
    // [[x1, x2, x3, x4 ...], [1, 1, 1, 1 ...]]

    let X = math.transpose(Xtr);
    // [[x1, 1], [x2, 2], [x3, 3] ...]

    let Xtr_X = math.multiply(Xtr, X);

    let Xtr_y = math.multiply(Xtr, y);

    theta = math.multiply(math.inv(Xtr_X), Xtr_y);

    let y_std = PerformanceMatrix[1]._data[0].slice(
        PerformanceMatrix[1]._data[0].length - 750
    );
    y_std = math.log(math.matrix(y_std));
    let y_std_diff = math.diff(y_std);

    y_std_diff_std = math.std(y_std_diff);

    let X_help_std = PerformanceMatrix[0]._data[0].slice(
        PerformanceMatrix[0]._data[0].length - 750
    );

    let X_std = math.multiply(theta._data[0], X_help_std);

    let theta_1_std = math.multiply(math.ones(750), theta._data[1]);

    let help = math.add(
        y_std,
        math.multiply(math.exp(math.add(X_std, theta_1_std)), -1)
    );
    std = math.sqrt(math.multiply(help, help) / 750);
};

// Calculates the confidence intervall over time for display,
// returns object: {lin_reg, conf_low, conf_up, linear_regression_1y, confidence_lower, confidence_upper}
const calculateRegressionConfidenceOverTime = (performance) => {
    let day = 365;
    let lin_reg = [];
    let conf_low = [];
    let conf_up = [];

    let linear_regression_1y = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000
    );

    let confidence_lower = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000 -
            math.sqrt(day) * y_std_diff_std
    );

    let confidence_upper = math.exp(
        math.log(performance[performance.length - 1][1]) +
            theta._data[0] * day * 24 * 3600 * 1000 +
            math.sqrt(day) * y_std_diff_std
    );

    for (day = 1; day < 366; day++) {
        lin_reg.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            math.exp(
                math.log(performance[performance.length - 1][1]) +
                    theta._data[0] * day * 24 * 3600 * 1000
            ),
        ]);
        conf_low.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            math.exp(
                math.log(performance[performance.length - 1][1]) +
                    theta._data[0] * day * 24 * 3600 * 1000 -
                    math.sqrt(day) * y_std_diff_std
            ),
        ]);
        conf_up.push([
            performance[performance.length - 1][0] + day * 24 * 3600 * 1000,
            math.exp(
                math.log(performance[performance.length - 1][1]) +
                    theta._data[0] * day * 24 * 3600 * 1000 +
                    math.sqrt(day) * y_std_diff_std
            ),
        ]);
    }

    return {
        lin_reg: lin_reg,
        conf_low: conf_low,
        conf_up: conf_up,
        linear_regression_1y: linear_regression_1y,
        confidence_lower: confidence_lower,
        confidence_upper: confidence_upper,
    };
};

// Calculate any Portfolio Performance
const calculatePortfolioPerformance = (asset_allocation, companies_array) => {
    // NEW
    let pre_sorted_companies = companies_array.sort((a, b) =>
        a.stock_prices.length > b.stock_prices.length ? -1 : 1
    );
    // console.log("SORTED ARRAY", sorted_companies);

    let sorted_companies_object = {};
    let sorted_companies = [];
    let total_matrix_length;
    let total_paid = 0;
    let total_current_value = 0;

    // Create sorted companies object
    pre_sorted_companies.map((asset) => {
        if (
            asset_allocation &&
            asset_allocation[asset.ticker] &&
            asset_allocation[asset.ticker].status
        ) {
            sorted_companies_object[asset.ticker] = asset;
            sorted_companies.push(asset);
        }
    });

    // Initiate matrix with first company
    // Transpose the matrix and multiply it by the number of shares
    let total_matrix = math.matrix(sorted_companies[0].stock_prices);
    total_matrix_length = sorted_companies[0].stock_prices.length;

    // console.log("TOTAL MATRIX LENGTH", total_matrix_length);

    let total_PerformanceMatrix = [
        math.transpose(math.column(total_matrix, 0)),
        math.transpose(math.column(total_matrix, 5)),
    ];

    // Multiply time by 1000 for charts
    total_PerformanceMatrix[0]._data[0] = math.multiply(
        total_PerformanceMatrix[0]._data[0],
        1000
    );

    // Multiply value by share count
    total_PerformanceMatrix[1]._data[0] = math.multiply(
        total_PerformanceMatrix[1]._data[0],
        asset_allocation[sorted_companies[0].ticker].count
    );

    // console.log("NEW START MATRIX", total_PerformanceMatrix);

    // Loop through the remaining companies
    // Add asset values to total_PerformanceMatrix
    sorted_companies.map((asset) => {
        if (asset.ticker !== sorted_companies[0].ticker) {
            // Initiate matrix for the current company
            // Transpose the matrix and multiply it by the number of shares
            let current_stock_prices = asset.stock_prices;
            let current_matrix_length = asset.stock_prices.length;
            // console.log("CURRENT MATRIX LENGTH", current_matrix_length);

            let current_matrix = math.matrix(current_stock_prices);

            let current_PerformanceMatrix = [
                math.transpose(math.column(current_matrix, 0)),
                math.transpose(math.column(current_matrix, 5)),
            ];

            current_PerformanceMatrix[1]._data[0] = math.multiply(
                current_PerformanceMatrix[1]._data[0],
                asset_allocation[asset.ticker].count
            );

            let current_prices_to_add;

            // Check if current stock prices are shorter than total
            if (current_matrix_length < total_matrix_length) {
                // Create placeholder with missing length
                let zerosPrices = math.zeros(
                    total_matrix_length - current_matrix_length
                )._data;
                // Create array for later addition

                // console.log("current_PerformanceMatrix[1]._data[0]", current_PerformanceMatrix[1]._data[0])
                current_prices_to_add = zerosPrices.concat(
                    current_PerformanceMatrix[1]._data[0]
                );
            } else {
                //
                current_prices_to_add = current_PerformanceMatrix[1]._data[0];
            }

            // Create time array placaholder for addition
            let zerosTime = math.zeros(total_matrix_length)._data;

            // console.log("CURRENT TOTAL MATRIX", total_PerformanceMatrix);

            let total_time = total_PerformanceMatrix[0]._data[0];
            let total_performance = total_PerformanceMatrix[1]._data[0];

            total_PerformanceMatrix = math.add(
                [[total_time], [total_performance]],
                [[zerosTime], [current_prices_to_add]]
            );
            total_PerformanceMatrix = [
                math.matrix(total_PerformanceMatrix[0]),
                math.matrix(total_PerformanceMatrix[1]),
            ];

            // console.log("CURRENT PRICES", current_prices_to_add);
        }
    });

    // Transform the matrix back to the right format
    let transform_matrix = math.matrix([
        total_PerformanceMatrix[0]._data[0],
        total_PerformanceMatrix[1]._data[0],
    ]);
    let performance_data = math.transpose(transform_matrix);

    let performance = performance_data._data.slice(
        performance_data._data.length - 800,
        performance_data._data.length
    );

    // console.log("FINAL RESULT", performance_data);

    let performance_return = {
        performance: performance,
        buy_in_total_price: total_paid,
    };

    return performance_return;
    // return performance = transformbitte._data;
};
