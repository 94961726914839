import React, { useEffect } from "react";
import { useIntl } from "react-intl";
// import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";

/*Dialog*/
import Dialog from "@mui/material/Dialog";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import { getBookmarks } from "../../../actions/bookmarkActions";

// Components
import BookmarkItem from "./BookmarkItem";

let compare_url;
const compare_url_creator = (bookmarks) => {
    compare_url = "?ticker=";
    if (bookmarks.length > 0) {
        bookmarks.map((bookmark, index) =>
            index < bookmarks.length - 1
                ? (compare_url += `${bookmark.ticker}_`)
                : (compare_url += `${bookmark.ticker}`)
        );
    }
};

const Bookmarks = ({ bookmark: { bookmarks }, getBookmarks }) => {
    const useintl = useIntl();

    useEffect(() => {
        if (localStorage.bookmarks) {
            getBookmarks();
        }
        // eslint-disable-next-line
    }, []);

    compare_url_creator(bookmarks);

    /*Tablecolumns Dialog Field*/
    const [openCompareCollection, setOpenCompareCollection] =
        React.useState(false);
    const handleClickOpenCompareCollection = () => {
        setOpenCompareCollection(true);
    };
    const handleCloseCompareCollection = () => {
        setOpenCompareCollection(false);
    };

    return (
        <div className='width_100pr height_100pr'>
            {bookmarks.length > 0 ? (
                <div className='border_box flex height_100pr'>
                    {/* MOBILE */}
                    <div className='display_lt768px height_100pr'>
                        <div className='pl_5px height_100pr flex'>
                            <div
                                className='button_ background_color_4 hover_opacity_80 text_color_1 text_3 width_140px height_30px height_80pr'
                                onClick={() => {
                                    handleClickOpenCompareCollection();
                                }}
                            >
                                {useintl.formatMessage({
                                    id: "general.view_bookmarks",
                                })}
                            </div>
                        </div>
                    </div>
                    {/* LARGER SCREENS */}
                    <div className='display_768px height_100pr overflow_x_auto scrollbar_invisible'>
                        <div className='flex'>
                            {bookmarks.map((item, index) => {
                                return <BookmarkItem item={item} key={index} />;
                            })}
                        </div>
                    </div>

                    <Dialog
                        open={openCompareCollection}
                        onClose={handleCloseCompareCollection}
                        maxWidth={500}
                    >
                        <div className='background_color_2'>
                            <div className='flex space_between width_100pr'>
                                <div className='pl_20px pt_20px text_1 flex'>
                                    {useintl.formatMessage({
                                        id: "general.bookmarks",
                                    })}
                                </div>
                                <div
                                    className='cursor_pointer title_4 flex center pr_15px pl_10px pt_20px'
                                    onClick={handleCloseCompareCollection}
                                >
                                    <AiIcons.AiOutlineClose />
                                </div>
                            </div>

                            <div className='divider mb_10px'></div>

                            <div className='border_box flex wrap ml_20px mr_20px mt_30px mb_30px'>
                                {bookmarks.map((item, index) => {
                                    return (
                                        <BookmarkItem item={item} key={index} />
                                    );
                                })}
                            </div>
                        </div>
                    </Dialog>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

Bookmarks.propTypes = {
    bookmark: PropTypes.object.isRequired,
    getBookmarks: PropTypes.func.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({
    bookmark: state.bookmark,
});

export default connect(mapStateToProps, { getBookmarks })(Bookmarks);
