import React, { useEffect } from "react";

import { useIntl } from "react-intl";

// Components
import Preloader from "../../../layout/Preloader";
import FeedbackIcon from "../../elements/FeedbackIcon";

// Actions
import { getPortfolioPerformance } from "../../../../actions/portfolioActions";

// Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

/*Actions*/
// import {  } from '../../../../actions/portfolioActions';

const PortfolioEstimatedPerformanceChart = ({
    portfolio_performance: {
        performance,
        buy_in_total_price,
        lin_reg,
        conf,
        conf_2,
        conf_3,
        linear_regression_1y,
        confidence_lower,
        confidence_upper,
    },
    portfolio_allocation,
    assets_array,
    getPortfolioPerformance,
}) => {
    const useintl = useIntl();
    useEffect(() => {
        if (
            window.location.pathname === "/creator" &&
            portfolio_allocation !== null &&
            assets_array !== null &&
            performance.length === 0
        ) {
            getPortfolioPerformance(
                portfolio_allocation,
                assets_array,
                "creator",
                null
            );
        }
        // eslint-disable-next-line
    }, []);

    let tClose = performance;

    const [estimation, setEstimation] = React.useState("linear_regression");

    let [timeFrame, setTimeFrame] = React.useState(4);

    let chartOptions = {
        chart: {
            height: "400px",
        },

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        rangeSelector: {
                            selected: 5,
                            buttons: [
                                {
                                    type: "month",
                                    count: 15,
                                    text: "3m",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
                {
                    condition: {
                        maxWidth: 1200,
                        minWidth: 401,
                    },
                    chartOptions: {
                        rangeSelector: {
                            // selected: 4,
                            buttons: [
                                {
                                    type: "month",
                                    count: 13,
                                    text: "1m",
                                },
                                {
                                    type: "month",
                                    count: 15,
                                    text: "3m",
                                },
                                {
                                    type: "month",
                                    count: 18,
                                    text: "6m",
                                },
                                {
                                    type: "year",
                                    count: 2,
                                    text: "1y",
                                },
                                {
                                    type: "year",
                                    count: 4,
                                    text: "3y",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
            ],
        },
        rangeSelector: {
            // selected: 4,
            buttons: [
                {
                    type: "month",
                    count: 13,
                    text: "1m",
                },
                {
                    type: "month",
                    count: 15,
                    text: "3m",
                },
                {
                    type: "month",
                    count: 18,
                    text: "6m",
                },
                {
                    type: "year",
                    count: 2,
                    text: "1y",
                },
                {
                    type: "year",
                    count: 4,
                    text: "3y",
                },
                {
                    type: "all",
                    text: "All",
                },
            ],
            buttonTheme: {
                width: 30,
                padding: 4,
            },
        },

        title: {
            text: "",
        },

        stockTools: {
            gui: {
                enabled: false,
            },
        },
        xAxis: {
            ordinal: false,
        },
        yAxis: [
            {
                type: "logarithmic",
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    enabled: false,
                    text: "Value",
                },
                height: "100%",
                lineWidth: 2,
                resize: {
                    enabled: true,
                },
            },
        ],
        navigator: {
            enabled: true,
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            text: " ",
        },
        tooltip: {
            shared: true,
        },
        series: [
            {
                type: "line",
                name: "Value",
                data: tClose,
                threshold: null,
                id: "stockprice",
                color: Highcharts.getOptions().colors[0],
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: "USD",
                },
                // dataGrouping: {
                //     units: [
                //         [
                //             'day',
                //             [1]
                //         ], [
                //             'week',
                //             [1]
                //         ],
                //         ["month", [1, 2, 3, 4, 6]],
                //     ],
                // },
            },
            // {
            //     type: "line",
            //     name: "lin. regression",
            //     color: "#1f74c4",
            //     data: lin_reg,
            //     zIndex: 4,
            //     tooltip: {
            //         valueDecimals: 2,
            //         valueSuffix: "USD",
            //     },
            // },
            {
                type: "arearange",
                name: "68.3% confidence",
                lineWidth: 0,
                color: "#185b9a",
                data: conf,
                zIndex: 3,
                // tooltip: {
                //     valueDecimals: 2,
                //     valueSuffix: "USD",
                // },
            },
            {
                type: "arearange",
                name: "95.4% confidence",
                lineWidth: 0,
                color: "#1f74c4",
                data: conf_2,
                zIndex: 2,
                // tooltip: {
                //     valueDecimals: 2,
                //     valueSuffix: "USD",
                // },
            },
            {
                type: "arearange",
                name: "99.7% confidence",
                lineWidth: 0,
                color: "#65a8e7",
                data: conf_3,
                zIndex: 1,
                // tooltip: {
                //     valueDecimals: 2,
                //     valueSuffix: "USD",
                // },
            },
        ],
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.portfolio",
        })} - ${useintl.formatMessage({
            id: "portfolio.manager.estimates",
        })}`,
        location_index: 0,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //         id: "general.portfolio",
    //     })} - ${useintl.formatMessage({
    //         id: "portfolio.manager.estimates",
    //     })}`,
    //     location_index: 0,
    // };

    return (
        <div>
            {!performance ||
            (performance && performance.length === 0) ||
            lin_reg === null ||
            conf === null ||
            conf_2 === null ||
            conf_3 === null ||
            linear_regression_1y === null ? (
                <Preloader />
            ) : (
                <div className='background_color_1 text_color_1 border_box'>
                    {window.location.pathname === "/portfolio" ? (
                        <FeedbackIcon feedbackInfo={feedbackInfo} />
                    ) : null}
                    {/* <div className='divider_no_margin_3px'></div> */}
                    <div className='flex space_between border_box pl_20px pr_20px pb_15px pt_15px'>
                        <div className='flex wrap'>
                            <div className='text_1 mr_15px'>
                                {useintl.formatMessage({
                                    id: `general.estimated_performance`,
                                })}
                            </div>
                            |{" "}
                            {/* {useintl.formatMessage({
                                id: `general.linear_regression`,
                            })}{" "} */}
                            linear regression of current company allocation
                        </div>
                        <div className='flex wrap'>
                            {linear_regression_1y -
                                performance[performance.length - 1][1] >=
                            0 ? (
                                <div className='text_color_green mr_10px'>
                                    +
                                    {Number(
                                        (
                                            linear_regression_1y -
                                            performance[
                                                performance.length - 1
                                            ][1]
                                        ).toPrecision(4)
                                    )}{" "}
                                    USD
                                </div>
                            ) : (
                                <div className='text_color_red mr_10px'>
                                    {Number(
                                        (
                                            linear_regression_1y -
                                            performance[
                                                performance.length - 1
                                            ][1]
                                        ).toPrecision(4)
                                    )}{" "}
                                    USD
                                </div>
                            )}
                            {linear_regression_1y -
                                performance[performance.length - 1][1] >=
                            0 ? (
                                <div className='text_color_green mr_10px'>
                                    (+
                                    {Number(
                                        (
                                            (linear_regression_1y /
                                                performance[
                                                    performance.length - 1
                                                ][1] -
                                                1) *
                                            100
                                        ).toPrecision(4)
                                    )}{" "}
                                    %)
                                </div>
                            ) : (
                                <div className='text_color_red mr_10px'>
                                    (
                                    {Number(
                                        (
                                            (linear_regression_1y /
                                                performance[
                                                    performance.length - 1
                                                ][1] -
                                                1) *
                                            100
                                        ).toPrecision(4)
                                    )}{" "}
                                    %)
                                </div>
                            )}
                            <div className='ml_10px'>
                                1{" "}
                                {useintl.formatMessage({
                                    id: `general.year`,
                                })}{" "}
                                (e)
                            </div>
                        </div>
                    </div>
                    {/* <div className='divider'></div> */}
                    {performance !== null ? (
                        <div className='profile_historic_price_max_height'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                                constructorType={"stockChart"}
                                containerProps={{ className: "chart" }}
                                className='profile_historic_price'
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

PortfolioEstimatedPerformanceChart.propTypes = {
    performance: PropTypes.array.isRequired,
    getPortfolioPerformance: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, { getPortfolioPerformance })(
    PortfolioEstimatedPerformanceChart
);
