import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as IoIcons5 from "react-icons/io5";
import { FormattedMessage, useIntl } from "react-intl";

// COMPONENTS
import FeedbackIcon from "../elements/FeedbackIcon";
import PortfolioRisk from "./PortfolioRisk";

// HIGHCHARTS
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import {
    getAverageMetric,
    numberFormatter,
} from "../../../actions/generalActions";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// MUI
import Dialog from "@mui/material/Dialog";

HighchartsMore(Highcharts);

function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

const Overview = ({
    description,
    portfolio_value,
    company_list,
    company_allocation,
    preferences,
    performance,
    user,
    screener: { slider },
    sidebar: { sidebar },
    // user,
    // requiredStatus,
}) => {
    const useintl = useIntl();
    const metrics = useContext(MetricsContext);

    // SIDESCROLL
    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    // SLIDE CLICK FUNCTION
    const slide = (shift) => {
        let new_scroll = scrollX + shift;
        if (new_scroll < 0) {
            scrl.current.scrollLeft = 0;
            new_scroll = 0;
        } else {
            scrl.current.scrollLeft += shift;
        }
        setscrollX(new_scroll);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    let SliderData = {};
    let AllMetrics = metrics.AllMetrics;
    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };
    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] = useState(SliderData);

    let [showStatistics, setShowStatistics] = useState("portfolio");

    const [donutInfo, setDonutInfo] = useState("chart");

    const [underLine, setUnderLine] = useState(1);

    const [sortOption, setSortOption] = useState("company");

    const handleShowStatistics = (ticker) => {
        if (sortOption === "company") {
            setShowStatistics(ticker);
        }
    };

    // COMPANY DESCRIPTION MODAL
    const [openDescriptionRow, setOpenDescriptionRow] = useState(false);
    const handleShowDescriptionRow = () => {
        setOpenDescriptionRow(true);
    };
    const handleCloseDescriptionRow = () => {
        setOpenDescriptionRow(false);
    };

    // RISK INFO
    const [openRiskInfo, setOpenRiskInfo] = React.useState(false);
    const handleClickOpenRiskInfo = () => {
        setOpenRiskInfo(true);
    };
    const handleCloseRiskInfo = () => {
        setOpenRiskInfo(false);
    };

    // RELATIVE SCORE INFO
    const [openRelativeScoreInfo, setOpenRelativeScoreInfo] =
        React.useState(false);
    const handleClickOpenRelativeScoreInfo = () => {
        setOpenRelativeScoreInfo(true);
    };
    const handleCloseRelativeScoreInfo = () => {
        setOpenRelativeScoreInfo(false);
    };

    // Donut Chart
    //
    //
    let asset_allocation = [];
    let assets_companies_object = {};
    let assets_industries_object = {};
    let assets_countries_object = {};

    let donut_colors = [
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
    ];

    let donut_colors_hover = [
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
    ];

    const handleSortOption = (value) => {
        setSortOption(value);
    };

    let company_object = {};

    // Sort by Companies
    if (company_list !== null) {
        let i = 0;
        company_list.map((company) => {
            company_object[company.ticker] = {
                ticker: company.ticker,
                name: company.name !== null ? company.name : company.ticker,
                dividend_yield:
                    company.dividend_yield !== null
                        ? Number(company.dividend_yield.toFixed(3))
                        : 0.0,
                description:
                    company.description !== null
                        ? company.description
                        : "description coming soon",
                sector: company.sector !== null ? company.sector : null,
                color: donut_colors[i],
                price_book_ranker:
                    company.price_book_ranker !== null
                        ? Number((company.price_book_ranker * 100).toFixed(2))
                        : null,
                debt_to_equity_ranker:
                    company.debt_to_equity_ranker !== null
                        ? Number(
                              (company.debt_to_equity_ranker * 100).toFixed(2)
                          )
                        : null,
                current_ratio_ranker:
                    company.current_ratio_ranker !== null
                        ? Number(
                              (company.current_ratio_ranker * 100).toFixed(2)
                          )
                        : null,
                asset_turnover_ranker:
                    company.asset_turnover_ranker !== null
                        ? Number(
                              (company.asset_turnover_ranker * 100).toFixed(2)
                          )
                        : null,
                revenue_growth_3y_ranker:
                    company.revenue_growth_3y_ranker !== null
                        ? Number(
                              (company.revenue_growth_3y_ranker * 100).toFixed(
                                  2
                              )
                          )
                        : null,
                revenue_growth_1y_ranker:
                    company.revenue_growth_1y_ranker !== null
                        ? Number(
                              (company.revenue_growth_1y_ranker * 100).toFixed(
                                  2
                              )
                          )
                        : null,
                price_sales_ranker:
                    company.price_sales_ranker !== null
                        ? Number((company.price_sales_ranker * 100).toFixed(2))
                        : null,
                price_sales_growth_ranker:
                    company.price_sales_growth_ranker !== null
                        ? Number(
                              (company.price_sales_growth_ranker * 100).toFixed(
                                  2
                              )
                          )
                        : null,
                price_earnings_growth_ranker:
                    company.price_earnings_growth_ranker !== null
                        ? Number(
                              (
                                  company.price_earnings_growth_ranker * 100
                              ).toFixed(2)
                          )
                        : null,
                gross_profit_margin_ranker:
                    company.gross_profit_margin_ranker !== null
                        ? Number(
                              (
                                  company.gross_profit_margin_ranker * 100
                              ).toFixed(2)
                          )
                        : null,
                debt_ratio_ranker:
                    company.debt_ratio_ranker !== null
                        ? Number((company.debt_ratio_ranker * 100).toFixed(2))
                        : null,
                stock_prices:
                    company.stock_prices !== null ? company.stock_prices : null,
                beta:
                    company.beta !== null
                        ? Number(company.beta.toFixed(2))
                        : null,
                market_cap_usd:
                    company.market_cap_usd !== null
                        ? numberFormatter(
                              Number(company.market_cap_usd.toFixed(2))
                          )
                        : null,
                relative_score:
                    company.relative_score !== null
                        ? Number(company.relative_score.toFixed(2))
                        : null,
                piotroski_score:
                    company.piotroski_score !== null
                        ? Number(company.piotroski_score.toFixed(2))
                        : null,
            };
            if (
                company_allocation !== null &&
                company_allocation[company.ticker] &&
                company_allocation[company.ticker].status
            ) {
                assets_companies_object[company.ticker] = {
                    name: company.name,
                    g: company.ticker,
                    a:
                        company.revenue_growth_1y !== null
                            ? Number(company.revenue_growth_1y.toPrecision(4))
                            : "-",
                    b:
                        company.earnings_growth_1y !== null
                            ? Number(company.earnings_growth_1y.toPrecision(4))
                            : "-",
                    d: "company",
                    e: Number(
                        (
                            (100 * company_allocation[company.ticker].value) /
                            portfolio_value
                        ).toPrecision(4)
                    ),
                    x:
                        company.dividend_yield !== null
                            ? Number(
                                  (
                                      0.01 *
                                      company.dividend_yield *
                                      company_allocation[company.ticker].value
                                  ).toPrecision(4)
                              )
                            : "-",
                    y: Number(
                        company_allocation[company.ticker].value.toPrecision(4)
                    ),
                    z:
                        company.dividend_yield !== null
                            ? Number(company.dividend_yield.toPrecision(4))
                            : "-",
                    color: donut_colors[i],
                    f: donut_colors_hover[i],
                };
            }
            i += 1;
        });
    }

    // Sort by Industries
    if (company_list !== null) {
        let i = 0;
        company_list.map((company) => {
            if (
                company_allocation !== null &&
                company_allocation[company.ticker] &&
                company_allocation[company.ticker].status &&
                !assets_industries_object[company.sector]
            ) {
                // Falls true und Industrie noch nicht im Object
                assets_industries_object[company.sector] = {
                    name: company.sector,
                    a:
                        company.revenue_growth_1y !== null
                            ? Number(company.revenue_growth_1y.toPrecision(4))
                            : "-",
                    b:
                        company.earnings_growth_1y !== null
                            ? Number(company.earnings_growth_1y.toPrecision(4))
                            : "-",
                    d: "sector",
                    e: Number(
                        (
                            (100 * company_allocation[company.ticker].value) /
                            portfolio_value
                        ).toPrecision(4)
                    ),
                    x:
                        company.dividend_yield !== null
                            ? Number(
                                  (
                                      0.01 *
                                      company.dividend_yield *
                                      company_allocation[company.ticker].value
                                  ).toPrecision(4)
                              )
                            : "-",
                    y: company_allocation[company.ticker].value,
                    z:
                        company.dividend_yield !== null
                            ? Number(company.dividend_yield.toPrecision(4))
                            : "-",
                    color: donut_colors[i],
                    f: donut_colors_hover[i],
                };
            } else if (
                company_allocation !== null &&
                company_allocation[company.ticker] &&
                company_allocation[company.ticker].status
            ) {
                // Falls true und Industrie bereits im Object

                let pre_total_value =
                    assets_industries_object[company.sector].y;

                // Total Dividend per Industry
                if (
                    company.dividend_yield !== null &&
                    assets_industries_object[company.sector].x !== "-"
                ) {
                    assets_industries_object[company.sector].x += Number(
                        (
                            0.01 *
                            company.dividend_yield *
                            company_allocation[company.ticker].value
                        ).toPrecision(4)
                    );
                }

                // Total Value per Industry
                assets_industries_object[company.sector].y +=
                    company_allocation[company.ticker].value;

                assets_industries_object[company.sector].e = Number(
                    (
                        (100 * assets_industries_object[company.sector].y) /
                        portfolio_value
                    ).toPrecision(4)
                );

                // Averaged Dividend Yield
                if (
                    company.dividend_yield !== null &&
                    assets_industries_object[company.sector].z !== "-"
                ) {
                    assets_industries_object[company.sector].z = Number(
                        (
                            (100 * assets_industries_object[company.sector].x) /
                            assets_industries_object[company.sector].y
                        ).toPrecision(4)
                    );
                } else if (assets_industries_object[company.sector].z === "-") {
                    assets_industries_object[company.sector].z = "-";
                }

                // Averaged Earnings Growth per Industry
                if (
                    company.earnings_growth_1y !== null &&
                    assets_industries_object[company.sector].b !== "-"
                ) {
                    assets_industries_object[company.sector].b = Number(
                        (
                            (pre_total_value /
                                assets_industries_object[company.sector].y) *
                                assets_industries_object[company.sector].b +
                            (company_allocation[company.ticker].value /
                                assets_industries_object[company.sector].y) *
                                company.earnings_growth_1y
                        ).toPrecision(4)
                    );
                } else if (
                    assets_industries_object[company.sector].b === "-" ||
                    company.earnigns_growth_1y === null
                ) {
                    assets_industries_object[company.sector].b = "-";
                }

                // Averaged Revenue Growth per Industry
                if (
                    company.revenue_growth_1y !== null &&
                    assets_industries_object[company.sector].a !== "-"
                ) {
                    assets_industries_object[company.sector].a = Number(
                        (
                            (pre_total_value /
                                assets_industries_object[company.sector].y) *
                                assets_industries_object[company.sector].a +
                            (company_allocation[company.ticker].value /
                                assets_industries_object[company.sector].y) *
                                company.revenue_growth_1y
                        ).toPrecision(4)
                    );
                } else if (
                    assets_industries_object[company.sector].a === "-" ||
                    company.earnigns_growth_1y === null
                ) {
                    assets_industries_object[company.sector].a = "-";
                }
            }
            i += 1;
        });
    }

    // SORT BY COUNTRIES
    //
    // if (company_list !== null) {
    //     let i = 0;
    //     company_list.map((company) => {
    //         if (
    //             company_allocation[company.ticker].status &&
    //             !assets_countries_object[
    //                 company_allocation[company.ticker].country
    //             ]
    //         ) {
    //             // Falls true und Industrie noch nicht im Object
    //             assets_countries_object[
    //                 company_allocation[company.ticker].country
    //             ] = {
    //                 name: company_allocation[company.ticker].country,
    //                 a:
    //                     company.revenue_growth_1y !== null
    //                         ? Number(company.revenue_growth_1y.toPrecision(4))
    //                         : "-",
    //                 b:
    //                     company.earnings_growth_1y !== null
    //                         ? Number(company.earnings_growth_1y.toPrecision(4))
    //                         : "-",
    //                 c: (
    //                     <ReactCountryFlag
    //                         className='mr_10px'
    //                         countryCode={company.country}
    //                         svg
    //                     />
    //                 ),
    //                 d: "country",
    //                 e: Number(
    //                     (
    //                         (100 * company_allocation[company.ticker].value) /
    //                         portfolio_value
    //                     ).toPrecision(4)
    //                 ),
    //                 x:
    //                     company.dividend_yield !== null
    //                         ? Number(
    //                               (
    //                                   0.01 *
    //                                   company.dividend_yield *
    //                                   company_allocation[company.ticker].value
    //                               ).toPrecision(4)
    //                           )
    //                         : "-",
    //                 y: company_allocation[company.ticker].value,
    //                 z:
    //                     company.dividend_yield !== null
    //                         ? Number(company.dividend_yield.toPrecision(4))
    //                         : "-",
    //                 color: donut_colors[i],
    //                 f: donut_colors_hover[i],
    //             };
    //         } else if (company_allocation[company.ticker].status) {
    //             // Falls true und Industrie bereits im Object

    //             let pre_total_value =
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].y;

    //             // Total Dividend per Industry
    //             if (
    //                 company.dividend_yield !== null &&
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].x !== "-"
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].x += Number(
    //                     (
    //                         0.01 *
    //                         company.dividend_yield *
    //                         company_allocation[company.ticker].value
    //                     ).toPrecision(4)
    //                 );
    //             }

    //             // Total Value per Industry
    //             assets_countries_object[
    //                 company_allocation[company.ticker].country
    //             ].y += company_allocation[company.ticker].value;

    //             assets_countries_object[
    //                 company_allocation[company.ticker].country
    //             ].e = Number(
    //                 (
    //                     (100 *
    //                         assets_countries_object[
    //                             company_allocation[company.ticker].country
    //                         ].y) /
    //                     portfolio_value
    //                 ).toPrecision(4)
    //             );

    //             // Averaged Dividend Yield
    //             if (
    //                 company.dividend_yield !== null &&
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].z !== "-"
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].z = Number(
    //                     (
    //                         (100 *
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].x) /
    //                         assets_countries_object[
    //                             company_allocation[company.ticker].country
    //                         ].y
    //                     ).toPrecision(4)
    //                 );
    //             } else if (
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].z === "-"
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].z = "-";
    //             }

    //             // Averaged Earnings Growth per Industry
    //             if (
    //                 company.earnings_growth_1y !== null &&
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].b !== "-"
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].b = Number(
    //                     (
    //                         (pre_total_value /
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].y) *
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].b +
    //                         (company_allocation[company.ticker].value /
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].y) *
    //                             company.earnings_growth_1y
    //                     ).toPrecision(4)
    //                 );
    //             } else if (
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].b === "-" ||
    //                 company.earnigns_growth_1y === null
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].b = "-";
    //             }

    //             // Averaged Revenue Growth per Industry
    //             if (
    //                 company.revenue_growth_1y !== null &&
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].a !== "-"
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].a = Number(
    //                     (
    //                         (pre_total_value /
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].y) *
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].a +
    //                         (company_allocation[company.ticker].value /
    //                             assets_countries_object[
    //                                 company_allocation[company.ticker].country
    //                             ].y) *
    //                             company.revenue_growth_1y
    //                     ).toPrecision(4)
    //                 );
    //             } else if (
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].a === "-" ||
    //                 company.earnigns_growth_1y === null
    //             ) {
    //                 assets_countries_object[
    //                     company_allocation[company.ticker].country
    //                 ].a = "-";
    //             }
    //         }
    //         i += 1;
    //     });
    // }

    // Problemtofix: Flags instead of country code
    // company_allocation[company.ticker].country

    // <ReactCountryFlag
    //     className='mr_10px'
    //     countryCode={country.value}
    //     svg
    // />

    // Sort Objects by size

    let sorted_tickers_company = Object.keys(assets_companies_object).sort(
        function (a, b) {
            return assets_companies_object[b].y - assets_companies_object[a].y;
        }
    );
    // let sorted_tickers_country = Object.keys(assets_countries_object).sort(
    //     function (a, b) {
    //         return assets_countries_object[b].y - assets_countries_object[a].y;
    //     }
    // );
    let sorted_tickers_industry = Object.keys(assets_industries_object).sort(
        function (a, b) {
            return (
                assets_industries_object[b].y - assets_industries_object[a].y
            );
        }
    );

    let sorted_company_array = [];
    sorted_tickers_company.map((ticker) => {
        sorted_company_array.push(company_object[ticker]);
    });

    // Create Array out of Object for Piechart
    if (sortOption === "company") {
        sorted_tickers_company.map((ticker) => {
            asset_allocation.push(assets_companies_object[ticker]);
        });
    }
    // if (sortOption === "country") {
    //     sorted_tickers_country.map((ticker) => {
    //         asset_allocation.push(assets_countries_object[ticker]);
    //     });
    // }
    if (sortOption === "sector") {
        sorted_tickers_industry.map((ticker) => {
            asset_allocation.push(assets_industries_object[ticker]);
        });
    }

    // DONUT CHART DIVERSIFICATION

    let chartOptions = {
        chart: {
            type: "pie",
            backgroundColor: "rgb(0,0,0,0)",
            margin: [0, 0, 0, 0],
            spacingTop: 10,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        },
        title: {
            // text: `${useintl.formatMessage({
            //     id: "general.total_value",
            // })}<br><b>${Number(portfolio_value.toPrecision(4))}</b> USD`,
            text: `Current value<br><b>${Number(
                portfolio_value.toPrecision(4)
            )}</b> USD`,
            align: "center",
            verticalAlign: "middle",
            y: 20,
            style: {
                fontSize: "16px",
            },
        },
        exporting: {
            enabled: false,
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -125,
                    y: -5,
                    format: "{y}%",
                    style: {
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                    },
                },
                size: "95%",
                borderWidth: 3,
            },
            series: {
                animation: false,
            },
        },
        // tooltip: {
        //     enabled: false,
        // },
        tooltip: {
            pointFormat:
                sortOption === "company"
                    ? `{series.name}<br/> Position: <b>{point.y}</b><br/>${useintl.formatMessage(
                          {
                              id: "general.portfolio_share",
                          }
                      )}: <b>{point.e} %</b><br/>`
                    : // : sortOption === "country"
                    // ? `{series.name}<br/> Position: <b>{point.y}</b><br/>${useintl.formatMessage(
                    //       {
                    //           id: "general.portfolio_share",
                    //       }
                    //   )}: <b>{point.e} %</b><br/>`
                    sortOption === "sector"
                    ? `{series.name}<br/> Position: <b>{point.y}</b><br/>${useintl.formatMessage(
                          {
                              id: "general.portfolio_share",
                          }
                      )}: <b>{point.e} %</b><br/>`
                    : null,

            shared: true,
        },
        series: [
            {
                type: "pie",
                name: "",
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: " USD",
                },
                innerSize: "65%",
                data: asset_allocation,
                // Change Selected Company to hovered one
                // point: {
                //     events: {
                //         mouseOver: function () {
                //             if (this.d === "company") {
                //                 handleShowStatistics(this.g);
                //             }
                //         },
                //     },
                // },
                // // chart.series[0].data.map.onMouseOver();
            },
        ],
    };

    let dividend_yield_avg = getAverageMetric(
        company_list,
        company_allocation,
        "dividend_yield"
    );
    let beta_avg = getAverageMetric(company_list, company_allocation, "beta");
    let market_cap_usd_avg = getAverageMetric(
        company_list,
        company_allocation,
        "market_cap_usd"
    );
    let relative_score_avg = getAverageMetric(
        company_list,
        company_allocation,
        "relative_score"
    );
    let piotroski_score_avg = getAverageMetric(
        company_list,
        company_allocation,
        "piotroski_score"
    );

    // SPIDER CHART
    let price_book_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "price_book_ranker"
        ) * 100;
    let price_sales_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "price_sales_ranker"
        ) * 100;
    let asset_turnover_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "asset_turnover_ranker"
        ) * 100;
    let gross_profit_margin_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "gross_profit_margin_ranker"
        ) * 100;
    let revenue_growth_1y_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "revenue_growth_1y_ranker"
        ) * 100;
    let revenue_growth_3y_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "revenue_growth_3y_ranker"
        ) * 100;
    let debt_to_equity_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "debt_to_equity_ranker"
        ) * 100;
    let current_ratio_ranker_avg =
        getAverageMetric(
            company_list,
            company_allocation,
            "current_ratio_ranker"
        ) * 100;

    let statistics = {
        performance_1_year: performance
            ? Number(
                  (
                      (performance.performance[
                          performance.performance.length - 1
                      ][1] /
                          performance.performance[
                              performance.performance.length - 250
                          ][1] -
                          1) *
                      100
                  ).toPrecision(4)
              )
            : "-",
        dividend_yield_avg:
            dividend_yield_avg !== null
                ? Number(dividend_yield_avg.toFixed(2))
                : null,
        beta_avg: beta_avg !== null ? Number(beta_avg.toFixed(2)) : null,
        market_cap_usd_avg:
            market_cap_usd_avg !== null
                ? numberFormatter(Number(market_cap_usd_avg.toFixed(2)))
                : null,
        relative_score_avg:
            relative_score_avg !== null
                ? Number(relative_score_avg.toFixed(2))
                : null,
        piotroski_score_avg:
            piotroski_score_avg !== null
                ? Number(piotroski_score_avg.toFixed(2))
                : null,
    };

    let polarChartOptions = {};
    if (showStatistics !== "portfolio") {
        polarChartOptions = {
            chart: {
                polar: true,
            },
            title: {
                text: "",
                // x: -80,
            },
            xAxis: {
                categories: [
                    useintl.formatMessage({
                        id: "metrics.price_book",
                    }),
                    useintl.formatMessage({
                        id: "metrics.price_sales",
                    }),
                    useintl.formatMessage({
                        id: "metrics.asset_turnover",
                    }),
                    useintl.formatMessage({
                        id: "metrics.gross_profit_margin",
                    }),
                    useintl.formatMessage({
                        id: "metrics.revenue_growth_1y",
                    }),
                    useintl.formatMessage({
                        id: "metrics.revenue_growth_3y",
                    }),
                    useintl.formatMessage({
                        id: "metrics.debt_to_equity",
                    }),
                    useintl.formatMessage({
                        id: "metrics.current_ratio",
                    }),
                ],
                tickmarkPlacement: "on",
                lineWidth: 0,
            },
            yAxis: {
                gridLineInterpolation: "polygon",
                lineWidth: 0,
                min: 0,
                max: 100,
            },
            exporting: {
                enabled: false,
            },
            stockTools: {
                gui: {
                    enabled: false,
                },
            },
            legend: {
                disabled: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: `Sector ranking: <b>{point.y} %</b></b><br/>`,
            },
            series: [
                {
                    // type: "area",
                    showInLegend: false,
                    data: [
                        company_object[showStatistics].price_book_ranker,
                        company_object[showStatistics].price_sales_ranker,
                        company_object[showStatistics].asset_turnover_ranker,
                        company_object[showStatistics]
                            .gross_profit_margin_ranker,
                        company_object[showStatistics].revenue_growth_1y_ranker,
                        company_object[showStatistics].revenue_growth_3y_ranker,
                        company_object[showStatistics].debt_to_equity_ranker,
                        company_object[showStatistics].current_ratio_ranker,
                    ],
                    pointPlacement: "on",
                },
            ],
        };
    } else {
        polarChartOptions = {
            chart: {
                polar: true,
            },
            title: {
                text: "",
                // x: -80,
            },
            xAxis: {
                categories: [
                    useintl.formatMessage({
                        id: "metrics.price_book",
                    }),
                    useintl.formatMessage({
                        id: "metrics.price_sales",
                    }),
                    useintl.formatMessage({
                        id: "metrics.asset_turnover",
                    }),
                    useintl.formatMessage({
                        id: "metrics.gross_profit_margin",
                    }),
                    useintl.formatMessage({
                        id: "metrics.revenue_growth_1y",
                    }),
                    useintl.formatMessage({
                        id: "metrics.revenue_growth_3y",
                    }),
                    useintl.formatMessage({
                        id: "metrics.debt_to_equity",
                    }),
                    useintl.formatMessage({
                        id: "metrics.current_ratio",
                    }),
                ],
                tickmarkPlacement: "on",
                lineWidth: 0,
            },
            yAxis: {
                gridLineInterpolation: "polygon",
                lineWidth: 0,
                min: 0,
                max: 100,
            },
            exporting: {
                enabled: false,
            },
            stockTools: {
                gui: {
                    enabled: false,
                },
            },
            legend: {
                disabled: false,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: `Sector ranking: <b>{point.y} %</b></b><br/>`,
            },
            series: [
                {
                    // type: "area",
                    showInLegend: false,
                    data: [
                        price_book_ranker_avg !== null
                            ? Number(price_book_ranker_avg.toFixed(2))
                            : null,
                        price_sales_ranker_avg !== null
                            ? Number(price_sales_ranker_avg.toFixed(2))
                            : null,
                        asset_turnover_ranker_avg !== null
                            ? Number(asset_turnover_ranker_avg.toFixed(2))
                            : null,
                        gross_profit_margin_ranker_avg !== null
                            ? Number(gross_profit_margin_ranker_avg.toFixed(2))
                            : null,
                        revenue_growth_1y_ranker_avg !== null
                            ? Number(revenue_growth_1y_ranker_avg.toFixed(2))
                            : null,
                        revenue_growth_3y_ranker_avg !== null
                            ? Number(revenue_growth_3y_ranker_avg.toFixed(2))
                            : null,
                        debt_to_equity_ranker_avg !== null
                            ? Number(debt_to_equity_ranker_avg.toFixed(2))
                            : null,
                        current_ratio_ranker_avg !== null
                            ? Number(current_ratio_ranker_avg.toFixed(2))
                            : null,
                    ],
                    pointPlacement: "on",
                },
            ],
        };
    }

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.portfolio",
        })} - ${useintl.formatMessage({
            id: "portfolio.manager.manager",
        })}`,
        location_index: 0,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //         id: "general.portfolio",
    //     })} - ${useintl.formatMessage({
    //         id: "portfolio.manager.manager",
    //     })}`,
    //     location_index: 0,
    // };

    return (
        <div>
            {window.location.pathname === "/portfolio" ? (
                <FeedbackIcon feedbackInfo={feedbackInfo} />
            ) : null}
            {/* HEADER */}
            <div className='flex space_between background_color_1 text_color_1 border_box padding_20px'>
                <div className='flex'>
                    <div className='text_1 shown_asset_allocation'>
                        {/* <FormattedMessage
                            id='general.asset_allocation'
                            defaultMessage='Asset Allocation'
                        /> */}
                    </div>
                </div>
                <div className='flex'>
                    {/* <AiIcons.AiOutlineFullscreen />
                    <AiIcons.AiOutlineInfoCircle
                        className='screener_icon_info cursor_pointer'
                        // onClick={handleClickOpenInfo}
                    /> */}
                </div>
            </div>
            {/* <div className='divider_no_margin'></div> */}
            {company_list !== null ? (
                <div className='pos_relative'>
                    {/* SCROLL NAVIGATION */}
                    {(window.innerWidth < 2410 && sidebar) ||
                    (window.innerWidth < 2160 && !sidebar) ? (
                        <div className='pos_absolute width_100pr height_480px'>
                            <div className='flex space_between height_100pr z_index_max mt_20px mb_20px'>
                                {scrollX !== 0 ? (
                                    <div
                                        className='prev cursor_pointer border_radius_15px_top_right border_radius_15px_bottom_right height_100pr flex flex_column center background_color_4 width_30px_20px hover_opacity_60'
                                        onClick={() =>
                                            slide(
                                                window.innerWidth < 768
                                                    ? -360
                                                    : -540
                                            )
                                        }
                                    >
                                        <MdIcons.MdChevronLeft className='width_30px height_40px text_color_1' />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {!scrolEnd ? (
                                    <div
                                        className='prev cursor_pointer border_radius_15px_top_left border_radius_15px_bottom_left height_100pr flex flex_column center background_color_4 width_30px_20px hover_opacity_60'
                                        onClick={() =>
                                            slide(
                                                window.innerWidth < 768
                                                    ? +360
                                                    : +540
                                            )
                                        }
                                    >
                                        <MdIcons.MdChevronRight className='width_30px height_40px text_color_1' />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* CAROUSEL */}
                    <div className='pos_relative ms_30px'>
                        <div
                            className='flex overflow_x_auto scroll_behavior_smooth ml_5px mr_5px border_box'
                            ref={scrl}
                            onScroll={scrollCheck}
                        >
                            {/* ALLOCATION */}
                            <div className='min_width_500px_320px max_width_700px width_95pr height_480px flex background_color_2 mr_20px mt_20px mb_20px ps_5px shadow_dark border_radius_15px border_box'>
                                <div className='pos_relative min_width_500px_320px max_width_700px flex flex_column space_around height_480px_auto width_100pr padding_auto'>
                                    <div className='pos_absolute flex center width_100pr height_100pr background_color_2 pt_20px ps_5px border_radius_15px'>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                    </div>

                                    {/* setShowSortOption */}

                                    <div className='cursor_pointer pb_10px'>
                                        <div className='flex wrap center'>
                                            <div
                                                className={
                                                    underLine === 1
                                                        ? "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_blue"
                                                        : "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_1"
                                                }
                                                onClick={() => {
                                                    handleSortOption("company");
                                                    setUnderLine(1);
                                                }}
                                            >
                                                {useintl.formatMessage({
                                                    id: "general.by_company",
                                                })}
                                            </div>
                                            {/* <div
                                                className={
                                                    underLine === 2
                                                        ? "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_blue"
                                                        : "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_1"
                                                }
                                                onClick={() => {
                                                    handleSortOption("country");
                                                    setUnderLine(2);
                                                }}
                                            >
                                                <IoIcons5.IoFlagOutline className='mr_5px' />
                                                {useintl.formatMessage({
                                                    id: "general.by_country",
                                                })}
                                            </div> */}
                                            <div
                                                className={
                                                    underLine === 3
                                                        ? "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_blue"
                                                        : "page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_3 text_color_1"
                                                }
                                                onClick={() => {
                                                    handleSortOption("sector");
                                                    setUnderLine(3);
                                                }}
                                            >
                                                <IoIcons5.IoBusinessOutline className='mr_5px' />
                                                {useintl.formatMessage({
                                                    id: "general.by_sector",
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* COMPANIES LIST */}
                            <div className='text_2 min_width_500px_320px max_width_700px width_95pr overflow_y_auto height_480px background_color_2 margin_20px ps_5px pt_10px pb_5px shadow_dark border_radius_15px border_box'>
                                {sorted_tickers_company.map(function (
                                    key,
                                    index
                                ) {
                                    return (
                                        <div>
                                            {showStatistics === key ? (
                                                <div className='flex space_between bg_color_grey_1 border_radius_5px pl_15px pr_5px'>
                                                    <div className='flex left'>
                                                        <div
                                                            className='circle mr_15px mb_3px mt_3px ml_5px cursor_pointer'
                                                            onClick={() => {
                                                                handleShowStatistics(
                                                                    key
                                                                );
                                                            }}
                                                            style={{
                                                                backgroundColor:
                                                                    company_object[
                                                                        key
                                                                    ].color,
                                                            }}
                                                        ></div>
                                                        <div
                                                            className='mb_3px mt_3px cursor_pointer text_left color_blue'
                                                            onClick={() => {
                                                                handleShowStatistics(
                                                                    key
                                                                );
                                                            }}
                                                        >
                                                            {truncate(
                                                                company_object[
                                                                    key
                                                                ].name,
                                                                39
                                                            )}
                                                        </div>
                                                    </div>

                                                    <AiIcons.AiOutlineInfoCircle
                                                        className='screener_icon_info text_2 cursor_pointer mr_5px ml_20px'
                                                        onClick={() => {
                                                            handleShowStatistics(
                                                                key
                                                            );
                                                            handleShowDescriptionRow();
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div className='flex space_between'>
                                                    <div className='flex left'>
                                                        <div
                                                            className='circle ml_10px mr_15px mb_3px mt_3px cursor_pointer'
                                                            onClick={() => {
                                                                handleShowStatistics(
                                                                    key
                                                                );
                                                            }}
                                                            style={{
                                                                backgroundColor:
                                                                    company_object[
                                                                        key
                                                                    ].color,
                                                            }}
                                                        ></div>
                                                        <div
                                                            className='mb_3px mt_3px text_left cursor_pointer'
                                                            onClick={() => {
                                                                handleShowStatistics(
                                                                    key
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                company_object[
                                                                    key
                                                                ].name
                                                            }
                                                        </div>
                                                    </div>

                                                    <AiIcons.AiOutlineInfoCircle
                                                        className='screener_icon_info text_2 cursor_pointer mr_10px ml_20px'
                                                        onClick={() => {
                                                            handleShowStatistics(
                                                                key
                                                            );
                                                            handleShowDescriptionRow();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {/* SPIDER CHART SCORE */}
                            <div className='min_width_500px_320px width_500px_320px max_width_700px width_95pr height_480px background_color_2 margin_20px shadow_dark border_radius_15px border_box'>
                                <div className='width_100pr height_480px flex flex_column space_between'>
                                    {/* Statistics Header */}
                                    <div className='text_2 flex border_box width_100pr'>
                                        {/* {showStatistics !== 'portfolio' ? ( */}
                                        <div className='flex width_100pr'>
                                            {showStatistics !== "portfolio" ? (
                                                <div
                                                    className='width_50pr text_center cursor_pointer background_color_3 text_color_1 pt_3px pb_3px border_1px_solid_black border_radius_15px_top_left'
                                                    onClick={() => {
                                                        setShowStatistics(
                                                            "portfolio"
                                                        );
                                                    }}
                                                >
                                                    Portfolio
                                                </div>
                                            ) : (
                                                <div
                                                    className='width_50pr text_center cursor_pointer background_color_1 color_black pt_3px pb_3px border_1px_solid_black border_radius_15px_top_left'
                                                    onClick={() => {
                                                        setShowStatistics(
                                                            "portfolio"
                                                        );
                                                    }}
                                                >
                                                    <div className='text_color_blue'>
                                                        Portfolio
                                                    </div>
                                                </div>
                                            )}
                                            {showStatistics !== "portfolio" ? (
                                                <div
                                                    className='width_50pr flex center cursor_pointer background_color_1 wrap text_center pt_3px pb_3px border_1px_solid_black border_radius_15px_top_right'
                                                    onClick={
                                                        handleShowDescriptionRow
                                                    }
                                                >
                                                    <div className='text_color_blue mr_15px'>
                                                        {truncate(
                                                            company_object[
                                                                showStatistics
                                                            ].name,
                                                            20
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='width_50pr flex center wrap text_center background_color_3 pt_3px pb_3px border_1px_solid_black border_radius_15px_top_right'>
                                                    <div className=' text_color_1 cursor_pointer mr_15px'>
                                                        {"-"}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Company Description Modal */}
                                    <Dialog
                                        open={openDescriptionRow}
                                        onClose={handleCloseDescriptionRow}
                                        maxWidth={false}
                                        className='width_70pr_95pr max_width_700px ms_auto'
                                    >
                                        <div>
                                            {showStatistics !== "portfolio" ? (
                                                <div>
                                                    {/* headerline */}
                                                    <div className='background_color_2 flex space_between title_5 pt_10px pb_10px pl_15px pr_15px'>
                                                        <div className='flex'>
                                                            <div>
                                                                {company_object[
                                                                    showStatistics
                                                                ].name
                                                                    ? `${company_object[showStatistics].name}   -   `
                                                                    : null}
                                                                {showStatistics
                                                                    ? `${showStatistics}`
                                                                    : null}
                                                            </div>
                                                            {company_object[
                                                                showStatistics
                                                            ].sector ? (
                                                                <div className='flex'>
                                                                    <div className='ml_20px'>
                                                                        |
                                                                    </div>
                                                                    <div className='ml_20px'>
                                                                        {company_object[
                                                                            showStatistics
                                                                        ].sector
                                                                            ? company_object[
                                                                                  showStatistics
                                                                              ]
                                                                                  .sector
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            className='cursor_pointer title_5 flex center mt_5px'
                                                            onClick={
                                                                handleCloseDescriptionRow
                                                            }
                                                        >
                                                            <AiIcons.AiOutlineClose />
                                                        </div>
                                                    </div>
                                                    <div className='divider_no_margin'></div>
                                                    {/* description */}
                                                    <div className='background_color_2 padding_15px ps_auto text_block width_100pr'>
                                                        {company_object[
                                                            showStatistics
                                                        ].description
                                                            ? company_object[
                                                                  showStatistics
                                                              ].description
                                                            : "Description coming soon"}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Dialog>

                                    {/* Statistics Content */}
                                    {donutInfo === "table" ? (
                                        <div className='width_100pr'>
                                            {/* <div className='divider_no_margin_dark'></div> */}
                                            <div className='overflow_x_auto ml_20px mr_20px'>
                                                <table className='width_100pr'>
                                                    <thead>
                                                        <tr className='pl_20px pr_20px'>
                                                            <th className='text_left min_width_120px'>
                                                                <FormattedMessage
                                                                    id='general.metric'
                                                                    defaultMessage='Metric'
                                                                />
                                                            </th>
                                                            <th className='text_left min_width_80px'>
                                                                <FormattedMessage
                                                                    id='general.value'
                                                                    defaultMessage='Value'
                                                                />
                                                            </th>
                                                            <th className='text_left min_width_40px'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    <FormattedMessage
                                                                        id='metrics.market_cap'
                                                                        defaultMessage='Market Cap.'
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='metrics.market_cap'
                                                                        defaultMessage='Market Cap.'
                                                                    />
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.market_cap_usd_avg
                                                                    }
                                                                    {" USD"}
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {
                                                                        company_object[
                                                                            showStatistics
                                                                        ]
                                                                            .market_cap_usd
                                                                    }
                                                                    {" USD"}
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    {/* <FormattedMessage
                                                                        id='general.annual_growth'
                                                                        defaultMessage='1 year performance'
                                                                    /> */}
                                                                    1 year
                                                                    performance
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {/* <FormattedMessage
                                                                        id='general.annual_growth'
                                                                        defaultMessage='1 year performance'
                                                                    /> */}
                                                                    1 year
                                                                    performance
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.performance_1_year
                                                                    }{" "}
                                                                    %
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {Number(
                                                                        (
                                                                            (company_object[
                                                                                showStatistics
                                                                            ]
                                                                                .stock_prices[
                                                                                company_object[
                                                                                    showStatistics
                                                                                ]
                                                                                    .stock_prices
                                                                                    .length -
                                                                                    1
                                                                            ][5] /
                                                                                company_object[
                                                                                    showStatistics
                                                                                ]
                                                                                    .stock_prices[
                                                                                    company_object[
                                                                                        showStatistics
                                                                                    ]
                                                                                        .stock_prices
                                                                                        .length -
                                                                                        250
                                                                                ][5] -
                                                                                1) *
                                                                            100
                                                                        ).toPrecision(
                                                                            4
                                                                        )
                                                                    )}{" "}
                                                                    %
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    <FormattedMessage
                                                                        id='metrics.beta'
                                                                        defaultMessage='Beta'
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='metrics.beta'
                                                                        defaultMessage='Beta'
                                                                    />
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.beta_avg
                                                                    }{" "}
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {
                                                                        company_object[
                                                                            showStatistics
                                                                        ].beta
                                                                    }{" "}
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    <FormattedMessage
                                                                        id='metrics.dividend_yield'
                                                                        defaultMessage='Dividend Yield'
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='metrics.dividend_yield'
                                                                        defaultMessage='Dividend Yield'
                                                                    />
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.dividend_yield_avg
                                                                    }{" "}
                                                                    %
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {
                                                                        company_object[
                                                                            showStatistics
                                                                        ]
                                                                            .dividend_yield
                                                                    }{" "}
                                                                    %
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    <FormattedMessage
                                                                        id='metrics.relative_score'
                                                                        defaultMessage='Relative Score'
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='metrics.relative_score'
                                                                        defaultMessage='Relative Score'
                                                                    />
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.relative_score_avg
                                                                    }{" "}
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {
                                                                        company_object[
                                                                            showStatistics
                                                                        ]
                                                                            .relative_score
                                                                    }{" "}
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='general.avg'
                                                                        defaultMessage='Ø'
                                                                    />{" "}
                                                                    <FormattedMessage
                                                                        id='metrics.piotroski_score'
                                                                        defaultMessage='Piotroski F-Score'
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    <FormattedMessage
                                                                        id='metrics.piotroski_score'
                                                                        defaultMessage='Piotroski F-Score'
                                                                    />
                                                                </td>
                                                            )}
                                                            {showStatistics ===
                                                            "portfolio" ? (
                                                                <td className='text_left'>
                                                                    {
                                                                        statistics.piotroski_score_avg
                                                                    }
                                                                </td>
                                                            ) : (
                                                                <td className='text_left'>
                                                                    {
                                                                        company_object[
                                                                            showStatistics
                                                                        ]
                                                                            .piotroski_score
                                                                    }
                                                                </td>
                                                            )}
                                                            <td className='text_right'>
                                                                {/* <AiIcons.AiOutlineInfoCircle
                                                                    className='screener_icon_info text_2 cursor_pointer'
                                                                    onClick={
                                                                        handleShowDescriptionRow
                                                                    }
                                                                /> */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='width_100pr'>
                                            <HighchartsReact
                                                className='background_color_3'
                                                highcharts={Highcharts}
                                                options={polarChartOptions}
                                            />
                                        </div>
                                    )}

                                    {/* Statistics Footer */}
                                    {donutInfo === "chart" ? (
                                        <div className='flex width_100pr'>
                                            <div className='width_50pr flex center cursor_pointer text_color_blue background_color_1 border_1px_solid_black border_radius_15px_bottom_left'>
                                                <IoIcons5.IoBarChartOutline className='text_1 mt_5px mb_5px' />
                                            </div>
                                            <div
                                                className='width_50pr flex center cursor_pointer background_color_3 text_color_1 border_1px_solid_black border_radius_15px_bottom_right'
                                                onClick={() =>
                                                    setDonutInfo("table")
                                                }
                                            >
                                                <AiIcons.AiOutlineTable className='text_1 mt_5px mb_5px color_black' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='flex width_100pr'>
                                            <div
                                                className='width_50pr flex center cursor_pointer background_color_3 text_color_1 border_1px_solid_black border_radius_15px_bottom_left'
                                                onClick={() =>
                                                    setDonutInfo("chart")
                                                }
                                            >
                                                <IoIcons5.IoBarChartOutline className='text_1 mt_5px mb_5px color_black' />
                                            </div>
                                            <div className='width_50pr flex center cursor_pointer background_color_1 text_color_blue border_1px_solid_black border_radius_15px_bottom_right'>
                                                <AiIcons.AiOutlineTable className='text_1 mt_5px mb_5px ' />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* RISK ALLOCATION */}
                            {user.status === 0 ? (
                                <div className='pos_relative min_width_500px_320px max_width_700px width_95pr overflow_y_auto height_480px mt_20px mb_20px ml_20px shadow_dark border_radius_15px border_box'>
                                    <div className='pos_absolute width_100pr height_100pr blur background_color_2 ps_5px'>
                                        <PortfolioRisk
                                            company_list={company_list}
                                            company_allocation={
                                                company_allocation
                                            }
                                            user_free={true}
                                        />
                                    </div>
                                    <div className='pos_absolute width_100pr height_100pr flex center'>
                                        <div className='flex flex_column center width_220px height_100px background_color_3 border_radius_15px shadow_dark'>
                                            <div className='text_2'>
                                                Portoflio risk allocation
                                            </div>
                                            <Link
                                                to={`/account`}
                                                className='hover_opacity_80'
                                            >
                                                Upgrade
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ) : user.simplified_view ? null : (
                                <div className='pos_relative min_width_500px_320px max_width_700px width_95pr overflow_y_auto height_480px background_color_2 mt_20px mb_20px ml_20px ps_5px shadow_dark border_radius_15px border_box'>
                                    {/* HEADER  */}
                                    <div className='padding_10px flex text_1 pos_absolute'>
                                        <div className='text_1'>
                                            {" "}
                                            Risk Allocation
                                        </div>
                                        <div className='text_2 flex right ml_10px mt_5px'>
                                            <AiIcons.AiOutlineInfoCircle
                                                className='cursor_pointer'
                                                onClick={
                                                    handleClickOpenRiskInfo
                                                }
                                            />
                                        </div>
                                    </div>

                                    {/* RISK INFO */}
                                    <Dialog
                                        open={openRiskInfo}
                                        onClose={handleCloseRiskInfo}
                                        maxWidth={false}
                                        className='slider_info_wrapper'
                                    >
                                        <div className='slider_info_container background_color_3 text_color_1'>
                                            <div className='slider_info_header title_5'>
                                                <div className='flex'>
                                                    <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px ' />
                                                    {/* {sliderprops.displayName} */}
                                                    <div className='mr_10px'>
                                                        Risk Allocation
                                                    </div>
                                                </div>
                                                <AiIcons.AiOutlineClose
                                                    className='title_5 cursor_pointer mt_5px'
                                                    onClick={
                                                        handleCloseRiskInfo
                                                    }
                                                />
                                            </div>

                                            {/* <SliderInfoBox sliderprops={sliderprops} /> */}
                                            <div className='divider_no_margin'></div>
                                            <div className='flex flex_column align_items_start margin_10px'>
                                                <div className='title_6 flex'>
                                                    <IoIcons5.IoBookOutline className='mr_10px' />
                                                    {useintl.formatMessage({
                                                        id: `metrics.infobox.section_1.header`,
                                                    })}
                                                </div>
                                                <div className='margin_10px'>
                                                    {useintl.formatMessage({
                                                        id: `explanation.extrinsic_risk`,
                                                    })}
                                                </div>
                                                <div className='title_6'>
                                                    The indices are:
                                                </div>
                                                {/* SPY, SHY, VCSH, GSG, CPI_US */}
                                                <div className='margin_10px width_100pr'>
                                                    <div className='flex mb_10px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Type
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            Ticker
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            Name
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Equity risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            SPY
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            S&P 500 Index
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Interest rate risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            SHY
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            1-3 Year Treasury
                                                            Bond ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Credit risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            VCSH
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            Short-Term Corporate
                                                            Bond ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Commodities risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            GSG
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            GSCI
                                                            Commodity-Indexed
                                                            Trust ETF
                                                        </div>
                                                    </div>
                                                    <div className='flex mb_5px'>
                                                        <div className='width_25pr min_width_100px'>
                                                            Inflation risk
                                                        </div>
                                                        <div className='width_15pr min_width_40px'>
                                                            -
                                                        </div>
                                                        <div className='width_60pr min_width_140px'>
                                                            US consumer price
                                                            index
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='divider_no_margin'></div> */}
                                        </div>
                                    </Dialog>

                                    <div className='flex flex_column  width_100pr space_around width_500px_320px'>
                                        <PortfolioRisk
                                            company_list={company_list}
                                            company_allocation={
                                                company_allocation
                                            }
                                            user_free={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : company_list !== null ? (
                "Go Premium"
            ) : null}
            {/* {user.status >= requiredStatus ? null : (
                <div className='bg_color_grey_1'>
                    <div className='divider_no_margin'></div>
                    <div className='width_100pr flex center pt_10px pb_10px border_box'>
                        <div className='text_center text_2'>
                            <p>This is an exemplary Portfolio</p>
                            <p>To get your own individual results</p>
                            <div className='flex center mt_10px'>
                                <div className='button_green width_120px color_white'>
                                    Go Premium
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

Overview.propTypes = {
    user: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    performance: PropTypes.object.isRequired,
    requiredStatus: PropTypes.number.isRequired,
    sidebar: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    screener: state.screener,
    sidebar: state.sidebar,
});

export default connect(mapStateToProps, null)(Overview);
