import React from "react";

// ICONS
import * as AiIcons from "react-icons/ai";

// MUI
import Dialog from "@mui/material/Dialog";

// PAGE INFORMATION STATE
// const [showPageInfo, setShowPageInfo] = React.useState(false);
// const handleOpenPageInfo = () => {
//     setShowPageInfo(true);
// };
// const handleClosePageInfo = () => {
//     setShowPageInfo(false);
// };

// COPY PASTE USE
{
    /* <AiIcons.AiOutlineInfoCircle
    className='text_2 hover_translate_up ml_15px mt_5px'
    onClick={() => {
        handleOpenPageInfo();
    }}
/> */
}
{
    /* <PageInfoModalSimple
    infoHeader={"The screener explained"}
    infoBody={
        "The screener allows you to ..."
    }
    showPageInfo={showPageInfo}
    handleClosePageInfo={handleClosePageInfo}
/> */
}

const PageInfoModalSimple = ({
    showPageInfo,
    handleClosePageInfo,
    infoHeader,
    infoBody,
}) => {
    return (
        <div>
            <Dialog
                open={showPageInfo}
                onClose={handleClosePageInfo}
                maxWidth={false}
                className='border_radius_15px'
            >
                <div className='max_width_500px background_color_2 text_color_1 border_radius_15px'>
                    <div className='flex space_between title_4 mt_10px mb_10px ml_15px mr_15px'>
                        <div className='mr_10px'>{infoHeader}</div>
                        <div
                            className='cursor_pointer title_4 flex center ml_25px mt_5px'
                            onClick={handleClosePageInfo}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>
                    <div className='divider_no_margin'></div>
                    <div className='width_100pr padding_10px'>{infoBody}</div>
                </div>
            </Dialog>
        </div>
    );
};

export default PageInfoModalSimple;
