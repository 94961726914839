import React from "react";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Highcharts
import SliderInfoBox from "../../screener/sliderinfo/SliderInfoBox";
// import SliderHistogram from "../../screener/sliderinfo/SliderHistogram";
import MetricHistogramAsset from "../../elements/MetricHistogramAsset";

// Intl
import { useIntl } from "react-intl";

// Actions
import { numberFormatter } from "../../../../actions/generalActions";

/*Dialog*/
import Dialog from "@mui/material/Dialog";
import { typeOf } from "mathjs";

const MetricAnalysisBar = ({
    sliderprops,
    screener: { slider },
    user,
    company,
    metric,
    metric_ranker,
}) => {
    const useintl = useIntl();

    const [value, setValue] = React.useState(sliderprops.value);
    /*Sliders Information Modal*/
    const [openInfo, setOpenInfo] = React.useState(false);
    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };
    const handleCloseInfo = () => {
        setOpenInfo(false);
    };
    /*Sliders Histogram Modal*/
    const [openHistogram, setOpenHistogram] = React.useState(false);
    const handleClickOpenHistogram = () => {
        setOpenHistogram(true);
    };
    const handleCloseHistogram = () => {
        setValue(slider[sliderprops.property].value);
        setOpenHistogram(false);
    };

    // SHOW FURTHER INFO ON HOVER
    const [ranker_hovered, setRanker_hovered] = React.useState(false);
    const handleHoverIn = () => {
        setRanker_hovered(true);
    };
    const handleHoverOut = () => {
        setRanker_hovered(false);
    };

    if (metric !== null && typeOf(metric)) {
        metric = Number(Number(metric).toFixed(2));
    } else {
        metric = "-";
    }

    if (metric_ranker !== null) {
        metric_ranker = Number((metric_ranker * 100).toFixed(2));
    } else {
        metric_ranker = "-";
    }

    let histogram = {
        name: useintl.formatMessage({
            id: `metrics.${sliderprops.property}`,
        }),
        raw_value:
            company[sliderprops.property] !== null
                ? Number(Number(company[sliderprops.property]).toPrecision(4))
                : null,
        value:
            company[sliderprops.property] !== null
                ? `${Number(
                      Number(company[sliderprops.property]).toPrecision(4)
                  )}`
                : "-",
        crit: null,
        histogram_name: sliderprops.displayName,
        histogram_value: sliderprops.property,
        histogram_unit: sliderprops.unit,
        histogram_change: false,
    };

    return (
        <div className='background_color_5 width_100pr min_width_300px max_width_1500px mb_15px'>
            <div className='flex wrap pl_10px'>
                {/* NAME, FIGURE, INFO (width_40pr_100pr) */}
                <div className='flex space_between width_100pr min_width_300px'>
                    {/* NAME */}
                    <div className='text_3 width_30pr_50pr min_width_150px'>
                        {sliderprops.displayName}
                    </div>
                    {/* VALUE */}
                    {sliderprops.property === "market_cap" ||
                    sliderprops.property === "market_cap_usd" ||
                    sliderprops.property === "ev" ? (
                        <div className='width_50pr_30pr min_width_50px flex wrap left'>
                            <div className='mr_10px text_3'>
                                {numberFormatter(metric) +
                                    `${sliderprops.unit}`}
                            </div>
                            {metric_ranker ? (
                                <div className='text_5 pr_5px text_color_1'>
                                    Ranker: {metric_ranker} %
                                </div>
                            ) : null}
                        </div>
                    ) : metric !== "-" ? (
                        <div className='width_50pr_30pr min_width_50px flex wrap left'>
                            <div className='mr_10px text_3'>
                                {metric + `${sliderprops.unit}`}
                            </div>
                            {metric_ranker ? (
                                <div className='text_5 pr_5px text_color_1'>
                                    Ranker: {metric_ranker} %
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div className='width_50pr_30pr min_width_50px flex wrap left'>
                            <div className='mr_10px text_3'>{metric}</div>
                            {metric_ranker ? (
                                <div className='text_5 pr_5px text_color_1'>
                                    Ranker: {metric_ranker} %
                                </div>
                            ) : null}
                        </div>
                    )}
                    {/* INFO ICONS */}
                    <div className='text_2 width_20pr min_width_50px flex right pr_10px'>
                        <AiIcons.AiOutlineFullscreen
                            className='cursor_pointer'
                            onClick={handleClickOpenHistogram}
                        />
                        <AiIcons.AiOutlineInfoCircle
                            className='cursor_pointer'
                            onClick={handleClickOpenInfo}
                        />
                    </div>
                </div>
                {/* RANKER (width_60pr_100pr) */}
                <div className='width_100pr min_width_300px pr_10px '>
                    <div
                        className='width_100pr'
                        onMouseOver={handleHoverIn}
                        onMouseLeave={handleHoverOut}
                    >
                        {/* BARCHART */}
                        {!isNaN(metric_ranker) ? (
                            <div className='pos_relative flex right width_100pr border_1px_solid_grey left mb_5px height_12px'>
                                <div
                                    className={
                                        user.simplified_view
                                            ? "pos_absolute background_color_gradient_red_yellow_green width_100pr height_10px"
                                            : "pos_absolute background_color_blue width_100pr height_10px"
                                    }
                                >
                                    <div className='text_color_white text_right text_5 mt_-3px pr_5px'>
                                        {/* {metric_ranker} % */}
                                    </div>
                                </div>

                                <div
                                    className='pos_absolute background_color_5'
                                    style={{
                                        width: `${100 - metric_ranker}%`,
                                        height: "10px",
                                    }}
                                >
                                    <div className='text_color_white text_right text_5 mt_-3px pr_5px'>
                                        {/* {metric_ranker} % */}
                                    </div>
                                </div>
                                {/* <div
                                className='pos_absolute width_100pr mt_-5px'
                                // style={{
                                //     width: `${
                                //         metric_ranker
                                //     }%`,
                                // }}
                            >
                                {ranker_hovered &&
                                !isNaN(metric_ranker) &&
                                metric_ranker > 20 ? (
                                    <div className='width_100pr height_100pr flex left pos_relative '>
                                        <div className='pos_absolute ml_10px text_5 pr_5px mt_5px text_color_white'>
                                            Ranker: {metric_ranker}{" "}
                                            %
                                        </div>
                                    </div>
                                ) : ranker_hovered &&
                                  !isNaN(metric_ranker) &&
                                  metric_ranker <= 20 ? (
                                    <div className='width_100pr height_100pr flex left pos_relative '>
                                        <div className='pos_absolute pl_5px text_5 pr_5px mt_5px text_color_white'>
                                            Ranker: {metric_ranker}{" "}
                                            %
                                        </div>
                                    </div>
                                ) : null}
                            </div> */}
                            </div>
                        ) : (
                            <div className='border_box flex width_100pr left mb_5px'>
                                <div
                                    className='background_color_blue'
                                    style={{
                                        width: `0%`,
                                        height: "10px",
                                    }}
                                >
                                    <div className='text_color_white text_right text_5 mt_-3px pr_5px'>
                                        {/* {metric_ranker} % */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* </div> */}
                    </div>
                </div>

                {/* INFO */}
                <Dialog
                    open={openInfo}
                    onClose={handleCloseInfo}
                    maxWidth={false}
                    className='slider_info_wrapper'
                >
                    <div className='slider_info_container background_color_3 text_color_1'>
                        <div className='slider_info_header title_5'>
                            <div className='flex'>
                                <AiIcons.AiOutlineInfoCircle className='title_5 mr_10px mt_5px' />
                                {sliderprops.displayName}
                            </div>
                            <AiIcons.AiOutlineClose
                                className='title_5 cursor_pointer'
                                onClick={handleCloseInfo}
                            />
                        </div>

                        <SliderInfoBox sliderprops={sliderprops} />

                        <div className='divider_no_margin'></div>
                        <div className='slider_info_disclaimer margin_10px'>
                            <p>
                                To get an <b>overview</b> on typical values for
                                this metric, click{" "}
                                <b
                                    className='cursor_pointer'
                                    onClick={() => {
                                        handleCloseInfo();
                                        handleClickOpenHistogram();
                                    }}
                                >
                                    here
                                </b>
                            </p>
                        </div>
                    </div>
                </Dialog>

                {/* HISTOGRAM */}
                <Dialog
                    open={openHistogram}
                    onClose={handleCloseHistogram}
                    maxWidth={false}
                    className='slider_info_wrapper'
                >
                    <div className='slider_info_container background_color_3 text_color_1'>
                        <div className='slider_info_header title_5'>
                            <div className='flex'>
                                <RiIcons.RiBarChartBoxLine className='title_5 mr_10px mt_5px' />
                                {sliderprops.displayName}
                            </div>
                            <AiIcons.AiOutlineClose
                                className='title_5 cursor_pointer'
                                onClick={handleCloseHistogram}
                            />
                        </div>
                        <MetricHistogramAsset histogramprops={histogram} />
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

MetricAnalysisBar.propTypes = {
    user: PropTypes.object.isRequired,
    sliderprops: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    user: state.user,
});

export default connect(mapStateToProps, {})(MetricAnalysisBar);
