import { combineReducers } from "redux";

// // Import Reducers
import sidebarReducer from "./sidebarReducer";
import alertReducer from "./alertReducer";
import bookmarkReducer from "./bookmarkReducer";
import portfolioReducer from "./portfolioReducer";
import watchlistReducer from "./watchlistReducer";
import screenerReducer from "./screenerReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import compareReducer from "./compareReducer";
import generalReducer from "./generalReducer";
import marketReducer from "./marketReducer";

export default combineReducers({
    bookmark: bookmarkReducer,
    alerts: alertReducer,
    sidebar: sidebarReducer,
    portfolio: portfolioReducer,
    watchlist: watchlistReducer,
    screener: screenerReducer,
    user: userReducer,
    company: companyReducer,
    compare: compareReducer,
    general: generalReducer,
    market: marketReducer,
});
