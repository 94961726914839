import { SET_SIDEBAR } from '../actions/Types';

const initialState = {
    sidebar: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR:
            return {
                ...state,
                sidebar: !state.sidebar,
            };

        default:
            return state;
    }
};
