import React from "react";
import { propTypes } from "react-country-flag";

// ACTIONS
import { numberFormatterfour } from "../../../actions/generalActions";
import { openSectorMetricHistory } from "../../../actions/marketActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

let not_sortable = ["sector"];
let not_metrics = [
    "page",
    "limit",
    "search",
    "searchOption",
    "sort",
    "industry",
    "esg",
    "stock_exchange",
    "sector",
    "description",
    "name",
    "currency",
    "country",
    "market_cap",
    "market_cap_usd",
];
let returns_metrics = [
    "daily_return",
    "weekly_return",
    "monthly_return",
    "quarterly_return",
    "semi_annual_return",
    "annual_return",
    "two_year_return",
    "three_year_return",
];

const SectorTableRowColumn = ({ sector, metric, openSectorMetricHistory }) => {
    /*Sector information Dialog Field*/
    const handleClickOpenSectorMetricHistory = () => {
        openSectorMetricHistory(sector.sector, metric);
    };

    if (not_metrics.includes(metric)) {
        return null;
    } else if (returns_metrics.includes(metric)) {
        return (
            <td className='padding_15_5px'>
                {!isNaN(sector[metric]) &&
                typeof sector[metric] === "number" ? (
                    <div
                        className={
                            Number(sector[metric]) >= 0
                                ? "text_color_green flex right"
                                : "text_color_red flex right"
                        }
                    >
                        <div>
                            {numberFormatterfour(
                                Number.parseFloat(sector[metric]).toPrecision(4)
                            )}
                        </div>
                        <div className='ml_5px'>{" %"}</div>
                    </div>
                ) : (
                    <div className='text_color_1 text_right'>-</div>
                )}
            </td>
        );
    } else if (typeof sector[metric] === "number" && metric === "ev") {
        return (
            <td className='padding_15_5px text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(sector[metric]).toPrecision(4)
                )}{" "}
                USD
            </td>
        );
    } else if (
        typeof sector[metric] === "number" &&
        metric.includes("ranker")
    ) {
        return (
            <td className='padding_15_5px text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(sector[metric]).toPrecision(4)
                )}
            </td>
        );
    } else if (typeof sector[metric] === "number" && metric !== "market_cap") {
        return (
            <td
                className='padding_15_5px text_color_1 cursor_pointer text_right'
                onClick={handleClickOpenSectorMetricHistory}
            >
                {numberFormatterfour(
                    Number.parseFloat(sector[metric]).toPrecision(4)
                )}{" "}
                {metric === "stock_price" ? "USD" : null}
            </td>
        );
    } else if (typeof sector[metric] === "string" && metric !== "market_cap") {
        return (
            <td className='padding_15_5px text_color_1 text_right'>
                {sector[metric]}
            </td>
        );
    } else if (sector[metric] === null) {
        return <td className='padding_15_5px text_color_1 text_right'>-</td>;
    } else {
        return null;
    }
};

SectorTableRowColumn.propTypes = {
    // market: PropTypes.object.isRequired,
    openSectorMetricHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    // market: state.market,
});

export default connect(mapStateToProps, { openSectorMetricHistory })(
    SectorTableRowColumn
);
