import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Preloader from "../../layout/Preloader";

// ACTIONS
import { getSingleHistogramData } from "../../../actions/screenerAction";
import { numberFormatter } from "../../../actions/generalActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// HIGHCHARTS
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";

// MUI
// import Slider from '@mui/material/Slider';
import Tooltip from "@mui/material/Tooltip";

/* Sliders Tooltip Styling */
function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={open}
            enterTouchDelay={0}
            placement='top'
            title={value}
        >
            {children}
        </Tooltip>
    );
}
ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

bellcurve(Highcharts);

// Problemtofix: Bei 'Change' Zahlen -1 * 100, sonst nicht!

const MetricHistogramAsset = ({
    histogramprops,
    getSingleHistogramData,
    histogram,
}) => {
    // let data = [];
    const useintl = useIntl();
    useEffect(() => {
        if (histogram.statistics) {
            if (histogramprops.histogram_value !== histogram.statistics.id) {
                getSingleHistogramData(histogramprops.histogram_value);
                // data = histogram.values;
            }
        } else {
            getSingleHistogramData(histogramprops.histogram_value);
            // data = histogram.values;
        }
        // eslint-disable-next-line
    }, []);

    let base_metric;

    if (histogramprops.histogram_change) {
        base_metric = histogramprops.histogram_value.replace("_change", "");
    }

    if (
        !histogram.values ||
        histogram.statistics.metric !== histogramprops.histogram_value
    ) {
        return <Preloader />;
    } else {
        let value_array = histogram.values;

        let bin_width = histogram.statistics.bin_width;

        let new_quantile_50 = histogram.statistics.quantile_50;

        let histogram_legend = {
            blue: useintl.formatMessage({
                id: `histogram.number_of_companies_in_a_given_range`,
            }),
            green:
                window.location.pathname === "/portfolio"
                    ? useintl.formatMessage({
                          id: `histogram.this_portfolios_value`,
                      })
                    : useintl.formatMessage({
                          id: `histogram.this_companys_value`,
                      }),
            red: useintl.formatMessage({
                id: `histogram.median_value`,
            }),
        };

        let chartOptions = {
            title: {
                text: useintl.formatMessage({
                    id: `general.frequency_distribution`,
                }),
                visible: false,
            },
            credits: {
                enabled: false,
            },

            xAxis: [
                {
                    labels: {
                        formatter: function () {
                            return numberFormatter(this.value);
                        },
                    },
                    title: { text: histogramprops.histogram_name },
                    alignTicks: false,
                    opposite: true,
                },
                {
                    alignTicks: false,
                    // visible: false,
                    plotLines: [
                        {
                            color: "red", // Color value
                            dashStyle: "longdashdot", // Style of the plot line. Default to solid
                            value: new_quantile_50, // Value of where the line will appear
                            width: 2, // Width of the line
                            zIndex: 2,
                        },
                        {
                            color: "green", // Color value
                            dashStyle: "longdashdot", // Style of the plot line. Default to solid
                            value: histogramprops.raw_value, // Value of where the line will appear
                            width: 2, // Width of the line
                            zIndex: 2,
                        },
                    ],
                },
            ],

            yAxis: [
                {
                    title: { text: "Data" },
                    visible: false,
                    opposite: true,
                    showInLegend: false,
                },
                {
                    title: {
                        text: useintl.formatMessage({
                            id: `histogram.number_of_companies`,
                        }),
                    },
                },
            ],

            plotOptions: {
                histogram: {
                    accessibility: {
                        pointDescriptionFormatter: function (point) {
                            var ix = point.index + 1,
                                x1 = numberFormatter(
                                    Number(point.x.toFixed(3))
                                ),
                                x2 = numberFormatter(
                                    Number(point.x2.toFixed(3))
                                ),
                                val = point.y;
                            return (
                                ix + ". " + x1 + " to " + x2 + ", " + val + "."
                            );
                        },
                    },
                },
            },
            tooltip: {
                pointFormat: `Companies: <b>{point.y}</b></br>Range: <b>{point.x}</b> to <b>{point.x2}</b>`,
                shared: false,
                valueDecimals: 2,
            },
            legend: {
                enabled: false,
            },

            series: [
                {
                    type: "histogram",
                    xAxis: 1,
                    yAxis: 1,
                    // binWidth: bin_width,
                    // binsNumber: 50,
                    baseSeries: "s1",
                    zIndex: -1,
                },
                {
                    name: "Data",
                    type: "scatter",
                    data: value_array,
                    visible: false,
                    showInLegend: false,
                    id: "s1",
                    marker: {
                        radius: 1.5,
                    },
                },
            ],
        };
        return (
            <div>
                <div className='divider_no_margin'></div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />

                <div className='divider_no_margin'></div>
                <div className='histogram_legend ml_20px mt_10px'>
                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_blue'></div>
                        <div className='ml_10px'> {histogram_legend.blue}</div>
                    </div>

                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_red'></div>
                        <div className='ml_10px'> {histogram_legend.red}</div>
                    </div>

                    <div className='flex'>
                        <div className='histogram_legend_circle background_color_green'></div>
                        <div className='ml_10px'> {histogram_legend.green}</div>
                    </div>
                </div>
                <div className='divider_no_margin'></div>
                <div className='slider_info_typical_values'>
                    <div className='title_5'>
                        {useintl.formatMessage({
                            id: `general.statistics`,
                        })}
                    </div>
                    <table class='mt_5px'>
                        <thead>
                            <tr>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_25`,
                                    })}
                                </th>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.median`,
                                    })}
                                </th>
                                {/* <th className='text_left'>Mean</th> */}
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_75`,
                                    })}
                                </th>
                                <th className='text_left border_left_1px_solid text_right'>
                                    {window.location.pathname === "/portfolio"
                                        ? useintl.formatMessage({
                                              id: `general.portfolio`,
                                          })
                                        : useintl.formatMessage({
                                              id: `general.company`,
                                          })}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='histogram_statistics_table_body_row'>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {numberFormatter(
                                        Number(
                                            histogram.statistics.quantile_25.toPrecision(
                                                4
                                            )
                                        )
                                    )}{" "}
                                    {histogramprops.histogram_unit}
                                </td>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {numberFormatter(
                                        Number(
                                            histogram.statistics.quantile_50.toPrecision(
                                                4
                                            )
                                        )
                                    )}{" "}
                                    {histogramprops.histogram_unit}
                                </td>
                                {/* <td className='text_left histogram_statistics_table_body_column'>
                                    {Number(
                                              histogram.statistics.mean.toPrecision(
                                                  4
                                              )
                                          )}
                                    {histogramprops.histogram_unit}
                                </td> */}
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {numberFormatter(
                                        Number(
                                            histogram.statistics.quantile_75.toPrecision(
                                                4
                                            )
                                        )
                                    )}{" "}
                                    {histogramprops.histogram_unit}
                                </td>
                                <td className='text_left histogram_statistics_table_body_column border_left_1px_solid text_right'>
                                    {numberFormatter(histogramprops.raw_value)}{" "}
                                    {histogramprops.histogram_unit}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='slider_info_disclaimer'>
                    <div className='margin_10px'>
                        <IoIcons5.IoBookOutline className='mr_10px' />
                        Frequency distribution is the term generally used to
                        describe the numbers that indicate how often a
                        particular value has occurred in a statistical analysis.
                        In the chart above, you can see how often a certain
                        range occurs in our database, based on a key figure. In
                        other words, how many companies have this key figure in
                        the specified range.
                    </div>
                    {histogramprops.histogram_change ? (
                        <div className='margin_10px'>
                            <BsIcons.BsExclamationTriangle className='mr_10px' />
                            {`The 'Change' of a metric equals to the relative change in the last trailing twelve months. In this particular case: ${useintl.formatMessage(
                                {
                                    id: `metrics.${base_metric}`,
                                }
                            )} ${
                                histogramprops.raw_value > 0
                                    ? "increased"
                                    : "decreased"
                            } by ${histogramprops.raw_value} ${
                                histogramprops.histogram_unit
                            }.`}
                        </div>
                    ) : null}
                    <div className='margin_10px'>
                        <BsIcons.BsExclamationTriangle className='mr_10px' />
                        The data shown above represents all the companies of our
                        database. Minimum and maximum values are capped in both
                        directions.
                    </div>
                </div>
            </div>
        );
    }
};

MetricHistogramAsset.propTypes = {
    histogram: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    histogram: state.screener.histogram,
});

export default connect(mapStateToProps, {
    getSingleHistogramData,
})(MetricHistogramAsset);
