import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import { FormattedMessage } from "react-intl";

/*Transitions*/
// import { CSSTransition, TransitionGroup } from "react-transition-group";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setSidebar } from "../../../actions/sidebarActions";
import SidebarPortfolios from "./SidebarPortfolios";
import SidebarWatchlists from "./SidebarWatchlists";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const marketsubNav = [
    {
        title: (
            <FormattedMessage id='sidemenu.indices' defaultMessage='Indices' />
        ),
        path: "/market/indices",
        icon: <IoIcons.IoIosPaper />,
    },
    {
        title: (
            <FormattedMessage
                id='sidemenu.industries'
                defaultMessage='Industries'
            />
        ),
        path: "/market/industries",
        icon: <IoIcons.IoIosPaper />,
    },
];

const accountsubNav = [
    {
        title: <FormattedMessage id='logout.logout' defaultMessage='Logout' />,
        icon: <IoIcons.IoIosPaper />,
    },
];

const SideSubMenu = ({ item, setSidebar }) => {
    const location = useLocation();

    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    const [subnav2, setSubnav2] = useState(false);

    const showSubnav2 = () => setSubnav2(!subnav2);

    const showSidebar = () => {
        setSidebar();
    };

    return (
        <div className='sidesubmenu_height sidesubmenu_width flex flex_column space_between'>
            <div className='height_95pr overflow_y_auto flex flex_column width_100pr'>
                {/* MARKET */}
                <Link
                    to={``}
                    className={
                        location.pathname === "/"
                            ? "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer selected_menu_hover"
                            : "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer menu_hover"
                    }
                    onClick={() => {
                        if (window.innerWidth < 1224) {
                            showSidebar();
                        }
                    }}
                >
                    <MdIcons.MdEqualizer className='text_1 mr_20px' />
                    <div className='text_2'>
                        <FormattedMessage
                            id='sidemenu.market'
                            defaultMessage='Market'
                        />
                    </div>
                </Link>

                {/* Portfolios */}
                <SidebarPortfolios />

                {/* Creator */}
                <Link
                    to={`/creator`}
                    className={
                        location.pathname === "/creator"
                            ? "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer selected_menu_hover"
                            : "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer menu_hover"
                    }
                    onClick={() => {
                        if (window.innerWidth < 1224) {
                            showSidebar();
                        }
                    }}
                >
                    <MdIcons.MdCreate className='text_1 mr_20px' />
                    <div className='text_2'>
                        <FormattedMessage
                            id='sidemenu.portfolio_creator'
                            defaultMessage='Portfolio Creator'
                        />
                    </div>
                </Link>

                {/* Watchlists */}
                <SidebarWatchlists />

                {/* Screener */}
                <Link
                    to={`/screener`}
                    className={
                        location.pathname === "/screener"
                            ? "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer selected_menu_hover"
                            : "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer menu_hover"
                    }
                    onClick={() => {
                        if (window.innerWidth < 1224) {
                            showSidebar();
                        }
                    }}
                >
                    <MdIcons.MdFilterList className='text_1 mr_20px' />
                    <div className='text_2'>
                        <FormattedMessage
                            id='sidemenu.screener'
                            defaultMessage='Screener'
                        />
                    </div>
                </Link>

                {/* Monte Carlo DCF */}
                <Link
                    to={`/monte_carlo_dcf`}
                    className={
                        location.pathname === "/monte_carlo_dcf"
                            ? "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer selected_menu_hover"
                            : "pl_20px width_100pr pt_22px pb_22px border_radius_15px_right border_box flex space-between text_color_3 list_style_none text_decoration_none cursor_pointer menu_hover"
                    }
                    onClick={() => {
                        if (window.innerWidth < 1224) {
                            showSidebar();
                        }
                    }}
                >
                    <MdIcons.MdAreaChart className='text_1 mr_20px' />
                    <div className='text_2'>
                        <FormattedMessage
                            id='sidemenu.discounted_cash_flow'
                            defaultMessage='Discounted Cash Flow'
                        />
                    </div>
                </Link>
            </div>
            <div className='height_5pr flex left'>
                <a
                    href={`https://www.stocksonview.com/terms-of-service`}
                    target='_blank'
                    className='width_100pr text_4 text_color_white'
                >
                    <FormattedMessage
                        id='general.terms_of_service'
                        defaultMessage='Terms of Service'
                    />
                </a>
            </div>
        </div>
    );
};

SideSubMenu.propTypes = {
    setSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setSidebar })(SideSubMenu);
