import { AiFillSlackSquare } from "react-icons/ai";
import {
    GET_COMPANY,
    GET_COMPANY_MONTE_CARLO_PARAMS,
    GET_MONTE_CARLO_BY_PARAMS,
    GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS,
    UPDATE_MONTE_CARLO_PARAM,
    RESET_MONTE_CARLO_PARAMS,
    GET_COMPETITORS,
    GET_COMPETITORS_PAGINATION,
    SET_COMPANY_LOADING,
    SET_COMPANY_TICKER,
    SET_COMPANY_OPEN_TAB,
    SET_METRIC_DATA,
    SET_CHART_STRUCTURE,
    SET_CHART_METRICS,
    RESET_METRIC_DATA,
    SET_CHART_PER_SHARE,
    SET_CHART_CHANGE_RELATIVE,
    SET_CHART_CHANGE_ABSOLUTE,
} from "../actions/Types";

const initialState = {
    company: null,
    company_monte_carlo_dcf_params: null,
    years: [
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
    ],
    chart_structure: "individual",
    chart_selected: [],
    chart_metrics: [],
    chart_metric_data: null,
    chart_per_share: false,
    chart_change_relative: false,
    chart_change_absolute: false,
    monte_carlo_params: {
        n_trials: 100000, // 1-100000
        n_periods: 10, // 1-20
        r_0: 1.3, // 0.1-5
        dr_0: 0.3, // 0.001-5
        r_n: 1.04, // 0.1-5
        dr_n: 0.16, // 0.001-5
        S_0: 10, // >0 - 100000
        p_0: 100,
        m: 0.3,
        dm: 0.1,
        discount_rate: 0.1, // 0-9
        terminal_multiple: 15, // 0-100
        cash: 0,
        debt: 0,
    },
    ticker: null,
    industry: null,
    competitors: null,
    open_tab: 1,
    loading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                company: action.payload,
                ticker: action.payload.ticker,
                industry: action.payload.industry,
            };

        case GET_COMPANY_MONTE_CARLO_PARAMS:
            console.log(
                "GET_COMPANY_MONTE_CARLO_PARAMS: ",
                action.payload.new_monte_carlo_params
            );
            return {
                ...state,
                monte_carlo_params: action.payload.new_monte_carlo_params,
                company_monte_carlo_dcf_params:
                    action.payload.new_monte_carlo_params,
                company_monte_carlo_dcf: action.payload.varanaut_res_dcf,
                company_monte_carlo_dcf_preview:
                    action.payload.varanaut_res_dcf_preview,
                loading: false,
            };

        case GET_MONTE_CARLO_BY_PARAMS:
            return {
                ...state,
                company_monte_carlo_dcf: action.payload.varanaut_res_dcf,
                loading: false,
            };

        case GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS:
            console.log(action.payload.varanaut_res_dcf);
            return {
                ...state,
                company_monte_carlo_dcf_preview:
                    action.payload.varanaut_res_dcf,
                loading: false,
            };

        case UPDATE_MONTE_CARLO_PARAM:
            return {
                ...state,
                monte_carlo_params: {
                    ...state.monte_carlo_params,
                    [action.payload.key]: action.payload.value,
                },
            };

        case RESET_MONTE_CARLO_PARAMS:
            return {
                ...state,
                monte_carlo_params: action.payload.params,
            };

        case SET_COMPANY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case SET_COMPANY_TICKER:
            return {
                ...state,
                ticker: action.payload,
            };

        case SET_COMPANY_OPEN_TAB:
            return {
                ...state,
                open_tab: action.payload,
            };

        case GET_COMPETITORS:
            return {
                ...state,
                competitors: action.payload.data,
                competitors_recent: action.payload.industry,
            };

        case GET_COMPETITORS_PAGINATION:
            return {
                ...state,
                competitors: state.competitors.concat(action.payload.data),
            };

        case SET_METRIC_DATA:
            return {
                ...state,
                chart_metric_data: action.payload.new_data,
                chart_selected: action.payload.chart_selected,
            };

        case SET_CHART_STRUCTURE:
            return {
                ...state,
                chart_structure: action.payload.preset,
                chart_metrics: action.payload.metric_data,
                chart_selected: action.payload.new_chart_selected,
            };

        case SET_CHART_METRICS:
            return {
                ...state,
                chart_metrics: action.payload,
            };

        case RESET_METRIC_DATA:
            return {
                ...state,
                chart_metric_data: action.payload.new_data,
                chart_metrics: [],
                chart_selected: [],
                chart_structure: "individual",
            };

        case SET_CHART_PER_SHARE:
            return {
                ...state,
                chart_per_share: !state.chart_per_share,
            };

        case SET_CHART_CHANGE_RELATIVE:
            return {
                ...state,
                chart_change_relative: !state.chart_change_relative,
                chart_change_absolute: false,
            };

        case SET_CHART_CHANGE_ABSOLUTE:
            return {
                ...state,
                chart_change_relative: false,
                chart_change_absolute: !state.chart_change_absolute,
            };

        default:
            return state;
    }
};
