import React from "react";
import * as MdIcons from "react-icons/md";

// Actions
import { setAlert } from "../../actions/alertActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Alerts = ({ alerts, setAlert }) => {
    return alerts.length > 0 ? (
        <div className='width_100pr pos_fixed flex center mt_25px z_index_max'>
            <div className='border_radius_10px width_50pr_100pr shadow_dark hover_opacity_80'>
                {alerts.map((alert) => (
                    <div
                        key={alert.id}
                        className={`flex start padding_5px border_radius_5px text_color_white alert_${alert.type}`}
                    >
                        {alert.type === "danger" ? (
                            <div className='text_1 mt_5px ml_5px text_color_white'>
                                <MdIcons.MdOutlineDangerous />
                            </div>
                        ) : alert.type === "success" ? (
                            <div className='text_1 mt_5px ml_5px text_color_white'>
                                <MdIcons.MdCheckCircleOutline />
                            </div>
                        ) : alert.type === "notification" ? (
                            <div className='text_1 mt_5px ml_5px text_color_white'>
                                <MdIcons.MdInfoOutline />
                            </div>
                        ) : alert.type === "warning " ? (
                            <div className='text_1 mt_5px ml_5px text_color_white'>
                                <MdIcons.MdWarningAmber />
                            </div>
                        ) : (
                            <div className='text_1 mt_5px ml_5px text_color_white'>
                                <MdIcons.MdInfoOutline />
                            </div>
                        )}

                        <div className='ml_10px text_left text_color_white'>
                            {alert.msg}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

Alerts.propTypes = {
    alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alerts,
});

export default connect(mapStateToProps, {
    setAlert,
})(Alerts);
