import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import { numberFormatter } from "../../../actions/generalActions";

bellcurve(Highcharts);

// bellcurve(Highcharts);
function closest(num, arr) {
    var curr = arr[0],
        diff = Math.abs(num - curr),
        index = 0;

    for (var val = 0; val < arr.length; val++) {
        let newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            index = val;
        }
    }
    return index;
}

const max = (value1, value2) => {
    if (value1 > value2) {
        return value1;
    } else {
        return value2;
    }
};

const MCFairValueDistribution = ({
    company: { company },
    hist,
    p_undervalued_arr,
    bin_edges,
    fair_value,
    stock_price,
}) => {
    const useintl = useIntl();
    let fair_value_index = closest(fair_value, bin_edges);
    let stock_price_index =
        stock_price && stock_price !== null
            ? closest(stock_price, bin_edges)
            : null;
    let y_min = 0.001;
    let y_max = 0.999;
    let y_min_index = closest(y_min, p_undervalued_arr);
    let y_max_index = closest(y_max, p_undervalued_arr);
    let x_max = max(y_min_index, stock_price_index);
    let x_min = y_max_index;

    let chartPlotLines = [
        {
            color: "green", // Color value
            dashStyle: "longdashdot", // Style of the plot line. Default to solid
            value: fair_value_index, // Value of where the line will appear
            width: 2, // Width of the line
            zIndex: 5,
            label: {
                text: `Fair value: ${Number(fair_value.toFixed(2))} USD`,
                style: {
                    color: Highcharts.getOptions().title.style.color,
                    // fontWeight: 'bold'
                },
                rotation: 0,
                y: 60,
            },
        },
    ];

    if (stock_price !== null) {
        chartPlotLines.push({
            color: Highcharts.getOptions().colors[0], // Color value
            dashStyle: "longdashdot", // Style of the plot line. Default to solid
            value: stock_price_index, // Value of where the line will appear
            width: 2, // Width of the line
            zIndex: 5,
            label: {
                text: `Stock price: ${Number(stock_price.toFixed(2))} USD`,
                style: {
                    color: Highcharts.getOptions().title.style.color,
                    // fontWeight: 'bold'
                },
                rotation: 0,
                y: 120,
            },
        });
    }

    let chartOptions = {
        chart: {
            //renderTo: 'umsatzchart',
            zoomType: "xy",
        },
        title: {
            text: "Fair value distribution",
        },
        subtitle: {
            text: "",
        },
        credits: {
            text: "StocksOnView",
            href: "#",
        },
        xAxis: [
            {
                // showResetZoom: true,
                categories: bin_edges,
                // tickInterval: bin_edges[bin_edges.length-1]/20,
                labels: {
                    formatter: function () {
                        return numberFormatter(Number(this.value).toFixed(2));
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: `Stock Price`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                plotLines: chartPlotLines,
                max: x_max,
                min: x_min,
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    formatter: function () {
                        return numberFormatter(this.value);
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: `Fair value distribution`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                alignTicks: false,
                gridLineWidth: 0.3,
            },
        ],
        tooltip: {
            enabled: false,
            // shared: true,
            // formatter: function () {
            //     var points = this.points;
            //     var pointsLength = points.length;
            //     var tooltipMarkup = pointsLength
            //         ? '<span style="font-size: 10px">' +
            //           points[0].key +
            //           "</span><br/>"
            //         : "";
            //     var index;
            //     var value;

            //     for (index = 0; index < pointsLength; index += 1) {
            //         if (
            //             points[index].series.name === "Fair value distribution"
            //         ) {
            //             value = numberFormatter(points[index].y);

            //             tooltipMarkup +=
            //                 '<span style="color:' +
            //                 points[index].series.color +
            //                 '">\u25CF</span> ' +
            //                 points[index].series.name +
            //                 ": <b>" +
            //                 (Number((value*100).toFixed(2))) +
            //                 ` %</b><br/>`;
            //         }
            //     }
            //     return tooltipMarkup;
            // },
        },
        legend: {
            enabled: false,
            // layout: "vertical",
            // align: "left",
            // x: 80,
            // verticalAlign: "top",
            // y: 30,
            // floating: true,
            // backgroundColor:
            //     Highcharts.getOptions().legend.backgroundColor || // theme
            //     "rgba(255,255,255,0.25)",
        },
        series: [
            {
                name: "Fair value distribution",
                type: "line",
                color: Highcharts.getOptions().colors[2],
                data: hist.map((prob) =>
                    prob !== null ? Number(prob.toPrecision(4)) : null
                ),
                tooltip: {
                    valueSuffix: `USD`,
                },
                color: Highcharts.getOptions().colors[0],
            },
        ],
    };

    let chartOptionsHistogram = {
        title: {
            text: useintl.formatMessage({
                id: `general.histogram`,
            }),
            visible: false,
        },
        credits: {
            enabled: false,
        },

        xAxis: [
            {
                title: { text: "Fair Value Distribution" },
                alignTicks: false,
                opposite: true,
                max: x_max,
                min: x_min,
            },
            {
                alignTicks: false,
                plotLines: chartPlotLines,
            },
        ],

        yAxis: [
            {
                title: { text: "Data" },
                visible: false,
                opposite: true,
                showInLegend: false,
            },
            {
                title: {
                    text: useintl.formatMessage({
                        id: `histogram.number_of_companies`,
                    }),
                },
            },
        ],

        plotOptions: {
            histogram: {
                accessibility: {
                    pointDescriptionFormatter: function (point) {
                        var ix = point.index + 1,
                            x1 = point.x.toFixed(3),
                            x2 = point.x2.toFixed(3),
                            val = point.y;
                        return ix + ". " + x1 + " to " + x2 + ", " + val + ".";
                    },
                },
            },
        },
        tooltip: {
            pointFormat: `Pricerange: <b>{point.y}</b></br>Range: <b>{point.x}</b> to <b>{point.x2}</b>`,
            shared: false,
            valueDecimals: 2,
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                type: "histogram",
                xAxis: 1,
                yAxis: 1,
                // binWidth: histogram.statistics.bin_width,
                // binsNumber: 50,
                baseSeries: "s1",
                zIndex: -1,
            },
            {
                name: "Data",
                type: "scatter",
                data: bin_edges,
                visible: false,
                showInLegend: false,
                id: "s1",
                marker: {
                    radius: 1.5,
                },
            },
        ],
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

MCFairValueDistribution.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(MCFairValueDistribution);
