import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

// ACTIONS
import { verifyUserEmail } from "../../actions/userActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

const EmailVerification = ({ verifyUserEmail }) => {
    useEffect(() => {
        // CLEAR LOCALSTORAGE/ REDUX etc.
        verifyUserEmail(findGetParameter("token"));
        // eslint-disable-next-line
    }, []);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className='width_100vw height_100vh_real flex space_around background_color_1 text_color_1'>
            <Helmet>
                <title>{`Email Verification | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Verify your email adress, to be able to subscribe to our paid services.'
                />
            </Helmet>
            <div className='flex flex_column'>
                <div className='margin_20px'>
                    You can either go to the dashboard by clicking the button,
                    or close this page.
                </div>
                <a
                    className='button_blue padding_10px text_color_1 margin_20px'
                    href='https://dashboard.stocksonview.com/screener'
                >
                    Continue
                </a>
            </div>
        </div>
    );
};

EmailVerification.propTypes = {
    verifyUserEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    verifyUserEmail,
})(EmailVerification);
