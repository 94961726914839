import React from "react";
import { useIntl } from "react-intl";
import * as IoIcons5 from "react-icons/io5";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SectorTableHead = ({ metric, market_table, user }) => {
    const useintl = useIntl();

    let not_metrics = [
        "page",
        "limit",
        "search",
        "searchOption",
        "sort",
        "industry",
        "esg",
        "stock_exchange",
        "ticker",
        "sector",
        "description",
        "name",
        "currency",
        "country",
    ];
    let not_sortable = ["sector"];

    if (!not_metrics.includes(metric)) {
        return (
            <th
                className='flex right text_right text_color_1 min_width_120px cursor_pointer vertical_align_top'
                style={{
                    zIndex: "8",
                }}
                // onClick={() => {
                //     sortTableBy(metric);
                // }}
            >
                {useintl.formatMessage({
                    id: `metrics.${metric}`,
                })}
                {/* {options.sortBy.direction === "ASC" ? (
                    <IoIcons5.IoArrowUpOutline className='text_1 ml_5px text_color_blue' />
                ) : (
                    <IoIcons5.IoArrowDownOutline className='text_1 ml_5px text_color_blue' />
                )} */}
            </th>
            // ) : (
            //     // ) : not_sortable.includes(metric) ? (
            //     //     <th className='min_width_120px text_color_1'>
            //     //         {useintl.formatMessage({
            //     //             id: `metrics.${metric}`,
            //     //         })}
            //     //     </th>
            //     <th
            //         className='min_width_120px text_color_1 cursor_pointer vertical_align_top'
            //         // onClick={() => {
            //         //     sortTableBy(metric);
            //         // }}
            //     >
            //         {useintl.formatMessage({
            //             id: `metrics.${metric}`,
            //         })}
            //     </th>
        );
    } else {
        return null;
    }
};

SectorTableHead.propTypes = {
    user: PropTypes.number.isRequired,
    market_table: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(SectorTableHead);
