import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
// import { FormattedMessage } from "react-intl";
// import { useHistory } from "react-router-dom";
// import styled from "styled-components";
// import { Link } from 'react-router-dom';

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Portfolio from "./Portfolio";
import PortfolioMenuContainer from "./PortfolioMenuContainer";
import FeedbackIcon from "../elements/FeedbackIcon";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { setPortfolio } from "../../../actions/portfolioActions";
import { setAlert } from "../../../actions/alertActions";

// MUI
import Dialog from "@mui/material/Dialog";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const PortfoliosMenu = ({
    sidebar: { sidebar },
    user,
    portfolio,
    setPortfolio,
    setAlert,
}) => {
    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // eslint-disable-next-line
    }, [portfolio]);

    const useintl = useIntl();

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    // PORTFOLIO CREATION ICON SELECTION
    const [openPortfolioIcon, setOpenPortfolioIcon] = React.useState(false);
    const handleClickOpenPortfolioIcon = () => {
        setOpenPortfolioIcon(true);
    };
    const handleClosePortfolioIcon = () => {
        setOpenPortfolioIcon(false);
    };

    /*Create Portfolio functions*/
    /*newPortfolio ist local State um zwischen zu speichern*/
    const [newPortfolio, setNewPortfolio] = React.useState({
        id: null,
        name: "",
        icon: "collection",
        description: "",
    });
    const handleNewPortfolioName = (event) => {
        if (event.target.value.length <= 20) {
            setNewPortfolio({ ...newPortfolio, name: event.target.value });
        }
    };
    const handleNewPortfolioIcon = (icon) => {
        setNewPortfolio({ ...newPortfolio, icon: icon });
    };
    const handleNewPortfolioDescription = (event) => {
        if (event.target.value.length <= 500) {
            setNewPortfolio({
                ...newPortfolio,
                description: event.target.value,
            });
        }
    };

    const handleSetPortfolio = () => {
        if (newPortfolio.name !== "") {
            setPortfolio(
                {
                    name: newPortfolio.name,
                    icon: newPortfolio.icon,
                    description: newPortfolio.description,
                    user: user.id,
                },
                portfolio,
                user.status
            );
            // setNewPortfolio({
            //     id: null,
            //     name: "",
            //     icon: "collection",
            //     description: "",
            // });
            // setCurrentPortfolio(id);
            // getPortfolioCompanies(null, user.id, portfolio.portfolios);
            // handleCloseCreatePortfolio();
            // routeRedirect(`/portfolio?id=${id}`);
        } else {
            setAlert(`You need to provide a portfolio name`, "danger");
        }
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.portfolio",
        })}`,
        location_index: 2,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.screener",
        })}`,
        location_index: 3,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
        ],
        header_text_1: useintl.formatMessage({
            id: "screener.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/8kvIzYbd8AY'
                title='HOW you can MANAGE YOUR PORTFOLIOS using our PORTFOLIO MANAGER'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "screener.description_text_1",
        }),
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Portfolio Manager | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Create Portfolios to track their historic performance. Compare them with your friends performances or indices, view information like your sharpe, estimated dividend payments and much more.'
                />
            </Helmet>
            <FeedbackIcon feedbackInfo={feedbackInfo} />
            {!window.location.search ||
            portfolio.error ||
            portfolio.portfolios.length === 0 ? (
                <div className='background_color_1 text_color_1'>
                    <TopBarCallToAction />
                    <div className='flex width_100pr mt_20px mb_20px'>
                        <div className='title_4 ml_25px_10px mt_5px'>
                            <RiIcons.RiDonutChartFill />
                        </div>
                        <div className='title_4 ml_15px'>Your Portfolios</div>
                        <AiIcons.AiOutlineInfoCircle
                            className='text_1 hover_translate_up ml_15px mt_5px'
                            onClick={() => {
                                handleOpenPageInfo();
                            }}
                        />
                    </div>

                    <PageInfoModalSimple
                        infoHeader={"What can i do here?"}
                        infoBody={
                            "In this place you can overview your own portfolios as well as the ones you follow."
                        }
                        showPageInfo={showPageInfo}
                        handleClosePageInfo={handleClosePageInfo}
                    />
                    <div className='border_box width_100pr mt_35px'>
                        <div className='ms_auto width_100pr grid grid_column_repeat_5_4_3_2_1'>
                            {portfolio.portfolios.length !== 0
                                ? portfolio.portfolios.map((wl) =>
                                      wl ? (
                                          <PortfolioMenuContainer
                                              containerprops={wl}
                                          />
                                      ) : null
                                  )
                                : null}

                            {user.status === 80 ? null : (
                                <div className='border_box shadow_dark width_30pr_45pr_80pr ms_auto mt_10px mb_10px min_width_320px height_300px background_color_2 border_radius_15px'>
                                    <div className='width_100pr background_color_3 border_radius_15px_top height_20pr flex center padding_10px text_2'>
                                        <div className='width_20px title_4 mt_6px'>
                                            <MdIcons.MdAdd />
                                        </div>
                                        <div className='ml_20px text_2'>
                                            Create new Portfolio
                                        </div>
                                    </div>
                                    <div className='divider_no_margin'></div>
                                    <div className='flex flex_column height_80pr padding_20px space_between'>
                                        <div className='width_100pr'>
                                            <div className='flex'>
                                                <div
                                                    className='width_60px mt_5px flex left cursor_pointer'
                                                    onClick={
                                                        handleClickOpenPortfolioIcon
                                                    }
                                                >
                                                    <div className='width_20px text_1'>
                                                        {icon_storage.map(
                                                            (icon) =>
                                                                icon.name ===
                                                                newPortfolio.icon
                                                                    ? icon.icon
                                                                    : null
                                                        )}
                                                    </div>
                                                    <div className='width_30px text_1'>
                                                        <RiIcons.RiArrowDownSFill />
                                                    </div>
                                                </div>
                                                <div className='input_basic_border border_radius_5px width_85pr padding_5px height_100pr'>
                                                    <input
                                                        className='text_3 text_color_1'
                                                        type='text'
                                                        maxLength='20'
                                                        placeholder='Name...'
                                                        onKeyUp={
                                                            handleNewPortfolioName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <textarea
                                                    className='text_3 mt_15px width_100pr text_color_1 height_100px padding_5px resize_none border_radius_5px input_basic_border'
                                                    name='text'
                                                    rows='8'
                                                    // wrap='soft'
                                                    maxLength='500'
                                                    placeholder='Description...'
                                                    onKeyUp={
                                                        handleNewPortfolioDescription
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className='width_100pr flex right'>
                                            <div
                                                className='button_blue color_white ml_15px'
                                                onClick={() => {
                                                    handleSetPortfolio();
                                                }}
                                            >
                                                <div className='ml_10px text_3'>
                                                    Create
                                                </div>
                                                <IoIcons.IoIosAddCircleOutline className='mr_10px ml_10px text_2' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Select Portfolio Icon Modal */}
                    <Dialog
                        open={openPortfolioIcon}
                        onClose={handleClosePortfolioIcon}
                        maxWidth={false}
                        className=''
                    >
                        <div className='max_width_500px text_color_1 background_color_2'>
                            <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                <div className='mr_10px'>
                                    {"Select a fitting Icon"}
                                </div>
                                <div
                                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                    onClick={handleClosePortfolioIcon}
                                >
                                    <AiIcons.AiOutlineClose />
                                </div>
                            </div>
                            <div className='divider mb_15px'></div>
                            <div className='flex space_around wrap border_box padding_10px'>
                                {icon_storage.map((icon, index) => {
                                    return (
                                        <div
                                            className='create_portfolio_icon border_box width_60px height_40px text_1 flex center padding_10px cursor_pointer'
                                            key={index}
                                            onClick={() => {
                                                handleNewPortfolioIcon(
                                                    icon.name,
                                                    icon.icon
                                                );
                                                handleClosePortfolioIcon();
                                            }}
                                        >
                                            {icon.icon}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Dialog>
                </div>
            ) : (
                <Portfolio />
            )}
        </div>
    );
};

PortfoliosMenu.propTypes = {
    sidebar: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    deletePortfolio: PropTypes.func.isRequired,
    setPortfolio: PropTypes.func.isRequired,
    getPortfolioCompanies: PropTypes.func.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
    setCurrentPortfolio: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    user: state.user,
    portfolio: state.portfolio,
    screener: state.screener,
});

export default connect(mapStateToProps, { setPortfolio, setAlert })(
    PortfoliosMenu
);
