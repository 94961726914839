import React from "react";
import { useIntl } from "react-intl";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ICONS
import * as IoIcons5 from "react-icons/io5";

// MUI
import Popover from "@mui/material/Popover";

// ACTIONS
import { updateSingleSliderValueOnly } from "../../../actions/screenerAction";
import { numberFormatter } from "../../../actions/generalActions";

const RangeSliderScreenerMaxPopover = ({
    sliderprops,
    value,
    setValue,
    maxValue,
    setMinValue,
    setMaxValue,
    updateSingleSliderValueOnly,
}) => {
    const useintl = useIntl();

    const [openMaxModal, setOpenMaxModal] = React.useState(null);
    const handleOpenMaxModal = (event) => {
        setOpenMaxModal(event.currentTarget);
    };
    const handleCloseMaxModal = () => {
        if (Number(maxValue) > value[0]) {
            setValue([value[0], Number(maxValue)]);
            sliderprops.value = [value[0], Number(maxValue)];
        } else {
            setValue([Number(maxValue), value[0]]);
            sliderprops.value = [Number(maxValue), value[0]];
            setMinValue(Number(maxValue));
            setMaxValue(value[0]);
        }

        // if (Number(value[1]).toPrecision(3) !== maxValue) {
        handleUpdateSingleSliderValueOnly();
        // }
        setOpenMaxModal(null);
    };

    const openMax = Boolean(openMaxModal);
    const idMax = openMax ? "simple-popover" : undefined;

    const handleMaxValue = (event) => {
        if (sliderprops.property.includes("ranker")) {
            sliderprops.value = [value[0], Number(maxValue)];
            setMaxValue(Number(event.target.value) / 100);
            setValue([value[0], Number(maxValue)]);
        } else {
            sliderprops.value = [value[0], Number(maxValue)];
            setMaxValue(event.target.value);
            setValue([value[0], Number(maxValue)]);
        }
    };

    const handleMaxStatistics = (value_in) => {
        setMaxValue(value_in);
        if (value_in > value[0]) {
            setValue([value[0], value_in]);
            sliderprops.value = [value[0], value_in];
        } else {
            setValue([value_in, value[0]]);
            sliderprops.value = [value_in, value[0]];
        }
        // if (Number(value[1]).toPrecision(3) !== maxValue) {
        handleUpdateSingleSliderValueOnly();
        // }
        setOpenMaxModal(null);
    };

    // SHOW FURTHER INFO ON HOVER
    const [onmouseClickInfoMax, setOnmouseClickInfoMax] = React.useState(false);
    const handleMouseinClickInfoMax = () => {
        setOnmouseClickInfoMax(true);
    };
    const handleMouseoutClickInfoMax = () => {
        setOnmouseClickInfoMax(false);
    };
    const [onmouse25, setOnmouse25] = React.useState(false);
    const handleMousein25 = () => {
        setOnmouse25(true);
    };
    const handleMouseout25 = () => {
        setOnmouse25(false);
    };
    const [onmouse50, setOnmouse50] = React.useState(false);
    const handleMousein50 = () => {
        setOnmouse50(true);
    };
    const handleMouseout50 = () => {
        setOnmouse50(false);
    };
    const [onmouse75, setOnmouse75] = React.useState(false);
    const handleMousein75 = () => {
        setOnmouse75(true);
    };
    const handleMouseout75 = () => {
        setOnmouse75(false);
    };

    const handleExtremeRanker = (value) => {
        let number = Number(value);
        if (number > 100) {
            return 100;
        }
        if (number < 0) {
            return 0;
        }
        return number;
    };

    const handleUpdateSingleSliderValueOnly = (event, newValue) => {
        updateSingleSliderValueOnly(sliderprops);
    };

    return (
        <div className='flex center'>
            <div
                className='max_width_100px cursor_text background_color_2 border_radius_5px pv_5px ps_10px hover_opacity_80'
                aria-describedby={idMax}
                onClick={handleOpenMaxModal}
                onMouseOver={handleMouseinClickInfoMax}
                onMouseLeave={handleMouseoutClickInfoMax}
            >
                {sliderprops.property.includes("ranker") &&
                !sliderprops.property.includes("change") ? (
                    <div>
                        {handleExtremeRanker(
                            Number(maxValue).toPrecision(3) * 100
                        )}
                        %
                    </div>
                ) : sliderprops.property === "market_cap" ||
                  sliderprops.property === "market_cap_usd" ||
                  sliderprops.property === "ev" ? (
                    numberFormatter(Number(maxValue).toPrecision(3)) +
                    `${sliderprops.unit}`
                ) : Number(maxValue) >= 10000000000000000 ? (
                    <div className='flex'>
                        <p className='pb_3px mr_3px'>+</p>
                        <IoIcons5.IoInfiniteOutline />
                        <div className='pb_2px ml_3px'>{sliderprops.unit}</div>
                    </div>
                ) : (
                    numberFormatter(Number(maxValue).toPrecision(3)) +
                    `${sliderprops.unit}`
                )}

                {onmouseClickInfoMax ? (
                    <div className='pos_absolute background_color_4 text_4 text_color_1'>
                        Click to edit
                    </div>
                ) : null}
            </div>

            {/* MAX POPOVER */}
            <Popover
                id={idMax}
                open={openMax}
                anchorEl={openMaxModal}
                onClose={() => {
                    handleCloseMaxModal();
                    // handleUpdateSingleSliderValueOnly();
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                className=''
            >
                <div className='width_200px background_color_4 border_box'>
                    <div className='border_box padding_6px'>
                        <input
                            type='number'
                            autofocus
                            className={
                                maxValue !== ""
                                    ? "text_2 text_color_1 pl_10px"
                                    : "text_2 text_color_2 pl_10px"
                            }
                            // value={minValue}
                            placeholder='new max value...'
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    handleCloseMaxModal();
                                    // handleUpdateSingleSliderValueOnly();
                                } else {
                                    handleMaxValue(event);
                                }
                            }}
                        />
                    </div>
                    <div className='divider_no_margin'></div>
                    <div className='flex'>
                        <div
                            className='width_33pr flex center border_box padding_5px cursor_pointer'
                            onMouseOver={handleMousein25}
                            onMouseLeave={handleMouseout25}
                            onClick={() => {
                                handleMaxStatistics(sliderprops.statistics[0]);
                                // handleUpdateSingleSliderValueOnly();
                                handleMouseout25();
                            }}
                        >
                            <div className='flex'>
                                <p className='pb_3px mr_3px'>25</p>
                            </div>
                        </div>
                        <div
                            className='width_33pr flex center border_box padding_5px cursor_pointer'
                            onMouseOver={handleMousein50}
                            onMouseLeave={handleMouseout50}
                            onClick={() => {
                                handleMaxStatistics(sliderprops.statistics[1]);
                                // handleUpdateSingleSliderValueOnly();
                                handleMouseout50();
                            }}
                        >
                            <div className='flex'>
                                <p className='pb_3px mr_3px'>50</p>
                            </div>
                        </div>
                        <div
                            className='width_33pr flex center border_box padding_5px cursor_pointer'
                            onMouseOver={handleMousein75}
                            onMouseLeave={handleMouseout75}
                            onClick={() => {
                                handleMaxStatistics(sliderprops.statistics[2]);
                                // handleUpdateSingleSliderValueOnly();
                                handleMouseout75();
                            }}
                        >
                            <div className='flex'>
                                <p className='pb_3px mr_3px'>75</p>
                            </div>
                        </div>
                    </div>
                    {onmouse25 ? (
                        <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                            {useintl.formatMessage({
                                id: `histogram.quantile_25`,
                            })}
                        </div>
                    ) : null}
                    {onmouse50 ? (
                        <div className='pos_absolute ml_60px background_color_4 text_4 text_color_1'>
                            {useintl.formatMessage({
                                id: `histogram.median`,
                            })}
                        </div>
                    ) : null}
                    {onmouse75 ? (
                        <div className='pos_absolute ml_120px background_color_4 text_4 text_color_1'>
                            {useintl.formatMessage({
                                id: `histogram.quantile_75`,
                            })}
                        </div>
                    ) : null}
                    <div className='divider_no_margin'></div>
                    <div className='flex border_radius_15px_bottom'>
                        <div className='width_50pr flex center text_color_unactive border_box padding_4px'>
                            <div className='flex'>
                                <p className='pb_3px mr_3px'>-</p>
                                <IoIcons5.IoInfiniteOutline />
                                <div className='pb_2px ml_3px'>
                                    {sliderprops.unit}
                                </div>
                            </div>
                        </div>
                        <div
                            className='width_50pr flex center border_box padding_4px cursor_pointer'
                            onClick={() => {
                                handleMaxStatistics(10000000000000000);
                                // handleUpdateSingleSliderValueOnly();
                            }}
                        >
                            <div className='flex'>
                                <p className='pb_3px mr_3px'>+</p>
                                <IoIcons5.IoInfiniteOutline />
                                <div className='pb_2px ml_3px'>
                                    {sliderprops.unit}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

RangeSliderScreenerMaxPopover.propTypes = {
    updateSingleSliderValueOnly: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//     screener: state.screener,
// });

export default connect(null, {
    updateSingleSliderValueOnly,
})(RangeSliderScreenerMaxPopover);
