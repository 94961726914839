import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// ACTIONS
import { loginDemoUser } from "../../actions/userActions";

// COMPONENTS
import Preloader from "../layout/Preloader";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

const LoginDemoRedirect = ({ user, loginDemoUser }) => {
    const history = useHistory();

    useEffect(() => {
        // ANALYTICS
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // localStorage.setItem("latest_url", "/screener");
        loginDemoUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user.isAuthenticated) {
            if (localStorage.getItem("latest_url")) {
                try {
                    history.push(
                        JSON.parse(localStorage.getItem("latest_url"))
                    );
                    localStorage.setItem("latest_url", "");
                } catch (err) {
                    history.push("/screener");
                    localStorage.setItem("latest_url", "");
                }
                // console.log(
                //     "LATEST URL: ",
                //     JSON.parse(localStorage.getItem("latest_url"))
                // );
            } else {
                history.push("/screener");
                localStorage.setItem("latest_url", "");
            }
        }
        // eslint-disable-next-line
    }, [user.isAuthenticated]);

    return (
        <div className='width_100pr'>
            <Preloader />
        </div>
    );
};

LoginDemoRedirect.propTypes = {
    user: PropTypes.object.isRequired,
    loginDemoUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {
    loginDemoUser,
})(LoginDemoRedirect);
