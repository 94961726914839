import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

import * as AiIcons from "react-icons/ai";

// Components
import Preloader from "../../layout/Preloader";
import PortfolioTable from "../../tables/companiesPortfolio/PortfolioTable";
import CompaniesTableModal from "../elements/CompaniesTableModal";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Context
import { MetricsContext } from "../../../context/MetricsContext";

/*Actions*/
import { setDisplayFalseAll } from "../../../actions/screenerAction";
import {
    resetCreatorCompanyShareCount,
    submitCompanyShareCount,
} from "../../../actions/portfolioActions";

// MUI
import Dialog from "@mui/material/Dialog";

/*Form to save Values to App level State*/

let screenerOptionsForm = {};

const PortfolioAssetsTable = ({
    screener: { slider, options },
    portfolio,
    portfolioType,
    user,
    company_list,
    company_allocation,
    company_allocation_tracker,
    portfolio_value,
    setDisplayFalseAll,
    resetCreatorCompanyShareCount,
    submitCompanyShareCount,
}) => {
    screenerOptionsForm = options;
    // Current selected portfolio
    let currentPortfolio = null;
    currentPortfolio = portfolio.portfolios.filter((obj) => {
        return obj.id === portfolio.current;
    });

    const useintl = useIntl();

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = metrics.sorted_metrics_object;

    let sorted_rankers_object = metrics.sorted_rankers_object;

    let [metricType, setMetricType] = React.useState(0);

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // Slider Search Functionality

    const handlesearchSliderText = (event) => {
        if (event.target.value === "") {
            setSearchSliderObject(SliderData);
        } else {
            let helper = {};
            Object.keys(SliderData).map((slider) => {
                if (
                    SliderData[slider].displayName.includes(
                        event.target.value
                    ) ||
                    SliderData[slider].property.includes(event.target.value) ||
                    SliderData[slider].filter_keywords.includes(
                        event.target.value
                    )
                ) {
                    helper[slider] = SliderData[slider];
                }
            });
            setSearchSliderObject(helper);
        }
    };

    /*Tablecolumns Dialog Field*/
    const [openColumns, setOpenColumns] = React.useState(false);
    const handleClickOpenColumns = () => {
        setOpenColumns(true);
    };
    const handleCloseColumns = () => {
        setOpenColumns(false);
    };
    const [tableStructure, setTableStructure] = React.useState(
        screenerOptionsForm.tableStructure.value
    );
    const handletableStructure = (new_structure) => {
        setTableStructure(new_structure);
        screenerOptionsForm.tableStructure.value = new_structure;
    };

    /*Create Companies Array for the Table*/
    let assets_table_companies = [];
    if (company_list) {
        Object.keys(company_list).map(function (key, index) {
            assets_table_companies.push(company_list[key]);
        });
    }

    /*Reset Creator Portfolio to original */
    const handleResetShareCount = () => {
        resetCreatorCompanyShareCount(company_allocation, company_list);
    };

    // Submit open changes to the database
    const handleSubmitChanges = () => {
        let new_ticker = [];
        let new_allocation = {};

        currentPortfolio[0].ticker.map((tick) => {
            if (currentPortfolio[0].allocation[tick].count !== 0) {
                new_ticker.push(tick);
                new_allocation[tick] = currentPortfolio[0].allocation[tick];
            }
        });
        submitCompanyShareCount(
            portfolio.current,
            new_allocation,
            currentPortfolio[0].ticker
        );
    };

    return (
        <div>
            {company_list === null ? (
                <Preloader />
            ) : (
                <div className='mt_15px background_color_1 text_color_1'>
                    <div className='flex space_between wrap width_100pr'>
                        <div className='border_box mt_20px mb_20px pl_20px pr_20px text_1'>
                            <FormattedMessage
                                id='general.assets_table'
                                defaultMessage='Assets Table'
                            />
                        </div>
                        <div
                            className='button_blue hover_opacity_80 text_2 padding_10px mr_15px'
                            onClick={() => {
                                handleClickOpenColumns();
                                setSearchSliderObject(SliderData);
                            }}
                        >
                            <div className='pr_10px'>
                                <FormattedMessage
                                    id='general.table'
                                    defaultMessage='Table'
                                />
                            </div>
                            <AiIcons.AiOutlineTable />
                        </div>
                    </div>

                    <div className='screener_table_wrapper pl_10px pr_10px background_color_1'>
                        <PortfolioTable
                            portfolio_value={portfolio_value}
                            portfolioType={portfolioType}
                            allocation={company_allocation}
                            company_allocation_tracker={
                                company_allocation_tracker
                            }
                            companies={assets_table_companies}
                            slider={slider}
                            options={options}
                        />
                    </div>

                    {/* Table Column Creation */}
                    <Dialog
                        open={openColumns}
                        onClose={handleCloseColumns}
                        fullScreen={true}
                    >
                        <CompaniesTableModal
                            closeModal={handleCloseColumns}
                            slider={slider}
                            options={options}
                            tableStructure={tableStructure}
                            handletableStructure={handletableStructure}
                            metricType={metricType}
                            setMetricType={setMetricType}
                            handlesearchSliderText={handlesearchSliderText}
                            sorted_metrics_object={sorted_metrics_object}
                            sorted_rankers_object={sorted_rankers_object}
                            AllowedMetrics={AllowedMetrics}
                            searchSliderObject={searchSliderObject}
                        />
                    </Dialog>
                </div>
            )}
        </div>
    );
};

PortfolioAssetsTable.propTypes = {
    screener: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    company_list: PropTypes.object.isRequired,
    company_allocation: PropTypes.object.isRequired,
    portfolio_value: PropTypes.number.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
    resetCreatorCompanyShareCount: PropTypes.func.isRequired,
    submitCompanyShareCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    setDisplayFalseAll,
    resetCreatorCompanyShareCount,
    submitCompanyShareCount,
})(PortfolioAssetsTable);
