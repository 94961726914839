import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import * as MdIcons from "react-icons/md";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";

// ACTIONS
import {
    updateCompanyShareCount,
    openPortfolioChanges,
} from "../../../actions/portfolioActions";
import { numberFormatter } from "../../../actions/generalActions";

// COMPONENTS
import AssetsTableRowColumn from "./PortfolioTableRowColumn";
import CompanyActionsMenu from "../../pages/elements/CompanyActionsMenu";
import TransactionForm from "../../pages/elements/TransactionForm";

const PortfolioTableRow = ({
    company,
    user,
    currentPortfolio,
    company_allocation,
    portfolioType,
    portfolio_value,
    metrics,
    portfolio,
    updateCompanyShareCount,
    openPortfolioChanges,
}) => {
    const useintl = useIntl();

    // CHECKBOX HANDLE IF CONTAINED
    const [creatorCheckbox, setCreatorCheckbox] = React.useState(true);
    const handleCreatorCheckbox = (event) => {
        setCreatorCheckbox(event.target.checked);
        updateCompanyShareCount(
            company.ticker,
            event.target.checked
                ? Number(company_allocation[company.ticker].recommend)
                : 0,
            company_allocation,
            portfolio.current_creator_companies,
            "creator"
        );
    };

    // OPEN TRANSACTION
    const [openPortfolioTransaction, setOpenPortfolioTransaction] =
        React.useState(false);

    // SHARE COUNT MODAL
    const handleOpenShareTransaction = () => {
        setOpenPortfolioTransaction(true);
        openPortfolioChanges(true, "PortfolioTableRow");
    };
    const handleCloseShareTransaction = () => {
        setOpenPortfolioTransaction(false);
        openPortfolioChanges(false, "PortfolioTableRow");
    };

    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    return (
        <tr
            key={`${window.location.pathname}${company.ticker}`}
            className={`creator_sticky_table_tbody_tr text_color_1 ${
                company_allocation[company.ticker].status === true
                    ? "background_color_table_true"
                    : "background_color_table_false"
            }`}
        >
            {/* TICKER */}
            <th
                style={{
                    zIndex: "9",
                }}
            >
                <div
                    className={`${
                        company_allocation[company.ticker].status === true
                            ? "flex background_color_table_true padding_5px border_radius_10px_right"
                            : "flex background_color_table_false padding_5px border_radius_10px_right"
                    }`}
                >
                    {window.location.pathname === "/creator" ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={creatorCheckbox}
                                    onChange={handleCreatorCheckbox}
                                    name={company.ticker}
                                    color='primary'
                                />
                            }
                            label=''
                        />
                    ) : null}

                    {/* Actions */}
                    <CompanyActionsMenu
                        company={company}
                        portfolioType={portfolioType}
                    />
                    <Link
                        to={`/company?ticker=${company.ticker}`}
                        className='ml_15px'
                    >
                        <div className='text_3 text_weight_4 text_color_blue'>
                            {company.name ? company.name : company.ticker}
                        </div>
                        <div className='text_4 text_weight_4 text_color_blue'>
                            {company.ticker}
                        </div>
                    </Link>
                </div>
            </th>
            {/* ALLOCATION */}
            <td className='padding_15_5px text_2 text_right text_color_1'>
                {company_allocation[company.ticker].status === true
                    ? `${Number(
                          (company_allocation[company.ticker].value /
                              portfolio_value) *
                              100
                      ).toPrecision(3)} %`
                    : "-"}
            </td>
            {/* POSITION VALUE */}
            <td className='padding_15_5px text_2 text_right text_color_1'>
                {company_allocation[company.ticker].status === true
                    ? `${Number(
                          company_allocation[company.ticker].value.toFixed(2)
                      )} USD`
                    : 0}
            </td>
            {/* STOCK PRICE */}
            {window.location.pathname === "/portfolio" ||
            window.location.pathname === "/creator" ? (
                !isNaN(company.daily_return) &&
                typeof company.daily_return === "number" ? (
                    <td className='padding_15_5px text_2'>
                        <div className='flex right text_color_1'>
                            <div>{numberFormatter(company.stock_price)}</div>
                            <div className='ml_5px'> USD</div>
                        </div>
                    </td>
                ) : (
                    <td className='text_2 text_color_1'>-</td>
                )
            ) : null}
            {/* DAILY RETURN */}
            {window.location.pathname === "/portfolio" ||
            window.location.pathname === "/creator" ? (
                !isNaN(company.daily_return) &&
                typeof company.daily_return === "number" ? (
                    company.daily_return >= 0 ? (
                        <td className='padding_15_5px'>
                            <div className='flex right text_2 text_color_green'>
                                <div>
                                    {numberFormatter(company.daily_return)}
                                </div>
                                <div className='ml_5px'> %</div>
                            </div>
                        </td>
                    ) : (
                        <td className='padding_15_5px'>
                            <div className='flex right text_2 text_color_red'>
                                <div>
                                    {numberFormatter(company.daily_return)}
                                </div>
                                <div className='ml_5px'> %</div>
                            </div>
                        </td>
                    )
                ) : (
                    <td className='text_2 text_color_1 text_right'>-</td>
                )
            ) : null}
            {/* NUMBER OF SHARES/ TRANSACTIONS */}
            {window.location.pathname === "/creator" ||
            (window.location.pathname === "/portfolio" &&
                currentPortfolio.user_id === user.id) ? (
                <td className='padding_15_5px text_2 text_right text_color_1'>
                    <div
                        className='width_80px padding_10px flex space_between cursor_pointer hover_opacity_80 border_radius_15px border_1px_solid background_color_4'
                        onClick={handleOpenShareTransaction}
                    >
                        <div className=''>
                            {company_allocation[company.ticker]
                                ? company_allocation[company.ticker].count
                                : null}
                        </div>
                        <MdIcons.MdModeEditOutline />
                    </div>
                </td>
            ) : (
                <td className='padding_15_5px text_2 text_right text_color_1'>
                    <div className='width_80px padding_10px flex space_between hover_opacity_80 border_radius_15px border_1px_solid background_color_4'>
                        <div className=''>
                            {company_allocation[company.ticker]
                                ? company_allocation[company.ticker].count
                                : null}
                        </div>
                        <MdIcons.MdModeEditOutline />
                    </div>
                </td>
            )}

            {openPortfolioTransaction ? (
                <Dialog
                    open={
                        portfolio.changes &&
                        portfolio.changes_component === "PortfolioTableRow" &&
                        openPortfolioTransaction
                    }
                    onClose={handleCloseShareTransaction}
                    maxWidth={false}
                    className=''
                >
                    <TransactionForm
                        portfolioType={portfolioType}
                        currentPortfolio={currentPortfolio}
                        pre_selected_ticker={company.ticker}
                        closeTransactionModal={handleCloseShareTransaction}
                    />
                </Dialog>
            ) : null}

            {metrics.map((metric) =>
                industries_display.includes(metric) ? (
                    <AssetsTableRowColumn company={company} metric={metric} />
                ) : null
            )}

            {/* Figures */}
            {metrics.map((metric) =>
                !industries_display.includes(metric) ? (
                    <AssetsTableRowColumn company={company} metric={metric} />
                ) : null
            )}

            {company.market_cap ? (
                <td className='padding_15_5px text_2 text_right text_color_1'>
                    {numberFormatter(company.market_cap)} USD
                </td>
            ) : (
                <td className='padding_15_5px text_2 text_right text_color_1'>
                    -
                </td>
            )}
        </tr>
    );
};

PortfolioTableRow.propTypes = {
    user: PropTypes.object.isRequired,
    company_allocation: PropTypes.object.isRequired,
    currentPortfolio: PropTypes.object.isRequired,
    portfolio_value: PropTypes.number.isRequired,
    portfolio: PropTypes.object.isRequired,
    updateCompanyShareCount: PropTypes.func.isRequired,
    openPortfolioChanges: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    updateCompanyShareCount,
    openPortfolioChanges,
})(PortfolioTableRow);
