import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import logo from "../../favicon.png";
import { useHistory } from "react-router-dom";

import { GoogleLogin, GoogleLogout } from "react-google-login";

import { useIntl } from "react-intl";

import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import * as IoIcons5 from "react-icons/io5";

import Alerts from "../layout/Alerts";

// Actions
import { setAlert } from "../../actions/alertActions";
import {
    logoutUser,
    registerUser,
    registerUserGoogle,
    loginDemoUser,
} from "../../actions/userActions";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//     },
//     margin: {
//         margin: theme.spacing(1),
//     },
//     withoutLabel: {
//         marginTop: theme.spacing(3),
//     },
// }));

// ANALYTICS
import ReactGA from "react-ga4";
const {
    REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_ENVIRONMENT,
} = process.env;

const locale = navigator.language;

const Register = ({
    user,
    alerts,
    setAlert,
    logoutUser,
    registerUser,
    registerUserGoogle,
    loginDemoUser,
}) => {
    const history = useHistory();
    const useintl = useIntl();

    useEffect(() => {
        // CLEAR LOCALSTORAGE/ REDUX etc.
        logoutUser();
        // eslint-disable-next-line
    }, []);

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user.isAuthenticated) {
            if (localStorage.getItem("latest_url")) {
                history.push(JSON.parse(localStorage.getItem("latest_url")));
                localStorage.setItem("latest_url", "");
            } else {
                history.push("/screener");
                localStorage.setItem("latest_url", "");
            }
        }
        // eslint-disable-next-line
    }, [user.isAuthenticated]);

    const submitDemoLogin = () => {
        loginDemoUser();
    };

    // TRADITIONAL REGISTER
    const [values, setValues] = React.useState({
        name: "",
        email: "",
        password: "",
        password2: "",
        showPassword: false,
        showPassword2: false,
    });

    const { name, email, password, password2, showPassword, showPassword2 } =
        values;

    const [passwordStrength, setPasswordStrength] = React.useState(0);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        if (prop === "password") {
            let strength = 0;
            let val = event.target.value;
            var hasNumber = /\d/;
            var hasSpecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            var hasUpper = /[A-Z]/;

            if (val.length > 0) {
                strength += 1;
            }
            if (val.length > 0) {
                if (val.length > 7) {
                    strength += 1;
                }
                if (hasNumber.test(val)) {
                    strength += 1;
                }
                if (hasUpper.test(val)) {
                    strength += 1;
                }
                if (hasSpecial.test(val)) {
                    strength += 1;
                }
            }

            console.log("NEW STRENGTH: ", strength);
            setPasswordStrength(strength);
        }
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowPassword2 = () => {
        setValues({ ...values, showPassword2: !values.showPassword2 });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitRegister = () => {
        console.log("Submit clicked");
        if (
            name === "" ||
            email === "" ||
            password === "" ||
            password2 === ""
        ) {
            setAlert("Please enter all fields", "danger");
        } else if (password.length < 8) {
            setAlert("Your password must have at least 8 characters", "danger");
        } else if (password !== password2) {
            setAlert("Passwords do not match", "danger");
        } else {
            // setAlert('All fine', 'danger');
            registerUser({
                name: name,
                email: email,
                password: password,
                locale: locale,
            });
        }
    };

    // GOOGLE REGISTER

    const googleClientId = REACT_APP_GOOGLE_CLIENT_ID;

    const onGoogleRegisterSuccess = (res) => {
        let google_user_object = res.profileObj;

        google_user_object["locale"] = locale;
        registerUserGoogle(google_user_object);
    };

    const onGoogleRegisterFailure = (res) => {
        console.log("Google Register Failure: ", res.profileObj);
    };

    if (user.isAuthenticated) {
        history.push("/screener");
    }

    return (
        <div
            className='width_100pr background_color_1 text_color_1'
            onKeyUp={(event) => {
                if (event.key === "Enter") {
                    submitRegister();
                }
            }}
        >
            <Helmet>
                <title>{`Register | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Register at StocksOnView to access the dashboard features available to your plan.'
                />
            </Helmet>
            <Alerts />
            <div className='ms_auto mt_50px mb_50px width_95pr max_width_350px background_color_2 shadow_dark border_radius_15px'>
                <div className='flex center'>
                    <div className='color_black mt_25px'>
                        <img
                            src={logo}
                            alt='Stocks On View Logo'
                            className='width_80px height_80px'
                        />
                    </div>
                </div>
                <div className='flex center mt_40px title_3'>Welcome!</div>
                <div className='flex center mt_15px text_3'>
                    <p>Create your Account here</p>
                    {/* <IoIcons5.IoLogInOutline /> */}
                </div>
                <div className='divider_no_margin mt_10px'></div>
                <div className='flex center mt_20px'>
                    <a
                        className='button_grey width_95pr max_width_250px height_40px hover_opacity_80'
                        onClick={submitDemoLogin}
                    >
                        Try Demo
                    </a>
                </div>
                <div className='flex center mt_20px mb_25px'>
                    <GoogleLogin
                        clientId={googleClientId}
                        className='width_250px color_black bg_color_white'
                        buttonText={useintl.formatMessage({
                            id: "login.continue_with_google",
                        })}
                        onSuccess={onGoogleRegisterSuccess}
                        onFailure={onGoogleRegisterFailure}
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
                <div className='flex center mt_40px ms_auto'>
                    <TextField
                        className='width_95pr max_width_250px pl_10px background_color_3 text_color_1'
                        id='outlined-basic'
                        label='Name'
                        variant='outlined'
                        onChange={handleChange("name")}
                        required
                    />
                </div>
                <div className='flex center mt_15px ms_auto'>
                    <TextField
                        className='width_95pr max_width_250px pl_10px background_color_3 text_color_1'
                        id='outlined-basic'
                        label='Email'
                        variant='outlined'
                        onChange={handleChange("email")}
                        required
                    />
                </div>
                <div className='flex center mt_30px width_95pr max_width_250px ms_auto'>
                    <FormControl className='' variant='outlined'>
                        <InputLabel htmlFor='outlined-adornment-password'>
                            Password
                        </InputLabel>
                        <OutlinedInput
                            // className='width_100pr'
                            id='outlined-adornment-password'
                            className='width_100pr background_color_3 text_color_1'
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            required
                            minLength={6}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                    >
                                        {values.showPassword ? (
                                            <IoIcons5.IoEyeOutline />
                                        ) : (
                                            <IoIcons5.IoEyeOffOutline />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                            label={useintl.formatMessage({
                                id: "general.password",
                            })}
                        />
                    </FormControl>
                </div>

                <div className='flex center mt_15px width_95pr max_width_250px ms_auto'>
                    <FormControl className='' variant='outlined'>
                        <InputLabel htmlFor='outlined-adornment-password'>
                            Confirm Password
                        </InputLabel>
                        <OutlinedInput
                            // className='width_250px'
                            id='outlined-adornment-password'
                            className='width_100pr background_color_3 text_color_1'
                            type={values.showPassword2 ? "text" : "password"}
                            value={values.password2}
                            onChange={handleChange("password2")}
                            required
                            minLength={6}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword2}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                    >
                                        {values.showPassword ? (
                                            <IoIcons5.IoEyeOutline />
                                        ) : (
                                            <IoIcons5.IoEyeOffOutline />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                            label='Confirm Password'
                        />
                    </FormControl>
                </div>

                <div className='ms_auto mt_15px border_box width_95pr max_width_250px'>
                    {/* passwordStrength */}
                    {passwordStrength === 0 ? (
                        <div className='width_100pr text_color_1'>
                            Password strength
                        </div>
                    ) : (
                        <div className='flex width_100pr'>
                            <div className='mr_5px'>Password strength: </div>
                            <div
                                className={
                                    passwordStrength < 3
                                        ? "text_color_red"
                                        : passwordStrength < 4
                                        ? "text_color_yellow"
                                        : "text_color_green"
                                }
                            >
                                {passwordStrength < 2
                                    ? "very low"
                                    : passwordStrength < 3
                                    ? "low"
                                    : passwordStrength < 4
                                    ? "medium"
                                    : passwordStrength < 5
                                    ? "high"
                                    : "very high"}
                            </div>
                        </div>
                    )}
                </div>

                <div className='ms_auto mt_5px border_1px_solid border_box width_95pr max_width_250px height_7px button_background_color_4'>
                    {/* passwordStrength */}
                    {passwordStrength === 0 ? (
                        <div className='width_100pr height_100pr button_background_color_4'></div>
                    ) : (
                        <div
                            style={{
                                width: `calc(${(passwordStrength / 5) * 100}%)`,
                            }}
                            className={
                                passwordStrength < 3
                                    ? "background_color_red height_100pr"
                                    : passwordStrength < 4
                                    ? "background_color_yellow height_100pr"
                                    : "background_color_green height_100pr"
                            }
                        ></div>
                    )}
                </div>

                <div className='ms_auto mt_5px border_box width_95pr max_width_250px'>
                    {/* passwordStrength */}
                    {password.length < 8 ? (
                        <div className='width_100pr height_100pr text_4'>
                            (Must have at least 8 characters)
                        </div>
                    ) : null}
                </div>

                <div className='flex center mt_35px'>
                    <a
                        className='button_blue color_white width_95pr max_width_250px height_40px hover_opacity_80'
                        onClick={submitRegister}
                        // href='/screener'
                    >
                        Continue
                    </a>
                </div>
                <div className='flex center mt_10px ml_35px mr_35px'>
                    <p>
                        By continuing you agree to the
                        <a
                            href={`https://www.stocksonview.com/terms-of-service`}
                            target='_blank'
                            className='ml_5px'
                        >
                            Stocks On View Terms of Service
                        </a>
                    </p>
                </div>
                <div className='flex wrap center mt_30px mb_20px'>
                    <div>Already signed up? </div>
                    <div>
                        <Link to={`/login`} className='ml_5px'>
                            Log in here
                        </Link>
                    </div>
                </div>
                {/* <div className='flex center mt_25px'>
                    <div className='button_google color_white width_250px height_40px'>
                        <div className='ml_15px text_2 pt_5px'>
                            <IoIcons5.IoLogoGoogle />
                        </div>
                        <div className='ml_15px text_3'>
                            Continue with Google
                        </div>
                    </div>
                </div> */}
                {/* <div className='flex center mt_15px'>
                    <div className='button_facebook color_white width_250px height_40px'>
                        <div className='ml_15px text_2 pt_5px'>
                            <IoIcons5.IoLogoFacebook />
                        </div>
                        <div className='ml_15px text_3'>
                            Continue with Facebook
                        </div>
                    </div>
                </div>
                <div className='flex center mt_15px mb_25px'>
                    <div className='button_apple color_black width_250px height_40px'>
                        <div className='ml_15px text_2 pt_5px'>
                            <IoIcons5.IoLogoApple />
                        </div>
                        <div className='ml_15px text_3'>
                            Continue with Apple
                        </div>
                    </div>
                </div> */}
                <div className='divider_no_margin'></div>

                <div className='flex wrap center pt_25px pb_25px'>
                    <div>
                        {useintl.formatMessage({
                            id: "general.questions",
                        })}
                        ?{" "}
                    </div>
                    <a className='ml_5px' href='mailto: info@stocksonview.com'>
                        info@stocksonview.com
                    </a>
                </div>
            </div>
        </div>
    );
};

Register.propTypes = {
    user: PropTypes.object.isRequired,
    alerts: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    registerUserGoogle: PropTypes.func.isRequired,
    loginDemoUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alerts,
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    logoutUser,
    registerUser,
    registerUserGoogle,
    loginDemoUser,
})(Register);
