import React, { useState, createContext } from "react";
import { useIntl } from "react-intl";

import { numberFormatter } from "../actions/generalActions";

export const MetricsContext = createContext();

export const MetricsProvider = (props) => {
    const useintl = useIntl();

    let company = "Microsoft";
    let stock_price = `334.65`;
    let outstanding_shares = `${7.58 * Math.pow(10, 9)}`;
    let market_cap = `${2.13 * Math.pow(10, 12)}`;
    let year = "2021";
    let year_1y = "2020";
    let year_3y = "2018";
    let year_5y = "2016";
    let year_9y = "2012";
    let revenue = `${168 * Math.pow(10, 9)}`;
    let revenue_1y = `${143 * Math.pow(10, 9)}`;
    let revenue_3y = `${110 * Math.pow(10, 9)}`;
    let revenue_5y = `${85.3 * Math.pow(10, 9)}`;
    let revenue_9y = `${73.7 * Math.pow(10, 9)}`;

    let earnings = `${61.3 * Math.pow(10, 9)}`;
    let earnings_1y = `${44.3 * Math.pow(10, 9)}`;
    let earnings_3y = `${16.6 * Math.pow(10, 9)}`;
    let earnings_5y = `${16.5 * Math.pow(10, 9)}`;
    let earnings_9y = `${17 * Math.pow(10, 9)}`;

    let dividend = `${16.5 * Math.pow(10, 9)}`;
    let dividend_1y = `${15.1 * Math.pow(10, 9)}`;
    let dividend_3y = `${12.7 * Math.pow(10, 9)}`;
    let dividend_5y = `${11 * Math.pow(10, 9)}`;
    let dividend_9y = `${6.38 * Math.pow(10, 9)}`;

    // REVENUE
    let revenue_1y_pct_raw = `${Number(
        Number(revenue) / Number(revenue_1y)
    ).toFixed(4)}`;
    let revenue_1y_pct = `${Number(
        100 * (Number(revenue) / Number(revenue_1y) - 1)
    ).toFixed(2)}`;
    let revenue_3y_pct_raw = `${Number(
        Number(revenue) / Number(revenue_3y)
    ).toFixed(4)}`;
    let revenue_3y_pct = `${Number(
        100 * (Number(revenue) / Number(revenue_3y) - 1)
    ).toFixed(2)}`;
    let revenue_3y_cagr_raw = `${Number(
        Math.pow(Number(revenue) / Number(revenue_3y), 1 / 3)
    ).toFixed(4)}`;
    let revenue_3y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(revenue) / Number(revenue_3y), 1 / 3)) - 1)
    ).toFixed(2)}`;
    let revenue_5y_pct_raw = `${Number(
        Number(revenue) / Number(revenue_5y)
    ).toFixed(4)}`;
    let revenue_5y_pct = `${Number(
        100 * (Number(revenue) / Number(revenue_5y) - 1)
    ).toFixed(2)}`;
    let revenue_5y_cagr_raw = `${Number(
        Math.pow(Number(revenue) / Number(revenue_5y), 1 / 5)
    ).toFixed(4)}`;
    let revenue_5y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(revenue) / Number(revenue_5y), 1 / 5)) - 1)
    ).toFixed(2)}`;
    let revenue_9y_pct_raw = `${Number(
        Number(revenue) / Number(revenue_9y)
    ).toFixed(4)}`;
    let revenue_9y_pct = `${Number(
        100 * (Number(revenue) / Number(revenue_9y) - 1)
    ).toFixed(2)}`;
    let revenue_9y_cagr_raw = `${Number(
        Math.pow(Number(revenue) / Number(revenue_9y), 1 / 9)
    ).toFixed(4)}`;
    let revenue_9y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(revenue) / Number(revenue_9y), 1 / 9)) - 1)
    ).toFixed(2)}`;

    // EARNINGS
    let earnings_1y_pct_raw = `${Number(
        Number(earnings) / Number(earnings_1y)
    ).toFixed(4)}`;
    let earnings_1y_pct = `${Number(
        100 * (Number(earnings) / Number(earnings_1y) - 1)
    ).toFixed(2)}`;
    let earnings_3y_pct_raw = `${Number(
        Number(earnings) / Number(earnings_3y)
    ).toFixed(4)}`;
    let earnings_3y_pct = `${Number(
        100 * (Number(earnings) / Number(earnings_3y) - 1)
    ).toFixed(2)}`;
    let earnings_3y_cagr_raw = `${Number(
        Math.pow(Number(earnings) / Number(earnings_3y), 1 / 3)
    ).toFixed(4)}`;
    let earnings_3y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(earnings) / Number(earnings_3y), 1 / 3)) -
                1)
    ).toFixed(2)}`;
    let earnings_5y_pct_raw = `${Number(
        Number(earnings) / Number(earnings_5y)
    ).toFixed(4)}`;
    let earnings_5y_pct = `${Number(
        100 * (Number(earnings) / Number(earnings_5y) - 1)
    ).toFixed(2)}`;
    let earnings_5y_cagr_raw = `${Number(
        Math.pow(Number(earnings) / Number(earnings_5y), 1 / 5)
    ).toFixed(4)}`;
    let earnings_5y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(earnings) / Number(earnings_5y), 1 / 5)) -
                1)
    ).toFixed(2)}`;
    let earnings_9y_pct_raw = `${Number(
        Number(earnings) / Number(earnings_9y)
    ).toFixed(4)}`;
    let earnings_9y_pct = `${Number(
        100 * (Number(earnings) / Number(earnings_9y) - 1)
    ).toFixed(2)}`;
    let earnings_9y_cagr_raw = `${Number(
        Math.pow(Number(earnings) / Number(earnings_9y), 1 / 9)
    ).toFixed(4)}`;
    let earnings_9y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(earnings) / Number(earnings_9y), 1 / 9)) -
                1)
    ).toFixed(2)}`;

    //DIVIDEND
    let dividend_1y_pct_raw = `${Number(
        Number(dividend) / Number(dividend_1y)
    ).toFixed(4)}`;
    let dividend_1y_pct = `${Number(
        100 * (Number(dividend) / Number(dividend_1y) - 1)
    ).toFixed(2)}`;
    let dividend_3y_pct_raw = `${Number(
        Number(dividend) / Number(dividend_3y)
    ).toFixed(4)}`;
    let dividend_3y_pct = `${Number(
        100 * (Number(dividend) / Number(dividend_3y) - 1)
    ).toFixed(2)}`;
    let dividend_3y_cagr_raw = `${Number(
        Math.pow(Number(dividend) / Number(dividend_3y), 1 / 3)
    ).toFixed(4)}`;
    let dividend_3y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(dividend) / Number(dividend_3y), 1 / 3)) -
                1)
    ).toFixed(2)}`;
    let dividend_5y_pct_raw = `${Number(
        Number(dividend) / Number(dividend_5y)
    ).toFixed(4)}`;
    let dividend_5y_pct = `${Number(
        100 * (Number(dividend) / Number(dividend_5y) - 1)
    ).toFixed(2)}`;
    let dividend_5y_cagr_raw = `${Number(
        Math.pow(Number(dividend) / Number(dividend_5y), 1 / 5)
    ).toFixed(4)}`;
    let dividend_5y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(dividend) / Number(dividend_5y), 1 / 5)) -
                1)
    ).toFixed(2)}`;
    let dividend_9y_pct_raw = `${Number(
        Number(dividend) / Number(dividend_9y)
    ).toFixed(4)}`;
    let dividend_9y_pct = `${Number(
        100 * (Number(dividend) / Number(dividend_9y) - 1)
    ).toFixed(2)}`;
    let dividend_9y_cagr_raw = `${Number(
        Math.pow(Number(dividend) / Number(dividend_9y), 1 / 9)
    ).toFixed(4)}`;
    let dividend_9y_cagr = `${Number(
        100 *
            (Number(Math.pow(Number(dividend) / Number(dividend_9y), 1 / 9)) -
                1)
    ).toFixed(2)}`;

    let AllMetrics = [
        "beta",
        "avg_rating_analyst",
        "implied_volatility",
        // "short_interest_ratio",
        // "short_ratio_float",
        // "short_ratio_outstanding",
        // "target_price_analyst",
        // "shares_short_prior_month",
        // "shares_held_by_insiders",
        // "shares_held_by_institutions",
        // "outstanding_shares",
        // // "outstanding_shares_change",
        // "shares_float",
        "ev",
        "market_cap_usd",
        "stock_price",
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
        // "total_revenue",
        // "free_cashflow",
        // "net_income",
        // "ebit",
        // "gross_profit",
        // "dividends_paid",
        // "total_liabilities",
        // "total_current_liabilities",
        // "total_assets",
        // "total_current_assets",
        // "research_development",
        // "market_cap",
        // "market_cap_change",
        "market_cap_ranker",
        // "market_cap_ranker_change",
        "price_earnings",
        // "price_earnings_change",
        "price_earnings_ranker",
        // "price_earnings_ranker_change",
        "price_earnings_growth",
        // "price_earnings_growth_change",
        "price_earnings_growth_ranker",
        // "price_earnings_growth_ranker_change",
        "price_sales",
        // "price_sales_change",
        "price_sales_ranker",
        // "price_sales_ranker_change",
        "price_sales_growth",
        // "price_sales_growth_change",
        "price_sales_growth_ranker",
        // "price_sales_growth_ranker_change",
        "price_book",
        // "price_book_change",
        "price_book_ranker",
        // "price_book_ranker_change",
        "dividend_yield",
        // "dividend_yield_change",
        "dividend_yield_ranker",
        // "dividend_yield_ranker_change",
        "payout_ratio",
        // "payout_ratio_change",
        "payout_ratio_ranker",
        // "payout_ratio_ranker_change",
        "revenue_growth_1y",
        // "revenue_growth_1y_change",
        "revenue_growth_1y_ranker",
        // "revenue_growth_1y_ranker_change",
        "earnings_growth_1y",
        // "earnings_growth_1y_change",
        "earnings_growth_1y_ranker",
        // "earnings_growth_1y_ranker_change",
        "dividend_growth_1y",
        // "dividend_growth_1y_change",
        "dividend_growth_1y_ranker",
        // "dividend_growth_1y_ranker_change",
        "revenue_growth_3y",
        // "revenue_growth_3y_change",
        "revenue_growth_3y_ranker",
        // "revenue_growth_3y_ranker_change",
        "earnings_growth_3y",
        // "earnings_growth_3y_change",
        "earnings_growth_3y_ranker",
        // "earnings_growth_3y_ranker_change",
        "dividend_growth_3y",
        // "dividend_growth_3y_change",
        "dividend_growth_3y_ranker",
        // "dividend_growth_3y_ranker_change",
        "revenue_growth_5y",
        // "revenue_growth_5y_change",
        "revenue_growth_5y_ranker",
        // "revenue_growth_5y_ranker_change",
        "earnings_growth_5y",
        // "earnings_growth_5y_change",
        "earnings_growth_5y_ranker",
        // "earnings_growth_5y_ranker_change",
        "dividend_growth_5y",
        // "dividend_growth_5y_change",
        "dividend_growth_5y_ranker",
        // "dividend_growth_5y_ranker_change",
        "revenue_growth_9y",
        // "revenue_growth_9y_change",
        "revenue_growth_9y_ranker",
        // "revenue_growth_9y_ranker_change",
        "earnings_growth_9y",
        // "earnings_growth_9y_change",
        "earnings_growth_9y_ranker",
        // "earnings_growth_9y_ranker_change",
        "dividend_growth_9y",
        // "dividend_growth_9y_change",
        "dividend_growth_9y_ranker",
        // "dividend_growth_9y_ranker_change",
        "price_ebit",
        "price_ebit_average_10y",
        "price_ebit_deviation_10y",
        "ev_ebit",
        "ev_ebit_average_10y",
        "ev_ebit_deviation_10y",
        "ev_ebitda",
        "ev_ebitda_average_10y",
        "ev_ebitda_deviation_10y",
        "dividend_consistency",
        "price_ebit_ranker",
        "ev_ebit_ranker",
        "ev_ebitda_ranker",
        "gross_profit_margin",
        // "gross_profit_margin_change",
        "gross_profit_margin_ranker",
        // "gross_profit_margin_ranker_change",
        "net_profit_margin",
        // "net_profit_margin_change",
        "net_profit_margin_ranker",
        // "net_profit_margin_ranker_change",
        "operating_cash_flow_margin",
        // "operating_cash_flow_margin_change",
        "operating_cash_flow_margin_ranker",
        // "operating_cash_flow_margin_ranker_change",
        "operating_margin",
        // "operating_margin_change",
        "operating_margin_ranker",
        // "operating_margin_ranker_change",
        "return_on_assets",
        // "return_on_assets_change",
        "return_on_assets_ranker",
        // "return_on_assets_ranker_change",
        "asset_turnover",
        // "asset_turnover_change",
        "asset_turnover_ranker",
        // "asset_turnover_ranker_change",
        "cash_flow_on_assets",
        // "cash_flow_on_assets_change",
        "cash_flow_on_assets_ranker",
        // "cash_flow_on_assets_ranker_change",
        "return_on_equity",
        // "return_on_equity_change",
        "return_on_equity_ranker",
        // "return_on_equity_ranker_change",
        "revenue_on_equity",
        // "revenue_on_equity_change",
        "revenue_on_equity_ranker",
        // "revenue_on_equity_ranker_change",
        "return_on_capital_employed",
        // "return_on_capital_employed_change",
        "return_on_capital_employed_ranker",
        // "return_on_capital_employed_ranker_change",
        "debt_ratio",
        // "debt_ratio_change",
        "debt_ratio_ranker",
        // "debt_ratio_ranker_change",
        "debt_to_ebit",
        // "debt_to_ebit_change",
        "debt_to_ebit_ranker",
        // "debt_to_ebit_ranker_change",
        "debt_to_equity",
        // "debt_to_equity_change",
        "debt_to_equity_ranker",
        // "debt_to_equity_ranker_change",
        "debt_to_revenue",
        // "debt_to_revenue_change",
        "debt_to_revenue_ranker",
        // "debt_to_revenue_ranker_change",
        "current_ratio",
        // "current_ratio_change",
        "current_ratio_ranker",
        // "current_ratio_ranker_change",
        "price_cash_flow",
        // "price_cash_flow_change",
        "price_cash_flow_ranker",
        // "price_cash_flow_ranker_change",
        "price_earnings_average_10y",
        "price_earnings_deviation_10y",
        "price_book_average_10y",
        "price_book_deviation_10y",
        "price_sales_average_10y",
        "price_sales_deviation_10y",
        "price_cash_flow_average_10y",
        "price_cash_flow_deviation_10y",
        "volume_deviation",
        "relative_score",
        "piotroski_score",
        "return_on_capital_ranker",
        "return_on_net_tangible_assets_ranker",
        "return_on_capital",
        "return_on_net_tangible_assets",
    ];

    let SimplifiedMetrics = [
        "avg_rating_analyst",
        "ev",
        "market_cap_usd",
        "stock_price",
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
        "market_cap_ranker",
        "price_earnings",
        "price_earnings_ranker",
        "price_earnings_growth",
        "price_earnings_growth_ranker",
        "price_sales",
        "price_sales_ranker",
        "price_sales_growth",
        "price_sales_growth_ranker",
        "price_book",
        "price_book_ranker",
        "dividend_yield",
        "dividend_yield_ranker",
        "payout_ratio",
        "payout_ratio_ranker",
        "revenue_growth_1y",
        "revenue_growth_1y_ranker",
        "earnings_growth_1y",
        "earnings_growth_1y_ranker",
        "dividend_growth_1y",
        "dividend_growth_1y_ranker",
        "revenue_growth_3y",
        "revenue_growth_3y_ranker",
        "earnings_growth_3y",
        "earnings_growth_3y_ranker",
        "dividend_growth_3y",
        "dividend_growth_3y_ranker",
        "revenue_growth_5y",
        "revenue_growth_5y_ranker",
        "earnings_growth_5y",
        "earnings_growth_5y_ranker",
        "dividend_growth_5y",
        "dividend_growth_5y_ranker",
        "revenue_growth_9y",
        "revenue_growth_9y_ranker",
        "earnings_growth_9y",
        "earnings_growth_9y_ranker",
        "dividend_growth_9y",
        "dividend_growth_9y_ranker",
        "price_ebit",
        "ev_ebit",
        "ev_ebitda",
        "dividend_consistency",
        "price_ebit_ranker",
        "ev_ebit_ranker",
        "ev_ebitda_ranker",
        "gross_profit_margin",
        "gross_profit_margin_ranker",
        "net_profit_margin",
        "net_profit_margin_ranker",
        "operating_cash_flow_margin",
        "operating_cash_flow_margin_ranker",
        "operating_margin",
        "operating_margin_ranker",
        "debt_ratio",
        "debt_ratio_ranker",
        "debt_to_ebit",
        "debt_to_ebit_ranker",
        "debt_to_revenue",
        "debt_to_revenue_ranker",
        "price_cash_flow",
        "price_cash_flow_ranker",
        "relative_score",
        "piotroski_score",
    ];
    let FreeMetrics = [
        "beta",
        "ev",
        "market_cap_usd",
        "stock_price",
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
        "price_earnings",
        "price_earnings_ranker",
        "price_earnings_growth",
        "price_earnings_growth_ranker",
        "price_sales",
        "price_sales_growth",
        "price_book",
        "dividend_yield",
        "payout_ratio",
        "revenue_growth_1y",
        "earnings_growth_1y",
        "dividend_growth_1y",
        "revenue_growth_3y",
        "earnings_growth_3y",
        // "dividend_growth_3y",
        // "revenue_growth_5y",
        // "earnings_growth_5y",
        // "dividend_growth_5y",
        // "revenue_growth_9y",
        // "earnings_growth_9y",
        // "dividend_growth_9y",
        "price_ebit",
        "ev_ebit",
        "ev_ebitda",
        "gross_profit_margin",
        "gross_profit_margin_ranker",
        "net_profit_margin",
        "net_profit_margin_ranker",
        "operating_cash_flow_margin",
        "operating_margin",
        "return_on_assets",
        "asset_turnover",
        "cash_flow_on_assets",
        "return_on_equity",
        "revenue_on_equity",
        "return_on_capital_employed",
        "debt_ratio",
        "debt_to_ebit",
        "debt_to_equity",
        "debt_to_revenue",
        "current_ratio",
        "price_cash_flow",
        "price_book_average_10y",
        "price_book_deviation_10y",
    ];

    let sorted_metrics_object = {
        general: [
            "market_cap_usd",
            "ev",
            "stock_price",
            "beta",
            "implied_volatility",
            // "short_interest_ratio",
            // "short_ratio_float",
            // "short_ratio_outstanding",
            "volume_deviation",
        ],
        performance: [
            "daily_return",
            "weekly_return",
            "monthly_return",
            "quarterly_return",
            "semi_annual_return",
            "annual_return",
            "two_year_return",
            "three_year_return",
        ],
        ratings: [
            "avg_rating_analyst",
            "piotroski_score",
            // "target_price_analyst",
            "relative_score",
        ],
        price_ratios: [
            "price_earnings",
            "price_earnings_growth",
            "price_earnings_deviation_10y",
            "price_sales",
            "price_sales_growth",
            "price_sales_deviation_10y",
            "price_book",
            "price_book_deviation_10y",
            "price_cash_flow",
            "price_cash_flow_deviation_10y",
            "price_ebit",
            "price_ebit_deviation_10y",
            "ev_ebit",
            "ev_ebit_deviation_10y",
            "ev_ebitda",
            "ev_ebitda_deviation_10y",
        ],
        fundamental_ratios: [
            "return_on_assets",
            "asset_turnover",
            "cash_flow_on_assets",
            "return_on_equity",
            "revenue_on_equity",
            "return_on_capital_employed",
            "return_on_capital",
            "return_on_net_tangible_assets",
        ],
        dividends: ["dividend_yield", "dividend_consistency", "payout_ratio"],
        // shares: [
        //     "short_interest_ratio",
        //     "short_ratio_float",
        //     "short_ratio_outstanding",
        //     "shares_held_by_insiders",
        //     "shares_held_by_institutions",
        // ],
        growth: [
            "revenue_growth_1y",
            "earnings_growth_1y",
            "dividend_growth_1y",
            "revenue_growth_3y",
            "earnings_growth_3y",
            "dividend_growth_3y",
            "revenue_growth_5y",
            "earnings_growth_5y",
            "dividend_growth_5y",
            "revenue_growth_9y",
            "earnings_growth_9y",
            "dividend_growth_9y",
        ],
        debt_assets: [
            "debt_ratio",
            "debt_to_ebit",
            "debt_to_equity",
            "debt_to_revenue",
            "current_ratio",
        ],
        margins: [
            "gross_profit_margin",
            "net_profit_margin",
            "operating_cash_flow_margin",
            "operating_margin",
        ],
    };
    let simplified_sorted_metrics_object = {
        general: ["market_cap_usd", "ev", "stock_price"],
        performance: [
            "daily_return",
            "weekly_return",
            "monthly_return",
            "quarterly_return",
            "semi_annual_return",
            "annual_return",
            "two_year_return",
            "three_year_return",
        ],
        ratings: [
            "avg_rating_analyst",
            "piotroski_score",
            // "target_price_analyst",
            "relative_score",
        ],
        price_ratios: [
            "price_earnings",
            "price_earnings_growth",
            "price_sales",
            "price_sales_growth",
            "price_book",
            "price_cash_flow",
            "price_ebit",
            "ev_ebit",
            "ev_ebitda",
        ],
        dividends: ["dividend_yield", "dividend_consistency", "payout_ratio"],
        // shares: [
        //     "short_interest_ratio",
        //     "short_ratio_float",
        //     "short_ratio_outstanding",
        //     "shares_held_by_insiders",
        //     "shares_held_by_institutions",
        // ],
        growth: [
            "revenue_growth_1y",
            "earnings_growth_1y",
            "dividend_growth_1y",
            "revenue_growth_3y",
            "earnings_growth_3y",
            "dividend_growth_3y",
            "revenue_growth_5y",
            "earnings_growth_5y",
            "dividend_growth_5y",
            "revenue_growth_9y",
            "earnings_growth_9y",
            "dividend_growth_9y",
        ],
        debt_assets: ["debt_ratio", "debt_to_ebit", "debt_to_revenue"],
        margins: [
            "gross_profit_margin",
            "net_profit_margin",
            "operating_cash_flow_margin",
            "operating_margin",
        ],
    };
    let free_sorted_metrics_object = {
        general: ["market_cap_usd", "ev", "stock_price"],
        performance: [
            "daily_return",
            "monthly_return",
            "annual_return",
            "two_year_return",
            "three_year_return",
        ],
        price_ratios: [
            "price_earnings",
            "price_earnings_growth",
            "price_sales",
            "price_sales_growth",
            "price_book",
            "price_book_deviation_10y",
        ],
        fundamental_ratios: ["return_on_assets", "return_on_equity"],
        dividends: ["dividend_yield", "payout_ratio"],
        growth: [
            "revenue_growth_1y",
            "earnings_growth_1y",
            "dividend_growth_1y",
            "revenue_growth_3y",
            "earnings_growth_3y",
            "dividend_growth_3y",
        ],
        debt_assets: ["debt_ratio", "debt_to_ebit"],
        margins: ["gross_profit_margin", "net_profit_margin"],
    };

    let sorted_rankers_object = {
        price_ratios: [
            "price_earnings_ranker",
            "price_earnings_growth_ranker",
            "price_sales_ranker",
            "price_sales_growth_ranker",
            "price_book_ranker",
            "price_cash_flow_ranker",
            "price_ebit_ranker",
            "ev_ebit_ranker",
            "ev_ebitda_ranker",
        ],
        fundamental_ratios: [
            "return_on_assets_ranker",
            "asset_turnover_ranker",
            "cash_flow_on_assets_ranker",
            "return_on_equity_ranker",
            "revenue_on_equity_ranker",
            "return_on_capital_employed_ranker",
            "return_on_capital_ranker",
            "return_on_net_tangible_assets_ranker",
        ],
        dividends: ["dividend_yield_ranker", "payout_ratio_ranker"],
        growth: [
            "revenue_growth_1y_ranker",
            "earnings_growth_1y_ranker",
            "dividend_growth_1y_ranker",
            "revenue_growth_3y_ranker",
            "earnings_growth_3y_ranker",
            "dividend_growth_3y_ranker",
            "revenue_growth_5y_ranker",
            "earnings_growth_5y_ranker",
            "dividend_growth_5y_ranker",
            "revenue_growth_9y_ranker",
            "earnings_growth_9y_ranker",
            "dividend_growth_9y_ranker",
        ],
        debt_assets: [
            "debt_ratio_ranker",
            "debt_to_ebit_ranker",
            "debt_to_equity_ranker",
            "debt_to_revenue_ranker",
            "current_ratio_ranker",
        ],
        margins: [
            "gross_profit_margin_ranker",
            "net_profit_margin_ranker",
            "operating_cash_flow_margin_ranker",
            "operating_margin_ranker",
        ],
    };
    let simplified_sorted_rankers_object = {
        price_ratios: [
            "price_earnings_ranker",
            "price_earnings_growth_ranker",
            "price_sales_ranker",
            "price_sales_growth_ranker",
            "price_book_ranker",
            "price_cash_flow_ranker",
            "price_ebit_ranker",
            "ev_ebit_ranker",
            "ev_ebitda_ranker",
        ],
        dividends: ["dividend_yield_ranker", "payout_ratio_ranker"],
        growth: [
            "revenue_growth_1y_ranker",
            "earnings_growth_1y_ranker",
            "dividend_growth_1y_ranker",
            "revenue_growth_3y_ranker",
            "earnings_growth_3y_ranker",
            "dividend_growth_3y_ranker",
            "revenue_growth_5y_ranker",
            "earnings_growth_5y_ranker",
            "dividend_growth_5y_ranker",
            "revenue_growth_9y_ranker",
            "earnings_growth_9y_ranker",
            "dividend_growth_9y_ranker",
        ],
        debt_assets: [
            "debt_ratio_ranker",
            "debt_to_ebit_ranker",
            "debt_to_revenue_ranker",
        ],
        margins: [
            "gross_profit_margin_ranker",
            "net_profit_margin_ranker",
            "operating_cash_flow_margin_ranker",
            "operating_margin_ranker",
        ],
    };
    let free_sorted_rankers_object = {
        price_ratios: ["price_earnings_ranker", "price_earnings_growth_ranker"],
        margins: ["gross_profit_margin_ranker", "net_profit_margin_ranker"],
    };

    //possible Options Values
    let possible_options = [
        "page",
        "limit",
        "search",
        "searchOption",
        "sortBy",
        "industry",
        "esg",
        "stock_exchange",
        "ticker",
        "description",
        "name",
        "currency",
        "country",
    ];
    //possible Manifest Options Values
    let possible_manifest_values = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];
    //possible Slider Range Keys
    let possible_ranges = [
        "beta",
        "implied_volatility",
        // "short_interest_ratio",
        // "short_ratio_float",
        // "short_ratio_outstanding",
        "avg_rating_analyst",
        "target_price_analyst",
        "stock_price",
        "shares_short",
        "shares_short_prior_month",
        "shares_held_by_insiders",
        "shares_held_by_institutions",
        "outstanding_shares",
        // "outstanding_shares_change",
        "shares_float",
        "market_cap_usd",
        "ev",
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
        "total_revenue",
        "free_cashflow",
        "net_income",
        "ebit",
        "gross_profit",
        "dividends_paid",
        "total_liabilities",
        "total_current_liabilities",
        "total_assets",
        "total_current_assets",
        "research_development",
        "market_cap",
        // "market_cap_change",
        "market_cap_ranker",
        // "market_cap_ranker_change",
        "price_earnings",
        // "price_earnings_change",
        "price_earnings_ranker",
        // "price_earnings_ranker_change",
        "price_earnings_growth",
        // "price_earnings_growth_change",
        "price_earnings_growth_ranker",
        // "price_earnings_growth_ranker_change",
        "price_sales",
        // "price_sales_change",
        "price_sales_ranker",
        // "price_sales_ranker_change",
        "price_sales_growth",
        // "price_sales_growth_change",
        "price_sales_growth_ranker",
        // "price_sales_growth_ranker_change",
        "price_book",
        // "price_book_change",
        "price_book_ranker",
        // "price_book_ranker_change",
        "dividend_yield",
        // "dividend_yield_change",
        "dividend_yield_ranker",
        // "dividend_yield_ranker_change",
        "payout_ratio",
        // "payout_ratio_change",
        "payout_ratio_ranker",
        // "payout_ratio_ranker_change",
        "revenue_growth_1y",
        // "revenue_growth_1y_change",
        "revenue_growth_1y_ranker",
        // "revenue_growth_1y_ranker_change",
        "earnings_growth_1y",
        // "earnings_growth_1y_change",
        "earnings_growth_1y_ranker",
        // "earnings_growth_1y_ranker_change",
        "dividend_growth_1y",
        // "dividend_growth_1y_change",
        "dividend_growth_1y_ranker",
        // "dividend_growth_1y_ranker_change",
        "revenue_growth_3y",
        // "revenue_growth_3y_change",
        "revenue_growth_3y_ranker",
        // "revenue_growth_3y_ranker_change",
        "earnings_growth_3y",
        // "earnings_growth_3y_change",
        "earnings_growth_3y_ranker",
        // "earnings_growth_3y_ranker_change",
        "dividend_growth_3y",
        // "dividend_growth_3y_change",
        "dividend_growth_3y_ranker",
        // "dividend_growth_3y_ranker_change",
        "revenue_growth_5y",
        // "revenue_growth_5y_change",
        "revenue_growth_5y_ranker",
        // "revenue_growth_5y_ranker_change",
        "earnings_growth_5y",
        // "earnings_growth_5y_change",
        "earnings_growth_5y_ranker",
        // "earnings_growth_5y_ranker_change",
        "dividend_growth_5y",
        // "dividend_growth_5y_change",
        "dividend_growth_5y_ranker",
        // "dividend_growth_5y_ranker_change",
        "revenue_growth_9y",
        // "revenue_growth_9y_change",
        "revenue_growth_9y_ranker",
        // "revenue_growth_9y_ranker_change",
        "earnings_growth_9y",
        // "earnings_growth_9y_change",
        "earnings_growth_9y_ranker",
        // "earnings_growth_9y_ranker_change",
        "dividend_growth_9y",
        // "dividend_growth_9y_change",
        "dividend_growth_9y_ranker",
        // "dividend_growth_9y_ranker_change",
        "price_ebit",
        "price_ebit_average_10y",
        "price_ebit_deviation_10y",
        "ev_ebit",
        "ev_ebit_average_10y",
        "ev_ebit_deviation_10y",
        "ev_ebitda",
        "ev_ebitda_average_10y",
        "ev_ebitda_deviation_10y",
        "dividend_consistency",
        "price_ebit_ranker",
        "ev_ebit_ranker",
        "ev_ebitda_ranker",
        "gross_profit_margin",
        // "gross_profit_margin_change",
        "gross_profit_margin_ranker",
        // "gross_profit_margin_ranker_change",
        "net_profit_margin",
        // "net_profit_margin_change",
        "net_profit_margin_ranker",
        // "net_profit_margin_ranker_change",
        "operating_cash_flow_margin",
        // "operating_cash_flow_margin_change",
        "operating_cash_flow_margin_ranker",
        // "operating_cash_flow_margin_ranker_change",
        "operating_margin",
        // "operating_margin_change",
        "operating_margin_ranker",
        // "operating_margin_ranker_change",
        "return_on_assets",
        // "return_on_assets_change",
        "return_on_assets_ranker",
        // "return_on_assets_ranker_change",
        "asset_turnover",
        // "asset_turnover_change",
        "asset_turnover_ranker",
        // "asset_turnover_ranker_change",
        "cash_flow_on_assets",
        // "cash_flow_on_assets_change",
        "cash_flow_on_assets_ranker",
        // "cash_flow_on_assets_ranker_change",
        "return_on_equity",
        // "return_on_equity_change",
        "return_on_equity_ranker",
        // "return_on_equity_ranker_change",
        "revenue_on_equity",
        // "revenue_on_equity_change",
        "revenue_on_equity_ranker",
        // "revenue_on_equity_ranker_change",
        "return_on_capital_employed",
        // "return_on_capital_employed_change",
        "return_on_capital_employed_ranker",
        // "return_on_capital_employed_ranker_change",
        "debt_ratio",
        // "debt_ratio_change",
        "debt_ratio_ranker",
        // "debt_ratio_ranker_change",
        "debt_to_ebit",
        // "debt_to_ebit_change",
        "debt_to_ebit_ranker",
        // "debt_to_ebit_ranker_change",
        "debt_to_equity",
        // "debt_to_equity_change",
        "debt_to_equity_ranker",
        // "debt_to_equity_ranker_change",
        "debt_to_revenue",
        // "debt_to_revenue_change",
        "debt_to_revenue_ranker",
        // "debt_to_revenue_ranker_change",
        "current_ratio",
        // "current_ratio_change",
        "current_ratio_ranker",
        // "current_ratio_ranker_change",
        "price_cash_flow",
        // "price_cash_flow_change",
        "price_cash_flow_ranker",
        // "price_cash_flow_ranker_change",
        "price_earnings_average_10y",
        "price_earnings_deviation_10y",
        "price_book_average_10y",
        "price_book_deviation_10y",
        "price_sales_average_10y",
        "price_sales_deviation_10y",
        "price_cash_flow_average_10y",
        "price_cash_flow_deviation_10y",
        "volume_deviation",
        "relative_score",
        "piotroski_score",
        "return_on_capital_ranker",
        "return_on_net_tangible_assets_ranker",
        "return_on_capital",
        "return_on_net_tangible_assets",
    ];
    // Sectors and industries
    let sectors = {
        _10: {
            name: "Communication Services",
            industries: {
                _1000: "Advertising Agencies",
                _1001: "Broadcasting",
                _1002: "Electronic Gaming & Multimedia",
                _1003: "Entertainment",
                _1004: "Internet Content & Information",
                _1005: "Publishing",
                _1006: "Telecom Services",
            },
        },
        _15: {
            name: "Industrials",
            industries: {
                _1500: "Aerospace & Defense",
                _1501: "Airlines",
                _1502: "Airports & Air Services",
                _1503: "Building Products & Equipment",
                _1504: "Business Equipment & Supplies",
                _1505: "Conglomerates",
                _1506: "Consulting Services",
                _1507: "Electrical Equipment & Parts",
                _1508: "Engineering & Construction",
                _1509: "Farm & Heavy Construction Machinery",
                _1510: "Industrial Distribution",
                _1511: "Infrastructure Operations",
                _1512: "Integrated Freight & Logistics",
                _1513: "Marine Shipping",
                _1514: "Metal Fabrication",
                _1515: "Pollution & Treatment Controls",
                _1516: "Railroads",
                _1517: "Rental & Leasing Services",
                _1518: "Security & Protection Services",
                _1519: "Specialty Business Services",
                _1520: "Specialty Industrial Machinery",
                _1521: "Staffing & Employment Services",
                _1522: "Tools & Accessories",
                _1523: "Trucking",
                _1524: "Waste Management",
            },
        },
        _20: {
            name: "Basic Materials",
            industries: {
                _2000: "Agricultural Inputs",
                _2001: "Aluminum",
                _2002: "Building Materials",
                _2003: "Chemicals",
                _2004: "Coking Coal",
                _2005: "Copper",
                _2006: "Gold",
                _2007: "Lumber & Wood Production",
                _2008: "Oil & Gas Pipelines",
                _2009: "Other Industrial Metals & Mining",
                _2010: "Other Precious Metals & Mining",
                _2011: "Paper & Paper Products",
                _2012: "Specialty Chemicals",
                _2013: "Steel",
            },
        },
        _25: {
            name: "Consumer Cyclical",
            industries: {
                _2500: "Apparel Manufacturing",
                _2501: "Apparel Retail",
                _2502: "Auto Manufacturers",
                _2503: "Auto Parts",
                _2504: "Auto & Truck Dealerships",
                _2505: "Department Stores",
                _2506: "Footwear & Accessories",
                _2507: "Furnishings, Fixtures & Appliances",
                _2508: "Gambling",
                _2509: "Home Improvement Retail",
                _2510: "Internet Retail",
                _2511: "Leisure",
                _2512: "Lodging",
                _2513: "Luxury Goods",
                _2514: "Packaging & Containers",
                _2515: "Personal Services",
                _2516: "Recreational Vehicles",
                _2517: "Residential Construction",
                _2518: "Resorts & Casinos",
                _2519: "Restaurants",
                _2520: "Specialty Retail",
                _2521: "Textile Manufacturing",
                _2522: "Travel Services",
            },
        },
        _30: {
            name: "Financial Services",
            industries: {
                _3000: "Asset Management",
                _3001: "Banks-Diversified",
                _3002: "Banks-Regional",
                _3003: "Capital Markets",
                _3004: "Credit Services",
                _3005: "Financial Conglomerates",
                _3006: "Financial Data & Stock Exchanges",
                _3007: "Insurance Brokers",
                _3008: "Insurance-Diversified",
                _3009: "Insurance-Life",
                _3010: "Insurance-Property & Casualty",
                _3011: "Insurance-Reinsurance",
                _3012: "Insurance-Specialty",
                _3013: "Mortgage Finance",
                _3014: "Shell Companies",
            },
        },
        _35: {
            name: "Consumer Defensive",
            industries: {
                _3500: "Beverages-Brewers",
                _3501: "Beverages-Non-Alcoholic",
                _3502: "Beverages-Wineries & Distilleries",
                _3503: "Confectioners",
                _3504: "Discount Stores",
                _3505: "Education & Training Services",
                _3506: "Farm Products",
                _3507: "Food Distribution",
                _3508: "Grocery Stores",
                _3509: "Household & Personal Products",
                _3510: "Packaged Foods",
                _3511: "Tobacco",
            },
        },
        _40: {
            name: "Healthcare",
            industries: {
                _4000: "Biotechnology",
                _4001: "Diagnostics & Research",
                _4002: "Drug Manufacturers-General",
                _4003: "Drug Manufacturers-Specialty & Generic",
                _4004: "Healthcare Plans",
                _4005: "Health Information Services",
                _4006: "Medical Care Facilities",
                _4007: "Medical Devices",
                _4008: "Medical Distribution",
                _4009: "Medical Instruments & Supplies",
                _4010: "Pharmaceutical Retailers",
            },
        },
        _45: {
            name: "Technology",
            industries: {
                _4500: "Communication Equipment",
                _4501: "Computer Hardware",
                _4502: "Consumer Electronics",
                _4503: "Electronic Components",
                _4504: "Electronics & Computer Distribution",
                _4505: "Information Technology Services",
                _4506: "Scientific & Technical Instruments",
                _4507: "Semiconductor Equipment & Materials",
                _4508: "Semiconductors",
                _4509: "Software-Application",
                _4510: "Software-Infrastructure",
                _4511: "Solar",
            },
        },
        _50: {
            name: "Industrial Goods",
            industries: { _5000: "Diversified Machinery" },
        },
        _55: {
            name: "Energy",
            industries: {
                _5500: "Oil & Gas Drilling",
                _5501: "Oil & Gas E&P",
                _5502: "Oil & Gas Equipment & Services",
                _5503: "Oil & Gas Integrated",
                _5504: "Oil & Gas Midstream",
                _5505: "Oil & Gas Refining & Marketing",
                _5506: "Thermal Coal",
                _5507: "Uranium",
            },
        },
        _60: { name: "Other", industries: { _6000: "Other" } },
        _65: {
            name: "Real Estate",
            industries: {
                _6500: "Real Estate-Development",
                _6501: "Real Estate-Diversified",
                _6502: "Real Estate Services",
                _6503: "REIT-Diversified",
                _6504: "REIT-Healthcare Facilities",
                _6505: "REIT-Hotel & Motel",
                _6506: "REIT-Industrial",
                _6507: "REIT-Mortgage",
                _6508: "REIT-Office",
                _6509: "REIT-Residential",
                _6510: "REIT-Retail",
                _6511: "REIT-Specialty",
            },
        },
        _70: {
            name: "Utilities",
            industries: {
                _7000: "Utilities-Diversified",
                _7001: "Utilities-Independent Power Producers",
                _7002: "Utilities-Regulated Electric",
                _7003: "Utilities-Regulated Gas",
                _7004: "Utilities-Regulated Water",
                _7005: "Utilities-Renewable",
            },
        },
    };

    let industries = {
        10: {
            code: 10,
            name: "Energy",
            contains: {
                1010: {
                    code: 1010,
                    name: "Energy",
                    contains: {
                        101010: {
                            code: 101010,
                            name: "Energy Equipment & Services",
                            contains: {
                                10101010: {
                                    code: 10101010,
                                    name: "Oil & Gas Drilling",
                                },
                                10101020: {
                                    code: 10101020,
                                    name: "Oil & Gas Equipment & Services",
                                },
                            },
                        },
                        101020: {
                            code: 101020,
                            name: "Oil, Gas & Consumable Fuels",
                            contains: {
                                10102010: {
                                    code: 10102010,
                                    name: "Integrated Oil & Gas",
                                },
                                10102020: {
                                    code: 10102020,
                                    name: "Oil & Gas Exploration & Production",
                                },
                                10102030: {
                                    code: 10102030,
                                    name: "Oil & Gas Refining & Marketing",
                                },
                                10102040: {
                                    code: 10102040,
                                    name: "Oil & Gas Storage & Transportation",
                                },
                                10102050: {
                                    code: 10102050,
                                    name: "Coal & Consumable Fuels",
                                },
                            },
                        },
                    },
                },
            },
        },
        15: {
            code: 15,
            name: "Materials",
            contains: {
                1510: {
                    code: 1510,
                    name: "Materials",
                    contains: {
                        151010: {
                            code: 151010,
                            name: "Chemicals",
                            contains: {
                                15101010: {
                                    code: 15101010,
                                    name: "Commodity Chemicals",
                                },
                                15101020: {
                                    code: 15101020,
                                    name: "Diversified Chemicals",
                                },
                                15101030: {
                                    code: 15101030,
                                    name: "Fertilizers & Agricultural Chemicals",
                                },
                                15101040: {
                                    code: 15101040,
                                    name: "Industrial Gases",
                                },
                                15101050: {
                                    code: 15101050,
                                    name: "Specialty Chemicals",
                                },
                            },
                        },
                        151020: {
                            code: 151020,
                            name: "Construction Materials",
                            contains: {
                                15102010: {
                                    code: 15102010,
                                    name: "Construction Materials",
                                },
                            },
                        },
                        151030: {
                            code: 151030,
                            name: "Containers & Packaging",
                            contains: {
                                15103010: {
                                    code: 15103010,
                                    name: "Metal & Glass Containers",
                                },
                                15103020: {
                                    code: 15103020,
                                    name: "Paper Packaging",
                                },
                            },
                        },
                        151040: {
                            code: 151040,
                            name: "Metals & Mining",
                            contains: {
                                15104010: {
                                    code: 15104010,
                                    name: "Aluminum",
                                },
                                15104020: {
                                    code: 15104020,
                                    name: "Diversified Metals & Mining",
                                },
                                15104025: {
                                    code: 15104025,
                                    name: "Copper",
                                },
                                15104030: {
                                    code: 15104030,
                                    name: "Gold",
                                },
                                15104040: {
                                    code: 15104040,
                                    name: "Precious Metals & Minerals",
                                },
                                15104045: {
                                    code: 15104045,
                                    name: "Silver",
                                },
                                15104050: {
                                    code: 15104050,
                                    name: "Steel",
                                },
                            },
                        },
                        151050: {
                            code: 151050,
                            name: "Paper & Forest Products",
                            contains: {
                                15105010: {
                                    code: 15105010,
                                    name: "Forest Products",
                                },
                                15105020: {
                                    code: 15105020,
                                    name: "Paper Products",
                                },
                            },
                        },
                    },
                },
            },
        },
        20: {
            code: 20,
            name: "Industrials",
            contains: {
                2010: {
                    code: 2010,
                    name: "Capital Goods",
                    contains: {
                        201010: {
                            code: 201010,
                            name: "Aerospace & Defense",
                            contains: {
                                20101010: {
                                    code: 20101010,
                                    name: "Aerospace & Defense",
                                },
                            },
                        },
                        201020: {
                            code: 201020,
                            name: "Building Products",
                            contains: {
                                20102010: {
                                    code: 20102010,
                                    name: "Building Products",
                                },
                            },
                        },
                        201030: {
                            code: 201030,
                            name: "Construction & Engineering",
                            contains: {
                                20103010: {
                                    code: 20103010,
                                    name: "Construction & Engineering",
                                },
                            },
                        },
                        201040: {
                            code: 201040,
                            name: "Electrical Equipment",
                            contains: {
                                20104010: {
                                    code: 20104010,
                                    name: "Electrical Components & Equipment",
                                },
                                20104020: {
                                    code: 20104020,
                                    name: "Heavy Electrical Equipment",
                                },
                            },
                        },
                        201050: {
                            code: 201050,
                            name: "Industrial Conglomerates",
                            contains: {
                                20105010: {
                                    code: 20105010,
                                    name: "Industrial Conglomerates",
                                },
                            },
                        },
                        201060: {
                            code: 201060,
                            name: "Machinery",
                            contains: {
                                20106010: {
                                    code: 20106010,
                                    name: "Construction Machinery & Heavy Trucks",
                                },
                                20106015: {
                                    code: 20106015,
                                    name: "Agricultural & Farm Machinery",
                                },
                                20106020: {
                                    code: 20106020,
                                    name: "Industrial Machinery",
                                },
                            },
                        },
                        201070: {
                            code: 201070,
                            name: "Trading Companies & Distributors",
                            contains: {
                                20107010: {
                                    code: 20107010,
                                    name: "Trading Companies & Distributors",
                                },
                            },
                        },
                    },
                },
                2020: {
                    code: 2020,
                    name: "Commercial & Professional Services",
                    contains: {
                        202010: {
                            code: 202010,
                            name: "Commercial Services & Supplies",
                            contains: {
                                20201010: {
                                    code: 20201010,
                                    name: "Commercial Printing",
                                },
                                20201050: {
                                    code: 20201050,
                                    name: "Environmental & Facilities Services",
                                },
                                20201060: {
                                    code: 20201060,
                                    name: "Office Services & Supplies",
                                },
                                20201070: {
                                    code: 20201070,
                                    name: "Diversified Support Services",
                                },
                                20201080: {
                                    code: 20201080,
                                    name: "Security & Alarm Services",
                                },
                            },
                        },
                        202020: {
                            code: 202020,
                            name: "Professional Services",
                            contains: {
                                20202010: {
                                    code: 20202010,
                                    name: "Human Resource & Employment Services",
                                },
                                20202020: {
                                    code: 20202020,
                                    name: "Research & Consulting Services",
                                },
                            },
                        },
                    },
                },
                2030: {
                    code: 2030,
                    name: "Transportation",
                    contains: {
                        203010: {
                            code: 203010,
                            name: "Air Freight & Logistics",
                            contains: {
                                20301010: {
                                    code: 20301010,
                                    name: "Air Freight & Logistics",
                                },
                            },
                        },
                        203020: {
                            code: 203020,
                            name: "Airlines",
                            contains: {
                                20302010: {
                                    code: 20302010,
                                    name: "Airlines",
                                },
                            },
                        },
                        203030: {
                            code: 203030,
                            name: "Marine",
                            contains: {
                                20303010: {
                                    code: 20303010,
                                    name: "Marine",
                                },
                            },
                        },
                        203040: {
                            code: 203040,
                            name: "Road & Rail",
                            contains: {
                                20304010: {
                                    code: 20304010,
                                    name: "Railroads",
                                },
                                20304020: {
                                    code: 20304020,
                                    name: "Trucking",
                                },
                            },
                        },
                        203050: {
                            code: 203050,
                            name: "Transportation Infrastructure",
                            contains: {
                                20305010: {
                                    code: 20305010,
                                    name: "Airport Services",
                                },
                                20305020: {
                                    code: 20305020,
                                    name: "Highways & Railtracks",
                                },
                                20305030: {
                                    code: 20305030,
                                    name: "Marine Ports & Services",
                                },
                            },
                        },
                    },
                },
            },
        },
        25: {
            code: 25,
            name: "Consumer Discretionary",
            contains: {
                2510: {
                    code: 2510,
                    name: "Automobiles & Components",
                    contains: {
                        251010: {
                            code: 251010,
                            name: "Auto Components",
                            contains: {
                                25101010: {
                                    code: 25101010,
                                    name: "Auto Parts & Equipment",
                                },
                                25101020: {
                                    code: 25101020,
                                    name: "Tires & Rubber",
                                },
                            },
                        },
                        251020: {
                            code: 251020,
                            name: "Automobiles",
                            contains: {
                                25102010: {
                                    code: 25102010,
                                    name: "Automobile Manufacturers",
                                },
                                25102020: {
                                    code: 25102020,
                                    name: "Motorcycle Manufacturers",
                                },
                            },
                        },
                    },
                },
                2520: {
                    code: 2520,
                    name: "Consumer Durables & Apparel",
                    contains: {
                        252010: {
                            code: 252010,
                            name: "Household Durables",
                            contains: {
                                25201010: {
                                    code: 25201010,
                                    name: "Consumer Electronics",
                                },
                                25201020: {
                                    code: 25201020,
                                    name: "Home Furnishings",
                                },
                                25201030: {
                                    code: 25201030,
                                    name: "Homebuilding",
                                },
                                25201040: {
                                    code: 25201040,
                                    name: "Household Appliances",
                                },
                                25201050: {
                                    code: 25201050,
                                    name: "Housewares & Specialties",
                                },
                            },
                        },
                        252020: {
                            code: 252020,
                            name: "Leisure Products",
                            contains: {
                                25202010: {
                                    code: 25202010,
                                    name: "Leisure Products",
                                },
                            },
                        },
                        252030: {
                            code: 252030,
                            name: "Textiles, Apparel & Luxury Goods",
                            contains: {
                                25203010: {
                                    code: 25203010,
                                    name: "Apparel, Accessories & Luxury Goods",
                                },
                                25203020: {
                                    code: 25203020,
                                    name: "Footwear",
                                },
                                25203030: {
                                    code: 25203030,
                                    name: "Textiles",
                                },
                            },
                        },
                    },
                },
                2530: {
                    code: 2530,
                    name: "Consumer Services",
                    contains: {
                        253010: {
                            code: 253010,
                            name: "Hotels, Restaurants & Leisure",
                            contains: {
                                25301010: {
                                    code: 25301010,
                                    name: "Casinos & Gaming",
                                },
                                25301020: {
                                    code: 25301020,
                                    name: "Hotels, Resorts & Cruise Lines",
                                },
                                25301030: {
                                    code: 25301030,
                                    name: "Leisure Facilities",
                                },
                                25301040: {
                                    code: 25301040,
                                    name: "Restaurants",
                                },
                            },
                        },
                        253020: {
                            code: 253020,
                            name: "Diversified Consumer Services",
                            contains: {
                                25302010: {
                                    code: 25302010,
                                    name: "Education Services",
                                },
                                25302020: {
                                    code: 25302020,
                                    name: "Specialized Consumer Services",
                                },
                            },
                        },
                    },
                },
                2550: {
                    code: 2550,
                    name: "Retailing",
                    contains: {
                        255010: {
                            code: 255010,
                            name: "Distributors",
                            contains: {
                                25501010: {
                                    code: 25501010,
                                    name: "Distributors",
                                },
                            },
                        },
                        255020: {
                            code: 255020,
                            name: "Internet & Direct Marketing Retail",
                            contains: {
                                25502020: {
                                    code: 25502020,
                                    name: "Internet & Direct Marketing Retail",
                                },
                            },
                        },
                        255030: {
                            code: 255030,
                            name: "Multiline Retail",
                            contains: {
                                25503010: {
                                    code: 25503010,
                                    name: "Department Stores",
                                },
                                25503020: {
                                    code: 25503020,
                                    name: "General Merchandise Stores",
                                },
                            },
                        },
                        255040: {
                            code: 255040,
                            name: "Specialty Retail",
                            contains: {
                                25504010: {
                                    code: 25504010,
                                    name: "Apparel Retail",
                                },
                                25504020: {
                                    code: 25504020,
                                    name: "Computer & Electronics Retail",
                                },
                                25504030: {
                                    code: 25504030,
                                    name: "Home Improvement Retail",
                                },
                                25504040: {
                                    code: 25504040,
                                    name: "Specialty Stores",
                                },
                                25504050: {
                                    code: 25504050,
                                    name: "Automotive Retail",
                                },
                                25504060: {
                                    code: 25504060,
                                    name: "Homefurnishing Retail",
                                },
                            },
                        },
                    },
                },
            },
        },
        30: {
            code: 30,
            name: "Consumer Staples",
            contains: {
                3010: {
                    code: 3010,
                    name: "Food & Staples Retailing",
                    contains: {
                        301010: {
                            code: 301010,
                            name: "Food & Staples Retailing",
                            contains: {
                                30101010: {
                                    code: 30101010,
                                    name: "Drug Retail",
                                },
                                30101020: {
                                    code: 30101020,
                                    name: "Food Distributors",
                                },
                                30101030: {
                                    code: 30101030,
                                    name: "Food Retail",
                                },
                                30101040: {
                                    code: 30101040,
                                    name: "Hypermarkets & Super Centers",
                                },
                            },
                        },
                    },
                },
                3020: {
                    code: 3020,
                    name: "Food, Beverage & Tobacco",
                    contains: {
                        302010: {
                            code: 302010,
                            name: "Beverages",
                            contains: {
                                30201010: {
                                    code: 30201010,
                                    name: "Brewers",
                                },
                                30201020: {
                                    code: 30201020,
                                    name: "Distillers & Vintners",
                                },
                                30201030: {
                                    code: 30201030,
                                    name: "Soft Drinks",
                                },
                            },
                        },
                        302020: {
                            code: 302020,
                            name: "Food Products",
                            contains: {
                                30202010: {
                                    code: 30202010,
                                    name: "Agricultural Products",
                                },
                                30202030: {
                                    code: 30202030,
                                    name: "Packaged Foods & Meats",
                                },
                            },
                        },
                        302030: {
                            code: 302030,
                            name: "Tobacco",
                            contains: {
                                30203010: {
                                    code: 30203010,
                                    name: "Tobacco",
                                },
                            },
                        },
                    },
                },
                3030: {
                    code: 3030,
                    name: "Household & Personal Products",
                    contains: {
                        303010: {
                            code: 303010,
                            name: "Household Products",
                            contains: {
                                30301010: {
                                    code: 30301010,
                                    name: "Household Products",
                                },
                            },
                        },
                        303020: {
                            code: 303020,
                            name: "Personal Products",
                            contains: {
                                30302010: {
                                    code: 30302010,
                                    name: "Personal Products",
                                },
                            },
                        },
                    },
                },
            },
        },
        35: {
            code: 35,
            name: "Health Care",
            contains: {
                3510: {
                    code: 3510,
                    name: "Health Care Equipment & Services",
                    contains: {
                        351010: {
                            code: 351010,
                            name: "Health Care Equipment & Supplies",
                            contains: {
                                35101010: {
                                    code: 35101010,
                                    name: "Health Care Equipment",
                                },
                                35101020: {
                                    code: 35101020,
                                    name: "Health Care Supplies",
                                },
                            },
                        },
                        351020: {
                            code: 351020,
                            name: "Health Care Providers & Services",
                            contains: {
                                35102010: {
                                    code: 35102010,
                                    name: "Health Care Distributors",
                                },
                                35102015: {
                                    code: 35102015,
                                    name: "Health Care Services",
                                },
                                35102020: {
                                    code: 35102020,
                                    name: "Health Care Facilities",
                                },
                                35102030: {
                                    code: 35102030,
                                    name: "Managed Health Care",
                                },
                            },
                        },
                        351030: {
                            code: 351030,
                            name: "Health Care Technology",
                            contains: {
                                35103010: {
                                    code: 35103010,
                                    name: "Health Care Technology",
                                },
                            },
                        },
                    },
                },
                3520: {
                    code: 3520,
                    name: "Pharmaceuticals, Biotechnology  & Life Sciences",
                    contains: {
                        352010: {
                            code: 352010,
                            name: "Biotechnology",
                            contains: {
                                35201010: {
                                    code: 35201010,
                                    name: "Biotechnology",
                                },
                            },
                        },
                        352020: {
                            code: 352020,
                            name: "Pharmaceuticals",
                            contains: {
                                35202010: {
                                    code: 35202010,
                                    name: "Pharmaceuticals",
                                },
                            },
                        },
                        352030: {
                            code: 352030,
                            name: "Life Sciences Tools & Services",
                            contains: {
                                35203010: {
                                    code: 35203010,
                                    name: "Life Sciences Tools & Services",
                                },
                            },
                        },
                    },
                },
            },
        },
        40: {
            code: 40,
            name: "Financials",
            contains: {
                4010: {
                    code: 4010,
                    name: "Banks",
                    contains: {
                        401010: {
                            code: 401010,
                            name: "Banks",
                            contains: {
                                40101010: {
                                    code: 40101010,
                                    name: "Diversified Banks",
                                },
                                40101015: {
                                    code: 40101015,
                                    name: "Regional Banks",
                                },
                            },
                        },
                        401020: {
                            code: 401020,
                            name: "Thrifts & Mortgage Finance",
                            contains: {
                                40102010: {
                                    code: 40102010,
                                    name: "Thrifts & Mortgage Finance",
                                },
                            },
                        },
                    },
                },
                4020: {
                    code: 4020,
                    name: "Diversified Financials",
                    contains: {
                        402010: {
                            code: 402010,
                            name: "Diversified Financial Services",
                            contains: {
                                40201020: {
                                    code: 40201020,
                                    name: "Other Diversified Financial Services",
                                },
                                40201030: {
                                    code: 40201030,
                                    name: "Multi-Sector Holdings",
                                },
                                40201040: {
                                    code: 40201040,
                                    name: "Specialized Finance",
                                },
                            },
                        },
                        402020: {
                            code: 402020,
                            name: "Consumer Finance",
                            contains: {
                                40202010: {
                                    code: 40202010,
                                    name: "Consumer Finance",
                                },
                            },
                        },
                        402030: {
                            code: 402030,
                            name: "Capital Markets",
                            contains: {
                                40203010: {
                                    code: 40203010,
                                    name: "Asset Management & Custody Banks",
                                },
                                40203020: {
                                    code: 40203020,
                                    name: "Investment Banking & Brokerage",
                                },
                                40203030: {
                                    code: 40203030,
                                    name: "Diversified Capital Markets",
                                },
                                40203040: {
                                    code: 40203040,
                                    name: "Financial Exchanges & Data",
                                },
                            },
                        },
                        402040: {
                            code: 402040,
                            name: "Mortgage Real Estate Investment Trusts (REITs)",
                            contains: {
                                40204010: {
                                    code: 40204010,
                                    name: "Mortgage REITs",
                                },
                            },
                        },
                    },
                },
                4030: {
                    code: 4030,
                    name: "Insurance",
                    contains: {
                        403010: {
                            code: 403010,
                            name: "Insurance",
                            contains: {
                                40301010: {
                                    code: 40301010,
                                    name: "Insurance Brokers",
                                },
                                40301020: {
                                    code: 40301020,
                                    name: "Life & Health Insurance",
                                },
                                40301030: {
                                    code: 40301030,
                                    name: "Multi-line Insurance",
                                },
                                40301040: {
                                    code: 40301040,
                                    name: "Property & Casualty Insurance",
                                },
                                40301050: {
                                    code: 40301050,
                                    name: "Reinsurance",
                                },
                            },
                        },
                    },
                },
            },
        },
        45: {
            code: 45,
            name: "Information Technology",
            contains: {
                4510: {
                    code: 4510,
                    name: "Software & Services",
                    contains: {
                        451020: {
                            code: 451020,
                            name: "IT Services",
                            contains: {
                                45102010: {
                                    code: 45102010,
                                    name: "IT Consulting & Other Services",
                                },
                                45102020: {
                                    code: 45102020,
                                    name: "Data Processing & Outsourced Services",
                                },
                                45102030: {
                                    code: 45102030,
                                    name: "Internet Services & Infrastructure",
                                },
                            },
                        },
                        451030: {
                            code: 451030,
                            name: "Software",
                            contains: {
                                45103010: {
                                    code: 45103010,
                                    name: "Application Software",
                                },
                                45103020: {
                                    code: 45103020,
                                    name: "Systems Software",
                                },
                            },
                        },
                    },
                },
                4520: {
                    code: 4520,
                    name: "Technology Hardware & Equipment",
                    contains: {
                        452010: {
                            code: 452010,
                            name: "Communications Equipment",
                            contains: {
                                45201020: {
                                    code: 45201020,
                                    name: "Communications Equipment",
                                },
                            },
                        },
                        452020: {
                            code: 452020,
                            name: "Technology Hardware, Storage & Peripherals",
                            contains: {
                                45202030: {
                                    code: 45202030,
                                    name: "Technology Hardware, Storage & Peripherals",
                                },
                            },
                        },
                        452030: {
                            code: 452030,
                            name: "Electronic Equipment, Instruments & Components",
                            contains: {
                                45203010: {
                                    code: 45203010,
                                    name: "Electronic Equipment & Instruments",
                                },
                                45203015: {
                                    code: 45203015,
                                    name: "Electronic Components",
                                },
                                45203020: {
                                    code: 45203020,
                                    name: "Electronic Manufacturing Services",
                                },
                                45203030: {
                                    code: 45203030,
                                    name: "Technology Distributors",
                                },
                            },
                        },
                    },
                },
                4530: {
                    code: 4530,
                    name: "Semiconductors & Semiconductor Equipment",
                    contains: {
                        453010: {
                            code: 453010,
                            name: "Semiconductors & Semiconductor Equipment",
                            contains: {
                                45301010: {
                                    code: 45301010,
                                    name: "Semiconductor Equipment",
                                },
                                45301020: {
                                    code: 45301020,
                                    name: "Semiconductors",
                                },
                            },
                        },
                    },
                },
            },
        },
        50: {
            code: 50,
            name: "Communication Services",
            contains: {
                5010: {
                    code: 5010,
                    name: "Telecommunication Services",
                    contains: {
                        501010: {
                            code: 501010,
                            name: "Diversified Telecommunication Services",
                            contains: {
                                50101010: {
                                    code: 50101010,
                                    name: "Alternative Carriers",
                                },
                                50101020: {
                                    code: 50101020,
                                    name: "Integrated Telecommunication Services",
                                },
                            },
                        },
                        501020: {
                            code: 501020,
                            name: "Wireless Telecommunication Services",
                            contains: {
                                50102010: {
                                    code: 50102010,
                                    name: "Wireless Telecommunication Services",
                                },
                            },
                        },
                    },
                },
                5020: {
                    code: 5020,
                    name: "Media & Entertainment",
                    contains: {
                        502010: {
                            code: 502010,
                            name: "Media",
                            contains: {
                                50201010: {
                                    code: 50201010,
                                    name: "Advertising",
                                },
                                50201020: {
                                    code: 50201020,
                                    name: "Broadcasting",
                                },
                                50201030: {
                                    code: 50201030,
                                    name: "Cable & Satellite",
                                },
                                50201040: {
                                    code: 50201040,
                                    name: "Publishing",
                                },
                            },
                        },
                        502020: {
                            code: 502020,
                            name: "Entertainment",
                            contains: {
                                50202010: {
                                    code: 50202010,
                                    name: "Movies & Entertainment",
                                },
                                50202020: {
                                    code: 50202020,
                                    name: "Interactive Home Entertainment",
                                },
                            },
                        },
                        502030: {
                            code: 502030,
                            name: "Interactive Media & Services",
                            contains: {
                                50203010: {
                                    code: 50203010,
                                    name: "Interactive Media & Services",
                                },
                            },
                        },
                    },
                },
            },
        },
        55: {
            code: 55,
            name: "Utilities",
            contains: {
                5510: {
                    code: 5510,
                    name: "Utilities",
                    contains: {
                        551010: {
                            code: 551010,
                            name: "Electric Utilities",
                            contains: {
                                55101010: {
                                    code: 55101010,
                                    name: "Electric Utilities",
                                },
                            },
                        },
                        551020: {
                            code: 551020,
                            name: "Gas Utilities",
                            contains: {
                                55102010: {
                                    code: 55102010,
                                    name: "Gas Utilities",
                                },
                            },
                        },
                        551030: {
                            code: 551030,
                            name: "Multi-Utilities",
                            contains: {
                                55103010: {
                                    code: 55103010,
                                    name: "Multi-Utilities",
                                },
                            },
                        },
                        551040: {
                            code: 551040,
                            name: "Water Utilities",
                            contains: {
                                55104010: {
                                    code: 55104010,
                                    name: "Water Utilities",
                                },
                            },
                        },
                        551050: {
                            code: 551050,
                            name: "Independent Power and Renewable Electricity Producers",
                            contains: {
                                55105010: {
                                    code: 55105010,
                                    name: "Independent Power Producers & Energy Traders",
                                },
                                55105020: {
                                    code: 55105020,
                                    name: "Renewable Electricity",
                                },
                            },
                        },
                    },
                },
            },
        },
        60: {
            code: 60,
            name: "Real Estate",
            contains: {
                6010: {
                    code: 6010,
                    name: "Real Estate",
                    contains: {
                        601010: {
                            code: 601010,
                            name: "Equity Real Estate Investment Trusts (REITs)",
                            contains: {
                                60101010: {
                                    code: 60101010,
                                    name: "Diversified REITs",
                                },
                                60101020: {
                                    code: 60101020,
                                    name: "Industrial REITs",
                                },
                                60101030: {
                                    code: 60101030,
                                    name: "Hotel & Resort REITs",
                                },
                                60101040: {
                                    code: 60101040,
                                    name: "Office REITs",
                                },
                                60101050: {
                                    code: 60101050,
                                    name: "Health Care REITs",
                                },
                                60101060: {
                                    code: 60101060,
                                    name: "Residential REITs",
                                },
                                60101070: {
                                    code: 60101070,
                                    name: "Retail REITs",
                                },
                                60101080: {
                                    code: 60101080,
                                    name: "Specialized REITs",
                                },
                            },
                        },
                        601020: {
                            code: 601020,
                            name: "Real Estate Management & Development",
                            contains: {
                                60102010: {
                                    code: 60102010,
                                    name: "Diversified Real Estate Activities",
                                },
                                60102020: {
                                    code: 60102020,
                                    name: "Real Estate Operating Companies",
                                },
                                60102030: {
                                    code: 60102030,
                                    name: "Real Estate Development",
                                },
                                60102040: {
                                    code: 60102040,
                                    name: "Real Estate Services",
                                },
                            },
                        },
                    },
                },
            },
        },
    };

    const [metrics, setMetrics] = useState({
        beta: {
            property: "beta",
            displayName: useintl.formatMessage({
                id: "metrics.beta",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.beta",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.beta.description_text_1",
            }),
        },
        implied_volatility: {
            property: "implied_volatility",
            displayName: useintl.formatMessage({
                id: "metrics.implied_volatility",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.implied_volatility",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.implied_volatility.description_text_1",
            }),
        },
        short_interest_ratio: {
            property: "short_interest_ratio",
            displayName: useintl.formatMessage({
                id: "metrics.short_interest_ratio",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_interest_ratio",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_interest_ratio.description_text_1",
            }),
        },
        short_ratio_float: {
            property: "short_ratio_float",
            displayName: useintl.formatMessage({
                id: "metrics.short_ratio_float",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_ratio_float",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_ratio_float.description_text_1",
            }),
        },
        short_ratio_outstanding: {
            property: "short_ratio_outstanding",
            displayName: useintl.formatMessage({
                id: "metrics.short_ratio_outstanding",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_ratio_outstanding",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_ratio_outstanding.description_text_1",
            }),
        },
        avg_rating_analyst: {
            property: "avg_rating_analyst",
            displayName: useintl.formatMessage({
                id: "metrics.avg_rating_analyst",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.avg_rating_analyst",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.avg_rating_analyst.description_text_1",
            }),
        },
        target_price_analyst: {
            property: "target_price_analyst",
            displayName: useintl.formatMessage({
                id: "metrics.target_price_analyst",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.target_price_analyst",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.avg_rating_analyst.description_text_1",
            }),
        },
        short_interest_ratio: {
            property: "short_interest_ratio",
            displayName: useintl.formatMessage({
                id: "metrics.short_interest_ratio",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_interest_ratio",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_interest_ratio.description_text_1",
            }),
        },
        short_ratio_float: {
            property: "short_ratio_float",
            displayName: useintl.formatMessage({
                id: "metrics.short_ratio_float",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_ratio_float",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_ratio_float.description_text_1",
            }),
        },
        short_ratio_outstanding: {
            property: "short_ratio_outstanding",
            displayName: useintl.formatMessage({
                id: "metrics.short_ratio_outstanding",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_ratio_outstanding",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_ratio_outstanding.description_text_1",
            }),
        },
        shares_short: {
            property: "shares_short",
            displayName: useintl.formatMessage({
                id: "metrics.shares_short",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.shares_short",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.shares_short.description_text_1",
            }),
        },
        shares_short_prior_month: {
            property: "shares_short_prior_month",
            displayName: useintl.formatMessage({
                id: "metrics.shares_short_prior_month",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.shares_short_prior_month",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.shares_short_prior_month.description_text_1",
            }),
        },
        shares_held_by_insiders: {
            property: "shares_held_by_insiders",
            displayName: useintl.formatMessage({
                id: "metrics.shares_held_by_insiders",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.shares_held_by_insiders",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.shares_held_by_insiders.description_text_1",
            }),
        },
        shares_held_by_institutions: {
            property: "shares_held_by_institutions",
            displayName: useintl.formatMessage({
                id: "metrics.shares_held_by_institutions",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.shares_held_by_institutions",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.shares_held_by_institutions.description_text_1",
            }),
        },
        outstanding_shares: {
            property: "outstanding_shares",
            displayName: useintl.formatMessage({
                id: "metrics.outstanding_shares",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.outstanding_shares",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.outstanding_shares.description_text_1",
            }),
        },
        outstanding_shares_change: {
            property: "outstanding_shares_change",
            displayName: useintl.formatMessage({
                id: "metrics.outstanding_shares_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.outstanding_shares_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.outstanding_shares.description_text_1",
            }),
        },
        shares_float: {
            property: "shares_float",
            displayName: useintl.formatMessage({
                id: "metrics.shares_float",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.shares_float",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.shares_float.description_text_1",
            }),
        },
        market_cap_usd: {
            property: "market_cap_usd",
            displayName: useintl.formatMessage({
                id: "metrics.market_cap_usd",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.market_cap_usd",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.market_cap_usd.description_text_1",
            }),
        },
        ev: {
            property: "ev",
            displayName: useintl.formatMessage({
                id: "metrics.ev",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.ev",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev.description_text_1",
            }),
        },
        daily_return: {
            property: "daily_return",
            displayName: useintl.formatMessage({
                id: "metrics.daily_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.daily_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.daily_return.description_text_1",
            }),
        },
        weekly_return: {
            property: "weekly_return",
            displayName: useintl.formatMessage({
                id: "metrics.weekly_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.weekly_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.weekly_return.description_text_1",
            }),
        },
        monthly_return: {
            property: "monthly_return",
            displayName: useintl.formatMessage({
                id: "metrics.monthly_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.monthly_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.monthly_return.description_text_1",
            }),
        },
        quarterly_return: {
            property: "quarterly_return",
            displayName: useintl.formatMessage({
                id: "metrics.quarterly_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.quarterly_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.quarterly_return.description_text_1",
            }),
        },
        semi_annual_return: {
            property: "semi_annual_return",
            displayName: useintl.formatMessage({
                id: "metrics.semi_annual_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.semi_annual_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.semi_annual_return.description_text_1",
            }),
        },
        annual_return: {
            property: "annual_return",
            displayName: useintl.formatMessage({
                id: "metrics.annual_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.annual_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.annual_return.description_text_1",
            }),
        },
        two_year_return: {
            property: "two_year_return",
            displayName: useintl.formatMessage({
                id: "metrics.two_year_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.two_year_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.two_year_return.description_text_1",
            }),
        },
        three_year_return: {
            property: "three_year_return",
            displayName: useintl.formatMessage({
                id: "metrics.three_year_return",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.three_year_return",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.three_year_return.description_text_1",
            }),
        },
        total_revenue: {
            property: "total_revenue",
            displayName: useintl.formatMessage({
                id: "metrics.total_revenue",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.total_revenue",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.total_revenue.description_text_1",
            }),
        },
        free_cashflow: {
            property: "free_cashflow",
            displayName: useintl.formatMessage({
                id: "metrics.free_cashflow",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.free_cashflow",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.free_cashflow.description_text_1",
            }),
        },
        net_income: {
            property: "net_income",
            displayName: useintl.formatMessage({
                id: "metrics.net_income",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.net_income",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.net_income.description_text_1",
            }),
        },
        ebit: {
            property: "ebit",
            displayName: useintl.formatMessage({
                id: "metrics.ebit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.ebit",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ebit.description_text_1",
            }),
        },
        gross_profit: {
            property: "gross_profit",
            displayName: useintl.formatMessage({
                id: "metrics.gross_profit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.gross_profit",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.gross_profit.description_text_1",
            }),
        },
        dividends_paid: {
            property: "dividends_paid",
            displayName: useintl.formatMessage({
                id: "metrics.dividends_paid",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividends_paid",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividends_paid.description_text_1",
            }),
        },
        total_liabilities: {
            property: "total_liabilities",
            displayName: useintl.formatMessage({
                id: "metrics.total_liabilities",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.total_liabilities",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.total_liabilities.description_text_1",
            }),
        },
        total_current_liabilities: {
            property: "total_current_liabilities",
            displayName: useintl.formatMessage({
                id: "metrics.total_current_liabilities",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.total_current_liabilities",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.total_current_liabilities.description_text_1",
            }),
        },
        total_assets: {
            property: "total_assets",
            displayName: useintl.formatMessage({
                id: "metrics.total_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.total_assets",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.total_assets.description_text_1",
            }),
        },
        total_current_assets: {
            property: "total_current_assets",
            displayName: useintl.formatMessage({
                id: "metrics.total_current_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.total_current_assets",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.total_current_assets.description_text_1",
            }),
        },
        research_development: {
            property: "research_development",
            displayName: useintl.formatMessage({
                id: "metrics.research_development",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.research_development",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.research_development.description_text_1",
            }),
        },
        market_cap: {
            property: "market_cap",
            displayName: useintl.formatMessage({
                id: "metrics.market_cap",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.market_cap",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.market_cap.description_text_1",
            }),
        },
        market_cap_change: {
            property: "market_cap_change",
            displayName: useintl.formatMessage({
                id: "metrics.market_cap_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.market_cap_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.market_cap.description_text_1",
            }),
        },
        market_cap_ranker: {
            property: "market_cap_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.market_cap_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.market_cap_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.market_cap.description_text_1",
            }),
        },
        market_cap_ranker_change: {
            property: "market_cap_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.market_cap_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.market_cap_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.market_cap.description_text_1",
            }),
        },
        volume_deviation: {
            property: "volume_deviation",
            displayName: useintl.formatMessage({
                id: "metrics.volume_deviation",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.volume_deviation",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.volume.description_text_1",
            }),
        },
        return_on_capital_ranker: {
            property: "return_on_capital_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital_ranker",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital_ranker.description_text_1",
            }),
        },
        return_on_net_tangible_assets_ranker: {
            property: "return_on_net_tangible_assets_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_net_tangible_assets_ranker",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets_ranker.description_text_1",
            }),
        },
        return_on_capital: {
            property: "return_on_capital",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital.description_text_1",
            }),
        },
        return_on_net_tangible_assets: {
            property: "return_on_net_tangible_assets",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_net_tangible_assets",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets.description_text_1",
            }),
        },
        return_on_capital: {
            property: "return_on_capital",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital.description_text_1",
            }),
        },
        return_on_net_tangible_assets: {
            property: "return_on_net_tangible_assets",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_net_tangible_assets",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_net_tangible_assets.description_text_1",
            }),
        },
        ev_ebit: {
            property: "ev_ebit",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.ev_ebit",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebit.description_text_1",
            }),
        },
        ev_ebitda: {
            property: "ev_ebitda",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebitda",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.ev_ebitda",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebitda.description_text_1",
            }),
        },
        ebitda: {
            property: "ebitda",
            displayName: useintl.formatMessage({
                id: "metrics.ebitda",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.ebitda",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ebitda.description_text_1",
            }),
        },
        cash: {
            property: "cash",
            displayName: useintl.formatMessage({
                id: "metrics.cash",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash.description_text_1",
            }),
        },
        short_long_term_debt_total: {
            property: "short_long_term_debt_total",
            displayName: useintl.formatMessage({
                id: "metrics.short_long_term_debt_total",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.short_long_term_debt_total",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.short_long_term_debt_total.description_text_1",
            }),
        },
        price_earnings_average_10y: {
            property: "price_earnings_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_average_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_earnings_deviation_10y: {
            property: "price_earnings_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_deviation_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_book_average_10y: {
            property: "price_book_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_book_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book_average_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        price_book_deviation_10y: {
            property: "price_book_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_book_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book_deviation_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        price_sales_average_10y: {
            property: "price_sales_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_average_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_sales_deviation_10y: {
            property: "price_sales_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_deviation_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_cash_flow_average_10y: {
            property: "price_cash_flow_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow_average_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        price_cash_flow_deviation_10y: {
            property: "price_cash_flow_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow_deviation_10y",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        price_earnings: {
            property: "price_earnings",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_earnings_change: {
            property: "price_earnings_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_earnings_ranker: {
            property: "price_earnings_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_earnings_ranker_change: {
            property: "price_earnings_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings.description_text_1",
            }),
        },
        price_earnings_growth: {
            property: "price_earnings_growth",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_growth",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_growth",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "description_text_2" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings_growth.description_text_1",
            }),
            example_math_1: String.raw`\frac{\text{${useintl.formatMessage({
                id: "metrics.math.market_cap",
            })}}}{\text{${useintl.formatMessage({
                id: "metrics.math.earnings",
            })}} \times \text{${useintl.formatMessage({
                id: "metrics.math.earnings_growth",
            })}}}`,
            description_text_2: useintl.formatMessage({
                id: "metrics.price_earnings_growth.description_text_2",
            }),
        },
        price_earnings_growth_change: {
            property: "price_earnings_growth_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_growth_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_growth_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings_growth.description_text_1",
            }),
        },
        price_earnings_growth_ranker: {
            property: "price_earnings_growth_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_growth_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_growth_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings_growth.description_text_1",
            }),
        },
        price_earnings_growth_ranker_change: {
            property: "price_earnings_growth_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_earnings_growth_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_earnings_growth_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_earnings_growth.description_text_1",
            }),
        },
        price_sales: {
            property: "price_sales",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_sales_change: {
            property: "price_sales_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_sales_ranker: {
            property: "price_sales_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_sales_ranker_change: {
            property: "price_sales_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales.description_text_1",
            }),
        },
        price_sales_growth: {
            property: "price_sales_growth",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_growth",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_growth",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales_growth.description_text_1",
            }),
        },
        price_sales_growth_change: {
            property: "price_sales_growth_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_growth_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_growth_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales_growth.description_text_1",
            }),
        },
        price_sales_growth_ranker: {
            property: "price_sales_growth_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_growth_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_growth_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales_growth.description_text_1",
            }),
        },
        price_sales_growth_ranker_change: {
            property: "price_sales_growth_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_sales_growth_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_sales_growth_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_sales_growth.description_text_1",
            }),
        },
        price_book: {
            property: "price_book",
            displayName: useintl.formatMessage({
                id: "metrics.price_book",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        price_book_change: {
            property: "price_book_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_book_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        price_book_ranker: {
            property: "price_book_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_book_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        price_book_ranker_change: {
            property: "price_book_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_book_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_book_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_book.description_text_1",
            }),
        },
        dividend_yield: {
            property: "dividend_yield",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_yield",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_yield",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_yield.description_text_1",
            }),
        },
        dividend_yield_change: {
            property: "dividend_yield_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_yield_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_yield_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_yield.description_text_1",
            }),
        },
        dividend_yield_ranker: {
            property: "dividend_yield_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_yield_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_yield_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_yield.description_text_1",
            }),
        },
        dividend_yield_ranker_change: {
            property: "dividend_yield_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_yield_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_yield_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_yield.description_text_1",
            }),
        },
        payout_ratio: {
            property: "payout_ratio",
            displayName: useintl.formatMessage({
                id: "metrics.payout_ratio",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.payout_ratio",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.payout_ratio.description_text_1",
            }),
        },
        payout_ratio_change: {
            property: "payout_ratio_change",
            displayName: useintl.formatMessage({
                id: "metrics.payout_ratio_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.payout_ratio_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.payout_ratio.description_text_1",
            }),
        },
        payout_ratio_ranker: {
            property: "payout_ratio_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.payout_ratio_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.payout_ratio_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.payout_ratio.description_text_1",
            }),
        },
        payout_ratio_ranker_change: {
            property: "payout_ratio_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.payout_ratio_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.payout_ratio_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.payout_ratio.description_text_1",
            }),
        },
        revenue_growth_1y: {
            property: "revenue_growth_1y",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_1y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_1y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_1y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_1y.example_text_1",
                },
                {
                    company: company,
                    year_1y: year_1y,
                    revenue_1y: numberFormatter(revenue_1y),
                    year: year,
                    revenue: numberFormatter(revenue),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_1y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_1y.example_text_2",
                },
                {
                    revenue_1y_pct: revenue_1y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_1y
            )}}\right) = ${revenue_1y_pct_raw} \text{ }\widehat{=} \text{ } ${revenue_1y_pct}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_1y.disclaimer_text_1",
            }),
        },
        revenue_growth_1y_change: {
            property: "revenue_growth_1y_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_1y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_1y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_1y.description_text_1",
            }),
        },
        revenue_growth_1y_ranker: {
            property: "revenue_growth_1y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_1y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_1y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_1y.description_text_1",
            }),
        },
        revenue_growth_1y_ranker_change: {
            property: "revenue_growth_1y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_1y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_1y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_1y.description_text_1",
            }),
        },

        earnings_growth_1y: {
            property: "earnings_growth_1y",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_1y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_1y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_1y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_1y.example_text_1",
                },
                {
                    company: company,
                    year_1y: year_1y,
                    earnings_1y: numberFormatter(earnings_1y),
                    year: year,
                    earnings: numberFormatter(earnings),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_1y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_1y.example_text_2",
                },
                {
                    earnings_1y_pct: earnings_1y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_1y
            )}}\right) = ${earnings_1y_pct_raw} \text{ }\widehat{=} \text{ } ${earnings_1y_pct}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_1y.disclaimer_text_1",
            }),
        },
        earnings_growth_1y_change: {
            property: "earnings_growth_1y_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_1y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_1y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_1y.description_text_1",
            }),
        },
        earnings_growth_1y_ranker: {
            property: "earnings_growth_1y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_1y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_1y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_1y.description_text_1",
            }),
        },
        earnings_growth_1y_ranker_change: {
            property: "earnings_growth_1y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_1y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_1y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_1y.description_text_1",
            }),
        },
        dividend_growth_1y: {
            property: "dividend_growth_1y",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_1y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_1y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_1y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_1y.example_text_1",
                },
                {
                    company: company,
                    year_1y: year_1y,
                    dividend_1y: numberFormatter(dividend_1y),
                    year: year,
                    dividend: numberFormatter(dividend),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_1y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_1y.example_text_2",
                },
                {
                    dividend_1y_pct: dividend_1y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_1y
            )}}\right) = ${dividend_1y_pct_raw} \text{ }\widehat{=} \text{ } ${dividend_1y_pct}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_1y.disclaimer_text_1",
            }),
        },
        dividend_growth_1y_change: {
            property: "dividend_growth_1y_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_1y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_1y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_1y.description_text_1",
            }),
        },
        dividend_growth_1y_ranker: {
            property: "dividend_growth_1y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_1y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_1y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_1y.description_text_1",
            }),
        },
        dividend_growth_1y_ranker_change: {
            property: "dividend_growth_1y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_1y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_1y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_1y.description_text_1",
            }),
        },
        revenue_growth_3y: {
            property: "revenue_growth_3y",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_3y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_3y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_3y.example_text_1",
                },
                {
                    company: company,
                    year_3y: year_3y,
                    revenue_3y: numberFormatter(revenue_3y),
                    year: year,
                    revenue: numberFormatter(revenue),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_3y.example_text_2",
                },
                {
                    revenue_3y_pct: revenue_3y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_3y
            )}}\right) = ${revenue_3y_pct_raw} \text{ }\widehat{=} \text{ } ${revenue_3y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_3y
            )}}\right)^{\frac{1}{3}} = ${revenue_3y_cagr_raw} \text{ }\widehat{=} \text{ } ${revenue_3y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.disclaimer_text_1",
            }),
        },
        revenue_growth_3y_change: {
            property: "revenue_growth_3y_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_3y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_3y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.description_text_1",
            }),
        },
        revenue_growth_3y_ranker: {
            property: "revenue_growth_3y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_3y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_3y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.description_text_1",
            }),
        },
        revenue_growth_3y_ranker_change: {
            property: "revenue_growth_3y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_3y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_3y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_3y.description_text_1",
            }),
        },
        earnings_growth_3y: {
            property: "earnings_growth_3y",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_3y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_3y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_3y.example_text_1",
                },
                {
                    company: company,
                    year_3y: year_3y,
                    earnings_3y: numberFormatter(earnings_3y),
                    year: year,
                    earnings: numberFormatter(earnings),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_3y.example_text_2",
                },
                {
                    earnings_3y_pct: earnings_3y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_3y
            )}}\right) = ${earnings_3y_pct_raw} \text{ }\widehat{=} \text{ } ${earnings_3y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_3y
            )}}\right)^{\frac{1}{3}} = ${earnings_3y_cagr_raw} \text{ }\widehat{=} \text{ } ${earnings_3y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.disclaimer_text_1",
            }),
        },
        earnings_growth_3y_change: {
            property: "earnings_growth_3y_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_3y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_3y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.description_text_1",
            }),
        },
        earnings_growth_3y_ranker: {
            property: "earnings_growth_3y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_3y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_3y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.description_text_1",
            }),
        },
        earnings_growth_3y_ranker_change: {
            property: "earnings_growth_3y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_3y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_3y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_3y.description_text_1",
            }),
        },

        dividend_growth_3y: {
            property: "dividend_growth_3y",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_3y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_3y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_3y.example_text_1",
                },
                {
                    company: company,
                    year_3y: year_3y,
                    dividend_3y: numberFormatter(dividend_3y),
                    year: year,
                    dividend: numberFormatter(dividend),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_3y.example_text_2",
                },
                {
                    dividend_3y_pct: dividend_3y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_3y
            )}}\right) = ${dividend_3y_pct_raw} \text{ }\widehat{=} \text{ } ${dividend_3y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_3y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_3y
            )}}\right)^{\frac{1}{3}} = ${dividend_3y_cagr_raw} \text{ }\widehat{=} \text{ } ${dividend_3y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.disclaimer_text_1",
            }),
        },
        dividend_growth_3y_change: {
            property: "dividend_growth_3y_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_3y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_3y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.description_text_1",
            }),
        },
        dividend_growth_3y_ranker: {
            property: "dividend_growth_3y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_3y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_3y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.description_text_1",
            }),
        },
        dividend_growth_3y_ranker_change: {
            property: "dividend_growth_3y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_3y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_3y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_3y.description_text_1",
            }),
        },
        revenue_growth_5y: {
            property: "revenue_growth_5y",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_5y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_5y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_5y.example_text_1",
                },
                {
                    company: company,
                    year_5y: year_5y,
                    revenue_5y: numberFormatter(revenue_5y),
                    year: year,
                    revenue: numberFormatter(revenue),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            // example_img_1: useintl.formatMessage({
            //     id: "metrics.revenue_growth_5y.example_img_1",
            // }),
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_5y.example_text_2",
                },
                {
                    revenue_5y_pct: revenue_5y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_5y
            )}}\right) = ${revenue_5y_pct_raw} \text{ }\widehat{=} \text{ } ${revenue_5y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_5y
            )}}\right)^{\frac{1}{5}} = ${revenue_5y_cagr_raw} \text{ }\widehat{=} \text{ } ${revenue_5y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.disclaimer_text_1",
            }),
        },
        revenue_growth_5y_change: {
            property: "revenue_growth_5y_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_5y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_5y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.description_text_1",
            }),
        },
        revenue_growth_5y_ranker: {
            property: "revenue_growth_5y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_5y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_5y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.description_text_1",
            }),
        },
        revenue_growth_5y_ranker_change: {
            property: "revenue_growth_5y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_5y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_5y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_5y.description_text_1",
            }),
        },
        earnings_growth_5y: {
            property: "earnings_growth_5y",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_5y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_5y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_5y.example_text_1",
                },
                {
                    company: company,
                    year_5y: year_5y,
                    earnings_5y: numberFormatter(earnings_5y),
                    year: year,
                    earnings: numberFormatter(earnings),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            // example_img_1: useintl.formatMessage({
            //     id: "metrics.earnings_growth_5y.example_img_1",
            // }),
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_5y.example_text_2",
                },
                {
                    earnings_5y_pct: earnings_5y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_5y
            )}}\right) = ${earnings_5y_pct_raw} \text{ }\widehat{=} \text{ } ${earnings_5y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_5y
            )}}\right)^{\frac{1}{5}} = ${earnings_5y_cagr_raw} \text{ }\widehat{=} \text{ } ${earnings_5y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.disclaimer_text_1",
            }),
        },
        earnings_growth_5y_change: {
            property: "earnings_growth_5y_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_5y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_5y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.description_text_1",
            }),
        },
        earnings_growth_5y_ranker: {
            property: "earnings_growth_5y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_5y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_5y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.description_text_1",
            }),
        },
        earnings_growth_5y_ranker_change: {
            property: "earnings_growth_5y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_5y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_5y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_5y.description_text_1",
            }),
        },

        dividend_growth_5y: {
            property: "dividend_growth_5y",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_5y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_5y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_5y.example_text_1",
                },
                {
                    company: company,
                    year_5y: year_5y,
                    dividend_5y: numberFormatter(dividend_5y),
                    year: year,
                    dividend: numberFormatter(dividend),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            // example_img_1: useintl.formatMessage({
            //     id: "metrics.dividend_growth_5y.example_img_1",
            // }),
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_5y.example_text_2",
                },
                {
                    dividend_5y_pct: dividend_5y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_5y
            )}}\right) = ${dividend_5y_pct_raw} \text{ }\widehat{=} \text{ } ${dividend_5y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_5y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_5y
            )}}\right)^{\frac{1}{5}} = ${dividend_5y_cagr_raw} \text{ }\widehat{=} \text{ } ${dividend_5y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.disclaimer_text_1",
            }),
        },
        dividend_growth_5y_change: {
            property: "dividend_growth_5y_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_5y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_5y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.description_text_1",
            }),
        },
        dividend_growth_5y_ranker: {
            property: "dividend_growth_5y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_5y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_5y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.description_text_1",
            }),
        },
        dividend_growth_5y_ranker_change: {
            property: "dividend_growth_5y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_5y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_5y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_5y.description_text_1",
            }),
        },
        revenue_growth_9y: {
            property: "revenue_growth_9y",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_9y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_9y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_9y.example_text_1",
                },
                {
                    company: company,
                    year_9y: year_9y,
                    revenue_9y: numberFormatter(revenue_9y),
                    year: year,
                    revenue: numberFormatter(revenue),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.revenue_growth_9y.example_text_2",
                },
                {
                    revenue_9y_pct: revenue_9y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_9y
            )}}\right) = ${revenue_9y_pct_raw} \text{ }\widehat{=} \text{ } ${revenue_9y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                revenue
            )}}{${numberFormatter(
                revenue_9y
            )}}\right)^{\frac{1}{9}} = ${revenue_9y_cagr_raw} \text{ }\widehat{=} \text{ } ${revenue_9y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.disclaimer_text_1",
            }),
        },
        revenue_growth_9y_change: {
            property: "revenue_growth_9y_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_9y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_9y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.description_text_1",
            }),
        },
        revenue_growth_9y_ranker: {
            property: "revenue_growth_9y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_9y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_9y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.description_text_1",
            }),
        },
        revenue_growth_9y_ranker_change: {
            property: "revenue_growth_9y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_growth_9y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_growth_9y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_growth_9y.description_text_1",
            }),
        },
        earnings_growth_9y: {
            property: "earnings_growth_9y",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_9y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_9y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_9y.example_text_1",
                },
                {
                    company: company,
                    year_9y: year_9y,
                    earnings_9y: numberFormatter(earnings_9y),
                    year: year,
                    earnings: numberFormatter(earnings),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.earnings_growth_9y.example_text_2",
                },
                {
                    earnings_9y_pct: earnings_9y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_9y
            )}}\right) = ${earnings_9y_pct_raw} \text{ }\widehat{=} \text{ } ${earnings_9y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                earnings
            )}}{${numberFormatter(
                earnings_9y
            )}}\right)^{\frac{1}{9}} = ${earnings_9y_cagr_raw} \text{ }\widehat{=} \text{ } ${earnings_9y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.disclaimer_text_1",
            }),
        },
        earnings_growth_9y_change: {
            property: "earnings_growth_9y_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_9y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_9y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.description_text_1",
            }),
        },
        earnings_growth_9y_ranker: {
            property: "earnings_growth_9y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_9y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_9y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.description_text_1",
            }),
        },
        earnings_growth_9y_ranker_change: {
            property: "earnings_growth_9y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.earnings_growth_9y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.earnings_growth_9y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.earnings_growth_9y.description_text_1",
            }),
        },

        dividend_growth_9y: {
            property: "dividend_growth_9y",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_9y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_9y",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
                { type: "divider", content: null },
                { type: "header_2", content: 2 },
                { type: "text_1", content: "example_text_1" },
                { type: "math", content: "example_math_1" },
                { type: "text_1", content: "example_text_2" },
                { type: "math", content: "example_math_2" },
                { type: "text_1", content: "example_text_3" },
                { type: "math", content: "example_math_3" },
                { type: "text_1", content: "example_text_4" },
                { type: "math", content: "example_math_4" },
                { type: "divider", content: null },
                { type: "header_2", content: 3 },
                { type: "text_1", content: "disclaimer_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.description_text_1",
            }),
            example_text_1: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_9y.example_text_1",
                },
                {
                    company: company,
                    year_9y: year_9y,
                    dividend_9y: numberFormatter(dividend_9y),
                    year: year,
                    dividend: numberFormatter(dividend),
                }
            ),
            example_math_1: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{x} = \text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year}}`,
            example_text_2: useintl.formatMessage(
                {
                    id: "metrics.dividend_growth_9y.example_text_2",
                },
                {
                    dividend_9y_pct: dividend_9y_pct,
                }
            ),
            example_math_2: String.raw`\text{x} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_9y
            )}}\right) = ${dividend_9y_pct_raw} \text{ }\widehat{=} \text{ } ${dividend_9y_pct}\text{%}`,
            example_text_3: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.example_text_3",
            }),
            example_math_3: String.raw`\text{${useintl.formatMessage({
                id: "metrics.math.value",
            })}}_{${year_9y}}\times \text{CAGR}^{years} = \text{${useintl.formatMessage(
                {
                    id: "metrics.math.value",
                }
            )}}_{${year}}`,
            example_text_4: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.example_text_4",
            }),
            example_math_4: String.raw`\text{CAGR} = \left(\frac{${numberFormatter(
                dividend
            )}}{${numberFormatter(
                dividend_9y
            )}}\right)^{\frac{1}{9}} = ${dividend_9y_cagr_raw} \text{ }\widehat{=} \text{ } ${dividend_9y_cagr}\text{%}`,
            disclaimer_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.disclaimer_text_1",
            }),
        },
        dividend_growth_9y_change: {
            property: "dividend_growth_9y_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_9y_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_9y_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.description_text_1",
            }),
        },
        dividend_growth_9y_ranker: {
            property: "dividend_growth_9y_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_9y_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_9y_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.description_text_1",
            }),
        },
        dividend_growth_9y_ranker_change: {
            property: "dividend_growth_9y_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_growth_9y_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.dividend_growth_9y_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_growth_9y.description_text_1",
            }),
        },
        price_ebit: {
            property: "price_ebit",
            displayName: useintl.formatMessage({
                id: "metrics.price_ebit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.price_ebit.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_ebit.description_text_1",
            }),
        },
        price_ebit_average_10y: {
            property: "price_ebit_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_ebit_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.price_ebit_average_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_ebit_average_10y.description_text_1",
            }),
        },
        price_ebit_deviation_10y: {
            property: "price_ebit_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.price_ebit_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.price_ebit_deviation_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_ebit_deviation_10y.description_text_1",
            }),
        },
        ev_ebit: {
            property: "ev_ebit",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebit.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebit.description_text_1",
            }),
        },
        ev_ebit_average_10y: {
            property: "ev_ebit_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebit_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebit_average_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebit_average_10y.description_text_1",
            }),
        },
        ev_ebit_deviation_10y: {
            property: "ev_ebit_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebit_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebit_deviation_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebit_deviation_10y.description_text_1",
            }),
        },
        ev_ebitda: {
            property: "ev_ebitda",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebitda",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebitda.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebitda.description_text_1",
            }),
        },
        ev_ebitda_average_10y: {
            property: "ev_ebitda_average_10y",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebitda_average_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebitda_average_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebitda_average_10y.description_text_1",
            }),
        },
        ev_ebitda_deviation_10y: {
            property: "ev_ebitda_deviation_10y",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebitda_deviation_10y",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebitda_deviation_10y.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebitda_deviation_10y.description_text_1",
            }),
        },
        dividend_consistency: {
            property: "dividend_consistency",
            displayName: useintl.formatMessage({
                id: "metrics.dividend_consistency",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.dividend_consistency.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.dividend_consistency.description_text_1",
            }),
        },
        price_ebit_ranker: {
            property: "price_ebit_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_ebit_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.price_ebit_ranker.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_ebit_ranker.description_text_1",
            }),
        },
        ev_ebit_ranker: {
            property: "ev_ebit_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebit_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebit_ranker.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebit_ranker.description_text_1",
            }),
        },
        ev_ebitda_ranker: {
            property: "ev_ebitda_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.ev_ebitda_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.ev_ebitda_ranker.description_text_1",
            }),
            unit: "",
            axis: "lin",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.ev_ebitda_ranker.description_text_1",
            }),
        },
        gross_profit_margin: {
            property: "gross_profit_margin",
            displayName: useintl.formatMessage({
                id: "metrics.gross_profit_margin",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.gross_profit_margin",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.gross_profit_margin.description_text_1",
            }),
        },
        gross_profit_margin_change: {
            property: "gross_profit_margin_change",
            displayName: useintl.formatMessage({
                id: "metrics.gross_profit_margin_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.gross_profit_margin_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.gross_profit_margin.description_text_1",
            }),
        },
        gross_profit_margin_ranker: {
            property: "gross_profit_margin_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.gross_profit_margin_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.gross_profit_margin_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.gross_profit_margin.description_text_1",
            }),
        },
        gross_profit_margin_ranker_change: {
            property: "gross_profit_margin_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.gross_profit_margin_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.gross_profit_margin_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.gross_profit_margin.description_text_1",
            }),
        },
        net_profit_margin: {
            property: "net_profit_margin",
            displayName: useintl.formatMessage({
                id: "metrics.net_profit_margin",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.net_profit_margin",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.net_profit_margin.description_text_1",
            }),
        },
        net_profit_margin_change: {
            property: "net_profit_margin_change",
            displayName: useintl.formatMessage({
                id: "metrics.net_profit_margin_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.net_profit_margin_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.net_profit_margin.description_text_1",
            }),
        },
        net_profit_margin_ranker: {
            property: "net_profit_margin_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.net_profit_margin_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.net_profit_margin_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.net_profit_margin.description_text_1",
            }),
        },
        net_profit_margin_ranker_change: {
            property: "net_profit_margin_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.net_profit_margin_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.net_profit_margin_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.net_profit_margin.description_text_1",
            }),
        },
        operating_cash_flow_margin: {
            property: "operating_cash_flow_margin",
            displayName: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_cash_flow_margin",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin.description_text_1",
            }),
        },
        operating_cash_flow_margin_change: {
            property: "operating_cash_flow_margin_change",
            displayName: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_cash_flow_margin_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin.description_text_1",
            }),
        },
        operating_cash_flow_margin_ranker: {
            property: "operating_cash_flow_margin_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_cash_flow_margin_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin.description_text_1",
            }),
        },
        operating_cash_flow_margin_ranker_change: {
            property: "operating_cash_flow_margin_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_cash_flow_margin_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_cash_flow_margin.description_text_1",
            }),
        },
        operating_margin: {
            property: "operating_margin",
            displayName: useintl.formatMessage({
                id: "metrics.operating_margin",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_margin",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_margin.description_text_1",
            }),
        },
        operating_margin_change: {
            property: "operating_margin_change",
            displayName: useintl.formatMessage({
                id: "metrics.operating_margin_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_margin_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_margin.description_text_1",
            }),
        },
        operating_margin_ranker: {
            property: "operating_margin_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.operating_margin_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_margin_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_margin.description_text_1",
            }),
        },
        operating_margin_ranker_change: {
            property: "operating_margin_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.operating_margin_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.operating_margin_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.operating_margin.description_text_1",
            }),
        },
        return_on_assets: {
            property: "return_on_assets",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_assets",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_assets.description_text_1",
            }),
        },
        return_on_assets_change: {
            property: "return_on_assets_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_assets_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_assets_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_assets.description_text_1",
            }),
        },
        return_on_assets_ranker: {
            property: "return_on_assets_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_assets_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_assets_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_assets.description_text_1",
            }),
        },
        return_on_assets_ranker_change: {
            property: "return_on_assets_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_assets_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_assets_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_assets.description_text_1",
            }),
        },
        asset_turnover: {
            property: "asset_turnover",
            displayName: useintl.formatMessage({
                id: "metrics.asset_turnover",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.asset_turnover",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.asset_turnover.description_text_1",
            }),
        },
        asset_turnover_change: {
            property: "asset_turnover_change",
            displayName: useintl.formatMessage({
                id: "metrics.asset_turnover_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.asset_turnover_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.asset_turnover.description_text_1",
            }),
        },
        asset_turnover_ranker: {
            property: "asset_turnover_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.asset_turnover_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.asset_turnover_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.asset_turnover.description_text_1",
            }),
        },
        asset_turnover_ranker_change: {
            property: "asset_turnover_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.asset_turnover_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.asset_turnover_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.asset_turnover.description_text_1",
            }),
        },
        cash_flow_on_assets: {
            property: "cash_flow_on_assets",
            displayName: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash_flow_on_assets",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets.description_text_1",
            }),
        },
        cash_flow_on_assets_change: {
            property: "cash_flow_on_assets_change",
            displayName: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash_flow_on_assets_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets.description_text_1",
            }),
        },
        cash_flow_on_assets_ranker: {
            property: "cash_flow_on_assets_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash_flow_on_assets_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets.description_text_1",
            }),
        },
        cash_flow_on_assets_ranker_change: {
            property: "cash_flow_on_assets_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.cash_flow_on_assets_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.cash_flow_on_assets.description_text_1",
            }),
        },
        return_on_equity: {
            property: "return_on_equity",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_equity",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_equity",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_equity.description_text_1",
            }),
        },
        return_on_equity_change: {
            property: "return_on_equity_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_equity_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_equity_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_equity.description_text_1",
            }),
        },
        return_on_equity_ranker: {
            property: "return_on_equity_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_equity_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_equity_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_equity.description_text_1",
            }),
        },
        return_on_equity_ranker_change: {
            property: "return_on_equity_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_equity_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_equity_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_equity.description_text_1",
            }),
        },
        revenue_on_equity: {
            property: "revenue_on_equity",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_on_equity",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_on_equity",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_on_equity.description_text_1",
            }),
        },
        revenue_on_equity_change: {
            property: "revenue_on_equity_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_on_equity_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_on_equity_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_on_equity.description_text_1",
            }),
        },
        revenue_on_equity_ranker: {
            property: "revenue_on_equity_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_on_equity_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_on_equity_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_on_equity.description_text_1",
            }),
        },
        revenue_on_equity_ranker_change: {
            property: "revenue_on_equity_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.revenue_on_equity_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.revenue_on_equity_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.revenue_on_equity.description_text_1",
            }),
        },
        return_on_capital_employed: {
            property: "return_on_capital_employed",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital_employed",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital_employed",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital_employed.description_text_1",
            }),
        },
        return_on_capital_employed_change: {
            property: "return_on_capital_employed_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital_employed_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital_employed_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital_employed.description_text_1",
            }),
        },
        return_on_capital_employed_ranker: {
            property: "return_on_capital_employed_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital_employed_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital_employed_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital_employed.description_text_1",
            }),
        },
        return_on_capital_employed_ranker_change: {
            property: "return_on_capital_employed_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.return_on_capital_employed_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.return_on_capital_employed_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.return_on_capital_employed.description_text_1",
            }),
        },
        debt_ratio: {
            property: "debt_ratio",
            displayName: useintl.formatMessage({
                id: "metrics.debt_ratio",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_ratio",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_ratio.description_text_1",
            }),
        },
        debt_ratio_change: {
            property: "debt_ratio_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_ratio_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_ratio_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_ratio.description_text_1",
            }),
        },
        debt_ratio_ranker: {
            property: "debt_ratio_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.debt_ratio_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_ratio_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_ratio.description_text_1",
            }),
        },
        debt_ratio_ranker_change: {
            property: "debt_ratio_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_ratio_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_ratio_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_ratio.description_text_1",
            }),
        },
        debt_to_ebit: {
            property: "debt_to_ebit",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_ebit",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_ebit",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_ebit.description_text_1",
            }),
        },
        debt_to_ebit_change: {
            property: "debt_to_ebit_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_ebit_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_ebit_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_ebit.description_text_1",
            }),
        },
        debt_to_ebit_ranker: {
            property: "debt_to_ebit_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_ebit_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_ebit_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_ebit.description_text_1",
            }),
        },
        debt_to_ebit_ranker_change: {
            property: "debt_to_ebit_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_ebit_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_ebit_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_ebit.description_text_1",
            }),
        },
        debt_to_equity: {
            property: "debt_to_equity",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_equity",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_equity",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_equity.description_text_1",
            }),
        },
        debt_to_equity_change: {
            property: "debt_to_equity_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_equity_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_equity_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_equity.description_text_1",
            }),
        },
        debt_to_equity_ranker: {
            property: "debt_to_equity_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_equity_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_equity_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_equity.description_text_1",
            }),
        },
        debt_to_equity_ranker_change: {
            property: "debt_to_equity_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_equity_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_equity_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_equity.description_text_1",
            }),
        },
        debt_to_revenue: {
            property: "debt_to_revenue",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_revenue",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_revenue",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_revenue.description_text_1",
            }),
        },
        debt_to_revenue_change: {
            property: "debt_to_revenue_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_revenue_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_revenue_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_revenue.description_text_1",
            }),
        },
        debt_to_revenue_ranker: {
            property: "debt_to_revenue_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_revenue_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_revenue_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_revenue.description_text_1",
            }),
        },
        debt_to_revenue_ranker_change: {
            property: "debt_to_revenue_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.debt_to_revenue_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.debt_to_revenue_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.debt_to_revenue.description_text_1",
            }),
        },
        current_ratio: {
            property: "current_ratio",
            displayName: useintl.formatMessage({
                id: "metrics.current_ratio",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.current_ratio",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.current_ratio.description_text_1",
            }),
        },
        current_ratio_change: {
            property: "current_ratio_change",
            displayName: useintl.formatMessage({
                id: "metrics.current_ratio_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.current_ratio_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.current_ratio.description_text_1",
            }),
        },
        current_ratio_ranker: {
            property: "current_ratio_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.current_ratio_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.current_ratio_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.current_ratio.description_text_1",
            }),
        },
        current_ratio_ranker_change: {
            property: "current_ratio_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.current_ratio_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.current_ratio_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.current_ratio.description_text_1",
            }),
        },
        price_cash_flow: {
            property: "price_cash_flow",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        price_cash_flow_change: {
            property: "price_cash_flow_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        price_cash_flow_ranker: {
            property: "price_cash_flow_ranker",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow_ranker",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow_ranker",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        price_cash_flow_ranker_change: {
            property: "price_cash_flow_ranker_change",
            displayName: useintl.formatMessage({
                id: "metrics.price_cash_flow_ranker_change",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.price_cash_flow_ranker_change",
            }),
            unit: "%",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.price_cash_flow.description_text_1",
            }),
        },
        relative_score: {
            property: "relative_score",
            displayName: useintl.formatMessage({
                id: "metrics.relative_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.relative_score",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.relative_score.description_text_1",
            }),
        },
        piotroski_score: {
            property: "piotroski_score",
            displayName: useintl.formatMessage({
                id: "metrics.piotroski_score",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.piotroski_score",
            }),
            unit: "",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.piotroski_score.description_text_1",
            }),
        },
        stock_price: {
            property: "stock_price",
            displayName: useintl.formatMessage({
                id: "metrics.stock_price",
            }),
            filter_keywords: useintl.formatMessage({
                id: "metrics.keywords.stock_price",
            }),
            unit: "USD",
            axis: "log",
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.stock_price.description_text_1",
            }),
            info: [
                { type: "header_2", content: 1 },
                { type: "text_1", content: "description_text_1" },
            ],
            description_text_1: useintl.formatMessage({
                id: "metrics.stock_price.description_text_1",
            }),
        },
    });

    return (
        <MetricsContext.Provider
            value={{
                AllMetrics,
                SimplifiedMetrics,
                FreeMetrics,
                sorted_metrics_object,
                simplified_sorted_metrics_object,
                free_sorted_metrics_object,
                sorted_rankers_object,
                simplified_sorted_rankers_object,
                free_sorted_rankers_object,
                possible_options,
                possible_manifest_values,
                possible_ranges,
                sectors,
                industries,
                metrics,
            }}
        >
            {props.children}
        </MetricsContext.Provider>
    );
};
