import React, { useEffect, useContext } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

import SectorTable from "../../tables/market/SectorTable";
import TableStructureComponent from "../../tables/market/TableStructureComponent";
import Preloader from "../../layout/Preloader";

// Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

/*Dialog*/
import Dialog from "@mui/material/Dialog";

import {
    getHistoricMetricSector,
    openSectorMetricHistory,
    closeSectorMetricHistory,
} from "../../../actions/marketActions";
import { numberFormatter } from "../../../actions/generalActions";

// Context
import { MetricsContext } from "../../../context/MetricsContext";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SectorTableWrapper = ({
    market: {
        market_table,
        open_metric_history,
        metric_history,
        sector_history,
        metric_history_data,
    },
    all_sectors_data,
    closeSectorMetricHistory,
    getHistoricMetricSector,
}) => {
    const useintl = useIntl();

    useEffect(() => {
        if (sector_history !== null && metric_history !== null) {
            getHistoricMetricSector(sector_history, metric_history);
        }
        // eslint-disable-next-line
    }, [metric_history, sector_history]);

    // GET THE METRICS DATA (DESCRIPTION ETC.)

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = metrics.sorted_metrics_object;

    let SectorData = {};

    let sector_metrics = [
        "sector",
        "ev_ebit",
        "ev_ebitda",
        "price_earnings",
        "price_ebit",
        "price_sales",
        "price_cash_flow",
        "price_book",
        "dividend_yield",
        "payout_ratio",
        "gross_profit_margin",
        "net_profit_margin",
        "operating_cash_flow_margin",
        "operating_margin",
        "return_on_assets",
        "return_on_net_tangible_assets",
        "asset_turnover",
        "cash_flow_on_assets",
        "return_on_equity",
        "revenue_on_equity",
        "return_on_capital_employed",
        "return_on_capital",
        "debt_ratio",
        "debt_to_ebit",
        "debt_to_equity",
        "debt_to_revenue",
        "current_ratio",
        "price_earnings_growth",
        "price_sales_growth",
        "revenue_growth_1y",
        "revenue_growth_3y",
        "revenue_growth_5y",
        "revenue_growth_9y",
        "earnings_growth_1y",
        "earnings_growth_3y",
        "earnings_growth_5y",
        "earnings_growth_9y",
        "dividend_growth_1y",
        "dividend_growth_3y",
        "dividend_growth_5y",
        "dividend_growth_9y",
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
    ];

    let sector_object = metrics.industries;

    let AllMetrics = metrics.AllMetrics;

    let SectorDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            if (sector_metrics.includes(metric)) {
                DataProvider[metric] = {
                    id: index,
                    display: market_table[metric].display,
                    ...metrics.metrics[metric],
                };
            }
        });
        return DataProvider;
    };

    SectorData = SectorDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchMetricObject, setSearchSliderObject] =
        React.useState(SectorData);

    // Slider Search Functionality

    const handlesearchSliderText = (event) => {
        if (event.target.value === "") {
            setSearchSliderObject(SectorData);
        } else {
            let helper = {};
            Object.keys(SectorData).map((slider) => {
                if (
                    SectorData[slider].displayName.includes(
                        event.target.value
                    ) ||
                    SectorData[slider].property.includes(event.target.value) ||
                    SectorData[slider].filter_keywords.includes(
                        event.target.value
                    )
                ) {
                    helper[slider] = SectorData[slider];
                }
            });
            setSearchSliderObject(helper);
        }
    };

    /*Tablecolumns Dialog Field*/
    const [openColumns, setOpenColumns] = React.useState(false);
    const handleClickOpenColumns = () => {
        setOpenColumns(true);
    };
    const handleCloseColumns = () => {
        setOpenColumns(false);
    };

    /*Sector information Dialog Field*/
    const handleCloseSectorMetricHistory = () => {
        closeSectorMetricHistory();
    };

    let chartOptions = {
        chart: {
            zoomType: "xy",
        },
        title: {
            enabled: false,
            text: "",
        },
        subtitle: {
            text: "",
        },
        credits: {
            text: "StocksOnView",
            href: "#",
        },
        xAxis: [
            {
                // showResetZoom: true,
                categories: metric_history_data.x_axis.map((date) =>
                    date !== null ? date : null
                ),
                // min: company.time_arr[company.time_arr.length - 40]
                //     ? new Date(
                //           company.time_arr[company.time_arr.length - 40]
                //       ).toLocaleDateString()
                //     : new Date(company.time_arr[0]).toLocaleDateString(),
                // max: new Date(
                //     company.time_arr[company.time_arr.length - 1]
                // ).toLocaleDateString(),
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    // formatter: function () {
                    //     return numberFormatter(this.value);
                    // },
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    enabled: false,
                    text: ``,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
            {
                // Secondary yAxis
                title: {
                    enabled: false,
                    text: "Margin in %",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                labels: {
                    format: "{value} %",
                    style: {
                        color: Highcharts.getOptions().colors[0],
                    },
                },
                opposite: true,
            },
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                var points = this.points;
                var pointsLength = points.length;
                var tooltipMarkup = pointsLength
                    ? '<span style="font-size: 10px">' +
                      points[0].key +
                      "</span><br/>"
                    : "";
                var index;
                var value;

                for (index = 0; index < pointsLength; index += 1) {
                    // if (
                    //     points[index].series.name === "Revenue TTM" ||
                    //     points[index].series.name === "Earnings TTM"
                    // ) {
                    value = numberFormatter(points[index].y);

                    tooltipMarkup +=
                        '<span style="color:' +
                        points[index].series.color +
                        '">\u25CF</span> ' +
                        points[index].series.name +
                        ": <b>" +
                        value +
                        ` ${searchMetricObject[metric_history].unit}</b><br/>`;
                    // } else {
                    //     value = points[index].y;

                    //     tooltipMarkup +=
                    //         '<span style="color:' +
                    //         points[index].series.color +
                    //         '">\u25CF</span> ' +
                    //         points[index].series.name +
                    //         ": <b>" +
                    //         value +
                    //         " %</b><br/>";
                    // }
                }
                return tooltipMarkup;
            },
        },
        legend: {
            align: "center",
            verticalAlign: "bottom",
            x: 0,
            y: 0,
            backgroundColor: "rgba(255,255,255,0)",
        },
        series: [
            {
                name: useintl.formatMessage({
                    id: `metrics.${metric_history}`,
                }),
                type: "area",
                color: Highcharts.getOptions().colors[0],
                lineWidth: 1,
                marker: {
                    radius: 2,
                    symbol: "circle",
                },
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [
                            0,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0.9)
                                .get("rgba"),
                        ],
                        [
                            1,
                            Highcharts.Color(Highcharts.getOptions().colors[0])
                                .setOpacity(0.2)
                                .get("rgba"),
                        ],
                    ],
                },
                data: metric_history_data.y_axis.map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
                labels: {
                    formatter: function () {
                        return numberFormatter(this.value);
                    },
                },
            },
        ],
    };

    return (
        <div className='background_color_1 text_color_1 width_100pr'>
            <div className='page_menu_flex space_between'>
                {/* HEADER */}
                <div className='flex space_between height_70px pl_20px pr_20px'>
                    <div className='text_1'>
                        Averaged metrics for different sectors
                    </div>
                    {/* TABLE BUTTON */}
                    <div className='flex'>
                        <div
                            className='button_blue text_2 padding_10px ml_15px color_white click_translate'
                            onClick={() => {
                                handleClickOpenColumns();
                                // setSearchSliderObject(SectorData);
                            }}
                        >
                            <div className='text_3 color_white pr_10px'>
                                {useintl.formatMessage({
                                    id: "general.table",
                                })}
                            </div>
                            <AiIcons.AiOutlineTable />
                        </div>
                    </div>
                </div>
            </div>
            <SectorTable
                all_sectors={all_sectors_data}
                sector_object={sector_object}
                market_table={market_table}
                tableStructure={"filtered_metrics"}
            />

            {/* Table Column Creation */}
            <Dialog
                open={openColumns}
                onClose={handleCloseColumns}
                fullScreen={true}
            >
                <div className='background_color_2 text_color_1 min_height_500px width_100pr'>
                    {/* OPTIONS SECTION */}
                    <div className='flex space_between'>
                        <div className='flex wrap space_between width_100pr'>
                            <div className='flex wrap'>
                                {/* HEADER */}
                                <div className='ml_20px mt_20px pr_5px title_3 flex text_color_1'>
                                    <div className='text_1'>
                                        <FormattedMessage
                                            id='general.table_structure'
                                            defaultMessage='Table Structure'
                                        />
                                    </div>
                                    <AiIcons.AiOutlineTable className='text_1 ml_5px pt_3px' />
                                </div>

                                {/* PRESETS DROPDOWN */}
                                {/* <div className='mt_20px ml_20px'>
                                            <FormControl 
                                    size='small'>
                                                <InputLabel
                                                    htmlFor='tableStructure'
                                                    className='width_200px_120px'
                                                >
                                                    <FormattedMessage
                                                        id='general.table_structure'
                                                        defaultMessage='Table Structure'
                                                    />
                                                </InputLabel>
                                                <Select
                                                    native
                                                    value={tableStructure}
                                                    onChange={
                                                        handletableStructure
                                                    }
                                                    className='width_200px_120px'
                                                >
                                                    <option
                                                        value={
                                                            "filtered_metrics"
                                                        }
                                                    >
                                                        {useintl.formatMessage({
                                                            id: "general.strategy.individual",
                                                        })}
                                                    </option>
                                                    <option value={"dividends"}>
                                                        {useintl.formatMessage({
                                                            id: "general.strategy.dividends",
                                                        })}
                                                    </option>
                                                    <option value={"value"}>
                                                        {useintl.formatMessage({
                                                            id: "general.strategy.value",
                                                        })}
                                                    </option>
                                                    <option value={"growth"}>
                                                        {useintl.formatMessage({
                                                            id: "general.strategy.growth",
                                                        })}
                                                    </option>
                                                </Select>
                                            </FormControl>
                                        </div> */}
                            </div>

                            {/* RESET BUTTON */}
                            <div className='flex right mt_5px'>
                                {/* <div
                                            className='button_white_layer_2 hover_opacity_80 text_2 padding_10px ml_15px mt_10px mr_15px'
                                            onClick={() => {
                                                setDisplayFalseAll(
                                                    slider,
                                                    options
                                                );
                                                handleCloseColumns();
                                            }}
                                        >
                                            <div className='text_2 text_color_1 pr_10px'>
                                                <FormattedMessage
                                                    id='general.reset'
                                                    defaultMessage='Reset'
                                                />
                                            </div>
                                            <IoIcons.IoIosRefresh />
                                        </div> */}
                            </div>
                        </div>
                        <div
                            className='cursor_pointer title_4 flex center mr_15px mt_15px'
                            onClick={handleCloseColumns}
                        >
                            <AiIcons.AiOutlineClose />
                        </div>
                    </div>

                    <div className='divider'></div>

                    {/* SEARCH METRIC INPUT */}
                    <div className='flex left width_100pr height_40px_30px'>
                        <AiIcons.AiOutlineSearch className='ml_15px mr_15px text_1' />
                        <div className='width_100pr'>
                            <input
                                type='text'
                                className='text_2 text_color_1 width_100pr pt_15px pb_15px'
                                placeholder={useintl.formatMessage({
                                    id: "metrics.search_metric",
                                })}
                                onKeyUp={handlesearchSliderText}
                            />
                        </div>
                    </div>

                    <div className='divider'></div>

                    {/* METRICS */}

                    <div className='flex wrap space_around border_box width_100pr mt_20px background_color_2'>
                        {Object.keys(sorted_metrics_object).map((category) => (
                            <div className='width_100pr flex wrap'>
                                <div className='width_100pr height_40px ml_20px flex left text_2'>
                                    {useintl.formatMessage({
                                        id: `metrics.${category}`,
                                    })}
                                </div>
                                {sorted_metrics_object[category].map((metric) =>
                                    searchMetricObject[metric] &&
                                    metric !== "market_cap_usd" &&
                                    metric !== "market_cap" &&
                                    metric !== "daily_return" ? (
                                        <TableStructureComponent
                                            sliderprops={
                                                searchMetricObject[metric]
                                            }
                                            // tableStructureValue={
                                            //     options.tableStructure.value
                                            // }
                                            key={category + "_" + metric}
                                        />
                                    ) : null
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>

            {/* SECTOR METRIC HISTORIC DEVELOPMENT */}
            <Dialog
                open={open_metric_history}
                onClose={handleCloseSectorMetricHistory}
                maxWidth='md'
                fullWidth={true}
            >
                <div className='background_color_2 text_color_1 max_width_1250px width_100pr'>
                    {/* HEADING */}
                    <div className='flex space_between width_100pr'>
                        <div className='flex wrap space_between width_100pr'>
                            {/* Header, Country-selection */}
                            <div className='flex wrap'>
                                <div className='ml_20px mt_20px text_1 text_color_1 flex'>
                                    <div className=''>
                                        {useintl.formatMessage({
                                            id: `metrics.${metric_history}`,
                                        })}{" "}
                                        - {sector_history}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='cursor_pointer title_4 text_color_1 flex center mr_15px mt_22px hover_opacity_80'
                            onClick={handleCloseSectorMetricHistory}
                        >
                            <MdIcons.MdClose />
                        </div>
                    </div>

                    <div className='divider'></div>

                    {/* HISTORIC METRIC */}
                    <div className='flex wrap space_around width_100pr border_box mt_20px background_color_2'>
                        <div className='divider_no_margin'></div>
                        {/* <div className="flex left width_100pr ml_15px">Historic Development</div> */}
                        {metric_history_data === null ? (
                            <Preloader />
                        ) : (
                            <div className='width_100pr'>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={chartOptions}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

SectorTableWrapper.propTypes = {
    market: PropTypes.object.isRequired,
    closeSectorMetricHistory: PropTypes.func.isRequired,
    getHistoricMetricSector: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    market: state.market,
});

export default connect(mapStateToProps, {
    closeSectorMetricHistory,
    getHistoricMetricSector,
})(SectorTableWrapper);
