import React from "react";
import { numberFormatterfour } from "../../../actions/generalActions";

let not_sortable = [
    "industry",
    "gic_sector",
    "gic_group",
    "gic_industry",
    "gic_sub_industry",
];
let not_metrics = [
    "page",
    "limit",
    "search",
    "searchOption",
    "sort",
    "industry",
    "esg",
    "stock_exchange",
    "ticker",
    "description",
    "name",
    "currency",
    "country",
    "market_cap",
    "market_cap_usd",
];
let returns_metrics = [
    "daily_return",
    "weekly_return",
    "monthly_return",
    "quarterly_return",
    "semi_annual_return",
    "annual_return",
    "two_year_return",
    "three_year_return",
];
let not_metrics_watchlist = ["market_cap", "market_cap_usd", "daily_return"];

const CompaniesTableRowColumn = ({ company, metric }) => {
    if (
        not_metrics.includes(metric) ||
        ((window.location.pathname === "/watchlist" ||
            window.location.pathname === "/portfolio") &&
            not_metrics_watchlist.includes(metric))
    ) {
        return null;
    } else if (not_sortable.includes(metric)) {
        return (
            <td className='text_color_1 text_4 text_left ps_10px'>
                {company[metric]}
            </td>
        );
    } else if (returns_metrics.includes(metric)) {
        return (
            <td>
                {!isNaN(company[metric]) &&
                typeof company[metric] === "number" ? (
                    <div
                        className={
                            Number(company[metric]) >= 0
                                ? "text_2 text_color_green flex right"
                                : "text_2 text_color_red flex right"
                        }
                    >
                        <div>
                            {numberFormatterfour(
                                Number.parseFloat(company[metric]).toPrecision(
                                    4
                                )
                            )}
                        </div>
                        <div className='ml_5px'>{" %"}</div>
                    </div>
                ) : (
                    <div className='text_2 text_color_1'>-</div>
                )}
            </td>
        );
    } else if (typeof company[metric] === "number" && metric === "ev") {
        return (
            <td className='text_2 text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(company[metric]).toPrecision(4)
                )}{" "}
                USD
            </td>
        );
    } else if (
        typeof company[metric] === "number" &&
        metric.includes("ranker")
    ) {
        return (
            <td className='text_2 text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(company[metric] * 100).toPrecision(4)
                )}{" "}
                {metric === "stock_price" ? "USD" : null}
            </td>
        );
    } else if (typeof company[metric] === "number" && metric !== "market_cap") {
        return (
            <td className='text_2 text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(company[metric]).toPrecision(4)
                )}{" "}
                {metric === "stock_price" ? "USD" : null}
            </td>
        );
    } else if (typeof company[metric] === "string" && metric !== "market_cap") {
        return (
            <td className='text_2 text_color_1 text_right'>
                {company[metric]}
            </td>
        );
    } else if (company[metric] === null) {
        return <td className='text_2 text_color_1 text_right'>-</td>;
    } else {
        return null;
    }
};
export default CompaniesTableRowColumn;
