import {
    GET_INDEX_PERFORMANCE,
    GET_SECTOR_STATISTICS,
    GET_HISTORIC_METRIC_SECTOR,
    SET_TABLE_METRIC_DISPLAY,
    SET_ALERT,
    OPEN_SECTOR_METRIC_HISTORY,
    CLOSE_SECTOR_METRIC_HISTORY,
    REMOVE_ALERT,
} from "./Types";
import * as math from "mathjs";

// GET AN INDEX PERFORMANCE BY TICKER
export const getIndexPerformance = (ticker) => async (dispatch) => {
    try {
        const res = await fetch(`/node_index?ticker=${ticker}`);
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            // {time: [], close: []}

            let unix_time = [];

            data.time.map((ts) => {
                let date = new Date(ts);
                unix_time.push(date.getTime());
            });

            let transformed_performance_matrix = math.matrix([
                // math.dotMultiply(data.time, 1000),
                unix_time,
                data.close,
            ]);
            let performance = math.transpose(transformed_performance_matrix);

            let transformed_volume_matrix = math.matrix([
                // math.dotMultiply(data.time, 1000),
                unix_time,
                data.volume,
            ]);
            let volume = math.transpose(transformed_volume_matrix);

            let final_performance = {
                performance: performance._data,
                volume: volume._data,
            };

            dispatch({
                type: GET_INDEX_PERFORMANCE,
                payload: final_performance,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occured quering for the index's performance data.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// GET STATISTICAL DATA TO ALL SECTORS
export const getSectorStatistics = () => async (dispatch) => {
    try {
        const res = await fetch(`/node_all_sectors`);
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED

            let cleaned_sectors_data = data;
            cleaned_sectors_data = cleaned_sectors_data.reduce(
                (p, c) => (c.sector !== "" && p.push(c), p),
                []
            );

            dispatch({
                type: GET_SECTOR_STATISTICS,
                payload: cleaned_sectors_data,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occured quering for the index's performance data.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// GET HISTORICAL DEVELOPMENT OF ONE SECTORS METRIC
export const getHistoricMetricSector = (sector, metric) => async (dispatch) => {
    try {
        const res = await fetch(
            `/node_sector_historical?sector=${encodeURIComponent(
                sector
            )}&metric=${metric}`
        );
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED
            dispatch({
                type: GET_HISTORIC_METRIC_SECTOR,
                payload: data,
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        console.log(err);
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occured quering for the index's performance data.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// CHANGE SECTORS TABLE LAYOUT
export const setSectorsTableMetricDisplay = (metric) => async (dispatch) => {
    try {
        dispatch({
            type: SET_TABLE_METRIC_DISPLAY,
            payload: metric,
        });
    } catch (err) {
        console.log(err);
    }
};

// OPEN A SECTOR METRIC'S HISTORY
export const openSectorMetricHistory = (sector, metric) => async (dispatch) => {
    try {
        dispatch({
            type: OPEN_SECTOR_METRIC_HISTORY,
            payload: { metric: metric, sector: sector },
        });
    } catch (err) {
        console.log(err);
    }
};

export const closeSectorMetricHistory = () => async (dispatch) => {
    try {
        dispatch({
            type: CLOSE_SECTOR_METRIC_HISTORY,
        });
    } catch (err) {
        console.log(err);
    }
};
