import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import CompaniesTableModal from "../elements/CompaniesTableModal";
import CompaniesTable from "../../tables/companiesSimple/CompaniesTable";
import ChangeWatchlist from "../elements/ChangeWatchlist";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// ACTIONS
import { getWatchlist } from "../../../actions/watchlistActions";
import { setDisplayFalseAll } from "../../../actions/screenerAction";
import { setAlert } from "../../../actions/alertActions";

// MUI
import Dialog from "@mui/material/Dialog";

// ANALYTICS
import ReactGA from "react-ga4";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

// Functionto get url parameters
const getUrlParam = (params) => {
    let params_arr = params.slice(1).split("&");
    let watchlist_id = params_arr[0].split("=")[1];
    // params_arr.forEach((element) => {
    //     let (key, value) = element.split("=");
    // });
    return watchlist_id;
};

let currentWatchlist = null;
let screenerSliderForm = {};
let screenerOptionsForm = {};

const Watchlist = ({
    user,
    watchlist,
    getWatchlist,
    setDisplayFalseAll,
    screener: { slider, options },
}) => {
    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    screenerOptionsForm = options;
    useEffect(() => {
        screenerSliderForm = slider;
        screenerOptionsForm = options;
        let watchlist_id_url = getUrlParam(window.location.search);
        watchlist_id_url = Number(watchlist_id_url);
        // Check the URL params and try to get the watchlist, else get the current watchlist
        if (user.id && watchlist.watchlists) {
            if (watchlist.current !== watchlist_id_url) {
                getWatchlist(
                    !isNaN(watchlist_id_url)
                        ? watchlist_id_url
                        : watchlist.current,
                    user.id,
                    screenerOptionsForm.sortWatchlistBy.value,
                    screenerOptionsForm.sortWatchlistBy.direction
                );

                currentWatchlist = watchlist.watchlists.filter((obj) => {
                    return obj.id === watchlist.current;
                });
            } else {
                getWatchlist(
                    watchlist.current,
                    user.id,
                    screenerOptionsForm.sortWatchlistBy.value,
                    screenerOptionsForm.sortWatchlistBy.direction
                );

                currentWatchlist = watchlist.watchlists.filter((obj) => {
                    return obj.id === watchlist.current;
                });
            }
        }

        // eslint-disable-next-line
    }, []);

    const useintl = useIntl();

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    currentWatchlist = watchlist.watchlists.filter((obj) => {
        return obj.id === watchlist.current;
    });

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = metrics.sorted_metrics_object;

    let sorted_rankers_object = metrics.sorted_rankers_object;

    let [metricType, setMetricType] = React.useState(0);

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // Slider Search Functionality

    const handlesearchSliderText = (event) => {
        if (event.target.value === "") {
            setSearchSliderObject(SliderData);
        } else {
            let helper = {};
            Object.keys(SliderData).map((slider) => {
                if (
                    SliderData[slider].displayName.includes(
                        event.target.value
                    ) ||
                    SliderData[slider].property.includes(event.target.value) ||
                    SliderData[slider].filter_keywords.includes(
                        event.target.value
                    )
                ) {
                    helper[slider] = SliderData[slider];
                }
            });
            setSearchSliderObject(helper);
        }
    };

    /*Tablecolumns Dialog Field*/
    const [openColumns, setOpenColumns] = React.useState(false);
    const handleClickOpenColumns = () => {
        setOpenColumns(true);
    };
    const handleCloseColumns = () => {
        setOpenColumns(false);
    };
    const [tableStructure, setTableStructure] = React.useState(
        screenerOptionsForm.tableStructure.value
    );
    const handletableStructure = (new_structure) => {
        setTableStructure(new_structure);
        screenerOptionsForm.tableStructure.value = new_structure;
    };

    /*Change Watchlist Dialog Field*/
    const [openChange, setOpenChange] = React.useState(false);
    const handleOpenChange = () => {
        setOpenChange(true);
    };
    const handleCloseChange = () => {
        setOpenChange(false);
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.watchlist",
        })}`,
        location_index: 2,
    };

    return (
        <div className='width_100pr'>
            <Helmet>
                <title>{`Watchlist | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Create Watchlists to obtain a structured overview on companies or sectors you are interested in.'
                />
            </Helmet>
            {currentWatchlist === null || currentWatchlist.length === 0 ? (
                <Preloader />
            ) : (
                <div className='background_color_1 text_color_1 width_100pr'>
                    <div className='page_menu_flex space_between mb_25px'>
                        {/* HEADER */}
                        <div className='flex space_between ml_25px_10px mb_10px'>
                            <div className='flex mt_15px'>
                                <div className='title_4 mt_5px'>
                                    {icon_storage.map((icon) =>
                                        icon.name === currentWatchlist[0].icon
                                            ? icon.icon
                                            : null
                                    )}
                                </div>
                                <div className='title_4 ml_15px'>
                                    {currentWatchlist[0].name}
                                </div>
                                <AiIcons.AiOutlineInfoCircle
                                    className='text_1 hover_translate_up ml_15px mt_5px'
                                    onClick={() => {
                                        handleOpenPageInfo();
                                    }}
                                />
                            </div>
                            {/* PUBLIC/PRIVATE */}
                            <div className='flex mt_25px mr_25px_10px'>
                                <div className='mr_15px '>
                                    {currentWatchlist[0].public ? (
                                        <div>
                                            {useintl.formatMessage({
                                                id: "general.public",
                                            })}
                                        </div>
                                    ) : (
                                        <div>
                                            {useintl.formatMessage({
                                                id: "general.private",
                                            })}
                                        </div>
                                    )}
                                </div>
                                {currentWatchlist[0].user_id === user.id ? (
                                    <div
                                        className='mt_6px title_4 cursor_pointer click_translate'
                                        onClick={() => {
                                            handleOpenChange();
                                        }}
                                    >
                                        <MdIcons.MdSettings />
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <PageInfoModalSimple
                            infoHeader={"What can i do here?"}
                            infoBody={
                                "Arrange the table as you need it using the 'Table' button. You can also share your watchlist with friends by clicking on the gear icon and selecting 'public'."
                            }
                            showPageInfo={showPageInfo}
                            handleClosePageInfo={handleClosePageInfo}
                        />

                        {/* TABLE BUTTON */}
                        <div className='flex space_between mr_15px ml_15px'>
                            <div className=''></div>
                            <div className='flex mt_25px'>
                                {currentWatchlist[0].ticker !== null &&
                                watchlist.companies !== null &&
                                currentWatchlist[0].ticker.length > 0 ? (
                                    <div
                                        className='button_blue text_2 padding_10px ml_15px color_white hover_opacity_80 click_translate'
                                        onClick={() => {
                                            handleClickOpenColumns();
                                            setSearchSliderObject(SliderData);
                                        }}
                                    >
                                        <div className='text_3 color_white pr_10px'>
                                            {useintl.formatMessage({
                                                id: "general.table",
                                            })}
                                        </div>
                                        <AiIcons.AiOutlineTable />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {currentWatchlist[0].ticker === null ? (
                        <div className='ml_15px mr_15px'>
                            {useintl.formatMessage({
                                id: "watchlist.get_the_watchlist_started",
                            })}
                        </div>
                    ) : watchlist.companies.length > 0 ? (
                        <div className='screener_table_wrapper ml_10px_0px mr_10px_0px'>
                            <CompaniesTable
                                companies={watchlist.companies}
                                slider={slider}
                                options={options}
                                tableStructure={tableStructure}
                            />
                        </div>
                    ) : null}

                    {/* TABLE CREATION MODAL */}
                    <Dialog
                        open={openColumns}
                        onClose={handleCloseColumns}
                        fullScreen={true}
                    >
                        <CompaniesTableModal
                            closeModal={handleCloseColumns}
                            slider={slider}
                            options={options}
                            tableStructure={tableStructure}
                            handletableStructure={handletableStructure}
                            metricType={metricType}
                            setMetricType={setMetricType}
                            handlesearchSliderText={handlesearchSliderText}
                            sorted_metrics_object={sorted_metrics_object}
                            sorted_rankers_object={sorted_rankers_object}
                            AllowedMetrics={AllowedMetrics}
                            searchSliderObject={searchSliderObject}
                        />
                    </Dialog>

                    {/* CHANGE WATCHLIST MODAL */}
                    <Dialog
                        open={openChange}
                        onClose={handleCloseChange}
                        maxWidth={false}
                    >
                        <ChangeWatchlist
                            closeModal={handleCloseChange}
                            currentWatchlist={currentWatchlist[0]}
                        />
                    </Dialog>
                </div>
            )}
        </div>
    );
};

Watchlist.propTypes = {
    sidebar: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    screener: PropTypes.object.isRequired,
    getWatchlist: PropTypes.func.isRequired,
    setDisplayFalseAll: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    watchlist: state.watchlist,
    screener: state.screener,
});

export default connect(mapStateToProps, {
    getWatchlist,
    setDisplayFalseAll,
    setAlert,
})(Watchlist);
