import React, { useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

// ICONS
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import FeedbackIcon from "../elements/FeedbackIcon";
import IndexPerformance from "./IndexPerformance";
import SectorTableWrapper from "./SectorTableWrapper";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// ACTIONS
import {
    getIndexPerformance,
    getSectorStatistics,
} from "../../../actions/marketActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

// Sektor   :
// S&P      :

const Market = ({
    sidebar: { sidebar },
    market,
    getIndexPerformance,
    getSectorStatistics,
}) => {
    const useintl = useIntl();

    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // GSPC.INDX
        getIndexPerformance("GSPC.INDX");
        getSectorStatistics();
        // eslint-disable-next-line
    }, []);

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    // FEEDBACK
    let feedbackInfo = {
        location: `Market Overview`,
        location_index: 0,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `Monte Carlo - Discounted Cash Flow`,
        location_index: 7,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "mcdcf.header_text_1",
        }),
        description_text_1: useintl.formatMessage({
            id: "mcdcf.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "mcdcf.header_text_2",
        }),
        description_text_2: useintl.formatMessage({
            id: "mcdcf.description_text_2",
        }),
    };

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Market Overview | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Overview historic performance and the historic averaged metrics development of all sectors.'
                />
            </Helmet>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                // informationInfo={informationInfo}
            />

            {1 === 0 ? (
                <Preloader />
            ) : (
                <div className='background_color_1 text_color_1 flex flex_column'>
                    <TopBarCallToAction />
                    <div className='flex width_100pr mt_20px mb_20px'>
                        <div className='title_4 ml_25px_10px mt_5px'>
                            <MdIcons.MdEqualizer />
                        </div>
                        <div className='title_4 ml_15px'>Market Overview</div>
                        <AiIcons.AiOutlineInfoCircle
                            className='text_1 hover_translate_up ml_15px mt_5px'
                            onClick={() => {
                                handleOpenPageInfo();
                            }}
                        />
                    </div>

                    {/* PAGE DESCRIPTION */}
                    <PageInfoModalSimple
                        infoHeader={"What can i do here?"}
                        infoBody={
                            "In the market tab, you can view historical index performances, as well as sector figures related to the index in the table below. A detailed description on how to use this page can be found in the bottom right corner of this page."
                        }
                        showPageInfo={showPageInfo}
                        handleClosePageInfo={handleClosePageInfo}
                    />

                    <div className='divider_no_margin'></div>
                    <div className='width_100pr'>
                        {market.index_performance &&
                        market.index_performance.performance &&
                        market.index_performance.performance !== null ? (
                            <IndexPerformance
                                index_performance_data={
                                    market.index_performance
                                }
                            />
                        ) : (
                            <Preloader />
                        )}
                    </div>
                    <div className='divider_no_margin'></div>
                    <div className='width_100pr'>
                        {market.all_sectors_data &&
                        market.all_sectors_data !== null ? (
                            <SectorTableWrapper
                                all_sectors_data={market.all_sectors_data}
                            />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

Market.propTypes = {
    sidebar: PropTypes.object.isRequired,
    market: PropTypes.object.isRequired,
    getIndexPerformance: PropTypes.func.isRequired,
    getSectorStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    market: state.market,
});

export default connect(mapStateToProps, {
    getIndexPerformance,
    getSectorStatistics,
})(Market);
