import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import { useIntl } from "react-intl";

// STRIPE
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import Dialog from "@mui/material/Dialog";

// Custom Components
import StripeForm from "./StripeForm";
import Preloader from "../layout/Preloader";

// Actions
import {
    userCancelSubscription,
    openSubscriptionChanges,
    openSubscriptionLoader,
} from "../../actions/userActions";
import { setAlert } from "../../actions/alertActions";

// import "./Stripe.css";

const {
    REACT_APP_STRIPE_MONTHLY_EUR,
    REACT_APP_STRIPE_ANNUAL_EUR,
    REACT_APP_STRIPE_PUBLIC_KEY,
    REACT_APP_EXPRESS_BASE_URL,
} = process.env;

const stripePromise = loadStripe(`${REACT_APP_STRIPE_PUBLIC_KEY}`);

let price_id_monthly = REACT_APP_STRIPE_MONTHLY_EUR;
let price_id_annual = REACT_APP_STRIPE_ANNUAL_EUR;

function StripePage({
    user,
    userCancelSubscription,
    openSubscriptionChanges,
    openSubscriptionLoader,
    setAlert,
}) {
    const useintl = useIntl();
    const [clientSecret, setClientSecret] = useState("");
    const [changeSubscription, setChangeSubscription] = useState(false);

    // ELEMET SETTINGS
    const appearance = {
        theme: "night",
        labels: "floating",
    };
    const options = {
        clientSecret: clientSecret,
        appearance: appearance,
    };

    // CREATE
    const handleMonthlySub = () => {
        setChangeSubscription(false);
        fetch("/create_subscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: user.email,
                priceId: price_id_monthly,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data.clientSecret, data.subscriptionId);
                setClientSecret(data.clientSecret);
            });
    };
    const handleAnnualSub = () => {
        setChangeSubscription(false);
        fetch("/create_subscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: user.email,
                priceId: price_id_annual,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data.clientSecret, data.subscriptionId);
                setClientSecret(data.clientSecret);
            });
    };
    const handleReactivateSub = () => {
        openSubscriptionLoader();
        fetch("/reactivate_subscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                stripe_product_id: user.stripeProductId,
                stripe_subscription_id: user.subscriptionId,
                stripe_customer_id: user.stripeCustomerId,
            }),
        });
        handleReactivateSSE();
    };
    const handleReactivateSSE = () => {
        const sse = new EventSource(
            `${
                REACT_APP_EXPRESS_BASE_URL +
                "/reactivate_subscription_stream?user_id=" +
                user.stripeCustomerId
            }`,
            {
                withCredentials: true,
            }
        );

        sse.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (Object.keys(response).length === 0) {
                // KEEP REQUESTING
                console.log("NO RESPONSE YET");
            } else {
                // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
                console.log("RESPONSE CAME IN");
                console.log(response);
                userCancelSubscription(response);
                setAlert("Your subscription has been reactivated", "success");
                sse.close();
            }
        };
        sse.onerror = () => {
            // error log here
            sse.close();
        };
        // return () => {
        //     sse.close();
        // };
    };

    // UPDATE BILLING PERIOD
    const [openBillingPeriod, setOpenBillingPeriod] = React.useState(false);
    const handleOpenBillingPeriod = () => {
        setOpenBillingPeriod(true);
    };
    const handleCloseBillingPeriod = () => {
        setOpenBillingPeriod(false);
    };
    const updateSubscription = (priceId, subscriptionId) => {
        openSubscriptionLoader();
        handleUpdatePeriodSSE(priceId);
        return fetch("/update-subscription", {
            method: "post",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                subscriptionId: subscriptionId,
                newPriceId: priceId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                return response;
            });
    };
    const handleUpdateSubscription = (type) => {
        console.log("UPDATE SUBSCRIPTION", type);
        if (type === 0 && !user.openLoader) {
            updateSubscription(price_id_monthly, user.subscriptionId);
        } else if (type === 1 && !user.openLoader) {
            updateSubscription(price_id_annual, user.subscriptionId);
        }
    };
    const handleUpdatePeriodSSE = (priceId) => {
        const sse = new EventSource(
            `${
                REACT_APP_EXPRESS_BASE_URL +
                "/update_period_subscription_stream?user_id=" +
                user.stripeCustomerId +
                "&price_id=" +
                priceId
            }`,
            {
                withCredentials: true,
            }
        );

        sse.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (Object.keys(response).length === 0) {
                // KEEP REQUESTING
                console.log("NO RESPONSE YET");
            } else {
                // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
                console.log("RESPONSE CAME IN");
                console.log(response);
                userCancelSubscription(response);
                setAlert("Your billing period has been updated", "success");
                sse.close();
            }
        };
        sse.onerror = () => {
            // error log here
            sse.close();
        };
        // return () => {
        //     sse.close();
        // };
    };

    // UPDATE PAYMENT METHOD
    const handleChangePaymentMethod = () => {
        setChangeSubscription(true);
        fetch("/stripe_secret", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ customer_id: user.customer_id }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data.client_secret);
                setClientSecret(data.client_secret);
            });
    };

    // DELETE
    const [openCancel, setOpenCancel] = React.useState(false);
    const handleOpenCancel = () => {
        setOpenCancel(true);
    };
    const handleCloseCancel = () => {
        setOpenCancel(false);
    };
    const cancelSubscription = () => {
        openSubscriptionLoader();
        handleCancelSSE();
        return fetch("/cancel-subscription", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: user.email,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((cancelSubscriptionResponse) => {
                // Display to the user that the subscription has been cancelled.
                // NOTIFY USER ABOUT CANCELED SUBSCRIPTION
            });
    };
    const handleCancelSSE = () => {
        const sse = new EventSource(
            `${
                REACT_APP_EXPRESS_BASE_URL +
                "/cancel_subscription_stream?user_id=" +
                user.stripeCustomerId
            }`,
            {
                withCredentials: true,
            }
        );

        sse.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (Object.keys(response).length === 0) {
                // KEEP REQUESTING
                console.log("NO RESPONSE YET");
            } else {
                // ADD DATA TO STATE, CLOSE PAYMENT MODAL AND ALERT TO USER
                console.log("RESPONSE CAME IN");
                console.log(response);
                userCancelSubscription(response);
                setAlert(
                    "Your subscription has been cancelled",
                    "notification"
                );
                sse.close();
            }
        };
        sse.onerror = () => {
            // error log here
            sse.close();
        };
        // return () => {
        //     sse.close();
        // };
    };

    return (
        <div className='height_auto padding_10px'>
            {!clientSecret ? (
                user.status === 0 ? (
                    <div className='width_100pr'>
                        <div className='width_100pr padding_10px text_2'>
                            Upgrade to premium
                        </div>
                        <div className='width_100pr padding_10px text_3'>
                            Save 30$ per year by selecting annual payment.
                            <br />
                            <div className='text_4'>
                                1% of every payment will be donated to a
                                selection of carbon removal projects!{" "}
                                <a
                                    href='https://stripe.com/climate'
                                    target='_blank'
                                >
                                    Read more
                                </a>
                                .
                            </div>
                        </div>
                        <div className='width_100pr padding_10px text_4'>
                            Click{" "}
                            <a
                                href='https://www.stocksonview.com/pricing'
                                target='_blank'
                            >
                                here
                            </a>{" "}
                            to see all advantages of a premium subscription on a
                            view.
                        </div>
                        <div className='width_100pr flex wrap space_around'>
                            <div className='flex center min_width_150px max_width_250px width_45pr_95pr padding_10px'>
                                <div
                                    className='button_blue width_100pr hover_opacity_80 padding_10px'
                                    onClick={
                                        user.openLoader
                                            ? null
                                            : handleMonthlySub
                                    }
                                >
                                    15$/mo
                                </div>
                            </div>
                            <div className='flex center min_width_150px max_width_250px width_45pr_95pr padding_10px'>
                                <div
                                    className='button_blue width_100pr hover_opacity_80 padding_10px'
                                    onClick={
                                        user.openLoader ? null : handleAnnualSub
                                    }
                                >
                                    150$/yr
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='width_700px_320px'>
                        <div className='padding_10px text_2'>
                            Billing period
                        </div>
                        {user.stripeCancelAtPeriodEnd ? (
                            <div>
                                <div className='width_100pr flex wrap padding_10px'>
                                    You cancelled your subscription. Our service
                                    will be available for you to use until:
                                    <br></br>{" "}
                                    {new Intl.DateTimeFormat("en-us", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    }).format(new Date(user.stripePeriodEnd))}
                                </div>
                                <div className='divider_no_margin'></div>
                                <div className='width_100pr'>
                                    <div className='width_100pr padding_10px text_2'>
                                        Reactivate subscription
                                    </div>
                                    <div className='width_100pr flex wrap space_between'>
                                        <div className='flex min_width_150px width_45pr_95pr padding_10px'>
                                            You can reactivate your old
                                            subscription at any time at no
                                            further cost until your old
                                            subscription would've run out!
                                        </div>
                                        <div className='flex min_width_150px max_width_250px width_45pr_95pr padding_10px'>
                                            <div
                                                className='button_blue width_100pr hover_opacity_80 padding_10px'
                                                onClick={
                                                    user.openLoader
                                                        ? null
                                                        : handleReactivateSub
                                                }
                                            >
                                                Reactivate
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {user.openLoader ? (
                                    <Preloader></Preloader>
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                <div className='width_100pr flex wrap'>
                                    <div className='width_50pr pl_10px pr_10px'>
                                        Next payment due
                                    </div>
                                    <div className='flex right_left width_50pr pl_10px pr_10px'>
                                        {user.stripePeriodEnd
                                            ? new Intl.DateTimeFormat("en-us", {
                                                  weekday: "long",
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                              }).format(
                                                  new Date(user.stripePeriodEnd)
                                              )
                                            : null}
                                        {/* {user.stripePeriodEnd} */}
                                    </div>
                                </div>
                                <div className='width_100pr flex wrap'>
                                    <div className='width_50pr pl_10px pr_10px'></div>
                                    <div className='flex right_left width_50pr pl_10px pr_10px'>
                                        $
                                        {user.stripeNextInvoice
                                            ? user.stripeNextInvoice
                                            : null}
                                    </div>
                                </div>

                                <div className='flex wrap '>
                                    <div className='width_50pr min_width_320px padding_10px'>
                                        {price_id_monthly ===
                                        user.stripeProductId
                                            ? "At the moment, you are billed on a monthly basis"
                                            : "At the moment, you are billed on an annual basis"}
                                    </div>
                                    <div className='flex right_left min_width_320px width_50pr padding_10px'>
                                        {price_id_monthly ===
                                        user.stripeProductId ? (
                                            <div
                                                className='button_blue hover_opacity_80 padding_10px'
                                                onClick={() => {
                                                    handleOpenBillingPeriod();
                                                }}
                                            >
                                                Change to annual billing
                                            </div>
                                        ) : (
                                            <div
                                                className='button_blue hover_opacity_80 padding_10px'
                                                onClick={() => {
                                                    handleOpenBillingPeriod();
                                                }}
                                            >
                                                Change to monthly billing
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Dialog
                                    open={openBillingPeriod}
                                    onClose={handleCloseBillingPeriod}
                                    maxWidth={false}
                                    className=''
                                >
                                    <div className='background_color_2 max_width_500px text_color_1'>
                                        <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                            <div className='mr_10px'>
                                                Edit billing period
                                            </div>
                                            <div
                                                className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                                onClick={
                                                    handleCloseBillingPeriod
                                                }
                                            >
                                                <AiIcons.AiOutlineClose />
                                            </div>
                                        </div>
                                        <div className='divider'></div>

                                        <div className='width_100pr padding_10px'>
                                            {price_id_monthly ===
                                            user.stripeProductId ? (
                                                <div className='min_width_320px padding_10px'>
                                                    If you switch to annual
                                                    billing, the balance of $135
                                                    will be charged to your
                                                    payment method the following
                                                    month.
                                                </div>
                                            ) : (
                                                <div className='min_width_320px width_100pr padding_10px'>
                                                    If you switch to monthly
                                                    billing, your payment method
                                                    will be charged $15 per
                                                    month after the current
                                                    period you paid for expires.
                                                </div>
                                            )}
                                        </div>
                                        <div className='width_100pr flex right padding_10px'>
                                            {price_id_monthly ===
                                            user.stripeProductId ? (
                                                <div
                                                    className='button_blue hover_opacity_80 padding_10px'
                                                    onClick={() => {
                                                        handleUpdateSubscription(
                                                            1
                                                        );
                                                    }}
                                                >
                                                    Change to annual billing
                                                </div>
                                            ) : (
                                                <div
                                                    className='button_blue hover_opacity_80 padding_10px'
                                                    onClick={() => {
                                                        handleUpdateSubscription(
                                                            0
                                                        );
                                                    }}
                                                >
                                                    Change to monthly billing
                                                </div>
                                            )}
                                        </div>
                                        {user.openLoader ? (
                                            <Preloader></Preloader>
                                        ) : null}
                                    </div>
                                </Dialog>
                                <div className='divider_no_margin'></div>
                                <div className='width_100pr padding_10px text_2'>
                                    Payment method
                                </div>
                                {user.paymentMethod &&
                                user.paymentMethod.type === "card" ? (
                                    <div className='width_100pr flex wrap'>
                                        <div className='width_50pr pl_10px pr_10px'>
                                            {user.paymentMethod.brand} (...
                                            {user.paymentMethod.last4})
                                        </div>
                                        <div className='flex right_left width_50pr pl_10px pr_10px'>
                                            expires:{" "}
                                            {user.paymentMethod.exp_month}/
                                            {user.paymentMethod.exp_year}
                                        </div>
                                    </div>
                                ) : null}
                                {user.paymentMethod &&
                                user.paymentMethod.type === "sepa_debit" ? (
                                    <div className='width_100pr flex wrap'>
                                        <div className='width_50pr pl_10px pr_10px'>
                                            Sepa Debit (...
                                            {user.paymentMethod.last4})
                                        </div>
                                        <div className='flex right_left width_50pr pl_10px pr_10px'>
                                            {user.paymentMethod.name},{" "}
                                            {user.paymentMethod.country}
                                        </div>
                                    </div>
                                ) : null}
                                <div className='width_100pr flex wrap'>
                                    <div className='width_50pr min_width_320px padding_10px'></div>
                                    <div className='flex right_left min_width_320px width_50pr padding_10px'>
                                        <div
                                            className='button_blue hover_opacity_80 padding_10px'
                                            onClick={handleChangePaymentMethod}
                                        >
                                            Change payment method
                                        </div>
                                    </div>
                                </div>
                                <div className='divider_no_margin'></div>
                                {/* CANCEL */}
                                <div className='width_100pr wrap flex'>
                                    <div className='width_50pr min_width_320px padding_10px'>
                                        If you cancel, your payment method will
                                        no longer be charged and thus your
                                        subscription will run out at the end of
                                        the paid period.
                                    </div>
                                    <div className='flex right_left min_width_320px width_50pr padding_10px'>
                                        <div
                                            className='button_delete hover_opacity_60 padding_10px'
                                            onClick={handleOpenCancel}
                                        >
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                                <Dialog
                                    open={openCancel}
                                    onClose={handleCloseCancel}
                                    maxWidth={false}
                                    className=''
                                >
                                    <div className='max_width_500px background_color_2 text_color_1'>
                                        <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                                            <div className='mr_10px'>
                                                Cancel your subscription
                                            </div>
                                            <div
                                                className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                                onClick={handleCloseCancel}
                                            >
                                                <AiIcons.AiOutlineClose />
                                            </div>
                                        </div>
                                        <div className='divider'></div>
                                        <div className='width_100pr flex padding_10px'>
                                            <div className='width_50pr min_width_320px'>
                                                If you cancel, your payment
                                                method will no longer be charged
                                                but you can still enjoy our
                                                premium functionality until for
                                                the period you originally paid
                                                for.
                                                <br />
                                            </div>
                                            <div className='flex right width_50pr'>
                                                <div
                                                    className='button_delete hover_opacity_60 padding_10px'
                                                    onClick={
                                                        user.openLoader
                                                            ? null
                                                            : cancelSubscription
                                                    }
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                        {user.openLoader ? (
                                            <Preloader></Preloader>
                                        ) : null}
                                    </div>
                                </Dialog>
                            </div>
                        )}
                    </div>
                )
            ) : (
                <Elements stripe={stripePromise} options={options}>
                    <StripeForm
                        clientSecret={clientSecret}
                        changeSubscription={changeSubscription}
                    />
                </Elements>
            )}

            {/* {clientSecret && (
  
          <Elements stripe={stripePromise} options={options}>
  
            <StripeForm clientSecret={clientSecret} changeSubscription={changeSubscription}/>
  
          </Elements>
  
        )} */}
        </div>
    );
}

StripePage.propTypes = {
    user: PropTypes.object.isRequired,
    userCancelSubscription: PropTypes.func.isRequired,
    openSubscriptionChanges: PropTypes.func.isRequired,
    openSubscriptionLoader: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {
    userCancelSubscription,
    openSubscriptionChanges,
    openSubscriptionLoader,
    setAlert,
})(StripePage);
