import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import SearchBarResult from "../../layout/sidebar/SearchBarResult";
import CreatePortfolio from "./CreatePortfolio";
import Preloader from "../../layout/Preloader";

// MUI
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { getSearchBarCompanies } from "../../../actions/screenerAction";
import {
    updateCompanyShareCount,
    submitPortfolioTransaction,
    submitCompanyShareCount,
    addPortfolioCompany,
} from "../../../actions/portfolioActions";
import { setAlert } from "../../../actions/alertActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const TransactionForm = ({
    portfolioType,
    currentPortfolio,
    authorized,
    recommendations,
    pre_selected_ticker,
    closeTransactionModal,
    portfolio,
    user,
    screener: { searchbar_companies },
    getSearchBarCompanies,
    updateCompanyShareCount,
    submitPortfolioTransaction,
    submitCompanyShareCount,
    addPortfolioCompany,
    setAlert,
}) => {
    const useintl = useIntl();
    const ref = useRef();

    useEffect(() => {
        setTransactionTicker(
            pre_selected_ticker && pre_selected_ticker !== null
                ? pre_selected_ticker
                : null
        );
        setOpenLoader(false);
        // eslint-disable-next-line
    }, []);

    // LOADING ANIMATION
    const [openLoader, setOpenLoader] = React.useState(false);

    // OPEN/CLOSE MODAL
    const handleCloseShareTransaction = () => {
        closeTransactionModal();
        setShareCount(oldShareCount);
    };
    const handleCloseNewShareTransaction = () => {
        closeTransactionModal();
    };

    // CHANGE PORTFOLIO TYPE
    const [portfolioTypeForm, setPortfolioTypeForm] = React.useState(
        portfolioType ? portfolioType : "manager"
    );
    const handlePortfolioTypeForm = (event) => {
        if (event.target.value !== portfolioTypeForm) {
            setPortfolioTypeForm(event.target.value);
        }
    };

    // STATES
    const [oldShareCount, setOldShareCount] = React.useState(
        // currentPortfolio.allocation[company.ticker].count
        null
    );
    const [shareCount, setShareCount] = React.useState(
        // currentPortfolio.allocation[company.ticker].count
        0
    );
    const handleLocalShareCount = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0) &&
            Number(event.target.value <= 1000000000)
        ) {
            setShareCount(Number(event.target.value));
        } else {
            setShareCount(0);
        }
    };
    const [transactionTicker, setTransactionTicker] = React.useState(
        pre_selected_ticker && pre_selected_ticker !== null
            ? pre_selected_ticker
            : null
    );

    // SELECT THE PORTFOLIO
    const [portfolioState, setPortfolioState] = React.useState({
        id: currentPortfolio ? currentPortfolio.id : null,
        user_id: currentPortfolio ? currentPortfolio.user_id : null,
    });
    const handlePortfolioState = (id) => {
        portfolio.portfolios.map((port) => {
            if (port.id === id) {
                setPortfolioState({ id: id, user_id: port.user_id });
                if (port.allocation === null) {
                    setPortfolioAllocation({});
                } else {
                    setPortfolioAllocation(port.allocation);
                }
            } else {
            }
        });
    };
    const [portfolioAllocation, setPortfolioAllocation] = React.useState(
        currentPortfolio && currentPortfolio.allocation
            ? currentPortfolio.allocation
            : null
    );
    const [portfolioAssets, setPortfolioAssets] = React.useState(
        currentPortfolio && currentPortfolio.assets
            ? currentPortfolio.assets
            : null
    );

    // CREATE A PORTFOLIO MODAL
    const [openCreatePortfolio, setOpenCreatePortfolio] = React.useState(false);
    const handleClickOpenCreatePortfolio = () => {
        setOpenCreatePortfolio(true);
    };
    const handleClickCloseCreatePortfolio = () => {
        setOpenCreatePortfolio(false);
    };

    // SUBMIT TO PORTFOLIO CREATOR
    const handleCreatorShareCount = (newShareCount, transaction_ticker) => {
        setOldShareCount(newShareCount);
        updateCompanyShareCount(
            transaction_ticker,
            newShareCount,
            portfolioAllocation,
            portfolioAssets,
            "creator"
        );
        closeTransactionModal();
    };

    // SUBMIT TO MANAGER
    const handleSetShareCount = (newShareCount, transaction_ticker) => {
        setOldShareCount(newShareCount);

        console.log("TICKER: ", transaction_ticker, newShareCount);

        if (
            currentPortfolio &&
            currentPortfolio.ticker !== null &&
            currentPortfolio.ticker.includes(transaction_ticker)
        ) {
            console.log("TICKER ALREADY EXISTING");
            // TICKER ALREADY EXISTING IN PORTFOLIO
            // LOCAL SUBMIT
            updateCompanyShareCount(
                transaction_ticker,
                newShareCount,
                portfolioAllocation,
                portfolioAssets,
                portfolioState.id
            );
            // DATABASE SUBMIT
            submitCompanyShareCount(
                portfolio.current,
                portfolioAllocation,
                null
            );
            handleCloseNewShareTransaction();
        } else {
            console.log("TICKER NEW TO PORTFOLIO");
            // TICKER NOT YET CONTAINED IN PORTFOLIO
            handleAddToPortfolio(transaction_ticker);
        }
    };
    // SUBMIT TO MANAGER IN CASE COMPANY NOT YET CONTAINED IN PORTFOLIO
    const handleAddToPortfolio = (ticker) => {
        if (ticker === null || ticker === "") {
            setAlert(
                "You need to provide a stock to complete the transaction",
                "warning"
            );
        } else if (shareCount === null && shareCount <= 0) {
            setAlert(
                "You need to provide a valid (positive number) amount of shares to complete the transaction",
                "warning"
            );
        } else {
            let data = {
                user_id: user.id,
                ticker: ticker,
                portfolio: currentPortfolio.id,
                amount: shareCount,
            };

            let newPortfolioTickerSymbols =
                currentPortfolio && currentPortfolio.ticker !== null
                    ? currentPortfolio.ticker
                    : [];
            newPortfolioTickerSymbols.push(ticker);

            handleCloseNewShareTransaction();

            addPortfolioCompany(
                data,
                newPortfolioTickerSymbols,
                portfolioAllocation,
                null,
                "add"
            );
        }
        // handleClickPortfolioSuccess();
    };

    // SUBMIT TRANSACTION TO TRACKER
    // TRANSACTION TYPE
    const [transactionType, setTransactionType] = React.useState("buy");
    const handleTransactionType = () => {
        if (transactionType === "buy") {
            setTransactionType("sell");
        } else {
            setTransactionType("buy");
        }
    };
    // DATE PICKER
    const [transactionDateValue, setTransactionDateValue] = React.useState(
        new Date()
    );
    const handleTransactionDateValue = (newValue) => {
        setTransactionDateValue(newValue);
    };
    // TRANSACTION SHARE PRICE
    const [transactionPricePerShare, setTransactionPricePerShare] =
        React.useState(0);

    const handleTransactionPricePerShare = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0) &&
            Number(event.target.value <= 1000000000)
        ) {
            setTransactionPricePerShare(Number(event.target.value));
            setTransactionSum(
                Number(event.target.value) * transactionShareCount
            );
        } else {
            setShareCount(0);
        }
    };
    // TRANSACTION SHARE COUNT
    const [transactionShareCount, setTransactionShareCount] = React.useState(0);
    const handleTransactionShareCount = (event) => {
        if (
            !isNaN(Number(event.target.value)) &&
            typeof Number(event.target.value) === "number" &&
            Number(event.target.value >= 0) &&
            Number(event.target.value <= 1000000000)
        ) {
            setTransactionShareCount(Number(event.target.value));
            setTransactionSum(
                transactionPricePerShare * Number(event.target.value)
            );
        } else {
            setShareCount(0);
        }
    };
    // TRANSACTION TOTAL SUM
    const [transactionSum, setTransactionSum] = React.useState(
        transactionPricePerShare * transactionShareCount
    );
    // SUBMIT TRANSACTION
    const handleSubmitTransaction = () => {
        if (transactionTicker === null || transactionTicker === []) {
            setAlert(
                "You need to provide the stock to complete the transaction",
                "warning"
            );
        } else if (
            transactionPricePerShare === null ||
            transactionPricePerShare === 0
        ) {
            setAlert(
                "You need to provide a price per share to complete the transaction",
                "warning"
            );
        } else if (
            transactionShareCount === null ||
            transactionShareCount === 0
        ) {
            setAlert(
                "You need to provide an amount of shares to complete the transaction",
                "warning"
            );
        } else {
            submitPortfolioTransaction(
                transactionTicker,
                transactionType,
                transactionDateValue,
                transactionPricePerShare,
                transactionShareCount,
                portfolioState.id,
                user.id,
                portfolioState.user_id,
                portfolio.risk_free_rate
            );
            setOpenLoader(true);
        }
    };

    // SEARCH COMPANIES FIELD
    const [showCompanies, setShowCompanies] = React.useState(false);
    const handleHideCompanies = () => {
        setShowCompanies(false);
    };
    React.useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                showCompanies &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                setShowCompanies(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showCompanies]);

    const [searchBarValue, setSearchBarValue] = React.useState("");
    const handleSearchBarValue = (event) => {
        let str = event.target.value.replace(/[^a-zA-Z0-9. ]/g, "");
        setSearchBarValue(str);
        if (str.length >= 1) {
            getSearchBarCompanies(str);
        } else {
            getSearchBarCompanies(null);
        }
    };
    const clearSearchBarValue = () => {
        setSearchBarValue("");
        document.getElementById("searchbar_input_field").reset();
        getSearchBarCompanies(null);
    };

    return (
        <div className='max_width_500px background_color_2 text_color_1'>
            {/* HEADER */}
            <div className='flex space_between title_4 mt_10px ml_15px mr_15px'>
                <div className='flex flex_column align_items_start mr_10px'>
                    {window.location.pathname === "/creator" ? (
                        <div className=''>Edit share count</div>
                    ) : (
                        <div className=''>Add transaction</div>
                    )}
                    {window.location.pathname !== "/creator" ? (
                        <div className='text_5'>
                            <div className='width_180px'>
                                <FormControl fullWidth size='small'>
                                    <Select
                                        id='demo-simple-select'
                                        className='background_color_3 text_color_1'
                                        value={portfolioTypeForm}
                                        defaultValue={portfolioTypeForm}
                                        onChange={handlePortfolioTypeForm}
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {}}
                                            value={"manager"}
                                        >
                                            <div className='pl_10px'>
                                                Manager
                                            </div>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {}}
                                            value={"tracker"}
                                        >
                                            <div className='pl_10px'>
                                                Tracker
                                            </div>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    className='cursor_pointer title_4 flex center ml_25px mt_5px'
                    onClick={handleCloseShareTransaction}
                >
                    <AiIcons.AiOutlineClose />
                </div>
            </div>
            <div className='divider'></div>

            {/* SELECT A PORTFOLIO */}
            {(window.location.pathname !== "/portfolio" ||
                !authorized ||
                recommendations) &&
            window.location.pathname !== "/creator" ? (
                <div>
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        <div className='width_auto_100pr margin_10px flex center'>
                            {/* DROPDOWN */}
                            <div className='min_width_220px width_90pr'>
                                <FormControl
                                    fullWidth
                                    // size='small'
                                    sx={{ minWidth: 220 }}
                                >
                                    <InputLabel
                                        id='demo-mutiple-checkbox-label'
                                        className=''
                                    >
                                        Select Portfolio
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        className='min_width_220px height_32px background_color_3'
                                        id='demo-simple-select'
                                        value={portfolioState.id}
                                        // onChange={handlePortfolioState}
                                        label='Select Portfolio'
                                        renderValue={() => {
                                            let render = "";
                                            portfolio.portfolios.map(
                                                (portfolio) =>
                                                    portfolioState.id ===
                                                        portfolio.id &&
                                                    portfolio.user_id ===
                                                        user.id
                                                        ? (render +=
                                                              portfolio.name)
                                                        : null
                                            );
                                            return render;
                                        }}
                                    >
                                        <MenuItem disabled value=''>
                                            <em>Select Portfolio</em>
                                        </MenuItem>
                                        {portfolio.portfolios.map((port) =>
                                            port.user_id === user.id ? (
                                                <MenuItem
                                                    key={port.id}
                                                    name={port.name}
                                                    value={port.id}
                                                >
                                                    {/* <Checkbox
                                                                        checked={
                                                                            false
                                                                        }
                                                                        
                                                                    /> */}
                                                    <div
                                                        className='mr_10px'
                                                        onClick={() => {
                                                            handlePortfolioState(
                                                                port.id
                                                            );
                                                        }}
                                                    >
                                                        {icon_storage.map(
                                                            (icon) =>
                                                                icon.name ===
                                                                port.icon
                                                                    ? icon.icon
                                                                    : null
                                                        )}
                                                    </div>
                                                    <ListItemText
                                                        primary={port.name}
                                                        onClick={() => {
                                                            handlePortfolioState(
                                                                port.id
                                                            );
                                                        }}
                                                    />
                                                </MenuItem>
                                            ) : null
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* CREATE BUTTON */}
                        <div className='width_auto_100pr min_width_200px margin_10px flex center'>
                            <div
                                className='height_32px button_blue padding_10px width_90pr hover_opacity_80 text_2 cursor_pointer'
                                onClick={() => {
                                    handleClickOpenCreatePortfolio();
                                }}
                            >
                                Create Portfolio
                            </div>
                        </div>
                        {/* CREATE NEW PORTFOLIO MODAL */}
                        <Dialog
                            open={openCreatePortfolio}
                            onClose={handleClickCloseCreatePortfolio}
                            maxWidth={false}
                        >
                            <CreatePortfolio
                                closeModal={handleClickCloseCreatePortfolio}
                            />
                        </Dialog>
                    </div>
                    <div className='divider'></div>
                </div>
            ) : null}

            {/* SEARCH DROPDOWN */}
            {transactionTicker && !showCompanies ? (
                <div
                    className='button_white mt_15px pl_15px pr_15px'
                    onClick={() => {
                        if (window.location.pathname !== "/creator") {
                            setShowCompanies(false);
                            setTransactionTicker(null);
                        }
                    }}
                >
                    {transactionTicker}
                </div>
            ) : (
                <div className='pl_15px pr_15px mt_15px mb_15px'>
                    <div
                        className={
                            searchbar_companies !== null &&
                            searchbar_companies.length > 0 &&
                            showCompanies
                                ? "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark mt_29px"
                                : "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark"
                        }
                        ref={ref}
                    >
                        <div
                            className={
                                searchbar_companies !== null &&
                                searchbar_companies.length > 0 &&
                                showCompanies
                                    ? "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px_top height_30px"
                                    : "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px height_30px"
                            }
                        >
                            <div className='mr_5px text_2 color_white height_100pr'>
                                <AiIcons.AiOutlineSearch />
                            </div>
                            <form id='searchbar_input_field'>
                                <input
                                    type='text'
                                    className={
                                        searchBarValue !== ""
                                            ? "text_color_1 text_2 ml_5px"
                                            : "text_color_2 text_2 ml_5px"
                                    }
                                    placeholder={
                                        searchBarValue !== ""
                                            ? searchBarValue
                                            : useintl.formatMessage({
                                                  id: "general.search",
                                              }) + "..."
                                    }
                                    onKeyUp={(event) => {
                                        handleSearchBarValue(event);
                                    }}
                                    onClick={() =>
                                        setShowCompanies(
                                            (oldState) => !oldState
                                        )
                                    }
                                />
                            </form>
                        </div>
                        {searchbar_companies !== null &&
                        searchbar_companies.length > 0 &&
                        showCompanies ? (
                            <div className='flex flex_column align_items_start width_100pr min_width_250px height_100pr'>
                                {searchbar_companies.map((company) => (
                                    <div
                                        onClick={() => {
                                            handleHideCompanies();
                                            setTransactionTicker(
                                                company.ticker
                                            );
                                            clearSearchBarValue();
                                        }}
                                        className='width_100pr min_width_250px cursor_pointer'
                                    >
                                        <SearchBarResult
                                            search_bar_company={company}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : // <div className='flex flex_column align_items_start width_100pr min_width_250px'></div>
                        null}
                    </div>
                </div>
            )}
            <div className='divider'></div>

            {/* SIMPLE SHARECOUNT EDIT */}
            {portfolioTypeForm &&
            portfolioTypeForm !== null &&
            portfolioTypeForm === "manager" ? (
                <div>
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        <div className='width_50pr_100pr mt_10px flex center'>
                            <div className='height_32px width_90pr pl_10px pt_3px border_box border_1px_solid border_radius_5px input_basic background_color_4 text_2'>
                                <input
                                    type='number'
                                    // defaultValue={shareCount}
                                    placeholder='# Shares'
                                    min='0'
                                    max='100000000'
                                    onKeyUp={handleLocalShareCount}
                                    className='text_color_1 text_3'
                                />
                            </div>
                        </div>
                        <div className='width_50pr_100pr mt_10px flex center'>
                            {!openLoader ? (
                                <div
                                    className='height_32px button_blue width_90pr text_2 hover_opacity_80 cursor_pointer'
                                    onClick={() => {
                                        if (
                                            window.location.pathname ===
                                            "/creator"
                                        ) {
                                            handleCreatorShareCount(
                                                shareCount,
                                                transactionTicker
                                            );
                                        } else {
                                            handleSetShareCount(
                                                shareCount,
                                                transactionTicker
                                            );
                                        }
                                    }}
                                >
                                    Submit
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* LOADING ANIMATION AFTER SUBMIT */}
                    {openLoader ? (
                        <div className='width_100pr padding_10px flex space_around wrap'>
                            <Preloader />
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* ADD TRANSACTION TO PORTFOLIO */}
            {window.location.pathname !== "/creator" &&
            portfolioTypeForm &&
            portfolioTypeForm !== null &&
            portfolioTypeForm === "tracker" ? (
                <div>
                    <div className='divider_no_margin'></div>

                    {/* TYPE AND DATE */}
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        {/* BUY/ SELL */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            <div className='width_100pr'>
                                <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                                    Type
                                </div>
                                <div className='flex center'>
                                    <div
                                        className='width_90pr height_35px accent_color_2 text_3 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'
                                        onClick={handleTransactionType}
                                    >
                                        <div
                                            className={
                                                transactionType === "sell"
                                                    ? "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                                    : "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            }
                                        >
                                            Buy
                                        </div>
                                        <div
                                            className={
                                                transactionType === "sell"
                                                    ? "background_color_2 width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                                    : "width_50pr pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            }
                                        >
                                            Sell
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* CALENDAR */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            <div className='width_100pr'>
                                <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                                    Date
                                </div>
                                <div className='flex center'>
                                    <div className='flex center width_90pr height_35px accent_color_2 text_3 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DesktopDatePicker
                                                // label='Date desktop'
                                                sx={{
                                                    width: 1,
                                                    // '& .MuiFormControl-root-MuiTextField-root': {
                                                    //     width: 1,
                                                    // },
                                                    "& .MuiOutlinedInput-notchedOutline":
                                                        {
                                                            borderWidth: 0,
                                                        },
                                                }}
                                                inputFormat='MM/dd/yyyy'
                                                value={transactionDateValue}
                                                onChange={
                                                    handleTransactionDateValue
                                                }
                                                maxDate={new Date()}
                                                minDate={new Date("2011,12,01")}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SHARE PRICE AND COUNT */}
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        {/* PRICE PER SHARE */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            <div className='width_100pr'>
                                <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                                    Price per share
                                </div>
                                <div className='flex center'>
                                    <div className='height_32px width_90pr pl_10px pt_3px border_box input_basic border_1px_solid border_radius_5px background_color_4 text_2'>
                                        <input
                                            type='number'
                                            defaultValue=''
                                            onKeyUp={
                                                handleTransactionPricePerShare
                                            }
                                            className='text_color_1 text_3'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* SHARE COUNT */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            <div className='width_100pr'>
                                <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                                    Shares
                                </div>
                                <div className='flex center'>
                                    <div className='height_32px width_90pr pl_10px pt_3px border_box input_basic border_1px_solid border_radius_5px background_color_4 text_2'>
                                        <input
                                            type='number'
                                            defaultValue=''
                                            onKeyUp={
                                                handleTransactionShareCount
                                            }
                                            className='text_color_1 text_3'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* TOTAL AND SUBMIT */}
                    <div className='width_100pr padding_10px flex space_around wrap mb_10px'>
                        {/* TOTAL SUM */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            <div className='width_100pr'>
                                <div className='text_2 height_25px text_color_1 mb_10px pl_5px'>
                                    Total
                                </div>
                                <div className='flex center'>
                                    <div className='height_32px width_90pr pl_10px pt_3px hover_opacity_80 border_box border_1px_solid border_radius_5px background_color_4 text_2'>
                                        <input
                                            type='text'
                                            defaultValue=''
                                            readOnly
                                            // onKeyUp={handleChangePortfolioName}
                                            className='text_color_1 text_3 cursor_default'
                                            value={transactionSum}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* SUBMIT */}
                        <div className='width_50pr_100pr flex left mt_10px'>
                            {!openLoader ? (
                                <div className='width_100pr'>
                                    <div className='text_2 height_25px text_color_1 mb_10px'></div>
                                    <div className='flex center'>
                                        <div
                                            className='height_32px button_blue width_90pr hover_opacity_80 text_2 cursor_pointer'
                                            onClick={handleSubmitTransaction}
                                        >
                                            Submit
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* LOADING ANIMATION AFTER SUBMIT */}
                    {openLoader ? (
                        <div className='width_100pr padding_10px flex space_around wrap'>
                            <Preloader />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

TransactionForm.propTypes = {
    searchbar_companies: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired,
    updateCompanyShareCount: PropTypes.func.isRequired,
    submitPortfolioTransaction: PropTypes.func.isRequired,
    submitCompanyShareCount: PropTypes.func.isRequired,
    getSearchBarCompanies: PropTypes.func.isRequired,
    addPortfolioCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    updateCompanyShareCount,
    submitPortfolioTransaction,
    submitCompanyShareCount,
    getSearchBarCompanies,
    addPortfolioCompany,
    setAlert,
})(TransactionForm);
