import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as AiIcons from "react-icons/ai";

// MUI
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";

// COMPONENTS
import FeedbackIcon from "../../elements/FeedbackIcon";

// ACTIONS
import { setCreatorStrategy } from "../../../../actions/portfolioActions";
import { setCreatorInterests } from "../../../../actions/portfolioActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

const CreatorStrategy = ({
    user,
    portfolio,
    setCreatorInterests,
    setCreatorStrategy,
}) => {
    const useintl = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let [esgCheck, setEsgCheck] = React.useState(
        portfolio.in_creation_preferences.esg
    );
    let [dividendsCheck, setDividendsCheck] = React.useState(
        portfolio.in_creation_preferences.dividends
    );
    let [valueCheck, setValueCheck] = React.useState(
        portfolio.in_creation_preferences.value
    );
    let [growthCheck, setGrowthCheck] = React.useState(
        portfolio.in_creation_preferences.growth
    );

    const handleCheckBoxes = (event) => {
        let check1 = false;
        let check2 = false;
        let check3 = false;
        let check4 = false;
        console.log(event.target.checked);
        if (event.target.name === "esg") {
            check1 = event.target.checked;
            setEsgCheck(check1);
        } else {
            check1 = esgCheck;
        }

        if (event.target.name === "dividends") {
            check2 = event.target.checked;
            setDividendsCheck(check2);
        } else {
            check2 = dividendsCheck;
        }

        if (event.target.name === "value") {
            check3 = event.target.checked;
            check4 = event.target.checked ? false : growthCheck;
            setValueCheck(check3);
            setGrowthCheck(check4);
        } else if (event.target.name !== "growth") {
            check3 = valueCheck;
        }

        if (event.target.name === "growth") {
            check3 = event.target.checked ? false : valueCheck;
            check4 = event.target.checked;
            setValueCheck(check3);
            setGrowthCheck(check4);
        } else if (event.target.name !== "value") {
            check4 = growthCheck;
        }

        setCreatorStrategy(
            check1,
            check2,
            check3,
            check4,
            portfolio.in_creation_preferences
        );
    };

    /*ESG Modal*/
    const [openESG, setOpenESG] = React.useState(false);
    const handleShowESG = () => {
        setOpenESG(true);
    };
    const handleCloseESG = () => {
        setOpenESG(false);
    };

    /*Dividends Modal*/
    const [openDividends, setOpenDividends] = React.useState(false);
    const handleShowDividends = () => {
        setOpenDividends(true);
    };
    const handleCloseDividends = () => {
        setOpenDividends(false);
    };

    /*Value Modal*/
    const [openValue, setOpenValue] = React.useState(false);
    const handleShowValue = () => {
        setOpenValue(true);
    };
    const handleCloseValue = () => {
        setOpenValue(false);
    };

    /*Growth Modal*/
    const [openGrowth, setOpenGrowth] = React.useState(false);
    const handleShowGrowth = () => {
        setOpenGrowth(true);
    };
    const handleCloseGrowth = () => {
        setOpenGrowth(false);
    };
    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.risk",
        })}`,
        location_index: 1,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.assessment",
        })}`,
        location_index: 1,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "portfolio.creator.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/Z2Cy-SHKviU'
                title='The top FEATURES our STOCK MARKET SCREENER offers'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "portfolio.creator.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.header_text_1",
        }),
        description_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.description_text_1",
        }),
    };

    return (
        <div className='height_90pr'>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                informationInfo={informationInfo}
            />
            <div className='height_70px text_1 pl_25px pt_20px pb_15px'>
                <FormattedMessage
                    id='general.strategy'
                    defaultMessage='Strategy'
                />
            </div>
            <div className='divider_no_margin'></div>
            <div className='background_color_3 pb_50px'>
                {/* ESG */}
                <div className='pt_50px ms_auto width_90pr_95pr text_2 flex space_between'>
                    {user.status !== 0 ? (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={esgCheck}
                                        onChange={handleCheckBoxes}
                                        name='esg'
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <FormattedMessage
                                    id='portfolio.creator.strategy.question_1'
                                    defaultMessage='I want to invest in <b>ESG screened</b> stocks only!'
                                    values={{ b: (words) => <b>{words}</b> }}
                                />
                            </p>
                        </div>
                    ) : (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={esgCheck}
                                        onChange={handleCheckBoxes}
                                        name='esg'
                                        disabled={true}
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <Link
                                    to={`/account`}
                                    className='hover_opacity_80 mr_5px'
                                >
                                    Upgrade
                                </Link>
                                to select <b>ESG screened</b> stocks only!
                                {/* <FormattedMessage
                                    id='portfolio.creator.strategy.question_1'
                                    defaultMessage='I want to invest in <b>ESG screened</b> stocks only!'
                                    values={{ b: (words) => <b>{words}</b> }}
                                /> */}
                            </p>
                        </div>
                    )}

                    <AiIcons.AiOutlineInfoCircle
                        className='width_35px text_1 cursor_pointer'
                        onClick={handleShowESG}
                    />

                    {/* ESG Modal */}
                    <Dialog
                        open={openESG}
                        onClose={handleCloseESG}
                        maxWidth={false}
                        className='width_70pr_95pr min_width_320px max_width_700px ms_auto'
                    >
                        <div>
                            <div>
                                {/* headerline */}
                                <div className='flex space_between title_5 mt_20px ml_15px mr_15px'>
                                    <div className='flex'>
                                        <FormattedMessage
                                            id='general.esg_screening'
                                            defaultMessage='ESG Screening'
                                        />
                                    </div>
                                    <div
                                        className='cursor_pointer title_5 flex center ml_25px mt_5px'
                                        onClick={handleCloseESG}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                {/* description */}
                                <div className='mt_15px mb_15px ms_auto text_block width_90pr'>
                                    <FormattedMessage
                                        id='explanation.esg_screening'
                                        defaultMessage='ESG is a system for how to measure the
                                    sustainability of a company or investment in
                                    three specific categories: environmental, social
                                    and governance. Socially responsible investing,
                                    ethical investing, sustainable investing and
                                    impact investing are more general terms.'
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                {/* DIVIDEND */}
                <div className='mt_50px ms_auto width_90pr_95pr text_2 flex space_between'>
                    <div className='flex left'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={dividendsCheck}
                                    onChange={handleCheckBoxes}
                                    name='dividends'
                                    color='primary'
                                />
                            }
                            label=''
                        />
                        <p>
                            <FormattedMessage
                                id='portfolio.creator.strategy.question_2'
                                defaultMessage='I want to invest in <b>dividend paying</b> stocks only!'
                                values={{ b: (words) => <b>{words}</b> }}
                            />
                        </p>
                    </div>

                    <AiIcons.AiOutlineInfoCircle
                        className='width_35px text_1 cursor_pointer'
                        onClick={handleShowDividends}
                    />
                    {/* Dividends Modal */}
                    <Dialog
                        open={openDividends}
                        onClose={handleCloseDividends}
                        maxWidth={false}
                        className='width_70pr_95pr min_width_320px max_width_700px ms_auto'
                    >
                        <div>
                            <div>
                                {/* headerline */}
                                <div className='flex space_between title_5 mt_20px ml_15px mr_15px'>
                                    <div className='flex'>
                                        <FormattedMessage
                                            id='general.dividends'
                                            defaultMessage='Dividends'
                                        />
                                    </div>
                                    <div
                                        className='cursor_pointer title_5 flex center ml_25px mt_5px'
                                        onClick={handleCloseDividends}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                {/* description */}
                                <div className='mt_15px mb_15px ms_auto text_block width_90pr'>
                                    <FormattedMessage
                                        id='explanation.dividends'
                                        defaultMessage='Dividends are payments a company makes to share
                                    profits with its stockholders. A dividend is
                                    paid per share of stock — if you own 30 shares
                                    in a company and that company pays $2 in annual
                                    cash dividends, you will receive $60 per year.'
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                {/* VALUE */}
                <div className='mt_50px ms_auto width_90pr_95pr text_2 flex space_between'>
                    {user.status !== 0 ? (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={valueCheck}
                                        onChange={handleCheckBoxes}
                                        name='value'
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <FormattedMessage
                                    id='portfolio.creator.strategy.question_3'
                                    defaultMessage='I want to invest in <b>value</b> stocks.'
                                    values={{ b: (words) => <b>{words}</b> }}
                                />
                            </p>
                        </div>
                    ) : (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={valueCheck}
                                        onChange={handleCheckBoxes}
                                        name='value'
                                        disabled={true}
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <Link
                                    to={`/account`}
                                    className='hover_opacity_80 mr_5px'
                                >
                                    Upgrade
                                </Link>
                                to select <b>value</b> stocks.
                            </p>
                        </div>
                    )}

                    <AiIcons.AiOutlineInfoCircle
                        className='width_35px text_1 cursor_pointer'
                        onClick={handleShowValue}
                    />

                    {/* Value Modal */}
                    <Dialog
                        open={openValue}
                        onClose={handleCloseValue}
                        maxWidth={false}
                        className='width_70pr_95pr min_width_320px max_width_700px ms_auto'
                    >
                        <div>
                            <div>
                                {/* headerline */}
                                <div className='flex space_between title_5 mt_20px ml_15px mr_15px'>
                                    <div className='flex'>
                                        <FormattedMessage
                                            id='general.value_stocks'
                                            defaultMessage='Value Stocks'
                                        />
                                    </div>
                                    <div
                                        className='cursor_pointer title_5 flex center ml_25px mt_5px'
                                        onClick={handleCloseValue}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                {/* description */}
                                <div className='mt_15px mb_15px ms_auto text_block width_90pr'>
                                    <FormattedMessage
                                        id='explanation.value_stocks'
                                        defaultMessage='Value'
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                {/* GROWTH */}
                <div className='mt_50px ms_auto width_90pr_95pr text_2 flex space_between'>
                    {user.status !== 0 ? (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={growthCheck}
                                        onChange={handleCheckBoxes}
                                        name='growth'
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <FormattedMessage
                                    id='portfolio.creator.strategy.question_4'
                                    defaultMessage='I want to invest in <b>growth</b> stocks.'
                                    values={{ b: (words) => <b>{words}</b> }}
                                />
                            </p>
                        </div>
                    ) : (
                        <div className='flex left'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={growthCheck}
                                        onChange={handleCheckBoxes}
                                        name='growth'
                                        disabled={true}
                                        color='primary'
                                    />
                                }
                                label=''
                            />
                            <p>
                                <Link
                                    to={`/account`}
                                    className='hover_opacity_80 mr_5px'
                                >
                                    Upgrade
                                </Link>
                                to select <b>growth</b> stocks.
                            </p>
                        </div>
                    )}

                    <AiIcons.AiOutlineInfoCircle
                        className='width_35px text_1 cursor_pointer'
                        onClick={handleShowGrowth}
                    />

                    {/* Growth Modal */}
                    <Dialog
                        open={openGrowth}
                        onClose={handleCloseGrowth}
                        maxWidth={false}
                        className='width_70pr_95pr min_width_320px max_width_700px ms_auto'
                    >
                        <div>
                            <div>
                                {/* headerline */}
                                <div className='flex space_between title_5 mt_20px ml_15px mr_15px'>
                                    <div className='flex'>
                                        <FormattedMessage
                                            id='general.growth_stocks'
                                            defaultMessage='Growth Stocks'
                                        />
                                    </div>
                                    <div
                                        className='cursor_pointer title_5 flex center ml_25px mt_5px'
                                        onClick={handleCloseGrowth}
                                    >
                                        <AiIcons.AiOutlineClose />
                                    </div>
                                </div>
                                <div className='divider'></div>
                                {/* description */}
                                <div className='mt_15px mb_15px ms_auto text_block width_90pr'>
                                    <FormattedMessage
                                        id='explanation.growth_stocks'
                                        defaultMessage='Growth Stocks'
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

                {/* <div className='ml_40px mt_15px'>
                    <Autocomplete
                        className='mt_15px width_90pr max_width_500px'
                        multiple
                        value={portfolio.in_creation_preferences.technologies}
                        onChange={handleInterest}
                        limitTags={2}
                        id='multiple-limit-tags'
                        options={options4.sort(
                            (a, b) =>
                                -b.relationship.localeCompare(a.relationship)
                        )}
                        groupBy={(option) => option.relationship}
                        getOptionLabel={(option) => option.technologyName}
                        // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label={
                                    <FormattedMessage
                                        id='general.technologies'
                                        defaultMessage='Technologies'
                                    />
                                }
                                placeholder='Type...'
                            />
                        )}
                    />
                </div> */}
            </div>
        </div>
    );
};

CreatorStrategy.propTypes = {
    user: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    setCreatorInterests: PropTypes.func.isRequired,
    setCreatorStrategy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, {
    setCreatorInterests,
    setCreatorStrategy,
})(CreatorStrategy);
