import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// COMPONENTS
import ChangeWatchlist from "../elements/ChangeWatchlist";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { setCurrentWatchlist } from "../../../actions/watchlistActions";

// MUI
import Dialog from "@material-ui/core/Dialog";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const WatchlistMenuContainer = ({
    containerprops,
    user,
    setCurrentWatchlist,
}) => {
    const useintl = useIntl();

    /*Change Watchlist Dialog Field*/
    const [openChange, setOpenChange] = React.useState(false);
    const handleOpenChange = () => {
        setOpenChange(true);
    };
    const handleCloseChange = () => {
        setOpenChange(false);
    };

    return (
        <div className='border_box shadow_dark width_30pr_45pr_80pr ms_auto mt_10px mb_10px min_width_320px height_300px background_color_2 border_radius_15px'>
            <div className='pos_relative background_color_3 border_radius_15px_top width_100pr height_20pr flex center padding_10px text_2'>
                <Link
                    onClick={() => {
                        setCurrentWatchlist(containerprops.id);
                    }}
                    to={`/watchlist?id=${containerprops.id}`}
                    className='flex text_color_1'
                >
                    <div className='mt_5px width_20px title_6'>
                        {icon_storage.map((icon) =>
                            icon.name === containerprops.icon ? icon.icon : null
                        )}
                    </div>

                    <div className='ml_20px text_2 text_truncated max_width_180px'>
                        {containerprops.name}
                    </div>
                </Link>
                <div className='pos_absolute pos_right'>
                    {containerprops.user_id === user.id ? (
                        <div
                            className='mr_25px pt_5px title_6 cursor_pointer click_translate'
                            onClick={() => {
                                handleOpenChange();
                            }}
                        >
                            <MdIcons.MdSettings />
                        </div>
                    ) : (
                        <div className='mr_25px pt_5px title_6'>
                            <MdIcons.MdGroup className='hover_display_master' />
                            <div className='hover_display_slave pos_absolute padding_10px background_color_4 border_radius_10px text_3'>
                                {useintl.formatMessage({
                                    id: "general.followed",
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='divider_no_margin'></div>
            <div className='flex flex_column height_80pr padding_20px space_between'>
                <div className='width_100pr text_3'>
                    {containerprops.description !== null
                        ? containerprops.description
                        : "-"}
                </div>
                <div className='width_100pr pos flex'>
                    {containerprops.ticker !== null
                        ? containerprops.ticker.length
                        : 0}{" "}
                    {containerprops.ticker !== null
                        ? containerprops.ticker.length === 1
                            ? "asset"
                            : "assets"
                        : "assets"}
                </div>
            </div>

            {/* Change Watchlist Modal */}
            <Dialog
                open={openChange}
                onClose={handleCloseChange}
                maxWidth={false}
            >
                <ChangeWatchlist
                    closeModal={handleCloseChange}
                    currentWatchlist={containerprops}
                />
            </Dialog>
        </div>
    );
};

WatchlistMenuContainer.propTypes = {
    user: PropTypes.object.isRequired,
    containerprops: PropTypes.object.isRequired,
    setCurrentWatchlist: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    watchlist: state.watchlist,
});

export default connect(mapStateToProps, {
    setCurrentWatchlist,
})(WatchlistMenuContainer);
