import React, { useContext } from "react";
import { useIntl } from "react-intl";

// COMPONENTS
import MetricAnalysisBar from "./figures/MetricAnalysisBar";
import MetricAnalysisBarUpgrade from "./figures/MetricAnalysisBarUpgrade";
import Preloader from "../../layout/Preloader";
import FeedbackIcon from "../elements/FeedbackIcon";

// CONTEXT
import { MetricsContext } from "../../../context/MetricsContext";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
// import { numberFormatter } from "../../../actions/generalActions";

// MUI
// import Dialog from '@mui/material/Dialog';

const Figures = ({ company: { company }, screener: { slider }, user }) => {
    const useintl = useIntl();

    const metrics = useContext(MetricsContext);

    let sorted_metrics_object = user.simplified_view
        ? metrics.simplified_sorted_metrics_object
        : metrics.sorted_metrics_object;

    let SliderData = {};

    let AllMetrics = user.simplified_view
        ? metrics.SimplifiedMetrics
        : metrics.AllMetrics;

    let AllowedMetrics =
        user.status === 0
            ? metrics.FreeMetrics
            : user.simplified_view
            ? metrics.SimplifiedMetrics
            : metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })} - ${useintl.formatMessage({
            id: "company.figures",
        })}`,
        location_en: "Company - Figures",
        location_index: 4,
    };

    // INFO COMPONENT
    // let informationInfo = {
    //     location: `${useintl.formatMessage({
    //     id: "general.company",
    // })} - ${useintl.formatMessage({
    //     id: "company.figures",
    // })}`,
    //     location_index: 4,
    // };

    return (
        <div>
            {company === null ? (
                <Preloader />
            ) : (
                <div className='background_1 text_color_1'>
                    <FeedbackIcon feedbackInfo={feedbackInfo} />
                    <div className='mt_30px'>
                        <div className='flex wrap mt_25px pl_20px width_100pr min_width_300px max_width_1500px'>
                            <div className='flex space_between width_100pr min_width_300px text_2'>
                                {/* NAME */}
                                <div className='width_30pr_50pr min_width_150px'>
                                    {useintl.formatMessage({
                                        id: `general.name`,
                                    })}
                                </div>
                                {/* VALUE */}
                                <div className='width_50pr_30pr pl_10px min_width_50px'>
                                    {useintl.formatMessage({
                                        id: `general.figure`,
                                    })}
                                </div>{" "}
                                {/* INFO ICONS */}
                                <div className='width_20pr min_width_50px flex'></div>
                                {/* RANKER */}
                                {/* <div className='width_10pr min_width_50px'>
                                    {useintl.formatMessage({
                                        id: `general.ranker`,
                                    })}
                                </div> */}
                            </div>
                            {/* <div className='width_100pr min_width_300px text_2'>
                                Ranker
                            </div> */}
                        </div>
                        <div className='mt_15px'>
                            {Object.keys(sorted_metrics_object).map(
                                (category) => {
                                    return !(
                                        user.simplified_view &&
                                        category === "fundamental_ratios"
                                    ) ? (
                                        <div className='width_100pr pl_20px pr_20px'>
                                            <div className='width_100pr height_15px mb_15px text_2'>
                                                {useintl.formatMessage({
                                                    id: `metrics.${category}`,
                                                })}
                                            </div>

                                            {sorted_metrics_object[
                                                category
                                            ].map((metric) => {
                                                return AllowedMetrics.includes(
                                                    metric
                                                ) ? (
                                                    <MetricAnalysisBar
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        company={company}
                                                        metric={company[metric]}
                                                        metric_ranker={
                                                            company[
                                                                `${metric}_ranker`
                                                            ]
                                                        }
                                                    />
                                                ) : (
                                                    <MetricAnalysisBarUpgrade
                                                        sliderprops={
                                                            searchSliderObject[
                                                                metric
                                                            ]
                                                        }
                                                        dummy_value={0}
                                                    />
                                                );
                                            })}
                                            <div className='mb_20px'> </div>
                                        </div>
                                    ) : null;
                                }
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Figures.propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
    user: state.user,
    screener: state.screener,
});

export default connect(mapStateToProps, null)(Figures);
