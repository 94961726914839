import React from "react";
import Sidebar from "./sidebar/Sidebar";

const Menu = () => {
    return (
        <div>
            <Sidebar />
        </div>
    );
};

export default Menu;
