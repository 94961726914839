import React, { useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

// CONTEXT
import { Context_Theme } from "../../../context/ThemeContext";

// COMPONENTS
import Overview from "./Overview";
import Transactions from "./Transactions";
import PortfolioAssetsTable from "./PortfolioAssetsTable";
import PortfolioManagerIdeas from "./PortfolioManagerIdeas";
import PortfolioAnalysisBars from "./charts/PortfolioAnalysisBars";
import PortfolioEstimatedPerformanceChart from "./charts/PortfolioEstimatedPerformanceChart";
import PortfolioTrackerPerformanceChart from "./charts/PortfolioTrackerPerformanceChart";
import PortfolioEstimatedDividendsChart from "./charts/PortfolioEstimatedDividendsChart";
import TransactionForm from "../elements/TransactionForm";
import ChangePortfolio from "../elements/ChangePortfolio";
import Benchmark from "./Benchmark";
import Preloader from "../../layout/Preloader";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";

// HIGHCHARTS
import Highcharts from "highcharts/highstock";

// ACTIONS
import { getSearchBarCompanies } from "../../../actions/screenerAction";
import {
    setCurrentPortfolio,
    getPortfolio,
    getDiversificationIdeas,
    setPortfolio,
    renamePortfolio,
    deletePortfolio,
    getPortfolioPerformance,
    loadPortfolioTrackerPerformance,
    openPortfolioChanges,
    resetPortfolioBenchmarks,
    followPortfolio,
    unfollowPortfolio,
} from "../../../actions/portfolioActions";
import { getIndexPerformance } from "../../../actions/marketActions";
import { setAlert } from "../../../actions/alertActions";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

/*Multiple Select Dropdown*/
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "8px",
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: "24px",
    },
}));

// Functionto get url parameters
const getUrlParam = (params) => {
    let params_arr = params.slice(1).split("&");
    let portfolio_id = params_arr[0].split("=")[1];
    // params_arr.forEach((element) => {
    //     let (key, value) = element.split("=");
    // });
    return portfolio_id;
};

let currentPortfolio = {};

const Portfolio = ({
    portfolio,
    user,
    getPortfolio,
    getDiversificationIdeas,
    getPortfolioPerformance,
    loadPortfolioTrackerPerformance,
    openPortfolioChanges,
    setAlert,
    market,
    getIndexPerformance,
    resetPortfolioBenchmarks,
    followPortfolio,
    unfollowPortfolio,
}) => {
    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    const context_theme = useContext(Context_Theme);
    const useintl = useIntl();
    Highcharts.setOptions(context_theme.currentThemeObject);

    useEffect(() => {
        resetPortfolioBenchmarks();
        if (underLine === 2 || underLine === 5) {
            setUnderLine(1);
        }
        let portfolio_id_url = getUrlParam(window.location.search);
        portfolio_id_url = Number(portfolio_id_url);
        setPortfolioType("manager");
        if (user.id && portfolio.portfolios) {
            getPortfolio(
                !isNaN(portfolio_id_url) ? portfolio_id_url : portfolio.current,
                user.id,
                portfolio.portfolios
            );

            if (
                portfolio.portfolios.length !== 0 &&
                portfolio.current !== null
            ) {
                currentPortfolio = portfolio.portfolios.filter((obj) => {
                    return obj.id === portfolio_id_url;
                });
                currentPortfolio = currentPortfolio[0];
                if (
                    currentPortfolio !== undefined &&
                    currentPortfolio.allocation !== null
                ) {
                    getDiversificationIdeas(
                        currentPortfolio.allocation,
                        currentPortfolio.id
                    );
                }
            } else {
                currentPortfolio = {};
                setAlert(
                    "This portfolio either doesn't exist or is not publically available",
                    "warning"
                );
            }
        }
        // eslint-disable-next-line
    }, [portfolio.current, portfolio.rerender]);

    if (portfolio.portfolios.length !== 0 && portfolio.current !== null) {
        currentPortfolio = portfolio.portfolios.filter((obj) => {
            return obj.id === Number(getUrlParam(window.location.search));
        });
        currentPortfolio = currentPortfolio[0];
    } else {
        currentPortfolio = {};
    }

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    // PORTFOLIO TYPE
    const [portfolioType, setPortfolioType] = React.useState("manager");
    const handlePortfolioType = (new_type) => {
        if (new_type === "tracker") {
            if (!market.index_performance) {
                getIndexPerformance("GSPC.INDX");
            }
            setPortfolioType("tracker");

            if (
                !currentPortfolio.performance_tracker &&
                currentPortfolio.allocation_tracker &&
                currentPortfolio.assets
            ) {
                // CALCULATE TRACKER PERFORMANCE
                loadPortfolioTrackerPerformance(
                    currentPortfolio.transactions,
                    currentPortfolio.ticker_tracker,
                    currentPortfolio.assets,
                    currentPortfolio.id,
                    portfolio.risk_free_rate
                );
            }
            if (
                currentPortfolio.allocation_tracker &&
                currentPortfolio.assets
            ) {
                getPortfolioPerformance(
                    currentPortfolio.allocation_tracker,
                    currentPortfolio.assets,
                    "tracker",
                    currentPortfolio.id
                );
                getDiversificationIdeas(
                    currentPortfolio.allocation_tracker,
                    currentPortfolio.id
                );
            }
        } else {
            if (underLine === 2 || underLine === 5) {
                setUnderLine(1);
            }
            setPortfolioType("manager");
            if (
                currentPortfolio.allocation &&
                currentPortfolio.allocation !== {} &&
                currentPortfolio.assets &&
                currentPortfolio.assets.length > 0
                // &&
                // currentPortfolio.ticker &&
                // currentPortfolio.ticker.length > 0
            ) {
                getPortfolioPerformance(
                    currentPortfolio.allocation,
                    currentPortfolio.assets,
                    "manager",
                    currentPortfolio.id
                );
                getDiversificationIdeas(
                    currentPortfolio.allocation,
                    currentPortfolio.id
                );
            }
        }
    };

    let new_allocation;
    let total_portfolio_value = 0;
    if (
        currentPortfolio &&
        currentPortfolio.length !== 0 &&
        currentPortfolio.id === Number(getUrlParam(window.location.search))
    ) {
        if (
            portfolioType === "manager" &&
            currentPortfolio &&
            currentPortfolio.assets &&
            currentPortfolio.allocation
        ) {
            new_allocation = currentPortfolio.allocation;
            if (new_allocation !== null && new_allocation !== undefined) {
                currentPortfolio.assets.map((company) => {
                    if (new_allocation[company.ticker]) {
                        new_allocation[company.ticker].value =
                            new_allocation[company.ticker].count *
                            company.stock_prices[
                                company.stock_prices.length - 1
                            ][4];
                    }
                });

                Object.keys(new_allocation).map(function (key, index) {
                    if (new_allocation[key].status === true) {
                        total_portfolio_value += new_allocation[key].value;
                    }
                });
            }
        }
        if (
            portfolioType === "tracker" &&
            currentPortfolio &&
            currentPortfolio.assets &&
            currentPortfolio.allocation_tracker
        ) {
            new_allocation = currentPortfolio.allocation_tracker;
            if (new_allocation !== null && new_allocation !== undefined) {
                currentPortfolio.assets.map((company) => {
                    if (new_allocation[company.ticker]) {
                        new_allocation[company.ticker].value =
                            new_allocation[company.ticker].count *
                            company.stock_prices[
                                company.stock_prices.length - 1
                            ][4];
                    }
                });

                Object.keys(new_allocation).map(function (key, index) {
                    if (new_allocation[key].status === true) {
                        total_portfolio_value += new_allocation[key].value;
                    }
                });
            }
        }
    }

    // CHANGE PORTFOLIO
    const [openChange, setOpenChange] = React.useState(false);
    const handleOpenChange = () => {
        setOpenChange(true);
    };
    const handleCloseChange = () => {
        setOpenChange(false);
    };

    // ADD TRANSACTION
    const handleOpenShareTransaction = () => {
        openPortfolioChanges(true, "TransactionForm");
    };
    const handleCloseShareTransaction = () => {
        openPortfolioChanges(false, "TransactionForm");
    };

    // FOLLOW PORTFOLIO
    const handleFollowPortfolio = () => {
        followPortfolio(
            { id: currentPortfolio.id, name: currentPortfolio.name },
            { user_status: user.status, id: user.id },
            portfolio
        );
    };
    const handleUnfollowPortfolio = () => {
        unfollowPortfolio(
            { id: currentPortfolio.id, name: currentPortfolio.name },
            { user_status: user.status, id: user.id }
        );
    };

    const [underLine, setUnderLine] = React.useState(1);

    // console.log("CURRENT PORTFOLIO: ", currentPortfolio);

    return (
        <div className='text_color_1 background_color_1'>
            <Helmet>
                <title>{`Portfolio Manager | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Create Portfolios to track their historic performance. Compare them with your friends performances or indices, view information like your sharpe, estimated dividend payments and much more.'
                />
            </Helmet>
            {!currentPortfolio ||
            currentPortfolio === null ||
            (currentPortfolio && currentPortfolio.length === 0) ? (
                <Preloader />
            ) : (
                <div>
                    <TopBarCallToAction />
                    {/* HEADER */}
                    <div className='flex space_between mb_20px'>
                        <div className='flex wrap mt_5px'>
                            <div className='flex'>
                                <div className='title_4 ml_25px_10px mt_15px'>
                                    {icon_storage.map((icon) =>
                                        icon.name === currentPortfolio.icon
                                            ? icon.icon
                                            : null
                                    )}
                                </div>
                                <div className='title_4 ml_15px mt_10px mr_10px'>
                                    {currentPortfolio.name}
                                </div>
                                <AiIcons.AiOutlineInfoCircle
                                    className='text_1 hover_translate_up ml_10px mt_15px'
                                    onClick={() => {
                                        handleOpenPageInfo();
                                    }}
                                />

                                <PageInfoModalSimple
                                    infoHeader={"What can i do here?"}
                                    infoBody={
                                        "Portfolios are designed to monitor the performance of your stocks, primarily on a fundamental level. The site is divided into a 'manager' and a 'tracker'. The former is there to give you a quick overview of your investments and quickly add and remove companies to see how they affect your portfolio. In the latter, you can additionally track your historical performance by entering your transactions. You can also compare your portfolio with popular indices, individual stocks and other portfolios. A detailed description on how to use this page can be found in the bottom right corner of this page."
                                    }
                                    showPageInfo={showPageInfo}
                                    handleClosePageInfo={handleClosePageInfo}
                                />
                            </div>
                            {/* MANAGER/ TRACKER */}
                            <div className='width_auto flex left ml_15px mr_20px mt_15px'>
                                <div className='width_100pr'>
                                    {/* <div className='text_2 text_color_1 mb_10px'>
                                Type
                            </div> */}
                                    <div className='height_35px accent_color_2 text_2 text_color_1 pl_10px pr_10px cursor_pointer flex border_radius_5px'>
                                        <div
                                            className={
                                                portfolioType === "tracker"
                                                    ? "pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                                    : "background_color_blue text_color_white pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            }
                                            onClick={() => {
                                                handlePortfolioType("manager");
                                            }}
                                        >
                                            Manager
                                        </div>
                                        <div
                                            className={
                                                portfolioType === "tracker"
                                                    ? "background_color_blue text_color_white pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                                    : "pt_5px pb_5px pl_10px pr_10px text_center border_radius_5px hover_opacity_80"
                                            }
                                            onClick={() => {
                                                handlePortfolioType("tracker");
                                            }}
                                        >
                                            Tracker
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* PUBLIC/PRIVATE TRANSACTION */}
                        <div className='flex right wrap mt_25px mr_25px_10px'>
                            {currentPortfolio.user_id === user.id ? (
                                <div
                                    className='button_blue padding_10px  text_color_1'
                                    onClick={handleOpenShareTransaction}
                                >
                                    <div className='pr_10px'>Transaction</div>
                                    <MdIcons.MdAddCircleOutline className='mt_2px' />
                                </div>
                            ) : null}

                            <div className='ml_20px flex'>
                                <div className='mr_15px'>
                                    {currentPortfolio.public ? (
                                        <div>
                                            {useintl.formatMessage({
                                                id: "general.public",
                                            })}
                                        </div>
                                    ) : (
                                        <div>
                                            {useintl.formatMessage({
                                                id: "general.private",
                                            })}
                                        </div>
                                    )}
                                </div>
                                {user.id ===
                                2 ? null : currentPortfolio.user_id ===
                                  user.id ? (
                                    <div
                                        className='mt_6px title_4 cursor_pointer click_translate'
                                        onClick={() => {
                                            handleOpenChange();
                                        }}
                                    >
                                        <MdIcons.MdSettings />
                                    </div>
                                ) : user.portfolios_followed.includes(
                                      currentPortfolio.id
                                  ) ? (
                                    <div
                                        className='ps_10px button_blue cursor_pointer click_translate'
                                        onClick={() => {
                                            handleUnfollowPortfolio();
                                        }}
                                    >
                                        <div className='mr_10px'>Unfollow</div>
                                        <MdIcons.MdOutlineLibraryAdd />
                                    </div>
                                ) : (
                                    <div
                                        className='ps_10px button_blue cursor_pointer click_translate'
                                        onClick={() => {
                                            handleFollowPortfolio();
                                        }}
                                    >
                                        <div className='mr_10px'>Follow</div>
                                        <MdIcons.MdOutlineLibraryAdd />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Dialog
                        open={
                            portfolio.changes &&
                            portfolio.changes_component === "TransactionForm"
                        }
                        onClose={handleCloseShareTransaction}
                        maxWidth={false}
                        className=''
                    >
                        <TransactionForm
                            portfolioType={portfolioType}
                            currentPortfolio={currentPortfolio}
                            pre_selected_ticker={null}
                            closeTransactionModal={handleCloseShareTransaction}
                        />
                    </Dialog>

                    {/* PORTFOLIO MENU */}
                    <div className='flex pt_5px overflow_x_auto scrollbar_invisible border_bottom_1px_solid_grey title_7 text_color_1'>
                        <div
                            className={
                                underLine === 1
                                    ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                                    : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                            }
                            onClick={() => {
                                setUnderLine(1);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "portfolio.manager.overview",
                            })}
                        </div>
                        {portfolioType === "tracker" ? (
                            <div
                                className={
                                    underLine === 2
                                        ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                                        : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(2);
                                }}
                            >
                                {/* {useintl.formatMessage({
                        id: "general.risk",
                    })} */}
                                Transactions
                            </div>
                        ) : null}
                        {portfolioType === "tracker" ? (
                            <div
                                className={
                                    underLine === 5
                                        ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                                        : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(5);
                                }}
                            >
                                {/* {useintl.formatMessage({
                        id: "general.risk",
                    })} */}
                                Benchmark
                            </div>
                        ) : null}
                        <div
                            className={
                                underLine === 3
                                    ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                                    : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                            }
                            onClick={() => {
                                setUnderLine(3);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "company.figures",
                            })}
                        </div>
                        <div
                            className={
                                underLine === 4
                                    ? "pb_5px page_menu_link transition_350ms cursor_default ml_30px mr_30px text_color_blue border_bottom_1px_solid_blue"
                                    : "pb_5px page_menu_link transition_350ms cursor_pointer ml_30px mr_30px text_color_1"
                            }
                            onClick={() => {
                                setUnderLine(4);
                            }}
                        >
                            {/* {useintl.formatMessage({
                        id: "portfolio.manager.performance",
                    })} */}
                            Performance
                        </div>
                    </div>

                    {/* NO COMPANIES IN THE PORTFOLIO */}
                    {(portfolioType === "tracker" &&
                        (!currentPortfolio.ticker_tracker ||
                            currentPortfolio.ticker_tracker === null ||
                            currentPortfolio.ticker_tracker.length === 0)) ||
                    (portfolioType === "manager" &&
                        (!currentPortfolio.ticker ||
                            currentPortfolio.ticker === null ||
                            currentPortfolio.ticker.length === 0)) ? (
                        <div className='width_100pr'>
                            <div className='ml_20px mt_20px'>
                                Add Companies using the Screener or by importing
                                a Portfolio from the Portfolio Creator
                            </div>
                        </div>
                    ) : (portfolioType === "tracker" &&
                          (currentPortfolio.assets === null ||
                              currentPortfolio.assets.length === 0 ||
                              !currentPortfolio.allocation_tracker)) ||
                      (portfolioType === "manager" &&
                          (currentPortfolio.assets === null ||
                              currentPortfolio.assets.length === 0 ||
                              !currentPortfolio.allocation)) ? (
                        // PROCESSING PORTFOLIO DATA
                        <Preloader />
                    ) : underLine === 1 ? (
                        <div>
                            <div className='divider_no_margin'></div>
                            {portfolioType === "manager" ? null : (
                                <PortfolioTrackerPerformanceChart
                                    benchmark_mode={false}
                                    transactions={
                                        currentPortfolio.transactions
                                            ? currentPortfolio.transactions
                                            : null
                                    }
                                    performance={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .performance
                                            : []
                                    }
                                    relative_performance={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .relative_performance
                                            : []
                                    }
                                    invested_sum={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .invested_sum
                                            : []
                                    }
                                    realized_profit_and_loss_over_time={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .realized_profit_and_loss_over_time
                                            : []
                                    }
                                    realized_profit_and_loss={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .realized_profit_and_loss
                                            : []
                                    }
                                    sharpe_ratio={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .sharpe_ratio
                                            : null
                                    }
                                    benchmarks={portfolio.benchmarks}
                                    company_list={currentPortfolio.assets}
                                    company_allocation={
                                        portfolioType === "tracker"
                                            ? currentPortfolio.allocation_tracker
                                            : currentPortfolio.allocation
                                    }
                                    portfolio_id={
                                        currentPortfolio.id
                                            ? currentPortfolio.id
                                            : null
                                    }
                                />
                            )}
                            <Overview
                                description={currentPortfolio.description}
                                portfolio_value={total_portfolio_value}
                                company_list={currentPortfolio.assets}
                                company_allocation={
                                    portfolioType === "tracker"
                                        ? currentPortfolio.allocation_tracker
                                        : currentPortfolio.allocation
                                }
                                preferences={currentPortfolio.preferences}
                                performance={currentPortfolio.performance}
                            />
                            <div className='mt_20px'></div>
                            <PortfolioManagerIdeas
                                portfolio_diversification={
                                    currentPortfolio.diversification_ideas
                                }
                                portfolio_strategy_fit={
                                    currentPortfolio.strategy_ideas
                                }
                            />

                            <PortfolioAssetsTable
                                portfolio_value={total_portfolio_value}
                                company_list={currentPortfolio.assets}
                                company_allocation={currentPortfolio.allocation}
                                company_allocation_tracker={
                                    currentPortfolio.allocation_tracker
                                        ? currentPortfolio.allocation_tracker
                                        : null
                                }
                                portfolioType={portfolioType}
                            />
                        </div>
                    ) : underLine === 5 ? (
                        <div>
                            <div className='divider_no_margin'></div>
                            {portfolioType === "manager" ? null : (
                                <div>
                                    <Benchmark
                                        portfolio_id={
                                            currentPortfolio.id
                                                ? currentPortfolio.id
                                                : null
                                        }
                                        current_portfolio={currentPortfolio}
                                    />
                                    <PortfolioTrackerPerformanceChart
                                        benchmark_mode={true}
                                        transactions={
                                            currentPortfolio.transactions
                                                ? currentPortfolio.transactions
                                                : null
                                        }
                                        performance={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .performance
                                                : []
                                        }
                                        relative_performance={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .relative_performance
                                                : []
                                        }
                                        invested_sum={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .invested_sum
                                                : []
                                        }
                                        realized_profit_and_loss_over_time={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .realized_profit_and_loss_over_time
                                                : []
                                        }
                                        realized_profit_and_loss={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .realized_profit_and_loss
                                                : []
                                        }
                                        sharpe_ratio={
                                            currentPortfolio.performance_tracker
                                                ? currentPortfolio
                                                      .performance_tracker
                                                      .sharpe_ratio
                                                : null
                                        }
                                        benchmarks={portfolio.benchmarks}
                                        company_list={currentPortfolio.assets}
                                        company_allocation={
                                            portfolioType === "tracker"
                                                ? currentPortfolio.allocation_tracker
                                                : currentPortfolio.allocation
                                        }
                                        portfolio_id={
                                            currentPortfolio.id
                                                ? currentPortfolio.id
                                                : null
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    ) : underLine === 2 ? (
                        <div className='background_color_1'>
                            {/* <div className='divider_no_margin'></div> */}
                            {portfolioType === "manager" ? null : (
                                <PortfolioTrackerPerformanceChart
                                    benchmark_mode={false}
                                    transactions={
                                        currentPortfolio.transactions
                                            ? currentPortfolio.transactions
                                            : null
                                    }
                                    performance={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .performance
                                            : []
                                    }
                                    invested_sum={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .invested_sum
                                            : []
                                    }
                                    relative_performance={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .relative_performance
                                            : []
                                    }
                                    realized_profit_and_loss_over_time={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .realized_profit_and_loss_over_time
                                            : []
                                    }
                                    realized_profit_and_loss={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .realized_profit_and_loss
                                            : []
                                    }
                                    sharpe_ratio={
                                        currentPortfolio.performance_tracker
                                            ? currentPortfolio
                                                  .performance_tracker
                                                  .sharpe_ratio
                                            : null
                                    }
                                    benchmarks={portfolio.benchmarks}
                                    company_list={currentPortfolio.assets}
                                    company_allocation={
                                        portfolioType === "tracker"
                                            ? currentPortfolio.allocation_tracker
                                            : currentPortfolio.allocation
                                    }
                                />
                            )}
                            <div className='divider'></div>
                            <Transactions
                                transactions={
                                    currentPortfolio.transactions
                                        ? currentPortfolio.transactions
                                        : null
                                }
                                risk_free_rate={
                                    portfolio.risk_free_rate
                                        ? portfolio.risk_free_rate
                                        : null
                                }
                                user_id={user.id}
                                portfolio_id={
                                    currentPortfolio.id
                                        ? currentPortfolio.id
                                        : null
                                }
                                portfolio_user_id={
                                    currentPortfolio.user_id
                                        ? currentPortfolio.user_id
                                        : null
                                }
                            />
                        </div>
                    ) : underLine === 3 ? (
                        <div className='background_color_1'>
                            <div className='divider_no_margin'></div>
                            <PortfolioAnalysisBars
                                company_list={currentPortfolio.assets}
                                company_allocation={
                                    portfolioType === "tracker"
                                        ? currentPortfolio.allocation_tracker
                                        : currentPortfolio.allocation
                                }
                            />
                        </div>
                    ) : underLine === 4 ? (
                        <div className=''>
                            <div className='divider_no_margin'></div>
                            <PortfolioEstimatedPerformanceChart
                                portfolio_performance={
                                    currentPortfolio.performance
                                }
                                portfolio_allocation={new_allocation}
                                assets_array={currentPortfolio.assets}
                                requiredStatus={1}
                            />
                            <div className='divider_no_margin'></div>
                            {/* THIS "-" IS NEEDED BECAUSE I SUCK */}
                            <div className='text_color_transparent'>-</div>
                            <PortfolioEstimatedDividendsChart
                                company_list={currentPortfolio.assets}
                                company_allocation={
                                    portfolioType === "tracker"
                                        ? currentPortfolio.allocation_tracker
                                        : currentPortfolio.allocation
                                }
                            />
                            <div className='divider_no_margin'></div>
                            {/* Dividend Calculator
                    <div className='divider_no_margin'></div> */}
                        </div>
                    ) : null}

                    {/* CHANGE PORTFOLIO MODAL */}
                    <Dialog
                        open={openChange}
                        onClose={handleCloseChange}
                        maxWidth={false}
                    >
                        <ChangePortfolio
                            closeModal={handleCloseChange}
                            currentPortfolio={currentPortfolio}
                        />
                    </Dialog>
                </div>
            )}
        </div>
    );
};

Portfolio.propTypes = {
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setPortfolio: PropTypes.func.isRequired,
    renamePortfolio: PropTypes.func.isRequired,
    deletePortfolio: PropTypes.func.isRequired,
    setCurrentPortfolio: PropTypes.func.isRequired,
    getPortfolio: PropTypes.func.isRequired,
    getDiversificationIdeas: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    getPortfolioPerformance: PropTypes.func.isRequired,
    loadPortfolioTrackerPerformance: PropTypes.func.isRequired,
    openPortfolioChanges: PropTypes.func.isRequired,
    getIndexPerformance: PropTypes.func.isRequired,
    resetPortfolioBenchmarks: PropTypes.func.isRequired,
    market: PropTypes.object.isRequired,
    followPortfolio: PropTypes.func.isRequired,
    unfollowPortfolio: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    portfolio: state.portfolio,
    user: state.user,
    market: state.market,
});

export default connect(mapStateToProps, {
    setPortfolio,
    getPortfolio,
    getDiversificationIdeas,
    setCurrentPortfolio,
    renamePortfolio,
    deletePortfolio,
    getPortfolioPerformance,
    loadPortfolioTrackerPerformance,
    getSearchBarCompanies,
    openPortfolioChanges,
    getIndexPerformance,
    resetPortfolioBenchmarks,
    setAlert,
    followPortfolio,
    unfollowPortfolio,
})(Portfolio);
