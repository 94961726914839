import {
    GET_BOOKMARKS,
    SET_BOOKMARK,
    CLEAR_BOOKMARK,
    UPDATE_BOOKMARK,
} from "../actions/Types";

const initialState = {
    bookmarks: [],
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.payload,
            };

        case SET_BOOKMARK:
            localStorage.setItem(
                "bookmarks",
                JSON.stringify([...state.bookmarks, action.payload])
            );
            return {
                ...state,
                bookmarks: [...state.bookmarks, action.payload],
            };

        case UPDATE_BOOKMARK:
            let add_bookmark = state.bookmarks.map((bookmark) =>
                bookmark.id === action.payload.id ? action.payload : bookmark
            );
            localStorage.setItem("bookmarks", JSON.stringify(add_bookmark));
            return {
                ...state,
                bookmarks: add_bookmark,
            };

        case CLEAR_BOOKMARK:
            let clear_bookmark = state.bookmarks.filter(
                (bookmark) => bookmark.id !== action.payload
            );
            localStorage.setItem("bookmarks", JSON.stringify(clear_bookmark));
            return {
                ...state,
                bookmarks: clear_bookmark,
            };

        default:
            return state;
    }
};
