import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
// import { FormattedMessage } from "react-intl";

// ICONS
import * as IoIcons5 from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

// COMPONENTS
import PortfolioCreatorSurvey from "./PortfolioCreatorSurvey";
import PortfolioCreatorPortfolio from "./PortfolioCreatorPortfolio";
import Ad0Vertical100vw from "../ads/Ad0Vertical100vw";
import PageInfoModalSimple from "../elements/PageInfoModalSimple";

// MUI
import Dialog from "@mui/material/Dialog";

// ACTIONS
import { setCreatorWindow } from "../../../actions/portfolioActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ANALYTICS
import ReactGA from "react-ga4";
import TopBarCallToAction from "../../layout/TopBarCallToAction";
const { REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID, REACT_APP_ENVIRONMENT } =
    process.env;

const PortfolioCreator = ({
    sidebar: { sidebar },
    user,
    portfolio,
    setCreatorWindow,
}) => {
    // ANALYTICS
    useEffect(() => {
        if (REACT_APP_ENVIRONMENT === "PRODUCTION") {
            ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_4_MEASUREMENT_ID);
            ReactGA.set({
                page: window.location.pathname + window.location.search,
            });
            ReactGA.send("pageview");

            // ReactGA.event({
            //     category: "Button",
            //     action: "Clicked the button"
            // })
        }
        // eslint-disable-next-line
    }, []);

    // PAGE INFORMATION STATE
    const [showPageInfo, setShowPageInfo] = React.useState(false);
    const handleOpenPageInfo = () => {
        setShowPageInfo(true);
    };
    const handleClosePageInfo = () => {
        setShowPageInfo(false);
    };

    let portfolios = [];
    let asdf = 0;
    const useintl = useIntl();
    const fill_portfolios = () => {
        portfolio.portfolios.map((portfolio) => {
            if (portfolio.user_id === user.id) {
                portfolios.push({
                    value: portfolio.id,
                    name: portfolio.name,
                    icon: portfolio.icon,
                });
            }
        });
        asdf += 1;
    };
    if (asdf < 1) {
        fill_portfolios();
    }
    const [underLine, setUnderLine] = React.useState(
        portfolio.current_creator_companies === null ? 1 : 2
    );

    const [openTab, setOpenTab] = React.useState(
        portfolio.current_creator_companies === null ? (
            <PortfolioCreatorSurvey />
        ) : (
            <PortfolioCreatorPortfolio />
        )
    );

    if (portfolio.creator_window === 2 && underLine !== 2) {
        setUnderLine(2);
        setOpenTab(<PortfolioCreatorPortfolio />);
    }

    return (
        <div className={sidebar ? "sidemenu_open" : "sidemenu_closed"}>
            <Helmet>
                <title>{`Portfolio Creator | StocksOnView`}</title>
                <meta
                    name='description'
                    content='Fill out a short questionnaire to evaluate your strategy and risk tolerance to obtain a whole portfolio of investment ideas.'
                />
            </Helmet>
            <TopBarCallToAction />
            <div className='flex background_color_1 text_color_1'>
                <div className='flex mt_20px mb_20px mr_40px'>
                    <div className='title_4 ml_25px_10px mt_5px'>
                        <MdIcons.MdCreate />
                    </div>
                    <div className='title_4 ml_15px'>Portfolio Creator</div>
                    <AiIcons.AiOutlineInfoCircle
                        className='text_1 hover_translate_up ml_15px mt_5px'
                        onClick={() => {
                            handleOpenPageInfo();
                        }}
                    />
                </div>

                <PageInfoModalSimple
                    infoHeader={"What can i do here?"}
                    infoBody={
                        underLine === 1
                            ? "Complete the questionnaire to obtain a portfolio of shares that matches the resulting strategy. This is not investment advice, but merely a way to search our database on a more abstract level. A detailed description can be found in the bottom right corner of this page."
                            : "Complete the questionnaire to obtain a portfolio of shares that matches the resulting strategy. This is not investment advice, but merely a way to search our database on a more abstract level. A detailed description can be found in the bottom right corner of this page."
                    }
                    showPageInfo={showPageInfo}
                    handleClosePageInfo={handleClosePageInfo}
                />

                <div className='flex mt_25px mb_20px space_between wrap title_6'>
                    <div className='flex mr_20px'>
                        <IoIcons5.IoChevronForwardSharp className='text_1 width_20px height_20px' />
                        <div
                            className={
                                underLine === 1
                                    ? "page_menu_link transition_350ms cursor_pointer ml_20px mr_30px text_color_blue"
                                    : "page_menu_link transition_350ms cursor_pointer ml_20px mr_30px text_color_1"
                            }
                            onClick={() => {
                                setUnderLine(1);
                                setCreatorWindow(1);
                                setOpenTab(<PortfolioCreatorSurvey />);
                            }}
                        >
                            {useintl.formatMessage({
                                id: "general.questionnaire",
                            })}
                        </div>
                    </div>
                    {portfolio.current_creator_companies !== null ? (
                        <div className='flex'>
                            <IoIcons5.IoChevronForwardSharp className='text_1 width_20px height_20px' />
                            <div
                                className={
                                    underLine === 2
                                        ? "page_menu_link transition_350ms cursor_pointer ml_20px text_color_blue"
                                        : "page_menu_link transition_350ms cursor_pointer ml_20px text_color_1"
                                }
                                onClick={() => {
                                    setUnderLine(2);
                                    setCreatorWindow(2);
                                    setOpenTab(<PortfolioCreatorPortfolio />);
                                }}
                            >
                                {useintl.formatMessage({
                                    id: "portfolio.creator.created_portfolio.header",
                                })}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <Ad0Vertical100vw />
            {openTab}
        </div>
    );
};

PortfolioCreator.propTypes = {
    sidebar: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setCreatorWindow: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sidebar: state.sidebar,
    portfolio: state.portfolio,
    user: state.user,
});

export default connect(mapStateToProps, {
    setCreatorWindow,
})(PortfolioCreator);
