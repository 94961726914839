import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";

// COMPONENTS
import SliderInfoBox from "./sliderinfo/SliderInfoBox";
import MetricHistogramSimple from "../elements/MetricHistogramSimple";

// MUI
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    updateSingleSliderValue,
    clearSingleSliderValue,
    updateScreenerValues,
} from "../../../actions/screenerAction";

/* Sliders Tooltip Styling */
function ValueLabelComponent(props) {
    const { children, open, value } = props;

    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={open}
            enterTouchDelay={0}
            placement='top'
            title={value}
        >
            {children}
        </Tooltip>
    );
}
ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};
let tableStructureObject = {
    value: [
        "ticker",
        "price_earnings",
        "price_earnings_growth",
        "price_sales",
        "ev_ebitda",
        "price_book",
        "price_cash_flow",
        "revenue_growth_5y",
        "earnings_growth_5y",
        "debt_ratio",
        "ev",
    ],
    growth: [
        "ticker",
        "revenue_growth_1y",
        "revenue_growth_3y",
        "revenue_growth_5y",
        "revenue_growth_5y_ranker",
        "earnings_growth_1y",
        "earnings_growth_3y",
        "earnings_growth_5y",
        "earnings_growth_5y_ranker",
        "gross_profit_margin",
        "gross_profit_margin_ranker",
        "price_sales",
    ],
    dividends: [
        "ticker",
        "dividend_yield",
        "dividend_consistency",
        "dividend_growth_3y",
        "earnings_growth_3y",
        "dividend_growth_5y",
        "earnings_growth_5y",
        "payout_ratio",
    ],
};

let noStructures = ["", null, "filtered_metrics", "individual"];

const TableStructureComponentUpgrade = ({
    sliderprops,
    handletableStructure,
    tableStructureValue,
    screener: { slider, options },
    updateSingleSliderValue,
    updateScreenerValues,
}) => {
    const [check, setCheck] = React.useState(
        slider[sliderprops.property].display
    );

    useEffect(() => {
        if (!noStructures.includes(tableStructureValue)) {
            let new_sliderprops = slider[sliderprops.property];

            if (
                tableStructureObject[tableStructureValue].includes(
                    sliderprops.property
                )
            ) {
                if (new_sliderprops.display === false) {
                    setCheck(true);
                    new_sliderprops.display = true;
                    updateSingleSliderValue(new_sliderprops, "display");
                }
            } else {
                if (new_sliderprops.display === true) {
                    setCheck(false);
                    new_sliderprops.display = false;
                    updateSingleSliderValue(new_sliderprops, "display");
                }
            }
        }
        // eslint-disable-next-line
    }, [tableStructureValue]);

    const handleCheck = (event, newValue) => {
        let new_sliderprops = slider[sliderprops.property];

        // UPDATE TABLE STRUCTURE
        if (
            options.tableStructure.value !== "filtered_metrics" &&
            options.tableStructure.value !== "individual"
        ) {
            handletableStructure("filtered_metrics");
            let new_options = options;
            new_options.tableStructure.value = "filtered_metrics";
            updateScreenerValues(slider, new_options);
        }

        // UPDATE COMPONENT CHECKBOX
        if (check) {
            setCheck(false);
            new_sliderprops.display = false;
            updateSingleSliderValue(new_sliderprops, "display");
        } else {
            setCheck(true);
            new_sliderprops.display = true;
            updateSingleSliderValue(new_sliderprops, "display");
        }
    };
    const [value, setValue] = React.useState(
        slider[sliderprops.property].value
    );

    /*Sliders Information Field*/
    const [openInfo, setOpenInfo] = React.useState(false);
    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };
    const handleCloseInfo = () => {
        setOpenInfo(false);
    };
    /*Sliders Histogram Field*/
    const [openHistogram, setOpenHistogram] = React.useState(false);
    const handleClickOpenHistogram = () => {
        setOpenHistogram(true);
    };
    const handleCloseHistogram = () => {
        setValue(slider[sliderprops.property].value);
        setOpenHistogram(false);
    };

    // property: 'revenue_growth_5y',
    // value: [20, 30],
    // default: [20, 30],
    // statistics: [15, 35, 55],
    // filter: false,
    // display: true,
    // displayName: 'Revenue Growth (5years) [%]',
    // axis: 'log',

    return (
        <div
            className={
                check
                    ? "screener_table_structure_grid accent_color_3 text_color_2 border_1px_solid opacity_80"
                    : "screener_table_structure_grid background_color_3 text_color_2 border_1px_solid opacity_80"
            }
            key={sliderprops.id}
        >
            <div className='screener_table_structure_checkbox'>
                <form>
                    <p>
                        <label>
                            <Checkbox
                                id={sliderprops.property}
                                name={sliderprops.property}
                                checked={check}
                                color='primary'
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                            <span></span>
                        </label>
                    </p>
                </form>
            </div>
            <div
                id='range-slider'
                className='screener_table_structure_label text_2'
            >
                <div className='flex'>
                    <p className='text_3'>{sliderprops.displayName}</p>
                    <div className=''>
                        <Link className='ml_15px' to={`/account`}>
                            Upgrade
                        </Link>
                    </div>
                </div>
            </div>
            <div className='screener_table_structure_icon_large text_2'>
                <AiIcons.AiOutlineFullscreen className='screener_table_structure_icon_widen' />
                <AiIcons.AiOutlineInfoCircle className='screener_icon_table_structure_info' />
            </div>
        </div>
    );
};

TableStructureComponentUpgrade.propTypes = {
    slider: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    tableStructureValue: PropTypes.string.isRequired,
    clearSingleSliderValue: PropTypes.func.isRequired,
    updateSingleSliderValue: PropTypes.func.isRequired,
    updateScreenerValues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
});

export default connect(mapStateToProps, {
    updateSingleSliderValue,
    clearSingleSliderValue,
    updateScreenerValues,
})(TableStructureComponentUpgrade);
