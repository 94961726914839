import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// ICONS
import * as IoIcons5 from "react-icons/io5";

// MUI
import Slider from "@mui/material/Slider";

// COMPONENTS
import FeedbackIcon from "../../elements/FeedbackIcon";

// ACTIONS
import { setCreatorExperience } from "../../../../actions/portfolioActions";
import { setNPositions } from "../../../../actions/portfolioActions";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

const CreatorExperience = ({
    user,
    portfolio,
    setCreatorExperience,
    setNPositions,
}) => {
    const useintl = useIntl();

    let positions = portfolio.in_creation_preferences.positions;

    useEffect(() => {
        window.scrollTo(0, 0);
        positions = 12 + (1 - portfolio.in_creation_preferences.risk / 5) * 6;
    }, []);

    const [n_positions, setN_Positions] = React.useState(positions);

    const handleNPositions = (event, newValue) => {
        setN_Positions(newValue);
        setNPositions(newValue);
    };

    // RISK SLIDER
    const risk_marks = [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
    ];

    const [risk_value, setRisk_Value] = React.useState(
        portfolio.in_creation_preferences.risk
    );

    const handleRiskChange = (event, newValue) => {
        setRisk_Value(newValue);
        setCreatorExperience(
            newValue,
            null,
            null,
            portfolio.in_creation_preferences
        );
        if (user.status !== 0) {
            let new_NPositions = 12 + (1 - (newValue - 1) / 4) * 6;
            // console.log(newValue, new_NPositions);
            if (new_NPositions <= 15) {
                let floor = Math.floor(new_NPositions);
                setN_Positions(floor);
                setNPositions(floor);
                // handleNPositions(floor);
            } else {
                let ceil = Math.ceil(new_NPositions);
                setN_Positions(ceil);
                setNPositions(ceil);
                // handleNPositions(ceil);
            }
        }
    };

    // N POSITIONS SLIDER

    const n_positions_marks = [
        {
            value: 8,
            label: "8",
        },
        {
            value: 10,
            label: "10",
        },
        {
            value: 12,
            label: "12",
        },
        {
            value: 14,
            label: "14",
        },
        {
            value: 16,
            label: "16",
        },
        {
            value: 18,
            label: "18",
        },
        {
            value: 20,
            label: "20",
        },
        {
            value: 22,
            label: "22",
        },
    ];
    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.risk",
        })}`,
        location_index: 1,
    };

    // INFO COMPONENT
    let informationInfo = {
        location: `${useintl.formatMessage({
            id: "sidemenu.portfolio_creator",
        })} - ${useintl.formatMessage({
            id: "general.assessment",
        })}`,
        location_index: 1,
        info: [
            { type: "header_2", content: "header_text_1" },
            { type: "video", content: "video_1" },
            { type: "text_2", content: "description_text_1" },
            { type: "header_2", content: "header_text_2" },
            { type: "text_2", content: "description_text_2" },
        ],
        header_text_1: useintl.formatMessage({
            id: "portfolio.creator.header_text_1",
        }),
        video_1: (
            <iframe
                width='446'
                height='249'
                src='https://www.youtube.com/embed/Z2Cy-SHKviU'
                title='The top FEATURES our STOCK MARKET SCREENER offers'
                frameborder='0'
                allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen='true'
            ></iframe>
        ),
        description_text_1: useintl.formatMessage({
            id: "portfolio.creator.description_text_1",
        }),
        header_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.header_text_1",
        }),
        description_text_2: useintl.formatMessage({
            id: "portfolio.creator.assessment.description_text_1",
        }),
    };

    return (
        <div className='height_90pr'>
            <FeedbackIcon
                feedbackInfo={feedbackInfo}
                informationInfo={informationInfo}
            />
            <div className='height_70px text_1 pl_25px pt_20px pb_15px'>
                <FormattedMessage id='general.risk' defaultMessage='Risk' />
            </div>
            <div className='divider_no_margin'></div>
            <div className='mv_auto background_color_3 pb_50px'>
                {/* RISK SLIDER */}
                <div className='pt_50px ml_15px mr_15px text_center text_2'>
                    <p>
                        <FormattedMessage
                            id='portfolio.creator.risk.question_1'
                            defaultMessage='Edit the <b>risk</b> you are willing to take with this portfolio.'
                            values={{ b: (words) => <b>{words}</b> }}
                        />
                    </p>
                </div>
                <div className='mt_50px ms_auto flex width_70pr_95pr'>
                    <div className='mr_25px mb_25px'>
                        <FormattedMessage
                            id='general.low_risk'
                            defaultMessage='Low'
                        />
                    </div>
                    <Slider
                        defaultValue={
                            // portfolio.in_creation_preferences.risk
                            1
                        }
                        min={1}
                        max={5}
                        value={risk_value}
                        onChange={handleRiskChange}
                        aria-labelledby='discrete-slider-custom'
                        step={1}
                        size='small'
                        marks={risk_marks}
                    />
                    <div className='ml_25px mb_25px'>
                        <FormattedMessage
                            id='general.high_risk'
                            defaultMessage='High'
                        />
                    </div>
                </div>

                {/* N POSITIONS SLIDER */}
                {user.status === 0 ? (
                    <div className='pt_50px ml_15px mr_15px text_center text_2'>
                        <p>
                            <Link to={`/account`} className='hover_opacity_80'>
                                Upgrade
                            </Link>{" "}
                            to edit the amount of companies you want the
                            portfolio to contain.
                        </p>
                    </div>
                ) : (
                    <div className='pt_50px ml_15px mr_15px text_center text_2'>
                        <p>
                            How many companies do you want the portfolio to
                            contain?
                        </p>
                    </div>
                )}

                <div className='mt_50px ms_auto flex width_70pr_95pr'>
                    <div className='mr_25px mb_25px'>
                        {/* <FormattedMessage
                            id='general.low_risk'
                            defaultMessage='Low'
                        /> */}
                    </div>
                    <Slider
                        defaultValue={
                            // portfolio.in_creation_preferences.risk
                            15
                        }
                        min={8}
                        max={22}
                        value={n_positions}
                        onChange={user.status !== 0 ? handleNPositions : null}
                        aria-labelledby='discrete-slider-custom'
                        step={1}
                        size='small'
                        marks={n_positions_marks}
                    />
                    <div className='ml_25px mb_25px'>
                        {/* <FormattedMessage
                            id='general.high_risk'
                            defaultMessage='High'
                        /> */}
                    </div>
                </div>

                {/* RISK DEFINITION */}
                <div className='mt_50px text_center text_2 ms_auto max_width_420px'>
                    {risk_value === 1 ||
                    risk_value === 2 ||
                    risk_value === 3 ||
                    risk_value === 4 ||
                    risk_value === 5 ? (
                        <div className='min_width_320px text_left ms_auto background_color_4 border_radius_15px shadow_dark ps_20px pv_10px'>
                            <FormattedMessage
                                id='portfolio.creator.risk.explanation_header'
                                defaultMessage='A risky strategy in a nutshell:'
                            />
                            <div className='ml_20px mt_5px ms_auto'>
                                <div className='flex'>
                                    <IoIcons5.IoPieChartOutline className='mr_15px' />
                                    <div>
                                        <FormattedMessage
                                            id='portfolio.creator.risk.explanation_list_1'
                                            defaultMessage='less companies'
                                        />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <IoIcons5.IoPulseOutline className='mr_15px' />
                                    <div>
                                        <FormattedMessage
                                            id='portfolio.creator.risk.explanation_list_2'
                                            defaultMessage='higher volatility'
                                        />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <IoIcons5.IoRocketOutline className='mr_15px' />
                                    <div>
                                        <FormattedMessage
                                            id='portfolio.creator.risk.explanation_list_3'
                                            defaultMessage='potential higher returns ...'
                                        />
                                    </div>
                                </div>
                                <div className='flex'>
                                    <IoIcons5.IoTrendingDownOutline className='mr_15px' />
                                    <div>
                                        <FormattedMessage
                                            id='portfolio.creator.risk.explanation_list_4'
                                            defaultMessage='... at a higher risk of default'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        </div>
    );
};

CreatorExperience.propTypes = {
    user: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    setCreatorExperience: PropTypes.func.isRequired,
    setNPositions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
    portfolio: state.portfolio,
});

export default connect(mapStateToProps, {
    setCreatorExperience,
    setNPositions,
})(CreatorExperience);
