import {
    GET_COMPANIES,
    GET_COMPANIES_PAGINATION,
    UPDATE_SCREENER_VALUE,
    UPDATE_SINGLE_SLIDER_VALUE,
    UPDATE_SINGLE_SLIDER_VALUE_ONLY,
    UPDATE_SINGLE_OPTIONS_VALUE,
    UPDATE_SINGLE_OPTION_SORT_BY,
    UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY,
    UPDATE_SEARCH_TEXT_SCREENER,
    UPDATE_SEARCH_OPTION_SCREENER,
    UPDATE_SEARCH_COUNTRIES,
    UPDATE_SEARCH_INDUSTRIES,
    UPDATE_CHECKED_INDUSTRIES,
    CLEAR_SCREENER_VALUES,
    SET_DISPLAY_FALSE_ALL,
    SET_THRESHHOLD,
    UPDATE_ESG_SCREENER,
    CLEAR_SINGLE_SLIDER_VALUE,
    GET_HISTOGRAM_DATA,
    GET_COMPANIES_STATISTICS,
    GET_SEARCHBAR_COMPANIES,
    SCREENER_ERROR,
    LOAD_SCREENER_PRESETS,
    ADD_SCREENER_PRESET,
    UPDATE_SCREENER_PRESET,
    DELETE_SCREENER_PRESET,
    SET_ALERT,
    REMOVE_ALERT,
    LOGOUT_SUCCESS,
    CLEAR_PORTFOLIOS,
    CLEAR_WATCHLISTS,
    CLEAR_SCREENER,
    LOGOUT_FAIL,
} from "./Types";

let possible_options = [
    "page",
    "limit",
    "search",
    "searchOption",
    "sortBy",
    "industry",
    "stock_exchange",
    "ticker",
    "description",
    "name",
    "esg",
    "currency",
    "country",
];
let possible_ranges = [
    "beta",
    "avg_rating_analyst",
    "implied_volatility",
    // "target_price_analyst",
    "short_interest_ratio",
    "short_ratio_float",
    "short_ratio_outstanding",
    // "shares_short",
    // "shares_short_prior_month",
    // "shares_held_by_insiders",
    // "shares_held_by_institutions",
    // "outstanding_shares",
    // // "outstanding_shares_change",
    // "shares_float",
    "ev",
    "market_cap_usd",
    "stock_price",
    "daily_return",
    "weekly_return",
    "monthly_return",
    "quarterly_return",
    "semi_annual_return",
    "annual_return",
    "two_year_return",
    "three_year_return",
    // "total_revenue",
    // "free_cashflow",
    // "net_income",
    // "ebit",
    // "gross_profit",
    // "dividends_paid",
    // "total_liabilities",
    // "total_current_liabilities",
    // "total_assets",
    // "total_current_assets",
    // "research_development",
    // "market_cap",
    // "market_cap_change",
    "market_cap_ranker",
    // "market_cap_ranker_change",
    "price_earnings",
    // "price_earnings_change",
    "price_earnings_ranker",
    // "price_earnings_ranker_change",
    "price_earnings_growth",
    // "price_earnings_growth_change",
    "price_earnings_growth_ranker",
    // "price_earnings_growth_ranker_change",
    "price_sales",
    // "price_sales_change",
    "price_sales_ranker",
    // "price_sales_ranker_change",
    "price_sales_growth",
    // "price_sales_growth_change",
    "price_sales_growth_ranker",
    // "price_sales_growth_ranker_change",
    "price_book",
    // "price_book_change",
    "price_book_ranker",
    // "price_book_ranker_change",
    "dividend_yield",
    // "dividend_yield_change",
    "dividend_yield_ranker",
    // "dividend_yield_ranker_change",
    "payout_ratio",
    // "payout_ratio_change",
    "payout_ratio_ranker",
    // "payout_ratio_ranker_change",
    "revenue_growth_1y",
    // "revenue_growth_1y_change",
    "revenue_growth_1y_ranker",
    // "revenue_growth_1y_ranker_change",
    "earnings_growth_1y",
    // "earnings_growth_1y_change",
    "earnings_growth_1y_ranker",
    // "earnings_growth_1y_ranker_change",
    "dividend_growth_1y",
    // "dividend_growth_1y_change",
    "dividend_growth_1y_ranker",
    // "dividend_growth_1y_ranker_change",
    "revenue_growth_3y",
    // "revenue_growth_3y_change",
    "revenue_growth_3y_ranker",
    // "revenue_growth_3y_ranker_change",
    "earnings_growth_3y",
    // "earnings_growth_3y_change",
    "earnings_growth_3y_ranker",
    // "earnings_growth_3y_ranker_change",
    "dividend_growth_3y",
    // "dividend_growth_3y_change",
    "dividend_growth_3y_ranker",
    // "dividend_growth_3y_ranker_change",
    "revenue_growth_5y",
    // "revenue_growth_5y_change",
    "revenue_growth_5y_ranker",
    // "revenue_growth_5y_ranker_change",
    "earnings_growth_5y",
    // "earnings_growth_5y_change",
    "earnings_growth_5y_ranker",
    // "earnings_growth_5y_ranker_change",
    "dividend_growth_5y",
    // "dividend_growth_5y_change",
    "dividend_growth_5y_ranker",
    // "dividend_growth_5y_ranker_change",
    "revenue_growth_9y",
    // "revenue_growth_9y_change",
    "revenue_growth_9y_ranker",
    // "revenue_growth_9y_ranker_change",
    "earnings_growth_9y",
    // "earnings_growth_9y_change",
    "earnings_growth_9y_ranker",
    // "earnings_growth_9y_ranker_change",
    "dividend_growth_9y",
    // "dividend_growth_9y_change",
    "dividend_growth_9y_ranker",
    // "dividend_growth_9y_ranker_change",
    "price_ebit",
    "price_ebit_average_10y",
    "price_ebit_deviation_10y",
    "ev_ebit",
    "ev_ebit_average_10y",
    "ev_ebit_deviation_10y",
    "ev_ebitda",
    "ev_ebitda_average_10y",
    "ev_ebitda_deviation_10y",
    "dividend_consistency",
    "price_ebit_ranker",
    "ev_ebit_ranker",
    "ev_ebitda_ranker",
    "gross_profit_margin",
    // "gross_profit_margin_change",
    "gross_profit_margin_ranker",
    // "gross_profit_margin_ranker_change",
    "net_profit_margin",
    // "net_profit_margin_change",
    "net_profit_margin_ranker",
    // "net_profit_margin_ranker_change",
    "operating_cash_flow_margin",
    // "operating_cash_flow_margin_change",
    "operating_cash_flow_margin_ranker",
    // "operating_cash_flow_margin_ranker_change",
    "operating_margin",
    // "operating_margin_change",
    "operating_margin_ranker",
    // "operating_margin_ranker_change",
    "return_on_assets",
    // "return_on_assets_change",
    "return_on_assets_ranker",
    // "return_on_assets_ranker_change",
    "asset_turnover",
    // "asset_turnover_change",
    "asset_turnover_ranker",
    // "asset_turnover_ranker_change",
    "cash_flow_on_assets",
    // "cash_flow_on_assets_change",
    "cash_flow_on_assets_ranker",
    // "cash_flow_on_assets_ranker_change",
    "return_on_equity",
    // "return_on_equity_change",
    "return_on_equity_ranker",
    // "return_on_equity_ranker_change",
    "revenue_on_equity",
    // "revenue_on_equity_change",
    "revenue_on_equity_ranker",
    // "revenue_on_equity_ranker_change",
    "return_on_capital_employed",
    // "return_on_capital_employed_change",
    "return_on_capital_employed_ranker",
    // "return_on_capital_employed_ranker_change",
    "return_on_capital",
    "return_on_capital_ranker",
    "return_on_net_tangible_assets",
    "return_on_net_tangible_assets_ranker",
    "debt_ratio",
    // "debt_ratio_change",
    "debt_ratio_ranker",
    // "debt_ratio_ranker_change",
    "debt_to_ebit",
    // "debt_to_ebit_change",
    "debt_to_ebit_ranker",
    // "debt_to_ebit_ranker_change",
    "debt_to_equity",
    // "debt_to_equity_change",
    "debt_to_equity_ranker",
    // "debt_to_equity_ranker_change",
    "debt_to_revenue",
    // "debt_to_revenue_change",
    "debt_to_revenue_ranker",
    // "debt_to_revenue_ranker_change",
    "current_ratio",
    // "current_ratio_change",
    "current_ratio_ranker",
    // "current_ratio_ranker_change",
    "price_cash_flow",
    // "price_cash_flow_change",
    "price_cash_flow_ranker",
    // "price_cash_flow_ranker_change",
    "price_earnings_average_10y",
    "price_earnings_deviation_10y",
    "price_book_average_10y",
    "price_book_deviation_10y",
    "price_sales_average_10y",
    "price_sales_deviation_10y",
    "price_cash_flow_average_10y",
    "price_cash_flow_deviation_10y",
    "volume_deviation",
    "relative_score",
    "piotroski_score",
];

// Updates slider Reducer using the local Forms
export const updateScreenerValues = (slider, options) => {
    return {
        type: UPDATE_SCREENER_VALUE,
        payload: { slider: slider, options: options },
    };
};

// Updates slider Reducer on Click
export const updateSingleSliderValue = (data, type) => {
    let latest_table = [];
    if (localStorage.latest_table) {
        latest_table = JSON.parse(localStorage.getItem("latest_table"));
        if (data.display) {
            if (!latest_table.includes(data.property)) {
                latest_table.push(data.property);
            }
            localStorage.setItem("latest_table", JSON.stringify(latest_table));
        } else {
            if (latest_table.includes(data.property)) {
                latest_table = latest_table.filter(
                    (prop) => prop !== data.property
                );
            }
            localStorage.setItem("latest_table", JSON.stringify(latest_table));
        }
    } else {
        latest_table.push(data.property);
        localStorage.setItem("latest_table", JSON.stringify(latest_table));
    }

    return {
        type: UPDATE_SINGLE_SLIDER_VALUE,
        payload: { data: data, type: type },
    };
};

// Updates only the value of one slider on Click
export const updateSingleSliderValueOnly = (data) => {
    return {
        type: UPDATE_SINGLE_SLIDER_VALUE_ONLY,
        payload: data,
    };
};

// Updates Options Value
export const updateSingleOptionsValue = (data) => {
    return {
        type: UPDATE_SINGLE_OPTIONS_VALUE,
        payload: data,
    };
};

// Updates Sort By Value and sort direction
export const updateSingleOptionSortBy = (data) => {
    return {
        type: UPDATE_SINGLE_OPTION_SORT_BY,
        payload: data,
    };
};

// Updates Sort By Value and sort direction
export const updateSingleOptionSortWatchlistBy = (data) => {
    return {
        type: UPDATE_SINGLE_OPTION_SORT_WATCHLIST_BY,
        payload: data,
    };
};

// Updates search Text of the Screener
export const updateSearchTextScreener = (data) => {
    let searchData;
    if (data !== "") {
        searchData = {
            value: data,
            filter: true,
        };
    } else {
        searchData = {
            value: "",
            filter: false,
        };
    }
    return {
        type: UPDATE_SEARCH_TEXT_SCREENER,
        payload: searchData,
    };
};

// Update option that will be searched by
export const updateSearchOption = (data) => {
    let searchData;
    if (data !== "") {
        searchData = {
            value: data,
            filter: true,
        };
    } else {
        searchData = {
            value: "",
            filter: false,
        };
    }
    return {
        type: UPDATE_SEARCH_OPTION_SCREENER,
        payload: searchData,
    };
};

export const updateSearchCountries = (country_obj) => {
    let new_countries_array = [];
    Object.keys(country_obj).map((country) =>
        country_obj[country] ? new_countries_array.push(country) : null
    );
    return {
        type: UPDATE_SEARCH_COUNTRIES,
        payload: new_countries_array,
    };
};

export const updateSearchIndustries = (industry_array, filter_boolean) => {
    return {
        type: UPDATE_SEARCH_INDUSTRIES,
        payload: { value: industry_array, filter: filter_boolean },
    };
};

export const updateCheckedIndustries = (
    new_checked_industries_object,
    new_selected_number
) => {
    return {
        type: UPDATE_CHECKED_INDUSTRIES,
        payload: {
            new_checked_industries_object: new_checked_industries_object,
            new_selected_number: new_selected_number,
        },
    };
};

export const clearScreenerValues = (slider, options) => {
    localStorage.setItem("latest_threshold", 0);
    localStorage.setItem("latest_industries", []);
    localStorage.setItem("latest_screener", "?sortBy=market_cap_usdDESC&");
    for (const [key] of Object.entries(slider)) {
        slider[key].value = slider[key].default_value;
        slider[key].filter = false;
        slider[key].display = false;
    }
    for (const [key] of Object.entries(options)) {
        if (
            key === "industry" ||
            key === "country" ||
            key === "esg" ||
            key === "filterPreset"
        ) {
            options[key].filter = false;
            options[key].display = false;
            options[key].value = options[key].default_value;
        } else if (key === "sortBy") {
            options[key].value = options[key].default_value;
            options[key].direction = options[key].direction_default;
        }
    }
    return {
        type: CLEAR_SCREENER_VALUES,
        payload: { slider: slider, options: options },
    };
};

export const setDisplayFalseAll = (slider, options) => {
    localStorage.setItem("latest_table", JSON.stringify([]));
    for (const [key] of Object.entries(slider)) {
        slider[key].display = false;
    }
    for (const [key] of Object.entries(options)) {
        options[key].value = options[key].default_value;
        if (key !== "searchOption" && key !== "sortBy") {
            options[key].display = false;
        }
    }
    return {
        type: SET_DISPLAY_FALSE_ALL,
        payload: { slider: slider, options: options },
    };
};

export const setThreshold = (threshold) => {
    localStorage.setItem("latest_threshold", JSON.stringify(threshold));
    return {
        type: SET_THRESHHOLD,
        payload: threshold,
    };
};

export const clearSingleSliderValue = (slider) => {
    slider.value = slider.default_value;
    slider.display = false;
    slider.filter = false;
    return {
        type: CLEAR_SINGLE_SLIDER_VALUE,
        payload: slider,
    };
};

export const updateESG = (bool) => {
    let esg_object = {
        property: "esg",
        value: bool,
        default_value: false,
        filter: bool,
        display: false,
        displayName: "ESG",
    };
    return {
        type: UPDATE_ESG_SCREENER,
        payload: esg_object,
    };
};

// /node_screener
// ?revenue_growth_5y=5_15&earnings_growth_5y=0_50
// GET filtered Companies from slider and options Reducer
export const getFilteredCompanies =
    (slider, options, page, threshold, checked) => async (dispatch) => {
        try {
            let not_in_url = ["sortBy", "country", "industry"];
            let preset = options.filterPreset.value;
            let params = `?sortBy=${options.sortBy.value}${options.sortBy.direction}&`;
            let score_helper = null;

            if (preset === "" || preset === "filtered_metrics") {
                for (const [key] of Object.entries(options)) {
                    if (options[key].filter && possible_options.includes(key)) {
                        if (!not_in_url.includes(key)) {
                            if (
                                !(
                                    key === "search" &&
                                    options[key].value === ""
                                ) &&
                                !(
                                    key === "searchOption" &&
                                    options.search.value === ""
                                )
                            ) {
                                params += `${key}=${options[key].value}&`;
                            }
                        }
                    }
                }
                for (const [key] of Object.entries(slider)) {
                    if (slider[key].filter && possible_ranges.includes(key)) {
                        score_helper = 0;
                        let new_min = slider[key].value[0];
                        let new_max = slider[key].value[1];
                        if (new_min < -9999999999999999) {
                            new_min = -9999999999999999;
                        }
                        if (new_max > 9999999999999999) {
                            new_max = 9999999999999999;
                        }

                        if (
                            slider[key].importance &&
                            slider[key].importance === 2
                        ) {
                            params += `${key}:=${new_min}_${new_max}&`;
                        } else {
                            params += `${key}=${new_min}_${new_max}&`;
                        }
                    }
                }
            }

            if (preset === "value") {
                params += `revenue_growth_5y=5_30&earnings_growth_5y=2_30&dividend_growth_5y=5_60&debt_ratio=0_75&price_earnings=0_10000000&`;
            }
            if (preset === "growth") {
                params += `revenue_growth_1y=15_100000&`;
            }
            if (preset === "dividends") {
                params += `revenue_growth_5y=5_15&earnings_growth_5y=2_15&dividend_growth_5y=5_60&price_earnings=0_10000000&dividend_yield_5y=1.5_10&`;
            }

            if (Number(threshold) > 0) {
                params += `threshold=${Number(threshold)}_1000&`;
            } else if (score_helper !== null) {
                params += `threshold=0_1000&`;
            }

            // APPLY TO LOCALSTORAGE
            localStorage.setItem("latest_screener", params);
            localStorage.setItem(
                "latest_industries",
                JSON.stringify(options.industry.value)
            );
            // localStorage.setItem("latest_countries", options.country.value);

            let securepage = Number(page);
            params += `page=${securepage}`;

            if (page === 0) {
                const res = await fetch(`/node_screener${params}`, {
                    method: "POST",
                    body: JSON.stringify({
                        countries_data: options.country.value,
                        industries_data: options.industry.value,
                        threshold: Number(threshold),
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // DO WHAT IS INTENDED
                    dispatch({
                        type: GET_COMPANIES,
                        payload: {
                            data: data,
                            slider: slider,
                            options: options,
                            threshold: Number(threshold),
                            checked: checked ? checked : null,
                        },
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occurred during the server request.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );

                    if (data.error_auth) {
                        // LOGOUT USER
                        try {
                            dispatch({
                                type: LOGOUT_SUCCESS,
                            });
                            dispatch({
                                type: CLEAR_PORTFOLIOS,
                            });
                            dispatch({
                                type: CLEAR_WATCHLISTS,
                            });
                            dispatch({
                                type: CLEAR_SCREENER,
                            });
                        } catch (err) {
                            dispatch({
                                type: LOGOUT_FAIL,
                            });
                        }
                    }
                }
            } else {
                const res = await fetch(`/node_screener${params}`, {
                    method: "POST",
                    body: JSON.stringify({
                        countries_data: options.country.value,
                        industries_data: options.industry.value,
                        threshold: Number(threshold),
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                });
                const data = await res.json();
                if (!data.error) {
                    // DO WHAT IS INTENDED
                    dispatch({
                        type: GET_COMPANIES_PAGINATION,
                        payload: data,
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occurred during the server request.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );

                    if (data.error_auth) {
                        // LOGOUT USER
                        try {
                            dispatch({
                                type: LOGOUT_SUCCESS,
                            });
                            dispatch({
                                type: CLEAR_PORTFOLIOS,
                            });
                            dispatch({
                                type: CLEAR_WATCHLISTS,
                            });
                            dispatch({
                                type: CLEAR_SCREENER,
                            });
                        } catch (err) {
                            dispatch({
                                type: LOGOUT_FAIL,
                            });
                        }
                    }
                }
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred during the server request.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// CREATE NEW SCREENER PRESET
export const createNewScreenerPreset =
    (slider, options, threshold, user_id, preset_name) => async (dispatch) => {
        try {
            let not_in_url = ["sortBy", "country", "industry"];
            let params = `?sortBy=${options.sortBy.value}${options.sortBy.direction}&`;
            let score_helper = null;

            for (const [key] of Object.entries(options)) {
                if (options[key].filter && possible_options.includes(key)) {
                    if (!not_in_url.includes(key)) {
                        if (
                            !(key === "search" && options[key].value === "") &&
                            !(
                                key === "searchOption" &&
                                options.search.value === ""
                            )
                        ) {
                            params += `${key}=${options[key].value}&`;
                        }
                    }
                }
            }
            for (const [key] of Object.entries(slider)) {
                if (slider[key].filter && possible_ranges.includes(key)) {
                    score_helper = 0;
                    let new_min = slider[key].value[0];
                    let new_max = slider[key].value[1];
                    if (new_min < -9999999999999999) {
                        new_min = -9999999999999999;
                    }
                    if (new_max > 9999999999999999) {
                        new_max = 9999999999999999;
                    }

                    if (
                        slider[key].importance &&
                        slider[key].importance === 2
                    ) {
                        params += `${key}:=${new_min}_${new_max}&`;
                    } else {
                        params += `${key}=${new_min}_${new_max}&`;
                    }
                }
            }

            // if (Number(threshold) > 0) {
            //     params += `threshold=${Number(threshold)}_1000&`;
            // } else if (score_helper !== null) {
            //     params += `threshold=0_1000&`;
            // }

            // params += `page=0`;

            const res = await fetch(`/node_create_screener_preset${params}`, {
                method: "POST",
                body: JSON.stringify({
                    user: user_id,
                    preset_name: preset_name,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            if (!data.error) {
                dispatch({
                    type: ADD_SCREENER_PRESET,
                    payload: data,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while creating a screener preset.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred while creating a screener preset.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// OVERWRITE SCREENER PRESET
export const overwriteScreenerPreset =
    (slider, options, threshold, user_id, preset_name, preset_id) =>
    async (dispatch) => {
        try {
            let not_in_url = ["sortBy", "country", "industry"];
            let params = `?sortBy=${options.sortBy.value}${options.sortBy.direction}&`;
            let score_helper = null;

            for (const [key] of Object.entries(options)) {
                if (options[key].filter && possible_options.includes(key)) {
                    if (!not_in_url.includes(key)) {
                        if (
                            !(key === "search" && options[key].value === "") &&
                            !(
                                key === "searchOption" &&
                                options.search.value === ""
                            )
                        ) {
                            params += `${key}=${options[key].value}&`;
                        }
                    }
                }
            }
            for (const [key] of Object.entries(slider)) {
                if (slider[key].filter && possible_ranges.includes(key)) {
                    score_helper = 0;
                    let new_min = slider[key].value[0];
                    let new_max = slider[key].value[1];
                    if (new_min < -9999999999999999) {
                        new_min = -9999999999999999;
                    }
                    if (new_max > 9999999999999999) {
                        new_max = 9999999999999999;
                    }

                    if (
                        slider[key].importance &&
                        slider[key].importance === 2
                    ) {
                        params += `${key}:=${new_min}_${new_max}&`;
                    } else {
                        params += `${key}=${new_min}_${new_max}&`;
                    }
                }
            }

            // if (Number(threshold) > 0) {
            //     params += `threshold=${Number(threshold)}_1000&`;
            // } else if (score_helper !== null) {
            //     params += `threshold=0_1000&`;
            // }

            // params += `page=0`;

            const res = await fetch(
                `/node_overwrite_screener_preset${params}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        user: user_id,
                        preset_name: preset_name,
                        preset_id: preset_id,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token,
                    },
                }
            );
            const data = await res.json();

            if (!data.error) {
                dispatch({
                    type: UPDATE_SCREENER_PRESET,
                    payload: data,
                });
                const id = Math.floor(Math.random() * 100);
                let type = "success";
                let msg = "Your screener preset has been successfully updated.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while overwriting your screener preset.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occurred while overwriting your screener preset.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// OVERWRITE SCREENER PRESET
export const deleteScreenerPreset =
    (user_id, preset_id) => async (dispatch) => {
        try {
            const res = await fetch(`/node_delete_screener_preset`, {
                method: "POST",
                body: JSON.stringify({
                    user: user_id,
                    preset_id: preset_id,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            if (!data.error) {
                dispatch({
                    type: DELETE_SCREENER_PRESET,
                    payload: data,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while deleting your screener preset.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred while deleting your screener preset.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// OVERWRITE SCREENER PRESET
export const renameScreenerPreset =
    (user_id, preset_id, preset_name) => async (dispatch) => {
        try {
            const res = await fetch(`/node_rename_screener_preset`, {
                method: "POST",
                body: JSON.stringify({
                    user: user_id,
                    preset_id: preset_id,
                    preset_name: preset_name,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();

            if (!data.error) {
                dispatch({
                    type: DELETE_SCREENER_PRESET,
                    payload: data,
                });
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while renaming your screener preset.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred while renaming your screener preset.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// /node_searchbar_companies
export const getSearchBarCompanies = (value) => async (dispatch) => {
    try {
        if (value !== null) {
            const res = await fetch(`/node_searchbar_companies?value=${value}`);
            const data = await res.json();

            if (!data.error) {
                if (data.rows) {
                    dispatch({
                        type: GET_SEARCHBAR_COMPANIES,
                        payload: data.rows,
                    });
                } else {
                    dispatch({
                        type: GET_SEARCHBAR_COMPANIES,
                        payload: null,
                    });
                }
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg
                    ? data.error_msg
                    : "An error occurred while requesting results to your search.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } else {
            dispatch({
                type: GET_SEARCHBAR_COMPANIES,
                payload: null,
            });
        }
    } catch (err) {
        // ERROR HANDLING
        const id = Math.floor(Math.random() * 100);
        let type = "alert";
        let msg = "An error occurred while requesting results to your search.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// node_histogram
// ?revenue_growth_5y
// GET data for the histogram
export const getSingleHistogramData = (metric) => async (dispatch) => {
    try {
        const res = await fetch(`/node_histogram?metric=${metric}`);
        const data = await res.json();
        if (!data.error) {
            let data_values = [];

            if (metric.includes("change")) {
                data.values.map((value) => data_values.push((value - 1) * 100));

                data.values = data_values;
                data.statistics.quantile_25 =
                    (data.statistics.quantile_25 - 1) * 100;
                data.statistics.quantile_50 =
                    (data.statistics.quantile_50 - 1) * 100;
                data.statistics.quantile_75 =
                    (data.statistics.quantile_75 - 1) * 100;
                data.statistics.bin_width = data.statistics.bin_width * 100;
                data.statistics.min =
                    data.statistics.quantile_50 - data.statistics.quantile_25 >
                    data.statistics.quantile_75 - data.statistics.quantile_50
                        ? data.statistics.quantile_50 -
                          4 *
                              (data.statistics.quantile_50 -
                                  data.statistics.quantile_25)
                        : data.statistics.quantile_50 -
                          4 *
                              (data.statistics.quantile_75 -
                                  data.statistics.quantile_50);
                data.statistics.max =
                    data.statistics.quantile_50 - data.statistics.quantile_25 >
                    data.statistics.quantile_75 - data.statistics.quantile_50
                        ? data.statistics.quantile_50 +
                          4 *
                              (data.statistics.quantile_50 -
                                  data.statistics.quantile_25)
                        : data.statistics.quantile_50 +
                          4 *
                              (data.statistics.quantile_75 -
                                  data.statistics.quantile_50);
            }

            dispatch({
                type: GET_HISTOGRAM_DATA,
                payload: { statistics: data.statistics, values: data.values },
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg
                ? data.error_msg
                : "An error occurred while requesting results to your search.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        // ERROR HANDLING
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "There are currently no statistics available for this company";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

let better_small = [
    "beta",
    "price_earnings",
    "price_sales",
    "price_book",
    "payout_ratio",
    "debt_ratio",
    "debt_to_ebit",
    "debt_to_equity",
    "debt_to_revenue",
    "price_cash_flow",
    "short_interest_ratio",
    "short_ratio_float",
    "short_ratio_outstanding",
    "price_earnings_deviation_10y",
    "price_book_deviation_10y",
    "price_sales_deviation_10y",
    "price_cash_flow_deviation_10y",
];

let fixed_range_metrics = [
    "beta",
    "avg_rating_analyst",
    "implied_volatility",
    // "target_price_analyst",
    // "short_interest_ratio",
    // "short_ratio_float",
    // "short_ratio_outstanding",
    // "shares_short",
    // "shares_short_prior_month",
    // "shares_held_by_insiders",
    // "shares_held_by_institutions",
    // "outstanding_shares",
    // "outstanding_shares_change",
    // "shares_float",
    "stock_price",
    "daily_return",
    "weekly_return",
    "monthly_return",
    "quarterly_return",
    "semi_annual_return",
    "annual_return",
    "two_year_return",
    "three_year_return",
    "market_cap_usd",
    // "total_revenue",
    // "free_cashflow",
    // "net_income",
    // "ebit",
    // "gross_profit",
    // "dividends_paid",
    // "total_liabilities",
    // "total_current_liabilities",
    // "total_assets",
    // "total_current_assets",
    // "research_development",
    // "market_cap",
    "market_cap_change",
    "market_cap_ranker",
    "market_cap_ranker_change",
    "price_earnings",
    "price_earnings_change",
    "price_earnings_ranker",
    "price_earnings_ranker_change",
    "price_earnings_growth",
    "price_earnings_growth_change",
    "price_earnings_growth_ranker",
    "price_earnings_growth_ranker_change",
    "price_sales",
    "price_sales_change",
    "price_sales_ranker",
    "price_sales_ranker_change",
    "price_sales_growth",
    "price_sales_growth_change",
    "price_sales_growth_ranker",
    "price_sales_growth_ranker_change",
    "price_book",
    "price_book_change",
    "price_book_ranker",
    "price_book_ranker_change",
    "dividend_yield",
    "dividend_yield_change",
    "dividend_yield_ranker",
    "dividend_yield_ranker_change",
    "price_ebit",
    "price_ebit_average_10y",
    "price_ebit_deviation_10y",
    "ev_ebit",
    "ev_ebit_average_10y",
    "ev_ebit_deviation_10y",
    "ev_ebitda",
    "ev_ebitda_average_10y",
    "ev_ebitda_deviation_10y",
    "dividend_consistency",
    "price_ebit_ranker",
    "ev_ebit_ranker",
    "ev_ebitda_ranker",
    "payout_ratio",
    "payout_ratio_change",
    "payout_ratio_ranker",
    "payout_ratio_ranker_change",
    "revenue_growth_1y",
    "revenue_growth_1y_change",
    "revenue_growth_1y_ranker",
    "revenue_growth_1y_ranker_change",
    "earnings_growth_1y",
    "earnings_growth_1y_change",
    "earnings_growth_1y_ranker",
    "earnings_growth_1y_ranker_change",
    // "dividend_growth_1y",
    // "dividend_growth_1y_change",
    // "dividend_growth_1y_ranker",
    // "dividend_growth_1y_ranker_change",
    "revenue_growth_3y",
    "revenue_growth_3y_change",
    "revenue_growth_3y_ranker",
    "revenue_growth_3y_ranker_change",
    "earnings_growth_3y",
    "earnings_growth_3y_change",
    "earnings_growth_3y_ranker",
    "earnings_growth_3y_ranker_change",
    // "dividend_growth_3y",
    // "dividend_growth_3y_change",
    // "dividend_growth_3y_ranker",
    // "dividend_growth_3y_ranker_change",
    "revenue_growth_5y",
    "revenue_growth_5y_change",
    "revenue_growth_5y_ranker",
    "revenue_growth_5y_ranker_change",
    "earnings_growth_5y",
    "earnings_growth_5y_change",
    "earnings_growth_5y_ranker",
    "earnings_growth_5y_ranker_change",
    // "dividend_growth_5y",
    // "dividend_growth_5y_change",
    // "dividend_growth_5y_ranker",
    // "dividend_growth_5y_ranker_change",
    "revenue_growth_9y",
    "revenue_growth_9y_change",
    "revenue_growth_9y_ranker",
    "revenue_growth_9y_ranker_change",
    "earnings_growth_9y",
    "earnings_growth_9y_change",
    "earnings_growth_9y_ranker",
    "earnings_growth_9y_ranker_change",
    "dividend_growth_9y",
    "dividend_growth_9y_change",
    "dividend_growth_9y_ranker",
    "dividend_growth_9y_ranker_change",
    "gross_profit_margin",
    "gross_profit_margin_change",
    "gross_profit_margin_ranker",
    "gross_profit_margin_ranker_change",
    "net_profit_margin",
    "net_profit_margin_change",
    "net_profit_margin_ranker",
    "net_profit_margin_ranker_change",
    "operating_cash_flow_margin",
    "operating_cash_flow_margin_change",
    "operating_cash_flow_margin_ranker",
    "operating_cash_flow_margin_ranker_change",
    "operating_margin",
    "operating_margin_change",
    "operating_margin_ranker",
    "operating_margin_ranker_change",
    "return_on_assets",
    "return_on_assets_change",
    "return_on_assets_ranker",
    "return_on_assets_ranker_change",
    "asset_turnover",
    "asset_turnover_change",
    "asset_turnover_ranker",
    "asset_turnover_ranker_change",
    "cash_flow_on_assets",
    "cash_flow_on_assets_change",
    "cash_flow_on_assets_ranker",
    "cash_flow_on_assets_ranker_change",
    "return_on_equity",
    "return_on_equity_change",
    "return_on_equity_ranker",
    "return_on_equity_ranker_change",
    "revenue_on_equity",
    "revenue_on_equity_change",
    "revenue_on_equity_ranker",
    "revenue_on_equity_ranker_change",
    "return_on_capital_employed",
    "return_on_capital_employed_change",
    "return_on_capital_employed_ranker",
    "return_on_capital_employed_ranker_change",
    "debt_ratio",
    "debt_ratio_change",
    "debt_ratio_ranker",
    "debt_ratio_ranker_change",
    "debt_to_ebit",
    "debt_to_ebit_change",
    "debt_to_ebit_ranker",
    "debt_to_ebit_ranker_change",
    "debt_to_equity",
    "debt_to_equity_change",
    "debt_to_equity_ranker",
    "debt_to_equity_ranker_change",
    "debt_to_revenue",
    "debt_to_revenue_change",
    "debt_to_revenue_ranker",
    "debt_to_revenue_ranker_change",
    "current_ratio",
    "current_ratio_change",
    "current_ratio_ranker",
    "current_ratio_ranker_change",
    "price_cash_flow",
    "price_cash_flow_change",
    "price_cash_flow_ranker",
    "price_cash_flow_ranker_change",
    "price_earnings_average_10y",
    "price_earnings_deviation_10y",
    "price_book_average_10y",
    "price_book_deviation_10y",
    "price_sales_average_10y",
    "price_sales_deviation_10y",
    "price_cash_flow_average_10y",
    "price_cash_flow_deviation_10y",
    "volume_deviation",
    "relative_score",
    "piotroski_score",
    "return_on_capital_ranker",
    "return_on_net_tangible_assets_ranker",
    "return_on_capital",
    "return_on_net_tangible_assets",
];
let fixed_range_object = {
    beta: {
        min: 0,
        max: 3,
    },
    avg_rating_analyst: {
        min: 0,
        max: 5,
    },
    implied_volatility: {
        min: 0,
        max: 100,
    },
    // target_price_analyst: {
    //     min: 0,
    //     max: 1,
    // },
    // short_interest_ratio: {
    //     min: 0,
    //     max: 1,
    // },
    // short_ratio_float: {
    //     min: 0,
    //     max: 1,
    // },
    // short_ratio_outstanding: {
    //     min: 0,
    //     max: 1,
    // },
    // shares_short: {
    //     min: 0,
    //     max: 1,
    // },
    // shares_short_prior_month: {
    //     min: 0,
    //     max: 1,
    // },
    // shares_held_by_insiders: {
    //     min: 0,
    //     max: 1,
    // },
    // shares_held_by_institutions: {
    //     min: 0,
    //     max: 1,
    // },
    // outstanding_shares: {
    //     min: 0,
    //     max: 1,
    // },
    // outstanding_shares_change: {
    //     min: 0,
    //     max: 1,
    // },
    // shares_float: {
    //     min: 0,
    //     max: 1,
    // },
    stock_price: {
        min: 0,
        max: 1000,
    },
    daily_return: {
        min: -15,
        max: 15,
    },
    weekly_return: {
        min: -20,
        max: 25,
    },
    monthly_return: {
        min: -25,
        max: 40,
    },
    quarterly_return: {
        min: -30,
        max: 50,
    },
    semi_annual_return: {
        min: -50,
        max: 100,
    },
    annual_return: {
        min: -50,
        max: 100,
    },
    two_year_return: {
        min: -50,
        max: 100,
    },
    three_year_return: {
        min: -50,
        max: 100,
    },
    market_cap_usd: {
        min: 0,
        max: 3000000000000,
    },
    // total_revenue: {
    //     min: 0,
    //     max: 1,
    // },
    // free_cashflow: {
    //     min: 0,
    //     max: 1,
    // },
    // net_income: {
    //     min: 0,
    //     max: 1,
    // },
    // ebit: {
    //     min: 0,
    //     max: 1,
    // },
    // gross_profit: {
    //     min: 0,
    //     max: 1,
    // },
    // dividends_paid: {
    //     min: 0,
    //     max: 1,
    // },
    // total_liabilities: {
    //     min: 0,
    //     max: 1,
    // },
    // total_current_liabilities: {
    //     min: 0,
    //     max: 1,
    // },
    // total_assets: {
    //     min: 0,
    //     max: 1,
    // },
    // total_current_assets: {
    //     min: 0,
    //     max: 1,
    // },
    // research_development: {
    //     min: 0,
    //     max: 1,
    // },
    // market_cap: {
    //     min: 0,
    //     max: 1,
    // },
    market_cap_change: {
        min: 0,
        max: 1,
    },
    market_cap_ranker: {
        min: 0,
        max: 1,
    },
    market_cap_ranker_change: {
        min: 0,
        max: 1,
    },
    price_earnings: {
        min: 0,
        max: 100,
    },
    price_earnings_change: {
        min: 0,
        max: 1,
    },
    price_earnings_ranker: {
        min: 0,
        max: 1,
    },
    price_earnings_ranker_change: {
        min: 0,
        max: 1,
    },
    price_earnings_growth: {
        min: 0,
        max: 20,
    },
    price_earnings_growth_change: {
        min: 0,
        max: 1,
    },
    price_earnings_growth_ranker: {
        min: 0,
        max: 1,
    },
    price_earnings_growth_ranker_change: {
        min: 0,
        max: 1,
    },
    price_sales: {
        min: 0,
        max: 50,
    },
    price_sales_change: {
        min: 0,
        max: 1,
    },
    price_sales_ranker: {
        min: 0,
        max: 1,
    },
    price_sales_ranker_change: {
        min: 0,
        max: 1,
    },
    price_sales_growth: {
        min: 0,
        max: 20,
    },
    price_sales_growth_change: {
        min: 0,
        max: 1,
    },
    price_sales_growth_ranker: {
        min: 0,
        max: 1,
    },
    price_sales_growth_ranker_change: {
        min: 0,
        max: 1,
    },
    price_book: {
        min: 0,
        max: 50,
    },
    price_book_change: {
        min: 0,
        max: 1,
    },
    price_book_ranker: {
        min: 0,
        max: 1,
    },
    price_book_ranker_change: {
        min: 0,
        max: 1,
    },
    dividend_yield: {
        min: 0,
        max: 10,
    },
    dividend_yield_change: {
        min: 0,
        max: 1,
    },
    dividend_yield_ranker: {
        min: 0,
        max: 1,
    },
    dividend_yield_ranker_change: {
        min: 0,
        max: 1,
    },
    price_ebit: {
        min: 0,
        max: 50,
    },
    price_ebit_average_10y: {
        min: 0,
        max: 50,
    },
    price_ebit_deviation_10y: {
        min: -50,
        max: 100,
    },
    ev_ebit: {
        min: 0,
        max: 50,
    },
    ev_ebit_average_10y: {
        min: 0,
        max: 50,
    },
    ev_ebit_deviation_10y: {
        min: -50,
        max: 100,
    },
    ev_ebitda: {
        min: 0,
        max: 50,
    },
    ev_ebitda_average_10y: {
        min: 0,
        max: 50,
    },
    ev_ebitda_deviation_10y: {
        min: -50,
        max: 100,
    },
    dividend_consistency: {
        min: 0,
        max: 1,
    },
    price_ebit_ranker: {
        min: 0,
        max: 1,
    },
    ev_ebit_ranker: {
        min: 0,
        max: 1,
    },
    ev_ebitda_ranker: {
        min: 0,
        max: 1,
    },
    payout_ratio: {
        min: 0,
        max: 100,
    },
    payout_ratio_change: {
        min: 0,
        max: 1,
    },
    payout_ratio_ranker: {
        min: 0,
        max: 1,
    },
    payout_ratio_ranker_change: {
        min: 0,
        max: 1,
    },
    revenue_growth_1y: {
        min: -20,
        max: 100,
    },
    revenue_growth_1y_change: {
        min: 0,
        max: 1,
    },
    revenue_growth_1y_ranker: {
        min: 0,
        max: 1,
    },
    revenue_growth_1y_ranker_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_1y: {
        min: -20,
        max: 100,
    },
    earnings_growth_1y_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_1y_ranker: {
        min: 0,
        max: 1,
    },
    earnings_growth_1y_ranker_change: {
        min: 0,
        max: 1,
    },
    dividend_growth_1y: {
        min: -20,
        max: 100,
    },
    // dividend_growth_1y_change: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_1y_ranker: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_1y_ranker_change: {
    //     min: 0,
    //     max: 1,
    // },
    revenue_growth_3y: {
        min: -20,
        max: 100,
    },
    revenue_growth_3y_change: {
        min: 0,
        max: 1,
    },
    revenue_growth_3y_ranker: {
        min: 0,
        max: 1,
    },
    revenue_growth_3y_ranker_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_3y: {
        min: -20,
        max: 100,
    },
    earnings_growth_3y_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_3y_ranker: {
        min: 0,
        max: 1,
    },
    earnings_growth_3y_ranker_change: {
        min: 0,
        max: 1,
    },
    dividend_growth_3y: {
        min: -20,
        max: 100,
    },
    // dividend_growth_3y_change: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_3y_ranker: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_3y_ranker_change: {
    //     min: 0,
    //     max: 1,
    // },
    revenue_growth_5y: {
        min: -20,
        max: 100,
    },
    revenue_growth_5y_change: {
        min: 0,
        max: 1,
    },
    revenue_growth_5y_ranker: {
        min: 0,
        max: 1,
    },
    revenue_growth_5y_ranker_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_5y: {
        min: -20,
        max: 100,
    },
    earnings_growth_5y_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_5y_ranker: {
        min: 0,
        max: 1,
    },
    earnings_growth_5y_ranker_change: {
        min: 0,
        max: 1,
    },
    dividend_growth_5y: {
        min: -20,
        max: 100,
    },
    // dividend_growth_5y_change: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_5y_ranker: {
    //     min: 0,
    //     max: 1,
    // },
    // dividend_growth_5y_ranker_change: {
    //     min: 0,
    //     max: 1,
    // },
    revenue_growth_9y: {
        min: -20,
        max: 100,
    },
    revenue_growth_9y_change: {
        min: 0,
        max: 1,
    },
    revenue_growth_9y_ranker: {
        min: 0,
        max: 1,
    },
    revenue_growth_9y_ranker_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_9y: {
        min: -20,
        max: 100,
    },
    earnings_growth_9y_change: {
        min: 0,
        max: 1,
    },
    earnings_growth_9y_ranker: {
        min: 0,
        max: 1,
    },
    earnings_growth_9y_ranker_change: {
        min: 0,
        max: 1,
    },
    dividend_growth_9y: {
        min: -20,
        max: 100,
    },
    dividend_growth_9y_change: {
        min: 0,
        max: 1,
    },
    dividend_growth_9y_ranker: {
        min: 0,
        max: 1,
    },
    dividend_growth_9y_ranker_change: {
        min: 0,
        max: 1,
    },
    gross_profit_margin: {
        min: 0,
        max: 100,
    },
    gross_profit_margin_change: {
        min: 0,
        max: 1,
    },
    gross_profit_margin_ranker: {
        min: 0,
        max: 1,
    },
    gross_profit_margin_ranker_change: {
        min: 0,
        max: 1,
    },
    net_profit_margin: {
        min: 0,
        max: 100,
    },
    net_profit_margin_change: {
        min: 0,
        max: 1,
    },
    net_profit_margin_ranker: {
        min: 0,
        max: 1,
    },
    net_profit_margin_ranker_change: {
        min: 0,
        max: 1,
    },
    operating_cash_flow_margin: {
        min: 0,
        max: 100,
    },
    operating_cash_flow_margin_change: {
        min: 0,
        max: 1,
    },
    operating_cash_flow_margin_ranker: {
        min: 0,
        max: 1,
    },
    operating_cash_flow_margin_ranker_change: {
        min: 0,
        max: 1,
    },
    operating_margin: {
        min: 0,
        max: 100,
    },
    operating_margin_change: {
        min: 0,
        max: 1,
    },
    operating_margin_ranker: {
        min: 0,
        max: 1,
    },
    operating_margin_ranker_change: {
        min: 0,
        max: 1,
    },
    return_on_assets: {
        min: -30,
        max: 80,
    },
    return_on_assets_change: {
        min: 0,
        max: 1,
    },
    return_on_assets_ranker: {
        min: 0,
        max: 1,
    },
    return_on_assets_ranker_change: {
        min: 0,
        max: 1,
    },
    asset_turnover: {
        min: 0,
        max: 150,
    },
    asset_turnover_change: {
        min: 0,
        max: 1,
    },
    asset_turnover_ranker: {
        min: 0,
        max: 1,
    },
    asset_turnover_ranker_change: {
        min: 0,
        max: 1,
    },
    cash_flow_on_assets: {
        min: -30,
        max: 80,
    },
    cash_flow_on_assets_change: {
        min: 0,
        max: 1,
    },
    cash_flow_on_assets_ranker: {
        min: 0,
        max: 1,
    },
    cash_flow_on_assets_ranker_change: {
        min: 0,
        max: 1,
    },
    return_on_equity: {
        min: -30,
        max: 80,
    },
    return_on_equity_change: {
        min: 0,
        max: 1,
    },
    return_on_equity_ranker: {
        min: 0,
        max: 1,
    },
    return_on_equity_ranker_change: {
        min: 0,
        max: 1,
    },
    revenue_on_equity: {
        min: 0,
        max: 250,
    },
    revenue_on_equity_change: {
        min: 0,
        max: 1,
    },
    revenue_on_equity_ranker: {
        min: 0,
        max: 1,
    },
    revenue_on_equity_ranker_change: {
        min: 0,
        max: 1,
    },
    return_on_capital_employed: {
        min: -30,
        max: 80,
    },
    return_on_capital_employed_change: {
        min: 0,
        max: 1,
    },
    return_on_capital_employed_ranker: {
        min: 0,
        max: 1,
    },
    return_on_capital_employed_ranker_change: {
        min: 0,
        max: 1,
    },
    debt_ratio: {
        min: 0,
        max: 100,
    },
    debt_ratio_change: {
        min: 0,
        max: 1,
    },
    debt_ratio_ranker: {
        min: 0,
        max: 1,
    },
    debt_ratio_ranker_change: {
        min: 0,
        max: 1,
    },
    debt_to_ebit: {
        min: 0,
        max: 20,
    },
    debt_to_ebit_change: {
        min: 0,
        max: 1,
    },
    debt_to_ebit_ranker: {
        min: 0,
        max: 1,
    },
    debt_to_ebit_ranker_change: {
        min: 0,
        max: 1,
    },
    debt_to_equity: {
        min: 0,
        max: 15,
    },
    debt_to_equity_change: {
        min: 0,
        max: 1,
    },
    debt_to_equity_ranker: {
        min: 0,
        max: 1,
    },
    debt_to_equity_ranker_change: {
        min: 0,
        max: 1,
    },
    debt_to_revenue: {
        min: 0,
        max: 5,
    },
    debt_to_revenue_change: {
        min: 0,
        max: 1,
    },
    debt_to_revenue_ranker: {
        min: 0,
        max: 1,
    },
    debt_to_revenue_ranker_change: {
        min: 0,
        max: 1,
    },
    current_ratio: {
        min: 0,
        max: 15,
    },
    current_ratio_change: {
        min: 0,
        max: 1,
    },
    current_ratio_ranker: {
        min: 0,
        max: 1,
    },
    current_ratio_ranker_change: {
        min: 0,
        max: 1,
    },
    price_cash_flow: {
        min: 0,
        max: 50,
    },
    price_cash_flow_change: {
        min: 0,
        max: 1,
    },
    price_cash_flow_ranker: {
        min: 0,
        max: 1,
    },
    price_cash_flow_ranker_change: {
        min: 0,
        max: 1,
    },
    price_earnings_average_10y: {
        min: 0,
        max: 100,
    },
    price_earnings_deviation_10y: {
        min: -50,
        max: 100,
    },
    price_book_average_10y: {
        min: 0,
        max: 50,
    },
    price_book_deviation_10y: {
        min: -50,
        max: 100,
    },
    price_sales_average_10y: {
        min: 0,
        max: 50,
    },
    price_sales_deviation_10y: {
        min: -50,
        max: 100,
    },
    price_cash_flow_average_10y: {
        min: 0,
        max: 50,
    },
    price_cash_flow_deviation_10y: {
        min: -50,
        max: 100,
    },
    volume_deviation: {
        min: -50,
        max: 100,
    },
    relative_score: {
        min: 0,
        max: 8,
    },
    piotroski_score: {
        min: 0,
        max: 9,
    },
    return_on_capital_ranker: { min: 0, max: 1 },
    return_on_net_tangible_assets_ranker: { min: 0, max: 1 },
    return_on_capital: { min: -30, max: 80 },
    return_on_net_tangible_assets: { min: -30, max: 80 },
};

let fixed_min_metrics = [
    "short_interest_ratio",
    "short_ratio_float",
    "short_ratio_outstanding",
    "price_sales",
    "dividend_yield",
    "payout_ratio",
    "asset_turnover",
];
let fixed_min_object = {
    short_interest_ratio: 0,
    short_ratio_float: 0,
    short_ratio_outstanding: 0,
    price_sales: 0,
    dividend_yield: 0,
    payout_ratio: 0,
    asset_turnover: 0,
};

let fixed_max_metrics = [];

// GET statistics for filter and sliders
export const getCompaniesStatistics =
    (statistics, slider, metrics, url_params) => async (dispatch) => {
        try {
            if (statistics === null) {
                const res = await fetch(`/node_statistics`);
                const data = await res.json();

                if (!data.error) {
                    let new_statistics = {};
                    data.map((met) => (new_statistics[met.metric] = met));

                    for (const [key] of Object.entries(slider)) {
                        try {
                            slider[key].displayName = metrics[key].displayName;
                            slider[key].filter_keywords =
                                metrics[key].filter_keywords;
                            slider[key].unit = metrics[key].unit;
                            slider[key].axis = metrics[key].axis;
                            if (key.includes("change")) {
                                slider[key].value = better_small.includes(key)
                                    ? [
                                          (new_statistics[key].quantile_25 -
                                              1) *
                                              100,
                                          (new_statistics[key].quantile_50 -
                                              1) *
                                              100,
                                      ]
                                    : [
                                          (new_statistics[key].quantile_50 -
                                              1) *
                                              100,
                                          (new_statistics[key].quantile_75 -
                                              1) *
                                              100,
                                      ];
                                slider[key].default_value =
                                    better_small.includes(key)
                                        ? [
                                              (new_statistics[key].quantile_25 -
                                                  1) *
                                                  100,
                                              (new_statistics[key].quantile_50 -
                                                  1) *
                                                  100,
                                          ]
                                        : [
                                              (new_statistics[key].quantile_50 -
                                                  1) *
                                                  100,
                                              (new_statistics[key].quantile_75 -
                                                  1) *
                                                  100,
                                          ];
                                slider[key].statistics = [
                                    (new_statistics[key].quantile_25 - 1) * 100,
                                    (new_statistics[key].quantile_50 - 1) * 100,
                                    (new_statistics[key].quantile_75 - 1) * 100,
                                ];
                                slider[key].min_max =
                                    new_statistics[key].quantile_50 -
                                        new_statistics[key].quantile_25 >
                                    new_statistics[key].quantile_75 -
                                        new_statistics[key].quantile_50
                                        ? [
                                              (new_statistics[key].quantile_50 -
                                                  4 *
                                                      (new_statistics[key]
                                                          .quantile_50 -
                                                          new_statistics[key]
                                                              .quantile_25) -
                                                  1) *
                                                  100,
                                              (new_statistics[key].quantile_50 +
                                                  4 *
                                                      (new_statistics[key]
                                                          .quantile_50 -
                                                          new_statistics[key]
                                                              .quantile_25) -
                                                  1) *
                                                  100,
                                          ]
                                        : [
                                              (new_statistics[key].quantile_50 -
                                                  4 *
                                                      (new_statistics[key]
                                                          .quantile_75 -
                                                          new_statistics[key]
                                                              .quantile_50) -
                                                  1) *
                                                  100,
                                              (new_statistics[key].quantile_50 +
                                                  4 *
                                                      (new_statistics[key]
                                                          .quantile_75 -
                                                          new_statistics[key]
                                                              .quantile_50) -
                                                  1) *
                                                  100,
                                          ];
                            } else {
                                if (
                                    key.includes("ranker") &&
                                    !key.includes("change")
                                ) {
                                    if (!url_params.includes(key)) {
                                        slider[key].value = [0.7, 1];
                                    }

                                    slider[key].default_value = [0.7, 1];

                                    slider[key].min_max = [0, 1];
                                } else {
                                    if (!url_params.includes(key)) {
                                        slider[key].value =
                                            better_small.includes(key)
                                                ? (slider[key].value = [
                                                      new_statistics[key]
                                                          .quantile_25,
                                                      new_statistics[key]
                                                          .quantile_50,
                                                  ])
                                                : (slider[key].value = [
                                                      new_statistics[key]
                                                          .quantile_50,
                                                      new_statistics[key]
                                                          .quantile_75,
                                                  ]);
                                    }

                                    slider[key].default_value =
                                        better_small.includes(key)
                                            ? [
                                                  new_statistics[key]
                                                      .quantile_25,
                                                  new_statistics[key]
                                                      .quantile_50,
                                              ]
                                            : [
                                                  new_statistics[key]
                                                      .quantile_50,
                                                  new_statistics[key]
                                                      .quantile_75,
                                              ];

                                    slider[key].min_max =
                                        new_statistics[key].quantile_50 -
                                            new_statistics[key].quantile_25 >
                                        new_statistics[key].quantile_75 -
                                            new_statistics[key].quantile_50
                                            ? [
                                                  new_statistics[key]
                                                      .quantile_50 -
                                                      4 *
                                                          (new_statistics[key]
                                                              .quantile_50 -
                                                              new_statistics[
                                                                  key
                                                              ].quantile_25),
                                                  new_statistics[key]
                                                      .quantile_50 +
                                                      4 *
                                                          (new_statistics[key]
                                                              .quantile_50 -
                                                              new_statistics[
                                                                  key
                                                              ].quantile_25),
                                              ]
                                            : [
                                                  new_statistics[key]
                                                      .quantile_50 -
                                                      4 *
                                                          (new_statistics[key]
                                                              .quantile_75 -
                                                              new_statistics[
                                                                  key
                                                              ].quantile_50),
                                                  new_statistics[key]
                                                      .quantile_50 +
                                                      4 *
                                                          (new_statistics[key]
                                                              .quantile_75 -
                                                              new_statistics[
                                                                  key
                                                              ].quantile_50),
                                              ];
                                }
                                slider[key].statistics = [
                                    new_statistics[key].quantile_25,
                                    new_statistics[key].quantile_50,
                                    new_statistics[key].quantile_75,
                                ];
                            }
                            if (fixed_range_metrics.includes(key)) {
                                slider[key].min_max = [
                                    fixed_range_object[key].min,
                                    fixed_range_object[key].max,
                                ];
                            }
                            if (fixed_min_metrics.includes(key)) {
                                slider[key].min_max[0] = 0;
                            }
                        } catch (err) {}
                    }
                    dispatch({
                        type: GET_COMPANIES_STATISTICS,
                        payload: { new_statistics, slider },
                    });
                } else {
                    // ERROR HANDLING
                    const id = Math.floor(Math.random() * 100);
                    let type = data.error_type ? data.error_type : "warning";
                    let msg = data.error_msg
                        ? data.error_msg
                        : "An error occurred during a server request.";

                    dispatch({
                        type: SET_ALERT,
                        payload: { msg, type, id },
                    });

                    setTimeout(
                        () => dispatch({ type: REMOVE_ALERT, payload: id }),
                        3000
                    );
                }
            }
        } catch (err) {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg = "An error occurred during a server request.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };
