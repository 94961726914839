import React, { useContext } from "react";
import { useIntl } from "react-intl";

// Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { MetricsContext } from "../../../context/MetricsContext";

import MetricAnalysisBar from "../portfolio/figures/MetricAnalysisBar";
import MetricAnalysisBarUpgrade from "./figures/MetricAnalysisBarUpgrade";

import { getAverageMetric } from "../../../actions/generalActions";

const PortfolioRisk = ({
    company_list,
    company_allocation,
    user_free,
    screener: { slider },
}) => {
    const useintl = useIntl();

    const metrics = useContext(MetricsContext);

    let SliderData = {};

    let AllMetrics = metrics.AllMetrics;

    let sliderDataProvider = (AllMetrics) => {
        let DataProvider = {};
        AllMetrics.map((metric, index) => {
            DataProvider[metric] = {
                id: index,
                value: slider[metric].value,
                default_value: slider[metric].default_value,
                statistics: slider[metric].statistics,
                min_max: slider[metric].min_max,
                filter: slider[metric].filter,
                display: slider[metric].display,
                ...metrics.metrics[metric],
            };
        });
        return DataProvider;
    };

    SliderData = sliderDataProvider(AllMetrics);

    // Slider Array getting mapped

    const [searchSliderObject, setSearchSliderObject] =
        React.useState(SliderData);

    let donut_colors = [
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
        "#263642",
        "#415167",
        "#60A695",
        "#89C18C",
        "#FEF5D6",
        "#BD8B7E",
        "#26617C",
    ];

    let donut_colors_hover = [
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
        "rgb(38, 54, 66, 0.2)",
        "rgb(65, 81, 103, 0.2)",
        "rgb(96, 166, 149, 0.3)",
        "rgb(137, 193, 140, 0.3)",
        "rgb(254, 245, 214, 0.3)",
        "rgb(189, 139, 126, 0.3)",
        "rgb(38, 97, 124, 0.3)",
    ];

    let intrinsic_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "intrinsic_risk"
    );

    let equity_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "equity_risk"
    );

    let interest_rate_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "interest_rate_risk"
    );

    let credit_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "credit_risk"
    );

    let commodities_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "commodities_risk"
    );

    let inflation_risk_avg = getAverageMetric(
        company_list,
        company_allocation,
        "inflation_risk"
    );

    let extrinsic_risk_total_avg = 1 - intrinsic_risk_avg;

    let extrinsic_equity_avg =
        Number(equity_risk_avg) / extrinsic_risk_total_avg;
    let extrinsic_interest_rate_avg =
        interest_rate_risk_avg / extrinsic_risk_total_avg;
    let extrinsic_credit_avg = credit_risk_avg / extrinsic_risk_total_avg;
    let extrinsic_commodities_avg =
        commodities_risk_avg / extrinsic_risk_total_avg;
    let extrinsic_inflation_avg = inflation_risk_avg / extrinsic_risk_total_avg;

    let risk_allocation = [
        {
            name: "Equity risk",
            y: user_free
                ? 20
                : Number((Number(extrinsic_equity_avg) * 100).toFixed(2)),
            color: donut_colors[1],
            f: donut_colors_hover[1],
        },
        {
            name: "Interest rate risk",
            y: user_free
                ? 20
                : Number((extrinsic_interest_rate_avg * 100).toFixed(2)),
            color: donut_colors[2],
            f: donut_colors_hover[2],
        },
        {
            name: "Credit risk",
            y: user_free ? 20 : Number((extrinsic_credit_avg * 100).toFixed(2)),
            color: donut_colors[3],
            f: donut_colors_hover[3],
        },
        {
            name: "Commodities risk",
            y: user_free
                ? 20
                : Number((extrinsic_commodities_avg * 100).toFixed(2)),
            color: donut_colors[4],
            f: donut_colors_hover[4],
        },
        {
            name: "Inflation risk",
            y: user_free
                ? 20
                : Number((extrinsic_inflation_avg * 100).toFixed(2)),
            color: donut_colors[5],
            f: donut_colors_hover[5],
        },
    ];

    let chartOptions = {
        // responsive: {
        //     rules: [
        //         {
        //             condition: {
        //                 maxWidth: 450,
        //             },
        //             chartOptions: {
        //                 // chart: {
        //                 //     width: 250,
        //                 // },
        //                 legend: {
        //                     layout: "vertical",
        //                     align: "right",
        //                     x: -45,
        //                     verticalAlign: "top",
        //                     y: -10,
        //                     floating: true,
        //                     backgroundColor:
        //                         Highcharts.getOptions().legend
        //                             .backgroundColor || // theme
        //                         "rgba(255,255,255,0.25)",
        //                 },
        //                 title: {
        //                     text: `Extrinsic Risk`,
        //                     align: "center",
        //                     verticalAlign: "middle",
        //                     y: 10,
        //                     style: {
        //                         fontSize: "14px",
        //                     },
        //                 },
        //             },
        //         },
        //         {
        //             condition: {
        //                 minWidth: 451,
        //             },
        //             chartOptions: {
        //                 // chart: {
        //                 //     width: 450,
        //                 // },title: {
        //                 text: `Extrinsic Risk`,
        //                 align: "center",
        //                 verticalAlign: "middle",
        //                 y: -10,
        //                 style: {
        //                     fontSize: "14px",
        //                 },
        //             },
        //             legend: {
        //                 align: "center",
        //                 verticalAlign: "bottom",
        //                 x: 0,
        //                 y: 0,
        //                 backgroundColor:
        //                     Highcharts.getOptions().legend.backgroundColor || // theme
        //                     "rgba(255,255,255,0.25)",
        //             },
        //         },
        //     ],
        // },

        chart: {
            type: "pie",
            backgroundColor: "rgb(0,0,0,0)",
            margin: [0, 0, 0, 0],
            spacingTop: 10,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        },
        title: {
            text: `Extrinsic Risk`,
            align: "center",
            verticalAlign: "middle",
            y: 20,
            style: {
                fontSize: "16px",
            },
        },
        exporting: {
            enabled: false,
        },
        stockTools: {
            gui: {
                enabled: false,
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -125,
                    y: -5,
                    format: "{y}%",
                    style: {
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                    },
                },
                size: "100%",
                borderWidth: 3,
                showInLegend: true,
            },
            series: {
                animation: false,
            },
        },
        tooltip: {
            pointFormat: `{series.name}<br/>Proportion: <b>{point.y} %</b><br/>`,
            shared: true,
        },
        legend: {
            layout: "vertical",
            align: "right",
            x: 5,
            verticalAlign: "top",
            y: -5,
            floating: true,
            backgroundColor:
                Highcharts.getOptions().legend.backgroundColor || // theme
                "rgba(255,255,255,0.25)",
        },
        series: [
            {
                type: "pie",
                name: "",
                tooltip: {
                    valueDecimals: 2,
                },
                innerSize: "65%",
                data: risk_allocation,
            },
        ],
    };

    return (
        <div className='flex flex_column space_around width_100pr padding_auto overflow_y_hidden'>
            <div className='flex center width_100pr border_radius_15px padding_auto mt_30px'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>

            <div className='text_color_1 width_100pr mt_-10px'>
                {user_free ? (
                    <MetricAnalysisBarUpgrade
                        sliderprops={searchSliderObject["implied_volatility"]}
                        dummy_value={20}
                    />
                ) : (
                    <MetricAnalysisBar
                        sliderprops={searchSliderObject["implied_volatility"]}
                        company_list={company_list}
                        company_allocation={company_allocation}
                    />
                )}
            </div>
        </div>
    );
};

PortfolioRisk.propTypes = {
    company: PropTypes.object.isRequired,
    slider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
    screener: state.screener,
});

export default connect(mapStateToProps, null)(PortfolioRisk);
