import React from "react";
import Preloader from "../../layout/Preloader";
import { useIntl } from "react-intl";
// import * as IoIcons5 from "react-icons/io5";

import SectorTableRow from "./SectorTableRow";
import SectorTableHead from "./SectorTableHead";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SectorTable = ({
    all_sectors,
    sector_object,
    user,
    market_table,
    tableStructure,
}) => {
    const useintl = useIntl();

    let tableBuild = {
        individual: { metrics: ["sector"], headers: ["Sector"] },
        filtered_metrics: { metrics: ["sector"], headers: ["Sector"] },
        value: {
            metrics: [
                "ticker",
                "price_earnings",
                "price_earnings_growth",
                "price_sales",
                "ev_ebitda",
                "price_book",
                "price_cash_flow",
                "revenue_growth_5y",
                "earnings_growth_5y",
                "debt_ratio",
                "ev",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.price_earnings",
                }),
                useintl.formatMessage({
                    id: "metrics.price_earnings_growth",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
                useintl.formatMessage({
                    id: "metrics.ev_ebitda",
                }),
                useintl.formatMessage({
                    id: "metrics.price_book",
                }),
                useintl.formatMessage({
                    id: "metrics.price_cash_flow",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.debt_ratio",
                }),
                useintl.formatMessage({
                    id: "metrics.ev",
                }),
            ],
        },
        growth: {
            metrics: [
                "ticker",
                "revenue_growth_1y",
                "revenue_growth_3y",
                "revenue_growth_5y",
                "revenue_growth_5y_ranker",
                "earnings_growth_1y",
                "earnings_growth_3y",
                "earnings_growth_5y",
                "earnings_growth_5y_ranker",
                "gross_profit_margin",
                "gross_profit_margin_ranker",
                "price_sales",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.revenue_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.revenue_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_1y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin",
                }),
                useintl.formatMessage({
                    id: "metrics.gross_profit_margin_ranker",
                }),
                useintl.formatMessage({
                    id: "metrics.price_sales",
                }),
            ],
        },
        dividends: {
            metrics: [
                "ticker",
                "dividend_yield",
                "dividend_consistency",
                "dividend_growth_3y",
                "earnings_growth_3y",
                "dividend_growth_5y",
                "earnings_growth_5y",
                "payout_ratio",
            ],
            headers: [
                "Ticker",
                useintl.formatMessage({
                    id: "metrics.dividend_yield",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_consistency",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_3y",
                }),
                useintl.formatMessage({
                    id: "metrics.dividend_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.earnings_growth_5y",
                }),
                useintl.formatMessage({
                    id: "metrics.payout_ratio",
                }),
            ],
        },
    };
    // Get Filtered Metrics and Metrics to display in the table
    tableBuild.individual.length = 0;
    tableBuild.filtered_metrics.length = 0;
    tableBuild.individual = { metrics: ["sector"], headers: ["Sector"] };
    tableBuild.filtered_metrics = { metrics: ["sector"], headers: ["Sector"] };

    for (const [key, value] of Object.entries(market_table)) {
        if (value.display) {
            tableBuild.filtered_metrics.metrics.push(key);
            tableBuild.filtered_metrics.headers.push(value.displayName);
        }
        if (value.display) {
            tableBuild.individual.metrics.push(key);
            tableBuild.individual.headers.push(value.displayName);
        }
    }

    let possible_table_structures = [
        "filtered_metrics",
        "value",
        "growth",
        "dividends",
    ];
    let tableValues;
    if (possible_table_structures.includes(tableStructure)) {
        tableValues = tableBuild[tableStructure];
    } else {
        tableValues = tableBuild.filtered_metrics;
    }

    return (
        <div>
            {all_sectors === null ? (
                <Preloader />
            ) : (
                <div className='overflow_x_auto sticky_table max_table_height background_color_2'>
                    <table>
                        {/* Problemtofix: Mobile - Ticker Symbol, Desktop - Company Name */}
                        <thead className='sticky_table_thead background_color_2'>
                            <tr className='sticky_table_thead_tr background_color_2 text_color_1'>
                                <th
                                    className='padding_15_5px min_width_200px background_color_2'
                                    style={{
                                        zIndex: "10",
                                    }}
                                >
                                    Sector
                                </th>
                                {tableValues.metrics.map((metric) =>
                                    metric !== "sector" ? (
                                        <th className='padding_15_5px'>
                                            <SectorTableHead
                                                metric={metric}
                                                market_table={market_table}
                                                user={user.id}
                                            />
                                        </th>
                                    ) : null
                                )}

                                {/* <th
                                    className='padding_15_5px text_right min_width_120px cursor_pointer vertical_align_top'
                                    onClick={() => {
                                        sortTableBy("daily_return");
                                    }}
                                >
                                    {useintl.formatMessage({
                                        id: "metrics.daily_return",
                                    })}
                                </th> */}

                                {/* <th className='padding_15_5px text_right min_width_80px vertical_align_top'>
                                    {useintl.formatMessage({
                                        id: "general.actions",
                                    })}
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {all_sectors.map((sector) => (
                                <SectorTableRow
                                    sector={sector}
                                    sector_object={sector_object}
                                    metrics={tableValues.metrics}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

SectorTable.propTypes = {
    all_sectors: PropTypes.array,
    slider: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    tableStructure: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {})(SectorTable);
