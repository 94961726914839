import {
    GET_COMPANY,
    GET_COMPANY_MONTE_CARLO_PARAMS,
    GET_MONTE_CARLO_BY_PARAMS,
    GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS,
    UPDATE_MONTE_CARLO_PARAM,
    RESET_MONTE_CARLO_PARAMS,
    GET_COMPETITORS,
    GET_COMPETITORS_PAGINATION,
    COMPANY_ERROR,
    SET_COMPANY_LOADING,
    SET_COMPANY_TICKER,
    SET_COMPANY_OPEN_TAB,
    SET_METRIC_DATA,
    SET_CHART_STRUCTURE,
    SET_CHART_METRICS,
    RESET_METRIC_DATA,
    SET_CHART_PER_SHARE,
    SET_CHART_CHANGE_RELATIVE,
    SET_CHART_CHANGE_ABSOLUTE,
    SET_ALERT,
    REMOVE_ALERT,
} from "./Types";

const min = (value1, value2) => {
    if (value1 < value2) {
        return value1;
    } else {
        return value2;
    }
};
function random_triangular(a, b, c) {
    var fc;
    var x;
    var u;
    fc = (c - a) / (b - a);
    u = Math.random();
    if (u < fc) {
        x = (b - a) * (c - a);
        return a + Math.sqrt(x * u);
    }
    x = (b - a) * (b - c);
    return b - Math.sqrt(x * (1.0 - u));
}

function random_normal() {
    return (
        Math.sqrt(-2 * Math.log(1 - Math.random())) *
        Math.cos(2 * Math.PI * Math.random())
    );
}

function linspace(startValue, stopValue, cardinality) {
    var arr = [];
    var step = (stopValue - startValue) / (cardinality - 1);
    for (var i = 0; i < cardinality; i++) {
        arr.push(startValue + step * i);
    }
    return arr;
}

function percentile(arr, q) {
    const sorted = arr.sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
}

function monte_carlo_discounted_cash_flow({
    n_trials,
    n_periods,
    p_0,
    r_0,
    dr_0,
    r_n,
    dr_n,
    S_0,
    m,
    dm,
    terminal_multiple,
    discount_rate,
    cash,
    debt,
}) {
    let r_arr = linspace(r_0, r_n, n_periods);
    let dr_arr = linspace(dr_0, dr_n, n_periods);

    let cash_flow_matrix = [];
    let discounted_cash_flow_matrix = [];
    let fair_value_arr = [];

    for (let i = 0; i < n_trials; i++) {
        let sales = S_0;

        let cash_flow_arr = [];
        let discounted_cash_flow_arr = [];

        let discounted_cash_flow_sum = 0;

        for (let j = 0; j < n_periods; j++) {
            sales = sales * (dr_arr[j] * random_normal() + r_arr[j]);

            let cash_flow = sales * random_triangular(m - dm, m, m + dm);
            cash_flow_arr.push(cash_flow);

            let discounted_cash_flow =
                cash_flow * (1 / (1 + discount_rate) ** (j + 1));
            discounted_cash_flow_arr.push(discounted_cash_flow);

            discounted_cash_flow_sum =
                discounted_cash_flow_sum + discounted_cash_flow;
        }

        cash_flow_matrix.push(cash_flow_arr);
        discounted_cash_flow_matrix.push(discounted_cash_flow_arr);

        let fair_value =
            discounted_cash_flow_sum +
            discounted_cash_flow_arr[n_periods - 1] * terminal_multiple +
            cash -
            debt;

        fair_value_arr.push(fair_value);
    }

    let fair_value = percentile(fair_value_arr, 0.5);
    let p_undervalued = fair_value_arr.filter((x) => x > p_0).length / n_trials;

    let bin_edges = [];
    let p_undervalued_arr = [];
    let hist = [];

    let p_1 = percentile(fair_value_arr, 0.01);
    let p_99 = percentile(fair_value_arr, 0.99);
    let range = p_99 - p_1;

    for (let i = 1; i <= 200; i++) {
        let bin_edge = p_1 + (range / 200) * i;
        bin_edges.push(bin_edge);
        p_undervalued_arr.push(
            fair_value_arr.filter((x) => x > bin_edge).length / n_trials
        );
        hist.push(
            fair_value_arr.filter(
                (x) => x > bin_edge && x <= bin_edge + range / 200
            ).length
        );
    }

    return {
        fair_value: fair_value,
        p_undervalued: p_undervalued,
        cash_flow_matrix: cash_flow_matrix.slice(0, 100),
        mean_cash_flow: cash_flow_matrix
            .reduce((r, a) => r.map((b, i) => b + a[i]))
            .map((x) => x / n_trials),
        discounted_cash_flow_matrix: discounted_cash_flow_matrix.slice(0, 100),
        mean_discounted_cash_flow: discounted_cash_flow_matrix
            .reduce((r, a) => r.map((b, i) => b + a[i]))
            .map((x) => x / n_trials),
        hist: hist,
        bin_edges: bin_edges,
        p_undervalued_arr: p_undervalued_arr,
    };
}

export const setCompanyLoading = () => {
    return {
        type: SET_COMPANY_LOADING,
    };
};

export const setCompanyTicker = (ticker) => {
    return {
        type: SET_COMPANY_TICKER,
    };
};

export const setCompanyOpenTab = (tab) => {
    return {
        type: SET_COMPANY_OPEN_TAB,
        payload: tab,
    };
};

// Get a company by its ticker
export const getCompany = (ticker) => async (dispatch) => {
    try {
        const res = await fetch(`/node_company${ticker}`);
        const data = await res.json();
        if (!data.error) {
            // DO WHAT IS INTENDED

            dispatch({
                type: GET_COMPANY,
                payload: data[0],
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg =
            "An error occured getting the data belonging to this company.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Update a companies monte carlo param
export const updateMonteCarloParam = (key, value) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_MONTE_CARLO_PARAM,
            payload: { key, value },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err.response.statusText,
        });
    }
};

// Set al companies monte carlo params
export const setMonteCarloParams = (params) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_MONTE_CARLO_PARAMS,
            payload: {
                params: params,
            },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err,
        });
    }
};

// Reset a companies monte carlo params
export const resetMonteCarloParams = (params) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_MONTE_CARLO_PARAMS,
            payload: {
                params: params,
            },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err,
        });
    }
};

// Get a companies monte carlo dcf params
export const getMonteCarloParams = (ticker) => async (dispatch) => {
    try {
        const res = await fetch(`/node_company_mc_dcf${ticker}`);
        const data = await res.json();

        if (!data.error) {
            // DO WHAT IS INTENDED
            let data_r_0 = data.annual_revenue_change_average;
            let data_dr_0 = data.annual_revenue_change_std;
            let data_S_0 = data.revenue_per_share;
            let multiple = data.price_ebit_average_10y;
            // let current_multiple = data.price_ebit;
            let current_sector_multiple = data.sector_price_ebit;
            let data_m = data.operating_margin_mid;
            let data_dm = data.operating_margin_range;
            let data_stock_price = data.stock_price;
            let data_discount_rate = data.wacc;
            let data_debt_per_share = data.debt_per_share;
            let data_cash_per_share = data.cash_per_share;

            console.log("SECTOR MULTIPLE: ", current_sector_multiple);

            if (
                !isNaN(data_r_0) &&
                data_r_0 !== null &&
                typeof data_r_0 === "number"
            ) {
                if (data_r_0 < 0.1) {
                    data_r_0 = 0.1;
                } else if (data_r_0 > 5) {
                    data_r_0 = 5;
                }
            } else {
                data_r_0 = 1.3;
            }

            if (
                !isNaN(data_dr_0) &&
                data_dr_0 !== null &&
                typeof data_dr_0 === "number"
            ) {
                if (data_dr_0 < 0.001) {
                    data_dr_0 = 0.001;
                } else if (data_dr_0 > 5) {
                    data_dr_0 = 5;
                }
            } else {
                data_dr_0 = 0.3;
            }

            if (
                !isNaN(data_S_0) &&
                data_S_0 !== null &&
                typeof data_S_0 === "number"
            ) {
                if (data_S_0 <= 0) {
                    data_S_0 = 0.0001;
                } else if (data_S_0 > 100000) {
                    data_S_0 = 100000;
                }
            } else {
                data_S_0 = 10;
            }

            if (
                !isNaN(multiple) &&
                multiple !== null &&
                typeof multiple === "number" &&
                !isNaN(current_sector_multiple) &&
                current_sector_multiple !== null &&
                typeof current_sector_multiple === "number"
            ) {
                let setmultiple = min(current_sector_multiple, multiple);
                if (setmultiple < 0) {
                    multiple = 0.01;
                } else if (setmultiple > 100) {
                    multiple = 100;
                } else {
                    multiple = setmultiple;
                }
            } else if (
                !isNaN(multiple) &&
                multiple !== null &&
                typeof multiple === "number"
            ) {
                if (multiple < 0) {
                    multiple = 0.01;
                } else if (multiple > 100) {
                    multiple = 100;
                }
            } else if (
                !isNaN(current_sector_multiple) &&
                current_sector_multiple !== null &&
                typeof current_sector_multiple === "number"
            ) {
                if (current_sector_multiple < 0) {
                    multiple = 0.01;
                } else if (current_sector_multiple > 100) {
                    multiple = 100;
                } else {
                    multiple = current_sector_multiple;
                }
            } else {
                multiple = 15;
            }

            if (
                !isNaN(data_m) &&
                data_m !== null &&
                typeof data_m === "number"
            ) {
                if (data_m <= 0) {
                    data_m = 0.01;
                } else if (data_m > 1) {
                    data_m = 1;
                }
            } else {
                data_m = 0.3;
            }

            if (
                !isNaN(data_dm) &&
                data_dm !== null &&
                typeof data_dm === "number"
            ) {
                if (data_dm <= 0.001) {
                    data_dm = 0.001;
                } else if (data_dm > 0.5) {
                    data_dm = 0.5;
                }
            } else {
                data_dm = 0.1;
            }

            if (
                !isNaN(data_stock_price) &&
                data_stock_price !== null &&
                typeof data_stock_price === "number"
            ) {
                if (data_stock_price <= 0) {
                    data_stock_price = 100;
                } else if (data_stock_price > 100000) {
                    data_stock_price = 100;
                }
            } else {
                data_stock_price = 100;
            }

            if (
                !isNaN(data_discount_rate) &&
                data_discount_rate !== null &&
                typeof data_discount_rate === "number"
            ) {
                if (data_discount_rate <= 5) {
                    data_discount_rate = 0.05;
                } else if (data_discount_rate > 900) {
                    data_discount_rate = 9;
                } else {
                    data_discount_rate = data_discount_rate / 100;
                }
            } else {
                data_discount_rate = 0.1;
            }

            if (
                !isNaN(data_debt_per_share) &&
                data_debt_per_share !== null &&
                typeof data_debt_per_share === "number"
            ) {
                // if (data_debt_per_share <= 5) {
                //     data_debt_per_share = 0.05;
                // } else if (data_debt_per_share > 900) {
                //     data_debt_per_share = 9;
                // } else {
                data_debt_per_share = data_debt_per_share;
                // }
            } else {
                data_debt_per_share = 0;
            }

            if (
                !isNaN(data_cash_per_share) &&
                data_cash_per_share !== null &&
                typeof data_cash_per_share === "number"
            ) {
                // if (data_cash_per_share <= 5) {
                //     data_cash_per_share = 0.05;
                // } else if (data_cash_per_share > 900) {
                //     data_cash_per_share = 9;
                // } else {
                data_cash_per_share = data_cash_per_share;
                // }
            } else {
                data_cash_per_share = 0;
            }

            let new_monte_carlo_params = {
                n_trials: 100000,
                n_periods: 10,
                r_0: data_r_0,
                dr_0: data_dr_0,
                r_n: 1.04,
                dr_n: 0.16,
                m: data_m,
                dm: data_dm,
                S_0: data_S_0,
                p_0: data_stock_price,
                discount_rate: data_discount_rate,
                terminal_multiple: min(multiple, 100),
                cash: data_cash_per_share,
                debt: data_debt_per_share,
            };

            let varanaut_res_dcf = monte_carlo_discounted_cash_flow({
                n_trials: 100000,
                n_periods: 10,
                r_0: data_r_0,
                dr_0: data_dr_0,
                r_n: 1.04,
                dr_n: 0.16,
                m: data_m,
                dm: data_dm,
                S_0: data_S_0,
                p_0: data_stock_price,
                discount_rate: data_discount_rate,
                terminal_multiple: min(multiple, 100),
                cash: data_cash_per_share,
                debt: data_debt_per_share,
            });

            let varanaut_res_dcf_preview = monte_carlo_discounted_cash_flow({
                n_trials: 250,
                n_periods: 10,
                r_0: data_r_0,
                dr_0: data_dr_0,
                r_n: 1.04,
                dr_n: 0.16,
                m: data_m,
                dm: data_dm,
                S_0: data_S_0,
                p_0: data_stock_price,
                discount_rate: data_discount_rate,
                terminal_multiple: min(multiple, 100),
                cash: data_cash_per_share,
                debt: data_debt_per_share,
            });

            dispatch({
                type: GET_COMPANY_MONTE_CARLO_PARAMS,
                payload: {
                    new_monte_carlo_params,
                    varanaut_res_dcf,
                    varanaut_res_dcf_preview,
                },
            });
        } else {
            // ERROR HANDLING
            const id = Math.floor(Math.random() * 100);
            let type = data.error_type ? data.error_type : "warning";
            let msg = data.error_msg ? data.error_msg : "An error occured.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    } catch (err) {
        const id = Math.floor(Math.random() * 100);
        let type = "warning";
        let msg = "An error occurred in the discounted cash flow analysis.";

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), 3000);
    }
};

// Get a MCDCF by specific parameters
export const getMonteCarloByParams = (params) => async (dispatch) => {
    try {
        let varanaut_res_dcf = monte_carlo_discounted_cash_flow(params);
        console.log("getMonteCarloByParams 1: ", varanaut_res_dcf);

        dispatch({
            type: GET_MONTE_CARLO_BY_PARAMS,
            payload: { varanaut_res_dcf },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err.response.statusText,
        });
    }

    // PREVIEW CHART
    try {
        let varanaut_res_dcf = monte_carlo_discounted_cash_flow({
            ...params,
            n_trials: 100,
        });
        console.log("getMonteCarloByParams 2: ", varanaut_res_dcf);

        dispatch({
            type: GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS,
            payload: { varanaut_res_dcf },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err.response.statusText,
        });
    }
};

// Get a MCDCF by specific parameters with only 500 trials
export const getMonteCarloByParamsEditTrials = (params) => async (dispatch) => {
    try {
        let varanaut_res_dcf = monte_carlo_discounted_cash_flow({
            ...params,
            n_trials: 100,
        });
        console.log("getMonteCarloByParamsEditTrials: ", varanaut_res_dcf);

        dispatch({
            type: GET_MONTE_CARLO_BY_PARAMS_EDIT_TRIALS,
            payload: { varanaut_res_dcf },
        });
    } catch (err) {
        dispatch({
            type: COMPANY_ERROR,
            payload: err.response.statusText,
        });
    }
};

// Get a company by its ticker
export const getCompetitors =
    (ticker, sortBy, sortDir, page) => async (dispatch) => {
        try {
            console.log("ticker: ", ticker);
            const res = await fetch(`/node_competitors${ticker}`, {
                method: "POST",
                body: JSON.stringify({
                    sortBy: sortBy,
                    sortDir: sortDir,
                    page: page,
                }),
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
            });
            const data = await res.json();
            if (!data.error) {
                // DO WHAT IS INTENDED
                if (page === 0) {
                    console.log("data 0: ", data[0]);
                    dispatch({
                        type: GET_COMPETITORS,
                        payload: { data: data, industry: ticker },
                    });
                } else {
                    console.log("data 1: ", data[0]);
                    dispatch({
                        type: GET_COMPETITORS_PAGINATION,
                        payload: { data: data },
                    });
                }
            } else {
                // ERROR HANDLING
                const id = Math.floor(Math.random() * 100);
                let type = data.error_type ? data.error_type : "warning";
                let msg = data.error_msg ? data.error_msg : "An error occured.";

                dispatch({
                    type: SET_ALERT,
                    payload: { msg, type, id },
                });

                setTimeout(
                    () => dispatch({ type: REMOVE_ALERT, payload: id }),
                    3000
                );
            }
        } catch (err) {
            const id = Math.floor(Math.random() * 100);
            let type = "warning";
            let msg =
                "An error occurred when querying the data on competing companies.";

            dispatch({
                type: SET_ALERT,
                payload: { msg, type, id },
            });

            setTimeout(
                () => dispatch({ type: REMOVE_ALERT, payload: id }),
                3000
            );
        }
    };

// Initial: Update all Metrics information => to keep users settings in app state
export const setMetricsReducer = (data, chart_selected) => {
    return {
        type: SET_METRIC_DATA,
        payload: { new_data: data, chart_selected: chart_selected },
    };
};

// Set Metric status to checked/ unchecked
export const changeMetricSelect =
    (all_metrics, metric_to_change, bool, chart_selected, preset) =>
    async (dispatch) => {
        // [{}, {}, ...],
        // revenue_arr,
        // true,
        // ["revenue_arr", "ebit_arr"],
        // "individual",
        // false,
        // false,
        let fundamental_data = all_metrics;
        let new_chart_selected = chart_selected;

        // SET BOOLEAN IN LARGE OBJECT
        fundamental_data.map((metric) => {
            if (metric.property === metric_to_change) {
                metric.selected = bool;
            }
        });

        // PUSH METRIC TO SELECTED PROPERTIES ARRAY
        if (bool) {
            new_chart_selected.push(metric_to_change);
        } else {
            new_chart_selected = chart_selected.filter(
                (metric) => metric !== metric_to_change
            );
        }

        // SUBMIT CHART STRUCTURE FUNCTION
        let metric_data = [];

        if (preset === "individual") {
            fundamental_data.map((metric) => {
                if (new_chart_selected.includes(metric.property)) {
                    metric_data.push(metric);
                }
            });
        } else {
            fundamental_data.map((metric) => {
                if (chartPresets[preset].includes(metric.property)) {
                    metric_data.push(metric);
                }
            });
        }

        dispatch({
            type: SET_CHART_STRUCTURE,
            payload: {
                preset: preset,
                metric_data: metric_data,
                new_chart_selected: new_chart_selected,
            },
        });

        dispatch({
            type: SET_METRIC_DATA,
            payload: {
                new_data: fundamental_data,
                chart_selected: new_chart_selected,
            },
        });
    };

// Set Metric status to checked/ unchecked
export const resetIndividualChart = (all_metrics) => {
    let new_data = all_metrics;
    new_data.map((metric) => {
        metric.selected = false;
    });

    return {
        type: RESET_METRIC_DATA,
        payload: { new_data: new_data },
    };
};

// Update Metrics shown in chart => chart_metrics, only the ones to display
export const submitChartMetrics = (data) => {
    let new_data = [];

    data.map((metric) => (metric.selected ? new_data.push(metric) : null));

    return {
        type: SET_CHART_METRICS,
        payload: new_data,
    };
};

let chartPresets = {
    revenue: [
        "total_revenue_arr",
        "net_income_arr",
        "gross_profit_margin_arr",
        "net_profit_margin_arr",
    ],
    earnings: [
        "free_cashflow_arr",
        "ebit_arr",
        "net_income_arr",
        "dividends_paid_arr",
        "payout_ratio_arr",
    ],
    balance_sheet: [
        "debt_ratio_arr",
        "total_liabilities_arr",
        "total_current_liabilities_arr",
        "total_assets_arr",
        "total_current_assets_arr",
    ],
    valuation: ["price_sales_arr", "price_earnings_arr"],
};

// Update Chart structure Preset
export const submitChartStructure = (preset, data, chart_selected) => {
    let metric_data = [];
    let new_chart_selected = [];
    let fundamental_data = data;

    if (preset === "individual") {
        fundamental_data.map((metric) => {
            if (chart_selected.includes(metric.property)) {
                metric_data.push(metric);
                new_chart_selected.push(metric.property);
            }
        });
    } else {
        fundamental_data.map((metric) => {
            if (chartPresets[preset].includes(metric.property)) {
                metric_data.push(metric);
                new_chart_selected.push(metric.property);
            }
        });
    }

    return {
        type: SET_CHART_STRUCTURE,
        payload: {
            preset: preset,
            metric_data: metric_data,
            new_chart_selected: new_chart_selected,
        },
    };
};

export const setChartPerShare = () => {
    return {
        type: SET_CHART_PER_SHARE,
        // payload: { preset: preset, metric_data: metric_data },
    };
};

export const setChartChangeRelative = () => {
    return {
        type: SET_CHART_CHANGE_RELATIVE,
    };
};

export const setChartChangeAbsolute = () => {
    return {
        type: SET_CHART_CHANGE_ABSOLUTE,
    };
};
