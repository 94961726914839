import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// HIGHCHARTS
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// COMPONENTS
import AdjustYAxisRange from "./AdjustYAxisRange";
import FundamentalsTable from "../table/FundamentalsTable";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { numberFormatter } from "../../../../actions/generalActions";

const getPerShare = (metric_array, shares_outstanding_array) => {
    let per_share_array = [];
    for (let i = 0; i < metric_array.length; i++) {
        if (metric_array[i] !== null && shares_outstanding_array[i] !== null) {
            per_share_array[i] = metric_array[i] / shares_outstanding_array[i];
            // per_share_array[i] = 1;
        } else {
            per_share_array[i] = null;
        }
    }
    return per_share_array;
};

const getChangePercent = (metric_array) => {
    let yoy_array = [];
    for (let i = 0; i < metric_array.length; i++) {
        if (
            metric_array[i] !== null &&
            metric_array[i - 1] &&
            metric_array[i - 1] !== null &&
            metric_array[i - 1] !== 0
        ) {
            if (metric_array[i] >= 0 && metric_array[i - 1] >= 0) {
                yoy_array[i] =
                    (metric_array[i] / metric_array[i - 1] - 1) * 100;
            } else if (metric_array[i] > 0 && metric_array[i - 1] <= 0) {
                yoy_array[i] = -100;
            } else {
                yoy_array[i] = null;
            }
        } else {
            yoy_array[i] = null;
        }
    }
    return yoy_array;
};

const getChangeAbsolute = (metric_array) => {
    let yoy_array = [];
    for (let i = 0; i < metric_array.length; i++) {
        if (
            metric_array[i] !== null &&
            metric_array[i - 1] &&
            metric_array[i - 1] !== null &&
            metric_array[i - 1] !== 0
        ) {
            yoy_array[i] = metric_array[i] - metric_array[i - 1];
        } else {
            yoy_array[i] = null;
        }
    }
    return yoy_array;
};

const Individual = ({
    company: { company },
    whole_statement,
    income_statement_object,
    balance_sheet_object,
    cash_flow_statement_object,
    whole_statement_object,
    allDates,
    selected_date_range,
    chartPerShare,
    chartChangePercent,
    chartChangeAbsolute,
    period,
    ticker,
}) => {
    const useintl = useIntl();

    // HANDLE THE METRICS DISPLAYED IN THE CHARTS
    const [plottedMetrics, setPlottedMetrics] = React.useState(["revenue"]);
    const handleAddPlottedMetric = (new_metric) => {
        const index = plottedMetrics.findIndex((el) => el === new_metric);

        if (index === -1) {
            setPlottedMetrics([...plottedMetrics, new_metric]);
        }
    };
    const handleRemovePlottedMetric = (old_metric) => {
        setPlottedMetrics(plottedMetrics.filter((el) => el !== old_metric));
    };

    // const [chartXAxis, setChartXAxis] = React.useState([]);

    let required_axis = {
        yAxis_0: false,
        yAxis_1: false,
        yAxis_2: false,
        yAxis_3: false,
    };
    // FUNCTION TO PROVIDE THE CHARTS' SERIES, PER SHARE, CHANGE, STH ELSE
    const changeChartOption = () => {
        required_axis = {
            yAxis_0: false,
            yAxis_1: false,
            yAxis_2: false,
            yAxis_3: false,
        };

        // GET REQUESTED PROPERTIES AS ARRAYS
        let properties_object = { xAxis: [] };
        plottedMetrics.map((property) => {
            properties_object[property] = [];
        });

        let shares_outstanding_array = [];
        if (chartPerShare) {
            if (period === "yearly") {
                Object.entries(whole_statement[period]).map((filing) => {
                    shares_outstanding_array = [
                        ...shares_outstanding_array,
                        Number(filing[1]["sharesOutstanding"]),
                    ];
                });
            } else {
                Object.entries(whole_statement["quarterly"]).map((filing) => {
                    shares_outstanding_array = [
                        ...shares_outstanding_array,
                        Number(filing[1]["sharesOutstanding"]),
                    ];
                });
            }
        }

        if (period === "yearly") {
            Object.entries(whole_statement[period]).map((filing) => {
                plottedMetrics.map((property) => {
                    properties_object[property] = [
                        ...properties_object[property],
                        Number(
                            filing[1][
                                whole_statement_object[property].eodproperty
                            ]
                        ),
                    ];
                });
            });
        } else {
            Object.entries(whole_statement["quarterly"]).map((filing) => {
                plottedMetrics.map((property) => {
                    properties_object[property] = [
                        ...properties_object[property],
                        Number(
                            filing[1][
                                whole_statement_object[property].eodproperty
                            ]
                        ),
                    ];
                });
            });
        }

        // IF TTM WITH QUARTERLY AND THEN CALCULATE
        if (period === "ttm") {
            plottedMetrics.map((property) => {
                let ttm_property = [];
                properties_object[property].map((quarter, idx) => {
                    if (idx > 2) {
                        whole_statement_object[property].yAxis === 0
                            ? ttm_property.push(
                                  quarter +
                                      properties_object[property][idx - 1] +
                                      properties_object[property][idx - 2] +
                                      properties_object[property][idx - 3]
                              )
                            : ttm_property.push(quarter);
                    }
                });

                properties_object[property] = ttm_property;
            });
        }

        if (chartPerShare) {
            // console.log("revenue", properties_object["revenue"]);
            // console.log("shares_outstanding", shares_outstanding_array);
            plottedMetrics.map((property) => {
                if (whole_statement_object[property].yAxis === 0) {
                    properties_object[property] = getPerShare(
                        properties_object[property],
                        shares_outstanding_array
                    );
                }
            });
        }

        if (chartChangeAbsolute) {
            plottedMetrics.map((property) => {
                properties_object[property] = getChangeAbsolute(
                    properties_object[property]
                );
            });
        }

        if (chartChangePercent) {
            plottedMetrics.map((property) => {
                properties_object[property] = getChangePercent(
                    properties_object[property]
                );
            });
        }

        // CREATE SERIES ARRAY FOR CHART
        let new_chart_metrics = [];
        plottedMetrics.map((property, idx) => {
            required_axis[
                `yAxis_${whole_statement_object[property].yAxis}`
            ] = true;

            new_chart_metrics = [
                ...new_chart_metrics,
                {
                    ...whole_statement_object[property],
                    data: properties_object[property].slice(
                        selected_date_range[0],
                        selected_date_range[1] + 1
                    ),
                    yAxis: chartChangePercent
                        ? 1
                        : whole_statement_object[property].yAxis,
                    type:
                        chartChangePercent || chartChangeAbsolute
                            ? "column"
                            : "line",
                    lineWidth: 1,
                    a: property,
                    marker: { radius: 2, symbol: "circle" },
                    selected: true,
                },
            ];
        });

        console.log("NEW CHART METRICS: ", new_chart_metrics);

        return new_chart_metrics;
    };

    // useEffect(() => {
    //     // eslint-disable-next-line
    // }, [chartPerShare, chartChangePercent, chartChangeAbsolute]);

    const component_id = "individual";
    const axis_1_description = "Values in USD";
    const axis_2_description = "Values in %";
    const axis_3_description = "Ratios";
    const axis_4_description = "Outstanding Shares";

    const [chartAlignTicks, setChartAlignTicks] = React.useState(true);

    // HANDLE Y AXIS RANGES
    useEffect(() => {
        handleResetAxis();
        // eslint-disable-next-line
    }, [ticker]);

    const [minY0, setminY0] = React.useState(null);
    const [maxY0, setmaxY0] = React.useState(null);
    const changeMinY0 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value.value === "" || isNaN(Number(new_value))) {
            setminY0(null);
            setChartAlignTicks(false);
        } else {
            setminY0(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY0 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY0(null);
            setChartAlignTicks(false);
        } else {
            setmaxY0(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const [minY1, setminY1] = React.useState(null);
    const [maxY1, setmaxY1] = React.useState(null);
    const changeMinY1 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setminY1(null);
            setChartAlignTicks(false);
        } else {
            setminY1(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY1 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY1(null);
            setChartAlignTicks(false);
        } else {
            setmaxY1(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const [minY2, setminY2] = React.useState(null);
    const [maxY2, setmaxY2] = React.useState(null);
    const changeMinY2 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setminY2(null);
            setChartAlignTicks(false);
        } else {
            setminY2(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY2 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY2(null);
            setChartAlignTicks(false);
        } else {
            setmaxY2(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const [minY3, setminY3] = React.useState(null);
    const [maxY3, setmaxY3] = React.useState(null);
    const changeMinY3 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setminY3(null);
            setChartAlignTicks(false);
        } else {
            setminY3(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const changeMaxY3 = (event) => {
        let new_value = event.target.value;
        if (!new_value || new_value === "" || isNaN(Number(new_value))) {
            setmaxY3(null);
            setChartAlignTicks(false);
        } else {
            setmaxY3(Number(new_value));
            setChartAlignTicks(false);
        }
    };
    const handleResetAxis = () => {
        setminY0(null);
        setmaxY0(null);
        setminY1(null);
        setmaxY1(null);
        setminY2(null);
        setmaxY2(null);
        setminY3(null);
        setmaxY3(null);
        try {
            document.getElementById(`${component_id}_minInputY0_field`).reset();
            document.getElementById(`${component_id}_maxInputY0_field`).reset();
            document.getElementById(`${component_id}_minInputY1_field`).reset();
            document.getElementById(`${component_id}_maxInputY1_field`).reset();
            document.getElementById(`${component_id}_minInputY2_field`).reset();
            document.getElementById(`${component_id}_maxInputY2_field`).reset();
            document.getElementById(`${component_id}_minInputY3_field`).reset();
            document.getElementById(`${component_id}_maxInputY3_field`).reset();
        } catch (err) {}
        setChartAlignTicks(true);
    };

    // ARRAYS TO CONTAIN INFORMATION IF THE NUMBER HAS TO BE FORMATTED OR NOT

    let chart_title_period =
        period === "yearly"
            ? useintl.formatMessage({
                  id: `general.yearly`,
              })
            : period === "quarterly"
            ? useintl.formatMessage({
                  id: `general.quarterly`,
              })
            : useintl.formatMessage({
                  id: `general.ttm_short`,
              });

    let chartOptions = {
        chart: {
            height: 500,
            zoomType: "xy",
        },
        title: {
            text: chartChangePercent
                ? `${company.ticker} - ${chart_title_period}, Change [%]`
                : chartChangeAbsolute
                ? `${company.ticker} - ${chart_title_period}, Change [abs]`
                : chartPerShare
                ? `${
                      company.ticker
                  } - ${chart_title_period}, ${useintl.formatMessage({
                      id: `company.per_share`,
                  })}`
                : `${company.ticker} - ${chart_title_period}`,
        },
        subtitle: {
            text: "",
        },
        credits: {
            text: "StocksOnView",
            href: "#",
        },
        xAxis: [
            {
                categories: allDates.slice(
                    selected_date_range[0],
                    selected_date_range[1] + 1
                ),
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                    formatter: function () {
                        return (
                            "<div>" +
                            numberFormatter(this.value) +
                            "<br>USD" +
                            "</div>"
                        );
                    },
                },
                title: {
                    enabled: false,
                    text: `Values in USD`,
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                opposite: false,
                min: minY0,
                max: maxY0,
                // tickInterval: (maxY0 - minY0) / 10,
                alignTicks: chartAlignTicks,
            },
            {
                // Secondary yAxis
                title: {
                    enabled: false,
                    text: `Values in %`,
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                labels: {
                    format: "{value} %",
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                opposite: true,
                min: minY1,
                max: maxY1,
                // tickInterval: (maxY1 - minY1) / 10,
                alignTicks: chartAlignTicks,
            },
            {
                // Ternary yAxis
                title: {
                    enabled: false,
                    text: `Values`,
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                labels: {
                    format: "{value}",
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                opposite: true,
                min: minY2,
                max: maxY2,
                // tickInterval: (maxY2 - minY2) / 10,
                alignTicks: chartAlignTicks,
            },
            {
                // 4th yAxis
                title: {
                    enabled: false,
                    text: `Values`,
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().yAxis.title.style.color,
                    },
                    formatter: function () {
                        return numberFormatter(this.value);
                    },
                },
                opposite: false,
                min: minY3,
                max: maxY3,
                // tickInterval: (maxY3 - minY3) / 10,
                alignTicks: chartAlignTicks,
            },
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                var points = this.points;
                var pointsLength = points.length;
                var tooltipMarkup = pointsLength
                    ? '<span style="font-size: 10px">' +
                      points[0].key +
                      "</span><br/>"
                    : "";
                var index;
                var value;

                for (index = 0; index < pointsLength; index += 1) {
                    if (chartChangePercent) {
                        value = numberFormatter(points[index].y);

                        tooltipMarkup +=
                            '<span style="color:' +
                            points[index].series.color +
                            '">\u25CF</span> ' +
                            points[index].series.name +
                            ": <b>" +
                            value +
                            ` %</b><br/>`;
                    } else {
                        if (
                            points[index].point.series.userOptions.yAxis === 0
                        ) {
                            value = numberFormatter(points[index].y);

                            tooltipMarkup +=
                                '<span style="color:' +
                                points[index].series.color +
                                '">\u25CF</span> ' +
                                points[index].series.name +
                                ": <b>" +
                                value +
                                ` ${company.currency}</b><br/>`;
                        } else if (
                            points[index].point.series.userOptions.yAxis === 1
                        ) {
                            value = points[index].y;

                            tooltipMarkup +=
                                '<span style="color:' +
                                points[index].series.color +
                                '">\u25CF</span> ' +
                                points[index].series.name +
                                ": <b>" +
                                value +
                                " %</b><br/>";
                        } else if (
                            points[index].point.series.userOptions.yAxis === 3
                        ) {
                            value = numberFormatter(points[index].y);

                            tooltipMarkup +=
                                '<span style="color:' +
                                points[index].series.color +
                                '">\u25CF</span> ' +
                                points[index].series.name +
                                ": <b>" +
                                value +
                                "</b><br/>";
                        } else if (
                            points[index].point.series.userOptions.yAxis === 2
                        ) {
                            value = points[index].y;

                            tooltipMarkup +=
                                '<span style="color:' +
                                points[index].series.color +
                                '">\u25CF</span> ' +
                                points[index].series.name +
                                ": <b>" +
                                value +
                                "</b><br/>";
                        } else {
                            value = points[index].y;

                            tooltipMarkup +=
                                '<span style="color:' +
                                points[index].series.color +
                                '">\u25CF</span> ' +
                                points[index].series.name +
                                ": <b>" +
                                value +
                                "</b><br/>";
                        }
                    }
                }
                return tooltipMarkup;
            },
        },
        legend: {
            align: "center",
            verticalAlign: "bottom",
            x: 0,
            y: 0,
            backgroundColor: "rgba(255,255,255,0)",
        },
        series: changeChartOption(),
    };

    return (
        <div className='text_color_1'>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <AdjustYAxisRange
                component_id={component_id}
                handleResetAxis={handleResetAxis}
                axis_1_description={axis_1_description}
                minY0={minY0 ? minY0 : null}
                changeMinY0={changeMinY0}
                maxY0={maxY0 ? maxY0 : null}
                changeMaxY0={changeMaxY0}
                axis_2_description={axis_2_description}
                minY1={minY1 ? minY1 : null}
                changeMinY1={changeMinY1}
                maxY1={maxY1 ? maxY1 : null}
                changeMaxY1={changeMaxY1}
                axis_3_description={axis_3_description}
                minY2={minY2 ? minY2 : null}
                changeMinY2={changeMinY2}
                maxY2={maxY2 ? maxY2 : null}
                changeMaxY2={changeMaxY2}
                axis_4_description={axis_4_description}
                minY3={minY3 ? minY3 : null}
                changeMinY3={changeMinY3}
                maxY3={maxY3 ? maxY3 : null}
                changeMaxY3={changeMaxY3}
                required_axis={required_axis}
            />
            <div className='mt_25px width_100pr'>
                <FundamentalsTable
                    period={period}
                    whole_statement={whole_statement}
                    income_statement_object={income_statement_object}
                    balance_sheet_object={balance_sheet_object}
                    cash_flow_statement_object={cash_flow_statement_object}
                    selected_date_range={selected_date_range}
                    plottedMetrics={plottedMetrics}
                    handleAddPlottedMetric={handleAddPlottedMetric}
                    handleRemovePlottedMetric={handleRemovePlottedMetric}
                />{" "}
            </div>
        </div>
    );
};

Individual.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(Individual);
