import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// Components
import Preloader from "../../layout/Preloader";

// Actions
import { getSingleHistogramData } from "../../../actions/screenerAction";
import { numberFormatter } from "../../../actions/generalActions";

// Redux, App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";
// import { RiContactsBookLine } from "react-icons/ri";

bellcurve(Highcharts);

const MetricHistogramSimple = ({
    sliderprops,
    getSingleHistogramData,
    histogram,
}) => {
    let data;
    const useintl = useIntl();
    useEffect(() => {
        if (histogram.statistics) {
            if (sliderprops.property !== histogram.statistics.metric) {
                getSingleHistogramData(sliderprops.property);
                data = histogram.values;
            } else {
                data = histogram.values;
            }
        } else {
            getSingleHistogramData(sliderprops.property);
            data = histogram.values;
        }
        // eslint-disable-next-line
    }, []);

    let histogram_legend = {
        blue: useintl.formatMessage({
            id: `histogram.number_of_companies_in_a_given_range`,
        }),
        grey: useintl.formatMessage({
            id: `histogram.selected_area_by_the_slider`,
        }),
        red: useintl.formatMessage({
            id: `histogram.median_value`,
        }),
    };

    if (
        !histogram.values ||
        histogram.statistics.metric !== sliderprops.property
    ) {
        return <Preloader />;
    } else {
        let chartOptions = {
            title: {
                text: useintl.formatMessage({
                    id: `general.frequency_distribution`,
                }),
                visible: false,
            },
            credits: {
                enabled: false,
            },

            xAxis: [
                {
                    title: { text: sliderprops.displayName },
                    alignTicks: false,
                    opposite: true,
                },
                {
                    alignTicks: false,
                    plotLines: [
                        {
                            color: "red", // Color value
                            dashStyle: "longdashdot", // Style of the plot line. Default to solid
                            value: histogram.statistics.quantile_50, // Value of where the line will appear
                            width: 2, // Width of the line
                            zIndex: 2,
                        },
                    ],
                },
            ],

            yAxis: [
                {
                    title: { text: "Data" },
                    visible: false,
                    opposite: true,
                    showInLegend: false,
                },
                {
                    title: {
                        text: useintl.formatMessage({
                            id: `histogram.number_of_companies`,
                        }),
                    },
                },
            ],

            plotOptions: {
                histogram: {
                    accessibility: {
                        pointDescriptionFormatter: function (point) {
                            var ix = point.index + 1,
                                x1 = point.x.toFixed(3),
                                x2 = point.x2.toFixed(3),
                                val = point.y;
                            return (
                                ix + ". " + x1 + " to " + x2 + ", " + val + "."
                            );
                        },
                    },
                },
            },
            tooltip: {
                pointFormat: `Companies: <b>{point.y}</b></br>Range: <b>{point.x}</b> to <b>{point.x2}</b>`,
                shared: false,
                valueDecimals: 2,
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    type: "histogram",
                    xAxis: 1,
                    yAxis: 1,
                    // binWidth: histogram.statistics.bin_width,
                    // binsNumber: 50,
                    baseSeries: "s1",
                    zIndex: -1,
                },
                {
                    name: "Data",
                    type: "scatter",
                    data: histogram.values,
                    visible: false,
                    showInLegend: false,
                    id: "s1",
                    marker: {
                        radius: 1.5,
                    },
                },
            ],
        };
        return (
            <div>
                <div className='divider_no_margin'></div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
                <div className='divider_no_margin'></div>
                <div className='histogram_legend ml_20px'>
                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_blue'></div>
                        <div className='ml_10px'> {histogram_legend.blue}</div>
                    </div>

                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_grey'></div>
                        <div className='ml_10px'> {histogram_legend.grey}</div>
                    </div>
                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_red'></div>
                        <div className='ml_10px'> {histogram_legend.red}</div>
                    </div>
                </div>
                <div className='divider_no_margin'></div>

                {/* STATISTICS SECTION */}
                <div className='slider_info_typical_values'>
                    <div className='title_5'>
                        {useintl.formatMessage({
                            id: `general.statistics`,
                        })}
                    </div>
                    <table class='mt_5px'>
                        <thead>
                            <tr>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_25`,
                                    })}
                                </th>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.median`,
                                    })}
                                </th>
                                {/* <th className='text_left'>Mean</th> */}
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_75`,
                                    })}{" "}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='histogram_statistics_table_body_row'>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd"
                                        ? numberFormatter(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_25
                                              ).toFixed(2)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_25
                                          ).toFixed(2) + ` ${sliderprops.unit}`}
                                </td>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd"
                                        ? numberFormatter(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_50
                                              ).toFixed(2)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_50
                                          ).toFixed(2) + ` ${sliderprops.unit}`}
                                </td>
                                {/* <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" || sliderprops.property === "market_cap_usd"
                                        ? numberFormatter(
                                              Number(
                                                  histogram.statistics.mean
                                              ).toFixed(2)
                                          ) + `${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.mean
                                          ).toFixed(2) + `${sliderprops.unit}`}
                                </td> */}
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd"
                                        ? numberFormatter(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_75
                                              ).toFixed(2)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_75
                                          ).toFixed(2) + ` ${sliderprops.unit}`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='divider_no_margin'></div>
                <div className='margin_10px'>
                    <IoIcons5.IoBookOutline className='mr_10px' />
                    Frequency distribution is the term generally used to
                    describe the numbers that indicate how often a particular
                    value has occurred in a statistical analysis. In the chart
                    above, you can see how often a certain range occurs in our
                    database, based on a key figure. In other words, how many
                    companies have this key figure in the specified range.
                </div>
                <div className='margin_10px'>
                    <BsIcons.BsExclamationTriangle className='mr_10px' />
                    The data shown above represents all the companies of our
                    database. Minimum and maximum values are capped in both
                    directions.
                </div>
            </div>
        );
    }
};

MetricHistogramSimple.propTypes = {
    histogram: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    histogram: state.screener.histogram,
});

export default connect(mapStateToProps, {
    getSingleHistogramData,
})(MetricHistogramSimple);
