import React, { useRef } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";

// COMPONENTS
import SearchBarResult from "../../layout/sidebar/SearchBarResult";
import Preloader from "../../layout/Preloader";

// MUI
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { getSearchBarCompanies } from "../../../actions/screenerAction";
import {
    updatePortfolioBenchmarks,
    openPortfolioChanges,
} from "../../../actions/portfolioActions";

let icon_storage = [
    { name: "rocket", icon: <IoIcons5.IoRocketOutline /> },
    { name: "cutlery", icon: <IoIcons5.IoRestaurantOutline /> },
    { name: "gear_wheel", icon: <IoIcons5.IoSettingsOutline /> },
    { name: "t_shirt", icon: <IoIcons5.IoShirtOutline /> },
    { name: "store", icon: <IoIcons5.IoStorefrontOutline /> },
    { name: "sun", icon: <IoIcons5.IoSunnyOutline /> },
    { name: "stock_up", icon: <IoIcons5.IoTrendingUpOutline /> },
    { name: "stock_down", icon: <IoIcons5.IoTrendingDownOutline /> },
    { name: "trash_can", icon: <IoIcons5.IoTrashOutline /> },
    { name: "globe", icon: <IoIcons5.IoEarthSharp /> },
    { name: "burger", icon: <IoIcons5.IoFastFoodOutline /> },
    { name: "healthcare", icon: <IoIcons5.IoFitnessOutline /> },
    { name: "lightning", icon: <IoIcons5.IoFlashOutline /> },
    { name: "console", icon: <IoIcons5.IoGameControllerOutline /> },
    { name: "hammer", icon: <IoIcons5.IoHammerOutline /> },
    { name: "house", icon: <IoIcons5.IoHomeOutline /> },
    { name: "payment", icon: <IoIcons5.IoJournalOutline /> },
    { name: "saturn", icon: <IoIcons5.IoPlanetOutline /> },
    { name: "thumn_up", icon: <IoIcons5.IoThumbsUpOutline /> },
    { name: "collection", icon: <BsIcons.BsCollection /> },
    { name: "wifi", icon: <IoIcons5.IoWifiOutline /> },
];

const Benchmark = ({
    portfolio_id,
    current_portfolio,
    portfolio,
    screener: { searchbar_companies },
    getSearchBarCompanies,
    updatePortfolioBenchmarks,
    openPortfolioChanges,
}) => {
    const useintl = useIntl();
    const ref = useRef();

    const [openBenchmarkSelection, setOpenBenchmarkSelection] =
        React.useState(true);
    const handleOpenBenchmarkSelection = () => {
        setOpenBenchmarkSelection(!openBenchmarkSelection);
    };

    const [benchmarkPortfolios, setBenchmarkPortfolios] = React.useState([]); // IDs
    // [{ticker: "", name: ""}]
    const handleSetBenchmarkPortfolios = (new_benchmark) => {
        const index = benchmarkPortfolios.findIndex(
            (object) => object.ticker === new_benchmark.ticker
        );

        if (index === -1) {
            setBenchmarkPortfolios([...benchmarkPortfolios, new_benchmark]);
        }
    };

    const [benchmarkPrices, setBenchmarkPrices] = React.useState([]); // TICKER
    const handleSetBenchmarkPrices = (new_benchmark) => {
        const index = benchmarkPrices.findIndex(
            (object) => object.ticker === new_benchmark.ticker
        );

        if (index === -1) {
            setBenchmarkPrices([...benchmarkPrices, new_benchmark]);
        }
    };

    const possibleBenchmarkIndices = [
        { ticker: "GSPC.INDX", name: "S&P 500 Index" },
    ];
    const [benchmarkIndices, setBenchmarkIndices] = React.useState([]); // TICKER
    const handleSetBenchmarkIndices = (new_benchmark) => {
        const index = benchmarkIndices.findIndex(
            (object) => object.ticker === new_benchmark.ticker
        );

        if (index === -1) {
            setBenchmarkIndices([...benchmarkIndices, new_benchmark]);
        }
    };

    const handleRemoveBenchmark = (benchmark) => {
        setBenchmarkPortfolios(
            benchmarkPortfolios.filter((el) => el.ticker !== benchmark.ticker)
        );
        setBenchmarkPrices(
            benchmarkPrices.filter((el) => el.ticker !== benchmark.ticker)
        );
        setBenchmarkIndices(
            benchmarkIndices.filter((el) => el.ticker !== benchmark.ticker)
        );
    };

    const handleUpdateBenchmarks = () => {
        openPortfolioChanges(true, "Benchmark");
        updatePortfolioBenchmarks({
            portfolios: benchmarkPortfolios,
            companies: benchmarkPrices,
            indices: benchmarkIndices,
            user_portfolio: {
                companies: current_portfolio.assets,
                transactions_tracker: current_portfolio.transactions,
                risk_free_rate: portfolio.risk_free_rate,
                performance: current_portfolio.performance_tracker.performance,
                invested_sum:
                    current_portfolio.performance_tracker.invested_sum,
            },
        });
    };

    // SEARCH COMPANIES FIELD
    const [showCompanies, setShowCompanies] = React.useState(false);
    const handleHideCompanies = () => {
        setShowCompanies(false);
    };
    React.useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                showCompanies &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                setShowCompanies(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showCompanies]);

    const [searchBarValue, setSearchBarValue] = React.useState("");
    const handleSearchBarValue = (event) => {
        let str = event.target.value.replace(/[^a-zA-Z0-9.]/g, "");
        setSearchBarValue(str);
        if (str.length >= 1) {
            getSearchBarCompanies(str);
        } else {
            getSearchBarCompanies(null);
        }
    };
    const clearSearchBarValue = () => {
        setSearchBarValue("");
        document.getElementById("searchbar_input_field").reset();
        getSearchBarCompanies(null);
    };

    console.log(
        "BENCHMARS: ",
        benchmarkPortfolios.concat(benchmarkPrices).concat(benchmarkIndices)
    );

    return (
        <div className='width_100pr padding_20px'>
            <div className='width_100pr text_color_1 background_color_2 border_radius_15px'>
                <div
                    className='width_100pr padding_10px text_1 flex space_between cursor_pointer'
                    onClick={handleOpenBenchmarkSelection}
                >
                    <div>Select your benchmarks</div>
                    {openBenchmarkSelection ? (
                        <IoIcons5.IoChevronUpSharp className='text_2' />
                    ) : (
                        <IoIcons5.IoChevronDownSharp className='text_2' />
                    )}
                </div>

                {/* MAKE BENCHMARK SELECTION */}
                {openBenchmarkSelection ? (
                    <div className='width_100pr padding_10px flex left wrap'>
                        <div className='width_50pr flex flex_column mt_10px'>
                            <div className='width_100pr pl_10px'>Indices</div>
                            {/* Indices DROPDOWN */}
                            <div className='width_100pr flex left mt_5px ps_20px'>
                                <div className='width_100pr'>
                                    <div className='width_100pr'>
                                        <FormControl fullWidth size='small'>
                                            <Select
                                                id='demo-simple-select'
                                                className='background_color_3 text_color_1'
                                                value={null}
                                                // defaultValue={benchmarkPrices}
                                                // onChange={handleLanguageChange}
                                                inputProps={{
                                                    "aria-label":
                                                        "Without label",
                                                }}
                                            >
                                                <MenuItem disabled value=''>
                                                    <em>Select Index</em>
                                                </MenuItem>

                                                {possibleBenchmarkIndices.map(
                                                    (index) => (
                                                        <MenuItem
                                                            key={index.ticker}
                                                            name={index.name}
                                                            value={index.ticker}
                                                            onClick={() => {
                                                                handleSetBenchmarkIndices(
                                                                    {
                                                                        ticker: index.ticker,
                                                                        name: index.name,
                                                                        type: "index",
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    index.name
                                                                }
                                                            />
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='width_50pr flex flex_column mt_10px'>
                            <div className='width_100pr pl_10px'>Companies</div>
                            {/* SEARCHBAR COMPANIES */}
                            <div className='width_100pr mt_5px'>
                                <div className='pl_15px pr_15px'>
                                    <div
                                        className={
                                            searchbar_companies !== null &&
                                            searchbar_companies.length > 0 &&
                                            showCompanies
                                                ? "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark mt_29px"
                                                : "height_100pr width_100pr flex flex_column align_items_start center border_box border_radius_5px shadow_dark"
                                        }
                                        ref={ref}
                                    >
                                        <div
                                            className={
                                                searchbar_companies !== null &&
                                                searchbar_companies.length >
                                                    0 &&
                                                showCompanies
                                                    ? "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px_top height_30px"
                                                    : "background_color_3 width_100pr flex left height_80pr padding_5px border_box input_basic border_radius_5px height_30px"
                                            }
                                        >
                                            <div className='mr_5px text_2 color_white height_100pr'>
                                                <AiIcons.AiOutlineSearch />
                                            </div>
                                            <form id='searchbar_input_field'>
                                                <input
                                                    type='text'
                                                    className={
                                                        searchBarValue !== ""
                                                            ? "text_color_1 text_2 ml_5px"
                                                            : "text_color_2 text_2 ml_5px"
                                                    }
                                                    placeholder={
                                                        searchBarValue !== ""
                                                            ? searchBarValue
                                                            : useintl.formatMessage(
                                                                  {
                                                                      id: "general.search",
                                                                  }
                                                              ) + "..."
                                                    }
                                                    onKeyUp={(event) => {
                                                        handleSearchBarValue(
                                                            event
                                                        );
                                                    }}
                                                    onClick={() =>
                                                        setShowCompanies(
                                                            (oldState) =>
                                                                !oldState
                                                        )
                                                    }
                                                />
                                            </form>
                                        </div>
                                        {searchbar_companies !== null &&
                                        searchbar_companies.length > 0 &&
                                        showCompanies ? (
                                            <div className='flex flex_column align_items_start width_100pr min_width_250px height_100pr'>
                                                {searchbar_companies.map(
                                                    (company) => (
                                                        <div
                                                            onClick={() => {
                                                                handleHideCompanies();
                                                                handleSetBenchmarkPrices(
                                                                    {
                                                                        ticker: company.ticker,
                                                                        name: company.name,
                                                                        type: "stock",
                                                                    }
                                                                );
                                                                clearSearchBarValue();
                                                            }}
                                                            className='width_100pr min_width_250px cursor_pointer'
                                                        >
                                                            <SearchBarResult
                                                                search_bar_company={
                                                                    company
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : // <div className='flex flex_column align_items_start width_100pr min_width_250px'></div>
                                        null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='width_50pr flex flex_column mt_10px'>
                            <div className='width_100pr pl_10px'>
                                Portfolios
                            </div>
                            {/* PORTFOLIOS DROPDOWN */}
                            <div className='width_100pr flex left mt_5px ps_20px'>
                                <div className='width_100pr'>
                                    <div className='width_100pr'>
                                        <FormControl fullWidth size='small'>
                                            <Select
                                                id='demo-simple-select'
                                                className='background_color_3 text_color_1'
                                                value={null}
                                                // defaultValue={benchmarkPrices}
                                                // onChange={handleLanguageChange}
                                                inputProps={{
                                                    "aria-label":
                                                        "Without label",
                                                }}
                                            >
                                                <MenuItem disabled value=''>
                                                    <em>Select Portfolio</em>
                                                </MenuItem>

                                                {portfolio.portfolios.map(
                                                    (port) =>
                                                        port.id !==
                                                            portfolio_id &&
                                                        port.transactions ? (
                                                            <MenuItem
                                                                key={port.id}
                                                                name={port.name}
                                                                value={port.id}
                                                                onClick={() => {
                                                                    handleSetBenchmarkPortfolios(
                                                                        {
                                                                            ticker: port.id,
                                                                            name: port.name,
                                                                            type: "portfolio",
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                <div className='mr_10px'>
                                                                    {icon_storage.map(
                                                                        (
                                                                            icon
                                                                        ) =>
                                                                            icon.name ===
                                                                            port.icon
                                                                                ? icon.icon
                                                                                : null
                                                                    )}
                                                                </div>
                                                                <ListItemText
                                                                    primary={
                                                                        port.name
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        ) : null
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* LIST OF SELECTED BENCHMARKS */}
                <div className='width_100pr ps_10px flex wrap space_between'>
                    {benchmarkPortfolios
                        .concat(benchmarkPrices)
                        .concat(benchmarkIndices).length > 0 ? (
                        <div className='border_radius_15px padding_10px flex wrap'>
                            {benchmarkPortfolios
                                .concat(benchmarkPrices)
                                .concat(benchmarkIndices)
                                .map((benchmark, idx) => {
                                    return (
                                        <div
                                            className='background_color_4 flex border_radius_15px cursor_pointer shadow_dark mr_10px space_between margin_5px hover_opacity_80'
                                            // onClick={handleRemoveBenchmark(benchmark)}
                                        >
                                            <div
                                                className={
                                                    "padding_5px border_radius_15px_left " +
                                                    `highcharts_color_${
                                                        idx + 2
                                                    }`
                                                }
                                            >
                                                {benchmark.type === "index" ? (
                                                    <MdIcons.MdEqualizer
                                                        alt='Index Icon'
                                                        className='text_color_black'
                                                    />
                                                ) : benchmark.type ===
                                                  "stock" ? (
                                                    <MdIcons.MdBusiness
                                                        alt='Company Icon'
                                                        className='text_color_black'
                                                    />
                                                ) : benchmark.type ===
                                                  "portfolio" ? (
                                                    <RiIcons.RiDonutChartFill
                                                        alt='Portfolio Icon'
                                                        className='text_color_black'
                                                    />
                                                ) : null}
                                            </div>
                                            <div className='pr_5px pl_10px'>
                                                {benchmark.name}
                                            </div>
                                            <AiIcons.AiOutlineClose
                                                className='ms_10px'
                                                onClick={() => {
                                                    handleRemoveBenchmark(
                                                        benchmark
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {openBenchmarkSelection ? (
                        <div className='padding_10px flex right'>
                            <div
                                className='button_blue padding_10px'
                                onClick={handleUpdateBenchmarks}
                            >
                                Submit
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                {/* <div className='divider_no_margin'></div> */}
                {portfolio.changes &&
                portfolio.changes_component === "Benchmark" ? (
                    <Preloader />
                ) : null}
                <div className='mb_20px'></div>
            </div>
        </div>
    );
};

Benchmark.propTypes = {
    searchbar_companies: PropTypes.object.isRequired,
    getSearchBarCompanies: PropTypes.func.isRequired,
    portfolio: PropTypes.object.isRequired,
    updatePortfolioBenchmarks: PropTypes.func.isRequired,
    openPortfolioChanges: PropTypes.func.isRequired,
    // portfolio: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    screener: state.screener,
    portfolio: state.portfolio,
    // user: state.user,
});

export default connect(mapStateToProps, {
    getSearchBarCompanies,
    updatePortfolioBenchmarks,
    openPortfolioChanges,
})(Benchmark);
