import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

// ICONS
import * as MdIcons from "react-icons/md";
import * as IoIcons5 from "react-icons/io5";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// COMPONENTS
import CompanyActionsMenu from "../elements/CompanyActionsMenu";

const PortfolioManagerIdeas = ({
    user,
    portfolio_diversification,
    portfolio_strategy_fit,
}) => {
    // useEffect(() => {
    //     // eslint-disable-next-line
    // }, []);

    const useintl = useIntl();

    return (
        <div className='background_color_1 text_color_1'>
            <div className='flex wrap width_100pr space_around'>
                <div className='shadow_dark width_48pr_95pr background_color_2 border_radius_15px mt_20px padding_10px mb_20px'>
                    <div className='text_2 pl_10px pb_10px'>
                        These companies would diversify your portfolio
                    </div>
                    <div className='divider_no_margin'></div>
                    {user.status === 0 ? (
                        <div className='pos_relative width_100pr'>
                            <div className='blur width_100pr text_3 mt_10px'>
                                <div className='width_90pr ms_auto flex space_between'>
                                    <Link to={`/company?ticker=GME`}>
                                        A company here
                                    </Link>

                                    <div className='text_right text_color_1 width_20px'>
                                        <IoIcons5.IoEllipsisVerticalSharp className='ml_5px mr_5px cursor_pointer text_1' />
                                    </div>
                                </div>
                                <div className='width_90pr ms_auto flex space_between'>
                                    <Link to={`/company?ticker=GME`}>
                                        A company there
                                    </Link>
                                    {/* Actions */}
                                    <div className='text_right text_color_1 width_20px'>
                                        <IoIcons5.IoEllipsisVerticalSharp className='ml_5px mr_5px cursor_pointer text_1' />
                                    </div>
                                </div>
                                <div className='width_90pr ms_auto flex space_between'>
                                    <Link to={`/company?ticker=GME`}>
                                        A company anywhere
                                    </Link>
                                    {/* Actions */}
                                    <div className='text_right text_color_1 width_20px'>
                                        <IoIcons5.IoEllipsisVerticalSharp className='ml_5px mr_5px cursor_pointer text_1' />
                                    </div>
                                </div>
                            </div>
                            <div className='pos_absolute width_100pr mt_-70px flex center'>
                                <div className='flex flex_column center width_220px height_70px background_color_3 border_radius_15px shadow_dark'>
                                    <Link
                                        to={`/account`}
                                        className='hover_opacity_80'
                                    >
                                        Upgrade
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='text_3 mt_10px'>
                            {Array.isArray(portfolio_diversification)
                                ? portfolio_diversification.map(function (
                                      company,
                                      index
                                  ) {
                                      return (
                                          <div className='width_90pr ms_auto flex space_between'>
                                              <Link
                                                  to={`/company?ticker=${company.ticker}`}
                                              >
                                                  {company.name}
                                              </Link>
                                              {/* Actions */}
                                              <div className='text_right text_color_1'>
                                                  <CompanyActionsMenu
                                                      company={company}
                                                      recommendations={true}
                                                  />
                                              </div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    )}
                </div>
                <div className='shadow_dark width_48pr_95pr background_color_2 border_radius_15px mt_20px padding_10px mb_20px'>
                    <div className='text_2 pl_10px pb_10px'>
                        Investors with similar portfolios are also interest in
                    </div>
                    <div className='divider_no_margin'></div>
                    <div className='text_3 mt_10px'>
                        {Array.isArray(portfolio_strategy_fit)
                            ? portfolio_strategy_fit.map(function (
                                  company,
                                  index
                              ) {
                                  return (
                                      <div className='width_90pr ms_auto flex space_between hover_pointer'>
                                          <Link
                                              to={`/company?ticker=${company.ticker}`}
                                          >
                                              {company.name}
                                          </Link>
                                          <div className='text_right text_color_1'>
                                              <CompanyActionsMenu
                                                  recommendations={true}
                                                  company={company}
                                              />
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>

            <div className='flex center background_color_1 text_2 text_color_1'>
                <div className='mb_15px mt_15px ml_10px mr_10px'>
                    <MdIcons.MdWarningAmber className='mr_10px mt_10px' />
                    The above companies are not intended as investment advice
                    but solely as an additional approach to search the stock
                    market for companies.
                </div>
            </div>
        </div>
    );
};

PortfolioManagerIdeas.propTypes = {
    user: PropTypes.object.isRequired,
    portfolio_diversification: PropTypes.array.isRequired,
    portfolio_strategy_fit: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {})(PortfolioManagerIdeas);
