import React from "react";

// COMPONENTS
import TransactionRow from "./transactions/TransactionRow";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ACTIONS
import { sort_array_of_objects_by_value_number } from "../../../actions/generalActions";

// REARRANGE TRANSACTIONS BY TIME
const rearrangeTransactionsByTime = (transactions) => {
    // {
    //     2020: {
    //         12: [],
    //         6: [],
    //         ...
    //     },
    //     ....
    // }
    let sorted_transactions = {};
    Object.keys(transactions).map((company) => {
        transactions[company].map((transaction) => {
            let date = new Date(transaction.date);
            let year = date.getFullYear();
            let month = date.toLocaleString("default", { month: "long" });
            transaction["ticker"] = company;
            if (
                sorted_transactions[year] &&
                sorted_transactions[year][`${month}`]
            ) {
                sorted_transactions = {
                    ...sorted_transactions,
                    [year]: {
                        ...sorted_transactions[year],
                        [`${month}`]: [
                            ...sorted_transactions[year][`${month}`],
                            transaction,
                        ],
                    },
                };
            } else if (sorted_transactions[year]) {
                sorted_transactions = {
                    ...sorted_transactions,
                    [year]: {
                        ...sorted_transactions[year],
                        [`${month}`]: [transaction],
                    },
                };
            } else {
                sorted_transactions = {
                    ...sorted_transactions,
                    [year]: {
                        [`${month}`]: [transaction],
                    },
                };
            }
        });
    });

    Object.keys(sorted_transactions).map((year) => {
        Object.keys(sorted_transactions[year]).map((month) => {
            sorted_transactions[year][month] =
                sort_array_of_objects_by_value_number(
                    sorted_transactions[year][month],
                    "date"
                );
        });
    });

    return sorted_transactions;
};

const Transactions = ({
    transactions,
    portfolio_id,
    user_id,
    portfolio_user_id,
    risk_free_rate,
}) => {
    const sorted_transactions = rearrangeTransactionsByTime(transactions);

    return (
        <div>
            {/* MAP SORTED TRANSACTIONS */}
            <div className='width_100pr'>
                {Object.keys(sorted_transactions)
                    .reverse()
                    .map((year) => {
                        return (
                            <div className='width_100pr ps_20px mt_30px'>
                                <div className='width_100pr padding_15px text_1 border_radius_15px background_color_3'>
                                    {year}
                                </div>
                                {Object.keys(sorted_transactions[year])
                                    .reverse()
                                    .map((month) => {
                                        return (
                                            <div className='width_100pr mt_10px padding_10px text_2 border_radius_15px background_color_2'>
                                                {month}
                                                {sorted_transactions[year][
                                                    month
                                                ]
                                                    .reverse()
                                                    .map((ta) => {
                                                        return (
                                                            <TransactionRow
                                                                transaction={ta}
                                                                portfolio_id={
                                                                    portfolio_id
                                                                }
                                                                user_id={
                                                                    user_id
                                                                }
                                                                portfolio_user_id={
                                                                    portfolio_user_id
                                                                }
                                                                risk_free_rate={
                                                                    risk_free_rate
                                                                }
                                                            />
                                                        );
                                                    })}
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

Transactions.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(Transactions);
