import React, { useEffect } from "react";
import { useIntl } from "react-intl";

// ICONS
import * as BsIcons from "react-icons/bs";
import * as IoIcons5 from "react-icons/io5";

// Components
import Preloader from "../../layout/Preloader";
import RangeSliderScreenerMinPopover from "../screener/RangeSliderScreenerMinPopover";
import RangeSliderScreenerMaxPopover from "../screener/RangeSliderScreenerMaxPopover";

// Actions
import {
    getSingleHistogramData,
    updateSingleSliderValueOnly,
} from "../../../actions/screenerAction";
import { numberFormatterfour } from "../../../actions/generalActions";

// Redux, App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellcurve from "highcharts/modules/histogram-bellcurve";
// import { RiContactsBookLine } from "react-icons/ri";

// MUI
import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

bellcurve(Highcharts);

const MetricHistogramSlider = ({
    sliderprops,
    minValue,
    maxValue,
    setMinValue,
    setMaxValue,
    updateSingleSliderValueOnly,
    getSingleHistogramData,
    histogram,
}) => {
    let data;
    const useintl = useIntl();
    useEffect(() => {
        if (histogram.statistics) {
            if (sliderprops.property !== histogram.statistics.metric) {
                getSingleHistogramData(sliderprops.property);
                data = histogram.values;
            } else {
                data = histogram.values;
            }
        } else {
            getSingleHistogramData(sliderprops.property);
            data = histogram.values;
        }
        // eslint-disable-next-line
    }, []);

    // Slider and Reducer Update
    const [value, setValue] = React.useState(sliderprops.value);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setMinValue(newValue[0]);
        setMaxValue(newValue[1]);
        sliderprops.value = newValue;
    };
    const handleUpdateSingleSliderValueOnly = (event, newValue) => {
        updateSingleSliderValueOnly(sliderprops);
    };

    /*SLIDERS EDIT MIN VALUE POPOVER*/
    const [openMinModal, setOpenMinModal] = React.useState(null);
    const handleOpenMinModal = (event) => {
        setOpenMinModal(event.currentTarget);
    };
    const handleCloseMinModal = () => {
        if (minValue < value[1]) {
            setValue([minValue, value[1]]);
            sliderprops.value = [minValue, value[1]];
        } else {
            setValue([value[1], minValue]);
            sliderprops.value = [value[1], minValue];
        }

        if (Number(value[0]).toPrecision(3) !== minValue) {
            handleUpdateSingleSliderValueOnly();
        }
        setOpenMinModal(null);
    };
    const openMin = Boolean(openMinModal);
    const idMin = openMin ? "simple-popover" : undefined;

    const handleMinValue = (event) => {
        if (sliderprops.property.includes("ranker")) {
            setMinValue(Number(event.target.value) / 100);
        } else {
            setMinValue(event.target.value);
        }
    };

    const handleMinStatistics = (value_in) => {
        setMinValue(value_in);
        if (value_in < value[1]) {
            setValue([value_in, value[1]]);
            sliderprops.value = [value_in, value[1]];
        } else {
            setValue([value[1], value_in]);
            sliderprops.value = [value[1], value_in];
        }

        if (Number(value[0]).toPrecision(3) !== value_in) {
            handleUpdateSingleSliderValueOnly();
        }
        setOpenMinModal(null);
    };

    /*SLIDERS EDIT MAX VALUE POPOVER*/
    const [openMaxModal, setOpenMaxModal] = React.useState(null);
    const handleOpenMaxModal = (event) => {
        setOpenMaxModal(event.currentTarget);
    };
    const handleCloseMaxModal = () => {
        if (maxValue > value[0]) {
            setValue([value[0], maxValue]);
            sliderprops.value = [value[0], maxValue];
        } else {
            setValue([maxValue, value[0]]);
            sliderprops.value = [maxValue, value[0]];
        }

        if (Number(value[1]).toPrecision(3) !== maxValue) {
            handleUpdateSingleSliderValueOnly();
        }
        setOpenMaxModal(null);
    };
    const openMax = Boolean(openMaxModal);
    const idMax = openMax ? "simple-popover" : undefined;

    const handleMaxValue = (event) => {
        if (sliderprops.property.includes("ranker")) {
            setMaxValue(Number(event.target.value) / 100);
        } else {
            setMaxValue(event.target.value);
        }
    };

    const handleMaxStatistics = (value_in) => {
        setMaxValue(value_in);
        if (value_in > value[0]) {
            setValue([value[0], value_in]);
            sliderprops.value = [value[0], value_in];
        } else {
            setValue([value_in, value[0]]);
            sliderprops.value = [value_in, value[0]];
        }
        if (Number(value[1]).toPrecision(3) !== maxValue) {
            handleUpdateSingleSliderValueOnly();
        }
        setOpenMaxModal(null);
    };

    useEffect(() => {
        // eslint-disable-next-line
    }, [minValue, maxValue]);

    // SHOW FURTHER INFO ON HOVER
    const [onmouse25, setOnmouse25] = React.useState(false);
    const handleMousein25 = () => {
        setOnmouse25(true);
    };
    const handleMouseout25 = () => {
        setOnmouse25(false);
    };
    const [onmouse50, setOnmouse50] = React.useState(false);
    const handleMousein50 = () => {
        setOnmouse50(true);
    };
    const handleMouseout50 = () => {
        setOnmouse50(false);
    };
    const [onmouse75, setOnmouse75] = React.useState(false);
    const handleMousein75 = () => {
        setOnmouse75(true);
    };
    const handleMouseout75 = () => {
        setOnmouse75(false);
    };

    let histogram_legend = {
        blue: useintl.formatMessage({
            id: `histogram.number_of_companies_in_a_given_range`,
        }),
        grey: useintl.formatMessage({
            id: `histogram.selected_area_by_the_slider`,
        }),
        red: useintl.formatMessage({
            id: `histogram.median_value`,
        }),
    };

    /* Sliders Tooltip Styling */
    function ValueLabelComponent(props) {
        const { children, open, value } = props;

        const popperRef = React.useRef(null);
        React.useEffect(() => {
            if (popperRef.current) {
                popperRef.current.update();
            }
        });

        return (
            <Tooltip
                PopperProps={{
                    popperRef,
                }}
                open={open}
                enterTouchDelay={0}
                placement='top'
                title={
                    sliderprops.property.includes("ranker") &&
                    !sliderprops.property.includes("change")
                        ? Number(value).toPrecision(3) * 100
                        : sliderprops.property === "market_cap" ||
                          sliderprops.property === "market_cap_usd" ||
                          sliderprops.property === "ev"
                        ? numberFormatterfour(Number(value).toPrecision(3)) +
                          `${sliderprops.unit}`
                        : Number(value).toPrecision(3) + `${sliderprops.unit}`
                }
            >
                {children}
            </Tooltip>
        );
    }
    ValueLabelComponent.propTypes = {
        children: PropTypes.element.isRequired,
        open: PropTypes.bool.isRequired,
        value: PropTypes.number.isRequired,
    };

    if (
        !histogram.values ||
        histogram.statistics.metric !== sliderprops.property
    ) {
        return <Preloader />;
    } else {
        let chartOptions = {
            title: {
                text: useintl.formatMessage({
                    id: `general.frequency_distribution`,
                }),
                visible: false,
            },
            credits: {
                enabled: false,
            },

            xAxis: [
                {
                    title: { text: sliderprops.displayName },
                    alignTicks: false,
                    opposite: true,
                    labels: {
                        formatter: function () {
                            var label =
                                this.axis.defaultLabelFormatter.call(this);

                            // Use thousands separator for four-digit numbers too

                            return numberFormatterfour(label);
                        },
                    },
                },
                {
                    alignTicks: false,
                    // visible: false,
                    plotBands: [
                        {
                            color: "grey", // Color value
                            from: minValue,
                            //  > sliderprops.min_max[0]
                            //     ? value[0]
                            //     : -10000000000000, // Start of the plot band
                            to: maxValue,
                            //  < sliderprops.min_max[1]
                            //     ? value[1]
                            //     : 10000000000000, // End of the plot band
                        },
                    ],
                    plotLines: [
                        {
                            color: "red", // Color value
                            dashStyle: "longdashdot", // Style of the plot line. Default to solid
                            value: histogram.statistics.quantile_50, // Value of where the line will appear
                            width: 2, // Width of the line
                            zIndex: 2,
                        },
                    ],
                },
            ],

            yAxis: [
                {
                    title: { text: "Data" },
                    visible: false,
                    opposite: true,
                    showInLegend: false,
                },
                {
                    title: {
                        text: useintl.formatMessage({
                            id: `histogram.number_of_companies`,
                        }),
                    },
                },
            ],

            plotOptions: {
                histogram: {
                    accessibility: {
                        pointDescriptionFormatter: function (point) {
                            var ix = point.index + 1,
                                x1 = point.x.toPrecision(3),
                                x2 = point.x2.toPrecision(3),
                                val = point.y;
                            return (
                                ix +
                                ". " +
                                numberFormatterfour(x1) +
                                " to " +
                                numberFormatterfour(x2) +
                                ", " +
                                val +
                                "."
                            );
                        },
                    },
                },
            },
            tooltip: {
                pointFormat: `Companies: <b>{point.y}</b></br>Range: <b>{point.x}</b> to <b>{point.x2}</b>`,
                shared: false,
                valueDecimals: 2,
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    type: "histogram",
                    xAxis: 1,
                    yAxis: 1,
                    binWidth: histogram.statistics.bin_width,
                    baseSeries: "s1",
                    zIndex: -1,
                },
                {
                    name: "Data",
                    type: "scatter",
                    data: histogram.values,
                    visible: false,
                    showInLegend: false,
                    id: "s1",
                    marker: {
                        radius: 1.5,
                    },
                },
            ],
        };
        return (
            <div>
                <div className='divider_no_margin'></div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
                <div className='screener_slider_range_grid_histogram'>
                    {/* MIN */}
                    <RangeSliderScreenerMinPopover
                        className='screener_min_value'
                        sliderprops={sliderprops}
                        value={value}
                        setValue={setValue}
                        minValue={minValue}
                        setMinValue={setMinValue}
                        setMaxValue={setMaxValue}
                    />
                    {/* RANGE */}
                    <Slider
                        step={
                            (sliderprops.min_max[1] - sliderprops.min_max[0]) /
                            100
                        }
                        components={{
                            ValueLabel: ValueLabelComponent,
                        }}
                        id={sliderprops.property}
                        name={sliderprops.property}
                        className='screener_selector_histogram'
                        min={sliderprops.min_max[0]}
                        max={sliderprops.min_max[1]}
                        value={[minValue, maxValue]}
                        onChange={handleChange}
                        onMouseUp={handleUpdateSingleSliderValueOnly}
                        size='small'
                        valueLabelDisplay='auto'
                        aria-labelledby='range-slider'
                    />
                    <div className='screener_placeholder_histogram'></div>
                    {/* MAX */}
                    <RangeSliderScreenerMaxPopover
                        className='screener_max_value'
                        sliderprops={sliderprops}
                        value={value}
                        setValue={setValue}
                        maxValue={maxValue}
                        setMinValue={setMinValue}
                        setMaxValue={setMaxValue}
                    />
                </div>
                <div className='divider_no_margin'></div>
                <div className='histogram_legend ml_20px'>
                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_blue'></div>
                        <div className='ml_10px'> {histogram_legend.blue}</div>
                    </div>

                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_grey'></div>
                        <div className='ml_10px'> {histogram_legend.grey}</div>
                    </div>
                    <div className='flex'>
                        <div className='histogram_legend_circle highcharts_red'></div>
                        <div className='ml_10px'> {histogram_legend.red}</div>
                    </div>
                </div>
                <div className='divider_no_margin'></div>
                <div className='slider_info_typical_values'>
                    <div className='title_5'>
                        {useintl.formatMessage({
                            id: `general.statistics`,
                        })}
                    </div>
                    <table class='mt_5px'>
                        <thead>
                            <tr>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_25`,
                                    })}
                                </th>
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.median`,
                                    })}
                                </th>
                                {/* <th className='text_left'>Mean</th> */}
                                <th className='text_left'>
                                    {useintl.formatMessage({
                                        id: `histogram.quantile_75`,
                                    })}{" "}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='histogram_statistics_table_body_row'>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd" ||
                                    sliderprops.property === "ev"
                                        ? numberFormatterfour(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_25
                                              ).toPrecision(3)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_25
                                          ).toPrecision(3) +
                                          ` ${sliderprops.unit}`}
                                </td>
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd" ||
                                    sliderprops.property === "ev"
                                        ? numberFormatterfour(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_50
                                              ).toPrecision(3)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_50
                                          ).toPrecision(3) +
                                          ` ${sliderprops.unit}`}
                                </td>
                                {/* <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" || sliderprops.property === "market_cap_usd" ||
                                    sliderprops.property === "ev"
                                        ? numberFormatterfour(
                                              Number(
                                                  histogram.statistics.mean
                                              ).toPrecision(3)
                                          ) + `${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.mean
                                          ).toPrecision(3) + `${sliderprops.unit}`}
                                </td> */}
                                <td className='text_left histogram_statistics_table_body_column'>
                                    {sliderprops.property === "market_cap" ||
                                    sliderprops.property === "market_cap_usd" ||
                                    sliderprops.property === "ev"
                                        ? numberFormatterfour(
                                              Number(
                                                  histogram.statistics
                                                      .quantile_75
                                              ).toPrecision(3)
                                          ) + ` ${sliderprops.unit}`
                                        : Number(
                                              histogram.statistics.quantile_75
                                          ).toPrecision(3) +
                                          ` ${sliderprops.unit}`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='divider_no_margin'></div>
                <div className='margin_10px'>
                    <IoIcons5.IoBookOutline className='mr_10px' />
                    Frequency distribution is the term generally used to
                    describe the numbers that indicate how often a particular
                    value has occurred in a statistical analysis. In the chart
                    above, you can see how often a certain range occurs in our
                    database, based on a key figure. In other words, how many
                    companies have this key figure in the specified range.
                </div>
                <div className='margin_10px'>
                    <BsIcons.BsExclamationTriangle className='mr_10px' />
                    The data shown above represents all the companies of our
                    database. Minimum and maximum values are capped in both
                    directions.
                </div>

                {/* MIN POPOVER */}
                <Popover
                    id={idMin}
                    open={openMin}
                    anchorEl={openMinModal}
                    onClose={() => {
                        handleCloseMinModal();
                        handleUpdateSingleSliderValueOnly();
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    className=''
                >
                    <div className='width_200px background_color_4 border_box'>
                        <div className='border_box padding_6px'>
                            <input
                                type='number'
                                className={
                                    minValue !== ""
                                        ? "text_2 text_color_1"
                                        : "text_2 text_color_2"
                                }
                                // value={minValue}
                                placeholder='min value'
                                onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        handleCloseMinModal();
                                        handleUpdateSingleSliderValueOnly();
                                    } else {
                                        handleMinValue(event);
                                    }
                                }}
                            />
                        </div>
                        <div className='divider_no_margin'></div>
                        <div className='flex'>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein25}
                                onMouseLeave={handleMouseout25}
                                onClick={() => {
                                    handleMinStatistics(
                                        sliderprops.statistics[0]
                                    );
                                    handleCloseMinModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout25();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>25</p>
                                </div>
                            </div>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein50}
                                onMouseLeave={handleMouseout50}
                                onClick={() => {
                                    handleMinStatistics(
                                        sliderprops.statistics[1]
                                    );
                                    handleCloseMinModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout50();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>50</p>
                                </div>
                            </div>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein75}
                                onMouseLeave={handleMouseout75}
                                onClick={() => {
                                    handleMinStatistics(
                                        sliderprops.statistics[2]
                                    );
                                    handleCloseMinModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout75();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>75</p>
                                </div>
                            </div>
                        </div>
                        {onmouse25 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.quantile_25`,
                                })}
                            </div>
                        ) : null}
                        {onmouse50 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.median`,
                                })}
                            </div>
                        ) : null}
                        {onmouse75 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.quantile_75`,
                                })}
                            </div>
                        ) : null}
                        <div className='divider_no_margin'></div>
                        <div className='flex border_radius_15px_bottom'>
                            <div
                                className='width_50pr flex center border_box padding_4px cursor_pointer'
                                onClick={() => {
                                    handleMinStatistics(sliderprops.min_max[0]);
                                    handleCloseMinModal();
                                    handleUpdateSingleSliderValueOnly();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>-</p>
                                    <IoIcons5.IoInfiniteOutline />
                                    <div className='pb_2px ml_3px'>
                                        {sliderprops.unit}
                                    </div>
                                </div>
                            </div>
                            <div className='width_50pr flex center text_color_unactive border_box padding_4px'>
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>+</p>
                                    <IoIcons5.IoInfiniteOutline />
                                    <div className='pb_2px ml_3px'>
                                        {sliderprops.unit}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popover>

                {/* MAX POPOVER */}
                <Popover
                    id={idMax}
                    open={openMax}
                    anchorEl={openMaxModal}
                    onClose={() => {
                        handleCloseMaxModal();
                        handleUpdateSingleSliderValueOnly();
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    className=''
                >
                    <div className='width_200px background_color_4 border_box'>
                        <div className='border_box padding_6px'>
                            <input
                                type='number'
                                className={
                                    maxValue !== ""
                                        ? "text_2 text_color_1"
                                        : "text_2 text_color_2"
                                }
                                // value={minValue}
                                placeholder='max value'
                                onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        handleCloseMaxModal();
                                        handleUpdateSingleSliderValueOnly();
                                    } else {
                                        handleMaxValue(event);
                                    }
                                }}
                            />
                        </div>
                        <div className='divider_no_margin'></div>
                        <div className='flex'>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein25}
                                onMouseLeave={handleMouseout25}
                                onClick={() => {
                                    handleMaxStatistics(
                                        sliderprops.statistics[0]
                                    );
                                    handleCloseMaxModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout25();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>25</p>
                                </div>
                            </div>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein50}
                                onMouseLeave={handleMouseout50}
                                onClick={() => {
                                    handleMaxStatistics(
                                        sliderprops.statistics[1]
                                    );
                                    handleCloseMaxModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout50();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>50</p>
                                </div>
                            </div>
                            <div
                                className='width_33pr flex center border_box padding_5px cursor_pointer'
                                onMouseOver={handleMousein75}
                                onMouseLeave={handleMouseout75}
                                onClick={() => {
                                    handleMaxStatistics(
                                        sliderprops.statistics[2]
                                    );
                                    handleCloseMaxModal();
                                    handleUpdateSingleSliderValueOnly();
                                    handleMouseout75();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>75</p>
                                </div>
                            </div>
                        </div>
                        {onmouse25 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.quantile_25`,
                                })}
                            </div>
                        ) : null}
                        {onmouse50 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.median`,
                                })}
                            </div>
                        ) : null}
                        {onmouse75 ? (
                            <div className='pos_absolute ml_10px background_color_4 text_4 text_color_1'>
                                {useintl.formatMessage({
                                    id: `histogram.quantile_75`,
                                })}
                            </div>
                        ) : null}
                        <div className='divider_no_margin'></div>
                        <div className='flex border_radius_15px_bottom'>
                            <div className='width_50pr flex center text_color_unactive border_box padding_4px'>
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>-</p>
                                    <IoIcons5.IoInfiniteOutline />
                                    <div className='pb_2px ml_3px'>
                                        {sliderprops.unit}
                                    </div>
                                </div>
                            </div>
                            <div
                                className='width_50pr flex center border_box padding_4px cursor_pointer'
                                onClick={() => {
                                    handleMaxStatistics(sliderprops.min_max[1]);
                                    handleCloseMaxModal();
                                    handleUpdateSingleSliderValueOnly();
                                }}
                            >
                                <div className='flex'>
                                    <p className='pb_3px mr_3px'>+</p>
                                    <IoIcons5.IoInfiniteOutline />
                                    <div className='pb_2px ml_3px'>
                                        {sliderprops.unit}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>
        );
    }
};

MetricHistogramSlider.propTypes = {
    histogram: PropTypes.object.isRequired,
    // slider: PropTypes.object.isRequired,
    updateSingleSliderValueOnly: PropTypes.func.isRequired,
    // updateSingleSliderValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    histogram: state.screener.histogram,
    // slider: state.screener.slider,
});

export default connect(mapStateToProps, {
    getSingleHistogramData,
    // updateSingleSliderValue,
    updateSingleSliderValueOnly,
})(MetricHistogramSlider);
