import {
    LOAD_USER,
    FORCE_LOGIN,
    REGISTER_SUCCESS,
    REGISTER_GOOGLE_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_GOOGLE_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_FAIL,
    CHANGE_USERNAME_SUCCESS,
    CHANGE_USERNAME_FAIL,
    CREATE_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
    EMAIL_VERIFICATION_SUCCESS,
    OPEN_CHANGES,
    OPEN_LOADER,
    FOLLOW_PORTFOLIO,
    UNFOLLOW_PORTFOLIO,
    USER_ERROR,
    SIMPLIFIED_VIEW,
    DELETE_ACCOUNT_SUCCESS,
} from "../actions/Types";

const initialState = {
    token: null,
    isAuthenticated: false,
    name: null,
    email: null,
    status: null,
    locale: null,
    currency: null,
    simplified_view: false,

    watchlists: [],
    portfolios: [],

    loading: true,
    openChanges: false,
    openLoader: false,

    force_login: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_USER:
            return {
                ...state,
                id: action.payload.id,
                date: action.payload.date,
                token: action.payload.token,
                stripeCustomerId: action.payload.stripe_customer_id,
                subscriptionId: action.payload.stripe_subscription_id,
                stripeProductId: action.payload.stripe_product_id,
                paymentMethod: action.payload.payment_method,
                stripePeriodEnd: action.payload.stripe_period_end,
                stripeCancelAtPeriodEnd:
                    action.payload.stripe_cancel_at_period_end,
                stripeNextInvoice: action.payload.stripe_next_invoice,
                isAuthenticated: true,
                preferred_login_method: action.payload.preferred_login_method,
                name: action.payload.name,
                email: action.payload.email,
                status: action.payload.status,
                locale: action.payload.locale,
                currency: action.payload.currency,
                watchlists: action.payload.watchlists,
                portfolios: action.payload.portfolios,
                portfolios_followed: action.payload.portfolios_followed
                    ? action.payload.portfolios_followed
                    : [],
                email_verified: action.payload.email_verified,
                loading: true,
                error: null,
            };

        case FOLLOW_PORTFOLIO:
            console.log(action.payload);
            return {
                ...state,
                portfolios_followed: [
                    ...state.portfolios_followed,
                    action.payload,
                ],
            };

        case UNFOLLOW_PORTFOLIO:
            return {
                ...state,
                portfolios_followed: state.portfolios_followed.filter(
                    (el) => el !== action.payload
                ),
                // portfolios_followed: [
                //     ...state.portfolios_followed.filter(
                //         (el) => el !== action.payload
                //     ),
                // ],
            };

        case FORCE_LOGIN:
            return {
                ...state,
                force_login: !state.force_login,
            };

        case REGISTER_SUCCESS:
            let { token, name, email, status, locale, currency } =
                action.payload;
            localStorage.setItem("token", token);
            return {
                ...state,
                token: token,
                isAuthenticated: true,
                name: name,
                email: email,
                status: status,
                locale: locale,
                currency: currency,
                force_login: false,
                loading: true,
                error: null,
            };

        case REGISTER_GOOGLE_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                name: action.payload.name,
                email: action.payload.email,
                status: action.payload.status,
                locale: action.payload.locale,
                currency: action.payload.currency,
                force_login: false,
                loading: true,
                error: null,
            };

        case REGISTER_FAIL:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                name: null,
                email: null,
                status: null,
                locale: null,
                currency: null,
                watchlists: null,
                portfolios: null,
                loading: false,
                error: action.payload,
            };

        case LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                preferred_login_method: action.payload.preferred_login_method,
                name: action.payload.name,
                email: action.payload.email,
                status: action.payload.status,
                locale: action.payload.locale,
                currency: action.payload.currency,
                watchlists: action.payload.watchlists,
                portfolios: action.payload.portfolios,
                force_login: false,
                loading: true,
                error: null,
            };

        case LOGIN_GOOGLE_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                name: action.payload.name,
                email: action.payload.email,
                status: action.payload.status,
                locale: action.payload.locale,
                currency: action.payload.currency,
                watchlists: action.payload.watchlists,
                portfolios: action.payload.portfolios,
                force_login: false,
                loading: true,
                error: null,
            };

        case LOGIN_FAIL:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                name: null,
                email: null,
                status: null,
                locale: null,
                currency: null,
                watchlists: null,
                portfolios: null,
                loading: false,
                error: action.payload,
            };

        case LOGOUT_SUCCESS:
            localStorage.removeItem("token");
            localStorage.removeItem("bookmarks");
            localStorage.removeItem("latest_screener");
            localStorage.removeItem("latest_threshold");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                name: null,
                email: null,
                status: null,
                locale: null,
                currency: null,
                watchlists: null,
                portfolios: null,
                loading: false,
                error: action.payload,
            };

        case LOGOUT_FAIL:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                name: null,
                email: null,
                status: null,
                locale: null,
                currency: null,
                watchlists: null,
                portfolios: null,
                loading: false,
                error: action.payload,
            };

        case EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                email_verified: true,
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                openChanges: false,
            };

        case RESET_PASSWORD_FAIL:
            return {
                ...state,
            };

        case CHANGE_EMAIL_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                openChanges: false,
                token: action.payload.token,
                email: action.payload.email,
                email_verified: false,
            };

        case CHANGE_EMAIL_FAIL:
            return {
                ...state,
            };

        case CHANGE_USERNAME_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                openChanges: false,
                token: action.payload.token,
                name: action.payload.name,
            };

        case CHANGE_USERNAME_FAIL:
            return {
                ...state,
            };

        case SIMPLIFIED_VIEW:
            return {
                ...state,
                simplified_view: action.payload,
            };

        case OPEN_CHANGES:
            return {
                ...state,
                openChanges: !state.openChanges,
            };

        case OPEN_LOADER:
            return {
                ...state,
                openLoader: true,
            };

        case CREATE_SUBSCRIPTION:
            console.log(action.payload);
            return {
                ...state,
                paymentMethod: action.payload.paymentMethod,
                stripeCustomerId: action.payload.stripeCustomerId,
                subscriptionId: action.payload.subscriptionId,
                stripeProductId: action.payload.stripeProductId,
                stripePeriodEnd: action.payload.stripePeriodEnd,
                stripeNextInvoice: action.payload.stripeNextInvoice,
                status: 1,
                stripeCancelAtPeriodEnd: action.payload.stripeCancelAtPeriodEnd,
                openChanges: false,
                openLoader: false,
            };

        case CANCEL_SUBSCRIPTION:
            return {
                ...state,
                paymentMethod: action.payload.paymentMethod,
                stripeCustomerId: action.payload.stripeCustomerId,
                subscriptionId: action.payload.subscriptionId,
                stripeProductId: action.payload.stripeProductId,
                stripePeriodEnd: action.payload.stripePeriodEnd,
                stripeNextInvoice: action.payload.stripeNextInvoice,
                status: 1,
                stripeCancelAtPeriodEnd: action.payload.stripeCancelAtPeriodEnd,
                openChanges: false,
                openLoader: false,
            };

        case DELETE_ACCOUNT_SUCCESS:
            // CLEAR LOCALSTORAGE
            localStorage.clear();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                name: null,
                email: null,
                status: null,
                locale: null,
                currency: null,

                watchlists: [],
                portfolios: [],

                loading: true,
                openChanges: false,
                openLoader: false,

                force_login: true,
                error: null,
            };

        case USER_ERROR:
            return state;

        default:
            return state;
    }
};
