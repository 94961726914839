import React from "react";
import { useIntl } from "react-intl";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

const SearchBarResult = ({ search_bar_company }) => {
    const [hoverBackgroundColor, setHoverBackgroundColor] = React.useState(
        "background_color_4 height_100pr width_100pr text_3 text_color_1 flex padding_5px"
    );
    const handleHoverOut = () => {
        setHoverBackgroundColor(
            "background_color_4 height_100pr width_100pr text_3 text_color_1 flex space_between wrap padding_5px cursor_pointer"
        );
    };
    const handleHoverIn = () => {
        setHoverBackgroundColor(
            "background_color_3 height_100pr width_100pr text_3 text_color_1 flex space_between wrap padding_5px cursor_pointer"
        );
    };

    function truncate(str, n) {
        return str !== null
            ? str.length > n
                ? str.substr(0, n - 1) + "..."
                : str
            : str;
    }

    const useintl = useIntl();

    return (
        <div
            className={hoverBackgroundColor}
            onMouseOver={handleHoverIn}
            onMouseLeave={handleHoverOut}
        >
            <div className='flex width_70pr'>
                <div className='width_20pr min_width_60px text_3 height_100pr flex left'>
                    {search_bar_company.ticker}
                </div>
                <div className='width_80pr text_3 flex left'>
                    {truncate(search_bar_company.name, 24)}
                </div>
            </div>
            {search_bar_company.isin ? (
                <div className='width_30pr min_width_100px flex right text_3'>
                    {search_bar_company.isin}
                </div>
            ) : null}
        </div>
    );
};

SearchBarResult.propTypes = {
    search_bar_company: PropTypes.object.isRequired,
};

// Wenn informationen aus dem State verwendet werden sollen, müssen sie hier als props reingebracht werden
// 'sidebar': 'state.sidebar' === 'Name des Props hier': 'Name des States, wie es im root (index) file heisst'
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SearchBarResult);
