import React from "react";
import { numberFormatterfour } from "../../../actions/generalActions";

const PortfolioTableRowColumn = ({ company, metric }) => {
    let not_metrics = [
        "page",
        "limit",
        "search",
        "searchOption",
        "sort",
        "industry",
        "esg",
        "stock_exchange",
        "ticker",
        "description",
        "name",
        "currency",
        "country",
        "market_cap",
        "market_cap_usd",
    ];
    let returns_metrics = [
        "daily_return",
        "weekly_return",
        "monthly_return",
        "quarterly_return",
        "semi_annual_return",
        "annual_return",
        "two_year_return",
        "three_year_return",
    ];
    let industries_display = [
        "gic_sector",
        "gic_group",
        "gic_industry",
        "gic_sub_industry",
    ];

    let not_manager = ["daily_return"];
    let not_creator = ["daily_return"];

    if (not_metrics.includes(metric) || !typeof company[metric] === "number") {
        return null;
    } else if (
        (window.location.pathname === "/portfolio" &&
            not_manager.includes(metric)) ||
        (window.location.pathname === "/portfolio" &&
            metric === "stock_price") ||
        (window.location.pathname === "/creator" && metric === "stock_price") ||
        (window.location.pathname === "/creator" &&
            not_creator.includes(metric))
    ) {
        return null;
    } else if (industries_display.includes(metric)) {
        return (
            <td className='text_color_1 text_4 text_left ps_10px'>
                {company[metric]}
            </td>
        );
    } else if (
        returns_metrics.includes(metric) &&
        typeof company[metric] === "number"
    ) {
        return (
            <td
                className={
                    Number(company[metric]) >= 0
                        ? "padding_15_5px text_2 text_color_green text_right"
                        : "padding_15_5px text_2 text_color_red text_right"
                }
            >
                {Number.parseFloat(company[metric]).toPrecision(4)} %
            </td>
        );
    } else if (typeof company[metric] === "number" && metric === "ev") {
        return (
            <td className='padding_15_5px text_2 text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(company[metric]).toPrecision(4)
                )}{" "}
                USD
            </td>
        );
    } else if (
        typeof company[metric] === "number" &&
        metric.includes("ranker")
    ) {
        return (
            <td className='padding_15_5px text_2 text_color_1 text_right'>
                {numberFormatterfour(
                    Number.parseFloat(company[metric] * 100).toPrecision(4)
                )}
            </td>
        );
    } else if (!isNaN(company[metric]) && typeof company[metric] === "number") {
        return (
            <td className='padding_15_5px text_2 text_right'>
                {Number.parseFloat(company[metric]).toPrecision(4)}{" "}
                {metric === "stock_price" ? "USD" : null}
            </td>
        );
    } else {
        return <td className='padding_15_5px text_2 text_right'>-</td>;
    }
};
export default PortfolioTableRowColumn;
