import React from "react";

// Highcharts
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";

// Bring in Redux and App level state
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../../../../stylesheets/GUI.css";

Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);

const GUI = ({ company: { company } }) => {
    let tClose = company.stock_prices.map((tohlc) => [
        tohlc[0] * 1000,
        tohlc[5],
    ]);
    // let tOHLC = company.stock_prices.map((tohlc) => [
    //     tohlc[0] * 1000,
    //     tohlc[1],
    //     tohlc[2],
    //     tohlc[3],
    //     tohlc[4],
    //     tohlc[5],
    // ]);
    let tVolume = company.stock_prices.map((tohlc) => [
        tohlc[0] * 1000,
        tohlc[6],
    ]);

    let chartOptions = {
        chart: {
            height: "75%",
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        rangeSelector: {
                            buttons: [
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
                {
                    condition: {
                        maxWidth: 1200,
                        minWidth: 401,
                    },
                    chartOptions: {
                        rangeSelector: {
                            buttons: [
                                {
                                    type: "month",
                                    count: 3,
                                    text: "3m",
                                },
                                {
                                    type: "month",
                                    count: 6,
                                    text: "6m",
                                },
                                {
                                    type: "ytd",
                                    text: "YTD",
                                },
                                {
                                    type: "year",
                                    count: 1,
                                    text: "1y",
                                },
                                {
                                    type: "year",
                                    count: 5,
                                    text: "5y",
                                },
                                {
                                    type: "all",
                                    text: "All",
                                },
                            ],
                            buttonTheme: {
                                width: 30,
                                padding: 4,
                            },
                        },
                    },
                },
            ],
        },

        title: {
            text: "",
        },

        stockTools: {
            gui: {
                buttons: [
                    "indicators",
                    "currentPriceIndicator",
                    "fullScreen",
                    "separator",
                    // "simpleShapes",
                    "lines",
                    "crookedLines",
                    "measure",
                    "advanced",
                    "toggleAnnotations",
                    // "verticalLabels",
                    // "flags",
                    // "zoomChange",
                    // "typeChange",
                    // "saveChart",
                ],
            },
        },

        yAxis: [
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    text: "Stockprice",
                },
                height: "85%",
                lineWidth: 2,
                resize: {
                    enabled: true,
                },
            },
            {
                labels: {
                    align: "right",
                    x: -3,
                },
                title: {
                    text: "Volume",
                },
                top: "85%",
                height: "15%",
                offset: 0,
                lineWidth: 2,
            },
        ],
        navigator: {
            enabled: true,
        },
        scrollbar: {
            enabled: false,
        },
        credits: {
            text: " ",
        },
        tooltip: {
            shared: true,
        },
        series: [
            {
                type: "area",
                name: "Stock Price",
                data: tClose,
                id: "stockprice",
                threshold: null,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [
                            0,
                            Highcharts.Color("#7cb5ec")
                                .setOpacity(0.7)
                                .get("rgba"),
                        ],
                        [
                            1,
                            Highcharts.Color("#ffffff")
                                .setOpacity(0)
                                .get("rgba"),
                        ],
                    ],
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: "USD",
                },
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
            {
                type: "column",
                name: "Volume",
                data: tVolume,
                threshold: null,
                yAxis: 1,
                color: Highcharts.getOptions().colors[0],
                dataGrouping: {
                    units: [
                        ["day", [1]],
                        ["week", [1]],
                        ["month", [1, 2, 3, 4, 6]],
                    ],
                },
            },
        ],
    };

    return (
        <div className='background_color_2 text_color_1'>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                constructorType={"stockChart"}
            />
        </div>
    );
};

GUI.propTypes = {
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, null)(GUI);
