import React, { useEffect } from "react";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import { useIntl, FormattedMessage } from "react-intl";

// COMPONENTS
import Preloader from "../../layout/Preloader";
import RevenueAnnual from "./charts/RevenueAnnual";
// import EarningsAnnual from "./charts/EarningsAnnual";
// import SharesOutstanding from "./charts/SharesOutstanding";
// import Assets from "./charts/Assets";
// import Valuation from "./charts/Valuation";
import Individual from "./charts/Individual";
import FeedbackIcon from "../elements/FeedbackIcon";

// ACTIONS
import { numberFormatter } from "../../../actions/generalActions";

// HIGHCHARTS
import Highcharts from "highcharts";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
// import Dialog from "@mui/material/Dialog";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import Popover from "@mui/material/Popover";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

const getQuarter = (d) => {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    m -= m > 4 ? 4 : 0;
    var y = d.getFullYear() + (m == 1 ? 1 : 0);
    return `${y}q${m}`;
};

const getRatioPercent = (numerator, denominator) => {
    let ratio = null;

    if (numerator !== null && denominator !== null) {
        ratio = Number(100 * Number(numerator / denominator)).toFixed(2);
    } else {
        ratio = null;
    }
    return ratio;
};

const getRatioPercentArray = (numerator, denominator) => {
    let ratio_array = [];
    for (let i = 0; i < numerator.length; i++) {
        if (numerator[i] !== null && denominator[i] !== null) {
            ratio_array[i] = (Number(numerator[i] / denominator[i]) - 1) * 100;
        } else {
            ratio_array[i] = null;
        }
    }
    return ratio_array;
};

const Charts = ({ company: { company, ticker }, numberFormatter }) => {
    const useintl = useIntl();

    // OBJECT TO ORDER AND NAME THE METRICS
    let income_statement_object = {
        revenue: {
            property: "revenue",
            eodproperty: "totalRevenue",
            name: "Revenue",
            style: 1,
            yAxis: 0,
        },
        cogs: {
            property: "cogs",
            eodproperty: "costOfRevenue",
            name: "COGS",
            yAxis: 0,
        },
        gross_profit: {
            property: "gross_profit",
            eodproperty: "grossProfit",
            name: "Gross Profit",
            style: 1,
            yAxis: 0,
        },
        gross_profit_ratio: {
            property: "gross_profit_ratio",
            eodproperty: "grossProfitRatio",
            name: "Gross Profit Ratio",
            yAxis: 1,
        },
        operating_expenses: {
            property: "operating_expenses",
            eodproperty: "totalOperatingExpenses",
            name: "Operating Expenses",
            yAxis: 0,
        },
        rd_expenses: {
            property: "rd_expenses",
            eodproperty: "researchDevelopment",
            name: "R&D Expenses",
            yAxis: 0,
        },
        // selling_ga_expenses: {
        // property: "selling_ga_expenses",
        //     eodproperty: "sellingGeneralAdministrative" + "sellingAndMarketingExpenses",
        //     name: "Selling G&A Exp",
        // },
        general_administrative_expenses: {
            property: "general_administrative_expenses",
            eodproperty: "sellingGeneralAdministrative",
            name: "General and Admin exp.",
            yAxis: 0,
        },
        selling_and_marketing_expenses: {
            property: "selling_and_marketing_expenses",
            eodproperty: "sellingAndMarketingExpenses",
            name: "Selling and Marketing exp.",
            yAxis: 0,
        },
        cogs_and_expenses: {
            property: "cogs_and_expenses",
            eodproperty: "otherOperatingExpenses",
            name: "COGS and Expenses",
            yAxis: 0,
        },
        interest_income: {
            property: "interest_income",
            eodproperty: "interestIncome",
            name: "Interest Income",
            yAxis: 0,
        },
        interest_expense: {
            property: "interest_expense",
            eodproperty: "interestExpense",
            name: "Interest Expense",
            yAxis: 0,
        },
        depreciation_and_amortization: {
            property: "depreciation_and_amortization",
            eodproperty: "depreciationAndAmortization",
            name: "Depreciation and Amortization",
            yAxis: 0,
        },
        ebitda: {
            property: "ebitda",
            eodproperty: "ebitda",
            name: "EBITDA",
            style: 1,
            yAxis: 0,
        },
        ebitda_ratio: {
            property: "ebitda_ratio",
            eodproperty: "ebitdaRatio",
            name: "EBITDA Ratio",
            yAxis: 1,
        },
        operating_income: {
            property: "operating_income",
            eodproperty: "operatingIncome",
            name: "Operating Income",
            style: 1,
            yAxis: 0,
        },
        operating_income_ratio: {
            property: "operating_income_ratio",
            eodproperty: "operatingIncomeRatio",
            name: "Operating Income Ratio",
            yAxis: 1,
        },
        total_other_income_expenses_gains: {
            property: "total_other_income_expenses_gains",
            eodproperty: "nonOperatingIncomeNetOther",
            name: "Total Other Icome Expenses (Gains)",
            yAxis: 0,
        },
        income_before_tax: {
            property: "income_before_tax",
            eodproperty: "incomeBeforeTax",
            name: "Income Before Tax",
            style: 1,
            yAxis: 0,
        },
        income_before_tax_ratio: {
            property: "income_before_tax_ratio",
            eodproperty: "incomeBeforeTaxRatio",
            name: "Income Before Tax Ratio",
            yAxis: 1,
        },
        income_tax_expense_gains: {
            property: "income_tax_expense_gains",
            eodproperty: "taxProvision",
            name: "Income Tax Expense (Gains)",
            yAxis: 0,
        },
        net_income: {
            property: "net_income",
            eodproperty: "netIncome",
            name: "Net Income",
            style: 1,
            yAxis: 0,
        },
        net_income_ratio: {
            property: "net_income_ratio",
            eodproperty: "netIncomeRatio",
            name: "Net Income Ratio",
            yAxis: 1,
        },
        // EPS: {
        // property: "EPS",
        //     eodproperty: "",
        //     name: "EPS",
        // },
        // EPS_Diluted: {
        // property: "EPS_Diluted",
        //     eodproperty: "",
        //     name: "EPS Diluted",
        // },
        shares_outstanding: {
            property: "shares_outstanding",
            eodproperty: "sharesOutstanding",
            name: "Shares Outstanding",
            yAxis: 3,
        },
        // Weighted_Avg_Shares_Outststanding_dil: {
        // property: "Weighted_Avg_Shares_Outststanding_dil",
        //     eodproperty: "",
        //     name: "Weighted Avg Shares Outststanding dil.",
        // style: 1
        // },
    };
    let balance_sheet_object = {
        cash_and_cash_equivalents: {
            property: "cash_and_cash_equivalents",
            eodproperty: "cash",
            name: "Cash and Cash Equivalents",
            // style: 1,
            yAxis: 0,
        },
        short_term_investments: {
            property: "short_term_investments",
            eodproperty: "shortTermInvestments",
            name: "Short-Term Investments",
            yAxis: 0,
        },
        cash_and_short_term_investments: {
            property: "cash_and_short_term_investments",
            eodproperty: "shortTermInvestments" + "cash",
            name: "Short-Term Investments",
            yAxis: 0,
        },
        net_reveivables: {
            property: "net_reveivables",
            eodproperty: "netReceivables",
            name: "Net Receivables",
            yAxis: 0,
        },
        inventory: {
            property: "inventory",
            eodproperty: "inventory",
            name: "Inventory",
            yAxis: 0,
        },
        other_current_assets: {
            property: "other_current_assets",
            eodproperty: "otherCurrentAssets",
            name: "Other Current Assets",
            yAxis: 0,
        },
        total_current_assets: {
            property: "total_current_assets",
            eodproperty: "totalCurrentAssets",
            name: "Total Current Assets",
            style: 1,
            yAxis: 0,
        },
        property_plant_and_equipment: {
            property: "property_plant_and_equipment",
            eodproperty: "propertyPlantEquipment",
            name: "Property, Plant and Equipment",
            yAxis: 0,
        },
        goodwill: {
            property: "goodwill",
            eodproperty: "goodWill",
            name: "GoodWill",
            yAxis: 0,
        },
        intangible_assets: {
            property: "intangible_assets",
            eodproperty: "intangibleAssets",
            name: "Intangible Assets",
            yAxis: 0,
        },
        goodwill_and_intangible_assets: {
            property: "goodwill_and_intangible_assets",
            eodproperty: "goodWill" + "intangibleAssets",
            name: "GoodWill and Intangible Assets",
            yAxis: 0,
        },
        investments: {
            property: "investments",
            eodproperty: "longTermInvestments",
            name: "Long Term Investments",
            yAxis: 0,
        },
        // tax_assets: {
        //property: "",
        // eodproperty: "",
        //     name: "",
        // },
        other_non_current_assets: {
            property: "other_non_current_assets",
            eodproperty: "nonCurrrentAssetsOther",
            name: "Other Non-Current Assets",
            yAxis: 0,
        },
        total_non_current_assets: {
            property: "total_non_current_assets",
            eodproperty: "totalCurrentAssets",
            name: "Total Current Assets",
            yAxis: 0,
        },
        other_assets: {
            property: "other_assets",
            eodproperty: "otherAssets",
            name: "Other Assets",
            yAxis: 0,
        },
        total_assets: {
            property: "total_assets",
            eodproperty: "totalAssets",
            name: "Total Assets",
            style: 1,
            yAxis: 0,
        },
        accounts_payable: {
            property: "accounts_payable",
            eodproperty: "accountsPayable",
            name: "Accounts Payable",
            yAxis: 0,
        },
        short_term_debt: {
            property: "short_term_debt",
            eodproperty: "shortTermDebt",
            name: "Short-Term Debt",
            yAxis: 0,
        },
        // tax_payable: {
        //property: "",
        // eodproperty: "",
        //     name: "",
        // },
        // deferred_revenue: {
        //property: "",
        // eodproperty: "",
        //     name: "",
        // },
        other_current_liabilities: {
            property: "other_current_liabilities",
            eodproperty: "otherCurrentLiab",
            name: "Other Current Liabilities",
            yAxis: 0,
        },
        total_current_liabilities: {
            property: "total_current_liabilities",
            eodproperty: "totalCurrentLiabilities",
            name: "Total Current Liabilities",
            style: 1,
            yAxis: 0,
        },
        long_term_debt: {
            property: "long_term_debt",
            eodproperty: "longTermDebtTotal",
            name: "Long-Term Debt",
            yAxis: 0,
        },
        // deferred_tax_liabilities: {
        //      property: "",
        //      eodproperty: "",
        //      name: "",
        // },
        other_non_current_liabilities: {
            property: "other_non_current_liabilities",
            eodproperty: "nonCurrentLiabilitiesOther",
            name: "Other Non-Current Liabilities",
            yAxis: 0,
        },
        total_non_current_liabilities: {
            property: "total_non_current_liabilities",
            eodproperty: "nonCurrentLiabilitiesTotal",
            name: "Total Non-Current Liabilities",
            yAxis: 0,
        },
        other_liabilities: {
            property: "other_liabilities",
            eodproperty: "otherLiab",
            name: "Other Liabilities",
            yAxis: 0,
        },
        capital_lease_obligations: {
            property: "capital_lease_obligations",
            eodproperty: "capitalLeaseObligations",
            name: "Capital Lease Obligations",
            yAxis: 0,
        },
        total_liabilities: {
            property: "total_liabilities",
            eodproperty: "totalLiab",
            name: "Total Liabilities",
            style: 1,
            yAxis: 0,
        },
        debt_ratio: {
            property: "debt_ratio",
            eodproperty: "debtRatio",
            name: "Debt Ratio",
            yAxis: 1,
        },
        preferred_stock: {
            property: "preferred_stock",
            eodproperty: "preferredStockTotalEquity",
            name: "Preferred Stock",
            yAxis: 0,
        },
        common_stock: {
            property: "common_stock",
            eodproperty: "commonStockTotalEquity",
            name: "Common Stock",
            yAxis: 0,
        },
        retained_earnings: {
            property: "retained_earnings",
            eodproperty: "retainedEarningsTotalEquity",
            name: "Retained Earnings",
            yAxis: 0,
        },
        other_compreh_income_loss: {
            property: "other_compreh_income_loss",
            eodproperty: "accumulatedOtherComprehensiveIncome",
            name: "Other Compreh. Income (Loss)",
            yAxis: 0,
        },
        other_stockholder_equity: {
            property: "other_stockholder_equity",
            eodproperty: "otherStockholderEquity",
            name: "Other Stockholder Equity",
            yAxis: 0,
        },
        total_stockholders_equity: {
            property: "total_stockholders_equity",
            eodproperty: "totalStockholderEquity",
            name: "Total Stockholder Equity",
            yAxis: 0,
            style: 1,
        },
        total_liabilities_and_stockholders_equity: {
            property: "total_liabilities_and_stockholders_equity",
            eodproperty: "liabilitiesAndStockholdersEquity",
            name: "Total Liab.& Stockhold. Equity",
            yAxis: 0,
        },
        minority_interest: {
            property: "minority_interest",
            eodproperty: "minorityInterest",
            name: "Minority Interest",
            yAxis: 0,
        },
        total_liabilities_and_equity: {
            property: "total_liabilities_and_equity",
            eodproperty: "liabilitiesAndStockholdersEquity",
            name: "Total Liabilities & Equity",
            yAxis: 0,
        },
    };
    let cash_flow_statement_object = {
        net_income: {
            property: "net_income",
            eodproperty: "netIncome",
            name: "Net Income",
            // style: 1,
            yAxis: 0,
        },
        depreciation_and_amortization: {
            property: "depreciation_and_amortization",
            eodproperty: "depreciationAndAmortization",
            name: "Depreciation And Amortization",
            yAxis: 0,
        },
        deferred_income_tax: {
            property: "deferred_income_tax",
            eodproperty: "depreciationAndAmortization",
            name: "Deferred Income Tax",
            yAxis: 0,
        },
        // stock_based_compensation: {
        //     property: "stock_based_compensation",
        //     eodproperty: "",
        //     name: "Stock Based Compensation",
        //     yAxis: 0,
        // },
        change_in_working_capital: {
            property: "change_in_working_capital",
            eodproperty: "changeInWorkingCapital",
            name: "Change In Working Capital",
            yAxis: 0,
        },
        change_in_accounts_receivables: {
            property: "change_in_account_receivables",
            eodproperty: "changeToAccountReceivables",
            name: "Change In Accounts Receivables",
            yAxis: 0,
        },
        change_to_inventory: {
            property: "change_to_inventory",
            eodproperty: "changeToInventory",
            name: "Change To Inventory",
            yAxis: 0,
        },
        // accounts_payable: {
        //     property: "accounts_payable",
        //     eodproperty: "",
        //     name: "Accounts Payable",
        //     yAxis: 0,
        // },
        change_in_working_capital: {
            property: "change_in_working_capital",
            eodproperty: "changeInWorkingCapital",
            name: "Change In Working Capital",
            yAxis: 0,
        },
        other_non_cash_items: {
            property: "other_non_cash_items",
            eodproperty: "otherNonCashItems",
            name: "Other Non Cash Items",
            yAxis: 0,
        },
        cash_provided_by_operating_activities: {
            property: "cash_provided_by_operating_activities",
            eodproperty: "totalCashFromOperatingActivities",
            name: "Cash Provided By Operating Activities",
            yAxis: 0,
            style: 1,
        },
        capex: {
            property: "capex",
            eodproperty: "capitalExpenditures",
            name: "Capex",
            yAxis: 0,
        },
        // acquisitions_net: {
        //     property: "acquisitions_net",
        //     eodproperty: "",
        //     name: "Acquisitions Net",
        //     yAxis: 0,
        // },
        // purchases_of_investments: {
        //     property: "purchases_of_investments",
        //     eodproperty: "",
        //     name: "Purchases Of Onvestments",
        //     yAxis: 0,
        // },
        // sales_maturities_of_investments: {
        //     property: "sales_maturities_of_investments",
        //     eodproperty: "",
        //     name: "Sales/ Maturities Of Onvestments",
        //     yAxis: 0,
        // },
        // other_investing_activities: {
        //     property: "other_investing_activities",
        //     eodproperty: "",
        //     name: "Other Investing Activities",
        //     yAxis: 0,
        // },
        cash_used_for_investing_activities: {
            property: "cash_used_for_investing_activities",
            eodproperty: "totalCashflowsFromInvestingActivities",
            name: "Cash Used For Investing Activities",
            yAxis: 0,
            style: 1,
        },
        // debt_repayment: {
        //     property: "debt_repayment",
        //     eodproperty: "",
        //     name: "Debt Repayment",
        //     yAxis: 0,
        // },
        // common_stock_issued: {
        //     property: "common_stock_issued",
        //     eodproperty: "",
        //     name: "Common Stock Issued",
        //     yAxis: 0,
        // },
        // common_stock_repurchased: {
        //     property: "common_stock_repurchased",
        //     eodproperty: "",
        //     name: "Common Stock Repurchased",
        //     yAxis: 0,
        // },
        dividends_paid: {
            property: "dividends_paid",
            eodproperty: "dividendsPaid",
            name: "Dividends Paid",
            yAxis: 0,
        },
        other_financing_activities: {
            property: "other_financing_activities",
            eodproperty: "otherCashflowsFromFinancingActivities",
            name: "Other Financing Activities",
            yAxis: 0,
        },
        cash_used_provided_by_financing_activities: {
            property: "cash_used_provided_by_financing_activities",
            eodproperty: "totalCashFromFinancingActivities",
            name: "Cash Used/Provided By Financing Activities",
            yAxis: 0,
            style: 1,
        },
        // effect_of_forex_exchanges_on_cash: {
        //     property: "effect_of_forex_exchanges_on_cash",
        //     eodproperty: "",
        //     name: "Effect Of Forex Exchanges On Cash",
        //     yAxis: 0,
        // },
        net_change_in_cash: {
            property: "net_change_in_cash",
            eodproperty: "changeInCash",
            name: "Net Change In Cash",
            yAxis: 0,
            style: 1,
        },
        cash_flow_at_the_end_of_period: {
            property: "cash_flow_at_the_end_of_period",
            eodproperty: "endPeriodCashFlow",
            name: "Cash Flow At The End Of Period",
            yAxis: 0,
        },
        cash_flow_at_the_begin_of_period: {
            property: "cash_flow_at_the_begin_of_period",
            eodproperty: "beginPeriodCashFlow",
            name: "Cash Flow At The Begin Of Period",
            yAxis: 0,
        },
        cash_flow_at_the_begin_of_period: {
            property: "cash_flow_at_the_begin_of_period",
            eodproperty: "beginPeriodCashFlow",
            name: "Cash Flow At The Begin Of Period",
            yAxis: 0,
        },
        free_cash_flow: {
            property: "free_cash_flow",
            eodproperty: "freeCashFlow",
            name: "Free Cash Flow",
            yAxis: 0,
            style: 1,
        },
        free_cash_flow_ratio: {
            property: "free_cash_flow_ratio",
            eodproperty: "freeCashFlowRatio",
            name: "Free Cash Flow Ratio",
            yAxis: 1,
        },
    };
    let whole_statement_object = {
        ...income_statement_object,
        ...balance_sheet_object,
        ...cash_flow_statement_object,
    };

    // CREATE ONE OBJECT CONTAINING ALL METRICS
    const provide_statement = (
        income,
        cash_flow,
        balance,
        outstanding_shares
    ) => {
        let statement = income;
        Object.keys(income.yearly).map((year) => {
            let loop_year_cash_flow = cash_flow.yearly[year];
            let loop_year_balance = balance.yearly[year];
            let loop_year_income = income.yearly[year];

            statement.yearly[year] = {
                ...loop_year_income,
                ...loop_year_cash_flow,
                ...loop_year_balance,
                sharesOutstanding: null,
            };
        });

        Object.keys(income.quarterly).map((quarter) => {
            let loop_quarter_cash_flow = cash_flow.quarterly[quarter];
            let loop_quarter_balance = balance.quarterly[quarter];
            let loop_quarter_income = income.quarterly[quarter];

            statement.quarterly[quarter] = {
                ...loop_quarter_income,
                ...loop_quarter_cash_flow,
                ...loop_quarter_balance,
                sharesOutstanding: null,
            };
        });

        // ADD OUTSTANDING SHARES BY dateFormatted
        let loop_outstanding_shares = outstanding_shares
            ? outstanding_shares.quarterly
            : null;

        if (loop_outstanding_shares) {
            Object.values(loop_outstanding_shares).map((object) => {
                if (statement.yearly[object.dateFormatted]) {
                    statement.yearly[object.dateFormatted].sharesOutstanding =
                        object.shares;
                }
                if (statement.quarterly[object.dateFormatted]) {
                    statement.quarterly[
                        object.dateFormatted
                    ].sharesOutstanding = object.shares;
                }
            });
        }

        return statement;
    };
    let income_statement = company.income_statement;
    let balance_sheet = company.balance_sheet;
    let cash_flow_statement = company.cash_flow_statement;
    let outstanding_shares = company.outstanding_shares_object;

    const ratios_to_add = [
        {
            id: "grossProfitRatio",
            numerator: "grossProfit",
            denominator: "totalRevenue",
        },
        { id: "ebitdaRatio", numerator: "ebitda", denominator: "totalRevenue" },
        {
            id: "operatingIncomeRatio",
            numerator: "operatingIncome",
            denominator: "totalRevenue",
        },
        {
            id: "incomeBeforeTaxRatio",
            numerator: "incomeBeforeTax",
            denominator: "totalRevenue",
        },
        {
            id: "netIncomeRatio",
            numerator: "netIncome",
            denominator: "totalRevenue",
        },
        {
            id: "freeCashFlowRatio",
            numerator: "freeCashFlow",
            denominator: "totalRevenue",
        },
        {
            id: "debtRatio",
            numerator: "shortLongTermDebtTotal",
            denominator: "totalAssets",
        },
    ];

    const provideOtherMetrics = (ratios_to_add) => {
        let new_whole_statement = provide_statement(
            income_statement,
            cash_flow_statement,
            balance_sheet,
            outstanding_shares
        );

        // YEARLY DATA
        Object.entries(new_whole_statement.yearly).map((loop_values) => {
            let key = loop_values[0];
            let value = loop_values[1];

            ratios_to_add.map((metric) => {
                new_whole_statement.yearly[key] = {
                    ...new_whole_statement.yearly[key],
                    [metric.id]: getRatioPercent(
                        value[metric.numerator],
                        value[metric.denominator]
                    ),
                };
            });
        });

        // QUARTERLY DATA
        Object.entries(new_whole_statement.quarterly).map((loop_values) => {
            let key = loop_values[0];
            let value = loop_values[1];

            ratios_to_add.map((metric) => {
                new_whole_statement.quarterly[key] = {
                    ...new_whole_statement.quarterly[key],
                    [metric.id]: getRatioPercent(
                        value[metric.numerator],
                        value[metric.denominator]
                    ),
                };
            });
        });

        return new_whole_statement;
    };

    let whole_statement = provideOtherMetrics(ratios_to_add);

    // HANDLE THE DISPLAYED TIME PERIODS
    const [period, setPeriod] = React.useState("yearly");
    const handlePeriodYearly = () => {
        let old_dates = allDates;

        old_dates.map((date, idx) => {
            old_dates[idx] = date.slice(0, -2);
        });

        let old_min = old_dates[dateRange[0]];
        let old_max = old_dates[dateRange[1]];

        let new_dates = [];
        Object.keys(whole_statement["yearly"]).map((time) => {
            new_dates = [...new_dates, time.substring(0, 4)];
        });

        // FIND INDEX OF YEAR IN QUARTERLY MIN AND MAX

        setDateRange([
            new_dates.indexOf(old_min) > 0 && dateRange[0] !== 0
                ? new_dates.indexOf(old_min)
                : 0,
            new_dates.lastIndexOf(old_max) >= 0 &&
            dateRange[1] !== old_dates.length - 1
                ? new_dates.lastIndexOf(old_max)
                : new_dates.length - 1,
        ]);
        setPeriod("yearly");
    };
    const handlePeriodQuarterly = () => {
        let old_dates = allDates;

        console.log("YEARLY: ", old_dates);

        let old_min = old_dates[dateRange[0]];
        let old_max = old_dates[dateRange[1]];

        if (period === "yearly") {
            let new_dates = [];
            Object.keys(whole_statement["quarterly"]).map((time) => {
                new_dates = [...new_dates, time.substring(0, 4)];
            });

            // FIND INDEX OF YEAR IN QUARTERLY MIN AND MAX

            setDateRange([
                new_dates.indexOf(old_min) > 0 && dateRange[0] !== 0
                    ? new_dates.indexOf(old_min) - 1
                    : 0,
                new_dates.lastIndexOf(old_max) >= 0 &&
                dateRange[1] !== old_dates.length - 1
                    ? new_dates.lastIndexOf(old_max) - 1
                    : new_dates.length - 1,
            ]);
        } else {
            let new_dates = [];
            Object.keys(whole_statement["quarterly"]).map((time) => {
                new_dates = [...new_dates, getQuarter(new Date(time))];
            });

            // FIND INDEX OF YEAR IN QUARTERLY MIN AND MAX

            setDateRange([
                new_dates.indexOf(old_min) > 0 && dateRange[0] !== 0
                    ? new_dates.indexOf(old_min)
                    : 0,
                new_dates.lastIndexOf(old_max) >= 0 &&
                dateRange[1] !== old_dates.length - 1
                    ? new_dates.lastIndexOf(old_max)
                    : new_dates.length - 1,
            ]);
        }
        setPeriod("quarterly");
    };
    const handlePeriodTtm = () => {
        let old_dates = allDates;

        if (period === "quarterly") {
            let old_min = old_dates[dateRange[0]];
            let old_max = old_dates[dateRange[1]];

            let new_dates = [];
            Object.keys(whole_statement["quarterly"]).map((time) => {
                new_dates = [...new_dates, getQuarter(new Date(time))];
            });
            new_dates = new_dates.slice(3);

            setDateRange([
                new_dates.indexOf(old_min) > 0 && dateRange[0] !== 0
                    ? new_dates.indexOf(old_min)
                    : 0,
                new_dates.lastIndexOf(old_max) >= 0 &&
                dateRange[1] !== old_dates.length - 1
                    ? new_dates.lastIndexOf(old_max)
                    : new_dates.length - 1,
            ]);
        } else if (period === "yearly") {
            let old_min = old_dates[dateRange[0]];
            let old_max = old_dates[dateRange[1]];

            let new_dates = [];
            Object.keys(whole_statement["quarterly"]).map((time) => {
                new_dates = [...new_dates, time.substring(0, 4)];
            });
            new_dates = new_dates.slice(3);

            setDateRange([
                new_dates.indexOf(old_min) > 0 && dateRange[0] !== 0
                    ? new_dates.indexOf(old_min) - 1
                    : 0,
                new_dates.lastIndexOf(old_max) >= 0 &&
                dateRange[1] !== old_dates.length - 1
                    ? new_dates.lastIndexOf(old_max) - 1
                    : new_dates.length - 1,
            ]);
        }

        setPeriod("ttm");
    };

    const provideDates = () => {
        let dates = [];

        if (period === "yearly") {
            Object.keys(whole_statement["yearly"]).map((time) => {
                dates = [...dates, time.substring(0, 4)];
            });
        } else {
            Object.keys(whole_statement["quarterly"]).map((time) => {
                dates = [...dates, getQuarter(new Date(time))];
            });
        }

        return period === "ttm" ? dates.slice(3) : dates;
    };
    const allDates = provideDates();

    // DATE RANGE SLIDER COMPONENT
    const [dateRange, setDateRange] = React.useState([
        0,
        allDates ? allDates.length - 1 : 0,
    ]);
    const handleDateRange = (event, newValue) => {
        setDateRange(newValue);
    };
    // TOOLTIP STYLING
    function ValueLabelComponent(props) {
        const { children, open, value } = props;

        const popperRef = React.useRef(null);
        useEffect(() => {
            if (popperRef.current) {
                popperRef.current.update();
            }

            // eslint-disable-next-line
        }, []);

        return (
            <Tooltip
                PopperProps={{
                    popperRef,
                }}
                open={open}
                enterTouchDelay={0}
                placement='top'
                title={
                    allDates
                        ? // new Intl.DateTimeFormat("en-us", {
                          //       // weekday: "long",
                          //       year: "numeric",
                          //       month: "long",
                          //       // day: "numeric",
                          //   }).format(new Date(allDates[value]))
                          allDates[value]
                        : "-"
                }
            >
                {children}
            </Tooltip>
        );
    }
    ValueLabelComponent.propTypes = {
        children: PropTypes.element.isRequired,
        open: PropTypes.bool.isRequired,
        value: PropTypes.number.isRequired,
    };

    // METRICS OBJECTS IN OBJECT
    let MetricObject = {
        total_revenue_arr: {
            id: 0,
            name: useintl.formatMessage({
                id: `metrics.revenue`,
            }),
            property: "total_revenue_arr",
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[0])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[0])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            data: company.total_revenue_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "revenue, sales",
            per_share_possible: true,
            relative_change_possible: true,
        },
        gross_profit_arr: {
            id: 1,
            name: useintl.formatMessage({
                id: `metrics.gross_profit`,
            }),
            property: "gross_profit_arr",
            type: "area",
            color: Highcharts.getOptions().colors[1],
            yAxis: 0,
            selected: false,
            data: company.gross_profit_arr
                .slice(4)
                .map((ratio, index) =>
                    ratio !== null ? Number(ratio.toPrecision(3)) : null
                ),
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "profit, gross,",
            per_share_possible: true,
        },
        net_income_arr: {
            id: 1,
            name: useintl.formatMessage({
                id: `metrics.net_income`,
            }),
            property: "net_income_arr",
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[2],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            data: company.net_income_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            per_share_possible: true,
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "net income, net, income, earnings",
        },
        free_cashflow_arr: {
            id: 2,
            name: useintl.formatMessage({
                id: `metrics.free_cash_flow`,
            }),
            property: "free_cashflow_arr",
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[6],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[6])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[6])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            data: company.free_cashflow_arr
                .slice(4)
                .map((cashflow, index) =>
                    cashflow !== null ? Number(cashflow.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "cashflow, operating cashflow, operative cashflow",
            per_share_possible: true,
        },
        ebit_arr: {
            id: 3,
            name: useintl.formatMessage({
                id: `metrics.ebit`,
            }),
            property: "ebit_arr",
            type: "area",
            color: Highcharts.getOptions().colors[1],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[1])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            selected: false,
            data: company.ebit_arr
                .slice(4)
                .map((ebit, index) =>
                    ebit !== null ? Number(ebit.toPrecision(3)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords:
                "EBIT, ebit, earnings before tax and interest, earnings before interest and tax",
            per_share_possible: true,
        },
        dividends_paid_arr: {
            id: 4,
            name: useintl.formatMessage({
                id: `metrics.dividend`,
            }),
            property: "dividends_paid_arr",
            type: "column",
            selected: false,
            color: Highcharts.getOptions().colors[3],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 0,
            data: company.dividends_paid_arr
                .slice(4)
                .map((dividend, index) =>
                    dividend !== null
                        ? -1 * Number(dividend.toPrecision(4))
                        : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "dividend, payout, dividends, yield",
            per_share_possible: true,
        },
        total_liabilities_arr: {
            id: 5,
            name: useintl.formatMessage({
                id: `metrics.total_liabilities`,
            }),
            property: "total_liabilities_arr",
            type: "column",
            selected: false,
            color: Highcharts.getOptions().colors[1],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 0,
            data: company.total_liabilities_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "liabilities, total",
            per_share_possible: true,
        },
        total_current_liabilities_arr: {
            id: 6,
            name: useintl.formatMessage({
                id: `metrics.total_current_liabilities`,
            }),
            property: "total_current_liabilities_arr",
            type: "column",
            selected: false,
            color: Highcharts.getOptions().colors[5],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 0,
            data: company.total_current_liabilities_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "current, liabilities, total",
            per_share_possible: true,
        },
        total_assets_arr: {
            id: 7,
            name: useintl.formatMessage({
                id: `metrics.total_assets`,
            }),
            property: "total_assets_arr",
            type: "column",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 0,
            data: company.total_assets_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "assets, total",
            per_share_possible: true,
        },
        total_current_assets_arr: {
            id: 8,
            name: useintl.formatMessage({
                id: `metrics.total_current_assets`,
            }),
            property: "total_current_assets_arr",
            type: "column",
            selected: false,
            color: Highcharts.getOptions().colors[5],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 0,
            data: company.total_current_assets_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "current, assets, total",
            per_share_possible: true,
        },
        outstanding_shares_arr: {
            id: 9,
            name: useintl.formatMessage({
                id: `metrics.outstanding_shares`,
            }),
            property: "outstanding_shares_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 3,
            data: company.outstanding_shares_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "outstanding, shares",
            per_share_possible: false,
        },
        price_earnings_arr: {
            id: 10,
            name: useintl.formatMessage({
                id: `metrics.price_earnings`,
            }),
            property: "price_earnings_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.price_earnings_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "price, earnings, net, income, ratio, multiple",
            per_share_possible: false,
        },
        price_sales_arr: {
            id: 11,
            name: useintl.formatMessage({
                id: `metrics.price_sales`,
            }),
            property: "price_sales_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.price_sales_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "revenue, sales, price, multiple",
            per_share_possible: false,
        },
        gross_profit_margin_arr: {
            id: 12,
            name: useintl.formatMessage({
                id: `metrics.gross_profit_margin`,
            }),
            property: "gross_profit_margin_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[1],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.gross_profit_margin_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            filter_keywords: "margin, gross, profit, gross profit margin",
            per_share_possible: false,
        },
        net_profit_margin_arr: {
            id: 13,
            name: useintl.formatMessage({
                id: `metrics.net_profit_margin`,
            }),
            property: "net_profit_margin_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[3],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.net_profit_margin_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            filter_keywords:
                "margin, net, income, net income, net margin, net income margin",
            per_share_possible: false,
        },
        operating_cash_flow_margin_arr: {
            id: 14,
            name: useintl.formatMessage({
                id: `metrics.operating_cash_flow_margin`,
            }),
            property: "operating_cash_flow_margin_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.operating_cash_flow_margin_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "margin, operating, cashflow",
            per_share_possible: false,
        },
        operating_margin_arr: {
            id: 15,
            name: useintl.formatMessage({
                id: `metrics.operating_margin`,
            }),
            property: "operating_margin_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.operating_margin_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "margin, operating",
            per_share_possible: false,
        },
        dividend_yield_arr: {
            id: 16,
            name: useintl.formatMessage({
                id: `metrics.dividend_yield`,
            }),
            property: "dividend_yield_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.dividend_yield_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "dividend, payout, dividends, yield",
            per_share_possible: false,
        },
        payout_ratio_arr: {
            id: 17,
            name: useintl.formatMessage({
                id: `metrics.payout_ratio`,
            }),
            property: "payout_ratio_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[4],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.payout_ratio_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            filter_keywords: "payout ratio, earnings, dividends",
            per_share_possible: false,
        },
        debt_ratio_arr: {
            id: 18,
            name: useintl.formatMessage({
                id: `metrics.debt_ratio`,
            }),
            property: "debt_ratio_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[4],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 1,
            data: company.debt_ratio_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            filter_keywords: "debt, ratio",
            per_share_possible: false,
        },
        price_book_arr: {
            id: 19,
            name: useintl.formatMessage({
                id: `metrics.price_book`,
            }),
            property: "price_book_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.price_book_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "price book",
            per_share_possible: false,
        },
        return_on_assets_arr: {
            id: 20,
            name: useintl.formatMessage({
                id: `metrics.return_on_assets`,
            }),
            property: "return_on_assets_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.return_on_assets_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "return on assets",
            per_share_possible: false,
        },
        asset_turnover_arr: {
            id: 21,
            name: useintl.formatMessage({
                id: `metrics.asset_turnover`,
            }),
            property: "asset_turnover_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.asset_turnover_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "revenue, sales, price, multiple",
            per_share_possible: false,
        },
        cash_flow_on_assets_arr: {
            id: 22,
            name: useintl.formatMessage({
                id: `metrics.cash_flow_on_assets`,
            }),
            property: "cash_flow_on_assets_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.cash_flow_on_assets_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "cash flow on assets",
            per_share_possible: false,
        },
        return_on_equity_arr: {
            id: 23,
            name: useintl.formatMessage({
                id: `metrics.return_on_equity`,
            }),
            property: "return_on_equity_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.return_on_equity_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "return on equity",
            per_share_possible: false,
        },
        revenue_on_equity_arr: {
            id: 24,
            name: useintl.formatMessage({
                id: `metrics.revenue_on_equity`,
            }),
            property: "revenue_on_equity_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.revenue_on_equity_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "revenue on equity",
            per_share_possible: false,
        },
        return_on_capital_employed_arr: {
            id: 25,
            name: useintl.formatMessage({
                id: `metrics.return_on_capital_employed`,
            }),
            property: "return_on_capital_employed_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.return_on_capital_employed_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "return on capital employed",
            per_share_possible: false,
        },
        debt_to_ebit_arr: {
            id: 26,
            name: useintl.formatMessage({
                id: `metrics.debt_to_ebit`,
            }),
            property: "debt_to_ebit_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.debt_to_ebit_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "debt to ebit",
            per_share_possible: false,
        },
        debt_to_equity_arr: {
            id: 27,
            name: useintl.formatMessage({
                id: `metrics.debt_to_equity`,
            }),
            property: "debt_to_equity_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.debt_to_equity_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "debt to equity",
            per_share_possible: false,
        },
        debt_to_revenue_arr: {
            id: 28,
            name: useintl.formatMessage({
                id: `metrics.debt_to_revenue`,
            }),
            property: "debt_to_revenue_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.debt_to_revenue_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "debt to revenue",
            per_share_possible: false,
        },
        current_ratio_arr: {
            id: 29,
            name: useintl.formatMessage({
                id: `metrics.current_ratio`,
            }),
            property: "current_ratio_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.current_ratio_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "current ratio",
            per_share_possible: false,
        },
        price_cash_flow_arr: {
            id: 30,
            name: useintl.formatMessage({
                id: `metrics.price_cash_flow`,
            }),
            property: "price_cash_flow_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.price_cash_flow_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "price cash flow",
            per_share_possible: false,
        },
        return_on_capital_arr: {
            id: 31,
            name: useintl.formatMessage({
                id: `metrics.return_on_capital`,
            }),
            property: "return_on_capital_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.return_on_capital_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "return on capital",
            per_share_possible: false,
        },
        return_on_net_tangible_assets_arr: {
            id: 32,
            name: useintl.formatMessage({
                id: `metrics.return_on_net_tangible_assets`,
            }),
            property: "return_on_net_tangible_assets_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.return_on_net_tangible_assets_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "return on net tangible assets",
            per_share_possible: false,
        },
        price_ebit_arr: {
            id: 33,
            name: useintl.formatMessage({
                id: `metrics.price_ebit`,
            }),
            property: "price_ebit_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.price_ebit_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "price ebit",
            per_share_possible: false,
        },
        ev_ebit_arr: {
            id: 34,
            name: useintl.formatMessage({
                id: `metrics.ev_ebit`,
            }),
            property: "ev_ebit_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.ev_ebit_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "ev ebit",
            per_share_possible: false,
        },
        ev_ebitda_arr: {
            id: 35,
            name: useintl.formatMessage({
                id: `metrics.ev_ebitda`,
            }),
            property: "ev_ebitda_arr",
            type: "line",
            selected: false,
            color: Highcharts.getOptions().colors[0],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            yAxis: 2,
            data: company.ev_ebitda_arr
                .slice(4)
                .map((value, index) =>
                    value !== null ? Number(value.toPrecision(4)) : null
                ),
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value);
                },
            },
            filter_keywords: "ev ebitda",
            per_share_possible: false,
        },
        ebitda_arr: {
            id: 36,
            name: useintl.formatMessage({
                id: `metrics.ebitda`,
            }),
            property: "ebitda_arr",
            type: "area",
            selected: false,
            color: Highcharts.getOptions().colors[1],
            lineWidth: 1,
            marker: {
                radius: 2,
                symbol: "circle",
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [
                        0,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.9)
                            .get("rgba"),
                    ],
                    [
                        1,
                        Highcharts.Color(Highcharts.getOptions().colors[2])
                            .setOpacity(0.2)
                            .get("rgba"),
                    ],
                ],
            },
            yAxis: 0,
            data: company.ebitda_arr
                .slice(4)
                .map((ratio) =>
                    ratio !== null ? Number(ratio.toPrecision(4)) : null
                ),
            per_share_possible: true,
            relative_change_possible: true,
            labels: {
                formatter: function () {
                    return numberFormatter(this.value) + company.currency;
                },
            },
            filter_keywords: "ebitda",
        },
    };

    // SORTED METRICS FOR CREATOR DIALOG
    let sorted_metrics_object = {
        income_statement: [
            "total_revenue_arr",
            "gross_profit_arr",
            "ebitda_arr",
            "ebit_arr",
            "net_income_arr",
        ],
        income_statement_ratios: [
            "gross_profit_margin_arr",
            "operating_margin_arr",
            "net_profit_margin_arr",
            "price_sales_arr",
            "price_earnings_arr",
            "price_ebit_arr",
            "return_on_assets_arr",
            "asset_turnover_arr",
            "return_on_equity_arr",
            "revenue_on_equity_arr",
            "return_on_capital_employed_arr",
            "return_on_capital_arr",
            "return_on_net_tangible_assets_arr",
        ],
        balance_sheet: [
            "total_assets_arr",
            "total_current_assets_arr",
            "total_liabilities_arr",
            "total_current_liabilities_arr",
            "dividends_paid_arr",
            "outstanding_shares_arr",
        ],
        balance_sheet_ratios: [
            "ev_ebit_arr",
            "ev_ebitda_arr",
            "debt_ratio_arr",
            "payout_ratio_arr",
            "dividend_yield_arr",
            "price_book_arr",
            "debt_to_ebit_arr",
            "debt_to_equity_arr",
            "debt_to_revenue_arr",
            "current_ratio_arr",
        ],
        cash_flow_statement: ["free_cashflow_arr"],
        cash_flow_statement_ratios: [
            "price_cash_flow_arr",
            "operating_cash_flow_margin_arr",
            "cash_flow_on_assets_arr",
        ],
    };

    useEffect(() => {
        // eslint-disable-next-line
    }, [ticker]);

    /*SWITCH BETWEEN PREDEFINED AND INDIVIDUAL CHART*/
    const [openindividual, setOpenIndividual] = React.useState(false);
    const handleOpenIndividual = () => {
        setOpenIndividual(true);
    };
    const handleCloseIndividual = () => {
        setOpenIndividual(false);
    };

    // PER SHARE FUNCTIONALITY
    const [chartPerShare, setChartPerShare] = React.useState(false);
    const handleClickPerShare = () => {
        setChartPerShare(!chartPerShare);
    };

    // CHANGE IN %
    const [chartChangePercent, setChartChangePercent] = React.useState(false);
    const handleClickChangePercent = () => {
        setChartChangePercent(!chartChangePercent);
        if (!chartChangePercent) {
            setChartChangeAbsolute(false);
        }
    };

    // CHANGE IN ABSOLUTE NUMBERS
    const [chartChangeAbsolute, setChartChangeAbsolute] = React.useState(false);
    const handleClickChangeAbsolute = () => {
        setChartChangeAbsolute(!chartChangeAbsolute);
        if (!chartChangeAbsolute) {
            setChartChangePercent(false);
        }
    };

    // FEEDBACK COMPONENT
    let feedbackInfo = {
        location: `${useintl.formatMessage({
            id: "general.company",
        })} - ${useintl.formatMessage({
            id: "company.charts",
        })}`,
        location_en: "Company - Charts",
        location_index: 4,
    };

    return (
        <div>
            {company.company === null ? (
                <Preloader />
            ) : (
                <div>
                    <FeedbackIcon feedbackInfo={feedbackInfo} />
                    <div className='flex center width_100pr padding_10px text_1'>
                        {openindividual ? "Chart Creator" : "Preset Charts"}
                    </div>
                    <div className='flex width_100pr ps_20px'>
                        <div
                            className='width_100px text_center background_color_4 border_radius_5px pl_10px pr_10px pt_5px pb_5px'
                            // aria-describedby={idMin}
                            // onClick={handleOpenMinModal}
                        >
                            {/* {new Intl.DateTimeFormat("en-us", {
                                // weekday: "long",
                                year: "numeric",
                                month: "numeric",
                                // day: "numeric",
                            }).format(new Date(allDates[dateRange[0]]))} */}
                            {allDates[dateRange[0]]}
                        </div>
                        <Slider
                            step={1}
                            components={{
                                ValueLabel: ValueLabelComponent,
                            }}
                            id='company_years_slider'
                            name='company_years_slider'
                            className='width_auto ml_20px mr_20px'
                            min={0}
                            max={allDates ? allDates.length - 1 : null}
                            value={dateRange}
                            onChange={handleDateRange}
                            // onMouseUp={handleDateRange}
                            size='small'
                            valueLabelDisplay='auto'
                            aria-label='range-slider'
                            aria-labelledby='range-slider'
                            marks
                        />
                        <div
                            className='width_100px text_center background_color_4 border_radius_5px pl_10px pr_10px pt_5px pb_5px'
                            // aria-describedby={idMax}
                            // onClick={handleOpenMaxModal}
                        >
                            {/* {new Intl.DateTimeFormat("en-us", {
                                // weekday: "long",
                                year: "numeric",
                                month: "numeric",
                                // day: "numeric",
                            }).format(new Date(allDates[dateRange[1]]))} */}
                            {allDates[dateRange[1]]}
                        </div>
                    </div>
                    <div className='width_100pr flex center height_40px mt_20px background_color_3'>
                        <div className='width_300px flex'>
                            <div
                                className={
                                    period === "yearly"
                                        ? "width_50pr padding_10px cursor_pointer background_color_4"
                                        : "width_50pr padding_10px cursor_pointer"
                                }
                                onClick={handlePeriodYearly}
                            >
                                Yearly
                            </div>
                            <div
                                className={
                                    period === "quarterly"
                                        ? "width_50pr padding_10px cursor_pointer background_color_4"
                                        : "width_50pr padding_10px cursor_pointer"
                                }
                                onClick={handlePeriodQuarterly}
                            >
                                Quarterly
                            </div>
                            <div
                                className={
                                    period === "ttm"
                                        ? "width_50pr padding_10px cursor_pointer background_color_4"
                                        : "width_50pr padding_10px cursor_pointer"
                                }
                                onClick={handlePeriodTtm}
                            >
                                TTM
                            </div>
                        </div>
                    </div>
                    {openindividual ? (
                        <div>
                            <div className='flex space_between ml_20px mr_20px mt_5px'>
                                <div className='flex left wrap'>
                                    {/* PRESETS */}
                                    <div
                                        className='button_blue click_translate padding_10px mr_10px mt_10px hover_opacity_80'
                                        onClick={handleCloseIndividual}
                                    >
                                        {useintl.formatMessage({
                                            id: `company.preset_charts`,
                                        })}
                                    </div>
                                    {/* RELATIVE CHANGE */}
                                    <div
                                        className={
                                            chartChangePercent
                                                ? "button_white_active click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                                : "button_white_layer_2 click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                        }
                                        onClick={handleClickChangePercent}
                                    >
                                        Change [%]
                                    </div>
                                    {/* ABSOLUTE CHANGE */}
                                    <div
                                        className={
                                            chartChangeAbsolute
                                                ? "button_white_active click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                                : "button_white_layer_2 click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                        }
                                        onClick={handleClickChangeAbsolute}
                                    >
                                        Change [abs]
                                    </div>
                                    {/* PS */}
                                    <div
                                        className={
                                            chartPerShare
                                                ? "button_white_active click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                                : "button_white_layer_2 click_translate padding_10px mr_10px mt_10px hover_opacity_80"
                                        }
                                        onClick={handleClickPerShare}
                                    >
                                        {useintl.formatMessage({
                                            id: `company.per_share`,
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='ml_20px_0px mr_20px mt_15px'>
                                <div className='company_charts_header'></div>
                                <div className='divider mb_20px'></div>

                                <Individual
                                    allDates={allDates}
                                    selected_date_range={dateRange}
                                    whole_statement={whole_statement}
                                    income_statement_object={
                                        income_statement_object
                                    }
                                    balance_sheet_object={balance_sheet_object}
                                    cash_flow_statement_object={
                                        cash_flow_statement_object
                                    }
                                    whole_statement_object={
                                        whole_statement_object
                                    }
                                    chartPerShare={chartPerShare}
                                    chartChangePercent={chartChangePercent}
                                    chartChangeAbsolute={chartChangeAbsolute}
                                    period={period}
                                    ticker={ticker}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='flex pl_20px mt_5px'>
                                {/* <div
                                    className='button_blue color_white pl_10px pr_10px hover_opacity_80'
                                    onClick={handleCloseIndividual}
                                >
                                    Preset Charts
                                </div> */}
                                <div
                                    className='button_blue click_translate color_white mt_10px padding_10px hover_opacity_80'
                                    onClick={handleOpenIndividual}
                                >
                                    {useintl.formatMessage({
                                        id: `company.chart_creator`,
                                    })}
                                </div>
                            </div>

                            <div className='mt_15px'>
                                <div className='company_charts_header'></div>
                                <div className='divider mb_20px'></div>
                                <div className='flex flex_column width_100pr'>
                                    <RevenueAnnual
                                        allDates={allDates}
                                        selected_date_range={dateRange}
                                        whole_statement={whole_statement}
                                        whole_statement_object={
                                            whole_statement_object
                                        }
                                        period={period}
                                        ticker={ticker}
                                    />
                                    {/* <EarningsAnnual
                                        allDates={allDates}
                                        selected_date_range={dateRange}
                                        whole_statement={whole_statement}
                                        period={period}
                                        ticker={ticker}
                                    /> */}
                                    {/* <SharesOutstanding
                                        allDates={allDates}
                                        selected_date_range={dateRange}
                                        whole_statement={whole_statement}
                                        period={period}
                                        ticker={ticker}
                                    /> */}
                                    {/* <Assets
                                        allDates={allDates}
                                        selected_date_range={dateRange}
                                        whole_statement={whole_statement}
                                        period={period}
                                        ticker={ticker}
                                    /> */}
                                    {/* <Valuation
                                        allDates={allDates}
                                        selected_date_range={dateRange}
                                        whole_statement={whole_statement}
                                        period={period}
                                        ticker={ticker}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

Charts.propTypes = {
    company: PropTypes.object.isRequired,
    numberFormatter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
});

export default connect(mapStateToProps, {
    numberFormatter,
})(Charts);
