import { SET_ALERT, REMOVE_ALERT } from "./Types";

// Get User id, portfolios and watchlists
export const setAlert =
    (msg, type, timeout = 5000) =>
    async (dispatch) => {
        const id = Math.floor(Math.random() * 100);

        dispatch({
            type: SET_ALERT,
            payload: { msg, type, id },
        });

        setTimeout(
            () => dispatch({ type: REMOVE_ALERT, payload: id }),
            timeout
        );
    };
