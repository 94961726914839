import React from "react";
import { useIntl } from "react-intl";

// ICONS
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as IoIcons5 from "react-icons/io5";

// REDUX
import { connect } from "react-redux";
import PropTypes from "prop-types";

// COMPONENTS
import CreatePortfolio from "./CreatePortfolio";
import CreateWatchlist from "./CreateWatchlist";
import TransactionForm from "./TransactionForm";
import RemoveFromPortfolio from "./RemoveFromPortfolio";
import AddToWatchlist from "./AddToWatchlist";

// MUI
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";

// ACTIONS
import { setBookmark } from "../../../actions/bookmarkActions";
import {
    addWatchlistCompany,
    deleteWatchlistCompany,
    getWatchlist,
    setWatchlist,
} from "../../../actions/watchlistActions";
import { setPortfolio } from "../../../actions/portfolioActions";

function TransitionUp(props) {
    return <Slide {...props} direction='up' />;
}
function TransitionLefta(props) {
    return <Slide {...props} direction='left' />;
}
function TransitionLeftb(props) {
    return <Slide {...props} direction='left' />;
}

const CompanyActionsMenu = ({
    company,
    portfolioType,
    recommendations,
    type,
    screener: { options },
    bookmark: { bookmarks },
    setBookmark,
    user,
    watchlist,
    deleteWatchlistCompany,
    getWatchlist,
    portfolio,
}) => {
    const useintl = useIntl();
    let currentWatchlist = watchlist.watchlists.filter((obj) => {
        return obj.id === watchlist.current;
    });
    let currentPortfolio = portfolio.portfolios.filter((obj) => {
        return obj.id === portfolio.current;
    });
    currentPortfolio = currentPortfolio[0];

    // DESCRIPTION MODAL
    const [openDescriptionRow, setOpenDescriptionRow] = React.useState(false);
    const handleShowDescriptionRow = () => {
        setOpenDescriptionRow(true);
    };
    const handleCloseDescriptionRow = () => {
        setOpenDescriptionRow(false);
    };

    // WATCHLISTS
    // CREATE NEW WATCHLIST
    const [openCreateWatchlist, setOpenCreateWatchlist] = React.useState(false);
    const handleClickOpenCreateWatchlist = () => {
        setOpenCreateWatchlist(true);
    };
    const handleCloseCreateWatchlist = () => {
        setOpenCreateWatchlist(false);
    };

    // ADD TO WATCHLIST
    const [openAddToWatchlist, setOpenAddToWatchlist] = React.useState(false);
    const handleClickOpenAddToWatchlist = () => {
        setOpenAddToWatchlist(true);
    };
    const handleCloseAddToWatchlist = () => {
        setOpenAddToWatchlist(false);
    };

    /*Watchlist Success Snackbar*/
    const [openWatchlistSuccess, setOpenWatchlistSuccess] =
        React.useState(false);
    const handleClickWatchlistSuccess = () => {
        setOpenWatchlistSuccess(false);
        setOpenWatchlistSuccess(true);
    };
    const handleCloseWatchlistSuccess = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenWatchlistSuccess(false);
    };

    /*Remove from Watchlist Modal*/
    const [openRemoveFromWatchlist, setOpenRemoveFromWatchlist] =
        React.useState(false);
    const handleClickOpenRemoveFromWatchlist = () => {
        setOpenRemoveFromWatchlist(true);
    };
    const handleCloseRemoveFromWatchlist = () => {
        setOpenRemoveFromWatchlist(false);
    };
    const handleDeleteWatchlistCompany = () => {
        deleteWatchlistCompany(company.ticker, watchlist, user.id);
        getWatchlist(
            currentWatchlist[0].id,
            user.id,
            options.sortWatchlistBy.value,
            options.sortWatchlistBy.direction
        );
        handleCloseRemoveFromWatchlist();
    };

    // PORTFOLIOS
    // CREATE PORTFOLIO MODAL
    const [openCreatePortfolio, setOpenCreatePortfolio] = React.useState(false);
    const handleClickOpenCreatePortfolio = () => {
        setOpenCreatePortfolio(true);
    };
    const handleClickCloseCreatePortfolio = () => {
        setOpenCreatePortfolio(false);
    };

    // ADD TO PORTFOLIO/ TRANSACTION
    const [openAddToPortfolio, setOpenAddToPortfolio] = React.useState(false);
    const handleClickOpenAddToPortfolio = () => {
        setOpenAddToPortfolio(true);
    };
    const handleCloseAddToPortfolio = () => {
        setOpenAddToPortfolio(false);
    };

    // REMOVE FROM PORTFOLIO
    const [openRemoveFromPortfolio, setOpenRemoveFromPortfolio] =
        React.useState(false);
    const handleClickOpenRemoveFromPortfolio = () => {
        setOpenRemoveFromPortfolio(true);
    };
    const handleCloseRemoveFromPortfolio = () => {
        setOpenRemoveFromPortfolio(false);
    };

    // BOOKMARKS
    /*Bookmark Alert Snackbar*/
    const [openBookmarkAlert, setOpenBookmarkAlert] = React.useState(false);
    const handleClickBookmarkAlert = () => {
        setOpenBookmarkAlert(false);
        setOpenBookmarkSuccess(false);
        setOpenBookmarkAlert(true);
    };
    const handleCloseBookmarkAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenBookmarkAlert(false);
    };
    /*Bookmark Success Snackbar*/
    const [openBookmarkSuccess, setOpenBookmarkSuccess] = React.useState(false);
    const handleClickBookmarkSuccess = () => {
        setOpenBookmarkAlert(false);
        setOpenBookmarkSuccess(false);
        setOpenBookmarkSuccess(true);
    };
    const handleCloseBookmarkSuccess = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenBookmarkSuccess(false);
    };
    const handleSetBookmark = (bookmark) => {
        let checkForBookmark = bookmarks.filter(
            (item) => item.ticker === bookmark.ticker
        );
        if (checkForBookmark.length == 0) {
            setBookmark(bookmark);
            handleClickBookmarkSuccess();
        } else {
            handleClickBookmarkAlert();
        }
    };

    // Actions
    const [openActions, setOpenActions] = React.useState(null);
    const handleOpenActions = (event) => {
        setOpenActions(event.currentTarget);
    };
    const handleCloseActions = () => {
        setOpenActions(null);
    };

    return (
        <div>
            {type && type === 1 ? (
                <div className='flex right text_right pr_10px'>
                    <div
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        className='cursor_pointer button_blue hover_opacity_80 min_width_80px padding_5px flex'
                        onClick={handleOpenActions}
                    >
                        <div>Add to</div>
                        <MdIcons.MdAddCircleOutline className='ml_5px text_2' />
                    </div>
                    <Menu
                        id='simple-menu'
                        anchorEl={openActions}
                        keepMounted
                        open={Boolean(openActions)}
                        onClose={handleCloseActions}
                    >
                        {/* BOOKMARK */}
                        <MenuItem
                            className='background_color_3'
                            onClick={() => {
                                handleSetBookmark({
                                    ticker: company.ticker,
                                    name: company.name ? company.name : null,
                                    industry: company.industry
                                        ? company.industry
                                        : null,
                                    id: company.ticker,
                                    compare: true,
                                });
                                handleCloseActions();
                            }}
                        >
                            <div className='flex left'>
                                <MdIcons.MdCompareArrows className='screener_icon_info text_1 cursor_pointer' />
                                <div className='ml_10px mr_5px'>
                                    {useintl.formatMessage({
                                        id: "general.bookmark",
                                    })}
                                </div>
                            </div>
                        </MenuItem>

                        {/* WATCHLIST */}
                        {user.status !== 80 ? (
                            window.location.pathname === "/watchlist" ? (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenRemoveFromWatchlist();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <IoIcons5.IoTrashOutline className='screener_icon_info text_1 cursor_pointer' />
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.remove",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenAddToWatchlist();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <MdIcons.MdBookmarkBorder className='screener_icon_info text_1 cursor_pointer' />
                                        {/* <AiIcons.AiOutlineEye className='screener_icon_info text_1 cursor_pointer' /> */}
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.watchlist",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                        ) : null}

                        {/* PORTFOLIO */}
                        {user.status !== 80 ? (
                            window.location.pathname === "/portfolio" ? (
                                portfolioType &&
                                portfolioType === "tracker" ? null : (
                                    <MenuItem
                                        className='background_color_3'
                                        onClick={() => {
                                            handleClickOpenRemoveFromPortfolio();
                                            handleCloseActions();
                                        }}
                                    >
                                        <div className='flex left'>
                                            <IoIcons5.IoTrashOutline className='screener_icon_info text_1 cursor_pointer' />
                                            <div className='ml_10px mr_5px'>
                                                {useintl.formatMessage({
                                                    id: "general.remove",
                                                })}
                                            </div>
                                        </div>
                                    </MenuItem>
                                )
                            ) : (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenAddToPortfolio();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <RiIcons.RiDonutChartFill className='screener_icon_info text_1 cursor_pointer' />
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.portfolio",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                        ) : null}
                    </Menu>
                    {/* DESCRIPTION */}
                    {/* <MdIcons.MdInfoOutline
                        className='ml_5px mr_10px cursor_pointer text_1'
                        onClick={() => {
                            handleCloseActions();
                            handleShowDescriptionRow();
                        }}
                    >
                        <div className='flex left'>
                            <AiIcons.AiOutlineInfoCircle className='screener_icon_info text_1 cursor_pointer' />
                            <div className='ml_5px mr_5px'>
                                {useintl.formatMessage({
                                    id: "metrics.description",
                                })}
                            </div>
                        </div>
                    </MdIcons.MdInfoOutline> */}
                </div>
            ) : (
                <div className='text_left width_20px'>
                    <IoIcons5.IoEllipsisVerticalSharp
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        className='ml_5px mr_5px cursor_pointer text_1'
                        onClick={handleOpenActions}
                    />
                    <Menu
                        id='simple-menu'
                        anchorEl={openActions}
                        keepMounted
                        open={Boolean(openActions)}
                        onClose={handleCloseActions}
                    >
                        {/* DESCRIPTION */}
                        <MenuItem
                            className='background_color_3'
                            onClick={() => {
                                handleCloseActions();
                                handleShowDescriptionRow();
                            }}
                        >
                            <div className='flex left'>
                                <AiIcons.AiOutlineInfoCircle className='screener_icon_info text_1 cursor_pointer' />
                                <div className='ml_10px mr_5px'>
                                    {useintl.formatMessage({
                                        id: "metrics.description",
                                    })}
                                </div>
                            </div>
                        </MenuItem>

                        {/* BOOKMARK */}
                        <MenuItem
                            className='background_color_3'
                            onClick={() => {
                                handleSetBookmark({
                                    ticker: company.ticker,
                                    name: company.name ? company.name : null,
                                    industry: company.industry
                                        ? company.industry
                                        : null,
                                    id: company.ticker,
                                    compare: true,
                                });
                                handleCloseActions();
                            }}
                        >
                            <div className='flex left'>
                                <MdIcons.MdCompareArrows className='screener_icon_info text_1 cursor_pointer' />
                                <div className='ml_10px mr_5px'>
                                    {useintl.formatMessage({
                                        id: "general.bookmark",
                                    })}
                                </div>
                            </div>
                        </MenuItem>

                        {/* WATCHLIST */}
                        {user.status !== 80 ? (
                            window.location.pathname === "/watchlist" ? (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenRemoveFromWatchlist();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <IoIcons5.IoTrashOutline className='screener_icon_info text_1 cursor_pointer' />
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.remove",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenAddToWatchlist();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <MdIcons.MdBookmarkBorder className='screener_icon_info text_1 cursor_pointer' />
                                        {/* <AiIcons.AiOutlineEye className='screener_icon_info text_1 cursor_pointer' /> */}
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.watchlist",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                        ) : null}

                        {/* PORTFOLIO */}
                        {user.status !== 80 ? (
                            !recommendations &&
                            window.location.pathname === "/portfolio" &&
                            user.id === currentPortfolio.user_id ? (
                                portfolioType &&
                                portfolioType === "tracker" ? null : (
                                    <MenuItem
                                        className='background_color_3'
                                        onClick={() => {
                                            handleClickOpenRemoveFromPortfolio();
                                            handleCloseActions();
                                        }}
                                    >
                                        <div className='flex left'>
                                            <IoIcons5.IoTrashOutline className='screener_icon_info text_1 cursor_pointer' />
                                            <div className='ml_10px mr_5px'>
                                                {useintl.formatMessage({
                                                    id: "general.remove",
                                                })}
                                            </div>
                                        </div>
                                    </MenuItem>
                                )
                            ) : (
                                <MenuItem
                                    className='background_color_3'
                                    onClick={() => {
                                        handleClickOpenAddToPortfolio();
                                        handleCloseActions();
                                    }}
                                >
                                    <div className='flex left'>
                                        <RiIcons.RiDonutChartFill className='screener_icon_info text_1 cursor_pointer' />
                                        <div className='ml_10px mr_5px'>
                                            {useintl.formatMessage({
                                                id: "general.portfolio",
                                            })}
                                        </div>
                                    </div>
                                </MenuItem>
                            )
                        ) : null}
                    </Menu>
                </div>
            )}

            {/* Company Description Modal */}
            <Dialog
                open={openDescriptionRow}
                onClose={handleCloseDescriptionRow}
                maxWidth={false}
                className='width_70pr_95pr min_width_350px max_width_1000px ms_auto'
            >
                <div className='background_color_2'>
                    {/* HEADER */}
                    {window.innerWidth < 600 ? (
                        <div className='flex flex_column'>
                            <div className='flex space_between width_100pr title_5 mt_10px pl_15px pr_15px'>
                                <div>
                                    {company.name ? `${company.name}` : null}
                                </div>
                                <div
                                    className='cursor_pointer title_5 flex center pl_10px mt_5px'
                                    onClick={handleCloseDescriptionRow}
                                >
                                    <AiIcons.AiOutlineClose />
                                </div>
                            </div>
                            <div className='width_100pr flex left'>
                                <div className='pl_20px'>
                                    {company.ticker
                                        ? `${company.ticker}`
                                        : null}
                                </div>
                            </div>
                            <div className='width_100pr flex left'>
                                {company.sector ? (
                                    <div className='pl_20px'>
                                        {company.sector ? company.sector : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className='flex space_between title_5 mt_10px pl_15px pr_15px'>
                            <div className='flex'>
                                <div>
                                    {company.name
                                        ? `${company.name}   -   `
                                        : null}
                                    {company.ticker
                                        ? `${company.ticker}`
                                        : null}
                                </div>
                                {company.sector ? (
                                    <div className='flex'>
                                        <div className='pl_10px'>|</div>
                                        <div className='pl_10px'>
                                            {company.sector
                                                ? company.sector
                                                : null}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className='cursor_pointer title_5 flex center pl_10px mt_5px'
                                onClick={handleCloseDescriptionRow}
                            >
                                <AiIcons.AiOutlineClose />
                            </div>
                        </div>
                    )}

                    <div className='divider'></div>
                    {/* DESCRIPTION TEXT */}
                    <div
                        className={
                            window.innerWidth < 600
                                ? "padding_10px text_block"
                                : "padding_20px text_block"
                        }
                    >
                        {company.description
                            ? company.description
                            : "Description coming soon"}
                    </div>
                </div>
            </Dialog>

            {/* WATCHLISTS */}

            {/* Remove company from watchlist modal */}
            <Dialog
                open={openRemoveFromWatchlist}
                onClose={handleCloseRemoveFromWatchlist}
                maxWidth={false}
            >
                {window.location.pathname === "/watchlist" ? (
                    <div className='background_color_1'>
                        <div className='flex space_between title_4 mt_10px mb_10px ml_15px mr_15px'>
                            <div className='mr_10px'>{`Remove ${company.ticker} from ${currentWatchlist[0].name}?`}</div>
                            <div
                                className='cursor_pointer title_4 flex center ml_25px mt_5px'
                                onClick={handleCloseRemoveFromWatchlist}
                            >
                                <AiIcons.AiOutlineClose />
                            </div>
                        </div>
                        <div className='divider_no_margin'></div>
                        <div className='margin_15px flex right'>
                            {/* Problemtofix: Dialog mit Icons */}

                            <div
                                className='button_delete hover_opacity_80 color_white ml_15px'
                                onClick={() => {
                                    handleDeleteWatchlistCompany();
                                }}
                            >
                                <div className='ml_10px text_3'>
                                    {useintl.formatMessage({
                                        id: "general.remove",
                                    })}
                                </div>
                                <IoIcons5.IoTrashOutline className='mr_10px ml_10px text_2' />
                            </div>
                        </div>
                    </div>
                ) : null}
            </Dialog>

            {/* Add to Watchlist Modal */}
            <Dialog
                open={openAddToWatchlist}
                onClose={handleCloseAddToWatchlist}
                maxWidth={false}
            >
                <AddToWatchlist
                    closeModal={handleCloseAddToWatchlist}
                    company={company}
                />
            </Dialog>

            {/* Create Watchlist Modal */}
            <Dialog
                open={openCreateWatchlist}
                onClose={handleCloseCreateWatchlist}
                maxWidth={false}
            >
                <CreateWatchlist closeModal={handleCloseCreateWatchlist} />
            </Dialog>

            {/* Add company to Portfolio modal */}
            <Dialog
                open={openAddToPortfolio}
                onClose={handleCloseAddToPortfolio}
                maxWidth={false}
            >
                <TransactionForm
                    portfolioType={null}
                    authorized={
                        user && currentPortfolio
                            ? user.id === currentPortfolio.user_id
                            : false
                    }
                    recommendations={recommendations}
                    currentPortfolio={currentPortfolio}
                    pre_selected_ticker={company.ticker}
                    closeTransactionModal={handleCloseAddToPortfolio}
                />
            </Dialog>

            {/* Remove company from Portfolio modal */}
            <Dialog
                open={openRemoveFromPortfolio}
                onClose={handleCloseRemoveFromPortfolio}
                maxWidth={false}
            >
                <RemoveFromPortfolio
                    closeModal={handleCloseRemoveFromPortfolio}
                    currentPortfolio={currentPortfolio}
                    ticker={company.ticker}
                />
            </Dialog>

            {/* CREATE NEW PORTFOLIO MODAL */}
            <Dialog
                open={openCreatePortfolio}
                onClose={handleClickCloseCreatePortfolio}
                maxWidth={false}
            >
                <CreatePortfolio closeModal={handleClickCloseCreatePortfolio} />
            </Dialog>

            {/* Bookmark Alert Snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openBookmarkAlert}
                autoHideDuration={5000}
                TransitionComponent={TransitionUp}
                key='TransitionUp'
                onClose={handleCloseBookmarkAlert}
                message={`${company.ticker} is already set as a bookmark`}
                action={
                    <React.Fragment>
                        <IconButton
                            size='small'
                            aria-label='close'
                            color='inherit'
                            onClick={handleCloseBookmarkAlert}
                        >
                            <AiIcons.AiOutlineClose />
                        </IconButton>
                    </React.Fragment>
                }
            />
            {/* Bookmark Success Snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openBookmarkSuccess}
                autoHideDuration={5000}
                TransitionComponent={TransitionLefta}
                key={`TransitionLefta${company.ticker}`}
                onClose={handleCloseBookmarkSuccess}
                message={`${company.ticker} added to your bookmarks`}
                action={
                    <React.Fragment>
                        <IconButton
                            size='small'
                            aria-label='close'
                            color='inherit'
                            onClick={handleCloseBookmarkSuccess}
                        >
                            <AiIcons.AiOutlineClose />
                        </IconButton>
                    </React.Fragment>
                }
            />
            {/* Watchlist Success Snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openWatchlistSuccess}
                autoHideDuration={5000}
                TransitionComponent={TransitionLeftb}
                key={`TransitionLeftb${company.ticker}`}
                onClose={handleCloseWatchlistSuccess}
                message={`${company.ticker} added to the selected watchlists`}
                action={
                    <React.Fragment>
                        <IconButton
                            size='small'
                            aria-label='close'
                            color='inherit'
                            onClick={handleCloseWatchlistSuccess}
                        >
                            <AiIcons.AiOutlineClose />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
};

CompanyActionsMenu.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    watchlist: PropTypes.object.isRequired,
    portfolio: PropTypes.object.isRequired,
    bookmark: PropTypes.object.isRequired,
    setBookmark: PropTypes.func.isRequired,
    addWatchlistCompany: PropTypes.func.isRequired,
    deleteWatchlistCompany: PropTypes.func.isRequired,
    getWatchlist: PropTypes.func.isRequired,
    setWatchlist: PropTypes.func.isRequired,
    setPortfolio: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    watchlist: state.watchlist,
    screener: state.screener,
    portfolio: state.portfolio,
    user: state.user,
    bookmark: state.bookmark,
});

export default connect(mapStateToProps, {
    setBookmark,
    addWatchlistCompany,
    deleteWatchlistCompany,
    getWatchlist,
    setWatchlist,
    setPortfolio,
})(CompanyActionsMenu);
