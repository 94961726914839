import {
    SET_WATCHLIST,
    RENAME_WATCHLIST,
    DELETE_WATCHLIST,
    SET_CURRENT_WATCHLIST,
    GET_WATCHLIST_COMPANIES,
    GET_URL_WATCHLIST_COMPANIES,
    ADD_WATCHLIST_COMPANY,
    DELETE_WATCHLIST_COMPANY,
    WATCHLIST_ERROR,
    LOAD_WATCHLISTS,
    CLEAR_WATCHLISTS,
} from "../actions/Types";

const initialState = {
    companies: [],
    modal: false,
    watchlists: [],
    current: null,
    current_access: true,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_WATCHLISTS:
            return {
                ...state,
                watchlists: state.watchlists.concat(action.payload),
                current:
                    action.payload.length > 0 && state.current === null
                        ? action.payload[0].id
                        : state.current,
            };

        case CLEAR_WATCHLISTS:
            return {
                companies: [],
                modal: false,
                watchlists: [],
                current: null,
                current_access: true,
                error: null,
            };
        case SET_WATCHLIST:
            return {
                ...state,
                companies: [],
                watchlists: [
                    ...state.watchlists,
                    {
                        id: action.payload.id,
                        user_id: action.payload.user_id,
                        public: action.payload.public,
                        name: action.payload.name,
                        description: action.payload.description,
                        icon: action.payload.icon,
                        date: action.payload.date,
                        ticker: null,
                        assets: null,
                    },
                ],
                current: action.payload.id,
                current_access: true,
            };

        case RENAME_WATCHLIST:
            return {
                ...state,
                watchlists: action.payload,
            };

        case DELETE_WATCHLIST:
            return {
                ...state,
                watchlists: action.payload.watchlists,
                current:
                    action.payload.watchlists.length === 0
                        ? null
                        : action.payload.watchlists[0].id,
            };

        case GET_WATCHLIST_COMPANIES:
            return {
                ...state,
                companies: action.payload.companies,
                current: action.payload.watchlist.id,
                error: false,
            };

        case GET_URL_WATCHLIST_COMPANIES:
            return {
                ...state,
                companies: action.payload.companies,
                current: action.payload.watchlist.id,
                watchlists: [
                    ...state.watchlists,
                    {
                        id: action.payload.watchlist.id,
                        user_id: action.payload.watchlist.user_id,
                        public: action.payload.watchlist.public,
                        name: action.payload.watchlist.name,
                        icon: action.payload.watchlist.icon,
                        date: action.payload.watchlist.date,
                        ticker: action.payload.watchlist.ticker,
                    },
                ],
                error: false,
            };

        case SET_CURRENT_WATCHLIST:
            return {
                ...state,
                current: action.payload,
                error: false,
            };

        case ADD_WATCHLIST_COMPANY:
            return {
                ...state,
                watchlists: action.payload,
            };

        case DELETE_WATCHLIST_COMPANY:
            return {
                ...state,
                watchlists: action.payload,
            };

        case WATCHLIST_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
